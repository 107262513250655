import Api from 'domain/api';

import { put, call } from 'redux-saga/effects';
import * as profileActions from 'domain/profile/actions';
import { showAxiosErrors } from 'lib/helpers';

export function* makeSendEmail({ payload }) {
  try {
    const { data } = yield call(Api.sendEmailForRecoverPassword, {
      email: payload.email,
    });
    yield put({
      type: profileActions.sendEmailForRecoverPasswordAction.success,
      payload: data,
    });
  } catch (errors) {
    yield showAxiosErrors(errors.response, true);
    yield put({
      type: profileActions.sendEmailForRecoverPasswordAction.failure,
      payload: errors.response.data,
    });
  }
}

export function* makeResetPassword({ payload }) {
  try {
    const { data } = yield call(Api.sendResetPassword, {
      headers: {
        Authorization: `Bearer ${payload.token}`,
      },
      password: payload.password,
      passwordConfirmation: payload.password_confirmation,
    });

    yield put({
      type: profileActions.sendResetPasswordAction.success,
      payload: data,
    });
  } catch (errors) {
    yield showAxiosErrors(errors.response, true);
    yield put({
      type: profileActions.sendResetPasswordAction.failure,
      payload: errors,
    });
  }
}

export function* makeChangePassword({ payload }) {
  try {
    const { data } = yield call(Api.sendChangePassword, {
      oldPassword: payload.old_password,
      password: payload.password,
      passwordConfirmation: payload.password_confirmation,
    });

    yield put({
      type: profileActions.sendChangePasswordAction.success,
      payload: data,
    });
  } catch (errors) {
    yield showAxiosErrors(errors.response, true);
    yield put({
      type: profileActions.sendChangePasswordAction.failure,
      payload: errors,
    });
  }
}
