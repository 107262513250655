export default [
  {
    ProducerProcessBatch_floId: 6166,
    ProducerProcessBatch_SSCCPallet: 35828482484842,
    ProducerDeliveryBatch_SSCCPallet: 35828482484842,
    ResellerInBatch_colombiaSSCCPallet: 35828482484845,
    ResellerOutBatch_antwerpSSCC: 35828482484845,
    ResellerOutBatch_banafoodSSCCPallet: null,
    DistributorInslagBatch_SSCCCode: null,
    DistributorInslagBatch_batchNumber: null,
    DistributorUitslagBatch_batchNumber: null,
  },
  {
    ProducerProcessBatch_floId: 6167,
    ProducerProcessBatch_SSCCPallet: null,
    ProducerDeliveryBatch_SSCCPallet: null,
    ResellerInBatch_colombiaSSCCPallet: null,
    ResellerOutBatch_antwerpSSCC: null,
    ResellerOutBatch_banafoodSSCCPallet: 35828482484845,
    DistributorInslagBatch_SSCCCode: 35828482484845,
    DistributorInslagBatch_batchNumber: 35828482484842,
    DistributorUitslagBatch_batchNumber: 35828482484842,
  },
];
