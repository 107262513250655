export default () => ({
  batchType: {
    fontSize: 20,
    margin: [10, 0],
    cursor: 'pointer',
  },
  arrow: {
    position: 'relative',
    top: 2,
  },
});
