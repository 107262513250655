import React from 'react';
// components
import ModelSettingsDropDown from 'components/Dropdown/ModelSettingsDropDown';
import NotificationLink from 'models/utils/NotificationButtons/NotificationLink/NotificationLink';
import MarkAsReadButton from 'models/utils/NotificationButtons/MarkAsReadButton/MarkAsReadButton';
// types
import { Notification } from 'lib/lib';

export default {
  key: 'settingsAndActions',
  title: <ModelSettingsDropDown />,
  style: { flex: '1 0 50px', justifyContent: 'space-evenly' },
  render: (column: Notification) => (
    <>
      <NotificationLink column={column} />
      <MarkAsReadButton column={column} />
    </>
  ),
};
