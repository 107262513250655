import ldcbrbatch from 'mocks/router/oranges/ldcbrbatch';
import jdebatch from 'mocks/router/oranges/jdebatch';
import refrescobatch from 'mocks/router/oranges/refrescobatch';
import ahbatch from 'mocks/router/oranges/ahbatch';
import generic from 'mocks/router/generic';

export default {
  ...generic,
  ldcbrbatch,
  jdebatch,
  refrescobatch,
  ahbatch,
};
