import React from 'react';
import { Link } from 'react-router-dom';
import { getUrl } from 'routes';
import { renderFullDateFormat, defineExpireColor } from 'lib/renderHelpers';
import { AdminInvitation as AdminInvitationType } from '../../../../lib/lib';

export default () => [
  {
    title: 'ID',
    key: 'id',
    defaultSelectedColumn: true,
    style: { flex: '1 0 30px' },
    render: (item) => item.id,
  },
  {
    title: 'E-mail',
    key: 'email',
    defaultSelectedColumn: true,
    style: { flex: '1 0 300px' },
    render: (item) => (
      <Link to={{ pathname: getUrl('AdminInvitation', { id: item.id }) }}>{item.email}</Link>
    ),
  },
  {
    title: 'To Org',
    key: 'invited_to_org_id',
    defaultSelectedColumn: true,
    style: { flex: '1 0 30px' },
    render: (item) => item.invited_to_org_id,
  },
  {
    title: 'status',
    key: 'status',
    defaultSelectedColumn: true,
    style: { flex: '1 0 100px' },
    render: (item) => item.status,
  },
  {
    title: 'expires at',
    key: 'expires_at',
    defaultSelectedColumn: true,
    style: { flex: '1 0 200px' },
    render: (item) => (
      <span style={{ color: defineExpireColor(item.expires_at, item.status) }}>
        {renderFullDateFormat(item.expires_at)}
      </span>
    ),
  },
];
