import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
// jss
import injectSheet, { WithStyles } from 'react-jss';
import styleChannelSwitcher from 'components/ChannelSwitcher/styles/styleChannelSwitcher';
// redux
import { getChannelName } from 'domain/env';
// components
import ChannelDropDown from './ChannelDropDown';
import { Arrow, OwnChannelIcon } from './IconResolvers';
// utils
import { useOutsideClick } from 'lib/utils';
// types
import { AppStateType } from 'types';

const mapStateToProps = (state: AppStateType) => ({
  channelName: getChannelName(state),
});

const ChannelSwitcher: React.FC<WithStyles<typeof styleChannelSwitcher>> = (props) => {
  const { classes } = props;
  const { channelName } = useSelector(mapStateToProps, shallowEqual);

  const { isOpen, setIsOpen, ref } = useOutsideClick(false);

  return (
    <div className={classes.channelSwitcher}>
      <div className="channels">
        <div
          className="currentChannelIcon"
          onClick={() => setIsOpen(!isOpen)}
          data-switch="channel"
          ref={ref}
        >
          {channelName && (
            <>
              <OwnChannelIcon />
              <Arrow isOpen={isOpen} />
            </>
          )}
        </div>

        <ChannelDropDown toggleDropDownList={setIsOpen} isOpen={isOpen} />
      </div>
    </div>
  );
};

export default injectSheet(styleChannelSwitcher)(ChannelSwitcher);
