import Route from 'route-parser';
import {getUserFromRequest} from "../../../usersUtils";
import certificates from 'mocks/responses/certificates/certificatesList';
import { ORGANIZATIONS } from 'themes/constants';
import { approveCertificate } from 'mocks/router/utils';

export default (request) => {
  const route = new Route('/api/v1/approve/document/:certId');
  const parameters = route.match(request.url);

  if (request.method === 'post') {
    if (parameters && parameters.certId) {
      const user = getUserFromRequest(request);
      const data = JSON.parse(request.data);

      return [
        200,
        approveCertificate(...certificates[ORGANIZATIONS.SIM][request.channel].active, data),
      ];
    }
  }

  return [500, `url not proceeded: ${request.url}`];
};
