import { CHANNELS, MODEL_TYPES } from 'themes/constants';
import tilapiaCertModelDefaultTab from './tilapia/tabs/defaultTabModel';
import orangesCertModelDefaultTab from './oranges/tabs/defaultTabModel';
import bananasCertModelDefaultTab from './bananas/tabs/defaultTabModel';
import eggsCertModelDefaultTab from './eggs/tabs/defaultTabModel';

import bananasCertModelIndex from 'models/certificates/bananas/index';
import bananasCertModel from 'models/certificates/bananas/default';
import tilapiaCertModelIndex from 'models/certificates/tilapia/index';
import tilapiaCertModel from 'models/certificates/tilapia/default';
import orangesCertModelIndex from 'models/certificates/oranges/index';
import orangesCertModel from 'models/certificates/oranges/default';
import eggsCertModelIndex from 'models/certificates/eggs/index';
import eggsCertModel from 'models/certificates/eggs/default';

export const getCertificateModelByChannelName = (channelName: CHANNELS, modelType: MODEL_TYPES) => {
  switch (channelName) {
    case CHANNELS.ORANGES: {
      if (modelType === MODEL_TYPES.CERTIFICATES_LIST) {
        return orangesCertModelIndex;
      }
      if (modelType === MODEL_TYPES.CERTIFICATE) {
        return orangesCertModel;
      }
      return orangesCertModelDefaultTab;
    }

    case CHANNELS.BANANAS: {
      if (modelType === MODEL_TYPES.CERTIFICATES_LIST) {
        return bananasCertModelIndex;
      }
      if (modelType === MODEL_TYPES.CERTIFICATE) {
        return bananasCertModel;
      }
      return bananasCertModelDefaultTab;
    }

    case CHANNELS.EGGS: {
      if (modelType === MODEL_TYPES.CERTIFICATES_LIST) {
        return eggsCertModelIndex;
      }
      if (modelType === MODEL_TYPES.CERTIFICATE) {
        return eggsCertModel;
      }
      return eggsCertModelDefaultTab;
    }

    case CHANNELS.TILAPIA: {
      if (modelType === MODEL_TYPES.CERTIFICATES_LIST) {
        return tilapiaCertModelIndex;
      }
      if (modelType === MODEL_TYPES.CERTIFICATE) {
        return tilapiaCertModel;
      }
      return tilapiaCertModelDefaultTab;
    }

    case CHANNELS.JUICE: {
      if (modelType === MODEL_TYPES.CERTIFICATES_LIST) {
        return tilapiaCertModelIndex;
      }
      if (modelType === MODEL_TYPES.CERTIFICATE) {
        return tilapiaCertModel;
      }
      return tilapiaCertModelDefaultTab;
    }

    default: {
      throw new Error(`No such channel ${channelName}`);
    }
  }
};
