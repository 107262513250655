import React, { useLayoutEffect, useMemo } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
// redux
import { getInvitations, getIsLoading } from 'domain/admin';
import { getParams } from 'domain/common';
import * as adminActions from 'domain/admin/actions';
// jss
import injectSheet, { WithStyles } from 'react-jss';
import adminInvitationStyle from 'pages/admin/styles/adminInvitationStyle';
// components
import AdminNav from 'pages/admin/components/adminNav';
import SimpleTabsFrame from 'components/Frames/SimpleTabsFrame';
import Loader from 'components/Loaders/smallLoader';
// types
import { AppStateType } from 'types';
import { AdminInvitation as AdminInvitationType } from 'lib/lib';

const mapStateToProps = (state: AppStateType) => ({
  invitations: getInvitations(state),
  isLoading: getIsLoading(state),
  params: getParams(state),
});

const AdminInvitation: React.FC<WithStyles<typeof adminInvitationStyle>> = ({ classes }) => {
  const dispatch = useDispatch();
  const { invitations, isLoading, params } = useSelector(mapStateToProps, shallowEqual);

  const invitation = useMemo(() => invitations.find((f) => f.id === Number(params.id)), [
    params.id,
  ]);

  const invitationModel = useMemo(
    () => [
      {
        title: 'id',
        key: 'id',
        render: (i: AdminInvitationType) => i.id,
      },
      {
        title: 'email',
        key: 'email',
        render: (i: AdminInvitationType) => i.email,
      },
      {
        title: 'to org',
        key: 'invited_to_org_id',
        render: (i: AdminInvitationType) => i.invited_to_org_id,
      },
      {
        title: 'expires_at',
        key: 'expires_at',
        render: (i: AdminInvitationType) => i.expires_at,
      },
      {
        title: 'status',
        key: 'status',
        render: (i: AdminInvitationType) => i.status,
      },
    ],
    [],
  );

  const invitationModelKeys = useMemo(() => invitationModel.map((c) => c.key), [invitationModel]);

  useLayoutEffect(() => {
    dispatch(adminActions.fetchInvitationsAction());
  }, []);

  return (
    <div className={classes.component}>
      {invitation && (
        <React.Fragment>
          <AdminNav />
          <SimpleTabsFrame
            tabs={
              isLoading
                ? [{ style: {}, title: <Loader />, body: '' }]
                : [
                    {
                      style: {},
                      className: '',
                      title: `User № ${invitation.id}`,
                      body: Object.keys(invitation)
                        .filter((key) => invitationModelKeys.includes(key))
                        .map((key) => {
                          const currentInvitation = invitationModel.find((f) => f.key === key);
                          return (
                            currentInvitation && (
                              <div className={classes.tableRow} key={key}>
                                <div className="left">{currentInvitation.title}</div>
                                <div className="right">{currentInvitation.render(invitation)}</div>
                              </div>
                            )
                          );
                        }),
                    },
                  ]
            }
          />
        </React.Fragment>
      )}
    </div>
  );
};

export default injectSheet(adminInvitationStyle)(AdminInvitation);
