import React from 'react';
import { Link } from 'react-router-dom';
//
import { getUrl } from 'routes';

interface OwnProps {
  onClick: () => void;
  entityId: string;
}

const CertificateLink: React.FC<OwnProps> = (props) => {
  const { onClick, entityId } = props;
  return (
    <Link
      data-certificate={`${entityId}`}
      className="square-button"
      to={{
        pathname: getUrl('Document', { id: entityId }),
      }}
      onClick={onClick}
    >
      Link
    </Link>
  );
};

export default CertificateLink;
