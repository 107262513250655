import React from 'react';
import PropTypes from 'prop-types';

// default theme is white
// do <Loader theme="black" /> for black color

const SmallLoader = ({ styles, theme }) => (
  <div className={`spinner-block ${theme || ''}`} style={{ ...styles }}>
    <div className="spinner spinner-1" />
  </div>
);

SmallLoader.propTypes = {
  styles: PropTypes.object,
  theme: PropTypes.string,
};

export default SmallLoader;
