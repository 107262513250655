import React, { useCallback, useEffect, useRef, useState } from 'react';
import moment from 'moment';
import { toast } from 'lib/helpers';
import Datepicker from 'react-datepicker';
import { ArrowDownSvg, ArrowUpSvg } from 'components/icons';
import { TabI, TabTitleButton } from 'components/Dropdown/SimpleTabGroup';

interface DateRange {
  beginDate: Date;
  endDate: Date;
}

interface DatePickersFormI {
  values: DateRange;
  callback: ({ beginDate, endDate }: DateRange) => void;
  isOpen: boolean;
}

const DatePickersForm: React.FC<DatePickersFormI> = (props) => {
  const { values, callback, isOpen } = props;
  const [beginDate, setBeginDate] = useState<Date | null>(values.beginDate);
  const [endDate, setEndDate] = useState<Date | null>(values.endDate);

  const isValidRange = () => beginDate && endDate && moment(endDate).diff(moment(beginDate)) >= 0;

  const isSelectedAfterToday = () =>
    beginDate &&
    endDate &&
    moment().diff(moment(beginDate)) >= 0 &&
    moment().diff(moment(endDate)) >= 0;

  const setTabQueryParams = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!isValidRange()) {
      toast('Start date should be before end date', 'error');
    } else if (!isSelectedAfterToday()) {
      toast('Dates should not contain future dates', 'error');
    } else if (beginDate && endDate) {
      callback({ beginDate, endDate });
    }
  };

  if (!isOpen) {
    return null;
  }

  return (
    <div className="select-range">
      <h5 className="select-range-header">Exact Dates</h5>
      <form className="select-range-form" onSubmit={setTabQueryParams}>
        <div className="datepicker-wrapper">
          <Datepicker
            className="date-input"
            selected={beginDate ? moment(beginDate).toDate() : null}
            onChange={(beginDate) => setBeginDate(beginDate ? moment(beginDate).toDate() : null)}
            selectsStart
            startDate={isValidRange() ? beginDate : null}
            endDate={endDate}
            dateFormat="dd/MM/yyyy"
          />
          <span className="date-input-breaker">-</span>
          <Datepicker
            className="date-input"
            selected={endDate ? moment(endDate).toDate() : null}
            onChange={(endDate) => setEndDate(endDate ? moment(endDate).toDate() : null)}
            selectsEnd
            startDate={beginDate}
            endDate={isValidRange() ? endDate : null}
            dateFormat="dd/MM/yyyy"
          />
        </div>
        <button className="apply-range-button">Apply filters</button>
      </form>
    </div>
  );
};

interface FormCurrentSelectedValueI {
  values: DateRange;
  id: string;
  isOpen: boolean;
}

const FormCurrentSelectedValue: React.FC<FormCurrentSelectedValueI> = (props) => {
  const { values, id, isOpen } = props;

  return (
    <button type={'button'}>
      {isOpen ? <ArrowUpSvg /> : <ArrowDownSvg />}
      <span className="datepicker-tab" data-datepicker-text={`data-datepicker-text-${id}`}>
        {`${moment(values.beginDate).format('DD/MM/YY')} - ${moment(values.endDate).format(
          'DD/MM/YY',
        )}`}
      </span>
    </button>
  );
};

export type ChangeFilters = {
  id: string;
  beginDate: Date;
  endDate: Date;
};

interface TabDropdownI extends TabI {
  values: DateRange;
  changeFilters: (filters: ChangeFilters) => void;
}

const DropdownTab: React.FC<TabDropdownI> = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null); // React.createRef<HTMLDivElement>();

  const refClickHandler = (e: Event) => {
    const target = e.target as HTMLElement;
    if (
      e &&
      dropdownRef &&
      dropdownRef.current &&
      (dropdownRef.current.contains(target) ||
        (target.getAttribute('class') || '').includes('react-datepicker'))
    ) {
      // calendar node disappears after click so we should handle it this way
      return;
    }
    setIsOpen(false);
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('mousedown', refClickHandler, false);
    } else {
      document.removeEventListener('mousedown', refClickHandler, false);
    }
  }, [isOpen]);

  const { setActiveTab, id, title, isActive, values, changeFilters } = props;

  const callback = ({ beginDate, endDate }: DateRange) => {
    setIsOpen(false);
    changeFilters({ id, beginDate, endDate });
  };

  return (
    <div ref={dropdownRef} className={`tab select ${isActive ? 'active' : ''}`}>
      <TabTitleButton setActiveTab={setActiveTab} id={id} title={title} />

      <div onClick={() => setIsOpen(!isOpen)} className="drop-down-list">
        <FormCurrentSelectedValue id={id} values={values} isOpen={isOpen} />
      </div>
      <DatePickersForm isOpen={isOpen} values={values} callback={callback} />
    </div>
  );
};

export default DropdownTab;
