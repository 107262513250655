import React from 'react';
import { Link } from 'react-router-dom';
import { getUrl } from 'routes';
import baseModelFields from 'models/baseModelFields';
import { CHANNELS, ORGANIZATIONS } from 'themes/constants';
import { renderShortDateFormat } from 'lib/renderHelpers';
import { getStatusReceivedDate, getStatusSentDate, getSupplierStatus } from '../../utils/utils';
import { BatchRecord, ModelRequiredInfo } from 'lib/lib';

export default (modelRequiredInfo: ModelRequiredInfo) => {
  const supplierVerification =
    modelRequiredInfo.loggedInUserRole === ORGANIZATIONS.SIM
      ? [
          {
            key: 'supplier.status',
            label: 'Supplier Verification',
            title: 'Supplier Verification',
            render: (column: BatchRecord) => getSupplierStatus(column.supplier),
          },
          {
            key: 'supplier.proposalSentAt',
            label: 'Status Verification Sent At',
            title: 'Status Verification Sent At',
            render: (column: BatchRecord) => getStatusSentDate(column.supplier),
          },
          {
            key: 'supplier.statusUpdatedAt',
            label: 'Status Verification Received At',
            title: 'Status Verification Received At',
            render: (column: BatchRecord) => getStatusReceivedDate(column.supplier),
          },
        ]
      : [];

  return [
    {
      key: 'jdeReference',
      label: 'JDE REFERENCE',
      title: 'JDE Reference',
      render: (column: BatchRecord) => (
        <Link
          className="contract"
          to={{
            pathname: getUrl('BatchContractType', {
              type: ORGANIZATIONS.LDCBR.toLowerCase(),
              contract_number: encodeURIComponent(encodeURIComponent(column.jdeReference)),
              modelType: 'contractDetails',
              channelName: column.channelName,
            }),
          }}
        >
          {column.jdeReference}
        </Link>
      ),
    },
    {
      key: 'contractNumber',
      label: 'CONTRACT NUMBER',
      title: 'Contract Number',
      render: (column: BatchRecord) => column.contractNumber,
    },
    {
      key: 'qttyContracted',
      label: 'QTTY CONTRACTED - KG',
      title: 'QTTY Contracted',
      render: (column: BatchRecord) => column.qttyContracted,
    },
    {
      key: 'batchFinal',
      label: 'BATCH - FINAL',
      title: 'Batch Final',
      render: (column: BatchRecord) => column.batchFinal,
    },
    {
      key: 'manufacturedSequence',
      label: 'MANUFACTING SEQUENCE',
      title: 'Manufactured Sequence',
      render: (column: BatchRecord) => column.manufacturedSequence,
    },
    {
      key: 'productCode',
      label: 'PRODUCT CODE',
      title: 'Product Code',
      render: (column: BatchRecord) => column.productCode,
    },
    {
      key: 'manufacturingDate',
      label: 'MANUFACTURING DATE',
      title: 'Manufacturing Date',
      render: (column: BatchRecord) => renderShortDateFormat(column.manufacturingDate),
    },
    {
      key: 'startBlendingDate',
      label: 'START BLENDING DATE',
      title: 'Start Blending Date',
      render: (column: BatchRecord) => renderShortDateFormat(column.startBlendingDate),
    },
    {
      key: 'finalBlendingDate',
      label: 'FINAL BLENDING DATE',
      title: 'Final Blending Date',
      render: (column: BatchRecord) => renderShortDateFormat(column.finalBlendingDate),
    },
    {
      key: 'facilityName',
      label: 'FACILITY NAME',
      title: 'Facility Name',
      render: (column: BatchRecord) => column.facilityName,
    },
    {
      key: 'farmName',
      label: 'FARM NAME',
      title: 'Farm Name',
      render: (column: BatchRecord) => column.farmName,
    },
    ...supplierVerification,
    {
      key: 'farmAddress',
      label: 'FARM ADDRESS',
      title: 'Farm Address',
      render: (column: BatchRecord) => column.farmAddress,
    },
    {
      key: 'zipcode',
      label: 'ZIPCODE',
      title: 'Zipcode',
      render: (column: BatchRecord) => column.zipcode,
    },
    {
      key: 'state',
      label: 'STATE',
      title: 'State',
      render: (column: BatchRecord) => column.state,
    },
    {
      key: 'city',
      label: 'CITY',
      title: 'City',
      render: (column: BatchRecord) => column.city,
    },
    {
      key: 'varietyName',
      label: 'VARIETY NAME',
      title: 'Variety Name',
      render: (column: BatchRecord) => column.varietyName,
    },
    {
      key: 'block',
      label: 'BLOCK',
      title: 'Block',
      render: (column: BatchRecord) => column.block,
    },
    {
      key: 'fruitHarvestStartDate',
      label: 'FRUIT HARVEST START DATE',
      title: 'Fruit Harvest Start Date',
      render: (column: BatchRecord) => renderShortDateFormat(column.fruitHarvestStartDate),
    },
    {
      key: 'fruitHarvestFinalDate',
      label: 'FRUIT HARVEST FINAL DATE',
      title: 'Fruit Harvest Final Date',
      render: (column: BatchRecord) => renderShortDateFormat(column.fruitHarvestFinalDate),
    },
    {
      key: 'percentFarm',
      label: '%FARM',
      title: '% Farm',
      render: (column: BatchRecord) => column.percentFarm,
    },
    {
      key: 'totalPickers',
      label: 'TOTAL PICKERS',
      title: 'Total Pickers',
      render: (column: BatchRecord) => column.totalPickers,
    },
    {
      key: 'ageAverage',
      label: 'AGE AVERAGE',
      title: 'Age Average',
      render: (column: BatchRecord) => column.ageAverage,
    },
    {
      key: 'dateOfReceiptSantos',
      label: 'DATE OF RECEIPT SANTOS',
      title: 'Date Of Receipt Santos',
      render: (column: BatchRecord) => renderShortDateFormat(column.dateOfReceiptSantos),
    },
    {
      key: 'batchId',
      label: 'Batch Id',
      title: 'Batch Details',
      render: (column: BatchRecord) => (
        <React.Fragment>
          <Link
            to={{
              pathname: getUrl('BatchDetailsType', {
                type: ORGANIZATIONS.LDCBR.toLowerCase(),
                batch_id: column.batchId,
                modelType: 'details',
                channelName: column.channelName,
              }),
            }}
            title={column.batchId}
            className="batch-details"
          >
            Details
          </Link>
        </React.Fragment>
      ),
    },
    ...baseModelFields(modelRequiredInfo),
  ];
};
