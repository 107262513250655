export default [
  {
    $class: 'org.powerchain.juice.batch.ProducerBatchGroupedConcept',
    vesselTkBatchId: 'tst__00000000',
    uniqPlantCount: 1,
    uniqFarmCount: 1,
    lastReceiptDateBrMin: '2020-03-23T10:23:44.197Z',
    lastReceiptDateBrMax: '2020-03-23T10:23:44.197Z',
    startManDateMin: '2020-03-23T10:23:44.197Z',
    startManDateMax: '2020-03-23T10:23:44.197Z',
    finalManDateMin: '2020-03-23T10:23:44.197Z',
    finalManDateMax: '2020-03-23T10:23:44.197Z',
    harvestStartDateMin: '2020-03-23T10:23:44.197Z',
    harvestStartDateMax: '2020-03-23T10:23:44.197Z',
    harvestFinalDateMin: '2020-03-23T10:23:44.197Z',
    harvestFinalDateMax: '2020-03-23T10:23:44.197Z',
    blDateMin: '2020-03-23T10:23:44.197Z',
    blDateMax: '2020-03-23T10:23:44.197Z',
    createdAtMin: '2020-03-23T11:12:41.484Z',
    createdAtMax: '2020-03-23T11:12:41.484Z',
    createdBy:
      'resource:org.powerchain.juice.member.ProducerUser#CitrosucoBrazil_6c3ed9e1-e09b-455c-a3e9-d0b7e955af02',
    channelName: 'juice',
    createdBy_participant: {
      username: null,
      email: null,
      participant:
        'org.powerchain.juice.member.ProducerUser#CitrosucoBrazil_6c3ed9e1-e09b-455c-a3e9-d0b7e955af02',
      org: { name: 'CitrosucoBrazil', fullname: 'Citrosuco Brazil', logo: null },
      channel: { title: 'Juice', name: 'juice' },
      user: {
        username: 'CitrosucoBrazilTST staging',
        email: 'CitrosucoBrazilTST@pc.t2f.io',
        org: { name: 'CitrosucoBrazil', fullname: 'Citrosuco Brazil', logo: null },
      },
    },
  },
];
