export default () => (
  {
    $class: 'org.powerchain.tilapia.batch.RetailerBatch',
    contractId: '147561',
    dateReceipt: '2019-01-02T15:07:00.000Z',
    supplier: '17165',
    poNumber: '4505581691',
    articleNumberConsumerProduct: '147561',
    palletNumber: '387175915102343571',
    lotNumber: '1809210057',
    aantalVerpakkingen: 144,
    thtConsumerProduct: '2020-07-24T21:00:00.000Z',
    batchId: '3adc5d06-e2fb-4eb0-ba8b-59aa1d6fee9b',
    createdAt: '2019-08-15T08:47:46.138Z',
    createdBy:
      'resource:org.powerchain.tilapia.member.RetailerUser#Jumbo_e1d059a8-05a0-40e9-ae9f-256341b22c96',
    status: 'PENDING',
    uploadSource: 'frontEndApp',"createdBy_participant":{
      "username":null,
      "email":null,
      "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
      "org":{
        "name":"Fyffes",
        "fullname":"Fyffes",
        "logo":null
      },
      "channel":{
        "title":"Bananas",
        "name":"bananas"
      },
      "user":{
        "username":"Fyffes TST staging",
        "email":"FyffesTST@pc.t2f.io",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        }
      }
    }
  },
  {
    $class: 'org.powerchain.tilapia.batch.RetailerBatch',
    contractId: '147561',
    dateReceipt: '2019-02-20T13:41:00.000Z',
    supplier: '17165',
    poNumber: '4505637831',
    articleNumberConsumerProduct: '147561',
    palletNumber: '387175915102110098',
    lotNumber: '1809210057',
    aantalVerpakkingen: 144,
    thtConsumerProduct: '2020-07-24T21:00:00.000Z',
    batchId: '0e7b46a1-2c84-4b9b-9e87-86001948bd73',
    createdAt: '2019-08-15T08:47:46.138Z',
    createdBy:
      'resource:org.powerchain.tilapia.member.RetailerUser#Jumbo_e1d059a8-05a0-40e9-ae9f-256341b22c96',
    status: 'PENDING',
    uploadSource: 'frontEndApp',"createdBy_participant":{
      "username":null,
      "email":null,
      "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
      "org":{
        "name":"Fyffes",
        "fullname":"Fyffes",
        "logo":null
      },
      "channel":{
        "title":"Bananas",
        "name":"bananas"
      },
      "user":{
        "username":"Fyffes TST staging",
        "email":"FyffesTST@pc.t2f.io",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        }
      }
    }
  },
  {
    $class: 'org.powerchain.tilapia.batch.RetailerBatch',
    contractId: '147561',
    dateReceipt: '2019-02-20T13:41:00.000Z',
    supplier: '17165',
    poNumber: '4505637831',
    articleNumberConsumerProduct: '147561',
    palletNumber: '387175915102110159',
    lotNumber: '1809210057',
    aantalVerpakkingen: 144,
    thtConsumerProduct: '2020-07-24T21:00:00.000Z',
    batchId: '17615c01-5677-467c-83bc-772abe1d4da7',
    createdAt: '2019-08-15T08:47:46.138Z',
    createdBy:
      'resource:org.powerchain.tilapia.member.RetailerUser#Jumbo_e1d059a8-05a0-40e9-ae9f-256341b22c96',
    status: 'PENDING',
    uploadSource: 'frontEndApp',"createdBy_participant":{
      "username":null,
      "email":null,
      "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
      "org":{
        "name":"Fyffes",
        "fullname":"Fyffes",
        "logo":null
      },
      "channel":{
        "title":"Bananas",
        "name":"bananas"
      },
      "user":{
        "username":"Fyffes TST staging",
        "email":"FyffesTST@pc.t2f.io",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        }
      }
    }
  },
  {
    $class: 'org.powerchain.tilapia.batch.RetailerBatch',
    contractId: '147561',
    dateReceipt: '2019-01-02T15:07:00.000Z',
    supplier: '17165',
    poNumber: '4505581691',
    articleNumberConsumerProduct: '147561',
    palletNumber: '387175915102110081',
    lotNumber: '1809210057',
    aantalVerpakkingen: 144,
    thtConsumerProduct: '2020-07-24T21:00:00.000Z',
    batchId: '1923b171-8e20-497e-ba22-2567c782c73f',
    createdAt: '2019-08-15T08:47:46.138Z',
    createdBy:
      'resource:org.powerchain.tilapia.member.RetailerUser#Jumbo_e1d059a8-05a0-40e9-ae9f-256341b22c96',
    status: 'PENDING',
    uploadSource: 'frontEndApp',"createdBy_participant":{
      "username":null,
      "email":null,
      "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
      "org":{
        "name":"Fyffes",
        "fullname":"Fyffes",
        "logo":null
      },
      "channel":{
        "title":"Bananas",
        "name":"bananas"
      },
      "user":{
        "username":"Fyffes TST staging",
        "email":"FyffesTST@pc.t2f.io",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        }
      }
    }
  },
  {
    $class: 'org.powerchain.tilapia.batch.RetailerBatch',
    contractId: '147561',
    dateReceipt: '2019-03-13T13:07:00.000Z',
    supplier: '17165',
    poNumber: '4505661110',
    articleNumberConsumerProduct: '147561',
    palletNumber: '387175915102110081',
    lotNumber: '1809210057',
    aantalVerpakkingen: 144,
    thtConsumerProduct: '2020-07-24T21:00:00.000Z',
    batchId: '383fd8e0-762f-4680-981f-9c6cee1f7dfe',
    createdAt: '2019-08-15T08:47:46.138Z',
    createdBy:
      'resource:org.powerchain.tilapia.member.RetailerUser#Jumbo_e1d059a8-05a0-40e9-ae9f-256341b22c96',
    status: 'PENDING',
    uploadSource: 'frontEndApp',"createdBy_participant":{
      "username":null,
      "email":null,
      "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
      "org":{
        "name":"Fyffes",
        "fullname":"Fyffes",
        "logo":null
      },
      "channel":{
        "title":"Bananas",
        "name":"bananas"
      },
      "user":{
        "username":"Fyffes TST staging",
        "email":"FyffesTST@pc.t2f.io",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        }
      }
    }
  },
  {
    $class: 'org.powerchain.tilapia.batch.RetailerBatch',
    contractId: '147561',
    dateReceipt: '2019-03-13T13:07:00.000Z',
    supplier: '17165',
    poNumber: '4505661110',
    articleNumberConsumerProduct: '147561',
    palletNumber: '387175915102343571',
    lotNumber: '1809210057',
    aantalVerpakkingen: 144,
    thtConsumerProduct: '2020-07-24T21:00:00.000Z',
    batchId: '10b827a0-bcdc-4fad-b815-2d0a61272b6c',
    createdAt: '2019-08-15T08:47:46.138Z',
    createdBy:
      'resource:org.powerchain.tilapia.member.RetailerUser#Jumbo_e1d059a8-05a0-40e9-ae9f-256341b22c96',
    status: 'PENDING',
    uploadSource: 'frontEndApp',"createdBy_participant":{
      "username":null,
      "email":null,
      "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
      "org":{
        "name":"Fyffes",
        "fullname":"Fyffes",
        "logo":null
      },
      "channel":{
        "title":"Bananas",
        "name":"bananas"
      },
      "user":{
        "username":"Fyffes TST staging",
        "email":"FyffesTST@pc.t2f.io",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        }
      }
    }
  },
  {
    $class: 'org.powerchain.tilapia.batch.RetailerBatch',
    contractId: '147561',
    dateReceipt: '2019-01-02T15:07:00.000Z',
    supplier: '17165',
    poNumber: '4505581691',
    articleNumberConsumerProduct: '147561',
    palletNumber: '387175915102343595',
    lotNumber: '1809210057',
    aantalVerpakkingen: 144,
    thtConsumerProduct: '2020-07-24T21:00:00.000Z',
    batchId: '634e8d2b-6ad0-4169-b459-f19274a4686b',
    createdAt: '2019-08-15T08:47:46.138Z',
    createdBy:
      'resource:org.powerchain.tilapia.member.RetailerUser#Jumbo_e1d059a8-05a0-40e9-ae9f-256341b22c96',
    status: 'PENDING',
    uploadSource: 'frontEndApp',"createdBy_participant":{
      "username":null,
      "email":null,
      "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
      "org":{
        "name":"Fyffes",
        "fullname":"Fyffes",
        "logo":null
      },
      "channel":{
        "title":"Bananas",
        "name":"bananas"
      },
      "user":{
        "username":"Fyffes TST staging",
        "email":"FyffesTST@pc.t2f.io",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        }
      }
    }
  },
  {
    $class: 'org.powerchain.tilapia.batch.RetailerBatch',
    contractId: '147561',
    dateReceipt: '2019-01-02T15:05:00.000Z',
    supplier: '17165',
    poNumber: '4505581751',
    articleNumberConsumerProduct: '147561',
    palletNumber: '387175915102110159',
    lotNumber: '1809210057',
    aantalVerpakkingen: 144,
    thtConsumerProduct: '2020-07-24T21:00:00.000Z',
    batchId: '636cdb8a-1feb-40dc-82f7-2ec2ade74942',
    createdAt: '2019-08-15T08:47:46.138Z',
    createdBy:
      'resource:org.powerchain.tilapia.member.RetailerUser#Jumbo_e1d059a8-05a0-40e9-ae9f-256341b22c96',
    status: 'PENDING',
    uploadSource: 'frontEndApp',"createdBy_participant":{
      "username":null,
      "email":null,
      "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
      "org":{
        "name":"Fyffes",
        "fullname":"Fyffes",
        "logo":null
      },
      "channel":{
        "title":"Bananas",
        "name":"bananas"
      },
      "user":{
        "username":"Fyffes TST staging",
        "email":"FyffesTST@pc.t2f.io",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        }
      }
    }
  },
  {
    $class: 'org.powerchain.tilapia.batch.RetailerBatch',
    contractId: '147561',
    dateReceipt: '2019-03-13T13:07:00.000Z',
    supplier: '17165',
    poNumber: '4505661110',
    articleNumberConsumerProduct: '147561',
    palletNumber: '387175915102343595',
    lotNumber: '1809210057',
    aantalVerpakkingen: 144,
    thtConsumerProduct: '2020-07-24T21:00:00.000Z',
    batchId: '6c92b953-e271-48e3-87d6-741e38498d0a',
    createdAt: '2019-08-15T08:47:46.138Z',
    createdBy:
      'resource:org.powerchain.tilapia.member.RetailerUser#Jumbo_e1d059a8-05a0-40e9-ae9f-256341b22c96',
    status: 'PENDING',
    uploadSource: 'frontEndApp',"createdBy_participant":{
      "username":null,
      "email":null,
      "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
      "org":{
        "name":"Fyffes",
        "fullname":"Fyffes",
        "logo":null
      },
      "channel":{
        "title":"Bananas",
        "name":"bananas"
      },
      "user":{
        "username":"Fyffes TST staging",
        "email":"FyffesTST@pc.t2f.io",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        }
      }
    }
  },
  {
    $class: 'org.powerchain.tilapia.batch.RetailerBatch',
    contractId: '147561',
    dateReceipt: '2019-01-02T15:05:00.000Z',
    supplier: '17165',
    poNumber: '4505581751',
    articleNumberConsumerProduct: '147561',
    palletNumber: '387175915102343588',
    lotNumber: '1809210057',
    aantalVerpakkingen: 144,
    thtConsumerProduct: '2020-07-24T21:00:00.000Z',
    batchId: '6f48fc8d-2318-46cf-b2db-55ac6de01e16',
    createdAt: '2019-08-15T08:47:46.138Z',
    createdBy:
      'resource:org.powerchain.tilapia.member.RetailerUser#Jumbo_e1d059a8-05a0-40e9-ae9f-256341b22c96',
    status: 'PENDING',
    uploadSource: 'frontEndApp',"createdBy_participant":{
      "username":null,
      "email":null,
      "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
      "org":{
        "name":"Fyffes",
        "fullname":"Fyffes",
        "logo":null
      },
      "channel":{
        "title":"Bananas",
        "name":"bananas"
      },
      "user":{
        "username":"Fyffes TST staging",
        "email":"FyffesTST@pc.t2f.io",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        }
      }
    }
  },
  {
    $class: 'org.powerchain.tilapia.batch.RetailerBatch',
    contractId: '147561',
    dateReceipt: '2019-02-20T13:41:00.000Z',
    supplier: '17165',
    poNumber: '4505637831',
    articleNumberConsumerProduct: '147561',
    palletNumber: '387175915102343588',
    lotNumber: '1809210057',
    aantalVerpakkingen: 144,
    thtConsumerProduct: '2020-07-24T21:00:00.000Z',
    batchId: 'd5ae2b48-9bd7-4ce4-a41e-0a92c29b2819',
    createdAt: '2019-08-15T08:47:46.138Z',
    createdBy:
      'resource:org.powerchain.tilapia.member.RetailerUser#Jumbo_e1d059a8-05a0-40e9-ae9f-256341b22c96',
    status: 'PENDING',
    uploadSource: 'frontEndApp',"createdBy_participant":{
      "username":null,
      "email":null,
      "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
      "org":{
        "name":"Fyffes",
        "fullname":"Fyffes",
        "logo":null
      },
      "channel":{
        "title":"Bananas",
        "name":"bananas"
      },
      "user":{
        "username":"Fyffes TST staging",
        "email":"FyffesTST@pc.t2f.io",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        }
      }
    }
  }
);
