import logoAH from 'components/logos/Albert_Heijn.png';

import { BatchListSvg, CertificateSvg, DashboardSvg, NotificationListSvg } from 'components/icons';
import { theme } from 'themes/styles';

export default {
  logo: logoAH,
  colors: {
    primary: 'rgb(83, 67, 57)',
    primaryHover: 'rgb(121, 99, 82)',
    mainMenuLine: 'rgb(83, 67, 57)',
    mainMenuIconColor: 'rgb(255, 255, 255)',
    mainMenuTextColor: 'rgb(255, 255, 255)',
    black: 'rgb(61, 61, 61)',
    backgroundHead: 'rgb(83, 67, 57)',
    datePickerBgColor: 'rgb(83, 67, 57)',
    accent: 'rgb(121, 99, 82)',
    modelSettingsCheckedColor: theme.colors.green,
  },
  mainMenuItems: [
    {
      routeName: 'Dashboard',
      icon: DashboardSvg,
      disabled: true,
    },
    {
      routeName: 'Notifications',
      icon: NotificationListSvg,
      disabled: true,
    },
    {
      routeName: 'Batches',
      icon: BatchListSvg,
    },
    {
      routeName: 'Documents',
      icon: CertificateSvg,
    },
  ],
  timeFormats: ['MM/DD/YYYY hh:mm:ss', 'MM-DD-YY', 'YYYY-DD-MM', 'MM/DD/YYYY'],
};
