import { BatchRecord } from 'lib/lib';

export default [
  {
    key: 'ProducerProcessBatch_floId',
    label: 'Fyffes FLO-ID',
    title: 'Fyffes FLO-ID',
    style: { flex: '1 0 60px' },
    defaultSelectedColumn: true,
    render: (column: BatchRecord) => column.ProducerProcessBatch_floId || 'null',
    renderExportValue: (column: BatchRecord) => column.ProducerProcessBatch_floId || 'null',
  },
  {
    key: 'ResellerInBatch_colombiaSSCCPallet',
    label: 'Colombia SSCCPallet (Banafood IN)',
    title: 'Colombia SSCCPallet (Banafood IN)',
    style: { flex: '1 0 80px' },
    defaultSelectedColumn: true,
    render: (column: BatchRecord) => column.ResellerInBatch_colombiaSSCCPallet || 'null',
    renderExportValue: (column: BatchRecord) => column.ResellerInBatch_colombiaSSCCPallet || 'null',
  },
  {
    key: 'ResellerOutBatch_antwerpSSCC',
    label: 'AntwerpSSCC (Banafood OUT)',
    title: 'AntwerpSSCC (Banafood OUT)',
    style: { flex: '1 0 80px' },
    defaultSelectedColumn: true,
    render: (column: BatchRecord) => column.ResellerOutBatch_antwerpSSCC || 'null',
    renderExportValue: (column: BatchRecord) => column.ResellerOutBatch_antwerpSSCC || 'null',
  },
  {
    key: 'ResellerOutBatch_banafoodSSCCPallet',
    label: 'Banafood SSCCPallet (Banafood OUT)',
    title: 'Banafood SSCCPallet (Banafood OUT)',
    style: { flex: '1 0 80px' },
    defaultSelectedColumn: true,
    render: (column: BatchRecord) => column.ResellerOutBatch_banafoodSSCCPallet || 'null',
    renderExportValue: (column: BatchRecord) =>
      column.ResellerOutBatch_banafoodSSCCPallet || 'null',
  },
];
