import React from 'react';
import { Link } from 'react-router-dom';
import { getUrl } from 'routes';
import indexBaseModelFields from 'models/indexBaseModelFields';
import {CHANNELS, ORGANIZATIONS} from 'themes/constants';
//
import { WarningSvg } from 'components/icons';

import { renderShortDateFormat } from 'lib/renderHelpers';
import { BatchRecord, ModelRequiredInfo } from 'lib/lib';
// label defines what field to take from CSV batch file for upload

export default (modelRequiredInfo: ModelRequiredInfo) => [
  {
    key: 'lotFinishedProduct',
    label: 'Lot_Finished_Product',
    title: 'Lot Finished Product',
    style: { flex: '0 0 150px' },
    defaultSelectedColumn: true,
    defaultSelectedBatchContractColumn: true,
    defaultPreviewBeforeUploadColumn: true,
    render: (column: BatchRecord) => (
      <Link
        className="contract"
        to={{
          pathname: getUrl('BatchContractType', {
            type: ORGANIZATIONS.REFRESCO.toLowerCase(),
            contract_number: `${column.lotFinishedProduct}`,
            modelType: 'contractDetails',
            channelName: CHANNELS.ORANGES,
          }),
        }}
      >
        {column.lotFinishedProduct}
      </Link>
    ),
  },
  {
    key: 'articleNbrFinishedProduct',
    label: 'ArticleNbr_Finished_Product',
    title: 'Article Nbr Finished Product',
    style: { flex: '1 0 50px' },
    render: (column: BatchRecord) => column.articleNbrFinishedProduct,
  },
  {
    key: 'descriptionFinishedProduct',
    label: 'Description_Finished_Product',
    title: 'Description Finished Product',
    style: { flex: '0 0 270px' },
    defaultSelectedColumn: true,
    defaultSelectedBatchContractColumn: true,
    defaultPreviewBeforeUploadColumn: true,
    render: (column: BatchRecord) => column.descriptionFinishedProduct,
  },
  {
    key: 'qtyProducedPcsFinishedProduct',
    label: 'QtyProduced_PCS_Finished_Product',
    title: 'QtyProduced PCS Finished Product',
    style: { flex: '1 0 100px' },
    defaultSelectedColumn: true,
    defaultSelectedBatchContractColumn: true,
    defaultPreviewBeforeUploadColumn: true,
    render: (column: BatchRecord) => column.qtyProducedPcsFinishedProduct,
  },
  {
    key: 'productionFinishedFinishedProduct',
    label: 'ProductionFinished_Finished_Product',
    title: 'ProductionFinished Finished Product',
    style: { flex: '1 0 100px' },
    defaultSelectedColumn: true,
    defaultSelectedBatchContractColumn: true,
    defaultPreviewBeforeUploadColumn: true,
    edit: { type: 'date' },
    render: (column: BatchRecord) =>
      renderShortDateFormat(column.productionFinishedFinishedProduct),
  },
  {
    key: 'lotFcoj',
    label: 'Lot_FCOJ',
    title: 'Lot FCOJ',
    style: { flex: '1 0 100px' },
    render: (column: BatchRecord) => (
      <Link
        className="contract"
        to={{
          pathname: getUrl('BatchContractType', {
            type: ORGANIZATIONS.JDE.toLowerCase(),
            contract_number: column.lotFcoj,
            modelType: 'contractDetails',
            channelName: CHANNELS.ORANGES,
          }),
        }}
      >
        {column.lotFcoj}
      </Link>
    ),
    // renderBatchesContract: (column) => (
    //   <React.Fragment>
    //     <Link
    //       className="contract"
    //       to={{
    //         pathname: getUrl('BatchContractType', {
    //           type: ORGANIZATIONS.JDE.toLowerCase(),
    //           contract_number: column.lotFcoj,
    //           modelType: 'contractDetails',
    //         }),
    //       }}
    //     >
    //       {column.lotFcoj}
    //     </Link>
    //     {column.subcontractAmount === 0 && (
    //       <span className="warning">
    //         <WarningSvg />
    //         {/*<Link*/}
    //         {/*  to={{*/}
    //         {/*    pathname: getUrl('RequestAdd'),*/}
    //         {/*    state: {*/}
    //         {/*      batch: {*/}
    //         {/*        requestNumber:*/}
    //         {/*          column[batchesHierarchyByOrgname[ORGANIZATIONS.REFRESCO].subcontract],*/}
    //         {/*        toOrg: ORGANIZATIONS.JDE,*/}
    //         {/*      },*/}
    //         {/*    },*/}
    //         {/*  }}*/}
    //         {/*>*/}
    //         {/*  <WarningSvg />*/}
    //         {/*</Link>*/}
    //       </span>
    //     )}
    //   </React.Fragment>
    // ),
  },
  {
    key: 'articleNbrFcoj',
    label: 'ArticleNbr_FCOJ',
    title: 'ArticleNbr FCOJ',
    style: { flex: '1 0 50px' },
    render: (column: BatchRecord) => column.articleNbrFcoj,
  },
  {
    key: 'dateCheckFcoj',
    label: 'Date_check_FCOJ',
    title: 'Date check FCOJ',
    style: { flex: '1 0 50px' },
    edit: { type: 'date' },
    render: (column: BatchRecord) => renderShortDateFormat(column.dateCheckFcoj),
  },
  {
    key: 'certificateOfConformityFcoj',
    label: 'Certificate_of_conformity_FCOJ',
    title: 'Certificate of conformity FCOJ',
    style: { flex: '1 0 50px' },
    render: (column: BatchRecord) => column.certificateOfConformityFcoj,
  },
  {
    key: 'organolepticCheckFcoj',
    label: 'Organoleptic_Check_FCOJ',
    title: 'Organoleptic Check FCOJ',
    style: { flex: '1 0 50px' },
    render: (column: BatchRecord) => column.organolepticCheckFcoj,
  },
  {
    key: 'ratioFcoj',
    label: 'Ratio_FCOJ',
    title: 'Ratio FCOJ',
    style: { flex: '1 0 50px' },
    edit: { type: 'date' },
    render: (column: BatchRecord) => renderShortDateFormat(column.ratioFcoj),
  },
  {
    key: 'organolepticCheckFinishedProduct',
    label: 'Organoleptic_Check_Finished_Product',
    title: 'Organoleptic Check Finished Product',
    style: { flex: '1 0 50px' },
    render: (column: BatchRecord) => column.organolepticCheckFinishedProduct,
  },
  {
    key: 'vitaminCFinishedProduct',
    label: 'Vitamin_C_Finished_Product',
    title: 'Vitamin C Finished Product',
    style: { flex: '1 0 50px' },
    render: (column: BatchRecord) => column.vitaminCFinishedProduct,
  },
  {
    key: 'ratioFinishedProduct',
    label: 'Ratio_Finished_Product',
    title: 'Ratio Finished Product',
    style: { flex: '1 0 50px' },
    render: (column: BatchRecord) => column.ratioFinishedProduct,
  },
  {
    key: 'dateDeliveryAhFert',
    label: 'Date_Delivery_AH_FERT',
    title: 'Date Delivery AH FERT',
    style: { flex: '1 0 50px' },
    edit: { type: 'date' },
    render: (column: BatchRecord) => renderShortDateFormat(column.dateDeliveryAhFert),
  },
  ...indexBaseModelFields(modelRequiredInfo),
  {
    key: 'simStatus',
    title: 'SIM',
    style: { flex: '1 1 100px' },
    render: (column: BatchRecord) => column.status,
    defaultSelectedColumn: true,
  },
];
