export const formUserById = (id) => ({
  resource: {
    username: null,
    email: null,
    participant: id.replace('/api/v1/participants/', ''),
    org: { name: 'Hollander', fullname: 'Hollander', logo: null },
    channel: { title: 'Bananas', name: 'bananas' },
    user: {
      username: 'Hollander admin',
      email: 'HollanderADMIN@powerchain.nl',
      org: { name: 'Hollander', fullname: 'Hollander', logo: null },
    },
  },
});
