import farmData from 'models/citrosucoBrazil/tabs/farmData';
import { ModelRequiredInfo } from 'lib/lib';

export default (modelRequiredInfo: ModelRequiredInfo) => ({
  tabs: [
    {
      modelName: 'farmData',
      originalName: 'Brazil Farm Data SIM',
      title: 'Brazil Farm Data SIM',
    },
  ],
  farmData: farmData(modelRequiredInfo),
});
