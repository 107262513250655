// helpers
import { renderShortDateFormat } from 'lib/renderHelpers';
// config
import { validationRequired, validationInt, validationString } from 'lib/uploadFieldValidations';
import { BatchRecord } from 'lib/lib';

export default () => {
  return [
    {
      key: 'banafoodSSCCPallet',
      label: 'Banafood SSCC pallet #',
      title: 'Banafood SSCC pallet #',
      style: { flex: '1 0 180px' },
      renderUploadPreview: (column: BatchRecord) => column.banafoodSSCCPallet,
      render: (column: BatchRecord) => column.banafoodSSCCPallet,
      validate: [validationRequired, validationInt],
      defaultSelectedColumn: true,
      defaultSelectedBatchContractColumn: true,
    },
    {
      key: 'deliveryDate',
      label: 'Date delivery',
      title: 'Date delivery',
      style: { flex: '1 0 180px' },
      renderUploadPreview: (column: BatchRecord) => renderShortDateFormat(column.deliveryDate),
      render: (column: BatchRecord) => renderShortDateFormat(column.deliveryDate),
      validate: [validationRequired, validationString],
      defaultSelectedColumn: true,
      defaultSelectedBatchContractColumn: true,
    },
    {
      key: 'boxes',
      label: '# boxes',
      title: '# boxes',
      style: { flex: '1 0 180px' },
      renderUploadPreview: (column: BatchRecord) => column.boxes,
      render: (column: BatchRecord) => column.boxes,
      validate: [validationRequired, validationInt],
      defaultSelectedColumn: true,
      defaultSelectedBatchContractColumn: true,
    },
    {
      key: 'product',
      label: 'Product #',
      title: 'Product #',
      style: { flex: '1 0 180px' },
      renderUploadPreview: (column: BatchRecord) => column.product,
      render: (column: BatchRecord) => column.product,
      validate: [validationRequired, validationInt],
      defaultSelectedColumn: true,
      defaultSelectedBatchContractColumn: true,
    },
    {
      key: 'productDescription',
      label: 'Product description',
      title: 'Product description',
      style: { flex: '1 0 180px' },
      renderUploadPreview: (column: BatchRecord) => column.productDescription,
      render: (column: BatchRecord) => column.productDescription,
      validate: [validationRequired, validationString],
      defaultSelectedColumn: true,
      defaultSelectedBatchContractColumn: true,
    },
    {
      key: 'antwerpSSCC',
      label: 'Antwerp SSCC Fyffes OUT',
      title: 'Antwerp SSCC Fyffes OUT',
      style: { flex: '1 0 180px' },
      renderUploadPreview: (column: BatchRecord) => column.antwerpSSCC,
      render: (column: BatchRecord) => column.antwerpSSCC,
      validate: [validationRequired, validationInt],
      defaultSelectedColumn: true,
      defaultSelectedBatchContractColumn: true,
    },
  ];
};
