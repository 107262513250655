export default () => ({
  $class: 'org.powerchain.tilapia.batch.producer.ProducerBatchReadConcept',
  detailList: [
    {
      $class: 'org.powerchain.tilapia.batch.producer.ProducerDetailBatchReadConcept',
      batchCode: 'A111 E118',
      bbd: '2019-03-25T00:00:00.000Z',
      product: '2FGAMAC1133AAE0001, Frozen Tilapia Fillet SDS IVP 180-200gr (10.00Kg) Kaiousei',
      cases: 12,
      volume: 120,
      shippingDate: '2019-03-28T00:00:00.000Z',
      containerNumber: 'CGMU5291410',
      storageDateFrom: '2019-03-22T00:00:00.000Z',
      storageDateTo: '2019-03-28T00:00:00.000Z',
      packingDate: '2019-03-22T00:00:00.000Z',
      freezingDate: '2019-03-22T00:00:00.000Z',
      processingDate: '2019-03-22T00:00:00.000Z',
      receivingDate: '2019-03-22T00:00:00.000Z',
      harvestingDate: '2019-03-22T00:00:00.000Z',
      farmSite: 'NGB',
      cageId: 'NGBCC034',
      batchCodeId: 'A111 E118',
      batchId: '1b353b17-a505-41a8-9c9d-4845427a9daa',
      createdAt: '2019-08-22T09:27:00.949Z',
      createdBy:
        'resource:org.powerchain.tilapia.member.ProducerUser#RegalSprings_8900eb90-c29b-458a-aa40-301057d9b81f',
      status: 'PENDING',
      uploadSource: 'frontEndApp',
      "modifiedBy_participant":{
        "username":null,
        "email":null,
        "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        },
        "channel":{
          "title":"Bananas",
          "name":"bananas"
        },
        "user":{
          "username":"Fyffes TST staging",
          "email":"FyffesTST@pc.t2f.io",
          "org":{
            "name":"Fyffes",
            "fullname":"Fyffes",
            "logo":null
          }
        }
      },
      "createdBy_participant":{
        "username":null,
        "email":null,
        "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        },
        "channel":{
          "title":"Bananas",
          "name":"bananas"
        },
        "user":{
          "username":"Fyffes TST staging",
          "email":"FyffesTST@pc.t2f.io",
          "org":{
            "name":"Fyffes",
            "fullname":"Fyffes",
            "logo":null
          }
        }
      }
    },
    {
      $class: 'org.powerchain.tilapia.batch.producer.ProducerDetailBatchReadConcept',
      batchCode: 'A111 E118',
      bbd: '2019-03-25T00:00:00.000Z',
      product: '2FGAMAC1133AAE0001, Frozen Tilapia Fillet SDS IVP 180-200gr (10.00Kg) Kaiousei',
      cases: 12,
      volume: 120,
      shippingDate: '2019-03-28T00:00:00.000Z',
      containerNumber: 'CGMU5291410',
      storageDateFrom: '2019-03-22T00:00:00.000Z',
      storageDateTo: '2019-03-28T00:00:00.000Z',
      packingDate: '2019-03-22T00:00:00.000Z',
      freezingDate: '2019-03-22T00:00:00.000Z',
      processingDate: '2019-03-22T00:00:00.000Z',
      receivingDate: '2019-03-22T00:00:00.000Z',
      harvestingDate: '2019-03-22T00:00:00.000Z',
      farmSite: 'NGB',
      cageId: 'NGBCC034',
      batchCodeId: 'A111 E118',
      batchId: 'c5efb00a-8483-47c5-9283-402193939862',
      createdAt: '2019-08-22T09:27:00.949Z',
      createdBy:
        'resource:org.powerchain.tilapia.member.ProducerUser#RegalSprings_8900eb90-c29b-458a-aa40-301057d9b81f',
      status: 'PENDING',
      uploadSource: 'frontEndApp',
      "modifiedBy_participant":{
        "username":null,
        "email":null,
        "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        },
        "channel":{
          "title":"Bananas",
          "name":"bananas"
        },
        "user":{
          "username":"Fyffes TST staging",
          "email":"FyffesTST@pc.t2f.io",
          "org":{
            "name":"Fyffes",
            "fullname":"Fyffes",
            "logo":null
          }
        }
      },
      "createdBy_participant":{
        "username":null,
        "email":null,
        "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        },
        "channel":{
          "title":"Bananas",
          "name":"bananas"
        },
        "user":{
          "username":"Fyffes TST staging",
          "email":"FyffesTST@pc.t2f.io",
          "org":{
            "name":"Fyffes",
            "fullname":"Fyffes",
            "logo":null
          }
        }
      }
    },
  ],
  exportList: [
    {
      $class: 'org.powerchain.tilapia.batch.producer.ProducerExportBatchReadConcept',
      shippingDate: '2019-03-22T00:00:00.000Z',
      containerNumber: 'SZLU9159700',
      doNumber: '21260319',
      poNumber: '20180181',
      temperatureLog: '1000915440',
      sealNumber: 'FJ03298128',
      packingLot: 'A111',
      productionLot: 'E118',
      customerNumber: 'RF',
      contractNumber: '20180061',
      customer: 'JMB',
      totalMc: 23.45,
      bbd: '2021-10-02T00:00:00.000Z',
      batchCodeId: 'A111 E118',
      batchId: '4412adc8-3314-478f-9bf3-4abba1c6cad7',
      createdAt: '2019-08-22T09:27:00.949Z',
      createdBy:
        'resource:org.powerchain.tilapia.member.ProducerUser#RegalSprings_8900eb90-c29b-458a-aa40-301057d9b81f',
      status: 'PENDING',
      uploadSource: 'frontEndApp',
      "modifiedBy_participant":{
        "username":null,
        "email":null,
        "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        },
        "channel":{
          "title":"Bananas",
          "name":"bananas"
        },
        "user":{
          "username":"Fyffes TST staging",
          "email":"FyffesTST@pc.t2f.io",
          "org":{
            "name":"Fyffes",
            "fullname":"Fyffes",
            "logo":null
          }
        }
      },
      "createdBy_participant":{
        "username":null,
        "email":null,
        "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        },
        "channel":{
          "title":"Bananas",
          "name":"bananas"
        },
        "user":{
          "username":"Fyffes TST staging",
          "email":"FyffesTST@pc.t2f.io",
          "org":{
            "name":"Fyffes",
            "fullname":"Fyffes",
            "logo":null
          }
        }
      }
    },
    {
      $class: 'org.powerchain.tilapia.batch.producer.ProducerExportBatchReadConcept',
      shippingDate: '2019-03-22T00:00:00.000Z',
      containerNumber: 'TLLU1110035',
      doNumber: '21200319',
      poNumber: '201900126',
      temperatureLog: '1000915432',
      sealNumber: 'ID108028A',
      packingLot: 'A111',
      productionLot: 'E118',
      customerNumber: 'JN',
      bbd: '2021-11-02T00:00:00.000Z',
      batchCodeId: 'A111 E118',
      batchId: '93d5dfbe-c2ee-4b0d-9f7e-29f8a129de24',
      createdAt: '2019-08-22T09:27:00.949Z',
      createdBy:
        'resource:org.powerchain.tilapia.member.ProducerUser#RegalSprings_8900eb90-c29b-458a-aa40-301057d9b81f',
      status: 'PENDING',
      uploadSource: 'frontEndApp',
      "modifiedBy_participant":{
        "username":null,
        "email":null,
        "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        },
        "channel":{
          "title":"Bananas",
          "name":"bananas"
        },
        "user":{
          "username":"Fyffes TST staging",
          "email":"FyffesTST@pc.t2f.io",
          "org":{
            "name":"Fyffes",
            "fullname":"Fyffes",
            "logo":null
          }
        }
      },
      "createdBy_participant":{
        "username":null,
        "email":null,
        "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        },
        "channel":{
          "title":"Bananas",
          "name":"bananas"
        },
        "user":{
          "username":"Fyffes TST staging",
          "email":"FyffesTST@pc.t2f.io",
          "org":{
            "name":"Fyffes",
            "fullname":"Fyffes",
            "logo":null
          }
        }
      }
    },
    {
      $class: 'org.powerchain.tilapia.batch.producer.ProducerExportBatchReadConcept',
      shippingDate: '2019-03-22T00:00:00.000Z',
      containerNumber: 'TLLU1110035',
      doNumber: '21200319',
      poNumber: '201900126',
      temperatureLog: '1000915432',
      sealNumber: 'ID108028A',
      packingLot: 'A111',
      productionLot: 'E118',
      customerNumber: 'JN',
      bbd: '2021-11-02T00:00:00.000Z',
      batchCodeId: 'A111 E118',
      batchId: 'fdfc573e-f541-4a8d-bed4-21c82815a1e6',
      createdAt: '2019-08-22T09:27:00.949Z',
      createdBy:
        'resource:org.powerchain.tilapia.member.ProducerUser#RegalSprings_8900eb90-c29b-458a-aa40-301057d9b81f',
      status: 'PENDING',
      uploadSource: 'frontEndApp',
      "modifiedBy_participant":{
        "username":null,
        "email":null,
        "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        },
        "channel":{
          "title":"Bananas",
          "name":"bananas"
        },
        "user":{
          "username":"Fyffes TST staging",
          "email":"FyffesTST@pc.t2f.io",
          "org":{
            "name":"Fyffes",
            "fullname":"Fyffes",
            "logo":null
          }
        }
      },
      "createdBy_participant":{
        "username":null,
        "email":null,
        "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        },
        "channel":{
          "title":"Bananas",
          "name":"bananas"
        },
        "user":{
          "username":"Fyffes TST staging",
          "email":"FyffesTST@pc.t2f.io",
          "org":{
            "name":"Fyffes",
            "fullname":"Fyffes",
            "logo":null
          }
        }
      }
    },
  ],
  packingList: [
    {
      $class: 'org.powerchain.tilapia.batch.producer.ProducerPackingReadItemConcept',
      packingDate: '2019-03-22T00:00:00.000Z',
      packingLot: 'A111',
      productionLot: 'E118',
      gisfBatch: 'NGB.20190322.F',
      fgCode: '2FGAFAE1106CAB0006',
      fgDescription: 'Frozen Tilapia Fillet DSNDS Bulk 7-9oz Regular Grade (12.50Kg) LM',
      quantityInMc: 5,
      "modifiedBy_participant":{
        "username":null,
        "email":null,
        "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        },
        "channel":{
          "title":"Bananas",
          "name":"bananas"
        },
        "user":{
          "username":"Fyffes TST staging",
          "email":"FyffesTST@pc.t2f.io",
          "org":{
            "name":"Fyffes",
            "fullname":"Fyffes",
            "logo":null
          }
        }
      },
      "createdBy_participant":{
        "username":null,
        "email":null,
        "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        },
        "channel":{
          "title":"Bananas",
          "name":"bananas"
        },
        "user":{
          "username":"Fyffes TST staging",
          "email":"FyffesTST@pc.t2f.io",
          "org":{
            "name":"Fyffes",
            "fullname":"Fyffes",
            "logo":null
          }
        }
      }
    },
    {
      $class: 'org.powerchain.tilapia.batch.producer.ProducerPackingReadItemConcept',
      packingDate: '2019-03-22T00:00:00.000Z',
      packingLot: 'A111',
      productionLot: 'E118',
      gisfBatch: 'NGB.20190322.E',
      fgCode: '2FGAFAE1104BAB0001',
      fgDescription:
        'Frozen Tilapia BP WM Bulk Protection Glazing 1oz-UP  Premium Grade (12.50Kg) LM',
      quantityInMc: 9,
      "modifiedBy_participant":{
        "username":null,
        "email":null,
        "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        },
        "channel":{
          "title":"Bananas",
          "name":"bananas"
        },
        "user":{
          "username":"Fyffes TST staging",
          "email":"FyffesTST@pc.t2f.io",
          "org":{
            "name":"Fyffes",
            "fullname":"Fyffes",
            "logo":null
          }
        }
      },
      "createdBy_participant":{
        "username":null,
        "email":null,
        "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        },
        "channel":{
          "title":"Bananas",
          "name":"bananas"
        },
        "user":{
          "username":"Fyffes TST staging",
          "email":"FyffesTST@pc.t2f.io",
          "org":{
            "name":"Fyffes",
            "fullname":"Fyffes",
            "logo":null
          }
        }
      }
    },
    {
      $class: 'org.powerchain.tilapia.batch.producer.ProducerPackingReadItemConcept',
      packingDate: '2019-03-22T00:00:00.000Z',
      packingLot: 'A111',
      productionLot: 'E118',
      gisfBatch: 'NGB.20190322.E',
      fgCode: '2FGAFAE1106CAB0004',
      fgDescription: 'Frozen Tilapia Fillet DSNDS Bulk 3-5oz Regular Grade (12.50Kg) LM',
      quantityInMc: 15,
      "modifiedBy_participant":{
        "username":null,
        "email":null,
        "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        },
        "channel":{
          "title":"Bananas",
          "name":"bananas"
        },
        "user":{
          "username":"Fyffes TST staging",
          "email":"FyffesTST@pc.t2f.io",
          "org":{
            "name":"Fyffes",
            "fullname":"Fyffes",
            "logo":null
          }
        }
      },
      "createdBy_participant":{
        "username":null,
        "email":null,
        "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        },
        "channel":{
          "title":"Bananas",
          "name":"bananas"
        },
        "user":{
          "username":"Fyffes TST staging",
          "email":"FyffesTST@pc.t2f.io",
          "org":{
            "name":"Fyffes",
            "fullname":"Fyffes",
            "logo":null
          }
        }
      }
    },
  ],
  processingList: [
    {
      $class: 'org.powerchain.tilapia.batch.producer.ProducerProcessingReadItemConcept',
      processingDate: '2019-03-22T00:00:00.000Z',
      gisfBatch: 'NGB.20190322.F',
      fgCode: '2FGAFAE1106CAB0004',
      quantityInKg: 12.5,
      "modifiedBy_participant":{
        "username":null,
        "email":null,
        "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        },
        "channel":{
          "title":"Bananas",
          "name":"bananas"
        },
        "user":{
          "username":"Fyffes TST staging",
          "email":"FyffesTST@pc.t2f.io",
          "org":{
            "name":"Fyffes",
            "fullname":"Fyffes",
            "logo":null
          }
        }
      },
      "createdBy_participant":{
        "username":null,
        "email":null,
        "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        },
        "channel":{
          "title":"Bananas",
          "name":"bananas"
        },
        "user":{
          "username":"Fyffes TST staging",
          "email":"FyffesTST@pc.t2f.io",
          "org":{
            "name":"Fyffes",
            "fullname":"Fyffes",
            "logo":null
          }
        }
      }
    },
    {
      $class: 'org.powerchain.tilapia.batch.producer.ProducerProcessingReadItemConcept',
      processingDate: '2019-03-22T00:00:00.000Z',
      gisfBatch: 'NGB.20190322.E',
      fgCode: '2FGDZAG1105AAB0002',
      quantityInKg: 175,
      "modifiedBy_participant":{
        "username":null,
        "email":null,
        "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        },
        "channel":{
          "title":"Bananas",
          "name":"bananas"
        },
        "user":{
          "username":"Fyffes TST staging",
          "email":"FyffesTST@pc.t2f.io",
          "org":{
            "name":"Fyffes",
            "fullname":"Fyffes",
            "logo":null
          }
        }
      },
      "createdBy_participant":{
        "username":null,
        "email":null,
        "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        },
        "channel":{
          "title":"Bananas",
          "name":"bananas"
        },
        "user":{
          "username":"Fyffes TST staging",
          "email":"FyffesTST@pc.t2f.io",
          "org":{
            "name":"Fyffes",
            "fullname":"Fyffes",
            "logo":null
          }
        }
      }
    },
    {
      $class: 'org.powerchain.tilapia.batch.producer.ProducerProcessingReadItemConcept',
      processingDate: '2019-03-22T00:00:00.000Z',
      gisfBatch: 'NGB.20190322.F',
      fgCode: '2FGAFAE1106CAB0006',
      quantityInKg: 62.5,
      "modifiedBy_participant":{
        "username":null,
        "email":null,
        "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        },
        "channel":{
          "title":"Bananas",
          "name":"bananas"
        },
        "user":{
          "username":"Fyffes TST staging",
          "email":"FyffesTST@pc.t2f.io",
          "org":{
            "name":"Fyffes",
            "fullname":"Fyffes",
            "logo":null
          }
        }
      },
      "createdBy_participant":{
        "username":null,
        "email":null,
        "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        },
        "channel":{
          "title":"Bananas",
          "name":"bananas"
        },
        "user":{
          "username":"Fyffes TST staging",
          "email":"FyffesTST@pc.t2f.io",
          "org":{
            "name":"Fyffes",
            "fullname":"Fyffes",
            "logo":null
          }
        }
      }
    },
    {
      $class: 'org.powerchain.tilapia.batch.producer.ProducerProcessingReadItemConcept',
      processingDate: '2019-03-22T00:00:00.000Z',
      gisfBatch: 'NGB.20190322.E',
      fgCode: '2FGAFAE1104BAB0001',
      quantityInKg: 112.5,
      "modifiedBy_participant":{
        "username":null,
        "email":null,
        "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        },
        "channel":{
          "title":"Bananas",
          "name":"bananas"
        },
        "user":{
          "username":"Fyffes TST staging",
          "email":"FyffesTST@pc.t2f.io",
          "org":{
            "name":"Fyffes",
            "fullname":"Fyffes",
            "logo":null
          }
        }
      },
      "createdBy_participant":{
        "username":null,
        "email":null,
        "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        },
        "channel":{
          "title":"Bananas",
          "name":"bananas"
        },
        "user":{
          "username":"Fyffes TST staging",
          "email":"FyffesTST@pc.t2f.io",
          "org":{
            "name":"Fyffes",
            "fullname":"Fyffes",
            "logo":null
          }
        }
      }
    },
    {
      $class: 'org.powerchain.tilapia.batch.producer.ProducerProcessingReadItemConcept',
      processingDate: '2019-03-22T00:00:00.000Z',
      gisfBatch: 'NGB.20190322.E',
      fgCode: '2FGAFAE1106CAB0004',
      quantityInKg: 187.5,
      "modifiedBy_participant":{
        "username":null,
        "email":null,
        "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        },
        "channel":{
          "title":"Bananas",
          "name":"bananas"
        },
        "user":{
          "username":"Fyffes TST staging",
          "email":"FyffesTST@pc.t2f.io",
          "org":{
            "name":"Fyffes",
            "fullname":"Fyffes",
            "logo":null
          }
        }
      },
      "createdBy_participant":{
        "username":null,
        "email":null,
        "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        },
        "channel":{
          "title":"Bananas",
          "name":"bananas"
        },
        "user":{
          "username":"Fyffes TST staging",
          "email":"FyffesTST@pc.t2f.io",
          "org":{
            "name":"Fyffes",
            "fullname":"Fyffes",
            "logo":null
          }
        }
      }
    },
    {
      $class: 'org.powerchain.tilapia.batch.producer.ProducerProcessingReadItemConcept',
      processingDate: '2019-03-22T00:00:00.000Z',
      gisfBatch: 'NGB.20190322.E',
      fgCode: '2FGBMAG1107ACZ0001',
      quantityInKg: 31.9334,
      "modifiedBy_participant":{
        "username":null,
        "email":null,
        "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        },
        "channel":{
          "title":"Bananas",
          "name":"bananas"
        },
        "user":{
          "username":"Fyffes TST staging",
          "email":"FyffesTST@pc.t2f.io",
          "org":{
            "name":"Fyffes",
            "fullname":"Fyffes",
            "logo":null
          }
        }
      },
      "createdBy_participant":{
        "username":null,
        "email":null,
        "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        },
        "channel":{
          "title":"Bananas",
          "name":"bananas"
        },
        "user":{
          "username":"Fyffes TST staging",
          "email":"FyffesTST@pc.t2f.io",
          "org":{
            "name":"Fyffes",
            "fullname":"Fyffes",
            "logo":null
          }
        }
      }
    },
    {
      $class: 'org.powerchain.tilapia.batch.producer.ProducerProcessingReadItemConcept',
      processingDate: '2019-03-22T00:00:00.000Z',
      gisfBatch: 'NGB.20190322.E',
      fgCode: '2FGBNAG1104ACE0001',
      quantityInKg: 412.5,
      "modifiedBy_participant":{
        "username":null,
        "email":null,
        "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        },
        "channel":{
          "title":"Bananas",
          "name":"bananas"
        },
        "user":{
          "username":"Fyffes TST staging",
          "email":"FyffesTST@pc.t2f.io",
          "org":{
            "name":"Fyffes",
            "fullname":"Fyffes",
            "logo":null
          }
        }
      },
      "createdBy_participant":{
        "username":null,
        "email":null,
        "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        },
        "channel":{
          "title":"Bananas",
          "name":"bananas"
        },
        "user":{
          "username":"Fyffes TST staging",
          "email":"FyffesTST@pc.t2f.io",
          "org":{
            "name":"Fyffes",
            "fullname":"Fyffes",
            "logo":null
          }
        }
      }
    },
    {
      $class: 'org.powerchain.tilapia.batch.producer.ProducerProcessingReadItemConcept',
      processingDate: '2019-03-22T00:00:00.000Z',
      gisfBatch: 'NGB.20190322.E',
      fgCode: '2FGBPAG1102AAB0001',
      quantityInKg: 215.46,
      "modifiedBy_participant":{
        "username":null,
        "email":null,
        "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        },
        "channel":{
          "title":"Bananas",
          "name":"bananas"
        },
        "user":{
          "username":"Fyffes TST staging",
          "email":"FyffesTST@pc.t2f.io",
          "org":{
            "name":"Fyffes",
            "fullname":"Fyffes",
            "logo":null
          }
        }
      },
      "createdBy_participant":{
        "username":null,
        "email":null,
        "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        },
        "channel":{
          "title":"Bananas",
          "name":"bananas"
        },
        "user":{
          "username":"Fyffes TST staging",
          "email":"FyffesTST@pc.t2f.io",
          "org":{
            "name":"Fyffes",
            "fullname":"Fyffes",
            "logo":null
          }
        }
      }
    },
    {
      $class: 'org.powerchain.tilapia.batch.producer.ProducerProcessingReadItemConcept',
      processingDate: '2019-03-22T00:00:00.000Z',
      gisfBatch: 'NGB.20190322.F',
      fgCode: '2FGAFAE1106CAB0005',
      quantityInKg: 37.5,
      "modifiedBy_participant":{
        "username":null,
        "email":null,
        "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        },
        "channel":{
          "title":"Bananas",
          "name":"bananas"
        },
        "user":{
          "username":"Fyffes TST staging",
          "email":"FyffesTST@pc.t2f.io",
          "org":{
            "name":"Fyffes",
            "fullname":"Fyffes",
            "logo":null
          }
        }
      },
      "createdBy_participant":{
        "username":null,
        "email":null,
        "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        },
        "channel":{
          "title":"Bananas",
          "name":"bananas"
        },
        "user":{
          "username":"Fyffes TST staging",
          "email":"FyffesTST@pc.t2f.io",
          "org":{
            "name":"Fyffes",
            "fullname":"Fyffes",
            "logo":null
          }
        }
      }
    },
    {
      $class: 'org.powerchain.tilapia.batch.producer.ProducerProcessingReadItemConcept',
      processingDate: '2019-03-22T00:00:00.000Z',
      gisfBatch: 'NGB.20190322.E',
      fgCode: '2FGDNAG1105AAI0001 ',
      quantityInKg: 678,
      "modifiedBy_participant":{
        "username":null,
        "email":null,
        "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        },
        "channel":{
          "title":"Bananas",
          "name":"bananas"
        },
        "user":{
          "username":"Fyffes TST staging",
          "email":"FyffesTST@pc.t2f.io",
          "org":{
            "name":"Fyffes",
            "fullname":"Fyffes",
            "logo":null
          }
        }
      },
      "createdBy_participant":{
        "username":null,
        "email":null,
        "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        },
        "channel":{
          "title":"Bananas",
          "name":"bananas"
        },
        "user":{
          "username":"Fyffes TST staging",
          "email":"FyffesTST@pc.t2f.io",
          "org":{
            "name":"Fyffes",
            "fullname":"Fyffes",
            "logo":null
          }
        }
      }
    },
    {
      $class: 'org.powerchain.tilapia.batch.producer.ProducerProcessingReadItemConcept',
      processingDate: '2019-03-22T00:00:00.000Z',
      gisfBatch: 'NGB.20190322.E',
      fgCode: '2FGDRAG1113AAB0001',
      quantityInKg: 20,
      "modifiedBy_participant":{
        "username":null,
        "email":null,
        "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        },
        "channel":{
          "title":"Bananas",
          "name":"bananas"
        },
        "user":{
          "username":"Fyffes TST staging",
          "email":"FyffesTST@pc.t2f.io",
          "org":{
            "name":"Fyffes",
            "fullname":"Fyffes",
            "logo":null
          }
        }
      },
      "createdBy_participant":{
        "username":null,
        "email":null,
        "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        },
        "channel":{
          "title":"Bananas",
          "name":"bananas"
        },
        "user":{
          "username":"Fyffes TST staging",
          "email":"FyffesTST@pc.t2f.io",
          "org":{
            "name":"Fyffes",
            "fullname":"Fyffes",
            "logo":null
          }
        }
      }
    },
    {
      $class: 'org.powerchain.tilapia.batch.producer.ProducerProcessingReadItemConcept',
      processingDate: '2019-03-22T00:00:00.000Z',
      gisfBatch: 'NGB.20190322.E',
      fgCode: '2FGDRAG1113AAB0007',
      quantityInKg: 120,
      "modifiedBy_participant":{
        "username":null,
        "email":null,
        "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        },
        "channel":{
          "title":"Bananas",
          "name":"bananas"
        },
        "user":{
          "username":"Fyffes TST staging",
          "email":"FyffesTST@pc.t2f.io",
          "org":{
            "name":"Fyffes",
            "fullname":"Fyffes",
            "logo":null
          }
        }
      },
      "createdBy_participant":{
        "username":null,
        "email":null,
        "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        },
        "channel":{
          "title":"Bananas",
          "name":"bananas"
        },
        "user":{
          "username":"Fyffes TST staging",
          "email":"FyffesTST@pc.t2f.io",
          "org":{
            "name":"Fyffes",
            "fullname":"Fyffes",
            "logo":null
          }
        }
      }
    },
    {
      $class: 'org.powerchain.tilapia.batch.producer.ProducerProcessingReadItemConcept',
      processingDate: '2019-03-22T00:00:00.000Z',
      gisfBatch: 'NGB.20190322.E',
      fgCode: '2FGDWAC1105AAB0001',
      quantityInKg: 56.7,
      "modifiedBy_participant":{
        "username":null,
        "email":null,
        "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        },
        "channel":{
          "title":"Bananas",
          "name":"bananas"
        },
        "user":{
          "username":"Fyffes TST staging",
          "email":"FyffesTST@pc.t2f.io",
          "org":{
            "name":"Fyffes",
            "fullname":"Fyffes",
            "logo":null
          }
        }
      },
      "createdBy_participant":{
        "username":null,
        "email":null,
        "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        },
        "channel":{
          "title":"Bananas",
          "name":"bananas"
        },
        "user":{
          "username":"Fyffes TST staging",
          "email":"FyffesTST@pc.t2f.io",
          "org":{
            "name":"Fyffes",
            "fullname":"Fyffes",
            "logo":null
          }
        }
      }
    },
    {
      $class: 'org.powerchain.tilapia.batch.producer.ProducerProcessingReadItemConcept',
      processingDate: '2019-03-22T00:00:00.000Z',
      gisfBatch: 'NGB.20190322.E',
      fgCode: '2FGDWAC1105AAB0002',
      quantityInKg: 22.68,
      "modifiedBy_participant":{
        "username":null,
        "email":null,
        "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        },
        "channel":{
          "title":"Bananas",
          "name":"bananas"
        },
        "user":{
          "username":"Fyffes TST staging",
          "email":"FyffesTST@pc.t2f.io",
          "org":{
            "name":"Fyffes",
            "fullname":"Fyffes",
            "logo":null
          }
        }
      },
      "createdBy_participant":{
        "username":null,
        "email":null,
        "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        },
        "channel":{
          "title":"Bananas",
          "name":"bananas"
        },
        "user":{
          "username":"Fyffes TST staging",
          "email":"FyffesTST@pc.t2f.io",
          "org":{
            "name":"Fyffes",
            "fullname":"Fyffes",
            "logo":null
          }
        }
      }
    },
    {
      $class: 'org.powerchain.tilapia.batch.producer.ProducerProcessingReadItemConcept',
      processingDate: '2019-03-22T00:00:00.000Z',
      gisfBatch: 'NGB.20190322.E',
      fgCode: '2FGDZAG1102AAB0001',
      quantityInKg: 120,
      "modifiedBy_participant":{
        "username":null,
        "email":null,
        "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        },
        "channel":{
          "title":"Bananas",
          "name":"bananas"
        },
        "user":{
          "username":"Fyffes TST staging",
          "email":"FyffesTST@pc.t2f.io",
          "org":{
            "name":"Fyffes",
            "fullname":"Fyffes",
            "logo":null
          }
        }
      },
      "createdBy_participant":{
        "username":null,
        "email":null,
        "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        },
        "channel":{
          "title":"Bananas",
          "name":"bananas"
        },
        "user":{
          "username":"Fyffes TST staging",
          "email":"FyffesTST@pc.t2f.io",
          "org":{
            "name":"Fyffes",
            "fullname":"Fyffes",
            "logo":null
          }
        }
      }
    },
    {
      $class: 'org.powerchain.tilapia.batch.producer.ProducerProcessingReadItemConcept',
      processingDate: '2019-03-22T00:00:00.000Z',
      gisfBatch: 'NGB.20190322.E',
      fgCode: '2FGDZAG1105AAB0001',
      quantityInKg: 357,
      "modifiedBy_participant":{
        "username":null,
        "email":null,
        "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        },
        "channel":{
          "title":"Bananas",
          "name":"bananas"
        },
        "user":{
          "username":"Fyffes TST staging",
          "email":"FyffesTST@pc.t2f.io",
          "org":{
            "name":"Fyffes",
            "fullname":"Fyffes",
            "logo":null
          }
        }
      },
      "createdBy_participant":{
        "username":null,
        "email":null,
        "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        },
        "channel":{
          "title":"Bananas",
          "name":"bananas"
        },
        "user":{
          "username":"Fyffes TST staging",
          "email":"FyffesTST@pc.t2f.io",
          "org":{
            "name":"Fyffes",
            "fullname":"Fyffes",
            "logo":null
          }
        }
      }
    },
    {
      $class: 'org.powerchain.tilapia.batch.producer.ProducerProcessingReadItemConcept',
      processingDate: '2019-03-22T00:00:00.000Z',
      gisfBatch: 'NGB.20190322.E',
      fgCode: '2FGDMAG1113ACF0001',
      quantityInKg: 1872.66,
      "modifiedBy_participant":{
        "username":null,
        "email":null,
        "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        },
        "channel":{
          "title":"Bananas",
          "name":"bananas"
        },
        "user":{
          "username":"Fyffes TST staging",
          "email":"FyffesTST@pc.t2f.io",
          "org":{
            "name":"Fyffes",
            "fullname":"Fyffes",
            "logo":null
          }
        }
      },
      "createdBy_participant":{
        "username":null,
        "email":null,
        "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        },
        "channel":{
          "title":"Bananas",
          "name":"bananas"
        },
        "user":{
          "username":"Fyffes TST staging",
          "email":"FyffesTST@pc.t2f.io",
          "org":{
            "name":"Fyffes",
            "fullname":"Fyffes",
            "logo":null
          }
        }
      }
    },
  ],
  rawMaterialList: [
    {
      $class: 'org.powerchain.tilapia.batch.producer.ProducerRawMaterialReadItemConcept',
      deliverySlip: '1190322011',
      gisfBatch: 'NGB.20190322.F',
      netFarm: 'NGBCC034',
      "modifiedBy_participant":{
        "username":null,
        "email":null,
        "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        },
        "channel":{
          "title":"Bananas",
          "name":"bananas"
        },
        "user":{
          "username":"Fyffes TST staging",
          "email":"FyffesTST@pc.t2f.io",
          "org":{
            "name":"Fyffes",
            "fullname":"Fyffes",
            "logo":null
          }
        }
      },
      "createdBy_participant":{
        "username":null,
        "email":null,
        "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        },
        "channel":{
          "title":"Bananas",
          "name":"bananas"
        },
        "user":{
          "username":"Fyffes TST staging",
          "email":"FyffesTST@pc.t2f.io",
          "org":{
            "name":"Fyffes",
            "fullname":"Fyffes",
            "logo":null
          }
        }
      }
    },
    {
      $class: 'org.powerchain.tilapia.batch.producer.ProducerRawMaterialReadItemConcept',
      deliverySlip: '1190322010',
      gisfBatch: 'NGB.20190322.E',
      netFarm: 'NGBCC034',
      "modifiedBy_participant":{
        "username":null,
        "email":null,
        "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        },
        "channel":{
          "title":"Bananas",
          "name":"bananas"
        },
        "user":{
          "username":"Fyffes TST staging",
          "email":"FyffesTST@pc.t2f.io",
          "org":{
            "name":"Fyffes",
            "fullname":"Fyffes",
            "logo":null
          }
        }
      },
      "createdBy_participant":{
        "username":null,
        "email":null,
        "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        },
        "channel":{
          "title":"Bananas",
          "name":"bananas"
        },
        "user":{
          "username":"Fyffes TST staging",
          "email":"FyffesTST@pc.t2f.io",
          "org":{
            "name":"Fyffes",
            "fullname":"Fyffes",
            "logo":null
          }
        }
      }
    },
    {
      $class: 'org.powerchain.tilapia.batch.producer.ProducerRawMaterialReadItemConcept',
      deliverySlip: '1190322027',
      gisfBatch: 'NGB.20190322.F',
      netFarm: 'NGBCC034',
      "modifiedBy_participant":{
        "username":null,
        "email":null,
        "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        },
        "channel":{
          "title":"Bananas",
          "name":"bananas"
        },
        "user":{
          "username":"Fyffes TST staging",
          "email":"FyffesTST@pc.t2f.io",
          "org":{
            "name":"Fyffes",
            "fullname":"Fyffes",
            "logo":null
          }
        }
      },
      "createdBy_participant":{
        "username":null,
        "email":null,
        "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        },
        "channel":{
          "title":"Bananas",
          "name":"bananas"
        },
        "user":{
          "username":"Fyffes TST staging",
          "email":"FyffesTST@pc.t2f.io",
          "org":{
            "name":"Fyffes",
            "fullname":"Fyffes",
            "logo":null
          }
        }
      }
    },
    {
      $class: 'org.powerchain.tilapia.batch.producer.ProducerRawMaterialReadItemConcept',
      deliverySlip: '1190322028',
      gisfBatch: 'NGB.20190322.F',
      netFarm: 'NGBCC034',
      "modifiedBy_participant":{
        "username":null,
        "email":null,
        "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        },
        "channel":{
          "title":"Bananas",
          "name":"bananas"
        },
        "user":{
          "username":"Fyffes TST staging",
          "email":"FyffesTST@pc.t2f.io",
          "org":{
            "name":"Fyffes",
            "fullname":"Fyffes",
            "logo":null
          }
        }
      },
      "createdBy_participant":{
        "username":null,
        "email":null,
        "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        },
        "channel":{
          "title":"Bananas",
          "name":"bananas"
        },
        "user":{
          "username":"Fyffes TST staging",
          "email":"FyffesTST@pc.t2f.io",
          "org":{
            "name":"Fyffes",
            "fullname":"Fyffes",
            "logo":null
          }
        }
      }
    },
    {
      $class: 'org.powerchain.tilapia.batch.producer.ProducerRawMaterialReadItemConcept',
      deliverySlip: '1190322030',
      gisfBatch: 'NGB.20190322.F',
      netFarm: 'NGBCC034',
      "modifiedBy_participant":{
        "username":null,
        "email":null,
        "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        },
        "channel":{
          "title":"Bananas",
          "name":"bananas"
        },
        "user":{
          "username":"Fyffes TST staging",
          "email":"FyffesTST@pc.t2f.io",
          "org":{
            "name":"Fyffes",
            "fullname":"Fyffes",
            "logo":null
          }
        }
      },
      "createdBy_participant":{
        "username":null,
        "email":null,
        "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        },
        "channel":{
          "title":"Bananas",
          "name":"bananas"
        },
        "user":{
          "username":"Fyffes TST staging",
          "email":"FyffesTST@pc.t2f.io",
          "org":{
            "name":"Fyffes",
            "fullname":"Fyffes",
            "logo":null
          }
        }
      }
    },
    {
      $class: 'org.powerchain.tilapia.batch.producer.ProducerRawMaterialReadItemConcept',
      deliverySlip: '1190322001',
      gisfBatch: 'NGB.20190322.E',
      netFarm: 'NGBCC034',
      "modifiedBy_participant":{
        "username":null,
        "email":null,
        "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        },
        "channel":{
          "title":"Bananas",
          "name":"bananas"
        },
        "user":{
          "username":"Fyffes TST staging",
          "email":"FyffesTST@pc.t2f.io",
          "org":{
            "name":"Fyffes",
            "fullname":"Fyffes",
            "logo":null
          }
        }
      },
      "createdBy_participant":{
        "username":null,
        "email":null,
        "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        },
        "channel":{
          "title":"Bananas",
          "name":"bananas"
        },
        "user":{
          "username":"Fyffes TST staging",
          "email":"FyffesTST@pc.t2f.io",
          "org":{
            "name":"Fyffes",
            "fullname":"Fyffes",
            "logo":null
          }
        }
      }
    },
    {
      $class: 'org.powerchain.tilapia.batch.producer.ProducerRawMaterialReadItemConcept',
      deliverySlip: '1190322002',
      gisfBatch: 'NGB.20190322.E',
      netFarm: 'NGBCC034',
      "modifiedBy_participant":{
        "username":null,
        "email":null,
        "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        },
        "channel":{
          "title":"Bananas",
          "name":"bananas"
        },
        "user":{
          "username":"Fyffes TST staging",
          "email":"FyffesTST@pc.t2f.io",
          "org":{
            "name":"Fyffes",
            "fullname":"Fyffes",
            "logo":null
          }
        }
      },
      "createdBy_participant":{
        "username":null,
        "email":null,
        "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        },
        "channel":{
          "title":"Bananas",
          "name":"bananas"
        },
        "user":{
          "username":"Fyffes TST staging",
          "email":"FyffesTST@pc.t2f.io",
          "org":{
            "name":"Fyffes",
            "fullname":"Fyffes",
            "logo":null
          }
        }
      }
    },
    {
      $class: 'org.powerchain.tilapia.batch.producer.ProducerRawMaterialReadItemConcept',
      deliverySlip: '1190322026',
      gisfBatch: 'NGB.20190322.F',
      netFarm: 'NGBCC034',
      "modifiedBy_participant":{
        "username":null,
        "email":null,
        "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        },
        "channel":{
          "title":"Bananas",
          "name":"bananas"
        },
        "user":{
          "username":"Fyffes TST staging",
          "email":"FyffesTST@pc.t2f.io",
          "org":{
            "name":"Fyffes",
            "fullname":"Fyffes",
            "logo":null
          }
        }
      },
      "createdBy_participant":{
        "username":null,
        "email":null,
        "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        },
        "channel":{
          "title":"Bananas",
          "name":"bananas"
        },
        "user":{
          "username":"Fyffes TST staging",
          "email":"FyffesTST@pc.t2f.io",
          "org":{
            "name":"Fyffes",
            "fullname":"Fyffes",
            "logo":null
          }
        }
      }
    },
    {
      $class: 'org.powerchain.tilapia.batch.producer.ProducerRawMaterialReadItemConcept',
      deliverySlip: '1190322004',
      gisfBatch: 'NGB.20190322.E',
      netFarm: 'NGBCC034',
      "modifiedBy_participant":{
        "username":null,
        "email":null,
        "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        },
        "channel":{
          "title":"Bananas",
          "name":"bananas"
        },
        "user":{
          "username":"Fyffes TST staging",
          "email":"FyffesTST@pc.t2f.io",
          "org":{
            "name":"Fyffes",
            "fullname":"Fyffes",
            "logo":null
          }
        }
      },
      "createdBy_participant":{
        "username":null,
        "email":null,
        "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        },
        "channel":{
          "title":"Bananas",
          "name":"bananas"
        },
        "user":{
          "username":"Fyffes TST staging",
          "email":"FyffesTST@pc.t2f.io",
          "org":{
            "name":"Fyffes",
            "fullname":"Fyffes",
            "logo":null
          }
        }
      }
    },
    {
      $class: 'org.powerchain.tilapia.batch.producer.ProducerRawMaterialReadItemConcept',
      deliverySlip: '1190322005',
      gisfBatch: 'NGB.20190322.E',
      netFarm: 'NGBCC034',
      "modifiedBy_participant":{
        "username":null,
        "email":null,
        "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        },
        "channel":{
          "title":"Bananas",
          "name":"bananas"
        },
        "user":{
          "username":"Fyffes TST staging",
          "email":"FyffesTST@pc.t2f.io",
          "org":{
            "name":"Fyffes",
            "fullname":"Fyffes",
            "logo":null
          }
        }
      },
      "createdBy_participant":{
        "username":null,
        "email":null,
        "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        },
        "channel":{
          "title":"Bananas",
          "name":"bananas"
        },
        "user":{
          "username":"Fyffes TST staging",
          "email":"FyffesTST@pc.t2f.io",
          "org":{
            "name":"Fyffes",
            "fullname":"Fyffes",
            "logo":null
          }
        }
      }
    },
    {
      $class: 'org.powerchain.tilapia.batch.producer.ProducerRawMaterialReadItemConcept',
      deliverySlip: '1190322006',
      gisfBatch: 'NGB.20190322.E',
      netFarm: 'NGBCC034',
      "modifiedBy_participant":{
        "username":null,
        "email":null,
        "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        },
        "channel":{
          "title":"Bananas",
          "name":"bananas"
        },
        "user":{
          "username":"Fyffes TST staging",
          "email":"FyffesTST@pc.t2f.io",
          "org":{
            "name":"Fyffes",
            "fullname":"Fyffes",
            "logo":null
          }
        }
      },
      "createdBy_participant":{
        "username":null,
        "email":null,
        "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        },
        "channel":{
          "title":"Bananas",
          "name":"bananas"
        },
        "user":{
          "username":"Fyffes TST staging",
          "email":"FyffesTST@pc.t2f.io",
          "org":{
            "name":"Fyffes",
            "fullname":"Fyffes",
            "logo":null
          }
        }
      }
    },
    {
      $class: 'org.powerchain.tilapia.batch.producer.ProducerRawMaterialReadItemConcept',
      deliverySlip: '1190322007',
      gisfBatch: 'NGB.20190322.E',
      netFarm: 'NGBCC034',
      "modifiedBy_participant":{
        "username":null,
        "email":null,
        "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        },
        "channel":{
          "title":"Bananas",
          "name":"bananas"
        },
        "user":{
          "username":"Fyffes TST staging",
          "email":"FyffesTST@pc.t2f.io",
          "org":{
            "name":"Fyffes",
            "fullname":"Fyffes",
            "logo":null
          }
        }
      },
      "createdBy_participant":{
        "username":null,
        "email":null,
        "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        },
        "channel":{
          "title":"Bananas",
          "name":"bananas"
        },
        "user":{
          "username":"Fyffes TST staging",
          "email":"FyffesTST@pc.t2f.io",
          "org":{
            "name":"Fyffes",
            "fullname":"Fyffes",
            "logo":null
          }
        }
      }
    },
    {
      $class: 'org.powerchain.tilapia.batch.producer.ProducerRawMaterialReadItemConcept',
      deliverySlip: '1190322008',
      gisfBatch: 'NGB.20190322.E',
      netFarm: 'NGBCC034',
      "modifiedBy_participant":{
        "username":null,
        "email":null,
        "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        },
        "channel":{
          "title":"Bananas",
          "name":"bananas"
        },
        "user":{
          "username":"Fyffes TST staging",
          "email":"FyffesTST@pc.t2f.io",
          "org":{
            "name":"Fyffes",
            "fullname":"Fyffes",
            "logo":null
          }
        }
      },
      "createdBy_participant":{
        "username":null,
        "email":null,
        "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        },
        "channel":{
          "title":"Bananas",
          "name":"bananas"
        },
        "user":{
          "username":"Fyffes TST staging",
          "email":"FyffesTST@pc.t2f.io",
          "org":{
            "name":"Fyffes",
            "fullname":"Fyffes",
            "logo":null
          }
        }
      }
    },
    {
      $class: 'org.powerchain.tilapia.batch.producer.ProducerRawMaterialReadItemConcept',
      deliverySlip: '1190322009',
      gisfBatch: 'NGB.20190322.E',
      netFarm: 'NGBCC034',
      "modifiedBy_participant":{
        "username":null,
        "email":null,
        "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        },
        "channel":{
          "title":"Bananas",
          "name":"bananas"
        },
        "user":{
          "username":"Fyffes TST staging",
          "email":"FyffesTST@pc.t2f.io",
          "org":{
            "name":"Fyffes",
            "fullname":"Fyffes",
            "logo":null
          }
        }
      },
      "createdBy_participant":{
        "username":null,
        "email":null,
        "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        },
        "channel":{
          "title":"Bananas",
          "name":"bananas"
        },
        "user":{
          "username":"Fyffes TST staging",
          "email":"FyffesTST@pc.t2f.io",
          "org":{
            "name":"Fyffes",
            "fullname":"Fyffes",
            "logo":null
          }
        }
      }
    },
    {
      $class: 'org.powerchain.tilapia.batch.producer.ProducerRawMaterialReadItemConcept',
      deliverySlip: '1190322003',
      gisfBatch: 'NGB.20190322.E',
      netFarm: 'NGBCC034',
      "modifiedBy_participant":{
        "username":null,
        "email":null,
        "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        },
        "channel":{
          "title":"Bananas",
          "name":"bananas"
        },
        "user":{
          "username":"Fyffes TST staging",
          "email":"FyffesTST@pc.t2f.io",
          "org":{
            "name":"Fyffes",
            "fullname":"Fyffes",
            "logo":null
          }
        }
      },
      "createdBy_participant":{
        "username":null,
        "email":null,
        "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        },
        "channel":{
          "title":"Bananas",
          "name":"bananas"
        },
        "user":{
          "username":"Fyffes TST staging",
          "email":"FyffesTST@pc.t2f.io",
          "org":{
            "name":"Fyffes",
            "fullname":"Fyffes",
            "logo":null
          }
        }
      }
    },
  ],
  harvestingFarmList: [
    {
      $class: 'org.powerchain.tilapia.batch.producer.ProducerHarvestingFarmReadItemConcept',
      deliverySlipNote: '190754484',
      harvestDate: '2019-03-20T00:00:00.000Z',
      destination: 'Medan Processing Plant',
      farmSite: 'NGB',
      netFarm: 'NGBCC034',
      projectId: 'NGBCC034.20180515',
      volume: 2201,
      "modifiedBy_participant":{
        "username":null,
        "email":null,
        "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        },
        "channel":{
          "title":"Bananas",
          "name":"bananas"
        },
        "user":{
          "username":"Fyffes TST staging",
          "email":"FyffesTST@pc.t2f.io",
          "org":{
            "name":"Fyffes",
            "fullname":"Fyffes",
            "logo":null
          }
        }
      },
      "createdBy_participant":{
        "username":null,
        "email":null,
        "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        },
        "channel":{
          "title":"Bananas",
          "name":"bananas"
        },
        "user":{
          "username":"Fyffes TST staging",
          "email":"FyffesTST@pc.t2f.io",
          "org":{
            "name":"Fyffes",
            "fullname":"Fyffes",
            "logo":null
          }
        }
      }
    },
    {
      $class: 'org.powerchain.tilapia.batch.producer.ProducerHarvestingFarmReadItemConcept',
      deliverySlipNote: '190754513',
      harvestDate: '2019-03-20T00:00:00.000Z',
      destination: 'Medan Processing Plant',
      farmSite: 'NGB',
      netFarm: 'NGBCC034',
      projectId: 'NGBCC034.20180515',
      volume: 99.2,
      "modifiedBy_participant":{
        "username":null,
        "email":null,
        "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        },
        "channel":{
          "title":"Bananas",
          "name":"bananas"
        },
        "user":{
          "username":"Fyffes TST staging",
          "email":"FyffesTST@pc.t2f.io",
          "org":{
            "name":"Fyffes",
            "fullname":"Fyffes",
            "logo":null
          }
        }
      },
      "createdBy_participant":{
        "username":null,
        "email":null,
        "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        },
        "channel":{
          "title":"Bananas",
          "name":"bananas"
        },
        "user":{
          "username":"Fyffes TST staging",
          "email":"FyffesTST@pc.t2f.io",
          "org":{
            "name":"Fyffes",
            "fullname":"Fyffes",
            "logo":null
          }
        }
      }
    },
    {
      $class: 'org.powerchain.tilapia.batch.producer.ProducerHarvestingFarmReadItemConcept',
      deliverySlipNote: '190754486',
      harvestDate: '2019-03-20T00:00:00.000Z',
      destination: 'Medan Processing Plant',
      farmSite: 'NGB',
      netFarm: 'NGBCC034',
      projectId: 'NGBCC034.20180515',
      volume: 2245,
      "modifiedBy_participant":{
        "username":null,
        "email":null,
        "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        },
        "channel":{
          "title":"Bananas",
          "name":"bananas"
        },
        "user":{
          "username":"Fyffes TST staging",
          "email":"FyffesTST@pc.t2f.io",
          "org":{
            "name":"Fyffes",
            "fullname":"Fyffes",
            "logo":null
          }
        }
      },
      "createdBy_participant":{
        "username":null,
        "email":null,
        "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        },
        "channel":{
          "title":"Bananas",
          "name":"bananas"
        },
        "user":{
          "username":"Fyffes TST staging",
          "email":"FyffesTST@pc.t2f.io",
          "org":{
            "name":"Fyffes",
            "fullname":"Fyffes",
            "logo":null
          }
        }
      }
    },
    {
      $class: 'org.powerchain.tilapia.batch.producer.ProducerHarvestingFarmReadItemConcept',
      deliverySlipNote: '190754487',
      harvestDate: '2019-03-20T00:00:00.000Z',
      destination: 'Medan Processing Plant',
      farmSite: 'NGB',
      netFarm: 'NGBCC034',
      projectId: 'NGBCC034.20180515',
      volume: 2005,
      "modifiedBy_participant":{
        "username":null,
        "email":null,
        "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        },
        "channel":{
          "title":"Bananas",
          "name":"bananas"
        },
        "user":{
          "username":"Fyffes TST staging",
          "email":"FyffesTST@pc.t2f.io",
          "org":{
            "name":"Fyffes",
            "fullname":"Fyffes",
            "logo":null
          }
        }
      },
      "createdBy_participant":{
        "username":null,
        "email":null,
        "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        },
        "channel":{
          "title":"Bananas",
          "name":"bananas"
        },
        "user":{
          "username":"Fyffes TST staging",
          "email":"FyffesTST@pc.t2f.io",
          "org":{
            "name":"Fyffes",
            "fullname":"Fyffes",
            "logo":null
          }
        }
      }
    },
    {
      $class: 'org.powerchain.tilapia.batch.producer.ProducerHarvestingFarmReadItemConcept',
      deliverySlipNote: '190754488',
      harvestDate: '2019-03-20T00:00:00.000Z',
      destination: 'Medan Processing Plant',
      farmSite: 'NGB',
      netFarm: 'NGBCC034',
      projectId: 'NGBCC034.20180515',
      volume: 2306,
      "modifiedBy_participant":{
        "username":null,
        "email":null,
        "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        },
        "channel":{
          "title":"Bananas",
          "name":"bananas"
        },
        "user":{
          "username":"Fyffes TST staging",
          "email":"FyffesTST@pc.t2f.io",
          "org":{
            "name":"Fyffes",
            "fullname":"Fyffes",
            "logo":null
          }
        }
      },
      "createdBy_participant":{
        "username":null,
        "email":null,
        "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        },
        "channel":{
          "title":"Bananas",
          "name":"bananas"
        },
        "user":{
          "username":"Fyffes TST staging",
          "email":"FyffesTST@pc.t2f.io",
          "org":{
            "name":"Fyffes",
            "fullname":"Fyffes",
            "logo":null
          }
        }
      }
    },
    {
      $class: 'org.powerchain.tilapia.batch.producer.ProducerHarvestingFarmReadItemConcept',
      deliverySlipNote: '190754489',
      harvestDate: '2019-03-20T00:00:00.000Z',
      destination: 'Medan Processing Plant',
      farmSite: 'NGB',
      netFarm: 'NGBCC034',
      projectId: 'NGBCC034.20180515',
      volume: 2044,
      "modifiedBy_participant":{
        "username":null,
        "email":null,
        "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        },
        "channel":{
          "title":"Bananas",
          "name":"bananas"
        },
        "user":{
          "username":"Fyffes TST staging",
          "email":"FyffesTST@pc.t2f.io",
          "org":{
            "name":"Fyffes",
            "fullname":"Fyffes",
            "logo":null
          }
        }
      },
      "createdBy_participant":{
        "username":null,
        "email":null,
        "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        },
        "channel":{
          "title":"Bananas",
          "name":"bananas"
        },
        "user":{
          "username":"Fyffes TST staging",
          "email":"FyffesTST@pc.t2f.io",
          "org":{
            "name":"Fyffes",
            "fullname":"Fyffes",
            "logo":null
          }
        }
      }
    },
    {
      $class: 'org.powerchain.tilapia.batch.producer.ProducerHarvestingFarmReadItemConcept',
      deliverySlipNote: '190754490',
      harvestDate: '2019-03-20T00:00:00.000Z',
      destination: 'Medan Processing Plant',
      farmSite: 'NGB',
      netFarm: 'NGBCC034',
      projectId: 'NGBCC034.20180515',
      volume: 2310,
      "modifiedBy_participant":{
        "username":null,
        "email":null,
        "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        },
        "channel":{
          "title":"Bananas",
          "name":"bananas"
        },
        "user":{
          "username":"Fyffes TST staging",
          "email":"FyffesTST@pc.t2f.io",
          "org":{
            "name":"Fyffes",
            "fullname":"Fyffes",
            "logo":null
          }
        }
      },
      "createdBy_participant":{
        "username":null,
        "email":null,
        "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        },
        "channel":{
          "title":"Bananas",
          "name":"bananas"
        },
        "user":{
          "username":"Fyffes TST staging",
          "email":"FyffesTST@pc.t2f.io",
          "org":{
            "name":"Fyffes",
            "fullname":"Fyffes",
            "logo":null
          }
        }
      }
    },
    {
      $class: 'org.powerchain.tilapia.batch.producer.ProducerHarvestingFarmReadItemConcept',
      deliverySlipNote: '190754491',
      harvestDate: '2019-03-20T00:00:00.000Z',
      destination: 'Medan Processing Plant',
      farmSite: 'NGB',
      netFarm: 'NGBCC034',
      projectId: 'NGBCC034.20180515',
      volume: 2301,
      "modifiedBy_participant":{
        "username":null,
        "email":null,
        "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        },
        "channel":{
          "title":"Bananas",
          "name":"bananas"
        },
        "user":{
          "username":"Fyffes TST staging",
          "email":"FyffesTST@pc.t2f.io",
          "org":{
            "name":"Fyffes",
            "fullname":"Fyffes",
            "logo":null
          }
        }
      },
      "createdBy_participant":{
        "username":null,
        "email":null,
        "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        },
        "channel":{
          "title":"Bananas",
          "name":"bananas"
        },
        "user":{
          "username":"Fyffes TST staging",
          "email":"FyffesTST@pc.t2f.io",
          "org":{
            "name":"Fyffes",
            "fullname":"Fyffes",
            "logo":null
          }
        }
      }
    },
    {
      $class: 'org.powerchain.tilapia.batch.producer.ProducerHarvestingFarmReadItemConcept',
      deliverySlipNote: '190754492',
      harvestDate: '2019-03-20T00:00:00.000Z',
      destination: 'Medan Processing Plant',
      farmSite: 'NGB',
      netFarm: 'NGBCC034',
      projectId: 'NGBCC034.20180515',
      volume: 2311,
      "modifiedBy_participant":{
        "username":null,
        "email":null,
        "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        },
        "channel":{
          "title":"Bananas",
          "name":"bananas"
        },
        "user":{
          "username":"Fyffes TST staging",
          "email":"FyffesTST@pc.t2f.io",
          "org":{
            "name":"Fyffes",
            "fullname":"Fyffes",
            "logo":null
          }
        }
      },
      "createdBy_participant":{
        "username":null,
        "email":null,
        "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        },
        "channel":{
          "title":"Bananas",
          "name":"bananas"
        },
        "user":{
          "username":"Fyffes TST staging",
          "email":"FyffesTST@pc.t2f.io",
          "org":{
            "name":"Fyffes",
            "fullname":"Fyffes",
            "logo":null
          }
        }
      }
    },
    {
      $class: 'org.powerchain.tilapia.batch.producer.ProducerHarvestingFarmReadItemConcept',
      deliverySlipNote: '190754493',
      harvestDate: '2019-03-20T00:00:00.000Z',
      destination: 'Medan Processing Plant',
      farmSite: 'NGB',
      netFarm: 'NGBCC034',
      projectId: 'NGBCC034.20180515',
      volume: 2150,
      "modifiedBy_participant":{
        "username":null,
        "email":null,
        "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        },
        "channel":{
          "title":"Bananas",
          "name":"bananas"
        },
        "user":{
          "username":"Fyffes TST staging",
          "email":"FyffesTST@pc.t2f.io",
          "org":{
            "name":"Fyffes",
            "fullname":"Fyffes",
            "logo":null
          }
        }
      },
      "createdBy_participant":{
        "username":null,
        "email":null,
        "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        },
        "channel":{
          "title":"Bananas",
          "name":"bananas"
        },
        "user":{
          "username":"Fyffes TST staging",
          "email":"FyffesTST@pc.t2f.io",
          "org":{
            "name":"Fyffes",
            "fullname":"Fyffes",
            "logo":null
          }
        }
      }
    },
    {
      $class: 'org.powerchain.tilapia.batch.producer.ProducerHarvestingFarmReadItemConcept',
      deliverySlipNote: '190754494',
      harvestDate: '2019-03-20T00:00:00.000Z',
      destination: 'Medan Processing Plant',
      farmSite: 'NGB',
      netFarm: 'NGBCC034',
      projectId: 'NGBCC034.20180515',
      volume: 2503,
      "modifiedBy_participant":{
        "username":null,
        "email":null,
        "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        },
        "channel":{
          "title":"Bananas",
          "name":"bananas"
        },
        "user":{
          "username":"Fyffes TST staging",
          "email":"FyffesTST@pc.t2f.io",
          "org":{
            "name":"Fyffes",
            "fullname":"Fyffes",
            "logo":null
          }
        }
      },
      "createdBy_participant":{
        "username":null,
        "email":null,
        "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        },
        "channel":{
          "title":"Bananas",
          "name":"bananas"
        },
        "user":{
          "username":"Fyffes TST staging",
          "email":"FyffesTST@pc.t2f.io",
          "org":{
            "name":"Fyffes",
            "fullname":"Fyffes",
            "logo":null
          }
        }
      }
    },
    {
      $class: 'org.powerchain.tilapia.batch.producer.ProducerHarvestingFarmReadItemConcept',
      deliverySlipNote: '190754509',
      harvestDate: '2019-03-20T00:00:00.000Z',
      destination: 'Medan Processing Plant',
      farmSite: 'NGB',
      netFarm: 'NGBCC034',
      projectId: 'NGBCC034.20180515',
      volume: 2082.5,
      "modifiedBy_participant":{
        "username":null,
        "email":null,
        "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        },
        "channel":{
          "title":"Bananas",
          "name":"bananas"
        },
        "user":{
          "username":"Fyffes TST staging",
          "email":"FyffesTST@pc.t2f.io",
          "org":{
            "name":"Fyffes",
            "fullname":"Fyffes",
            "logo":null
          }
        }
      },
      "createdBy_participant":{
        "username":null,
        "email":null,
        "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        },
        "channel":{
          "title":"Bananas",
          "name":"bananas"
        },
        "user":{
          "username":"Fyffes TST staging",
          "email":"FyffesTST@pc.t2f.io",
          "org":{
            "name":"Fyffes",
            "fullname":"Fyffes",
            "logo":null
          }
        }
      }
    },
    {
      $class: 'org.powerchain.tilapia.batch.producer.ProducerHarvestingFarmReadItemConcept',
      deliverySlipNote: '190754510',
      harvestDate: '2019-03-20T00:00:00.000Z',
      destination: 'Medan Processing Plant',
      farmSite: 'NGB',
      netFarm: 'NGBCC034',
      projectId: 'NGBCC034.20180515',
      volume: 2132.4,
      "modifiedBy_participant":{
        "username":null,
        "email":null,
        "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        },
        "channel":{
          "title":"Bananas",
          "name":"bananas"
        },
        "user":{
          "username":"Fyffes TST staging",
          "email":"FyffesTST@pc.t2f.io",
          "org":{
            "name":"Fyffes",
            "fullname":"Fyffes",
            "logo":null
          }
        }
      },
      "createdBy_participant":{
        "username":null,
        "email":null,
        "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        },
        "channel":{
          "title":"Bananas",
          "name":"bananas"
        },
        "user":{
          "username":"Fyffes TST staging",
          "email":"FyffesTST@pc.t2f.io",
          "org":{
            "name":"Fyffes",
            "fullname":"Fyffes",
            "logo":null
          }
        }
      }
    },
    {
      $class: 'org.powerchain.tilapia.batch.producer.ProducerHarvestingFarmReadItemConcept',
      deliverySlipNote: '190754511',
      harvestDate: '2019-03-20T00:00:00.000Z',
      destination: 'Medan Processing Plant',
      farmSite: 'NGB',
      netFarm: 'NGBCC034',
      projectId: 'NGBCC034.20180515',
      volume: 2090.4,
      "modifiedBy_participant":{
        "username":null,
        "email":null,
        "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        },
        "channel":{
          "title":"Bananas",
          "name":"bananas"
        },
        "user":{
          "username":"Fyffes TST staging",
          "email":"FyffesTST@pc.t2f.io",
          "org":{
            "name":"Fyffes",
            "fullname":"Fyffes",
            "logo":null
          }
        }
      },
      "createdBy_participant":{
        "username":null,
        "email":null,
        "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        },
        "channel":{
          "title":"Bananas",
          "name":"bananas"
        },
        "user":{
          "username":"Fyffes TST staging",
          "email":"FyffesTST@pc.t2f.io",
          "org":{
            "name":"Fyffes",
            "fullname":"Fyffes",
            "logo":null
          }
        }
      }
    },
    {
      $class: 'org.powerchain.tilapia.batch.producer.ProducerHarvestingFarmReadItemConcept',
      deliverySlipNote: '190754513',
      harvestDate: '2019-03-20T00:00:00.000Z',
      destination: 'Medan Processing Plant',
      farmSite: 'NGB',
      netFarm: 'NGBCC034',
      projectId: 'NGBCC034.20180515',
      volume: 99.2,
      "modifiedBy_participant":{
        "username":null,
        "email":null,
        "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        },
        "channel":{
          "title":"Bananas",
          "name":"bananas"
        },
        "user":{
          "username":"Fyffes TST staging",
          "email":"FyffesTST@pc.t2f.io",
          "org":{
            "name":"Fyffes",
            "fullname":"Fyffes",
            "logo":null
          }
        }
      },
      "createdBy_participant":{
        "username":null,
        "email":null,
        "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        },
        "channel":{
          "title":"Bananas",
          "name":"bananas"
        },
        "user":{
          "username":"Fyffes TST staging",
          "email":"FyffesTST@pc.t2f.io",
          "org":{
            "name":"Fyffes",
            "fullname":"Fyffes",
            "logo":null
          }
        }
      }
    },
    {
      $class: 'org.powerchain.tilapia.batch.producer.ProducerHarvestingFarmReadItemConcept',
      deliverySlipNote: '190754484',
      harvestDate: '2019-03-20T00:00:00.000Z',
      destination: 'Medan Processing Plant',
      farmSite: 'NGB',
      netFarm: 'NGBCC034',
      projectId: 'NGBCC034.20180515',
      volume: 2201,
      "modifiedBy_participant":{
        "username":null,
        "email":null,
        "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        },
        "channel":{
          "title":"Bananas",
          "name":"bananas"
        },
        "user":{
          "username":"Fyffes TST staging",
          "email":"FyffesTST@pc.t2f.io",
          "org":{
            "name":"Fyffes",
            "fullname":"Fyffes",
            "logo":null
          }
        }
      },
      "createdBy_participant":{
        "username":null,
        "email":null,
        "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        },
        "channel":{
          "title":"Bananas",
          "name":"bananas"
        },
        "user":{
          "username":"Fyffes TST staging",
          "email":"FyffesTST@pc.t2f.io",
          "org":{
            "name":"Fyffes",
            "fullname":"Fyffes",
            "logo":null
          }
        }
      }
    },
    {
      $class: 'org.powerchain.tilapia.batch.producer.ProducerHarvestingFarmReadItemConcept',
      deliverySlipNote: '190754485',
      harvestDate: '2019-03-20T00:00:00.000Z',
      destination: 'Medan Processing Plant',
      farmSite: 'NGB',
      netFarm: 'NGBCC034',
      projectId: 'NGBCC034.20180515',
      volume: 2305,
      "modifiedBy_participant":{
        "username":null,
        "email":null,
        "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        },
        "channel":{
          "title":"Bananas",
          "name":"bananas"
        },
        "user":{
          "username":"Fyffes TST staging",
          "email":"FyffesTST@pc.t2f.io",
          "org":{
            "name":"Fyffes",
            "fullname":"Fyffes",
            "logo":null
          }
        }
      },
      "createdBy_participant":{
        "username":null,
        "email":null,
        "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        },
        "channel":{
          "title":"Bananas",
          "name":"bananas"
        },
        "user":{
          "username":"Fyffes TST staging",
          "email":"FyffesTST@pc.t2f.io",
          "org":{
            "name":"Fyffes",
            "fullname":"Fyffes",
            "logo":null
          }
        }
      }
    },
    {
      $class: 'org.powerchain.tilapia.batch.producer.ProducerHarvestingFarmReadItemConcept',
      deliverySlipNote: '190754486',
      harvestDate: '2019-03-20T00:00:00.000Z',
      destination: 'Medan Processing Plant',
      farmSite: 'NGB',
      netFarm: 'NGBCC034',
      projectId: 'NGBCC034.20180515',
      volume: 2245,
      "modifiedBy_participant":{
        "username":null,
        "email":null,
        "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        },
        "channel":{
          "title":"Bananas",
          "name":"bananas"
        },
        "user":{
          "username":"Fyffes TST staging",
          "email":"FyffesTST@pc.t2f.io",
          "org":{
            "name":"Fyffes",
            "fullname":"Fyffes",
            "logo":null
          }
        }
      },
      "createdBy_participant":{
        "username":null,
        "email":null,
        "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        },
        "channel":{
          "title":"Bananas",
          "name":"bananas"
        },
        "user":{
          "username":"Fyffes TST staging",
          "email":"FyffesTST@pc.t2f.io",
          "org":{
            "name":"Fyffes",
            "fullname":"Fyffes",
            "logo":null
          }
        }
      }
    },
    {
      $class: 'org.powerchain.tilapia.batch.producer.ProducerHarvestingFarmReadItemConcept',
      deliverySlipNote: '190754487',
      harvestDate: '2019-03-20T00:00:00.000Z',
      destination: 'Medan Processing Plant',
      farmSite: 'NGB',
      netFarm: 'NGBCC034',
      projectId: 'NGBCC034.20180515',
      volume: 2005,
      "modifiedBy_participant":{
        "username":null,
        "email":null,
        "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        },
        "channel":{
          "title":"Bananas",
          "name":"bananas"
        },
        "user":{
          "username":"Fyffes TST staging",
          "email":"FyffesTST@pc.t2f.io",
          "org":{
            "name":"Fyffes",
            "fullname":"Fyffes",
            "logo":null
          }
        }
      },
      "createdBy_participant":{
        "username":null,
        "email":null,
        "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        },
        "channel":{
          "title":"Bananas",
          "name":"bananas"
        },
        "user":{
          "username":"Fyffes TST staging",
          "email":"FyffesTST@pc.t2f.io",
          "org":{
            "name":"Fyffes",
            "fullname":"Fyffes",
            "logo":null
          }
        }
      }
    },
    {
      $class: 'org.powerchain.tilapia.batch.producer.ProducerHarvestingFarmReadItemConcept',
      deliverySlipNote: '190754488',
      harvestDate: '2019-03-20T00:00:00.000Z',
      destination: 'Medan Processing Plant',
      farmSite: 'NGB',
      netFarm: 'NGBCC034',
      projectId: 'NGBCC034.20180515',
      volume: 2306,
      "modifiedBy_participant":{
        "username":null,
        "email":null,
        "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        },
        "channel":{
          "title":"Bananas",
          "name":"bananas"
        },
        "user":{
          "username":"Fyffes TST staging",
          "email":"FyffesTST@pc.t2f.io",
          "org":{
            "name":"Fyffes",
            "fullname":"Fyffes",
            "logo":null
          }
        }
      },
      "createdBy_participant":{
        "username":null,
        "email":null,
        "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        },
        "channel":{
          "title":"Bananas",
          "name":"bananas"
        },
        "user":{
          "username":"Fyffes TST staging",
          "email":"FyffesTST@pc.t2f.io",
          "org":{
            "name":"Fyffes",
            "fullname":"Fyffes",
            "logo":null
          }
        }
      }
    },
    {
      $class: 'org.powerchain.tilapia.batch.producer.ProducerHarvestingFarmReadItemConcept',
      deliverySlipNote: '190754489',
      harvestDate: '2019-03-20T00:00:00.000Z',
      destination: 'Medan Processing Plant',
      farmSite: 'NGB',
      netFarm: 'NGBCC034',
      projectId: 'NGBCC034.20180515',
      volume: 2044,
      "modifiedBy_participant":{
        "username":null,
        "email":null,
        "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        },
        "channel":{
          "title":"Bananas",
          "name":"bananas"
        },
        "user":{
          "username":"Fyffes TST staging",
          "email":"FyffesTST@pc.t2f.io",
          "org":{
            "name":"Fyffes",
            "fullname":"Fyffes",
            "logo":null
          }
        }
      },
      "createdBy_participant":{
        "username":null,
        "email":null,
        "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        },
        "channel":{
          "title":"Bananas",
          "name":"bananas"
        },
        "user":{
          "username":"Fyffes TST staging",
          "email":"FyffesTST@pc.t2f.io",
          "org":{
            "name":"Fyffes",
            "fullname":"Fyffes",
            "logo":null
          }
        }
      }
    },
    {
      $class: 'org.powerchain.tilapia.batch.producer.ProducerHarvestingFarmReadItemConcept',
      deliverySlipNote: '190754490',
      harvestDate: '2019-03-20T00:00:00.000Z',
      destination: 'Medan Processing Plant',
      farmSite: 'NGB',
      netFarm: 'NGBCC034',
      projectId: 'NGBCC034.20180515',
      volume: 2310,
      "modifiedBy_participant":{
        "username":null,
        "email":null,
        "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        },
        "channel":{
          "title":"Bananas",
          "name":"bananas"
        },
        "user":{
          "username":"Fyffes TST staging",
          "email":"FyffesTST@pc.t2f.io",
          "org":{
            "name":"Fyffes",
            "fullname":"Fyffes",
            "logo":null
          }
        }
      },
      "createdBy_participant":{
        "username":null,
        "email":null,
        "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        },
        "channel":{
          "title":"Bananas",
          "name":"bananas"
        },
        "user":{
          "username":"Fyffes TST staging",
          "email":"FyffesTST@pc.t2f.io",
          "org":{
            "name":"Fyffes",
            "fullname":"Fyffes",
            "logo":null
          }
        }
      }
    },
    {
      $class: 'org.powerchain.tilapia.batch.producer.ProducerHarvestingFarmReadItemConcept',
      deliverySlipNote: '190754491',
      harvestDate: '2019-03-20T00:00:00.000Z',
      destination: 'Medan Processing Plant',
      farmSite: 'NGB',
      netFarm: 'NGBCC034',
      projectId: 'NGBCC034.20180515',
      volume: 2301,
      "modifiedBy_participant":{
        "username":null,
        "email":null,
        "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        },
        "channel":{
          "title":"Bananas",
          "name":"bananas"
        },
        "user":{
          "username":"Fyffes TST staging",
          "email":"FyffesTST@pc.t2f.io",
          "org":{
            "name":"Fyffes",
            "fullname":"Fyffes",
            "logo":null
          }
        }
      },
      "createdBy_participant":{
        "username":null,
        "email":null,
        "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        },
        "channel":{
          "title":"Bananas",
          "name":"bananas"
        },
        "user":{
          "username":"Fyffes TST staging",
          "email":"FyffesTST@pc.t2f.io",
          "org":{
            "name":"Fyffes",
            "fullname":"Fyffes",
            "logo":null
          }
        }
      }
    },
    {
      $class: 'org.powerchain.tilapia.batch.producer.ProducerHarvestingFarmReadItemConcept',
      deliverySlipNote: '190754492',
      harvestDate: '2019-03-20T00:00:00.000Z',
      destination: 'Medan Processing Plant',
      farmSite: 'NGB',
      netFarm: 'NGBCC034',
      projectId: 'NGBCC034.20180515',
      volume: 2311,"createdBy_participant":{
        "username":null,
        "email":null,
        "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        },
        "channel":{
          "title":"Bananas",
          "name":"bananas"
        },
        "user":{
          "username":"Fyffes TST staging",
          "email":"FyffesTST@pc.t2f.io",
          "org":{
            "name":"Fyffes",
            "fullname":"Fyffes",
            "logo":null
          }
        }
      }
    },
    {
      $class: 'org.powerchain.tilapia.batch.producer.ProducerHarvestingFarmReadItemConcept',
      deliverySlipNote: '190754493',
      harvestDate: '2019-03-20T00:00:00.000Z',
      destination: 'Medan Processing Plant',
      farmSite: 'NGB',
      netFarm: 'NGBCC034',
      projectId: 'NGBCC034.20180515',
      volume: 2150,"createdBy_participant":{
        "username":null,
        "email":null,
        "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        },
        "channel":{
          "title":"Bananas",
          "name":"bananas"
        },
        "user":{
          "username":"Fyffes TST staging",
          "email":"FyffesTST@pc.t2f.io",
          "org":{
            "name":"Fyffes",
            "fullname":"Fyffes",
            "logo":null
          }
        }
      }
    },
    {
      $class: 'org.powerchain.tilapia.batch.producer.ProducerHarvestingFarmReadItemConcept',
      deliverySlipNote: '190754494',
      harvestDate: '2019-03-20T00:00:00.000Z',
      destination: 'Medan Processing Plant',
      farmSite: 'NGB',
      netFarm: 'NGBCC034',
      projectId: 'NGBCC034.20180515',
      volume: 2503,"createdBy_participant":{
        "username":null,
        "email":null,
        "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        },
        "channel":{
          "title":"Bananas",
          "name":"bananas"
        },
        "user":{
          "username":"Fyffes TST staging",
          "email":"FyffesTST@pc.t2f.io",
          "org":{
            "name":"Fyffes",
            "fullname":"Fyffes",
            "logo":null
          }
        }
      }
    },
    {
      $class: 'org.powerchain.tilapia.batch.producer.ProducerHarvestingFarmReadItemConcept',
      deliverySlipNote: '190754509',
      harvestDate: '2019-03-20T00:00:00.000Z',
      destination: 'Medan Processing Plant',
      farmSite: 'NGB',
      netFarm: 'NGBCC034',
      projectId: 'NGBCC034.20180515',
      volume: 2082.5,"createdBy_participant":{
        "username":null,
        "email":null,
        "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        },
        "channel":{
          "title":"Bananas",
          "name":"bananas"
        },
        "user":{
          "username":"Fyffes TST staging",
          "email":"FyffesTST@pc.t2f.io",
          "org":{
            "name":"Fyffes",
            "fullname":"Fyffes",
            "logo":null
          }
        }
      }
    },
    {
      $class: 'org.powerchain.tilapia.batch.producer.ProducerHarvestingFarmReadItemConcept',
      deliverySlipNote: '190754510',
      harvestDate: '2019-03-20T00:00:00.000Z',
      destination: 'Medan Processing Plant',
      farmSite: 'NGB',
      netFarm: 'NGBCC034',
      projectId: 'NGBCC034.20180515',
      volume: 2132.4,"createdBy_participant":{
        "username":null,
        "email":null,
        "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        },
        "channel":{
          "title":"Bananas",
          "name":"bananas"
        },
        "user":{
          "username":"Fyffes TST staging",
          "email":"FyffesTST@pc.t2f.io",
          "org":{
            "name":"Fyffes",
            "fullname":"Fyffes",
            "logo":null
          }
        }
      }
    },
    {
      $class: 'org.powerchain.tilapia.batch.producer.ProducerHarvestingFarmReadItemConcept',
      deliverySlipNote: '190754511',
      harvestDate: '2019-03-20T00:00:00.000Z',
      destination: 'Medan Processing Plant',
      farmSite: 'NGB',
      netFarm: 'NGBCC034',
      projectId: 'NGBCC034.20180515',
      volume: 2090.4,"createdBy_participant":{
        "username":null,
        "email":null,
        "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        },
        "channel":{
          "title":"Bananas",
          "name":"bananas"
        },
        "user":{
          "username":"Fyffes TST staging",
          "email":"FyffesTST@pc.t2f.io",
          "org":{
            "name":"Fyffes",
            "fullname":"Fyffes",
            "logo":null
          }
        }
      }
    },
    {
      $class: 'org.powerchain.tilapia.batch.producer.ProducerHarvestingFarmReadItemConcept',
      deliverySlipNote: '190754513',
      harvestDate: '2019-03-20T00:00:00.000Z',
      destination: 'Medan Processing Plant',
      farmSite: 'NGB',
      netFarm: 'NGBCC034',
      projectId: 'NGBCC034.20180515',
      volume: 99.2,"createdBy_participant":{
        "username":null,
        "email":null,
        "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        },
        "channel":{
          "title":"Bananas",
          "name":"bananas"
        },
        "user":{
          "username":"Fyffes TST staging",
          "email":"FyffesTST@pc.t2f.io",
          "org":{
            "name":"Fyffes",
            "fullname":"Fyffes",
            "logo":null
          }
        }
      }
    },
    {
      $class: 'org.powerchain.tilapia.batch.producer.ProducerHarvestingFarmReadItemConcept',
      deliverySlipNote: '190754485',
      harvestDate: '2019-03-20T00:00:00.000Z',
      destination: 'Medan Processing Plant',
      farmSite: 'NGB',
      netFarm: 'NGBCC034',
      projectId: 'NGBCC034.20180515',
      volume: 2305,"createdBy_participant":{
        "username":null,
        "email":null,
        "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        },
        "channel":{
          "title":"Bananas",
          "name":"bananas"
        },
        "user":{
          "username":"Fyffes TST staging",
          "email":"FyffesTST@pc.t2f.io",
          "org":{
            "name":"Fyffes",
            "fullname":"Fyffes",
            "logo":null
          }
        }
      }
    },
    {
      $class: 'org.powerchain.tilapia.batch.producer.ProducerHarvestingFarmReadItemConcept',
      deliverySlipNote: '190754485',
      harvestDate: '2019-03-20T00:00:00.000Z',
      destination: 'Medan Processing Plant',
      farmSite: 'NGB',
      netFarm: 'NGBCC034',
      projectId: 'NGBCC034.20180515',
      volume: 2305,"createdBy_participant":{
        "username":null,
        "email":null,
        "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        },
        "channel":{
          "title":"Bananas",
          "name":"bananas"
        },
        "user":{
          "username":"Fyffes TST staging",
          "email":"FyffesTST@pc.t2f.io",
          "org":{
            "name":"Fyffes",
            "fullname":"Fyffes",
            "logo":null
          }
        }
      }
    },
    {
      $class: 'org.powerchain.tilapia.batch.producer.ProducerHarvestingFarmReadItemConcept',
      deliverySlipNote: '190754486',
      harvestDate: '2019-03-20T00:00:00.000Z',
      destination: 'Medan Processing Plant',
      farmSite: 'NGB',
      netFarm: 'NGBCC034',
      projectId: 'NGBCC034.20180515',
      volume: 2245,"createdBy_participant":{
        "username":null,
        "email":null,
        "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        },
        "channel":{
          "title":"Bananas",
          "name":"bananas"
        },
        "user":{
          "username":"Fyffes TST staging",
          "email":"FyffesTST@pc.t2f.io",
          "org":{
            "name":"Fyffes",
            "fullname":"Fyffes",
            "logo":null
          }
        }
      }
    },
    {
      $class: 'org.powerchain.tilapia.batch.producer.ProducerHarvestingFarmReadItemConcept',
      deliverySlipNote: '190754487',
      harvestDate: '2019-03-20T00:00:00.000Z',
      destination: 'Medan Processing Plant',
      farmSite: 'NGB',
      netFarm: 'NGBCC034',
      projectId: 'NGBCC034.20180515',
      volume: 2005,"createdBy_participant":{
        "username":null,
        "email":null,
        "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        },
        "channel":{
          "title":"Bananas",
          "name":"bananas"
        },
        "user":{
          "username":"Fyffes TST staging",
          "email":"FyffesTST@pc.t2f.io",
          "org":{
            "name":"Fyffes",
            "fullname":"Fyffes",
            "logo":null
          }
        }
      }
    },
    {
      $class: 'org.powerchain.tilapia.batch.producer.ProducerHarvestingFarmReadItemConcept',
      deliverySlipNote: '190754488',
      harvestDate: '2019-03-20T00:00:00.000Z',
      destination: 'Medan Processing Plant',
      farmSite: 'NGB',
      netFarm: 'NGBCC034',
      projectId: 'NGBCC034.20180515',
      volume: 2306,"createdBy_participant":{
        "username":null,
        "email":null,
        "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        },
        "channel":{
          "title":"Bananas",
          "name":"bananas"
        },
        "user":{
          "username":"Fyffes TST staging",
          "email":"FyffesTST@pc.t2f.io",
          "org":{
            "name":"Fyffes",
            "fullname":"Fyffes",
            "logo":null
          }
        }
      }
    },
    {
      $class: 'org.powerchain.tilapia.batch.producer.ProducerHarvestingFarmReadItemConcept',
      deliverySlipNote: '190754489',
      harvestDate: '2019-03-20T00:00:00.000Z',
      destination: 'Medan Processing Plant',
      farmSite: 'NGB',
      netFarm: 'NGBCC034',
      projectId: 'NGBCC034.20180515',
      volume: 2044,"createdBy_participant":{
        "username":null,
        "email":null,
        "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        },
        "channel":{
          "title":"Bananas",
          "name":"bananas"
        },
        "user":{
          "username":"Fyffes TST staging",
          "email":"FyffesTST@pc.t2f.io",
          "org":{
            "name":"Fyffes",
            "fullname":"Fyffes",
            "logo":null
          }
        }
      }
    },
    {
      $class: 'org.powerchain.tilapia.batch.producer.ProducerHarvestingFarmReadItemConcept',
      deliverySlipNote: '190754490',
      harvestDate: '2019-03-20T00:00:00.000Z',
      destination: 'Medan Processing Plant',
      farmSite: 'NGB',
      netFarm: 'NGBCC034',
      projectId: 'NGBCC034.20180515',
      volume: 2310,"createdBy_participant":{
        "username":null,
        "email":null,
        "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        },
        "channel":{
          "title":"Bananas",
          "name":"bananas"
        },
        "user":{
          "username":"Fyffes TST staging",
          "email":"FyffesTST@pc.t2f.io",
          "org":{
            "name":"Fyffes",
            "fullname":"Fyffes",
            "logo":null
          }
        }
      }
    },
    {
      $class: 'org.powerchain.tilapia.batch.producer.ProducerHarvestingFarmReadItemConcept',
      deliverySlipNote: '190754491',
      harvestDate: '2019-03-20T00:00:00.000Z',
      destination: 'Medan Processing Plant',
      farmSite: 'NGB',
      netFarm: 'NGBCC034',
      projectId: 'NGBCC034.20180515',
      volume: 2301,
    },
    {
      $class: 'org.powerchain.tilapia.batch.producer.ProducerHarvestingFarmReadItemConcept',
      deliverySlipNote: '190754492',
      harvestDate: '2019-03-20T00:00:00.000Z',
      destination: 'Medan Processing Plant',
      farmSite: 'NGB',
      netFarm: 'NGBCC034',
      projectId: 'NGBCC034.20180515',
      volume: 2311,
    },
    {
      $class: 'org.powerchain.tilapia.batch.producer.ProducerHarvestingFarmReadItemConcept',
      deliverySlipNote: '190754493',
      harvestDate: '2019-03-20T00:00:00.000Z',
      destination: 'Medan Processing Plant',
      farmSite: 'NGB',
      netFarm: 'NGBCC034',
      projectId: 'NGBCC034.20180515',
      volume: 2150,
    },
    {
      $class: 'org.powerchain.tilapia.batch.producer.ProducerHarvestingFarmReadItemConcept',
      deliverySlipNote: '190754494',
      harvestDate: '2019-03-20T00:00:00.000Z',
      destination: 'Medan Processing Plant',
      farmSite: 'NGB',
      netFarm: 'NGBCC034',
      projectId: 'NGBCC034.20180515',
      volume: 2503,"createdBy_participant":{
        "username":null,
        "email":null,
        "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        },
        "channel":{
          "title":"Bananas",
          "name":"bananas"
        },
        "user":{
          "username":"Fyffes TST staging",
          "email":"FyffesTST@pc.t2f.io",
          "org":{
            "name":"Fyffes",
            "fullname":"Fyffes",
            "logo":null
          }
        }
      }
    },
    {
      $class: 'org.powerchain.tilapia.batch.producer.ProducerHarvestingFarmReadItemConcept',
      deliverySlipNote: '190754509',
      harvestDate: '2019-03-20T00:00:00.000Z',
      destination: 'Medan Processing Plant',
      farmSite: 'NGB',
      netFarm: 'NGBCC034',
      projectId: 'NGBCC034.20180515',
      volume: 2082.5,"createdBy_participant":{
        "username":null,
        "email":null,
        "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        },
        "channel":{
          "title":"Bananas",
          "name":"bananas"
        },
        "user":{
          "username":"Fyffes TST staging",
          "email":"FyffesTST@pc.t2f.io",
          "org":{
            "name":"Fyffes",
            "fullname":"Fyffes",
            "logo":null
          }
        }
      }
    },
    {
      $class: 'org.powerchain.tilapia.batch.producer.ProducerHarvestingFarmReadItemConcept',
      deliverySlipNote: '190754510',
      harvestDate: '2019-03-20T00:00:00.000Z',
      destination: 'Medan Processing Plant',
      farmSite: 'NGB',
      netFarm: 'NGBCC034',
      projectId: 'NGBCC034.20180515',
      volume: 2132.4,"createdBy_participant":{
        "username":null,
        "email":null,
        "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        },
        "channel":{
          "title":"Bananas",
          "name":"bananas"
        },
        "user":{
          "username":"Fyffes TST staging",
          "email":"FyffesTST@pc.t2f.io",
          "org":{
            "name":"Fyffes",
            "fullname":"Fyffes",
            "logo":null
          }
        }
      }
    },
    {
      $class: 'org.powerchain.tilapia.batch.producer.ProducerHarvestingFarmReadItemConcept',
      deliverySlipNote: '190754511',
      harvestDate: '2019-03-20T00:00:00.000Z',
      destination: 'Medan Processing Plant',
      farmSite: 'NGB',
      netFarm: 'NGBCC034',
      projectId: 'NGBCC034.20180515',
      volume: 2090.4,"createdBy_participant":{
        "username":null,
        "email":null,
        "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        },
        "channel":{
          "title":"Bananas",
          "name":"bananas"
        },
        "user":{
          "username":"Fyffes TST staging",
          "email":"FyffesTST@pc.t2f.io",
          "org":{
            "name":"Fyffes",
            "fullname":"Fyffes",
            "logo":null
          }
        }
      }
    },
    {
      $class: 'org.powerchain.tilapia.batch.producer.ProducerHarvestingFarmReadItemConcept',
      deliverySlipNote: '190754513',
      harvestDate: '2019-03-20T00:00:00.000Z',
      destination: 'Medan Processing Plant',
      farmSite: 'NGB',
      netFarm: 'NGBCC034',
      projectId: 'NGBCC034.20180515',
      volume: 99.2,"createdBy_participant":{
        "username":null,
        "email":null,
        "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        },
        "channel":{
          "title":"Bananas",
          "name":"bananas"
        },
        "user":{
          "username":"Fyffes TST staging",
          "email":"FyffesTST@pc.t2f.io",
          "org":{
            "name":"Fyffes",
            "fullname":"Fyffes",
            "logo":null
          }
        }
      }
    },
    {
      $class: 'org.powerchain.tilapia.batch.producer.ProducerHarvestingFarmReadItemConcept',
      deliverySlipNote: '190754484',
      harvestDate: '2019-03-20T00:00:00.000Z',
      destination: 'Medan Processing Plant',
      farmSite: 'NGB',
      netFarm: 'NGBCC034',
      projectId: 'NGBCC034.20180515',
      volume: 2201,"createdBy_participant":{
        "username":null,
        "email":null,
        "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        },
        "channel":{
          "title":"Bananas",
          "name":"bananas"
        },
        "user":{
          "username":"Fyffes TST staging",
          "email":"FyffesTST@pc.t2f.io",
          "org":{
            "name":"Fyffes",
            "fullname":"Fyffes",
            "logo":null
          }
        }
      }
    },
    {
      $class: 'org.powerchain.tilapia.batch.producer.ProducerHarvestingFarmReadItemConcept',
      deliverySlipNote: '190754485',
      harvestDate: '2019-03-20T00:00:00.000Z',
      destination: 'Medan Processing Plant',
      farmSite: 'NGB',
      netFarm: 'NGBCC034',
      projectId: 'NGBCC034.20180515',
      volume: 2305,"createdBy_participant":{
        "username":null,
        "email":null,
        "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        },
        "channel":{
          "title":"Bananas",
          "name":"bananas"
        },
        "user":{
          "username":"Fyffes TST staging",
          "email":"FyffesTST@pc.t2f.io",
          "org":{
            "name":"Fyffes",
            "fullname":"Fyffes",
            "logo":null
          }
        }
      }
    },
    {
      $class: 'org.powerchain.tilapia.batch.producer.ProducerHarvestingFarmReadItemConcept',
      deliverySlipNote: '190754486',
      harvestDate: '2019-03-20T00:00:00.000Z',
      destination: 'Medan Processing Plant',
      farmSite: 'NGB',
      netFarm: 'NGBCC034',
      projectId: 'NGBCC034.20180515',
      volume: 2245,"createdBy_participant":{
        "username":null,
        "email":null,
        "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        },
        "channel":{
          "title":"Bananas",
          "name":"bananas"
        },
        "user":{
          "username":"Fyffes TST staging",
          "email":"FyffesTST@pc.t2f.io",
          "org":{
            "name":"Fyffes",
            "fullname":"Fyffes",
            "logo":null
          }
        }
      }
    },
    {
      $class: 'org.powerchain.tilapia.batch.producer.ProducerHarvestingFarmReadItemConcept',
      deliverySlipNote: '190754487',
      harvestDate: '2019-03-20T00:00:00.000Z',
      destination: 'Medan Processing Plant',
      farmSite: 'NGB',
      netFarm: 'NGBCC034',
      projectId: 'NGBCC034.20180515',
      volume: 2005,"createdBy_participant":{
        "username":null,
        "email":null,
        "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        },
        "channel":{
          "title":"Bananas",
          "name":"bananas"
        },
        "user":{
          "username":"Fyffes TST staging",
          "email":"FyffesTST@pc.t2f.io",
          "org":{
            "name":"Fyffes",
            "fullname":"Fyffes",
            "logo":null
          }
        }
      }
    },
    {
      $class: 'org.powerchain.tilapia.batch.producer.ProducerHarvestingFarmReadItemConcept',
      deliverySlipNote: '190754488',
      harvestDate: '2019-03-20T00:00:00.000Z',
      destination: 'Medan Processing Plant',
      farmSite: 'NGB',
      netFarm: 'NGBCC034',
      projectId: 'NGBCC034.20180515',
      volume: 2306,"createdBy_participant":{
        "username":null,
        "email":null,
        "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        },
        "channel":{
          "title":"Bananas",
          "name":"bananas"
        },
        "user":{
          "username":"Fyffes TST staging",
          "email":"FyffesTST@pc.t2f.io",
          "org":{
            "name":"Fyffes",
            "fullname":"Fyffes",
            "logo":null
          }
        }
      }
    },
    {
      $class: 'org.powerchain.tilapia.batch.producer.ProducerHarvestingFarmReadItemConcept',
      deliverySlipNote: '190754489',
      harvestDate: '2019-03-20T00:00:00.000Z',
      destination: 'Medan Processing Plant',
      farmSite: 'NGB',
      netFarm: 'NGBCC034',
      projectId: 'NGBCC034.20180515',
      volume: 2044,"createdBy_participant":{
        "username":null,
        "email":null,
        "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        },
        "channel":{
          "title":"Bananas",
          "name":"bananas"
        },
        "user":{
          "username":"Fyffes TST staging",
          "email":"FyffesTST@pc.t2f.io",
          "org":{
            "name":"Fyffes",
            "fullname":"Fyffes",
            "logo":null
          }
        }
      }
    },
    {
      $class: 'org.powerchain.tilapia.batch.producer.ProducerHarvestingFarmReadItemConcept',
      deliverySlipNote: '190754490',
      harvestDate: '2019-03-20T00:00:00.000Z',
      destination: 'Medan Processing Plant',
      farmSite: 'NGB',
      netFarm: 'NGBCC034',
      projectId: 'NGBCC034.20180515',
      volume: 2310,"createdBy_participant":{
        "username":null,
        "email":null,
        "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        },
        "channel":{
          "title":"Bananas",
          "name":"bananas"
        },
        "user":{
          "username":"Fyffes TST staging",
          "email":"FyffesTST@pc.t2f.io",
          "org":{
            "name":"Fyffes",
            "fullname":"Fyffes",
            "logo":null
          }
        }
      }
    },
    {
      $class: 'org.powerchain.tilapia.batch.producer.ProducerHarvestingFarmReadItemConcept',
      deliverySlipNote: '190754491',
      harvestDate: '2019-03-20T00:00:00.000Z',
      destination: 'Medan Processing Plant',
      farmSite: 'NGB',
      netFarm: 'NGBCC034',
      projectId: 'NGBCC034.20180515',
      volume: 2301,"createdBy_participant":{
        "username":null,
        "email":null,
        "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        },
        "channel":{
          "title":"Bananas",
          "name":"bananas"
        },
        "user":{
          "username":"Fyffes TST staging",
          "email":"FyffesTST@pc.t2f.io",
          "org":{
            "name":"Fyffes",
            "fullname":"Fyffes",
            "logo":null
          }
        }
      }
    },
    {
      $class: 'org.powerchain.tilapia.batch.producer.ProducerHarvestingFarmReadItemConcept',
      deliverySlipNote: '190754492',
      harvestDate: '2019-03-20T00:00:00.000Z',
      destination: 'Medan Processing Plant',
      farmSite: 'NGB',
      netFarm: 'NGBCC034',
      projectId: 'NGBCC034.20180515',
      volume: 2311,"createdBy_participant":{
        "username":null,
        "email":null,
        "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        },
        "channel":{
          "title":"Bananas",
          "name":"bananas"
        },
        "user":{
          "username":"Fyffes TST staging",
          "email":"FyffesTST@pc.t2f.io",
          "org":{
            "name":"Fyffes",
            "fullname":"Fyffes",
            "logo":null
          }
        }
      }
    },
    {
      $class: 'org.powerchain.tilapia.batch.producer.ProducerHarvestingFarmReadItemConcept',
      deliverySlipNote: '190754493',
      harvestDate: '2019-03-20T00:00:00.000Z',
      destination: 'Medan Processing Plant',
      farmSite: 'NGB',
      netFarm: 'NGBCC034',
      projectId: 'NGBCC034.20180515',
      volume: 2150,"createdBy_participant":{
        "username":null,
        "email":null,
        "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        },
        "channel":{
          "title":"Bananas",
          "name":"bananas"
        },
        "user":{
          "username":"Fyffes TST staging",
          "email":"FyffesTST@pc.t2f.io",
          "org":{
            "name":"Fyffes",
            "fullname":"Fyffes",
            "logo":null
          }
        }
      }
    },
    {
      $class: 'org.powerchain.tilapia.batch.producer.ProducerHarvestingFarmReadItemConcept',
      deliverySlipNote: '190754494',
      harvestDate: '2019-03-20T00:00:00.000Z',
      destination: 'Medan Processing Plant',
      farmSite: 'NGB',
      netFarm: 'NGBCC034',
      projectId: 'NGBCC034.20180515',
      volume: 2503,"createdBy_participant":{
        "username":null,
        "email":null,
        "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        },
        "channel":{
          "title":"Bananas",
          "name":"bananas"
        },
        "user":{
          "username":"Fyffes TST staging",
          "email":"FyffesTST@pc.t2f.io",
          "org":{
            "name":"Fyffes",
            "fullname":"Fyffes",
            "logo":null
          }
        }
      }
    },
    {
      $class: 'org.powerchain.tilapia.batch.producer.ProducerHarvestingFarmReadItemConcept',
      deliverySlipNote: '190754509',
      harvestDate: '2019-03-20T00:00:00.000Z',
      destination: 'Medan Processing Plant',
      farmSite: 'NGB',
      netFarm: 'NGBCC034',
      projectId: 'NGBCC034.20180515',
      volume: 2082.5,"createdBy_participant":{
        "username":null,
        "email":null,
        "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        },
        "channel":{
          "title":"Bananas",
          "name":"bananas"
        },
        "user":{
          "username":"Fyffes TST staging",
          "email":"FyffesTST@pc.t2f.io",
          "org":{
            "name":"Fyffes",
            "fullname":"Fyffes",
            "logo":null
          }
        }
      }
    },
    {
      $class: 'org.powerchain.tilapia.batch.producer.ProducerHarvestingFarmReadItemConcept',
      deliverySlipNote: '190754510',
      harvestDate: '2019-03-20T00:00:00.000Z',
      destination: 'Medan Processing Plant',
      farmSite: 'NGB',
      netFarm: 'NGBCC034',
      projectId: 'NGBCC034.20180515',
      volume: 2132.4,"createdBy_participant":{
        "username":null,
        "email":null,
        "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        },
        "channel":{
          "title":"Bananas",
          "name":"bananas"
        },
        "user":{
          "username":"Fyffes TST staging",
          "email":"FyffesTST@pc.t2f.io",
          "org":{
            "name":"Fyffes",
            "fullname":"Fyffes",
            "logo":null
          }
        }
      }
    },
    {
      $class: 'org.powerchain.tilapia.batch.producer.ProducerHarvestingFarmReadItemConcept',
      deliverySlipNote: '190754511',
      harvestDate: '2019-03-20T00:00:00.000Z',
      destination: 'Medan Processing Plant',
      farmSite: 'NGB',
      netFarm: 'NGBCC034',
      projectId: 'NGBCC034.20180515',
      volume: 2090.4,"createdBy_participant":{
        "username":null,
        "email":null,
        "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        },
        "channel":{
          "title":"Bananas",
          "name":"bananas"
        },
        "user":{
          "username":"Fyffes TST staging",
          "email":"FyffesTST@pc.t2f.io",
          "org":{
            "name":"Fyffes",
            "fullname":"Fyffes",
            "logo":null
          }
        }
      }
    },
    {
      $class: 'org.powerchain.tilapia.batch.producer.ProducerHarvestingFarmReadItemConcept',
      deliverySlipNote: '190754484',
      harvestDate: '2019-03-20T00:00:00.000Z',
      destination: 'Medan Processing Plant',
      farmSite: 'NGB',
      netFarm: 'NGBCC034',
      projectId: 'NGBCC034.20180515',
      volume: 2201,"createdBy_participant":{
        "username":null,
        "email":null,
        "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        },
        "channel":{
          "title":"Bananas",
          "name":"bananas"
        },
        "user":{
          "username":"Fyffes TST staging",
          "email":"FyffesTST@pc.t2f.io",
          "org":{
            "name":"Fyffes",
            "fullname":"Fyffes",
            "logo":null
          }
        }
      }
    },
  ],
  growUpList: [
    {
      $class: 'org.powerchain.tilapia.batch.producer.ProducerGrowUpReadItemConcept',
      projectId: 'NGBCC034.20180515',
      feedSupplier: 'PT. Aquafarm Nusantara, Medan-AFGA-3',
      feedType: '3,0 mm',
      feedQuantity: 14640,"createdBy_participant":{
        "username":null,
        "email":null,
        "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        },
        "channel":{
          "title":"Bananas",
          "name":"bananas"
        },
        "user":{
          "username":"Fyffes TST staging",
          "email":"FyffesTST@pc.t2f.io",
          "org":{
            "name":"Fyffes",
            "fullname":"Fyffes",
            "logo":null
          }
        }
      }
    },
    {
      $class: 'org.powerchain.tilapia.batch.producer.ProducerGrowUpReadItemConcept',
      projectId: 'NGBCC034.20180515',
      feedSupplier: 'PT. Cargill Indonesia, Medan-RT994',
      feedType: '4,0 mm',
      feedQuantity: 73200,"createdBy_participant":{
        "username":null,
        "email":null,
        "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        },
        "channel":{
          "title":"Bananas",
          "name":"bananas"
        },
        "user":{
          "username":"Fyffes TST staging",
          "email":"FyffesTST@pc.t2f.io",
          "org":{
            "name":"Fyffes",
            "fullname":"Fyffes",
            "logo":null
          }
        }
      }
    },
    {
      $class: 'org.powerchain.tilapia.batch.producer.ProducerGrowUpReadItemConcept',
      projectId: 'NGBCC034.20180515',
      feedSupplier: 'PT. Shinta Prima Feedmill, Jakarta-SNA-5',
      feedType: '5,0 mm',
      feedQuantity: 101280,"createdBy_participant":{
        "username":null,
        "email":null,
        "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        },
        "channel":{
          "title":"Bananas",
          "name":"bananas"
        },
        "user":{
          "username":"Fyffes TST staging",
          "email":"FyffesTST@pc.t2f.io",
          "org":{
            "name":"Fyffes",
            "fullname":"Fyffes",
            "logo":null
          }
        }
      }
    },
  ],
  stockingList: [
    {
      $class: 'org.powerchain.tilapia.batch.producer.ProducerStockingReadItemConcept',
      deliverySlipNote: '182501295',
      stockingDate: '2018-05-29T00:00:00.000Z',
      projectId: 'NGBCC034.20180515',"createdBy_participant":{
        "username":null,
        "email":null,
        "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        },
        "channel":{
          "title":"Bananas",
          "name":"bananas"
        },
        "user":{
          "username":"Fyffes TST staging",
          "email":"FyffesTST@pc.t2f.io",
          "org":{
            "name":"Fyffes",
            "fullname":"Fyffes",
            "logo":null
          }
        }
      }
    },
    {
      $class: 'org.powerchain.tilapia.batch.producer.ProducerStockingReadItemConcept',
      deliverySlipNote: '182501601',
      stockingDate: '2018-07-12T00:00:00.000Z',
      projectId: 'NGBCC034.20180515',"createdBy_participant":{
        "username":null,
        "email":null,
        "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        },
        "channel":{
          "title":"Bananas",
          "name":"bananas"
        },
        "user":{
          "username":"Fyffes TST staging",
          "email":"FyffesTST@pc.t2f.io",
          "org":{
            "name":"Fyffes",
            "fullname":"Fyffes",
            "logo":null
          }
        }
      }
    },
    {
      $class: 'org.powerchain.tilapia.batch.producer.ProducerStockingReadItemConcept',
      deliverySlipNote: '182501297',
      stockingDate: '2018-05-29T00:00:00.000Z',
      projectId: 'NGBCC034.20180515',"createdBy_participant":{
        "username":null,
        "email":null,
        "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        },
        "channel":{
          "title":"Bananas",
          "name":"bananas"
        },
        "user":{
          "username":"Fyffes TST staging",
          "email":"FyffesTST@pc.t2f.io",
          "org":{
            "name":"Fyffes",
            "fullname":"Fyffes",
            "logo":null
          }
        }
      }
    },
    {
      $class: 'org.powerchain.tilapia.batch.producer.ProducerStockingReadItemConcept',
      deliverySlipNote: '182501298',
      stockingDate: '2018-05-29T00:00:00.000Z',
      projectId: 'NGBCC034.20180515',"createdBy_participant":{
        "username":null,
        "email":null,
        "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        },
        "channel":{
          "title":"Bananas",
          "name":"bananas"
        },
        "user":{
          "username":"Fyffes TST staging",
          "email":"FyffesTST@pc.t2f.io",
          "org":{
            "name":"Fyffes",
            "fullname":"Fyffes",
            "logo":null
          }
        }
      }
    },
    {
      $class: 'org.powerchain.tilapia.batch.producer.ProducerStockingReadItemConcept',
      deliverySlipNote: '182501299',
      stockingDate: '2018-05-29T00:00:00.000Z',
      projectId: 'NGBCC034.20180515',"createdBy_participant":{
        "username":null,
        "email":null,
        "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        },
        "channel":{
          "title":"Bananas",
          "name":"bananas"
        },
        "user":{
          "username":"Fyffes TST staging",
          "email":"FyffesTST@pc.t2f.io",
          "org":{
            "name":"Fyffes",
            "fullname":"Fyffes",
            "logo":null
          }
        }
      }
    },
    {
      $class: 'org.powerchain.tilapia.batch.producer.ProducerStockingReadItemConcept',
      deliverySlipNote: '182501300',
      stockingDate: '2018-05-29T00:00:00.000Z',
      projectId: 'NGBCC034.20180515',"createdBy_participant":{
        "username":null,
        "email":null,
        "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        },
        "channel":{
          "title":"Bananas",
          "name":"bananas"
        },
        "user":{
          "username":"Fyffes TST staging",
          "email":"FyffesTST@pc.t2f.io",
          "org":{
            "name":"Fyffes",
            "fullname":"Fyffes",
            "logo":null
          }
        }
      }
    },
    {
      $class: 'org.powerchain.tilapia.batch.producer.ProducerStockingReadItemConcept',
      deliverySlipNote: '182501295',
      stockingDate: '2018-05-29T00:00:00.000Z',
      projectId: 'NGBCC034.20180515',"createdBy_participant":{
        "username":null,
        "email":null,
        "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        },
        "channel":{
          "title":"Bananas",
          "name":"bananas"
        },
        "user":{
          "username":"Fyffes TST staging",
          "email":"FyffesTST@pc.t2f.io",
          "org":{
            "name":"Fyffes",
            "fullname":"Fyffes",
            "logo":null
          }
        }
      }
    },
    {
      $class: 'org.powerchain.tilapia.batch.producer.ProducerStockingReadItemConcept',
      deliverySlipNote: '182501296',
      stockingDate: '2018-05-29T00:00:00.000Z',
      projectId: 'NGBCC034.20180515',"createdBy_participant":{
        "username":null,
        "email":null,
        "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        },
        "channel":{
          "title":"Bananas",
          "name":"bananas"
        },
        "user":{
          "username":"Fyffes TST staging",
          "email":"FyffesTST@pc.t2f.io",
          "org":{
            "name":"Fyffes",
            "fullname":"Fyffes",
            "logo":null
          }
        }
      }
    },
    {
      $class: 'org.powerchain.tilapia.batch.producer.ProducerStockingReadItemConcept',
      deliverySlipNote: '182501297',
      stockingDate: '2018-05-29T00:00:00.000Z',
      projectId: 'NGBCC034.20180515',"createdBy_participant":{
        "username":null,
        "email":null,
        "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        },
        "channel":{
          "title":"Bananas",
          "name":"bananas"
        },
        "user":{
          "username":"Fyffes TST staging",
          "email":"FyffesTST@pc.t2f.io",
          "org":{
            "name":"Fyffes",
            "fullname":"Fyffes",
            "logo":null
          }
        }
      }
    },
    {
      $class: 'org.powerchain.tilapia.batch.producer.ProducerStockingReadItemConcept',
      deliverySlipNote: '182501298',
      stockingDate: '2018-05-29T00:00:00.000Z',
      projectId: 'NGBCC034.20180515',"createdBy_participant":{
        "username":null,
        "email":null,
        "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        },
        "channel":{
          "title":"Bananas",
          "name":"bananas"
        },
        "user":{
          "username":"Fyffes TST staging",
          "email":"FyffesTST@pc.t2f.io",
          "org":{
            "name":"Fyffes",
            "fullname":"Fyffes",
            "logo":null
          }
        }
      }
    },
    {
      $class: 'org.powerchain.tilapia.batch.producer.ProducerStockingReadItemConcept',
      deliverySlipNote: '182501299',
      stockingDate: '2018-05-29T00:00:00.000Z',
      projectId: 'NGBCC034.20180515',"createdBy_participant":{
        "username":null,
        "email":null,
        "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        },
        "channel":{
          "title":"Bananas",
          "name":"bananas"
        },
        "user":{
          "username":"Fyffes TST staging",
          "email":"FyffesTST@pc.t2f.io",
          "org":{
            "name":"Fyffes",
            "fullname":"Fyffes",
            "logo":null
          }
        }
      }
    },
    {
      $class: 'org.powerchain.tilapia.batch.producer.ProducerStockingReadItemConcept',
      deliverySlipNote: '182501300',
      stockingDate: '2018-05-29T00:00:00.000Z',
      projectId: 'NGBCC034.20180515',"createdBy_participant":{
        "username":null,
        "email":null,
        "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        },
        "channel":{
          "title":"Bananas",
          "name":"bananas"
        },
        "user":{
          "username":"Fyffes TST staging",
          "email":"FyffesTST@pc.t2f.io",
          "org":{
            "name":"Fyffes",
            "fullname":"Fyffes",
            "logo":null
          }
        }
      }
    },
    {
      $class: 'org.powerchain.tilapia.batch.producer.ProducerStockingReadItemConcept',
      deliverySlipNote: '182501296',
      stockingDate: '2018-05-29T00:00:00.000Z',
      projectId: 'NGBCC034.20180515',"createdBy_participant":{
        "username":null,
        "email":null,
        "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        },
        "channel":{
          "title":"Bananas",
          "name":"bananas"
        },
        "user":{
          "username":"Fyffes TST staging",
          "email":"FyffesTST@pc.t2f.io",
          "org":{
            "name":"Fyffes",
            "fullname":"Fyffes",
            "logo":null
          }
        }
      }
    },
    {
      $class: 'org.powerchain.tilapia.batch.producer.ProducerStockingReadItemConcept',
      deliverySlipNote: '182501597',
      stockingDate: '2018-07-12T00:00:00.000Z',
      projectId: 'NGBCC034.20180515',"createdBy_participant":{
        "username":null,
        "email":null,
        "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        },
        "channel":{
          "title":"Bananas",
          "name":"bananas"
        },
        "user":{
          "username":"Fyffes TST staging",
          "email":"FyffesTST@pc.t2f.io",
          "org":{
            "name":"Fyffes",
            "fullname":"Fyffes",
            "logo":null
          }
        }
      }
    },
    {
      $class: 'org.powerchain.tilapia.batch.producer.ProducerStockingReadItemConcept',
      deliverySlipNote: '182501598',
      stockingDate: '2018-07-12T00:00:00.000Z',
      projectId: 'NGBCC034.20180515',"createdBy_participant":{
        "username":null,
        "email":null,
        "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        },
        "channel":{
          "title":"Bananas",
          "name":"bananas"
        },
        "user":{
          "username":"Fyffes TST staging",
          "email":"FyffesTST@pc.t2f.io",
          "org":{
            "name":"Fyffes",
            "fullname":"Fyffes",
            "logo":null
          }
        }
      }
    },
    {
      $class: 'org.powerchain.tilapia.batch.producer.ProducerStockingReadItemConcept',
      deliverySlipNote: '182501599',
      stockingDate: '2018-07-12T00:00:00.000Z',
      projectId: 'NGBCC034.20180515',"createdBy_participant":{
        "username":null,
        "email":null,
        "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        },
        "channel":{
          "title":"Bananas",
          "name":"bananas"
        },
        "user":{
          "username":"Fyffes TST staging",
          "email":"FyffesTST@pc.t2f.io",
          "org":{
            "name":"Fyffes",
            "fullname":"Fyffes",
            "logo":null
          }
        }
      }
    },
    {
      $class: 'org.powerchain.tilapia.batch.producer.ProducerStockingReadItemConcept',
      deliverySlipNote: '182501600',
      stockingDate: '2018-07-12T00:00:00.000Z',
      projectId: 'NGBCC034.20180515',"createdBy_participant":{
        "username":null,
        "email":null,
        "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        },
        "channel":{
          "title":"Bananas",
          "name":"bananas"
        },
        "user":{
          "username":"Fyffes TST staging",
          "email":"FyffesTST@pc.t2f.io",
          "org":{
            "name":"Fyffes",
            "fullname":"Fyffes",
            "logo":null
          }
        }
      }
    },
    {
      $class: 'org.powerchain.tilapia.batch.producer.ProducerStockingReadItemConcept',
      deliverySlipNote: '182501601',
      stockingDate: '2018-07-12T00:00:00.000Z',
      projectId: 'NGBCC034.20180515',"createdBy_participant":{
        "username":null,
        "email":null,
        "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        },
        "channel":{
          "title":"Bananas",
          "name":"bananas"
        },
        "user":{
          "username":"Fyffes TST staging",
          "email":"FyffesTST@pc.t2f.io",
          "org":{
            "name":"Fyffes",
            "fullname":"Fyffes",
            "logo":null
          }
        }
      }
    },
    {
      $class: 'org.powerchain.tilapia.batch.producer.ProducerStockingReadItemConcept',
      deliverySlipNote: '182501596',
      stockingDate: '2018-07-12T00:00:00.000Z',
      projectId: 'NGBCC034.20180515',"createdBy_participant":{
        "username":null,
        "email":null,
        "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        },
        "channel":{
          "title":"Bananas",
          "name":"bananas"
        },
        "user":{
          "username":"Fyffes TST staging",
          "email":"FyffesTST@pc.t2f.io",
          "org":{
            "name":"Fyffes",
            "fullname":"Fyffes",
            "logo":null
          }
        }
      }
    },
    {
      $class: 'org.powerchain.tilapia.batch.producer.ProducerStockingReadItemConcept',
      deliverySlipNote: '182501597',
      stockingDate: '2018-07-12T00:00:00.000Z',
      projectId: 'NGBCC034.20180515',"createdBy_participant":{
        "username":null,
        "email":null,
        "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        },
        "channel":{
          "title":"Bananas",
          "name":"bananas"
        },
        "user":{
          "username":"Fyffes TST staging",
          "email":"FyffesTST@pc.t2f.io",
          "org":{
            "name":"Fyffes",
            "fullname":"Fyffes",
            "logo":null
          }
        }
      }
    },
    {
      $class: 'org.powerchain.tilapia.batch.producer.ProducerStockingReadItemConcept',
      deliverySlipNote: '182501598',
      stockingDate: '2018-07-12T00:00:00.000Z',
      projectId: 'NGBCC034.20180515',"createdBy_participant":{
        "username":null,
        "email":null,
        "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        },
        "channel":{
          "title":"Bananas",
          "name":"bananas"
        },
        "user":{
          "username":"Fyffes TST staging",
          "email":"FyffesTST@pc.t2f.io",
          "org":{
            "name":"Fyffes",
            "fullname":"Fyffes",
            "logo":null
          }
        }
      }
    },
    {
      $class: 'org.powerchain.tilapia.batch.producer.ProducerStockingReadItemConcept',
      deliverySlipNote: '182501599',
      stockingDate: '2018-07-12T00:00:00.000Z',
      projectId: 'NGBCC034.20180515',"createdBy_participant":{
        "username":null,
        "email":null,
        "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        },
        "channel":{
          "title":"Bananas",
          "name":"bananas"
        },
        "user":{
          "username":"Fyffes TST staging",
          "email":"FyffesTST@pc.t2f.io",
          "org":{
            "name":"Fyffes",
            "fullname":"Fyffes",
            "logo":null
          }
        }
      }
    },
    {
      $class: 'org.powerchain.tilapia.batch.producer.ProducerStockingReadItemConcept',
      deliverySlipNote: '182501600',
      stockingDate: '2018-07-12T00:00:00.000Z',
      projectId: 'NGBCC034.20180515',"createdBy_participant":{
        "username":null,
        "email":null,
        "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        },
        "channel":{
          "title":"Bananas",
          "name":"bananas"
        },
        "user":{
          "username":"Fyffes TST staging",
          "email":"FyffesTST@pc.t2f.io",
          "org":{
            "name":"Fyffes",
            "fullname":"Fyffes",
            "logo":null
          }
        }
      }
    },
    {
      $class: 'org.powerchain.tilapia.batch.producer.ProducerStockingReadItemConcept',
      deliverySlipNote: '182501596',
      stockingDate: '2018-07-12T00:00:00.000Z',
      projectId: 'NGBCC034.20180515',"createdBy_participant":{
        "username":null,
        "email":null,
        "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        },
        "channel":{
          "title":"Bananas",
          "name":"bananas"
        },
        "user":{
          "username":"Fyffes TST staging",
          "email":"FyffesTST@pc.t2f.io",
          "org":{
            "name":"Fyffes",
            "fullname":"Fyffes",
            "logo":null
          }
        }
      }
    },
  ],
  harvestingHcList: [
    {
      $class: 'org.powerchain.tilapia.batch.producer.ProducerHarvestingHcReadItemConcept',
      slipNumberDeliveryNote: '182501295',
      harvestDate: '2018-05-29T00:00:00.000Z',
      destination: 'Medan Processing Plant',
      nurseryId: 'GTXX-IBWXX/12A-09A/SR-021-90-J/070218/UBB',"createdBy_participant":{
        "username":null,
        "email":null,
        "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        },
        "channel":{
          "title":"Bananas",
          "name":"bananas"
        },
        "user":{
          "username":"Fyffes TST staging",
          "email":"FyffesTST@pc.t2f.io",
          "org":{
            "name":"Fyffes",
            "fullname":"Fyffes",
            "logo":null
          }
        }
      }
    },
    {
      $class: 'org.powerchain.tilapia.batch.producer.ProducerHarvestingHcReadItemConcept',
      slipNumberDeliveryNote: '182501601',
      harvestDate: '2018-07-12T00:00:00.000Z',
      destination: 'Medan Processing Plant',
      nurseryId: 'GTXX-IBWXX/01A-01A/FS-01/SR-021-82-J/010418/UBB',"createdBy_participant":{
        "username":null,
        "email":null,
        "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        },
        "channel":{
          "title":"Bananas",
          "name":"bananas"
        },
        "user":{
          "username":"Fyffes TST staging",
          "email":"FyffesTST@pc.t2f.io",
          "org":{
            "name":"Fyffes",
            "fullname":"Fyffes",
            "logo":null
          }
        }
      }
    },
    {
      $class: 'org.powerchain.tilapia.batch.producer.ProducerHarvestingHcReadItemConcept',
      slipNumberDeliveryNote: '182501296',
      harvestDate: '2018-05-29T00:00:00.000Z',
      destination: 'Medan Processing Plant',
      nurseryId: 'GTXX-IBWXX/12A-09A/SR-021-90-J/070218/UBB',"createdBy_participant":{
        "username":null,
        "email":null,
        "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        },
        "channel":{
          "title":"Bananas",
          "name":"bananas"
        },
        "user":{
          "username":"Fyffes TST staging",
          "email":"FyffesTST@pc.t2f.io",
          "org":{
            "name":"Fyffes",
            "fullname":"Fyffes",
            "logo":null
          }
        }
      }
    },
    {
      $class: 'org.powerchain.tilapia.batch.producer.ProducerHarvestingHcReadItemConcept',
      slipNumberDeliveryNote: '182501296',
      harvestDate: '2018-05-29T00:00:00.000Z',
      destination: 'Medan Processing Plant',
      nurseryId: 'GTXX-IBWXX/12A-09A/SR-021-90-J/070218/UBB',"createdBy_participant":{
        "username":null,
        "email":null,
        "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        },
        "channel":{
          "title":"Bananas",
          "name":"bananas"
        },
        "user":{
          "username":"Fyffes TST staging",
          "email":"FyffesTST@pc.t2f.io",
          "org":{
            "name":"Fyffes",
            "fullname":"Fyffes",
            "logo":null
          }
        }
      }
    },
    {
      $class: 'org.powerchain.tilapia.batch.producer.ProducerHarvestingHcReadItemConcept',
      slipNumberDeliveryNote: '182501297',
      harvestDate: '2018-05-29T00:00:00.000Z',
      destination: 'Medan Processing Plant',
      nurseryId: 'GTXX-IBWXX/12A-09A/SR-021-90-J/070218/UBB',"createdBy_participant":{
        "username":null,
        "email":null,
        "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        },
        "channel":{
          "title":"Bananas",
          "name":"bananas"
        },
        "user":{
          "username":"Fyffes TST staging",
          "email":"FyffesTST@pc.t2f.io",
          "org":{
            "name":"Fyffes",
            "fullname":"Fyffes",
            "logo":null
          }
        }
      }
    },
    {
      $class: 'org.powerchain.tilapia.batch.producer.ProducerHarvestingHcReadItemConcept',
      slipNumberDeliveryNote: '182501297',
      harvestDate: '2018-05-29T00:00:00.000Z',
      destination: 'Medan Processing Plant',
      nurseryId: 'GTXX-IBWXX/12A-09A/SR-021-90-J/070218/UBB',"createdBy_participant":{
        "username":null,
        "email":null,
        "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        },
        "channel":{
          "title":"Bananas",
          "name":"bananas"
        },
        "user":{
          "username":"Fyffes TST staging",
          "email":"FyffesTST@pc.t2f.io",
          "org":{
            "name":"Fyffes",
            "fullname":"Fyffes",
            "logo":null
          }
        }
      }
    },
    {
      $class: 'org.powerchain.tilapia.batch.producer.ProducerHarvestingHcReadItemConcept',
      slipNumberDeliveryNote: '182501298',
      harvestDate: '2018-05-29T00:00:00.000Z',
      destination: 'Medan Processing Plant',
      nurseryId: 'GTXX-IBWXX/12A-09A/SR-021-90-J/070218/UBB',"createdBy_participant":{
        "username":null,
        "email":null,
        "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        },
        "channel":{
          "title":"Bananas",
          "name":"bananas"
        },
        "user":{
          "username":"Fyffes TST staging",
          "email":"FyffesTST@pc.t2f.io",
          "org":{
            "name":"Fyffes",
            "fullname":"Fyffes",
            "logo":null
          }
        }
      }
    },
    {
      $class: 'org.powerchain.tilapia.batch.producer.ProducerHarvestingHcReadItemConcept',
      slipNumberDeliveryNote: '182501298',
      harvestDate: '2018-05-29T00:00:00.000Z',
      destination: 'Medan Processing Plant',
      nurseryId: 'GTXX-IBWXX/12A-09A/SR-021-90-J/070218/UBB',"createdBy_participant":{
        "username":null,
        "email":null,
        "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        },
        "channel":{
          "title":"Bananas",
          "name":"bananas"
        },
        "user":{
          "username":"Fyffes TST staging",
          "email":"FyffesTST@pc.t2f.io",
          "org":{
            "name":"Fyffes",
            "fullname":"Fyffes",
            "logo":null
          }
        }
      }
    },
    {
      $class: 'org.powerchain.tilapia.batch.producer.ProducerHarvestingHcReadItemConcept',
      slipNumberDeliveryNote: '182501299',
      harvestDate: '2018-05-29T00:00:00.000Z',
      destination: 'Medan Processing Plant',
      nurseryId: 'GTXX-IBWXX/12A-09A/SR-021-90-J/070218/UBB',"createdBy_participant":{
        "username":null,
        "email":null,
        "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        },
        "channel":{
          "title":"Bananas",
          "name":"bananas"
        },
        "user":{
          "username":"Fyffes TST staging",
          "email":"FyffesTST@pc.t2f.io",
          "org":{
            "name":"Fyffes",
            "fullname":"Fyffes",
            "logo":null
          }
        }
      }
    },
    {
      $class: 'org.powerchain.tilapia.batch.producer.ProducerHarvestingHcReadItemConcept',
      slipNumberDeliveryNote: '182501299',
      harvestDate: '2018-05-29T00:00:00.000Z',
      destination: 'Medan Processing Plant',
      nurseryId: 'GTXX-IBWXX/12A-09A/SR-021-90-J/070218/UBB',"createdBy_participant":{
        "username":null,
        "email":null,
        "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        },
        "channel":{
          "title":"Bananas",
          "name":"bananas"
        },
        "user":{
          "username":"Fyffes TST staging",
          "email":"FyffesTST@pc.t2f.io",
          "org":{
            "name":"Fyffes",
            "fullname":"Fyffes",
            "logo":null
          }
        }
      }
    },
    {
      $class: 'org.powerchain.tilapia.batch.producer.ProducerHarvestingHcReadItemConcept',
      slipNumberDeliveryNote: '182501300',
      harvestDate: '2018-05-29T00:00:00.000Z',
      destination: 'Medan Processing Plant',
      nurseryId: 'GTXX-IBWXX/12A-09A/SR-021-90-J/070218/UBB',"createdBy_participant":{
        "username":null,
        "email":null,
        "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        },
        "channel":{
          "title":"Bananas",
          "name":"bananas"
        },
        "user":{
          "username":"Fyffes TST staging",
          "email":"FyffesTST@pc.t2f.io",
          "org":{
            "name":"Fyffes",
            "fullname":"Fyffes",
            "logo":null
          }
        }
      }
    },
    {
      $class: 'org.powerchain.tilapia.batch.producer.ProducerHarvestingHcReadItemConcept',
      slipNumberDeliveryNote: '182501300',
      harvestDate: '2018-05-29T00:00:00.000Z',
      destination: 'Medan Processing Plant',
      nurseryId: 'GTXX-IBWXX/12A-09A/SR-021-90-J/070218/UBB',"createdBy_participant":{
        "username":null,
        "email":null,
        "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        },
        "channel":{
          "title":"Bananas",
          "name":"bananas"
        },
        "user":{
          "username":"Fyffes TST staging",
          "email":"FyffesTST@pc.t2f.io",
          "org":{
            "name":"Fyffes",
            "fullname":"Fyffes",
            "logo":null
          }
        }
      }
    },
    {
      $class: 'org.powerchain.tilapia.batch.producer.ProducerHarvestingHcReadItemConcept',
      slipNumberDeliveryNote: '182501295',
      harvestDate: '2018-05-29T00:00:00.000Z',
      destination: 'Medan Processing Plant',
      nurseryId: 'GTXX-IBWXX/12A-09A/SR-021-90-J/070218/UBB',"createdBy_participant":{
        "username":null,
        "email":null,
        "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        },
        "channel":{
          "title":"Bananas",
          "name":"bananas"
        },
        "user":{
          "username":"Fyffes TST staging",
          "email":"FyffesTST@pc.t2f.io",
          "org":{
            "name":"Fyffes",
            "fullname":"Fyffes",
            "logo":null
          }
        }
      }
    },
    {
      $class: 'org.powerchain.tilapia.batch.producer.ProducerHarvestingHcReadItemConcept',
      slipNumberDeliveryNote: '182501596',
      harvestDate: '2018-07-12T00:00:00.000Z',
      destination: 'Medan Processing Plant',
      nurseryId: 'GTXX-IBWXX/01A-01A/FS-01/SR-021-82-J/010418/UBB',"createdBy_participant":{
        "username":null,
        "email":null,
        "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        },
        "channel":{
          "title":"Bananas",
          "name":"bananas"
        },
        "user":{
          "username":"Fyffes TST staging",
          "email":"FyffesTST@pc.t2f.io",
          "org":{
            "name":"Fyffes",
            "fullname":"Fyffes",
            "logo":null
          }
        }
      }
    },
    {
      $class: 'org.powerchain.tilapia.batch.producer.ProducerHarvestingHcReadItemConcept',
      slipNumberDeliveryNote: '182501597',
      harvestDate: '2018-07-12T00:00:00.000Z',
      destination: 'Medan Processing Plant',
      nurseryId: 'GTXX-IBWXX/01A-01A/FS-01/SR-021-82-J/010418/UBB',"createdBy_participant":{
        "username":null,
        "email":null,
        "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        },
        "channel":{
          "title":"Bananas",
          "name":"bananas"
        },
        "user":{
          "username":"Fyffes TST staging",
          "email":"FyffesTST@pc.t2f.io",
          "org":{
            "name":"Fyffes",
            "fullname":"Fyffes",
            "logo":null
          }
        }
      }
    },
    {
      $class: 'org.powerchain.tilapia.batch.producer.ProducerHarvestingHcReadItemConcept',
      slipNumberDeliveryNote: '182501597',
      harvestDate: '2018-07-12T00:00:00.000Z',
      destination: 'Medan Processing Plant',
      nurseryId: 'GTXX-IBWXX/01A-01A/FS-01/SR-021-82-J/010418/UBB',"createdBy_participant":{
        "username":null,
        "email":null,
        "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        },
        "channel":{
          "title":"Bananas",
          "name":"bananas"
        },
        "user":{
          "username":"Fyffes TST staging",
          "email":"FyffesTST@pc.t2f.io",
          "org":{
            "name":"Fyffes",
            "fullname":"Fyffes",
            "logo":null
          }
        }
      }
    },
    {
      $class: 'org.powerchain.tilapia.batch.producer.ProducerHarvestingHcReadItemConcept',
      slipNumberDeliveryNote: '182501598',
      harvestDate: '2018-07-12T00:00:00.000Z',
      destination: 'Medan Processing Plant',
      nurseryId: 'GTXX-IBWXX/01A-01A/FS-01/SR-021-82-J/010418/UBB',"createdBy_participant":{
        "username":null,
        "email":null,
        "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        },
        "channel":{
          "title":"Bananas",
          "name":"bananas"
        },
        "user":{
          "username":"Fyffes TST staging",
          "email":"FyffesTST@pc.t2f.io",
          "org":{
            "name":"Fyffes",
            "fullname":"Fyffes",
            "logo":null
          }
        }
      }
    },
    {
      $class: 'org.powerchain.tilapia.batch.producer.ProducerHarvestingHcReadItemConcept',
      slipNumberDeliveryNote: '182501598',
      harvestDate: '2018-07-12T00:00:00.000Z',
      destination: 'Medan Processing Plant',
      nurseryId: 'GTXX-IBWXX/01A-01A/FS-01/SR-021-82-J/010418/UBB',"createdBy_participant":{
        "username":null,
        "email":null,
        "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        },
        "channel":{
          "title":"Bananas",
          "name":"bananas"
        },
        "user":{
          "username":"Fyffes TST staging",
          "email":"FyffesTST@pc.t2f.io",
          "org":{
            "name":"Fyffes",
            "fullname":"Fyffes",
            "logo":null
          }
        }
      }
    },
    {
      $class: 'org.powerchain.tilapia.batch.producer.ProducerHarvestingHcReadItemConcept',
      slipNumberDeliveryNote: '182501599',
      harvestDate: '2018-07-12T00:00:00.000Z',
      destination: 'Medan Processing Plant',
      nurseryId: 'GTXX-IBWXX/01A-01A/FS-01/SR-021-82-J/010418/UBB',"createdBy_participant":{
        "username":null,
        "email":null,
        "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        },
        "channel":{
          "title":"Bananas",
          "name":"bananas"
        },
        "user":{
          "username":"Fyffes TST staging",
          "email":"FyffesTST@pc.t2f.io",
          "org":{
            "name":"Fyffes",
            "fullname":"Fyffes",
            "logo":null
          }
        }
      }
    },
    {
      $class: 'org.powerchain.tilapia.batch.producer.ProducerHarvestingHcReadItemConcept',
      slipNumberDeliveryNote: '182501599',
      harvestDate: '2018-07-12T00:00:00.000Z',
      destination: 'Medan Processing Plant',
      nurseryId: 'GTXX-IBWXX/01A-01A/FS-01/SR-021-82-J/010418/UBB',"createdBy_participant":{
        "username":null,
        "email":null,
        "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        },
        "channel":{
          "title":"Bananas",
          "name":"bananas"
        },
        "user":{
          "username":"Fyffes TST staging",
          "email":"FyffesTST@pc.t2f.io",
          "org":{
            "name":"Fyffes",
            "fullname":"Fyffes",
            "logo":null
          }
        }
      }
    },
    {
      $class: 'org.powerchain.tilapia.batch.producer.ProducerHarvestingHcReadItemConcept',
      slipNumberDeliveryNote: '182501600',
      harvestDate: '2018-07-12T00:00:00.000Z',
      destination: 'Medan Processing Plant',
      nurseryId: 'GTXX-IBWXX/01A-01A/FS-01/SR-021-82-J/010418/UBB',"createdBy_participant":{
        "username":null,
        "email":null,
        "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        },
        "channel":{
          "title":"Bananas",
          "name":"bananas"
        },
        "user":{
          "username":"Fyffes TST staging",
          "email":"FyffesTST@pc.t2f.io",
          "org":{
            "name":"Fyffes",
            "fullname":"Fyffes",
            "logo":null
          }
        }
      }
    },
    {
      $class: 'org.powerchain.tilapia.batch.producer.ProducerHarvestingHcReadItemConcept',
      slipNumberDeliveryNote: '182501600',
      harvestDate: '2018-07-12T00:00:00.000Z',
      destination: 'Medan Processing Plant',
      nurseryId: 'GTXX-IBWXX/01A-01A/FS-01/SR-021-82-J/010418/UBB',"createdBy_participant":{
        "username":null,
        "email":null,
        "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        },
        "channel":{
          "title":"Bananas",
          "name":"bananas"
        },
        "user":{
          "username":"Fyffes TST staging",
          "email":"FyffesTST@pc.t2f.io",
          "org":{
            "name":"Fyffes",
            "fullname":"Fyffes",
            "logo":null
          }
        }
      }
    },
    {
      $class: 'org.powerchain.tilapia.batch.producer.ProducerHarvestingHcReadItemConcept',
      slipNumberDeliveryNote: '182501601',
      harvestDate: '2018-07-12T00:00:00.000Z',
      destination: 'Medan Processing Plant',
      nurseryId: 'GTXX-IBWXX/01A-01A/FS-01/SR-021-82-J/010418/UBB',"createdBy_participant":{
        "username":null,
        "email":null,
        "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        },
        "channel":{
          "title":"Bananas",
          "name":"bananas"
        },
        "user":{
          "username":"Fyffes TST staging",
          "email":"FyffesTST@pc.t2f.io",
          "org":{
            "name":"Fyffes",
            "fullname":"Fyffes",
            "logo":null
          }
        }
      }
    },
    {
      $class: 'org.powerchain.tilapia.batch.producer.ProducerHarvestingHcReadItemConcept',
      slipNumberDeliveryNote: '182501596',
      harvestDate: '2018-07-12T00:00:00.000Z',
      destination: 'Medan Processing Plant',
      nurseryId: 'GTXX-IBWXX/01A-01A/FS-01/SR-021-82-J/010418/UBB',"createdBy_participant":{
        "username":null,
        "email":null,
        "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        },
        "channel":{
          "title":"Bananas",
          "name":"bananas"
        },
        "user":{
          "username":"Fyffes TST staging",
          "email":"FyffesTST@pc.t2f.io",
          "org":{
            "name":"Fyffes",
            "fullname":"Fyffes",
            "logo":null
          }
        }
      }
    },
  ],
  nurseryList: [
    {
      $class: 'org.powerchain.tilapia.batch.producer.ProducerNurseryReadItemConcept',
      nurseryId: 'GTXX-IBWXX/12A-09A/SR-021-90-J/070218/UBB',
      itemName: 'Fingerling Tilapia',
      size: 27.9,
      fishCounts: 174276,
      feedSupplier: 'PT. Aquafarm Nusantara, Medan-AFGA-3',
      feedType: '3,0 mm',
      feedQty: 120,"createdBy_participant":{
        "username":null,
        "email":null,
        "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        },
        "channel":{
          "title":"Bananas",
          "name":"bananas"
        },
        "user":{
          "username":"Fyffes TST staging",
          "email":"FyffesTST@pc.t2f.io",
          "org":{
            "name":"Fyffes",
            "fullname":"Fyffes",
            "logo":null
          }
        }
      }
    },
    {
      $class: 'org.powerchain.tilapia.batch.producer.ProducerNurseryReadItemConcept',
      nurseryId: 'GTXX-IBWXX/12A-09A/SR-021-90-J/070218/UBB',
      itemName: 'Fingerling Tilapia',
      size: 27.563,
      fishCounts: 278292,
      feedSupplier: 'PT. Aquafarm Nusantara, Medan-AFSA-2',
      feedType: '2,0 mm',
      feedQty: 1320,"createdBy_participant":{
        "username":null,
        "email":null,
        "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        },
        "channel":{
          "title":"Bananas",
          "name":"bananas"
        },
        "user":{
          "username":"Fyffes TST staging",
          "email":"FyffesTST@pc.t2f.io",
          "org":{
            "name":"Fyffes",
            "fullname":"Fyffes",
            "logo":null
          }
        }
      }
    },
    {
      $class: 'org.powerchain.tilapia.batch.producer.ProducerNurseryReadItemConcept',
      nurseryId: 'GTXX-IBWXX/01A-01A/FS-01/SR-021-82-J/010418/UBB',
      itemName: 'Fingerling Tilapia',
      size: 18.878,
      fishCounts: 291343,
      feedSupplier: 'PT. Aquafarm Nusantara, Medan-AFGA-3',
      feedType: '3,0 mm',
      feedQty: 660,"createdBy_participant":{
        "username":null,
        "email":null,
        "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        },
        "channel":{
          "title":"Bananas",
          "name":"bananas"
        },
        "user":{
          "username":"Fyffes TST staging",
          "email":"FyffesTST@pc.t2f.io",
          "org":{
            "name":"Fyffes",
            "fullname":"Fyffes",
            "logo":null
          }
        }
      }
    },
  ],
  massBalanceList: [
    {
      $class: 'org.powerchain.tilapia.batch.producer.ProducerMassBalanceReadItemConcept',
      farmside: 'NGB.20190322.F',
      totalRmKg: 9452.55,
      totalUfKg: 2764.6648,
      yieldPercentage: 29.2478,"createdBy_participant":{
        "username":null,
        "email":null,
        "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        },
        "channel":{
          "title":"Bananas",
          "name":"bananas"
        },
        "user":{
          "username":"Fyffes TST staging",
          "email":"FyffesTST@pc.t2f.io",
          "org":{
            "name":"Fyffes",
            "fullname":"Fyffes",
            "logo":null
          }
        }
      }
    },
    {
      $class: 'org.powerchain.tilapia.batch.producer.ProducerMassBalanceReadItemConcept',
      farmside: 'NGB.20190322.E',
      totalRmKg: 23034.35,
      totalUfKg: 7386.8798,
      yieldPercentage: 32.0689,"createdBy_participant":{
        "username":null,
        "email":null,
        "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        },
        "channel":{
          "title":"Bananas",
          "name":"bananas"
        },
        "user":{
          "username":"Fyffes TST staging",
          "email":"FyffesTST@pc.t2f.io",
          "org":{
            "name":"Fyffes",
            "fullname":"Fyffes",
            "logo":null
          }
        }
      }
    },
  ],
});
