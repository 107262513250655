import React from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
// styles
import injectSheet, { WithStyles } from 'react-jss';
import stylesModelSettingsDropdown from 'components/Dropdown/styles/modelSettingsDropdownStyles';
// etc
import { SettingsSvg, CheckSvg, LogoutSvg } from 'components/icons';
// actions
import * as envActions from 'domain/env/actions';
// selectors
import { getDisplayModel, getModel } from 'domain/common';
// utils
import { useOutsideClick } from 'lib/utils';
// types
import { AppStateType } from 'types';
import { ModelObjectDefault } from 'lib/lib';

interface OwnProps extends WithStyles<typeof stylesModelSettingsDropdown> {}

const mapStateToProps = (state: AppStateType) => ({
  model: getModel(state),
  displayModel: getDisplayModel(state),
});

const ModelSettingsDropDown: React.FC<OwnProps> = (props) => {
  const dispatch = useDispatch();
  const { isOpen, setIsOpen, ref } = useOutsideClick(false);
  const { model, displayModel } = useSelector(mapStateToProps, shallowEqual);
  const { classes } = props;

  const toggleClick = () => setIsOpen(!isOpen);

  const saveSettingsToProfile = () => dispatch(envActions.setModelSettingsAction());

  const restoreDefaultColumns = () => dispatch(envActions.setDefaultModelSettingsAction());

  const handleColumnItemClick = (tabName: string, columnName: string) =>
    dispatch(envActions.toggleModelFieldAction({ tabName, columnName }));

  const verifyTabExists = (tabName: string) => displayModel[tabName];

  const verifyTabPropertyExists = (tabName: string, tabFieldName: string) =>
    displayModel[tabName].find(({ key }) => key === tabFieldName);

  const shouldBeChecked = (parentTabKey: string, tabFieldName: string) => {
    /* verify model has tab and tab has property */
    if (verifyTabExists(parentTabKey) && verifyTabPropertyExists(parentTabKey, tabFieldName)) {
      return <CheckSvg />;
    } else {
      return null;
    }
  };

  const formColumnRow = (column: ModelObjectDefault, index: number, parentTabKey: string) => {
    const { tabs } = model;
    const { title, key } = column;
    const isSingleTab = tabs && tabs.length && tabs.length < 2;

    return (
      <li
        className={`${classes.columnListItem}${
          isSingleTab ? ` ${classes.columnListItemSingleTab}` : ''
        }`}
        key={index}
        onClick={() => handleColumnItemClick(parentTabKey, key)}
        data-cy="settingsItem"
      >
        <span className={classes.svgContainer}>{shouldBeChecked(parentTabKey, key)}</span>
        <span>{title}</span>
      </li>
    );
  };

  const formColumnsList = (columnsArray: ModelObjectDefault[], parentTabKey: string) => {
    const columnArrayWithoutServiceFields = columnsArray.filter(
      ({ isServiceField }) => !isServiceField,
    );
    return columnArrayWithoutServiceFields.map((column, i) =>
      formColumnRow(column, i, parentTabKey),
    );
  };

  const formTabColumnsList = (tabKey: string) => {
    const { tabs } = model;

    const isSingleTab = tabs && tabs.length && tabs.length < 2;

    const tabInfo = tabs.find(({ modelName }) => modelName === tabKey);
    const modelName = tabInfo ? tabInfo.modelName : 'defaultTab';
    const tabColumns = model[tabKey];

    return (
      <div
        key={modelName}
        className={`${classes.tabContainer}${
          isSingleTab ? ` ${classes.tabContainerSingleTab}` : ''
        }`}
      >
        {!isSingleTab && <h5 className={classes.tabname}>{tabInfo ? tabInfo.title : ''}</h5>}
        <ul
          className={`${classes.columnList}${isSingleTab ? ` ${classes.columnListSingleTab}` : ''}`}
        >
          {formColumnsList(tabColumns, modelName)}
        </ul>
      </div>
    );
  };

  const displayControlPanel = () => (
    <div className={classes.submit}>
      <div className="accept" onClick={saveSettingsToProfile} title="save selected to profile">
        <CheckSvg />
        <span>Save to profile</span>
      </div>

      <div className="cancel" onClick={restoreDefaultColumns} title="reset to default model fields">
        <LogoutSvg />
        <span>Restore defaults</span>
      </div>
    </div>
  );

  const proceedByModelTabs = () => model.tabs.map(({ modelName }) => formTabColumnsList(modelName));

  return (
    <div
      className={classes.tableIcon}
      ref={ref}
      // onClick={(e) => {
      //   e.stopPropagation();
      // }}
      data-cy="settings"
    >
      <SettingsSvg onClick={toggleClick} />

      {isOpen && (
        <div className={classes.dropdownWrapper}>
          {proceedByModelTabs()}
          {displayControlPanel()}
        </div>
      )}
    </div>
  );
};

export default injectSheet(stylesModelSettingsDropdown)(ModelSettingsDropDown);
