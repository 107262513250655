import defaultTab from 'models/certificates/bananas/tabs/defaultTabModel';
import { MODEL_TYPES } from 'themes/constants';

export default () => ({
  tabs: [
    {
      modelName: 'defaultTab',
      originalName: MODEL_TYPES.CERTIFICATE,
      title: MODEL_TYPES.CERTIFICATE,
    },
  ],
  defaultTab: defaultTab,
});
