export default () => [
  {
    id: 1864,
    is_read: false,
    created_at: '2019-08-13T10:10:57.650Z',
    event: {
      id: 590,
      entityType: 'ProcessorBatch',
      entityId: '871845224103',
      statusChangedTo: null,
      eventDoneAt: '2019-08-13T10:10:54.000Z',
      actionType: 'CREATED',
      actionSource: 'frontEndApp',
      channel: { title: 'Tilapia', name: 'tilapia' },
      eventType: { id: 1, title: 'Batch upload', slug: 'BatchUpload' },
      eventDoneBy: {
        username: 'MayonnaTST',
        email: 'MayonnaTST@pc.t2f.io',
        org: { name: 'Mayonna', fullname: 'Mayonna', logo: null },
      },
    },
  },
  {
    id: 1559,
    is_read: true,
    created_at: '2019-08-06T10:00:00.701Z',
    event: {
      id: 466,
      entityType: 'CertFile',
      entityId: '39fb105c-b46d-4a6a-b004-2385cdd258a1',
      statusChangedTo: null,
      eventDoneAt: '2019-08-06T09:59:57.000Z',
      actionType: 'CREATED',
      actionSource: null,
      channel: { title: 'Tilapia', name: 'tilapia' },
      eventType: { id: 4, title: 'Certificate upload', slug: 'CertificateUpload' },
      eventDoneBy: {
        username: 'MayonnaTST',
        email: 'MayonnaTST@pc.t2f.io',
        org: { name: 'Mayonna', fullname: 'Mayonna', logo: null },
      },
    },
  },
];
