import { applyMiddleware, createStore } from 'redux';
import createSagaMiddleware, { SagaMiddleware } from 'redux-saga';
import { routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';

import { composeWithDevTools } from 'redux-devtools-extension';

import { DynamicStore } from './index';
import { AppStateType } from 'types';

import createReducer from './reducers';
import rootSaga from 'sagas';
// import { createInitialEnvState } from './env';

export const history = createBrowserHistory();

const sagaMiddleware: SagaMiddleware = createSagaMiddleware();
const middlewares = [sagaMiddleware, routerMiddleware(history)];
const enhancers = [applyMiddleware(...middlewares)];

const createCustomStore = (initialState: Partial<AppStateType>): DynamicStore => {
  const store: Partial<DynamicStore> = createStore(
    createReducer({}, history),
    initialState,
    composeWithDevTools({
      // actionCreators: actions,
      latency: 25,
    })(...enhancers),
  );

  // Extensions
  store.runSaga = sagaMiddleware.run;
  // store.close = () => (store as DynamicStore).dispatch(END);

  // store.injectedReducers = {}; // Reducer registry
  // store.injectedSagas = {}; // Saga registry

  // if (process.env.NODE_ENV === 'development') {
  //   window.__SAGA_REGISTRY__ = store.injectedSagas;
  // }

  return store as DynamicStore;
};

export default function configureStore(initialState: Partial<AppStateType> = {}): DynamicStore {
  const store = createCustomStore({
    ...initialState,
    env: require('./env').createInitialEnvState(),
  });
  sagaMiddleware.run(rootSaga);

  // Make reducers hot reloadable
  // if (module.hot) {
  //   module.hot.accept(['./configureStore', './reducers'], () => {
  //     store.replaceReducer(createReducer(store.injectedReducers, history));
  //   });
  // }

  return store;
}
