export default (theme) => ({
  formWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputField: {
    outline: 0,
    minWidth: theme.authLayout.inputMinWidth,
    height: theme.authLayout.inputHeight,
    borderRadius: theme.authLayout.borderRadius,
    backgroundColor: 'transparent',
    padding: [5, 10],
    color: theme.colors.white,
    border: [1, 'solid', theme.colors.white],
    '&::placeholder': {
      color: theme.colors.white,
    },
  },
  loginBtn: {
    minWidth: theme.authLayout.inputMinWidth,
    height: theme.authLayout.inputHeight,
    borderRadius: theme.authLayout.borderRadius,
    color: theme.colors.white,
    padding: [0, 15],
    fontSize: theme.fontSizes.sm,
    textAlign: 'center',
    backgroundColor: theme.colors.loginButton,
    border: 0,
    outline: 0,
    cursor: 'pointer',
  },
  formGroup: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap',
    marginBottom: 0,
    '& span': {
      color: theme.colors.errorLight,
    },
    '&:after': {
      display: 'table',
      content: ' ',
    },
    '& select, & input': {
      lineHeight: 1.42857143,
      boxShadow: 'inset 0 1px 1px rgba(0,0,0,.075)',
      transition: 'border-color ease-in-out .15s,box-shadow ease-in-out .15s',
    },
  },
});
