export default () => [
  {
    $class: 'org.powerchain.bananas.batch.GroupedProducerBatchesConcept',
    floId: '6168',
    farmCodeCount: 1,
    minStuffedDate: '2019-05-21T00:00:00.000Z',
    maxStuffedDate: '2019-05-22T00:00:00.000Z',
    minDepartureDate: '2019-05-24T00:00:00.000Z',
    maxDepartureDate: '2019-05-25T00:00:00.000Z',
    SSCCPalletCount: 2,
    status: 'PENDING',
    createdAtMin: '2019-07-25T09:48:07.152Z',
    createdAtMax: '2019-07-25T09:48:07.152Z',
    createdBy:
      'resource:org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8',
    "modifiedBy_participant":{
      "username":null,
      "email":null,
      "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
      "org":{
        "name":"Fyffes",
        "fullname":"Fyffes",
        "logo":null
      },
      "channel":{
        "title":"Bananas",
        "name":"bananas"
      },
      "user":{
        "username":"Fyffes TST staging",
        "email":"FyffesTST@pc.t2f.io",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        }
      }
    },
    "createdBy_participant":{
      "username":null,
      "email":null,
      "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
      "org":{
        "name":"Fyffes",
        "fullname":"Fyffes",
        "logo":null
      },
      "channel":{
        "title":"Bananas",
        "name":"bananas"
      },
      "user":{
        "username":"Fyffes TST staging",
        "email":"FyffesTST@pc.t2f.io",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        }
      }
    }
  },
];
