import React, { useCallback, useLayoutEffect, useMemo } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import * as batchesActions from 'domain/batches/actions';
// components
import Datatable from 'components/Datatable/datatable';
import { getChannelName, getUsers } from 'domain/env';
import { getBatches, getIsLoading } from 'domain/batches';
import { getDisplayModel, getIndexEndpoint, getModel, getQueryParams } from 'domain/common';
import { UploadWidget } from './components/AccessWidgets';
// utils
import { BATCH_STATUS, CHANNELS, ORGANIZATIONS } from 'themes/constants';
import { getTimePeriods } from 'pages/batches/Forms/listValues';
// lib
import { participantNameFixBatches } from 'lib/helpers';
// actions
// jss
import injectSheet, { WithStyles } from 'react-jss';
import batchesStyle from 'pages/batches/styles/batchesStyle';
import { DropDownTabGroup } from 'components/Dropdown/TabDropDownRange';
import { AppStateType } from 'types';

const mapStateToProps = (reducerState: AppStateType) => ({
  batches: getBatches(reducerState),
  users: getUsers(reducerState),
  isLoading: getIsLoading(reducerState),
  channelName: getChannelName(reducerState),
  model: getModel(reducerState),
  queryParams: getQueryParams(reducerState),
  displayModel: getDisplayModel(reducerState),
  endpoint: getIndexEndpoint(reducerState),
});

const Batches: React.FC<WithStyles<typeof batchesStyle>> = (props) => {
  const { classes } = props;
  const {
    batches,
    users,
    isLoading,
    channelName,
    model,
    queryParams,
    displayModel,
    endpoint,
  } = useSelector(mapStateToProps, shallowEqual);
  const { beginDate, endDate, status, sortingKey, sortingValue } = queryParams;
  const dispatch = useDispatch();

  const tabs = useMemo(
    () => [
      {
        title: 'All Batches',
        id: BATCH_STATUS.ALL,
        defaultSelected: true,
      },
      {
        title: 'Archived Batches',
        id: BATCH_STATUS.ARCHIVED,
      },
    ],
    [],
  );

  const proceedBatches = useCallback(() => {
    return participantNameFixBatches(batches, users);
  }, [batches, users]);

  useLayoutEffect(() => {
    if (beginDate && endDate && status) {
      const queryStringParams = {
        filter: {
          beginDate: queryParams.beginDate,
          endDate: queryParams.endDate,
          status: queryParams.status === BATCH_STATUS.ARCHIVED ? queryParams.status : undefined,
        },
        sorting: {
          property: queryParams.sortingKey,
          direction: queryParams.sortingValue,
        },
      };
      dispatch(batchesActions.fetchBatchesAction({ batchEndpoint: endpoint, queryStringParams }));
    }
  }, [beginDate, endDate, status, sortingKey, sortingValue]);

  return (
    <section className={classes.component}>
      <div className={classes.tableHeader}>
        <DropDownTabGroup tabs={tabs} valuesList={getTimePeriods(channelName)} />

        <UploadWidget />
      </div>

      <Datatable
        data={proceedBatches()}
        columns={displayModel}
        isLoading={isLoading}
        settingsAndActions={model && model.settingsAndActions}
      />
    </section>
  );
};

export default injectSheet(batchesStyle)(Batches);
