// helpers
import { renderShortDateFormat } from 'lib/renderHelpers';
// config
import { validationRequired, validationInt, validationString } from 'lib/uploadFieldValidations';
import { BatchRecord } from 'lib/lib';

export default () => {
  return [
    {
      key: 'colombiaSSCCPallet',
      label: 'Colombia SSCC pallet #',
      title: 'Colombia SSCC pallet #',
      style: { flex: '1 0 315px' },
      renderUploadPreview: (column: BatchRecord) => column.colombiaSSCCPallet,
      render: (column: BatchRecord) => column.colombiaSSCCPallet,
      validate: [validationRequired, validationInt],
      defaultSelectedColumn: true,
      defaultSelectedBatchContractColumn: true,
    },
    {
      key: 'receiptDate',
      label: 'Date receipt',
      title: 'Date receipt',
      style: { flex: '1 0 315px' },
      renderUploadPreview: (column: BatchRecord) => renderShortDateFormat(column.receiptDate),
      render: (column: BatchRecord) => renderShortDateFormat(column.receiptDate),
      validate: [validationRequired, validationString],
      defaultSelectedColumn: true,
      defaultSelectedBatchContractColumn: true,
    },
    {
      key: 'boxes',
      label: '# boxes',
      title: '# boxes',
      style: { flex: '1 0 315px' },
      renderUploadPreview: (column: BatchRecord) => column.boxes,
      render: (column: BatchRecord) => column.boxes,
      validate: [validationRequired, validationInt],
      defaultSelectedColumn: true,
      defaultSelectedBatchContractColumn: true,
    },
  ];
};
