import Route from 'route-parser';

import jdeBatchIndexListResponseOranges from 'mocks/responses/channels/oranges/jdebatch/indexList';
import jdeBatchContractListResponseOranges from 'mocks/responses/channels/oranges/jdebatch/contractList';
import jdeBatchDetailsResponseOranges from 'mocks/responses/channels/oranges/jdebatch/details';
import uploadBatchResponse from 'mocks/responses/batchUpload/batchUploadEggsOranges';

import { deleleConractResponseEggsOranges } from 'mocks/router/utils';

export default (request) => {
  const route = new Route('/api/v1/oranges/jdebatch(?*filter)(/:batchId)(/contracts/:contractId)');
  const parameters = route.match(request.url);
  if (parameters) {
    if (parameters.filter) {
      if (parameters.filter.includes('beginDate')) {
        return [200, jdeBatchIndexListResponseOranges()];
      }
    }

    if (parameters.contractId) {
      if (request.method === 'delete') {
        return [200, deleleConractResponseEggsOranges(parameters.contractId, 'jde')];
      }

      return [200, jdeBatchContractListResponseOranges()];
    }
    if (parameters.batchId) {
      return [200, jdeBatchDetailsResponseOranges()];
    }
  }

  if (request.method === 'post') {
    return [200, uploadBatchResponse(JSON.parse(request.data))];
  }

  return [500, `url not proceeded: ${request.url}`];
};
