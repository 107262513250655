import React from 'react';
import { Link } from 'react-router-dom';
// utils
import { getUrl } from 'routes';
import { BatchRecord } from 'lib/lib';

interface OwnProps {
  column: BatchRecord;
  className?: string;
}

const ValidateBatchLink: React.FC<OwnProps> = ({ column, className = 'validate-batch' }) => {
  return (
    <Link
      type="button"
      className={className}
      to={{ pathname: getUrl('BatchValidation', { batchNumber: column.batchNumber }) }}
    >
      Validate
    </Link>
  );
};

export default React.memo(ValidateBatchLink);
