import React, {useState, useRef, useEffect, useLayoutEffect} from 'react';
import { connect } from 'react-redux';
import { ArrowDownSvg, ArrowUpSvg } from '../icons';
import * as envActions from 'domain/env/actions';
import { SimpleTabGroupI, TabI, TabTitleButton } from './SimpleTabGroup';
import { BATCH_STATUS } from 'themes/constants';
import { getQueryParams } from 'domain/common';
import { AppStateType } from 'types';
import { IndexedObject } from 'lib/lib';

interface ListItem {
  title: string;
  value: number;
  defaultSelected?: boolean;
}

interface TabItem {
  title: string;
  id: BATCH_STATUS;
  defaultSelected?: boolean;
}

interface TabDropdownI extends TabI {
  valuesList: Array<ListItem>;
  selectedListValue: ListItem;
  changeSelectedListItem: (listItem: ListItem) => void;
}

interface DropdownTabGroupProps<T extends TabDropdownI> extends SimpleTabGroupI<TabItem> {
  valuesList: Array<ListItem>;
  activeListValue?: number;
  queryParams: IndexedObject<string>;
  commitFilters: (params: object) => void;
}

export const TabsContainer: React.FC = (props) => <div className="tabs">{props.children}</div>;

export const DropdownTab = (props: TabDropdownI) => {
  const [isOpened, changeListStatus] = useState(false);
  const dropdownRef = useRef(null); // React.createRef<HTMLDivElement>();

  const refClickHandler = () => changeListStatus(false);

  useEffect(() => {
    if (isOpened) {
      document.addEventListener('click', refClickHandler);
    }

    return () => document.removeEventListener('click', refClickHandler);
  }, [isOpened]);

  const FormValuesDropDown = () => {
    const { valuesList, changeSelectedListItem, setActiveTab, id } = props;

    return (
      <ul className={isOpened ? 'isDropDownListOpen' : ''}>
        {valuesList.map((listItem) => {
          return (
            <li
              onClick={() => {
                changeSelectedListItem(listItem);
                setActiveTab(id);
              }}
              key={listItem.title}
            >
              <button>{listItem.title}</button>
            </li>
          );
        })}
      </ul>
    );
  };

  const FormCurrentSelectedValue = () => {
    const { selectedListValue } = props;

    return (
      <button type={'button'}>
        {isOpened ? <ArrowUpSvg /> : <ArrowDownSvg />}
        {selectedListValue.title}
      </button>
    );
  };

  const { setActiveTab, id, title, isActive } = props;

  return (
    <div className={`tab select ${isActive ? 'active' : ''}`}>
      <TabTitleButton setActiveTab={setActiveTab} id={id} title={title} />

      <div ref={dropdownRef} onClick={() => changeListStatus(!isOpened)} className="drop-down-list">
        <FormCurrentSelectedValue />

        <FormValuesDropDown />
      </div>
    </div>
  );
};

const DropdownTabsGroup = (props: DropdownTabGroupProps<TabDropdownI>) => {
  const getDefaultSelectedTabDropdownId = () => {
    const { tabs } = props;

    const defaultSelected = tabs.find(({ defaultSelected }) => defaultSelected);
    if (!defaultSelected) {
      throw new Error(
        `There should be default selected tab for first render / no props, received  ${JSON.stringify(
          tabs,
        )}`,
      );
    } else {
      return defaultSelected.id;
    }
  };

  const defineActiveTab = () => {
    const { activeTabId } = props;

    return activeTabId ? activeTabId : getDefaultSelectedTabDropdownId();
  };

  const findDefaultListValue = (): ListItem => {
    const { valuesList } = props;

    const defaultSelectedValue = valuesList.find((value) => value.defaultSelected);

    if (defaultSelectedValue) {
      return defaultSelectedValue;
    }
    return valuesList[0];
  };

  const [selectedListValue, setSelectedListValue] = useState(findDefaultListValue());
  const [activeTabId, setActiveTab] = useState(defineActiveTab());

  useLayoutEffect(() => {
    const { value } = selectedListValue;
    const { queryParams } = props;

    props.commitFilters({
      ...queryParams,
      status: activeTabId,
      days: value,
    });
  }, [selectedListValue, activeTabId]);

  const mapTabs = () => {
    const { tabs, valuesList } = props;

    return tabs.map((tab) => (
      <DropdownTab
        setActiveTab={setActiveTab}
        id={tab.id}
        title={tab.title}
        selectedListValue={selectedListValue}
        valuesList={valuesList}
        isActive={activeTabId === tab.id}
        key={tab.title}
        changeSelectedListItem={setSelectedListValue}
      />
    ));
  };

  return <TabsContainer>{mapTabs()}</TabsContainer>;
};

export const DropDownTabGroup = connect(
  (state: AppStateType) => ({
    queryParams: getQueryParams(state),
  }),
  {
    commitFilters: (filters: {}) => ({
      type: envActions.setTabsQueryParamsAction.success,
      payload: filters,
    }),
  },
)(DropdownTabsGroup);
