import React from 'react';
// utils
import { Link } from 'react-router-dom';
import { BATCH_STATUS, ORGANIZATIONS } from 'themes/constants';
import { renderTwoShortDateFormat, renderShortDateFormat } from 'lib/renderHelpers';
import { getUrl } from 'routes';
import { validationRequired } from 'lib/validations';
import { BatchRecord, ModelRequiredInfo } from 'lib/lib';
import { getCreatorNameAndOrgName } from 'models/utils/utils';

export default (modelRequiredInfo: ModelRequiredInfo) => [
  {
    key: 'banafoodSSCCPallet',
    label: 'Banafood SSCC pallet #',
    title: 'Banafood SSCC pallet #',
    style: { flex: '1 0 120px' },
    renderExportValue: (column: BatchRecord) => `${column.batchNumber}`,
    defaultSelectedColumn: true,
    defaultSelectedBatchContractColumn: true,
    render: (column: BatchRecord) =>
      modelRequiredInfo.params && modelRequiredInfo.params.status === BATCH_STATUS.ARCHIVED ? (
        <Link
          to={{
            pathname: getUrl('bananasBatchDetails', {
              type: ORGANIZATIONS.BANAFOOD,
              batchCode: encodeURIComponent(encodeURIComponent(column.banafoodSSCCPallet)),
              status:
                modelRequiredInfo.params &&
                modelRequiredInfo.params.status === BATCH_STATUS.ARCHIVED
                  ? modelRequiredInfo.params.status
                  : '',
              modelType: 'details',
            }),
          }}
          title={column.banafoodSSCCPallet}
          className="contract"
        >
          {column.banafoodSSCCPallet}
        </Link>
      ) : (
        <Link
          to={{
            pathname: getUrl('bananasBatch', {
              type: ORGANIZATIONS.BANAFOOD,
              batchCode: encodeURIComponent(encodeURIComponent(column.banafoodSSCCPallet)),
              modelType: 'details',
            }),
          }}
          title={column.banafoodSSCCPallet}
          className="contract"
        >
          {column.banafoodSSCCPallet}
        </Link>
      ),
  },
  {
    key: 'antwerpSSCCCount',
    label: 'Unique Antwerp SSCC Fyffes OUT count',
    title: 'Unique Antwerp SSCC Fyffes OUT count',
    style: { flex: '1 0 70px' },
    defaultSelectedBatchContractColumn: true,
    renderExportValue: (column: BatchRecord) => column.antwerpSSCCCount,
    render: (column: BatchRecord) => column.antwerpSSCCCount,
    defaultSelectedColumn: true,
  },
  {
    key: 'dateDelivery',
    label: 'Date delivery',
    title: 'Date delivery',
    style: { flex: '1 0 100px' },
    defaultSelectedBatchContractColumn: true,
    renderExportValue: (column: BatchRecord) => {
      if (!column.minDeliveryDate || !column.maxDeliveryDate) {
        return '';
      }

      return renderTwoShortDateFormat(column.minDeliveryDate, column.maxDeliveryDate);
    },
    render: (column: BatchRecord) => {
      if (!column.minDeliveryDate || !column.maxDeliveryDate) {
        return null;
      }

      return renderTwoShortDateFormat(column.minDeliveryDate, column.maxDeliveryDate);
    },
    defaultSelectedColumn: true,
    edit: { type: 'date' },
  },
  {
    key: 'dateReceipt',
    label: 'Date receipt',
    title: 'Date receipt',

    style: { flex: '1 0 100px' },
    defaultSelectedBatchContractColumn: true,
    renderExportValue: (column: BatchRecord) => {
      if (!column.minReceiptDate || !column.maxReceiptDate) {
        return '';
      }

      return renderTwoShortDateFormat(column.minReceiptDate, column.maxReceiptDate);
    },
    render: (column: BatchRecord) => {
      if (!column.minReceiptDate || !column.maxReceiptDate) {
        return null;
      }

      return renderTwoShortDateFormat(column.minReceiptDate, column.maxReceiptDate);
    },
    defaultSelectedColumn: true,
    edit: { type: 'date' },
  },
  {
    key: 'createdAtMin',
    label: 'Created At',
    title: 'Created At',
    isSortable: true,
    style: { flex: '1 0 80px' },
    defaultSelectedColumn: true,
    renderExportValue: (column: BatchRecord) => column.createdAtMin,
    render: (column: BatchRecord) => renderShortDateFormat(column.createdAtMin),
  },
  {
    key: 'createdAtMax',
    label: 'Last Updated',
    title: 'Last Updated',
    isSortable: true,
    style: { flex: '1 0 80px' },
    defaultSelectedColumn: true,
    renderExportValue: (column: BatchRecord) => column.createdAtMax,
    render: (column: BatchRecord) => renderShortDateFormat(column.createdAtMax),
  },
  {
    key: 'createdBy',
    label: 'Created By',
    title: 'Created By',
    style: { flex: '1 0 120px' },
    renderExportValue: (record: BatchRecord) => getCreatorNameAndOrgName(record),
    render: (record: BatchRecord) => getCreatorNameAndOrgName(record),
    validate: [validationRequired],
    defaultSelectedColumn: true,
    defaultSelectedBatchContractColumn: true,
  },
];
