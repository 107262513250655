import React, { useMemo, useState } from 'react';
import CSV from 'papaparse';
//
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
// utils
import { formatBytes, toast } from 'lib/helpers';
import { getIsLoading } from 'domain/batches';
import { getModel } from 'domain/common';
// actions
import * as batchesActions from 'domain/batches/actions';
// components
import Loader from 'components/Loaders/smallLoader';
import BatchAddPreviewTable from 'pages/batches/components/BatchAddPreviewTable';
import DownloadTemplateLink from './DownloadTemplateLink';
// icons
import { UploadSvg, CheckSvg, ShowSvg, DeleteSvg } from 'components/icons';
// jss
import injectSheet, { WithStyles } from 'react-jss';
import batchAddStyles from 'pages/batches/components/UploadCSVFileStyles';
import { AppStateType } from 'types';
import { getOwnOrgName } from 'domain/env';
import { ORGANIZATIONS } from 'themes/constants';

export interface InputValue {
  file: File;
  data: Record<string, string>[];
  originData: Record<string, string>[];
  previewData: Record<string, React.ReactNode>[];
  validationData: Record<string, boolean>[];
  fields: string[];
  status: boolean;
}

interface OwnProps extends WithStyles<typeof batchAddStyles> {
  handleSubmit: (e: React.FormEvent) => void;
}

const mapStateToProps = (state: AppStateType) => ({
  isLoading: getIsLoading(state),
  model: getModel(state),
  orgName: getOwnOrgName(state),
});

const UploadCSVFile: React.FC<OwnProps> = ({ classes, handleSubmit }) => {
  const dispatch = useDispatch();
  const [isUploaded, setIsUploaded] = useState(false);
  const [inputValues, setInputValues] = useState<InputValue[]>([]);
  const [fileToShow, setFileToShow] = useState<string | undefined>();
  const { isLoading, model, orgName } = useSelector(mapStateToProps, shallowEqual);

  const modelFields = useMemo(() => model.defaultTab, [model]);

  const FileStatusIcon = useMemo(() => (isUploaded ? CheckSvg : UploadSvg), [isUploaded]);

  const readFile = (file: File) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onerror = reject;
      reader.onload = ({ target }) => (target && target.result ? resolve(target.result) : reject());
      reader.readAsText(file);
    });

  const validateUploadedCSVStructure = (file: File, fields: string[]) => {
    // 1
    if (inputValues.find((f) => f.file.name === file.name && f.file.size === file.size)) {
      throw new Error('Error! This file has been already added.');
    }

    // 2
    const skippedFields = fields.reduce<string[]>(
      (acc, field) =>
        !modelFields.find((modelField) => modelField.label.toLowerCase() === field.toLowerCase())
          ? [...acc, field]
          : acc,
      [],
    );

    if (skippedFields.length) {
      throw new Error(
        `Error! Check if it is a right file. There're fields in file you trying to upload which don't exist in current batch model: "${skippedFields.join(
          ', ',
        )}."`,
      );
    }
  };

  const parseAndNormaliseHandledUploadedCSV = (newData: Record<string, string>[]) => {
    const batchesToSend: Record<string, string>[] = [];
    const originData: Record<string, string>[] = [];
    const previewData: Record<string, React.ReactNode>[] = [];
    const validationData: Record<string, boolean>[] = [];

    newData.forEach((batch) => {
      // handled origin parsed with handleParsedValueFromCSVFile() function
      const newBatch: Record<string, string> = {};
      // parsed as it is in file
      const originBatch: Record<string, string> = {};
      // newBatch data with  renderPreviewBeforeUpload function
      const previewBatch: Record<string, React.ReactNode> = {};
      const validationErrors: Record<string, boolean> = {};

      Object.keys(batch).forEach((batchFieldKey) => {
        // if have such batch field name from file in our label model
        const currentModel = modelFields.find(
          (m) => m.label.toLowerCase() === batchFieldKey.toLowerCase(),
        );
        if (currentModel) {
          newBatch[currentModel.key] = currentModel.handleParsedValueFromCSVFile
            ? currentModel.handleParsedValueFromCSVFile(batch[batchFieldKey])
            : batch[batchFieldKey];
          originBatch[currentModel.key] = batch[batchFieldKey];
          previewBatch[currentModel.key] = currentModel.renderPreviewBeforeUpload
            ? currentModel.renderPreviewBeforeUpload({
                [currentModel.key]: newBatch[currentModel.key],
              })
            : batch[batchFieldKey];
          validationErrors[currentModel.key] = currentModel.validate
            ? (currentModel.validate as (v: string) => boolean)(batch[batchFieldKey])
            : true;
        } else {
          // console.log(`NO ${batchFieldKey}`);
        }
      });

      batchesToSend.push(newBatch);
      originData.push(originBatch);
      previewData.push(previewBatch);
      validationData.push(validationErrors);
    });

    let statusNoError = true;
    validationData.forEach((vFields) => {
      if (Object.values(vFields).filter((v) => !v).length) {
        statusNoError = false;
      }
    });

    return {
      batchesToSend,
      originData,
      previewData,
      validationData,
      statusNoError,
    };
  };

  const handleUploadedCSV = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files && e.target.files[0];

    if (!file) {
      return;
    }

    try {
      const fileData = (await readFile(file)) as string;

      const commaSepCount = (fileData.match(/,/g) || []).length;
      const semicolonSepCount = (fileData.match(/;/g) || []).length;
      const delimiter = commaSepCount >= semicolonSepCount ? ',' : ';';

      // GET DATA FROM FILE

      const { data, meta } = CSV.parse(
        fileData
          .split('\n')
          .map((f) => f.trim())
          .join('\n'),
        {
          delimiter,
          newline: '\n',
          header: true,
          skipEmptyLines: true,
        },
      );

      // COLLECT DATA FROM FILE

      const fields = meta.fields && meta.fields.filter(Boolean);
      if (!fields) {
        return;
      }

      const newData: Record<string, string>[] = [];

      (data as Record<string, string>[]).forEach((dataRow) => {
        const newDataRow = fields.reduce(
          (acc, field) => ({
            ...acc,
            [field]: dataRow[field] ? dataRow[field].trim() : '',
          }),
          {} as Record<string, string>,
        );
        newData.push(newDataRow);
      });

      // VALIDATE FILE AND DATA STRUCTURE
      validateUploadedCSVStructure(file, fields);

      // PARSE AND NORMALISE

      const {
        batchesToSend,
        originData,
        previewData,
        validationData,
        statusNoError,
      } = parseAndNormaliseHandledUploadedCSV(newData);

      setInputValues((prevState) => [
        ...prevState,
        {
          file,
          data: batchesToSend,
          originData,
          previewData,
          validationData,
          fields,
          status: statusNoError,
        },
      ]);

      setIsUploaded(true);

      setFileToShow(file.name);
    } catch (error) {
      toast(error.message);
    }
  };

  const delFile = (i: number) =>
    setInputValues((prevState) => {
      const newState = prevState.filter((_, ix) => i !== ix);
      setFileToShow((newState[0] && newState[0].file.name) || undefined);
      !newState.length && setIsUploaded(false);
      return newState;
    });

  const uploadParsedData = () => {
    let batchesToSend: Record<string, string>[] = [];
    let validationErrors = false;

    // for each file
    inputValues.forEach((f) => {
      batchesToSend = [...batchesToSend, ...f.data];

      f.validationData.forEach((row) => {
        if (Object.values(row).filter((fieldValid) => !fieldValid).length) {
          validationErrors = true;
        }
      });
    });

    if (validationErrors) {
      toast('You have not valid data in upload file. Please, check it and retry.');
      return false;
    }
    if (orgName === ORGANIZATIONS.REFRESCO) {
      dispatch(batchesActions.splitUploadBatchesCSVAction(batchesToSend));
    } else {
      dispatch(batchesActions.uploadBatchesCSVAction(batchesToSend));
    }

    return true;
  };

  const callbackSaveEditedValue = (fileToShow: string) => (index: number, key: string) => (
    value: string,
  ) => {
    setInputValues((prevState) =>
      prevState.map((inputValue) => {
        const isEntityToChange = inputValue.file.name === fileToShow;
        if (isEntityToChange) {
          const newDataItem = { ...inputValue.data[index] };
          const newOriginDataItem = { ...inputValue.originData[index] };
          const newPreviewDataItem = { ...inputValue.previewData[index] };
          newDataItem[key] = value;
          newOriginDataItem[key] = value;
          newPreviewDataItem[key] = value;
          const newData = inputValue.data.map((dataItem, i) =>
            i === index ? newDataItem : dataItem,
          );
          const newOriginData = inputValue.data.map((dataItem, i) =>
            i === index ? newDataItem : dataItem,
          );
          const newPreviewData = inputValue.data.map((dataItem, i) =>
            i === index ? newDataItem : dataItem,
          );

          return {
            ...inputValue,
            data: newData,
            originData: newOriginData,
            previewData: newPreviewData,
          } as InputValue;
        }
        return inputValue;
      }),
    );
  };

  return (
    <section className={classes.component}>
      <div className={classes.batchAdd}>
        <form onSubmit={handleSubmit} className={classes.form}>
          <div className={classes.formTitle}>
            <div> Start uploading a CSV file by clicking the button below...</div>
            <DownloadTemplateLink />
          </div>

          <label className={`${classes.uploadCSV} ${isUploaded ? 'success' : ''}`}>
            <div className="upload-place">
              <div className="icon">
                <FileStatusIcon />
              </div>
              <div className="text">Upload CSV</div>
              {/* accept=".csv" -  https://stackoverflow.com/questions/11832930/html-input-file-accept-attribute-file-type-csv */}
              <input type="file" accept=".csv" hidden onChange={handleUploadedCSV} />
            </div>
          </label>
        </form>

        {/* UPLOADED FILES LIST */}

        {!!inputValues.length && (
          <>
            <div className="uploadCSVsTable">
              <div className="tableHead">
                <div className="fileName">File name</div>
                <div className="fileSize">Size</div>
                <div className="fileOptions">Actions</div>
              </div>
              {inputValues.map((f, i) => (
                <div
                  className={`tableRow ${f.status ? 'success' : 'failed'}`}
                  key={`${f.file.size}${f.file.name}`}
                >
                  <div className="fileName">{f.file.name || ''}</div>
                  <div className="fileSize">
                    {f.file.size && f.file.name ? formatBytes(f.file.size) : ''}
                  </div>
                  <div className="fileOptions">
                    {f.file.size && f.file.name && (
                      <>
                        <ShowSvg onClick={() => setFileToShow(f.file.name)} />
                        <DeleteSvg onClick={() => delFile(i)} />
                      </>
                    )}
                  </div>
                </div>
              ))}
            </div>

            <div>
              {isLoading ? (
                <button type="button" className={classes.submitBtn}>
                  <Loader />
                </button>
              ) : (
                <button
                  data-button="submit"
                  type="button"
                  className={classes.submitBtn}
                  onClick={uploadParsedData}
                >
                  Register New Batch
                </button>
              )}
            </div>
          </>
        )}

        {/* SHOW FILE DATA TABLE */}

        {fileToShow && (
          <BatchAddPreviewTable
            inputValues={inputValues}
            fileToShow={fileToShow}
            callbackSaveEditedValue={callbackSaveEditedValue}
          />
        )}
      </div>
    </section>
  );
};

export default injectSheet(batchAddStyles)(UploadCSVFile);
