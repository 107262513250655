import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { ExampleSvg } from 'components/icons';
import { getUser } from 'domain/env';
import { getBatchInfoByUrlOrgRole } from 'domain/common';
import { AppStateType } from 'types';

const mapStateToProps = (state: AppStateType) => ({
  user: getUser(state),
  batchInfo: getBatchInfoByUrlOrgRole(state),
});

const DownloadTemplateLink = () => {
  const { batchInfo, user } = useSelector(mapStateToProps, shallowEqual);
  const { endpoint } = batchInfo;
  const { org } = user;
  const { fullname } = org;

  return (
    <Link
      className="formTitleLink"
      to={{
        pathname: `${process.env.REACT_APP_API_URL}/api/v1/${endpoint}/template`,
      }}
      target="_blank"
      download
    >
      <ExampleSvg width={24} />
      <span> Download example of {fullname} batch</span>
    </Link>
  );
};

export default DownloadTemplateLink;
