import { CHANNELS } from 'themes/constants';
import cookie from 'cookie';
import router from './router';
import { IndexedObject } from 'lib/lib';

const { BANANAS, TILAPIA, ORANGES, EGGS, JUICE } = CHANNELS;

export const getRoute = (url: string) => {
  const { REACT_APP_API_URL } = process.env;
  return url.replace(`${REACT_APP_API_URL}/api/v1/`, '').split('?');
};

export default (
  method: string,
  headers: IndexedObject<string>,
  url: string,
  data: any,
  testsCookies: string,
): Object => {
  const { channel } = cookie.parse(testsCookies);

  const [route] = getRoute(url);
  const request = {
    method,
    headers,
    data,
    url,
    channel,
  };
  console.log(request);

  switch (channel) {
    case JUICE:
    case TILAPIA:
    case BANANAS:
    case ORANGES:
    case EGGS: {
      const [pureUrlGeneric] = route.split('/');
      const [recievedChannel, pureUrl] = route.split('/');

      if (router[channel][pureUrlGeneric]) {
        return router[channel][pureUrlGeneric](request);
      }
      if (router[recievedChannel][pureUrl]) {
        return router[recievedChannel][pureUrl](request);
      }
      break;
    }
    default: {
      const [pureUrl] = route.split('/');

      if (router.generic[pureUrl]) {
        return router.generic[pureUrl](request);
      }

      if (router[channel][pureUrl]) return router[channel][pureUrl](request);
    }
  }

  const [pureUrl] = route.split('/');

  console.log(`Failed to mock by route ${route}, pure url ${pureUrl}`);
  return [500, { message: `Url is not proceeded   ${route}` }];
};
