import uploadModel from 'models/refresco/common/tabs/uploadModel';
import { ORGANIZATIONS } from 'themes/constants';
import { ModelRequiredInfo } from 'lib/lib';

export default (modelRequiredInfo: ModelRequiredInfo) => ({
  tabs: [
    {
      modelName: 'defaultTab',
      originalName: ORGANIZATIONS.REFRESCO,
      title: ORGANIZATIONS.REFRESCO,
    },
  ],
  defaultTab: uploadModel(modelRequiredInfo),
});
