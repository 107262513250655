import {
  validationRequired,
  validationFloat,
  validationString,
  validationInt,
  validationCustomDate,
} from 'lib/uploadFieldValidations';

import { customDatePreview, formatDateToISO } from 'lib/renderHelpers';

import { ORGANIZATIONS } from 'themes/constants';
import { UPLOAD_TIME_FORMAT } from 'themes/main';

import { BatchRecord, ModelRequiredInfo } from 'lib/lib';

// eslint-disable-next-line
export default (modelRequiredInfo: ModelRequiredInfo) => {
  const { SEAFOODCONNECTION } = ORGANIZATIONS;
  const DATE_FORMAT = UPLOAD_TIME_FORMAT[SEAFOODCONNECTION];
  const validationSeafoodConnectionDate = validationCustomDate(DATE_FORMAT);

  return [
    {
      key: 'productBatchIdBulk',
      label: 'Product/ batch ID bulk',
      title: 'ProductBatch Id Bulk',
      style: { flex: '1 0 200px' },
      handleParsedValue: (value: string) => {
        const numbers = String(value).match(/[\d+]{4,}/g);

        if (numbers && numbers.length) {
          return String(numbers[0]);
        }

        return String(value || '');
      },
      renderUploadPreview: (column: BatchRecord) => column.productBatchIdBulk,
      validate: [validationRequired, validationString, validationInt],
      edit: { type: 'input' },
    },
    {
      key: 'containerNumber',
      label: 'Container number',
      title: 'Container Number',
      style: { flex: '0 0 150px' },
      handleParsedValue: (value: string) => String(value || ''),
      renderUploadPreview: (column: BatchRecord) => column.containerNumber,
      validate: [validationRequired, validationString],
      edit: { type: 'input' },
    },
    {
      key: 'descriptionSim',
      label: 'Description SIM',
      title: 'Description Sim',
      style: { flex: '1 0 130px' },
      handleParsedValue: (value: string) => String(value || ''),
      renderUploadPreview: (column: BatchRecord) => column.descriptionSim,
      validate: [validationString],
      edit: { type: 'input' },
    },
    {
      key: 'customerArticleNumber',
      label: 'Customer article number',
      title: 'Customer Article Number',
      style: { flex: '0 0 120px' },
      handleParsedValue: (value: string) => String(value || ''),
      renderUploadPreview: (column: BatchRecord) => column.customerArticleNumber,
      validate: [validationString],
      edit: { type: 'input' },
    },
    {
      key: 'specie',
      label: 'Specie',
      title: 'Specie',
      style: { flex: '0 0 70px' },
      defaultSelectedColumn: true,
      defaultSelectedBatchContractColumn: true,
      handleParsedValue: (value: string) => String(value || ''),
      renderUploadPreview: (column: BatchRecord) => column.specie,
      validate: [validationString],
      edit: { type: 'input' },
    },
    {
      key: 'productType',
      label: 'Product type (Fil-Loin-skin on/ off)',
      title: 'Product Type',
      style: { flex: '0 0 80px' },
      handleParsedValue: (value: string) => String(value || ''),
      renderUploadPreview: (column: BatchRecord) => column.productType,
      render: (column: BatchRecord) => column.productType,
      validate: [validationString],
      edit: { type: 'input' },
    },
    {
      key: 'articleDescriptionCustomer',
      label: 'Article description customer',
      title: 'Article Description Customer',
      style: { flex: '1 0 200px' },
      handleParsedValue: (value: string) => String(value || ''),
      renderUploadPreview: (column: BatchRecord) => column.articleDescriptionCustomer,
      validate: [validationString],
      edit: { type: 'input' },
    },
    {
      key: 'volumeBulkReceiptInKg',
      label: 'Volume bulk receipt in KG',
      title: 'Volume Bulk Receipt In Kg',
      style: { flex: '1 0 80px' },
      renderUploadPreview: (column: BatchRecord) => column.volumeBulkReceiptInKg,
      validate: [validationRequired, validationFloat],
      edit: { type: 'input' },
    },
    {
      key: 'bbd',
      label: 'BBD',
      title: 'BBD',
      style: { flex: '1 0 90px' },
      handleParsedValue: (value: string) => formatDateToISO(value, DATE_FORMAT),
      renderUploadPreview: ({ bbd }: BatchRecord) =>
        customDatePreview(bbd, [validationRequired, validationSeafoodConnectionDate]),
      validate: [validationRequired, validationSeafoodConnectionDate],
      edit: { type: 'date' },
    },
    {
      key: 'dateReceipt',
      label: 'Date receipt',
      title: 'Date Receipt',
      style: { flex: '0 0 100px' },
      handleParsedValue: (value: string) => formatDateToISO(value, DATE_FORMAT),
      renderUploadPreview: ({ dateReceipt }: BatchRecord) =>
        customDatePreview(dateReceipt, [validationRequired, validationSeafoodConnectionDate]),
      validate: [validationRequired, validationSeafoodConnectionDate],
      edit: { type: 'date' },
    },
    {
      key: 'poNumberJumbo',
      label: 'PO number Jumbo',
      title: 'PO number Jumbo',
      style: { flex: '1 0 100px' },
      handleParsedValue: (value: string) => String(value || ''),
      renderUploadPreview: (column: BatchRecord) => column.poNumberJumbo,
      validate: [validationRequired, validationString],
      edit: { type: 'input' },
    },
    {
      key: 'ssccPallet',
      label: 'SSCC Pallet',
      title: 'SSCC Pallet',
      style: { flex: '1 0 200px' },
      handleParsedValue: (value: string) => String(value || ''),
      renderUploadPreview: (column: BatchRecord) => column.ssccPallet,
      validate: [validationRequired, validationString],
      edit: { type: 'input' },
    },
    {
      key: 'deliveryNumber',
      label: 'Delivery number',
      title: 'Delivery Number',
      style: { flex: '1 0 100px' },
      handleParsedValue: (value: string) => String(value || ''),
      renderUploadPreview: (column: BatchRecord) => column.deliveryNumber,
      validate: [validationRequired, validationString],
      edit: { type: 'input' },
    },
    {
      key: 'deliveryDate',
      label: 'Delivery date',
      title: 'Delivery Date',
      style: { flex: '1 0 100px' },
      handleParsedValue: (value: string) => formatDateToISO(value, DATE_FORMAT),
      renderUploadPreview: ({ deliveryDate }: BatchRecord) =>
        customDatePreview(deliveryDate, [validationRequired, validationSeafoodConnectionDate]),
      validate: [validationRequired, validationSeafoodConnectionDate],
      edit: { type: 'date' },
    },
    {
      key: 'volumeDeliveryProductInKg',
      label: 'Volume delivery product in KG',
      title: 'Volume Delivery ProductInKg',
      style: { flex: '1 0 150px' },
      renderUploadPreview: (column: BatchRecord) => column.volumeDeliveryProductInKg,
      validate: [validationFloat],
      edit: { type: 'input' },
    },
    {
      key: 'tempLogReaderMinTemp',
      label: 'Temp log reader Min temp',
      title: 'Temp Log Reader Min Temp',
      style: { flex: '1 0 150px' },
      renderUploadPreview: (column: BatchRecord) => column.tempLogReaderMinTemp,
      validate: [validationFloat],
      edit: { type: 'input' },
    },
    {
      key: 'tempLogReaderAveTemp',
      label: 'Temp log reader ave temp',
      title: 'Temp Log Reader Ave Temp',
      style: { flex: '1 0 150px' },
      renderUploadPreview: (column: BatchRecord) => column.tempLogReaderAveTemp,
      validate: [validationFloat],
      edit: { type: 'input' },
    },
    {
      key: 'tempLogReaderMaxTemp',
      label: 'Temp log reader Max temp',
      title: 'Temp Log Reader Max Temp',
      style: { flex: '1 0 150px' },
      renderUploadPreview: (column: BatchRecord) => column.tempLogReaderMaxTemp,
      validate: [validationFloat],
      edit: { type: 'input' },
    },
    // ...baseModelFields({ role, channelName, params }),
  ];
};
