import React from 'react';
import {
  validationRequired,
  validationInt,
  validationFloat,
  validationString,
  validationCustomDate,
} from 'lib/uploadFieldValidations';
import { customDatePreview, formatDateToISO } from 'lib/renderHelpers';
import { replaceFormattedStringWithNumberValueString } from 'models/utils/utils';
import { ORGANIZATIONS } from 'themes/constants';
import { UPLOAD_TIME_FORMAT } from 'themes/main';
import { BatchRecord, ModelRequiredInfo } from 'lib/lib';

export default (modelRequiredInfo: ModelRequiredInfo) => {
  const { MAYONNA } = ORGANIZATIONS;
  const DATE_FORMAT = UPLOAD_TIME_FORMAT[MAYONNA];
  const validationMoyonnaDate = validationCustomDate(DATE_FORMAT);

  return [
    {
      key: 'articleNumberConsumerProduct',
      label: 'Article number consumer product',
      title: 'Article number consumer product',
      style: { flex: '1 0 150px' },
      renderUploadPreview: (column: BatchRecord) => column.articleNumberConsumerProduct,
      validate: [validationRequired, validationString],
      edit: { type: 'input' },
    },
    {
      key: 'articleDescriptionConsumerProduct',
      label: 'Article description consumer product',
      title: 'Article description consumer product',
      style: { flex: '0 0 250px' },
      handleParsedValue: (value: string) => String(value || ''),
      renderUploadPreview: (column: BatchRecord) => column.articleDescriptionConsumerProduct,
      validate: [validationString],
      edit: { type: 'input' },
    },
    {
      key: 'specie',
      label: 'Specie',
      title: 'Specie',
      style: { flex: '1 0 100px' },
      handleParsedValue: (value: string) => String(value || ''),
      renderUploadPreview: (column: BatchRecord) => column.specie,
      validate: [validationString],
      edit: { type: 'input' },
    },
    {
      key: 'productType',
      label: 'Product type (Fil-Loin-skin on/ off)',
      title: 'Product type (Fil-Loin-skin on/ off)',
      style: { flex: '1 0 100px' },
      handleParsedValue: (value: string) => String(value || ''),
      renderUploadPreview: (column: BatchRecord) => column.productType,
      validate: [validationString],
      edit: { type: 'input' },
    },
    {
      key: 'volumeConsumerProductInKg',
      label: 'Volume Consumer product in KG',
      title: 'Volume consumer product in KG',
      style: { flex: '1 0 100px' },
      renderUploadPreview: (column: BatchRecord) => column.volumeConsumerProductInKg,
      validate: [validationFloat],
      edit: { type: 'input' },
    },
    {
      key: 'numberOfConsumerPackages',
      label: 'Number of consumer packages',
      title: 'Number of consumer packages',
      style: { flex: '1 0 100px' },
      handleParsedValue: replaceFormattedStringWithNumberValueString,
      renderUploadPreview: (column: BatchRecord) => column.numberOfConsumerPackages,
      validate: [validationInt],
      edit: { type: 'input' },
    },
    {
      key: 'processingDate',
      label: 'Processing date',
      title: 'Processing date',
      style: { flex: '1 0 100px' },
      handleParsedValue: (value: string) => formatDateToISO(value, DATE_FORMAT),
      renderUploadPreview: ({ processingDate }: BatchRecord) =>
        customDatePreview(processingDate, [validationRequired, validationMoyonnaDate]),
      validate: [validationRequired, validationMoyonnaDate],
      edit: { type: 'date' },
    },
    {
      key: 'deliveryDate',
      label: 'Delivery date',
      title: 'Delivery date',
      style: { flex: '1 0 100px' },
      handleParsedValue: (value: string) => formatDateToISO(value, DATE_FORMAT),
      renderUploadPreview: ({ deliveryDate }: BatchRecord) =>
        customDatePreview(deliveryDate, [validationRequired, validationMoyonnaDate]),
      validate: [validationRequired, validationMoyonnaDate],
      edit: { type: 'date' },
    },
    {
      key: 'thtConsumerProduct',
      label: 'THT consumer product',
      title: 'THT consumer product',
      style: { flex: '1 0 150px' },
      handleParsedValue: (value: string) => formatDateToISO(value, DATE_FORMAT),
      renderUploadPreview: ({ thtConsumerProduct }: BatchRecord) =>
        customDatePreview(thtConsumerProduct, [validationRequired, validationMoyonnaDate]),
      validate: [validationRequired, validationMoyonnaDate],
      edit: { type: 'date' },
    },
    {
      key: 'productBatchIdBulk',
      label: 'Product/ batch ID bulk',
      title: 'Product/ batch ID bulk',
      style: { flex: '1 0 150px' },
      handleParsedValue: (value: string) => String(value || ''),
      renderUploadPreview: (column: BatchRecord) => column.productBatchIdBulk,
      validate: [validationRequired, validationString],
      edit: { type: 'input' },
    },
    {
      key: 'articleNumberBulk',
      label: 'Article number bulk',
      title: 'Article number bulk',
      style: { flex: '1 0 200px' },
      handleParsedValue: (value: string) => String(value || ''),
      renderUploadPreview: (column: BatchRecord) => column.articleNumberBulk,
      validate: [validationRequired, validationString],
      edit: { type: 'input' },
    },
    {
      key: 'articleDescriptionBulk',
      label: 'Article description bulk',
      title: 'Article description bulk',
      style: { flex: '1 0 150px' },
      handleParsedValue: (value: string) => String(value || ''),
      renderUploadPreview: (column: BatchRecord) => column.articleDescriptionBulk,
      validate: [validationString],
      edit: { type: 'input' },
    },
    {
      key: 'dateReceipt',
      label: 'Date receipt',
      title: 'Date receipt',
      style: { flex: '1 0 100px' },
      handleParsedValue: (value: string) => formatDateToISO(value, DATE_FORMAT),
      renderUploadPreview: ({ dateReceipt }: BatchRecord) =>
        customDatePreview(dateReceipt, [validationRequired, validationMoyonnaDate]),
      validate: [validationRequired, validationMoyonnaDate],
      edit: { type: 'date' },
    },
    {
      key: 'thtBulk',
      label: 'THT bulk',
      title: 'THT bulk',
      style: { flex: '1 0 100px' },
      handleParsedValue: (value: string) => formatDateToISO(value, DATE_FORMAT),
      renderUploadPreview: ({ thtBulk }: BatchRecord) =>
        customDatePreview(thtBulk, [validationRequired, validationMoyonnaDate]),
      validate: [validationRequired, validationMoyonnaDate],
      edit: { type: 'date' },
    },
    {
      key: 'volumeBulkInKg',
      label: 'Volume bulk in KG',
      title: 'Volume bulk in KG',
      style: { flex: '1 0 150px' },
      renderUploadPreview: (column: BatchRecord) => column.volumeBulkInKg,
      validate: [validationFloat],
      edit: { type: 'input' },
    },
  ];
};
