export default {
  $class: 'org.powerchain.juice.batch.ImporterBatchReadConcept',
  salesOrderNumber: 'tst_contract000000',
  shipToNumber: '111',
  kpiBrix: 0.23,
  kpiRatio: 0.234,
  kgShipped: 10,
  invoiceDate: '2020-03-23T11:12:41.334Z',
  receiptDate: '2020-03-23T11:12:41.334Z',
  vesselTkBatchId: 'tst__00000000',
  vesselTkTrip: 'tst__trip30242--wfw0e-ewf-1',
  batchId: 'tst__111111111111',
  ghentBatchId: 'NFAI009734',
  ghentToVesselRatio: '100',
  truckBatchId: '3200101435',
  productCode: 'CLC001001',
  createdAt: '2020-03-23T11:12:41.334Z',
  createdBy:
    'resource:org.powerchain.juice.member.ImporterUser#CitrosucoGhent_f02b24d5-4aee-4077-a651-39cbb8b193d9',
  status: 'PENDING',
  channelName: 'juice',
  createdBy_participant: {
    username: null,
    email: null,
    participant:
      'org.powerchain.juice.member.ImporterUser#CitrosucoGhent_f02b24d5-4aee-4077-a651-39cbb8b193d9',
    channel: { title: 'Juice', name: 'juice' },
    org: { name: 'CitrosucoGhent', fullname: 'Citrosuco Ghent', logo: null },
    user: {
      username: 'CitrosucoGhentTST staging',
      email: 'CitrosucoGhentTST@pc.t2f.io',
      org: { name: 'CitrosucoGhent', fullname: 'Citrosuco Ghent', logo: null },
    },
  },
};
