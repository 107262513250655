import React from 'react';
import { getTheme } from 'themes/main';
//
import { formatBatchDate } from 'lib/helpers';
import { renderShortDateFormat } from 'lib/renderHelpers';
import { BatchRecord, ModelRequiredInfo } from 'lib/lib';

export default (modelRequiredInfo: ModelRequiredInfo) => [
  {
    key: 'lotFinishedProduct',
    label: 'Lot_Finished_Product',
    title: 'Lot Finished Product',
    style: { flex: '0 0 150px' },
    defaultPreviewBeforeUploadColumn: true,
  },
  {
    key: 'articleNbrFinishedProduct',
    label: 'ArticleNbr_Finished_Product',
    title: 'Article Nbr Finished Product',
    style: { flex: '1 0 50px' },
  },
  {
    key: 'descriptionFinishedProduct',
    label: 'Description_Finished_Product',
    title: 'Description Finished Product',
    style: { flex: '0 0 270px' },
    defaultPreviewBeforeUploadColumn: true,
  },
  {
    key: 'qtyProducedPcsFinishedProduct',
    label: 'QtyProduced_PCS_Finished_Product',
    handleParsedValueFromCSVFile: (value: string) => parseInt(value || '0', 10),
    title: 'QtyProduced PCS Finished Product',
    style: { flex: '1 0 100px' },
    defaultPreviewBeforeUploadColumn: true,
  },
  {
    key: 'qtyUsedLtrRawProduct',
    label: 'QtyUsed_LTR_RAW_Product',
    handleParsedValueFromCSVFile: (value: string) => parseInt(value || '0', 10),
    title: 'QtyUsed LTR RAW Product',
    style: { flex: '1 0 100px' },
    defaultPreviewBeforeUploadColumn: true,
  },
  {
    key: 'productionFinishedFinishedProduct',
    label: 'ProductionFinished_Finished_Product',
    title: 'ProductionFinished Finished Product',
    style: { flex: '1 0 100px' },
    defaultPreviewBeforeUploadColumn: true,
    edit: { type: 'date' },
    handleParsedValueFromCSVFile: (value: string) =>
      formatBatchDate(
        value,
        getTheme(modelRequiredInfo.role, modelRequiredInfo.channelName).timezone,
        getTheme(modelRequiredInfo.role, modelRequiredInfo.channelName).timeFormats,
      ),
    renderPreviewBeforeUpload: (column: BatchRecord) =>
      renderShortDateFormat(column.productionFinishedFinishedProduct),
  },
  {
    key: 'lotFcoj',
    label: 'Lot_FCOJ',
    title: 'Lot FCOJ',
    style: { flex: '1 0 100px' },
    warning: (value: string) => {
      const matchesPattern = new RegExp(/^((SG)|(.FAI))\w+/).test(value);
      return matchesPattern
        ? false
        : 'Downstream handshake rule not available. For now, Lot_FCOJ id must start with “SG” or “*FAI“.';
    },
  },
  {
    key: 'articleNbrFcoj',
    label: 'ArticleNbr_FCOJ',
    title: 'ArticleNbr FCOJ',
    style: { flex: '1 0 50px' },
  },
  {
    key: 'dateCheckFcoj',
    label: 'Date_check_FCOJ',
    title: 'Date check FCOJ',
    style: { flex: '1 0 50px' },
    edit: { type: 'date' },
    handleParsedValueFromCSVFile: (value: string) =>
      formatBatchDate(
        value,
        getTheme(modelRequiredInfo.role, modelRequiredInfo.channelName).timezone,
        getTheme(modelRequiredInfo.role, modelRequiredInfo.channelName).timeFormats,
      ),
    renderPreviewBeforeUpload: (column: BatchRecord) => renderShortDateFormat(column.dateCheckFcoj),
  },
  {
    key: 'certificateOfConformityFcoj',
    label: 'Certificate_of_conformity_FCOJ',
    title: 'Certificate of conformity FCOJ',
    style: { flex: '1 0 50px' },
  },
  {
    key: 'organolepticCheckFcoj',
    label: 'Organoleptic_Check_FCOJ',
    title: 'Organoleptic Check FCOJ',
    style: { flex: '1 0 50px' },
  },
  {
    key: 'ratioFcoj',
    label: 'Ratio_FCOJ',
    title: 'Ratio FCOJ',
    style: { flex: '1 0 50px' },
  },
  {
    key: 'organolepticCheckFinishedProduct',
    label: 'Organoleptic_Check_Finished_Product',
    title: 'Organoleptic Check Finished Product',
    style: { flex: '1 0 50px' },
  },
  {
    key: 'vitaminCFinishedProduct',
    label: 'Vitamin_C_Finished_Product',
    title: 'Vitamin C Finished Product',
    style: { flex: '1 0 50px' },
  },
  {
    key: 'ratioFinishedProduct',
    label: 'Ratio_Finished_Product',
    title: 'Ratio Finished Product',
    style: { flex: '1 0 50px' },
  },
  {
    key: 'dateDeliveryAhFert',
    label: 'Date_Delivery_AH_FERT',
    title: 'Date Delivery AH FERT',
    style: { flex: '1 0 50px' },
    edit: { type: 'date' },
    handleParsedValueFromCSVFile: (value: string) =>
      formatBatchDate(
        value,
        getTheme(modelRequiredInfo.role, modelRequiredInfo.channelName).timezone,
        getTheme(modelRequiredInfo.role, modelRequiredInfo.channelName).timeFormats,
      ),
    renderPreviewBeforeUpload: (column: BatchRecord) =>
      renderShortDateFormat(column.dateDeliveryAhFert),
  },
  {
    key: 'batchId',
    label: 'Batch Id',
    title: 'Batch Details',
    style: { flex: '0 0 150px' },
  },
];
