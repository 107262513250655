import { renderShortDateFormat, customDatePreview, formatDateToISO } from 'lib/renderHelpers';
import {
  validationRequired,
  validationInt,
  validationFloat,
  validationString,
  validationCustomDate,
} from 'lib/uploadFieldValidations';
import { ORGANIZATIONS } from 'themes/constants';
import { UPLOAD_TIME_FORMAT } from 'themes/main';
import { BatchRecord, ModelRequiredInfo } from 'lib/lib';

export default ({ params }: ModelRequiredInfo) => {
  const { REGALSPRINGS } = ORGANIZATIONS;
  const DATE_FORMAT = UPLOAD_TIME_FORMAT[REGALSPRINGS];
  const validationRegalSpringsDate = validationCustomDate(DATE_FORMAT);

  const model = {
    tabs: [
      {
        modelName: 'detailList',
        originalName: 'Detail',
        title: 'Detail',
      },
      {
        modelName: 'exportList',
        originalName: 'Export',
        title: 'Export',
      },
      {
        modelName: 'packingList',
        originalName: 'Packing',
        title: 'Packing',
      },
      {
        modelName: 'processingList',
        originalName: 'Processing',
        title: 'Processing',
      },
      {
        modelName: 'rawMaterialList',
        originalName: 'Raw Material',
        title: 'Raw Material',
      },
      {
        modelName: 'harvestingFarmList',
        originalName: 'Harvesting Farm',
        title: 'Harvesting Farm',
      },
      {
        modelName: 'growUpList',
        originalName: 'Grow Up',
        title: 'Grow Up',
      },
      {
        modelName: 'stockingList',
        originalName: 'Stocking',
        title: 'Stocking',
      },
      {
        modelName: 'harvestingHcList',
        originalName: 'Harvesting Hc',
        title: 'Harvesting Hc',
      },
      {
        modelName: 'nurseryList',
        originalName: 'Nursery',
        title: 'Nursery',
      },
      {
        modelName: 'massBalanceList',
        originalName: 'Mass Balance',
        title: 'Mass Balance',
      },
    ],
    detailList: [
      {
        key: 'batchCode',
        label: 'BATCH CODE',
        title: 'Batch Code',
        style: { flex: '1 0 90px' },
        handleParsedValue: (value: string) => String(value || ''),
        renderUploadPreview: (column: BatchRecord) => column.batchCode,
        render: (column: BatchRecord) => column.batchCode,
        validate: [validationRequired, validationString],
        edit: { type: 'input' },
      },
      {
        key: 'bbd',
        label: 'BBD',
        title: 'BBD',
        style: { flex: '1 0 100px' },
        handleParsedValue: (value: string) => formatDateToISO(value, DATE_FORMAT),
        renderUploadPreview: ({ bbd }: BatchRecord) =>
          customDatePreview(bbd, [validationRequired, validationRegalSpringsDate]),
        render: (column: BatchRecord) => renderShortDateFormat(column.bbd),
        validate: [validationRequired, validationRegalSpringsDate],
        edit: { type: 'date' },
      },
      {
        key: 'product',
        label: 'PRODUCT',
        title: 'Product',
        style: { flex: '1 0 400px' },
        handleParsedValue: (value: string) => String(value || ''),
        renderUploadPreview: (column: BatchRecord) => column.product,
        render: (column: BatchRecord) => column.product,
        validate: [validationRequired, validationString],
        edit: { type: 'input' },
      },
      {
        key: 'cases',
        label: 'CASES',
        title: 'Cases',
        style: { flex: '1 0 50px' },
        renderUploadPreview: (column: BatchRecord) => column.cases,
        render: (column: BatchRecord) => column.cases,
        validate: [validationInt],
        edit: { type: 'input' },
      },
      {
        key: 'volume',
        label: 'VOLUME',
        title: 'Volume',
        style: { flex: '1 0 80px' },
        renderUploadPreview: (column: BatchRecord) => column.volume,
        render: (column: BatchRecord) => column.volume,
        validate: [validationFloat],
        edit: { type: 'input' },
      },
      {
        key: 'shippingDate',
        label: 'SHIPPING DATE',
        title: 'Shipping Date',
        style: { flex: '1 0 100px' },
        handleParsedValue: (value: string) => formatDateToISO(value, DATE_FORMAT),
        renderUploadPreview: ({ shippingDate }: BatchRecord) =>
          customDatePreview(shippingDate, [validationRequired, validationRegalSpringsDate]),
        render: (column: BatchRecord) => renderShortDateFormat(column.shippingDate),
        validate: [validationRequired, validationRegalSpringsDate],
        edit: { type: 'date' },
      },
      {
        key: 'containerNumber',
        label: 'CONTAINER NUMBER',
        title: 'Container Number',
        style: { flex: '1 0 130px' },
        handleParsedValue: (value: string) => String(value || ''),
        renderUploadPreview: (column: BatchRecord) => column.containerNumber,
        render: (column: BatchRecord) => column.containerNumber,
        validate: [validationRequired, validationString],
        edit: { type: 'input' },
      },
      {
        key: 'storageDateFrom',
        label: 'STORAGE DATE FROM',
        title: 'Storage Date From',
        style: { flex: '1 0 100px' },
        handleParsedValue: (value: string) => formatDateToISO(value, DATE_FORMAT),
        renderUploadPreview: ({ storageDateFrom }: BatchRecord) =>
          customDatePreview(storageDateFrom, [validationRequired, validationRegalSpringsDate]),
        render: (column: BatchRecord) => renderShortDateFormat(column.storageDateFrom),
        validate: [validationRequired, validationRegalSpringsDate],
        edit: { type: 'date' },
      },
      {
        key: 'storageDateTo',
        label: 'STORAGE DATE TO',
        title: 'Storage Date To',
        style: { flex: '1 0 100px' },
        handleParsedValue: (value: string) => formatDateToISO(value, DATE_FORMAT),
        renderUploadPreview: ({ storageDateTo }: BatchRecord) =>
          customDatePreview(storageDateTo, [validationRequired, validationRegalSpringsDate]),
        render: (column: BatchRecord) => renderShortDateFormat(column.storageDateTo),
        validate: [validationRequired, validationRegalSpringsDate],
        edit: { type: 'date' },
      },
      {
        key: 'packingDate',
        label: 'PACKING DATE',
        title: 'Packing Date',
        style: { flex: '1 0 100px' },
        handleParsedValue: (value: string) => formatDateToISO(value, DATE_FORMAT),
        renderUploadPreview: ({ packingDate }: BatchRecord) =>
          customDatePreview(packingDate, [validationRequired, validationRegalSpringsDate]),
        render: (column: BatchRecord) => renderShortDateFormat(column.packingDate),
        validate: [validationRequired, validationRegalSpringsDate],
        edit: { type: 'date' },
      },
      {
        key: 'freezingDate',
        label: 'FREEZING DATE',
        title: 'Freezing Date',
        style: { flex: '1 0 100px' },
        handleParsedValue: (value: string) => formatDateToISO(value, DATE_FORMAT),
        renderUploadPreview: ({ freezingDate }: BatchRecord) =>
          customDatePreview(freezingDate, [validationRequired, validationRegalSpringsDate]),
        render: (column: BatchRecord) => renderShortDateFormat(column.freezingDate),
        validate: [validationRequired, validationRegalSpringsDate],
        edit: { type: 'date' },
      },
      {
        key: 'processingDate',
        label: 'PROCESSING DATE',
        title: 'Processing Date',
        style: { flex: '1 0 100px' },
        handleParsedValue: (value: string) => formatDateToISO(value, DATE_FORMAT),
        renderUploadPreview: ({ processingDate }: BatchRecord) =>
          customDatePreview(processingDate, [validationRequired, validationRegalSpringsDate]),
        render: (column: BatchRecord) => renderShortDateFormat(column.processingDate),
        validate: [validationRequired, validationRegalSpringsDate],
        edit: { type: 'date' },
      },
      {
        key: 'receivingDate',
        label: 'RECEIVING DATE',
        title: 'Receiving Date',
        style: { flex: '1 0 100px' },
        handleParsedValue: (value: string) => formatDateToISO(value, DATE_FORMAT),
        renderUploadPreview: ({ receivingDate }: BatchRecord) =>
          customDatePreview(receivingDate, [validationRequired, validationRegalSpringsDate]),
        render: (column: BatchRecord) => renderShortDateFormat(column.receivingDate),
        validate: [validationRequired, validationRegalSpringsDate],
        edit: { type: 'date' },
      },
      {
        key: 'harvestingDate',
        label: 'HARVESTING DATE',
        title: 'Harvesting Date',
        style: { flex: '1 0 100px' },
        handleParsedValue: (value: string) => formatDateToISO(value, DATE_FORMAT),
        renderUploadPreview: ({ harvestingDate }: BatchRecord) =>
          customDatePreview(harvestingDate, [validationRequired, validationRegalSpringsDate]),
        render: (column: BatchRecord) => renderShortDateFormat(column.harvestingDate),
        validate: [validationRequired, validationRegalSpringsDate],
        edit: { type: 'date' },
      },
      {
        key: 'farmSite',
        label: 'FARM SITE',
        title: 'Farm Site',
        style: { flex: '1 0 50px' },
        handleParsedValue: (value: string) => String(value || ''),
        renderUploadPreview: (column: BatchRecord) => column.farmSite,
        render: (column: BatchRecord) => column.farmSite,
        validate: [validationRequired, validationString],
        edit: { type: 'input' },
      },
      {
        key: 'cageId',
        label: 'CAGE ID',
        title: 'Cage Id',
        style: { flex: '1 0 100px' },
        handleParsedValue: (value: string) => String(value || ''),
        renderUploadPreview: (column: BatchRecord) => column.cageId,
        render: (column: BatchRecord) => column.cageId,
        validate: [validationRequired, validationString],
        edit: { type: 'input' },
      },
    ],
    exportList: [
      {
        key: 'shippingDate',
        label: 'Shipping Date',
        title: 'Shipping Date',
        style: { flex: '0 0 100px' },
        handleParsedValue: (value: string) => formatDateToISO(value, DATE_FORMAT),
        renderUploadPreview: ({ shippingDate }: BatchRecord) =>
          customDatePreview(shippingDate, [validationRequired, validationRegalSpringsDate]),
        render: (column: BatchRecord) => renderShortDateFormat(column.shippingDate),
        validate: [validationRequired, validationRegalSpringsDate],
        edit: { type: 'date' },
      },
      {
        key: 'containerNumber',
        label: 'containernumber',
        title: 'Container Number',
        style: { flex: '0 0 170px' },
        handleParsedValue: (value: string) => String(value || ''),
        renderUploadPreview: (column: BatchRecord) => column.containerNumber,
        render: (column: BatchRecord) => column.containerNumber,
        validate: [validationRequired, validationString],
        edit: { type: 'input' },
      },
      {
        key: 'doNumber',
        label: 'DO Number',
        title: 'Do Number',
        style: { flex: '0 0 100px' },
        handleParsedValue: (value: string) => String(value || ''),
        renderUploadPreview: (column: BatchRecord) => column.doNumber,
        render: (column: BatchRecord) => column.doNumber,
        validate: [validationRequired, validationString],
        edit: { type: 'input' },
      },
      {
        key: 'poNumber',
        label: 'PO Number',
        title: 'PO Number',
        style: { flex: '0 0 130px' },
        handleParsedValue: (value: string) => String(value || ''),
        renderUploadPreview: (column: BatchRecord) => column.poNumber,
        render: (column: BatchRecord) => column.poNumber,
        validate: [validationRequired, validationString],
        edit: { type: 'input' },
      },
      {
        key: 'temperatureLog',
        label: 'Temperature Log',
        title: 'Temperature Log',
        style: { flex: '1 0 100px' },
        handleParsedValue: (value: string) => String(value),
        renderUploadPreview: (column: BatchRecord) => column.temperatureLog,
        render: (column: BatchRecord) => column.temperatureLog,
        validate: [validationRequired, validationString],
        edit: { type: 'input' },
      },
      {
        key: 'sealNumber',
        label: 'Seal Number',
        title: 'Seal Number',
        style: { flex: '1 0 100px' },
        handleParsedValue: (value: string) => String(value || ''),
        renderUploadPreview: (column: BatchRecord) => column.sealNumber,
        render: (column: BatchRecord) => column.sealNumber,
        validate: [validationRequired, validationString],
        edit: { type: 'input' },
      },
      {
        key: 'packingLot',
        label: 'Packing Lot',
        title: 'Packing Lot',
        style: { flex: '0 0 100px' },
        handleParsedValue: (value: string) => String(value || ''),
        renderUploadPreview: (column: BatchRecord) => column.packingLot,
        render: (column: BatchRecord) => column.packingLot,
        validate: [validationRequired, validationString],
        edit: { type: 'input' },
      },
      {
        key: 'productionLot',
        label: 'Production Lot',
        title: 'Production Lot',
        style: { flex: '0 0 150px' },
        handleParsedValue: (value: string) => String(value || ''),
        renderUploadPreview: (column: BatchRecord) => column.productionLot,
        render: (column: BatchRecord) => column.productionLot,
        validate: [validationRequired, validationString],
        edit: { type: 'input' },
      },
      {
        key: 'customerNumber',
        label: 'Customer number',
        title: 'Customer Number',
        style: { flex: '1 0 70px' },
        handleParsedValue: (value: string) => String(value || ''),
        renderUploadPreview: (column: BatchRecord) => column.customerNumber,
        render: (column: BatchRecord) => column.customerNumber,
        validate: [validationRequired, validationString],
        edit: { type: 'input' },
      },
      {
        key: 'contractNumber',
        label: 'Contract Number',
        title: 'Contract Number',
        style: { flex: '0 0 150px' },
        handleParsedValue: (value: string) => String(value || ''),
        renderUploadPreview: (column: BatchRecord) => column.contractNumber,
        render: (column: BatchRecord) => column.contractNumber,
        validate: [validationString],
        edit: { type: 'input' },
      },
      {
        key: 'customer',
        label: 'Customer',
        title: 'Customer',
        style: { flex: '1 0 70px' },
        handleParsedValue: (value: string) => String(value || ''),
        renderUploadPreview: (column: BatchRecord) => column.customer,
        render: (column: BatchRecord) => column.customer,
        validate: [validationString],
        edit: { type: 'input' },
      },
      {
        key: 'totalMc',
        label: 'Total MC',
        title: 'Total Mc',
        style: { flex: '1 0 70px' },
        renderUploadPreview: (column: BatchRecord) => column.totalMc,
        render: (column: BatchRecord) => column.totalMc,
        validate: [validationFloat],
        edit: { type: 'input' },
      },
      {
        key: 'bbd',
        label: 'BBD',
        title: 'BBD',
        style: { flex: '0 0 100px' },
        handleParsedValue: (value: string) => formatDateToISO(value, DATE_FORMAT),
        renderUploadPreview: ({ bbd }: BatchRecord) =>
          customDatePreview(bbd, [validationRequired, validationRegalSpringsDate]),
        render: (column: BatchRecord) => renderShortDateFormat(column.bbd),
        validate: [validationRequired, validationRegalSpringsDate],
        edit: { type: 'date' },
      },
    ],
    packingList: [
      {
        key: 'packingDate',
        label: 'Packing Date',
        title: 'Packing Date',
        style: { flex: '1 0 70px' },
        handleParsedValue: (value: string) => formatDateToISO(value, DATE_FORMAT),
        renderUploadPreview: ({ packingDate }: BatchRecord) =>
          customDatePreview(packingDate, [validationRequired, validationRegalSpringsDate]),
        render: (column: BatchRecord) => renderShortDateFormat(column.packingDate),
        validate: [validationRequired, validationRegalSpringsDate],
        edit: { type: 'date' },
      },
      {
        key: 'packingLot',
        label: 'Packing Lot',
        title: 'Packing Lot',
        style: { flex: '1 0 100px' },
        renderUploadPreview: (column: BatchRecord) => column.packingLot,
        render: (column: BatchRecord) => column.packingLot,
        validate: [validationRequired, validationString],
        edit: { type: 'input' },
      },
      {
        key: 'productionLot',
        label: 'Production Lot',
        title: 'Production Lot',
        style: { flex: '1 0 50px' },
        handleParsedValue: (value: string) => String(value || ''),
        renderUploadPreview: (column: BatchRecord) => column.productionLot,
        render: (column: BatchRecord) => column.productionLot,
        validate: [validationRequired, validationString],
        edit: { type: 'input' },
      },
      {
        key: 'gisfBatch',
        label: 'GISF Batch',
        title: 'Gisf Batch',
        style: { flex: '1 0 50px' },
        handleParsedValue: (value: string) => String(value || ''),
        renderUploadPreview: (column: BatchRecord) => column.gisfBatch,
        render: (column: BatchRecord) => column.gisfBatch,
        validate: [validationRequired, validationString],
        edit: { type: 'input' },
      },
      {
        key: 'fgCode',
        label: 'FG Code',
        title: 'FG Code',
        style: { flex: '1 0 50px' },
        handleParsedValue: (value: string) => String(value || ''),
        renderUploadPreview: (column: BatchRecord) => column.fgCode,
        render: (column: BatchRecord) => column.fgCode,
        validate: [validationRequired, validationString],
        edit: { type: 'input' },
      },
      {
        key: 'fgDescription',
        label: 'FG Description',
        title: 'FG Description',
        style: { flex: '3 0 50px' },
        handleParsedValue: (value: string) => String(value || ''),
        renderUploadPreview: (column: BatchRecord) => column.fgDescription,
        render: (column: BatchRecord) => column.fgDescription,
        validate: [validationString],
        edit: { type: 'input' },
      },
      {
        key: 'quantityInMc',
        label: 'Quantity in MC',
        title: 'Quantity In Mc',
        style: { flex: '1 0 50px' },
        renderUploadPreview: (column: BatchRecord) => column.quantityInMc,
        render: (column: BatchRecord) => column.quantityInMc,
        validate: [validationRequired, validationFloat],
        edit: { type: 'input' },
      },
    ],
    processingList: [
      {
        key: 'processingDate',
        label: 'Processing Date',
        title: 'Processing Date',
        style: { flex: '1 0 50px' },
        handleParsedValue: (value: string) => formatDateToISO(value, DATE_FORMAT),
        renderUploadPreview: ({ processingDate }: BatchRecord) =>
          customDatePreview(processingDate, [validationRequired, validationRegalSpringsDate]),
        render: (column: BatchRecord) => renderShortDateFormat(column.processingDate),
        validate: [validationRequired, validationRegalSpringsDate],
        edit: { type: 'date' },
      },
      {
        key: 'gisfBatch',
        label: 'GISF Batch',
        title: 'GISF Batch',
        style: { flex: '1 0 50px' },
        handleParsedValue: (value: string) => String(value || ''),
        renderUploadPreview: (column: BatchRecord) => column.gisfBatch,
        render: (column: BatchRecord) => column.gisfBatch,
        validate: [validationRequired, validationString],
        edit: { type: 'input' },
      },
      {
        key: 'fgCode',
        label: 'FG Code',
        title: 'FG Code',
        style: { flex: '1 0 50px' },
        handleParsedValue: (value: string) => String(value || ''),
        renderUploadPreview: (column: BatchRecord) => column.fgCode,
        render: (column: BatchRecord) => column.fgCode,
        validate: [validationRequired, validationString],
        edit: { type: 'input' },
      },
      {
        key: 'quantityInKg',
        label: 'Quantity in Kg',
        title: 'Quantity In Kg',
        style: { flex: '1 0 50px' },
        renderUploadPreview: (column: BatchRecord) => column.quantityInKg,
        render: (column: BatchRecord) => column.quantityInKg,
        validate: [validationRequired, validationFloat],
        edit: { type: 'input' },
      },
    ],
    rawMaterialList: [
      {
        key: 'deliverySlip',
        label: 'Delivery Slip',
        title: 'Delivery Slip',
        style: { flex: '1 0 50px' },
        handleParsedValue: (value: string) => String(value || ''),
        renderUploadPreview: (column: BatchRecord) => column.deliverySlip,
        render: (column: BatchRecord) => column.deliverySlip,
        validate: [validationString],
        edit: { type: 'input' },
      },
      {
        key: 'gisfBatch',
        label: 'GISF BATCH',
        title: 'GISF Batch',
        style: { flex: '1 0 50px' },
        handleParsedValue: (value: string) => String(value || ''),
        renderUploadPreview: (column: BatchRecord) => column.gisfBatch,
        render: (column: BatchRecord) => column.gisfBatch,
        validate: [validationRequired, validationString],
        edit: { type: 'input' },
      },
      {
        key: 'netFarm',
        label: 'Net Farm',
        title: 'Net Farm',
        style: { flex: '1 0 50px' },
        handleParsedValue: (value: string) => String(value || ''),
        renderUploadPreview: (column: BatchRecord) => column.netFarm,
        render: (column: BatchRecord) => column.netFarm,
        validate: [validationRequired, validationString],
        edit: { type: 'input' },
      },
    ],
    harvestingFarmList: [
      {
        key: 'deliverySlipNote',
        label: 'Slip Number/Delivery Note',
        title: 'Delivery Slip Note',
        style: { flex: '1 0 50px' },
        handleParsedValue: (value: string) => String(value || ''),
        renderUploadPreview: (column: BatchRecord) => column.deliverySlipNote,
        render: (column: BatchRecord) => column.deliverySlipNote,
        validate: [validationString],
        edit: { type: 'input' },
      },
      {
        key: 'harvestDate',
        label: 'Harvest Date',
        title: 'Harvest Date',
        style: { flex: '1 0 50px' },
        handleParsedValue: (value: string) => formatDateToISO(value, DATE_FORMAT),
        renderUploadPreview: ({ harvestDate }: BatchRecord) =>
          customDatePreview(harvestDate, [validationRequired, validationRegalSpringsDate]),
        render: (column: BatchRecord) => renderShortDateFormat(column.harvestDate),
        validate: [validationRequired, validationRegalSpringsDate],
        edit: { type: 'date' },
      },
      {
        key: 'destination',
        label: 'Destination',
        title: 'Destination',
        style: { flex: '1 0 50px' },
        handleParsedValue: (value: string) => String(value || ''),
        renderUploadPreview: (column: BatchRecord) => column.destination,
        render: (column: BatchRecord) => column.destination,
        validate: [validationString],
        edit: { type: 'input' },
      },
      {
        key: 'farmSite',
        label: 'Farm Site',
        title: 'Farm Site',
        style: { flex: '1 0 50px' },
        handleParsedValue: (value: string) => String(value || ''),
        renderUploadPreview: (column: BatchRecord) => column.farmSite,
        render: (column: BatchRecord) => column.farmSite,
        validate: [validationString],
        edit: { type: 'input' },
      },
      {
        key: 'netFarm',
        label: 'Net Farm',
        title: 'Net Farm',
        style: { flex: '1 0 50px' },
        handleParsedValue: (value: string) => String(value || ''),
        renderUploadPreview: (column: BatchRecord) => column.netFarm,
        render: (column: BatchRecord) => column.netFarm,
        validate: [validationRequired, validationString],
        edit: { type: 'input' },
      },
      {
        key: 'projectId',
        label: 'Project Id',
        title: 'Project Id',
        style: { flex: '1 0 50px' },
        handleParsedValue: (value: string) => String(value || ''),
        renderUploadPreview: (column: BatchRecord) => column.projectId,
        render: (column: BatchRecord) => column.projectId,
        validate: [validationRequired, validationString],
        edit: { type: 'input' },
      },
      {
        key: 'volume',
        label: 'Volume',
        title: 'Volume',
        style: { flex: '1 0 50px' },
        renderUploadPreview: (column: BatchRecord) => column.volume,
        render: (column: BatchRecord) => column.volume,
        validate: [validationRequired, validationFloat],
        edit: { type: 'input' },
      },
    ],
    growUpList: [
      {
        key: 'projectId',
        label: 'Project Id',
        title: 'projectId',
        style: { flex: '1 0 50px' },
        handleParsedValue: (value: string) => String(value || ''),
        renderUploadPreview: (column: BatchRecord) => column.projectId,
        render: (column: BatchRecord) => column.projectId,
        validate: [validationRequired, validationString],
        edit: { type: 'input' },
      },
      {
        key: 'feedSupplier',
        label: 'Feed Supplier',
        title: 'Feed Supplier',
        style: { flex: '1 0 50px' },
        handleParsedValue: (value: string) => String(value || ''),
        renderUploadPreview: (column: BatchRecord) => column.feedSupplier,
        render: (column: BatchRecord) => column.feedSupplier,
        validate: [validationRequired, validationString],
        edit: { type: 'input' },
      },
      {
        key: 'feedType',
        label: 'Feed Type',
        title: 'Feed Type',
        style: { flex: '1 0 50px' },
        handleParsedValue: (value: string) => String(value || ''),
        renderUploadPreview: (column: BatchRecord) => column.feedType,
        render: (column: BatchRecord) => column.feedType,
        validate: [validationRequired, validationString],
        edit: { type: 'input' },
      },
      {
        key: 'feedQuantity',
        label: 'Feed Quantity',
        title: 'Feed Quantity',
        style: { flex: '1 0 50px' },
        renderUploadPreview: (column: BatchRecord) => column.feedQuantity,
        render: (column: BatchRecord) => column.feedQuantity,
        validate: [validationRequired, validationFloat],
        edit: { type: 'input' },
      },
    ],
    stockingList: [
      {
        key: 'deliverySlipNote',
        label: 'Delivery Slip/Delivery Note',
        title: 'Delivery Slip Note',
        style: { flex: '1 0 50px' },
        handleParsedValue: (value: string) => String(value || ''),
        renderUploadPreview: (column: BatchRecord) => column.deliverySlipNote,
        render: (column: BatchRecord) => column.deliverySlipNote,
        validate: [validationRequired, validationString],
        edit: { type: 'input' },
      },
      {
        key: 'stockingDate',
        label: 'Stocking Date',
        title: 'Stocking Date',
        style: { flex: '1 0 50px' },
        handleParsedValue: (value: string) => formatDateToISO(value, DATE_FORMAT),
        renderUploadPreview: ({ stockingDate }: BatchRecord) =>
          customDatePreview(stockingDate, [validationRequired, validationRegalSpringsDate]),
        render: (column: BatchRecord) => renderShortDateFormat(column.stockingDate),
        validate: [validationRequired, validationRegalSpringsDate],
        edit: { type: 'date' },
      },
      {
        key: 'projectId',
        label: 'Project Id',
        title: 'Project Id',
        style: { flex: '1 0 50px' },
        handleParsedValue: (value: string) => String(value || ''),
        renderUploadPreview: (column: BatchRecord) => column.projectId,
        render: (column: BatchRecord) => column.projectId,
        validate: [validationRequired, validationString],
        edit: { type: 'input' },
      },
    ],
    harvestingHcList: [
      {
        key: 'slipNumberDeliveryNote',
        label: 'Slip Number/Delivery Note',
        title: 'Slip Number Delivery Note',
        style: { flex: '1 0 50px' },
        handleParsedValue: (value: string) => String(value || ''),
        renderUploadPreview: (column: BatchRecord) => column.slipNumberDeliveryNote,
        render: (column: BatchRecord) => column.slipNumberDeliveryNote,
        validate: [validationRequired, validationString],
        edit: { type: 'input' },
      },
      {
        key: 'harvestDate',
        label: 'Harvest Date',
        title: 'Harvest Date',
        style: { flex: '1 0 50px' },
        handleParsedValue: (value: string) => formatDateToISO(value, DATE_FORMAT),
        renderUploadPreview: ({ harvestDate }: BatchRecord) =>
          customDatePreview(harvestDate, [validationRequired, validationRegalSpringsDate]),
        render: (column: BatchRecord) => renderShortDateFormat(column.harvestDate),
        validate: [validationRequired, validationRegalSpringsDate],
        edit: { type: 'date' },
      },
      {
        key: 'destination',
        label: 'Destination',
        title: 'Destination',
        style: { flex: '1 0 50px' },
        handleParsedValue: (value: string) => String(value || ''),
        renderUploadPreview: (column: BatchRecord) => column.destination,
        render: (column: BatchRecord) => column.destination,
        validate: [validationString],
        edit: { type: 'input' },
      },
      {
        key: 'nurseryId',
        label: 'Nursery Id',
        title: 'Nursery Id',
        style: { flex: '3 0 50px' },
        handleParsedValue: (value: string) => String(value || ''),
        renderUploadPreview: (column: BatchRecord) => column.nurseryId,
        render: (column: BatchRecord) => column.nurseryId,
        validate: [validationRequired, validationString],
        edit: { type: 'input' },
      },
    ],
    nurseryList: [
      {
        key: 'nurseryId',
        label: 'Nursery Id',
        title: 'Nursery Id',
        style: { flex: '4 0 50px' },
        handleParsedValue: (value: string) => String(value || ''),
        renderUploadPreview: (column: BatchRecord) => column.nurseryId,
        render: (column: BatchRecord) => column.nurseryId,
        validate: [validationRequired, validationString],
        edit: { type: 'input' },
      },
      {
        key: 'itemName',
        label: 'Item Name',
        title: 'Item Name',
        style: { flex: '1 0 50px' },
        handleParsedValue: (value: string) => String(value || ''),
        renderUploadPreview: (column: BatchRecord) => column.itemName,
        render: (column: BatchRecord) => column.itemName,
        validate: [validationString],
        edit: { type: 'input' },
      },
      {
        key: 'size',
        label: 'Size',
        title: 'Size',
        style: { flex: '1 0 50px' },
        renderUploadPreview: (column: BatchRecord) => column.length,
        render: (column: BatchRecord) => column.length,
        validate: [validationFloat],
        edit: { type: 'input' },
      },
      {
        key: 'fishCounts',
        label: 'Fish Counts',
        title: 'Fish Counts',
        style: { flex: '1 0 50px' },
        renderUploadPreview: (column: BatchRecord) => column.fishCounts,
        render: (column: BatchRecord) => column.fishCounts,
        validate: [validationFloat],
        edit: { type: 'input' },
      },
      {
        key: 'feedSupplier',
        label: 'Feed Supplier',
        title: 'Feed Supplier',
        style: { flex: '3 0 50px' },
        handleParsedValue: (value: string) => String(value || ''),
        renderUploadPreview: (column: BatchRecord) => column.feedSupplier,
        render: (column: BatchRecord) => column.feedSupplier,
        validate: [validationRequired, validationString],
        edit: { type: 'input' },
      },
      {
        key: 'feedType',
        label: 'Feed Type',
        title: 'Feed Type',
        style: { flex: '1 0 50px' },
        handleParsedValue: (value: string) => String(value || ''),
        renderUploadPreview: (column: BatchRecord) => column.feedType,
        render: (column: BatchRecord) => column.feedType,
        validate: [validationRequired, validationString],
        edit: { type: 'input' },
      },
      {
        key: 'feedQty',
        label: 'Feed Qty',
        title: 'Feed Qty',
        style: { flex: '1 0 50px' },
        renderUploadPreview: (column: BatchRecord) => column.feedQty,
        render: (column: BatchRecord) => column.feedQty,
        validate: [validationRequired, validationFloat],
        edit: { type: 'input' },
      },
    ],
    massBalanceList: [
      {
        key: 'farmside',
        label: 'Farmside :',
        title: 'Farmside :',
        style: { flex: '1 0 50px' },
        handleParsedValue: (value: string) => String(value || ''),
        renderUploadPreview: (column: BatchRecord) => column.farmside,
        render: (column: BatchRecord) => column.farmside,
        validate: [validationRequired, validationString],
        edit: { type: 'input' },
      },
      {
        key: 'totalRmKg',
        label: 'Total RM (Kg):',
        title: 'Total RM (Kg):',
        style: { flex: '1 0 50px' },
        renderUploadPreview: (column: BatchRecord) => column.totalRmKg,
        render: (column: BatchRecord) => column.totalRmKg,
        validate: [validationFloat],
        edit: { type: 'input' },
      },
      {
        key: 'totalUfKg',
        label: 'Total UF (Kg):',
        title: 'Total UF (Kg):',
        style: { flex: '1 0 50px' },
        renderUploadPreview: (column: BatchRecord) => column.totalUfKg,
        render: (column: BatchRecord) => column.totalUfKg,
        validate: [validationFloat],
        edit: { type: 'input' },
      },
      {
        key: 'yieldPercentage',
        label: 'Yield (%)',
        title: 'Yield (%)',
        style: { flex: '1 0 50px' },
        renderUploadPreview: (column: BatchRecord) => column.yieldPercentage,
        render: (column: BatchRecord) => column.yieldPercentage,
        validate: [validationFloat],
        edit: { type: 'input' },
      },
    ],
  };

  if (params && params.orgName && params.orgName !== REGALSPRINGS) {
    return {
      ...model,
      detailList: model.detailList.filter((f) => !['cases', 'volume'].includes(f.key)),
      exportList: model.exportList.filter(
        (f) => !['customerNumber', 'customer', 'totalMc'].includes(f.key),
      ),
      harvestingFarmList: model.harvestingFarmList.filter((f) => !['volume'].includes(f.key)),
      growUpList: model.growUpList.filter((f) => !['feedSupplier', 'feedQuantity'].includes(f.key)),
      nurseryList: model.nurseryList.filter(
        (f) => !['size', 'fishCounts', 'feedSupplier', 'feedType', 'feedQty'].includes(f.key),
      ),
      massBalanceList: model.massBalanceList.filter(
        (f) => !['totalRmKg', 'totalUfKg', 'yieldPercentage'].includes(f.key),
      ),
    };
  }

  return model;
};
