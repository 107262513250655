import produce from 'immer';
import { AnyAction } from 'redux';

import * as envActions from './actions';
import * as commonActions from 'domain/common';

import { getStorageItem } from 'lib/storage';
import {CHANNELS} from 'themes/constants';

const initialState = {
  participantClass: null,
  participantId: null,
  pageTitle: 'Dashboard',
  settings: {},
  user: {},
  users: {},
  channels: [],
  channel: {},
  columns: [],
  notificationConfig: {},
  isLoading: false,
  token: '',
};

export function createInitialEnvState() {
  return { ...initialState, token: getStorageItem('token', '') };
}

const env = produce<(draft: typeof initialState, action: AnyAction) => void>(
  (draft, { type, payload }) => {
    switch (type) {
      // set channel action
      case envActions.setChannelAction.type:
        draft.channel = payload;
        draft.notificationConfig = initialState.notificationConfig;
        return;

      // logIn
      case envActions.logInAction.success:
        draft.token = payload.token;
        return;

      case envActions.logInAction.failure:
        return;

      // fetchProfile
      case envActions.fetchProfileAction.success:
        draft.user = payload.user;
        draft.channels = payload.channels;
        draft.columns = payload.columns;
        return;

      case envActions.fetchProfileAction.failure:
        const failedResponse = payload;

        // status: 401 i.e. "Unauthorized" -> don't show toasts
        // TODO remove?
        if (!failedResponse.response || failedResponse.response.status === 401) {
          return;
        }
        return;

      // logOut
      case envActions.logOutAction.success:
        return initialState;

      // env
      case envActions.setTableColumnsAction.success:
        draft.columns = [...draft.columns.filter((f) => f.viewName !== payload.viewName), payload];
        return;

      case envActions.setModelSettingsAction.success:
        // TODO same as EnvActions.getModelSettingsAction.success?
        draft.isLoading = false;
        draft.columns = payload;
        return;

      case envActions.setModelSettingsAction.failure:
        draft.isLoading = false;
        return;

      case envActions.fetchNotificationConfigAction.type:
        draft.isLoading = true;
        // draft.notificationConfig = payload;
        return;

      case envActions.fetchNotificationConfigAction.success:
        draft.isLoading = false;
        draft.notificationConfig = payload;
        return;

      case envActions.fetchNotificationConfigAction.failure:
        draft.isLoading = false;
        return;

      case envActions.updateNotificationConfigAction.type:
        draft.isLoading = true;
        return;

      case envActions.updateNotificationConfigAction.success:
        draft.isLoading = false;
        draft.notificationConfig = payload;
        return;

      case envActions.updateNotificationConfigAction.failure:
        draft.isLoading = false;
        return;

      case commonActions.fetchServiceDataAction.success:
        const { channelName, data } = payload;
        if (draft.channel.name === channelName) {
          const matchedOrg = data.orgs.find(({ id }) => id === draft.user.org.id);
          draft.user.org = matchedOrg;
          draft.user.role = matchedOrg.name;
        }
        return;
    }
  },
  initialState,
);

export const reducer = { env };
