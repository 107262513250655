import { syncAction, asyncAction } from 'lib/actions';

export const fetchProfileAction = asyncAction('auth/FETCH_PROFILE');

export const logInAction = asyncAction('auth/LOGIN');
export const logOutAction = asyncAction('auth/LOGOUT');

export const signUpAction = asyncAction('auth/SIGN_UP');

export const setChannelAction = asyncAction('auth/SET_CHANNEL');
export const changeChannelAction = asyncAction('auth/CHANGE_CHANNEL');

export const setBatchInfoByRouteTypeParamAction = syncAction(
  'auth/SET_BATCH_INFO_BY_ROUTE_TYPE_PARAM',
);
export const setParamsAction = asyncAction('auth/SET_PARAMS');
export const notifyRouteParamsReceivedAction = asyncAction('auth/NOTIFY_ROUTE_PARAMS_RECEIVED');
export const locationChangeAction = asyncAction('@@router/LOCATION_CHANGE');

export const fetchTableColumnsAction = asyncAction('env/FETCH_TABLE_COLUMNS');
//  save to redux and send to back
export const setTableColumnsAction = asyncAction('env/SET_TABLE_COLUMNS');
//  just save to redux
export const setLocalTableColumnsAction = asyncAction('env/SET_LOCAL_TABLE_COLUMNS');
export const setMatchedRouteAction = asyncAction('env/SET_MATCHED_ROUTE');
export const setModelSettingsAction = asyncAction('env/SET_MODEL_SETTINGS');
export const getModelSettingsAction = asyncAction('env/GET_MODEL_SETTINGS');
export const setDefaultModelSettingsAction = asyncAction('env/SET_DEFAULT_MODEL_SETTINGS');
export const toggleModelFieldAction = asyncAction('env/TOGGLE_MODEL_FIELD');

export const fetchNotificationConfigAction = asyncAction('env/FETCH_NOTIFICATION_CONFIG');
export const updateNotificationConfigAction = asyncAction('env/UPDATE_NOTIFICATION_CONFIG');
export const setTabsQueryParamsAction = asyncAction('env/SET_TABS_QUERY_PARAMS');
