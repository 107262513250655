import { validationRequired } from 'lib/validations';
import batchModel from './default';
import batchModelWitchCheckboxes from './defaultWitchCheckboxes';
import { BatchRecord, ModelRequiredInfo } from '../../lib/lib';
import { getCreationInfoWithDate } from '../utils/utils';

export default (modelRequiredInfo: ModelRequiredInfo, withCheckboxes = false) => {
  // @ts-ignore
  const isDistributor = modelRequiredInfo.loggedInUserRole === 'DISTRIBUTOR';
  const defaultBatchModel =
    withCheckboxes && isDistributor
      ? batchModelWitchCheckboxes(modelRequiredInfo)
      : batchModel(modelRequiredInfo);

  const detailsTab = {
    modelName: 'details',
    originalName: 'Details',
    title: 'Details',
  };

  const detailsModel = [
    {
      key: 'createdBy',
      label: 'Uploaded By',
      title: 'Uploaded By',
      style: { flex: '1 0 200px' },
      renderUploadPreview: (record: BatchRecord) => record.createdBy,
      render: (record: BatchRecord) => getCreationInfoWithDate(record),
      validate: [validationRequired],
      defaultSelectedColumn: true,
      defaultSelectedBatchContractColumn: true,
    },
  ];

  return {
    ...defaultBatchModel,
    tabs: [...defaultBatchModel.tabs, detailsTab],
    details: detailsModel,
  };
};
