import indexTab from 'models/admin/users/tabs/indexTab';
import { MODEL_TYPES } from 'themes/constants';

export default () => ({
  tabs: [
    {
      modelName: 'defaultTab',
      originalName: MODEL_TYPES.ADMIN_USERS,
      title: MODEL_TYPES.ADMIN_USERS,
    },
  ],
  defaultTab: indexTab(),
});
