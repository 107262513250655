import { Theme } from 'lib/lib';

export default (themes: Theme) => ({
  component: {
    display: 'block',
    padding: ['2em', 0],
    '& form': {
      maxWidth: 300,
      margin: [0, 'auto'],
      '& .formGroup': {
        margin: [0, 0, '2em', 0],
        '& > h2': {
          fontSize: themes.fontSizes.lg,
          marginBottom: '0.5em',
        },
        '& > p': {
          fontSize: themes.fontSizes.xs,
        },
        '& .inputField': {
          width: '100%',
          height: 40,
          outline: 0,
          borderRadius: 20,
          padding: [5, 10],
        },
      },
    },
  },

  select: {
    '& .react-select__control': {
      minHeight: 36,
      borderRadius: 20,
      borderWidth: 2,
      borderTopColor: 'rgba(143, 143, 143, 0.75)',
      borderLeftColor: 'rgba(143, 143, 143, 0.75)',
      borderRightColor: 'rgba(143, 143, 143, 0.25)',
      borderBottomColor: 'rgba(143, 143, 143, 0.25)',

      '&:hover': {
        borderTopColor: 'rgba(143, 143, 143, 0.75)',
        borderLeftColor: 'rgba(143, 143, 143, 0.75)',
        borderRightColor: 'rgba(143, 143, 143, 0.25)',
        borderBottomColor: 'rgba(143, 143, 143, 0.25)',
      },
    },
  },

  confirmButton: {
    ...themes.components.btn,
  },
});
