import importerbatch from 'mocks/router/tilapia/importerbatch';
import processorbatch from 'mocks/router/tilapia/processorbatch';
import producerbatch from 'mocks/router/tilapia/producerbatch';
import retailerbatch from 'mocks/router/tilapia/retailerbatch';

import generic from 'mocks/router/generic';

export default {
  ...generic,
  importerbatch,
  processorbatch,
  producerbatch,
  retailerbatch,
};
