import indexTabModel from 'models/seafoodConnection/tabs/defaultTabModel';
import { CHANNELS, ORGANIZATIONS } from 'themes/constants';
import archivedModelFields from 'models/archivedModelFields';
import { defaultContractSettingsAndActionsExcel } from 'models/utils/utils';
import { ModelRequiredInfo } from 'lib/lib';

export default (modelRequiredInfo: ModelRequiredInfo) => {
  const indexArchivedTabModel = [...indexTabModel(modelRequiredInfo), ...archivedModelFields()];
  return {
    tabs: [
      {
        modelName: 'defaultTab',
        originalName: ORGANIZATIONS.SEAFOODCONNECTION,
        title: ORGANIZATIONS.SEAFOODCONNECTION,
      },
    ],
    defaultTab: indexArchivedTabModel,
    settingsAndActions: defaultContractSettingsAndActionsExcel(CHANNELS.TILAPIA),
  };
};
