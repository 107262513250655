import { getChannelName, getChannels, changeChannelAction, getOwnOrgName, getUserId } from 'domain/env';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import React from 'react';
import { Channel } from 'lib/lib';

import { ChannelIconResolver } from './IconResolvers';
import { CHANNEL_NAME_TO_TITLE_MAP } from 'themes/main';
import { AppStateType } from 'types';
import { CHANNELS, ORGANIZATIONS } from 'themes/constants';

interface OnwProps {
  isOpen: boolean;
  toggleDropDownList: React.Dispatch<React.SetStateAction<boolean>>;
}

const mapStateToProps = (state: AppStateType) => ({
  channels: getChannels(state),
  channelName: getChannelName(state),
  orgName: getOwnOrgName(state),
  userId: getUserId(state)
});

const ChannelDropDown: React.FC<OnwProps> = (props) => {
  const dispatch = useDispatch();
  const { channels, channelName, orgName, userId } = useSelector(mapStateToProps, shallowEqual);
  const { isOpen, toggleDropDownList } = props;

  const channelNames = channels.map(({ name }) => name);

  const changeChannel = (nextChannel: Channel) => {
    const { name } = nextChannel;

    toggleDropDownList(!isOpen);

    if (name !== channelName) {
      const label =
        (CHANNEL_NAME_TO_TITLE_MAP[orgName] && CHANNEL_NAME_TO_TITLE_MAP[orgName][name]) ||
        CHANNEL_NAME_TO_TITLE_MAP.common[name];
      if (window.confirm(`Are you sure you want to switch to "${label!.toLowerCase()}" channel?`)) {
        dispatch(changeChannelAction(nextChannel));
      }
    }
  };

  return (
    <div className={`channelSelect ${isOpen ? 'isOpen' : ''}`}>
      {channels.map((channel) => {
        const { name } = channel;

        if (
          [ORGANIZATIONS.REFRESCO, ORGANIZATIONS.AH].includes(orgName) &&
          name === CHANNELS.JUICE && userId != 72
        ) {
          return null;
        }

        const label =
          (CHANNEL_NAME_TO_TITLE_MAP[orgName] && CHANNEL_NAME_TO_TITLE_MAP[orgName][name]) ||
          CHANNEL_NAME_TO_TITLE_MAP.common[name];

        if (channelNames)
          return (
            <div key={name} onClick={() => changeChannel(channel)} data-channel={name}>
              <ChannelIconResolver channelName={name} />

              <span>{label!.toLowerCase()}</span>
            </div>
          );
      })}
    </div>
  );
};

export default ChannelDropDown;
