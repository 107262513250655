import { asyncAction } from 'lib/actions';

export const fetchDocumentsAction = asyncAction('documents/FETCH_DOCUMENTS');
export const fetchDocumentAction = asyncAction('documents/FETCH_DOCUMENT');
export const createDocumentAction = asyncAction('documents/CREATE_DOCUMENT');
export const deleteDocumentAction = asyncAction('documents/DELETE_DOCUMENT');
export const approveDocumentAction = asyncAction('documents/APPROVE_DOCUMENT');
//
export const uploadCertFileAction = asyncAction('documents/UPLOAD_DOCUMENT_FILE');
export const getCertFileAction = asyncAction('documents/GET_DOCUMENT_FILE');
