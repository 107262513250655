import Route from 'route-parser';

import ldcbrBatchIndexListResponseOranges from 'mocks/responses/channels/oranges/ldcbrbatch/indexList';
import ldcbrBatchContractListResponseOranges from 'mocks/responses/channels/oranges/ldcbrbatch/contractList';
import ldcbrBatchDetailsResponseOranges from 'mocks/responses/channels/oranges/ldcbrbatch/details';
import uploadBatchResponse from 'mocks/responses/batchUpload/batchUploadEggsOranges';

import { deleleConractResponseEggsOranges } from 'mocks/router/utils';

export default (request) => {
  const route = new Route(
    '/api/v1/oranges/ldcbrbatch(?*filter)(/:batchId)(/contracts/:contractId)',
  );
  const parameters = route.match(request.url);
  if (parameters) {
    if (parameters.filter) {
      if (parameters.filter.includes('beginDate')) {
        return [200, ldcbrBatchIndexListResponseOranges()];
      }
    }

    if (parameters.contractId) {
      if (request.method === 'delete') {
        return [
          200,
          deleleConractResponseEggsOranges(decodeURIComponent(parameters.contractId), 'ldcbr'),
        ];
      }

      return [200, ldcbrBatchContractListResponseOranges()];
    }

    if (parameters.batchId) {
      return [200, ldcbrBatchDetailsResponseOranges()];
    }
  }

  if (request.method === 'post') {
    return [200, uploadBatchResponse(JSON.parse(request.data))];
  }

  return [500, `url not proceeded: ${request.url}`];
};
