import axios from 'axios/index';
import qs from 'qs';
import AxiosMock from 'axios-mock-adapter';

// config
import { getStorageItem } from 'lib/storage';

const { REACT_APP_USE_MOCKS } = process.env;

const getAxiosInstance = () =>
  axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    withCredentials: true,
    paramsSerializer: (params) =>
      qs.stringify(params, { encode: false, skipNulls: true, arrayFormat: 'brackets' }),
  });

const axiosInstance = getAxiosInstance();

axiosInstance.interceptors.request.use(
  (config) => ({
    ...config,
    headers: {
      Authorization: `Bearer ${getStorageItem('token')}`,
      ...config.headers,
    },
    withCredentials: true,
    testsCookies:  document.cookie,

  }),
  (err) => Promise.reject(err),
);

if (REACT_APP_USE_MOCKS) {
  const mocksRouter = require('mocks/routeParser').default;

  const mockedAxios = new AxiosMock(axiosInstance, { delayResponse: 0 });

  mockedAxios.onAny().reply((config) => {
    const { method, headers, url, data , testsCookies} = config;

    return mocksRouter(method, headers, url, data, testsCookies);
  });
}

// wrapped as function to aviod mocks execution
export const httpClient = axiosInstance;
