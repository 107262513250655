import React from 'react';
import { useSelector, shallowEqual } from 'react-redux';
// routes
import { LayoutRoutes } from 'routes';
//
import { AppStateType } from 'types';
//
import { getUIIsLoading } from 'domain/ui';
import { getCurrentLayout } from 'domain/common';

const mapStateToProps = (state: AppStateType) => ({
  isLoading: getUIIsLoading(state),
  layout: getCurrentLayout(state),
});

const App: React.FC = () => {
  const { isLoading, layout } = useSelector(mapStateToProps, shallowEqual);

  return (
    <>
      {isLoading ? (
        <div className="loading">
          <div id="spinner" />
          Loading...
        </div>
      ) : (
        <LayoutRoutes layout={layout} />
      )}
    </>
  );
};

export default React.memo(App);
