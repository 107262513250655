import { peek, selector } from 'lib/selectors';
import { AppStateType } from 'types';
import { NotificationResponse, NotificationsMeta } from 'lib/lib';

const notifications = (state: AppStateType) => state.notifications;

export const getNotifications = selector<NotificationResponse>(notifications, peek('resources'));
// export const getNotification = selector(notifications, peek('resource'));
export const getMeta = selector<NotificationsMeta>(notifications, peek('meta'));
export const getIsLoading = selector(notifications, peek('isLoading'));
export const getIsUpdating = selector(notifications, peek('isUpdating'));
