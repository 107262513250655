export default () => [
  {
    articleDescriptionBulk: 'Tilapiafilet (TI.MAY.210.130)',
    articleDescriptionConsumerProduct: 'Tilapiafilet 2 st, ontdooid met kruidenzakje',
    articleNumberBulk: '21573',
    articleNumberConsumerProduct: '871845224101',
    batchId: '26b49a9e-c46a-4dd2-9bd2-c8b8bb0be654',
    contractId: '871845224101',
    createdAtMin: '2019-07-03T10:12:01.056Z',
    createdAtMax: '2019-07-03T10:12:01.056Z',
    createdBy:
      'resource:org.powerchain.tilapia.member.ProcessorUser#Mayonna_58945e88-4654-42dd-9071-0025fbd6e840',
    dateReceipt: '2019-01-01T16:00:00.000Z',
    deliveryDate: '2019-01-01T16:00:00.000Z',
    numberOfConsumerPackages: 2.454,
    processingDate: '2019-01-01T16:00:00.000Z',
    productBatchIdBulk: '181011061',
    productType: 'Filet (skin off)',
    qualityCheck1: 'qualityCheck1',
    specie: 'Tilapia',
    status: 'PENDING',
    thtBulk: '2019-01-01T16:00:00.000Z',
    thtConsumerProduct: '2019-01-01T16:00:00.000Z',
    uploadSource: 'api',
    volumeBulkInKg: 3160.5,
    volumeConsumerProductInKg: 134.94,
    deliveryDateMax: '2019-09-16T00:00:00.000Z',
    deliveryDateMin: '2018-11-02T00:00:00.000Z',
    processingDateMax: '2019-09-15T00:00:00.000Z',
    processingDateMin: '2018-11-01T00:00:00.000Z',
    thtBulkMin: '2018-10-11T00:00:00.000Z',
    thtBulkMax: '2018-11-01T00:00:00.000Z',"createdBy_participant":{
      "username":null,
      "email":null,
      "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
      "org":{
        "name":"Fyffes",
        "fullname":"Fyffes",
        "logo":null
      },
      "channel":{
        "title":"Bananas",
        "name":"bananas"
      },
      "user":{
        "username":"Fyffes TST staging",
        "email":"FyffesTST@pc.t2f.io",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        }
      }
    }
  },
];
