import { Action, Payload, AsyncActionCreator, SyncActionCreator } from './lib';
import { ActionCreator as AC } from 'redux';

export const SUFFIXES = {
  PENDING: 'PENDING',
  SUCCESS: 'SUCCESS',
  FAILURE: 'FAILURE',
};

const defaultActionMaker = <P>(type: string, payload: P) => ({ type, payload });

type ACConfig<P> = {
  makeAction?: AC<Action<P>>;
};

export function asyncAction<P = Payload>(
  base: string,
  { makeAction = defaultActionMaker }: ACConfig<P> = {},
): AsyncActionCreator<P> {
  const actionCreator = (payload: P) => makeAction(base, payload);

  actionCreator.type = base;
  actionCreator.pending = `${base}/${SUFFIXES.PENDING}`;
  actionCreator.success = `${base}/${SUFFIXES.SUCCESS}`;
  actionCreator.failure = `${base}/${SUFFIXES.FAILURE}`;
  return actionCreator;
}

export function syncAction<P = Payload>(
  base: string,
  { makeAction = defaultActionMaker }: ACConfig<P> = {},
): SyncActionCreator<P> {
  const actionCreator = (payload: P) => makeAction(base, payload);

  actionCreator.type = base;
  return actionCreator;
}
