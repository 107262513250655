export default (theme) => ({
  component: {
    padding: ['2em', 0],
  },
  addRequest: {
    display: 'flex',
    alignItems: 'flex-end',

    '& > a': {
      display: 'inline-flex',
      alignItems: 'center',
      color: theme.colors.white,
      margin: 0,
      padding: [5, 9],
      borderRadius: [10, 10, 0, 0],
      cursor: 'pointer',
      backgroundColor: theme.colors.primary,
      transition: 'background-color 0.5s ease',
      textDecoration: 'none',
      border: 0,
      '&:hover': {
        backgroundColor: theme.colors.primaryHover,
        textDecoration: 'none',
      },
      '& svg': {
        width: 24,
        height: 24,
        fill: theme.colors.white,
        marginRight: 10,
      },
      '& span': {
        font: {
          size: theme.fontSizes.sm,
          family: 'Lato',
          weight: 400,
        },
        color: theme.colors.white,
      },
    },
  },
});
