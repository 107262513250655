import React from 'react';
// components
import ModelSettingsDropdown from 'components/Dropdown/ModelSettingsDropDown';
import DownloadCSV from 'models/utils/DownloadCSV/DownloadCSV';
import DetailsLink from 'models/utils/DetailsLink/DetailsLink';
// types
import { BatchRecord } from 'lib/lib';

export default {
  key: 'settingsAndActions',
  title: (
    <React.Fragment>
      <DownloadCSV />
      <ModelSettingsDropdown />
    </React.Fragment>
  ),
  style: { flex: '1 0 105px', justifyContent: 'space-evenly' },
  render: (column: BatchRecord) => <DetailsLink column={column} channelName={column.channelName} />,
};
