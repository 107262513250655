import { asyncAction } from 'lib/actions';

export const fetchBatchAction = asyncAction('batches/FETCH_BATCH');
export const fetchBatchWithUserAction = asyncAction('batches/FETCH_BATCH_WITH_USER');
export const fetchBatchesAction = asyncAction('batches/FETCH_BATCHES');
export const fetchBatchContractAction = asyncAction('batches/FETCH_BATCH_CONTRACT');
export const fetchBatchValidationAction = asyncAction('batches/FETCH_BATCH_VALIDATION');
export const approveBatchValidationAction = asyncAction('batches/APPROVE_BATCH_VALIDATION');
export const fetchBatchHandshakeAction = asyncAction('batches/FETCH_BATCH_HANDSHAKE');
export const deleteMultipleBatchesAction = asyncAction('batches/DELETE_MULTIPLE_BATCHES');
export const deleteBatchAction = asyncAction('batches/DELETE_BATCH');
export const archiveBatchContractAction = asyncAction('batches/ARCHIVE_BATCH_CONTRACT');
export const uploadBatchesCSVAction = asyncAction('batches/UPlOAD_BATCHES_CSV');
export const splitUploadBatchesCSVAction = asyncAction('batches/SPLIT_UPlOAD_BATCHES_CSV');
export const uploadBatchesExcelAction = asyncAction('batches/UPlOAD_BATCHES_EXCEL');
export const approveBatchContractAction = asyncAction('batches/APPROVE_BATCH_CONTRACT');
export const setSelectedBatchesAction = asyncAction('batches/SET_SELECTED_BATCHES');
export const fetchQueueStatusAction = asyncAction('batches/FETCH_QUEUE_STATUS');
