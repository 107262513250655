import React from 'react';
import PropTypes from 'prop-types';
//
import { connect } from 'react-redux';
import { compose } from 'redux';
// qs
import { parse } from 'query-string';
// redux-form
import { Field, reduxForm } from 'redux-form';
//
import { toast } from 'lib/helpers';
// icons
import { CheckSvg, EyeSvg, EyeHiddenSvg } from 'components/icons';
// jss
import injectSheet from 'react-jss';
import resetPasswordStyles from 'pages/auth/resetPassword/resetPasswordStyles';
import * as profileActions from 'domain/profile/actions';
// selectors
import { getStatus } from 'domain/profile';
import { getParamsLocationSearch } from 'domain/common';

class ForgotPassword extends React.Component {
  token = null;

  state = {
    password: true,
    passwordConfirmation: true,
  };

  componentDidMount() {
    const { clearStatus, search } = this.props;
    const parameters = parse(search);
    clearStatus();
    if (!parameters.token) {
      toast('Wrong URL format.');
    }
    this.token = parameters.token;
  }

  validateNewPassword = () => {};

  validateConfirmPassword = () => {};

  submit = (values) => {
    const { resetPassword } = this.props;

    if (!values.password) {
      toast('The password required (8 charts, 1 number and 1 special at least )');
      return false;
    }

    if (values.password !== values.password_confirmation) {
      toast("Password and password's confirmation must be equal.");
      return false;
    }

    if (
      ['powerchain', 'ldc', 'refresco', 'jde', 'sim', 'eggs', 'oranges', 'password'].find((s) =>
        String(values.password)
          .toLowerCase()
          .includes(s),
      )
    ) {
      toast("Don't use words 'password', 'powerchain', orgnames, etc");
      return false;
    }

    if (String(values.password).length < 8) {
      toast('Password should be 8 characters at least');
      return false;
    }

    // eslint-disable-next-line
    const formatSpecial = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
    const formatNumber = /\d+/;

    if (
      !formatSpecial.test(String(values.password)) ||
      !formatNumber.test(String(values.password))
    ) {
      toast('Password should be 8 characters at least and contains 1 number and 1 special char');
      return false;
    }

    resetPassword({
      password: values.password,
      password_confirmation: values.password_confirmation,
      token: this.token,
    });
    return true;
  };

  togglePassword = () => {
    const { password } = this.state;
    this.setState({ password: !password });
  };

  togglePasswordConfirmation = () => {
    const { passwordConfirmation } = this.state;
    this.setState({ passwordConfirmation: !passwordConfirmation });
  };

  render() {
    const { classes, handleSubmit, status } = this.props;
    const { password, passwordConfirmation } = this.state;

    return status === 'success' ? (
      <div className={classes.changesAcceptedBlock}>
        <CheckSvg />
        <div className="title">Changed successfully. Login with new password.</div>
      </div>
    ) : (
      <div className={classes.formWrapper}>
        <form onSubmit={handleSubmit(this.submit)}>
          <div className={classes.formGroup}>
            <p className={classes.formGroupTitle}>Enter new password:</p>
          </div>

          <div className={classes.formGroup}>
            <Field
              name="password"
              title="Type new password"
              type={`${password ? 'password' : 'text'}`}
              validate={this.validateNewPassword}
              component="input"
              className={classes.inputField}
              props={{
                placeholder: '',
              }}
            />
            {password ? (
              <EyeHiddenSvg onClick={this.togglePassword} />
            ) : (
              <EyeSvg onClick={this.togglePassword} />
            )}
          </div>

          <div className={classes.formGroup}>
            <p className={classes.formGroupTitle}>Confirm new password:</p>
          </div>

          <div className={classes.formGroup}>
            <Field
              name="password_confirmation"
              title="Confirm new password"
              type={`${passwordConfirmation ? 'password' : 'text'}`}
              validate={this.validateConfirmPassword}
              component="input"
              className={classes.inputField}
              props={{
                placeholder: '',
              }}
            />
            {passwordConfirmation ? (
              <EyeHiddenSvg onClick={this.togglePasswordConfirmation} />
            ) : (
              <EyeSvg onClick={this.togglePasswordConfirmation} />
            )}
          </div>

          <div className={classes.formGroup}>
            <button className={classes.confirmButton} type="submit">
              Send new password
            </button>
          </div>
        </form>
      </div>
    );
  }
}

ForgotPassword.propTypes = {
  classes: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  resetPassword: PropTypes.func.isRequired,
  clearStatus: PropTypes.func.isRequired,
  status: PropTypes.string.isRequired,
  search: PropTypes.string,
};

export default compose(
  connect(
    (state) => ({
      status: getStatus(state),
      search: getParamsLocationSearch(state),
    }),
    {
      resetPassword: (data) => ({
        type: profileActions.sendResetPasswordAction.type,
        payload: data,
      }),
      clearStatus: () => ({ type: profileActions.clearStatusAction }),
    },
  ),
  reduxForm({
    form: 'form-reset-password',
  }),
  injectSheet(resetPasswordStyles),
)(ForgotPassword);
