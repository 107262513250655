import Route from 'route-parser';

export default (request) => {
  const route = new Route('/api/v1(/bananas)(/tilapia)/background-worker-tasks(/queue-state)');
  const parameters = route.match(request.url);
  if (parameters) {
    if (request.method === 'get') {
      return [200, { pendingTaskCount: 2, estimatedEndTime: 30 }];
    }
  }

  return [500, `url not proceeded: ${request.url}`];
};
