import { call, put, select } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import qs from 'qs';
import Api from 'domain/api';
// helper
import { showAxiosErrors, toast } from 'lib/helpers';
// actions
import * as batchesActions from './actions';
// selectors
import { getBatches } from './selectors';
import { getUrl } from 'routes';
import { CHANNELS, ORGANIZATIONS } from 'themes/constants';
import { getChannel, getChannelName, getOwnOrgName } from 'domain/env';
import { getBatchInfoByUrlOrgRole, getHierarchyByOrgName, getUrlOrgName } from 'domain/common';
import { Batch } from 'lib/lib';

export function* fetchQueueStatus() {
  const channelName = yield select(getChannelName);

  try {
    const { data } = yield call(Api.fetchQueueStatus, channelName);

    yield put({
      type: batchesActions.fetchQueueStatusAction.success,
      payload: data,
    });
  } catch (errors) {
    yield showAxiosErrors(errors.response, true);
    console.error(`Failed to fetchQueueStatus`, errors);

    yield put({
      type: batchesActions.fetchQueueStatusAction.failure,
    });
  }
}

export function* fetchBatches({ payload }) {
  try {
    const { data } = yield call(Api.fetchBatches, {
      batchEndpoint: String(payload.batchEndpoint).toLowerCase(),
      queryString: qs.stringify(payload.queryStringParams, {
        encodeValuesOnly: true,
        addQueryPrefix: true,
      }),
    });

    yield put({
      type: batchesActions.fetchBatchesAction.success,
      payload: data,
    });
  } catch (errors) {
    yield showAxiosErrors(errors.response);

    yield put({
      type: batchesActions.fetchBatchesAction.failure,
      payload: errors,
    });
  }
}

// TODO: merge fetchBatchWithUser and fetchBatchContract into single saga
export function* fetchBatchWithUser({ payload }) {
  try {
    const { data } = yield call(Api.fetchBatch, {
      batchId: payload.batchId,
      batchEndpoint: payload.batchEndpoint,
      filters: payload.filters || {},
    });

    yield put({
      type: batchesActions.fetchBatchAction.success,
      payload: data,
    });
  } catch (errors) {
    console.error(errors);
    yield showAxiosErrors(errors.response, true);
    yield put({
      type: batchesActions.fetchBatchAction.failure,
      payload: errors,
    });
  }
}

export function* fetchBatchContract({ payload }) {
  try {
    const { data } = yield call(Api.fetchBatchContract, {
      contractNumber: payload.contractNumber,
      batchEndpoint: payload.batchEndpoint,
      status: payload.filters.status,
    });

    yield put({
      type: batchesActions.fetchBatchContractAction.success,
      payload: data,
    });
  } catch (errors) {
    yield showAxiosErrors(errors.response);
    yield put({
      type: batchesActions.fetchBatchContractAction.failure,
      payload: errors,
    });
  }
}

export function* fetchBatchValidation({ payload }) {
  try {
    const { data } = yield call(Api.fetchBatchValidation, {
      endpoint: payload.endpoint,
      batchNumber: payload.batchNumber,
    });

    yield put({
      type: batchesActions.fetchBatchValidationAction.success,
      payload: data,
    });
  } catch (errors) {
    yield showAxiosErrors(errors.response);
    yield put({
      type: batchesActions.fetchBatchValidationAction.failure,
      payload: errors,
    });
  }
}

export function* approveBatchValidation({ payload }) {
  try {
    const { data } = yield call(Api.approveBatchValidation, {
      endpoint: payload.endpoint,
      batchNumber: payload.batchNumber,
      data: payload.data,
    });

    yield put({
      type: batchesActions.approveBatchValidationAction.success,
      payload: data,
    });
    yield toast('Successfully approved!', 'success');
    yield put(push(getUrl('Batches')));
  } catch (errors) {
    yield showAxiosErrors(errors.response);
    yield put({
      type: batchesActions.approveBatchValidationAction.failure,
      payload: errors,
    });
  }
}

export function* fetchBatchHandshake({ payload }) {
  try {
    const { data } = yield call(Api.fetchBatchHandshake, {
      endpoint: payload.endpoint,
      filters: payload.filters,
    });

    yield put({
      type: batchesActions.fetchBatchHandshakeAction.success,
      payload: data,
    });
  } catch (errors) {
    yield showAxiosErrors(errors.response);
    yield put({
      type: batchesActions.fetchBatchHandshakeAction.failure,
      payload: errors,
    });
  }
}

export function* fetchBatch({ payload }) {
  try {
    const { data } = yield call(Api.fetchBatch, {
      batchId: payload.batchId,
      batchEndpoint: payload.batchEndpoint,
      filters: payload.filters || {},
    });

    yield put({
      type: batchesActions.fetchBatchAction.success,
      payload: data,
    });
  } catch (errors) {
    yield showAxiosErrors(errors.response, true);
    yield put({
      type: batchesActions.fetchBatchAction.failure,
      payload: errors,
    });
  }
}

export function* uploadBatchesCSV({ payload }) {
  try {
    const { endpoint }: Batch = yield select(getBatchInfoByUrlOrgRole);

    const { data } = yield call(Api.uploadBatches, {
      endpoint,
      batchesToSend: payload,
    });

    yield put({
      type: batchesActions.uploadBatchesCSVAction.success,
      payload: data,
    });

    toast('Batches were successfully uploaded!', 'success');

    yield put(push(getUrl('Batches')));
  } catch (errors) {
    yield showAxiosErrors(errors.response);
    yield put({
      type: batchesActions.uploadBatchesCSVAction.failure,
      payload: errors,
    });
  }
}

const splitUploadToasts = (response) => {
  if (typeof response !== 'object') {
    return;
  }

  const toastKeys = Object.keys(response);
  for (const key of toastKeys) {
    const entity = response[key];
    const toastType = entity && entity.status === 'success' ? 'success' : undefined;
    const message = entity && entity.message;
    message && toast(`${key}: ${message}`, toastType);
  }
};

export function* splitUploadBatchesCSV({ payload }) {
  try {
    const { endpoint }: Batch = yield select(getBatchInfoByUrlOrgRole);

    const { data } = yield call(Api.splitUploadBatches, {
      endpoint,
      batchesToSend: { resources: payload },
    });

    yield put({
      type: batchesActions.splitUploadBatchesCSVAction.success,
      payload: data,
    });

    splitUploadToasts(data);

    yield put(push(getUrl('Batches')));
  } catch (errors) {
    const status409 = !!(errors && errors.response && errors.response.status === 409);
    if (status409) {
      splitUploadToasts(errors.response.data);
      yield put(push(getUrl('Batches')));
    } else {
      yield showAxiosErrors(errors.response);
    }
    yield put({
      type: batchesActions.splitUploadBatchesCSVAction.failure,
      payload: errors,
    });
  }
}

function* pushGenericNotification() {
  yield toast('Batches were successfully uploaded!', 'success');
}

function* asyncBatchUploadNotifications(serverResponse = {}) {
  const { taskCount } = serverResponse;

  yield toast(
    `Upload successful! ${taskCount} tasks are being now created. On job completion a notification will be sent. For progress check Notifications page Refresh this view.`,
    'success',
  );
}

function* pushChannelSpecificNotifications(serverResponse) {
  const channel = yield select(getChannel);
  const channelName = channel.name;

  switch (channelName) {
    case CHANNELS.TILAPIA:
    case CHANNELS.BANANAS: {
      yield asyncBatchUploadNotifications(serverResponse);
      break;
    }

    default: {
      yield pushGenericNotification(serverResponse);
    }
  }
}

export function* uploadBatchesExcel({ payload }) {
  try {
    const { data } = yield call(Api.uploadExcelBatches, {
      data: payload.data,
      batchEndpoint: payload.batchEndpoint,
    });

    yield put({
      type: batchesActions.uploadBatchesExcelAction.success,
      payload: data,
    });

    yield pushChannelSpecificNotifications(data);

    yield put(push(getUrl('Batches')));
  } catch (errors) {
    yield showAxiosErrors(errors.response);
    yield put({
      type: batchesActions.uploadBatchesExcelAction.failure,
      payload: errors,
    });
  }
}

export function* deleteBatch({ payload }) {
  try {
    const { data } = yield call(Api.deleteBatch, {
      endpoint: payload.endpoint,
      batchId: encodeURIComponent(encodeURIComponent(payload.batchId)),
    });

    const batches = yield select(getBatches);
    const updatedBatches = batches.filter((batch) => batch.batchId !== data.batchId);

    yield put({
      type: batchesActions.deleteBatchAction.success,
      payload: updatedBatches,
    });
    toast(`Batch № ${data.batchId} is successfully archived!`, 'info');
  } catch (errors) {
    yield showAxiosErrors(errors.response);
    yield put({
      type: batchesActions.deleteBatchAction.failure,
      payload: errors,
    });
  }
}

export function* archiveBatchContract({ payload }) {
  try {
    const { data } = yield call(Api.archiveBatchContract, {
      endpoint: payload.endpoint,
      batchNumber: payload.batchNumber,
    });
    yield put({
      type: batchesActions.archiveBatchContractAction.success,
      payload: { ...data, keyField: payload.keyField },
    });
    toast(`Batch № '${payload.batchNumber}' is successfully archived!`, 'info');
  } catch (errors) {
    yield showAxiosErrors(errors.response);
    yield put({
      type: batchesActions.archiveBatchContractAction.failure,
      payload: errors,
    });
  }
}

export function* getOrgInfoFromHierarchy(orgName) {
  const orgsHierarchy = yield select(getHierarchyByOrgName);
  return orgsHierarchy[orgName];
}

// approveBatchContractAction
export function* approveBatchContract({ payload }) {
  try {
    const batchInfo = yield select(getBatchInfoByUrlOrgRole);
    const { data } = yield call(Api.approveBatchContract, {
      contractId: payload.contractId,
      endpoint: batchInfo.endpoint,
      data: payload.data,
    });

    const urlOrgName = yield select(getUrlOrgName);
    const orgInfo = yield getOrgInfoFromHierarchy(urlOrgName);
    // TODO: put action with updated response data
    yield put({
      type: batchesActions.approveBatchContractAction.success,
      payload: { data: data[0], orgInfo },
    });
  } catch (errors) {
    yield showAxiosErrors(errors.response);
    yield put({
      type: batchesActions.approveBatchContractAction.failure,
      payload: errors,
    });
  }
}

export function* deleteMultipleBatches({ payload }) {
  try {
    const response = yield call(Api.deleteMultipleBatches, {
      endpoint: payload.endpoint,
      data: payload.data,
    });
    yield put({
      type: batchesActions.deleteMultipleBatchesAction.success,
      payload: { batchIds: response.data.batchIds, activeTabName: payload.activeTabName },
    });
    toast(`${response.data.archivedBatches.length} batches are successfully archived!`, 'success');
  } catch (errors) {
    yield showAxiosErrors(errors.response);
    yield put({
      type: batchesActions.deleteMultipleBatchesAction.failure,
      payload: errors,
    });
  }
}
