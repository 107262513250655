import React, { useCallback, useMemo } from 'react';
import { compose } from 'redux';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { NavLink, Link } from 'react-router-dom';
import Select, { ValueType, ActionMeta } from 'react-select';
// redux
import { getUser } from 'domain/env';
import { getOrgs } from 'domain/common';
import { setUsersFilterAction } from 'domain/admin/actions';
import { getFilter } from 'domain/admin';
// icons
import { CreateNewSvg } from 'components/icons';
// jss
import injectSheet, { WithStyles } from 'react-jss';
import AdminNavStyle, { activeNavStyles, defaultTheme } from 'pages/admin/styles/adminNavStyle';
// utils
import AC from 'domain/AC';
import { getUrl } from 'routes';
// types
import { AppStateType } from 'types';

interface SelectOption {
  value: number;
  label: string;
}

const mapStateToProps = (state: AppStateType) => ({
  user: getUser(state),
  orgs: getOrgs(state),
  filter: getFilter(state),
});

const AdminNav: React.FC<WithStyles<typeof AdminNavStyle>> = ({ classes }) => {
  const dispatch = useDispatch();
  const { user, orgs, filter } = useSelector(mapStateToProps, shallowEqual);
  const handleOrgsSelect = useCallback((option: ValueType<SelectOption>, desc: ActionMeta) => {
    if (desc.action === 'select-option') {
      option && dispatch(setUsersFilterAction({ org_id: (option as SelectOption).value }));
    }
  }, []);

  const options = useMemo(
    () =>
      orgs.map((org) => ({
        value: org.id,
        label: org.fullname,
      })),
    orgs,
  );

  const currentOrgID = filter.org_id || user.org.id;

  const defaultValue = useMemo(() => options.find((opt) => opt.value === currentOrgID), [options]);

  return (
    <div className={classes.adminNav}>
      <nav>
        <NavLink to={{ pathname: getUrl('AdminUsers') }} activeStyle={activeNavStyles}>
          Users
        </NavLink>
        |
        <NavLink to={{ pathname: getUrl('AdminInvitations') }} activeStyle={activeNavStyles}>
          Invitations
        </NavLink>
      </nav>

      <div className="controls">
        {AC.USER.CAN.ADMIN_USERS_LOOKUP && (
          <Select
            defaultValue={defaultValue}
            options={options}
            onChange={handleOrgsSelect}
            className={`${classes.selectStylesDefault} ${classes.select}`}
            classNamePrefix="react-select"
            theme={defaultTheme}
          />
        )}

        <Link className="newInvitation" to={{ pathname: getUrl('AdminInvitationCreate') }}>
          <CreateNewSvg />
          <span>New Invitation</span>
        </Link>
      </div>
    </div>
  );
};

export default compose<React.FC>(
  React.memo,
  injectSheet(AdminNavStyle),
)(AdminNav);
