export default () => [
  {
    $class: 'org.powerchain.bananas.batch.GroupedResellerBatchesConcept',
    banafoodSSCCPallet: '4954558154',
    antwerpSSCCCount: 1,
    minDeliveryDate: '2019-03-04T16:03:57.914Z',
    maxDeliveryDate: '2019-03-04T16:03:57.914Z',
    minReceiptDate: '2019-03-04T16:03:57.914Z',
    maxReceiptDate: '2019-03-04T16:03:57.914Z',
    createdAtMin: '2019-08-07T07:14:00.437Z',
    createdAtMax: '2019-08-07T07:14:00.437Z',
    status: 'PENDING',
    createdBy:
      'resource:org.powerchain.bananas.member.ResellerUser#ResellerUser_57445703-6fa9-4ae9-82a1-2303571b28e6',
    modifiedBy:
      'resource:org.powerchain.bananas.member.ResellerUser#ResellerUser_57445703-6fa9-4ae9-82a1-2303571b28e6',
  "modifiedBy_participant":{
  "username":null,
      "email":null,
      "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
      "org":{
    "name":"Fyffes",
        "fullname":"Fyffes",
        "logo":null
  },
  "channel":{
    "title":"Bananas",
        "name":"bananas"
  },
  "user":{
    "username":"Fyffes TST staging",
        "email":"FyffesTST@pc.t2f.io",
        "org":{
      "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
    }
  }
},
    "createdBy_participant":{
      "username":null,
      "email":null,
      "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
      "org":{
        "name":"Fyffes",
        "fullname":"Fyffes",
        "logo":null
      },
      "channel":{
        "title":"Bananas",
        "name":"bananas"
      },
      "user":{
        "username":"Fyffes TST staging",
        "email":"FyffesTST@pc.t2f.io",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        }
      }
    }
  },
  {
    $class: 'org.powerchain.bananas.batch.GroupedResellerBatchesConcept',
    banafoodSSCCPallet: '8843117127',
    antwerpSSCCCount: 1,
    minDeliveryDate: '2018-10-06T04:41:27.568Z',
    maxDeliveryDate: '2018-10-06T04:41:27.568Z',
    minReceiptDate: '2018-10-06T04:41:27.568Z',
    maxReceiptDate: '2018-10-06T04:41:27.568Z',
    createdAtMin: '2019-08-07T07:14:00.437Z',
    createdAtMax: '2019-08-07T07:14:00.437Z',
    status: 'PENDING',
    createdBy:
      'resource:org.powerchain.bananas.member.ResellerUser#ResellerUser_57445703-6fa9-4ae9-82a1-2303571b28e6',
    modifiedBy:
      'resource:org.powerchain.bananas.member.ResellerUser#ResellerUser_57445703-6fa9-4ae9-82a1-2303571b28e6',
    "modifiedBy_participant":{
      "username":null,
      "email":null,
      "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
      "org":{
        "name":"Fyffes",
        "fullname":"Fyffes",
        "logo":null
      },
      "channel":{
        "title":"Bananas",
        "name":"bananas"
      },
      "user":{
        "username":"Fyffes TST staging",
        "email":"FyffesTST@pc.t2f.io",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        }
      }
    },
    "createdBy_participant":{
      "username":null,
      "email":null,
      "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
      "org":{
        "name":"Fyffes",
        "fullname":"Fyffes",
        "logo":null
      },
      "channel":{
        "title":"Bananas",
        "name":"bananas"
      },
      "user":{
        "username":"Fyffes TST staging",
        "email":"FyffesTST@pc.t2f.io",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        }
      }
    }
  },
  {
    $class: 'org.powerchain.bananas.batch.GroupedResellerBatchesConcept',
    banafoodSSCCPallet: '7839636196',
    antwerpSSCCCount: 1,
    minDeliveryDate: '2019-06-22T19:50:26.695Z',
    maxDeliveryDate: '2019-06-22T19:50:26.695Z',
    minReceiptDate: '2019-06-22T19:50:26.695Z',
    maxReceiptDate: '2019-06-22T19:50:26.695Z',
    createdAtMin: '2019-08-07T07:14:00.437Z',
    createdAtMax: '2019-08-07T07:14:00.437Z',
    status: 'PENDING',
    createdBy:
      'resource:org.powerchain.bananas.member.ResellerUser#ResellerUser_57445703-6fa9-4ae9-82a1-2303571b28e6',
    modifiedBy:
      'resource:org.powerchain.bananas.member.ResellerUser#ResellerUser_57445703-6fa9-4ae9-82a1-2303571b28e6',
    "createdBy_participant":{
      "username":null,
      "email":null,
      "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
      "org":{
        "name":"Fyffes",
        "fullname":"Fyffes",
        "logo":null
      },
      "channel":{
        "title":"Bananas",
        "name":"bananas"
      },
      "user":{
        "username":"Fyffes TST staging",
        "email":"FyffesTST@pc.t2f.io",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        }
      }
    }
  },
  {
    $class: 'org.powerchain.bananas.batch.GroupedResellerBatchesConcept',
    banafoodSSCCPallet: '2310850626',
    antwerpSSCCCount: 1,
    minDeliveryDate: '2018-09-20T05:14:31.215Z',
    maxDeliveryDate: '2018-09-20T05:14:31.215Z',
    minReceiptDate: '2018-09-20T05:14:31.215Z',
    maxReceiptDate: '2018-09-20T05:14:31.215Z',
    createdAtMin: '2019-08-07T07:14:00.437Z',
    createdAtMax: '2019-08-07T07:14:00.437Z',
    status: 'PENDING',
    createdBy:
      'resource:org.powerchain.bananas.member.ResellerUser#ResellerUser_57445703-6fa9-4ae9-82a1-2303571b28e6',
    modifiedBy:
      'resource:org.powerchain.bananas.member.ResellerUser#ResellerUser_57445703-6fa9-4ae9-82a1-2303571b28e6',
    "createdBy_participant":{
      "username":null,
      "email":null,
      "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
      "org":{
        "name":"Fyffes",
        "fullname":"Fyffes",
        "logo":null
      },
      "channel":{
        "title":"Bananas",
        "name":"bananas"
      },
      "user":{
        "username":"Fyffes TST staging",
        "email":"FyffesTST@pc.t2f.io",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        }
      }
    }
  },
];
