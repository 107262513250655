import { Theme } from 'lib/lib';

export default (theme: Theme) => ({
  component: {
    padding: ['2em', 0],
    '& header': {
      display: 'flex',
      marginBottom: '1em',
      justifyContent: 'space-between',
      alignItems: 'center',
      '& .batchCode': {},
      '& .exportToExcel': {
        display: 'inline-flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        cursor: 'pointer',
        '& svg': {
          marginRight: '1em',
        },
        '& span': {},
      },
    },
    '& .tabsInner': {
      display: 'flex',
      flexWrap: 'nowrap',
      justifyContent: 'space-between',
      marginBottom: 0,
      '& .fileTab': {
        display: 'block',
        flexGrow: 1,
        padding: [10, 5],
        cursor: 'pointer',
        backgroundColor: theme.colors.primary,
        color: theme.colors.white,
        borderRadius: 0,
        userSelect: 'none',
      },
      '& .fileTab.active, & .fileTab:hover': {
        backgroundColor: theme.colors.primaryHover,
      },
    },
  },
});
