import { ORGANIZATIONS } from 'themes/constants';

export default (orgName) => {
  switch (orgName) {
    case ORGANIZATIONS.SIM:
      return {
        resource: {
          id: 66,
          email: 'SIMTST@pc.t2f.io',
          role: 'SIM',
          is_admin: false,
          flatChannels: [
            {
              id: 1,
              name: 'oranges',
              title: 'Oranges',
              participant: 'org.powerchain.member.SIMUser#SIM_3123365b-d1ce-4079-baa0-40e836920dcf',
              is_admin: false,
              approved_by_id: 59,
            },
            {
              id: 10,
              name: 'tilapia',
              title: 'Tilapia',
              participant:
                'org.powerchain.tilapia.member.AuditorUser#SIM_572ee33d-7022-43f3-bc23-80b027ba38f8',
              is_admin: false,
              approved_by_id: 59,
            },
            {
              id: 11,
              name: 'bananas',
              title: 'Bananas',
              participant:
                'org.powerchain.bananas.member.AuditorUser#SIM_b217c42a-5621-4e2f-a777-c548fbbdf0cc',
              is_admin: false,
              approved_by_id: 94,
            },
            {
              id: 2,
              name: 'eggs',
              title: 'Eggs',
              participant:
                'org.powerchain.egg.member.SIMUser#SIM_20ca0c7c-c962-49b4-95a2-b6d7cf4138a3',
              is_admin: false,
              approved_by_id: 59,
            },
            {
              id: 15,
              name: 'juice',
              title: 'Juice',
              participant:
                'org.powerchain.juice.member.SIMUser#SIM_20ca0c7c-c962-49b4-95a2-b6d7cf4138a3',
              is_admin: false,
              approved_by_id: 159,
            },
          ],
          username: 'SIM TST staging',
          org: {
            id: 5,
            name: 'SIM',
            fullname: 'Sim company',
            logo: null,
            is_approver: true,
            level: 50,
          },
          profile: null,
          viewConfigs: [
            {
              viewName: 'certificates::SIM',
              config: {
                defaultTab: [
                  'certId',
                  'documentType',
                  'entityCertified',
                  'until',
                  'status',
                  'approveReason',
                ],
              },
            },
            {
              viewName: 'notifications::SIM',
              config: {
                defaultTab: [
                  'actionType',
                  'eventTypeTitle',
                  'eventDoneBy',
                  'isRead',
                  'eventDoneAt',
                  'entityType',
                ],
              },
            },
            {
              viewName: 'certificates::SIM',
              config: {
                defaultTab: [
                  'certId',
                  'category',
                  'documentType',
                  'result',
                  'number',
                  'until',
                  'filesize',
                  'approveReason',
                  'status',
                  'approveMsg',
                  'approvedBy',
                  'uploadedBy',
                ],
              },
            },
            {
              viewName: 'batchContract::eggs::SIM::toOrganizationEGG1',
              config: {
                defaultTab: [
                  'farmName',
                  'farmEggNumber',
                  'thtDateFrom',
                  'ifsPacker',
                  'brcPacker',
                  'ikbPacker',
                  'katPacker',
                  'blkLevelPacker',
                  'ikbFarm',
                  'katFarm',
                  'blkLevelFarm',
                  'uploadSource',
                ],
              },
            },
            {
              viewName: 'batches::eggs',
              config: {
                defaultTab: [
                  'farmName',
                  'farmEggNumber',
                  'thtDateFrom',
                  'thtDateTo',
                  'packerName',
                  'ifsPacker',
                  'ikbPacker',
                  'createdAtMin',
                  'createdAtMax',
                  'createdBy',
                  'uploadSource',
                ],
              },
            },
            /*{ viewName: 'batchContractList::66::oranges::sim', config: ['lotFinishedProduct', 'descriptionFinishedProduct', 'qtyProducedPcsFinishedProduct', 'productionFinishedFinishedProduct', 'articleNbrFcoj', 'dateCheckFcoj', 'certificateOfConformityFcoj', 'organolepticCheckFcoj', 'createdAt', 'uploadSource', 'lotFcoj', 'vitaminCFinishedProduct', 'batchId', 'modifiedAt'] }, */ {
              viewName: 'batchContract::oranges::SIM::toOrganizationREFRESCO',
              config: {
                defaultTab: [
                  'lotFinishedProduct',
                  'articleNbrFinishedProduct',
                  'qtyProducedPcsFinishedProduct',
                  'qtyUsedLtrRawProduct',
                  'lotFcoj',
                  'certificateOfConformityFcoj',
                  'organolepticCheckFcoj',
                  'organolepticCheckFinishedProduct',
                  'approvedBy',
                  'uploadSource',
                  'articleNbrFcoj',
                  'dateDeliveryAhFert',
                ],
              },
            },
            {
              viewName: 'batchContract::bananas::SIM::toOrganizationFyffes',
              config: {
                details: ['status', 'uploadedBy'],
                processBatches: [
                  'floId',
                  'farmCode',
                  'farmName',
                  'productShortCode',
                  'containerNumber',
                  'SSCCPallet',
                  'stuffedDate',
                  'numberOfBoxes',
                  'voyageNumber',
                  'departureDate',
                  'portLoaded',
                ],
                deliveryBatches: [
                  'SSCCPallet',
                  'vessel',
                  'packagingType',
                  'producer',
                  'receiptDate',
                  'outboundTransportDate',
                  'numberOfLoadedBoxes',
                ],
              },
            },
            {
              viewName: 'batchContract::bananas::SIM::toOrganizationHollander',
              config: {
                details: ['createdBy', 'status'],
                inslagBatches: [
                  'batchNumber',
                  'relationshipCodeName',
                  'SSCCCode',
                  'mutationDate',
                  'packagesCount',
                ],
                uitslagBatches: [
                  'mutationDate',
                  'batchNumber',
                  'relationshipCodeName',
                  'packagesCount',
                ],
              },
            },
          ],
        },
      };

    case ORGANIZATIONS.HOLLANDER:
      return {
        resource: {
          id: 92,
          email: 'HollanderADMIN@pc.t2f.io',
          role: 'SIM',
          is_admin: true,
          flatChannels: [
            {
              id: 11,
              name: 'bananas',
              title: 'Bananas',
              participant:
                'org.powerchain.bananas.member.DistributorUser#Hollander_56d3395b-5160-467d-bd0d-74503820bcf3',
              is_admin: true,
              approved_by_id: null,
            },
          ],
          username: 'Hollander ADMIN staging',
          profile: null,
          org: {
            id: 46,
            name: 'Hollander',
            fullname: 'Hollander',
            logo: null,
            is_approver: false,
            level: 20,
          },
          viewConfigs: [
            {
              viewName: 'batchHandshake::bananas::Hollander::toOrganizationFyffes',
              config: [
                'floId',
                'farmCodeCount',
                'stuffedDate',
                'createdBy',
                'departureDate',
                'SSCCPalletCount',
                'createdAt',
              ],
            },
            {
              viewName: 'batchContract::bananas::Hollander::toOrganizationHollander',
              config: {
                details: ['status', 'createdBy'],
                inslagBatches: [
                  'batchNumber',
                  'relationshipCodeName',
                  'SSCCCode',
                  'mutationDate',
                  'packagesCount',
                ],
                uitslagBatches: [
                  'mutationDate',
                  'batchNumber',
                  'relationshipCodeName',
                  'packagesCount',
                ],
              },
            },
          ],
        },
      };
    case ORGANIZATIONS.FYFFES:
      return {
        resource: {
          id: 91,
          email: 'FyffesADMIN@pc.t2f.io',
          role: 'SIM',
          is_admin: true,
          flatChannels: [
            {
              id: 11,
              name: 'bananas',
              title: 'Bananas',
              participant:
                'org.powerchain.bananas.member.ProducerUser#Fyffes_a24ccd5e-c1cf-4747-a597-d10ff8905387',
              is_admin: true,
              approved_by_id: null,
            },
          ],
          username: 'Fyffes ADMIN staging',
          org: {
            id: 47,
            name: 'Fyffes',
            fullname: 'Fyffes',
            logo: null,
            is_approver: false,
            level: 10,
          },
          profile: null,
          viewConfigs: [
            {
              viewName: 'batches::bananas',
              config: {
                defaultTab: [
                  'floId',
                  'stuffedDate',
                  'departureDate',
                  'createdAtMin',
                  'createdAtMax',
                  'createdBy',
                ],
              },
            },
            {
              viewName: 'batchContract::bananas::Fyffes::toOrganizationFyffes',
              config: {
                details: ['status', 'uploadedBy'],
                processBatches: [
                  'floId',
                  'farmCode',
                  'farmName',
                  'productShortCode',
                  'containerNumber',
                  'SSCCPallet',
                  'stuffedDate',
                  'numberOfBoxes',
                  'voyageNumber',
                  'departureDate',
                  'portLoaded',
                ],
                deliveryBatches: [
                  'SSCCPallet',
                  'vessel',
                  'packagingType',
                  'producer',
                  'receiptDate',
                  'outboundTransportDate',
                  'numberOfLoadedBoxes',
                ],
              },
            },
            {
              viewName: 'certificates::bananas',
              config: ['certId', 'documentType', 'entityCertified', 'fromUntil', 'approvedAtBy'],
            },
          ],
        },
      };
    case ORGANIZATIONS.BANAFOOD:
      return {
        resource: {
          id: 100,
          email: 'BanafoodADMIN@pc.t2f.io',
          role: 'SIM',
          is_admin: true,
          flatChannels: [
            {
              id: 11,
              name: 'bananas',
              title: 'Bananas',
              participant:
                'org.powerchain.bananas.member.ResellerUser#Banafood_fd644eac-858c-4dd3-99c0-b6f0e8328b55',
              is_admin: true,
              approved_by_id: null,
            },
          ],
          username: 'Banafood user',
          org: {
            id: 48,
            name: 'Banafood',
            fullname: 'Banafood',
            logo: null,
            is_approver: false,
            level: 15,
          },
          profile: null,
          viewConfigs: [
            {
              viewName: 'batchContract::bananas::Banafood::toOrganizationBanafood',
              config: {
                details: ['createdBy', 'status'],
                inBatches: ['boxes', 'receiptDate', 'colombiaSSCCPallet'],
                outBatches: [
                  'banafoodSSCCPallet',
                  'antwerpSSCC',
                  'deliveryDate',
                  'boxes',
                  'productDescription',
                  'product',
                ],
              },
            },
          ],
        },
      };

    case ORGANIZATIONS.MAYONNA:
      return {
        resource: {
          id: 85,
          email: 'MayonnaTST@pc.t2f.io',
          role: 'Mayonna',
          is_admin: false,
          flatChannels: [
            {
              id: 10,
              name: 'tilapia',
              title: 'Tilapia',
              participant:
                'org.powerchain.tilapia.member.ProcessorUser#Mayonna_58945e88-4654-42dd-9071-0025fbd6e840',
              is_admin: false,
              approved_by_id: 89,
            },
          ],
          username: 'MayonnaTST',
          org: {
            id: 42,
            name: 'Mayonna',
            fullname: 'Mayonna',
            logo: null,
            is_approver: false,
            level: 30,
          },
          profile: null,
          viewConfigs: [
            {
              viewName: 'batchHandshake::tilapia::Mayonna::toOrganizationRegalSprings',
              config: {
                defaultTab: [
                  'batchCodeId',
                  'productsQuantity',
                  'shippingDate',
                  'storageDate',
                  'packingDate',
                  'createdAt',
                  'createdBy',
                ],
              },
            },
            {
              viewName: 'batchContract::tilapia::Mayonna::toOrganizationSeafoodConnection',
              config: {
                defaultTab: [
                  'productBatchIdBulk',
                  'containerNumber',
                  'customerArticleNumber',
                  'specie',
                  'volumeBulkReceiptInKg',
                  'poNumberJumbo',
                  'ssccPallet',
                  'descriptionSim',
                  'modifiedAt',
                  'modifiedBy',
                ],
              },
            },
            {
              viewName: 'batchContract::tilapia::Mayonna::toOrganizationMayonna',
              config: {
                defaultTab: [
                  'productBatchIdBulk',
                  'productType',
                  'thtConsumerProduct',
                  'thtBulk',
                  'articleNumberConsumerProduct',
                  'poNumberJumbo',
                  'ssccPallet',
                  'createdAt',
                  'createdBy',
                  'modifiedAt',
                  'modifiedBy',
                ],
              },
            },
            {
              viewName: 'batches::tilapia',
              config: {
                defaultTab: [
                  'articleNumberConsumerProduct',
                  'deliveryDate',
                  'thtBulk',
                  'status',
                  'createdAtMin',
                  'createdAtMax',
                  'createdBy',
                  'modifiedAt',
                  'modifiedBy',
                ],
              },
            },
          ],
        },
      };

    case ORGANIZATIONS.REGALSPRINGS:
      return {
        resource: {
          id: 83,
          email: 'RegalSpringsTST@pc.t2f.io',
          role: 'RegalSprings',
          is_admin: false,
          flatChannels: [
            {
              id: 10,
              name: 'tilapia',
              title: 'Tilapia',
              participant:
                'org.powerchain.tilapia.member.ProducerUser#RegalSprings_8900eb90-c29b-458a-aa40-301057d9b81f',
              is_admin: false,
              approved_by_id: 87,
            },
          ],
          username: 'RegalSpringsTST',
          org: {
            id: 43,
            name: 'RegalSprings',
            fullname: 'Regal Springs',
            logo: null,
            is_approver: false,
            level: 10,
          },
          profile: null,
          viewConfigs: [
            {
              viewName: 'batches::tilapia',
              config: {
                defaultTab: [
                  'batchCodeId',
                  'storageDate',
                  'packingDate',
                  'createdAtMin',
                  'createdAtMax',
                  'createdBy',
                  'productsQuantity',
                  'shippingDate',
                ],
              },
            },
          ],
        },
      };

    case ORGANIZATIONS.JUMBO: {
      return {
        resource: {
          id: 86,
          email: 'JumboTST@pc.t2f.io',
          role: 'Jumbo',
          is_admin: false,
          flatChannels: [
            {
              id: 10,
              name: 'tilapia',
              title: 'Tilapia',
              participant:
                'org.powerchain.tilapia.member.RetailerUser#Jumbo_e1d059a8-05a0-40e9-ae9f-256341b22c96',
              is_admin: false,
              approved_by_id: 90,
            },
          ],
          username: 'JumboTST',
          viewConfigs: [
            {
              viewName: 'batchHandshake::tilapia::Jumbo::toOrganizationRegalSprings',
              config: {
                defaultTab: [
                  'batchCodeId',
                  'productsQuantity',
                  'packingDate',
                  'shippingDate',
                  'storageDate',
                  'createdAt',
                  'createdBy',
                ],
              },
            },
            {
              viewName: 'batches::tilapia',
              config: {
                defaultTab: [
                  'articleNumberConsumerProduct',
                  'thtConsumerProduct',
                  'dateReceipt',
                  'uniqPalletNumbersAmount',
                  'status',
                  'createdAtMin',
                  'createdAtMax',
                  'createdBy',
                  'modifiedAt',
                  'modifiedBy',
                ],
              },
            },
            {
              viewName: 'certificates::tilapia',
              config: {
                defaultTab: [
                  'certId',
                  'documentType',
                  'entityCertified',
                  'until',
                  'approveReason',
                  'from',
                  'approvedBy',
                ],
              },
            },
            {
              viewName: 'batchHandshake::tilapia::Jumbo::toOrganizationMayonna',
              config: {
                defaultTab: [
                  'articleNumberConsumerProduct',
                  'deliveryDate',
                  'processingDate',
                  'thtBulk',
                  'createdAt',
                  'createdBy',
                  'status',
                ],
              },
            },
            {
              viewName: 'batchContract::tilapia::Jumbo::toOrganizationJumbo',
              config: {
                defaultTab: [
                  'articleNumberConsumerProduct',
                  'dateReceipt',
                  'supplier',
                  'palletNumber',
                  'lotNumber',
                  'aantalVerpakkingen',
                  'thtConsumerProduct',
                  'createdAt',
                  'createdBy',
                ],
              },
            },
          ],
          profile: null,
          org: {
            id: 44,
            name: 'Jumbo',
            fullname: 'Jumbo',
            logo: null,
            is_approver: false,
            level: 40,
          },
        },
      };
    }

    case ORGANIZATIONS.SEAFOODCONNECTION: {
      return {
        resource: {
          id: 84,
          email: 'SeafoodConnectionTST@pc.t2f.io',
          role: 'SeafoodConnection',
          is_admin: false,
          flatChannels: [
            {
              id: 10,
              name: 'tilapia',
              title: 'Tilapia',
              participant:
                'org.powerchain.tilapia.member.ImporterUser#SeafoodConnection_4d94b219-0e64-45f0-ba42-32eff7b0d7d6',
              is_admin: false,
              approved_by_id: 88,
            },
          ],
          username: 'SeafoodConnectionTST',
          org: {
            id: 41,
            name: 'SeafoodConnection',
            fullname: 'Seafood Connection',
            logo: null,
            is_approver: false,
            level: 20,
          },
          profile: null,
          viewConfigs: [
            {
              viewName: 'certificates::tilapia',
              config: {
                defaultTab: [
                  'certId',
                  'documentType',
                  'entityCertified',
                  'until',
                  'status',
                  'approveReason',
                ],
              },
            },
            {
              viewName: 'batches::tilapia',
              config: {
                defaultTab: [
                  'productBatchIdBulk',
                  'dateReceipt',
                  'deliveryDate',
                  'createdAtMin',
                  'createdAtMax',
                  'createdBy',
                  'modifiedAt',
                  'modifiedBy',
                ],
              },
            },
            {
              viewName: 'batchHandshake::tilapia::SeafoodConnection::toOrganizationRegalSprings',
              config: {
                defaultTab: [
                  'batchCodeId',
                  'productsQuantity',
                  'shippingDate',
                  'storageDate',
                  'packingDate',
                  'createdBy',
                  'createdAt',
                ],
              },
            },
            {
              viewName:
                'batchContract::tilapia::SeafoodConnection::toOrganizationSeafoodConnection',
              config: {
                defaultTab: [
                  'containerNumber',
                  'descriptionSim',
                  'customerArticleNumber',
                  'specie',
                  'volumeBulkReceiptInKg',
                  'poNumberJumbo',
                  'ssccPallet',
                ],
              },
            },
          ],
        },
      };
    }

    case ORGANIZATIONS.REFRESCO:
      return {
        resource: {
          id: 65,
          email: 'REFRESCOADMIN@pc.t2f.io',
          role: 'REFRESCO',
          is_admin: false,
          flatChannels: [
            {
              id: 1,
              name: 'oranges',
              title: 'Oranges',
              participant:
                'org.powerchain.member.REFRESCOUser#REFRESCO_e77178d1-2504-42d5-8094-075d44465460',
              is_admin: false,
              approved_by_id: 17,
            },
            {
              id: 15,
              name: 'juice',
              title: 'Juice',
              participant:
                'org.powerchain.juice.member.ProcessorUser#REFRESCO_de6a20ca-20cf-4b62-89ea-11c822c19b19',
              is_admin: false,
              approved_by_id: 59,
            },
          ],
          username: 'REFRESCO ADMIN staging',
          org: {
            id: 3,
            name: 'REFRESCO',
            fullname: 'Refresco company',
            logo: null,
            is_approver: false,
            level: 30,
          },
          profile: null,
          viewConfigs: [
            {
              viewName: 'certificates::oranges',
              config: { defaulTab: ['certId', 'category', 'until', 'filesize', 'approveReason'] },
            },
          ],
        },
      };

    case ORGANIZATIONS.AH:
      return {
        resource: {
          id: 21,
          email: 'AHTST@pc.t2f.io',
          role: 'AH',
          is_admin: false,
          flatChannels: [
            {
              id: 1,
              name: 'oranges',
              title: 'Oranges',
              participant: 'org.powerchain.member.AHUser#AH_aeee94af-494b-4cdf-92b3-3f58c6b162ce',
              is_admin: false,
              approved_by_id: 17,
            },
            {
              id: 2,
              name: 'eggs',
              title: 'Eggs',
              participant:
                'org.powerchain.egg.member.AHUser#AH_ac45c8d3-aba8-4433-aec2-812876211693',
              is_admin: false,
              approved_by_id: 58,
            },
            {
              id: 15,
              name: 'juice',
              title: 'Juice',
              participant:
                'org.powerchain.juice.member.RetailerUser#AHUser_de6a20ca-20cf-4b62-89ea-11c822c19b19',
              is_admin: false,
              approved_by_id: 59,
            },
          ],
          username: 'AHTST staging',
          profile: {
            id: 20,
            user_id: 21,
            org: 'AH',
            position: null,
            pref_lang: null,
            photo: null,
            gender: null,
          },
          viewConfigs: [
            {
              viewName: 'certificates::eggs',
              config: {
                defaultTab: [
                  'certId',
                  'documentType',
                  'entityCertified',
                  'until',
                  'status',
                  'approveReason',
                ],
              },
            },
            {
              viewName: 'batchContract::oranges::AH::toOrganizationAH',
              config: {
                defaultTab: [
                  'gtin',
                  'glnSupplier',
                  'glnBuyer',
                  'glnDeliveryLocation',
                  'deliveryDatetime',
                  'batchnumber',
                  'createdAt',
                  'createdBy',
                  'uploadSource',
                  'farmAddress',
                  'farmName',
                  'modifiedAt',
                  'modifiedBy',
                ],
              },
            },
            {
              viewName: 'batchHandshake::eggs::AH::toOrganizationEGG1',
              config: {
                defaultTab: [
                  'farmName',
                  'farmEggNumber',
                  'thtDateFrom',
                  'packerGln',
                  'ikbPacker',
                  'blkLevelFarm',
                  'createdAt',
                  'createdBy',
                  'uploadSource',
                  'thtDateTo',
                ],
              },
            },
            {
              viewName: 'certificates::oranges',
              config: { defaultTab: ['certId', 'category', 'until', 'filesize', 'approveReason'] },
            },
            {
              viewName: 'batchContract::eggs::AH::toOrganizationAH',
              config: {
                defaultTab: [
                  'gtin',
                  'glnSupplier',
                  'glnDeliveryLocation',
                  'quantity',
                  'deliveryDatetime',
                  'batchnumber',
                  'uploadSource',
                  'createdAt',
                  'modifiedAt',
                  'modifiedBy',
                ],
              },
            },
            {
              viewName: 'batches::eggs',
              config: {
                defaultTab: [
                  'gtin',
                  'glnSupplier',
                  'glnBuyer',
                  'glnDeliveryLocation',
                  'quantity',
                  'batchnumber',
                  'createdAtMin',
                  'createdAtMax',
                  'uploadSource',
                  'createdBy',
                ],
              },
            },
            {
              viewName: 'batchContract::oranges::AH::toOrganizationLDCBR',
              config: {
                defaultTab: [
                  'jdeReference',
                  'contractNumber',
                  'qttyContracted',
                  'batchFinal',
                  'productCode',
                  'uploadSource',
                  'farmName',
                  'modifiedAt',
                  'modifiedBy',
                ],
              },
            },
          ],
          org: {
            id: 4,
            name: 'AH',
            fullname: 'Ah company',
            logo: null,
            is_approver: false,
            level: 40,
          },
        },
      };

    case ORGANIZATIONS.JDE:
      return {
        resource: {
          id: 19,
          email: 'JDETST@pc.t2f.io',
          role: 'JDE',
          is_admin: false,
          flatChannels: [
            {
              id: 1,
              name: 'oranges',
              title: 'Oranges',
              participant: 'org.powerchain.member.JDEUser#JDE_e4e32907-61ac-4509-9177-07b320ef9f47',
              is_admin: false,
              approved_by_id: 17,
            },
          ],
          username: 'JDETST staging',
          profile: {
            id: 18,
            user_id: 19,
            org: 'JDE',
            position: null,
            pref_lang: null,
            photo: null,
            gender: null,
          },
          org: {
            id: 2,
            name: 'JDE',
            fullname: 'Jde company',
            logo: null,
            is_approver: false,
            level: 20,
          },
          viewConfigs: [
            {
              viewName: 'certificates::oranges',
              config: {
                defaultTab: [
                  'certId',
                  'category',
                  'until',
                  'filesize',
                  'approveReason',
                  'uploadedAt',
                  'uploadedBy',
                  'number',
                ],
              },
            },
            {
              viewName: 'batches::oranges',
              config: {
                defaultTab: [
                  'releaseNumber',
                  'kgShipped',
                  'shipToNumber',
                  'invoiceDate',
                  'kpiRatio',
                  'lotSerialNumber',
                  'uploadSource',
                  'createdAtMin',
                  'createdAtMax',
                ],
              },
            },
            {
              viewName: 'batchContract::oranges::JDE::toOrganizationJDE',
              config: {
                defaultTab: [
                  'batchesLevelOne',
                  'jdeReference',
                  'contractNumber',
                  'qttyContracted',
                  'batchFinal',
                  'productCode',
                  'uploadSource',
                ],
              },
            },
          ],
        },
      };

    case ORGANIZATIONS.LDCBR:
      return {
        resource: {
          id: 63,
          email: 'LDCBRADMIN@pc.t2f.io',
          role: 'LDCBR',
          is_admin: false,
          flatChannels: [
            {
              id: 1,
              name: 'oranges',
              title: 'Oranges',
              participant:
                'org.powerchain.member.LDCBRUser#LDCBR_7c3cd418-9271-4074-accc-ef8e5ca08eba',
              is_admin: true,
              approved_by_id: null,
            },
          ],
          username: 'LDCBR ADMIN staging',
          profile: null,
          org: {
            id: 1,
            name: 'LDCBR',
            fullname: 'Ldcbr company',
            logo: null,
            is_approver: false,
            level: 10,
          },
          viewConfigs: [],
        },
      };

    case ORGANIZATIONS.EGG1:
      return {
        resource: {
          id: 61,
          email: 'EGG1TST@pc.t2f.io',
          role: 'EGG1',
          is_admin: false,
          flatChannels: [
            {
              id: 2,
              name: 'eggs',
              title: 'Eggs',
              participant:
                'org.powerchain.egg.member.EGGUser#EGG1_6bc380d4-5b16-4b87-8529-5b1f76034e64',
              is_admin: false,
              approved_by_id: 55,
            },
          ],
          username: 'EGG1 staging',
          org: {
            id: 6,
            name: 'EGG1',
            fullname: 'Egg Packer 1',
            logo: null,
            is_approver: false,
            level: 31,
          },
          profile: null,
          viewConfigs: [
            {
              viewName: 'batchContract::eggs::EGG1::toOrganizationEGG1',
              config: {
                defaultTab: [
                  'farmName',
                  'farmEggNumber',
                  'thtDateFrom',
                  'ifsPacker',
                  'brcPacker',
                  'ikbPacker',
                  'katPacker',
                  'blkLevelPacker',
                  'ikbFarm',
                  'katFarm',
                  'blkLevelFarm',
                  'uploadSource',
                  'participantName',
                  'orgName',
                ],
              },
            },
            {
              viewName: 'certificates::eggs',
              config: {
                defaultTab: ['certId', 'documentType', 'entityCertified', 'until', 'approveReason'],
              },
            },
            {
              viewName: 'batches::eggs',
              config: {
                defaultTab: [
                  'farmName',
                  'thtDateFrom',
                  'ifsPacker',
                  'brcPacker',
                  'ikbPacker',
                  'katPacker',
                  'blkLevelPacker',
                  'batchId',
                  'createdAtMin',
                  'createdAtMax',
                  'createdBy',
                  'uploadSource',
                ],
              },
            },
          ],
        },
      };

    case ORGANIZATIONS.EGG2:
      return {
        resource: {
          id: 62,
          email: 'EGG2TST@pc.t2f.io',
          role: 'EGG2',
          is_admin: false,
          flatChannels: [
            {
              id: 2,
              name: 'eggs',
              title: 'Eggs',
              participant:
                'org.powerchain.egg.member.EGGUser#EGG2_fee2baae-d52d-448f-b947-8095e6bdc753',
              is_admin: false,
              approved_by_id: 56,
            },
          ],
          username: 'EGG2 staging',
          profile: null,
          org: {
            id: 7,
            name: 'EGG2',
            fullname: 'Egg Packer 2',
            logo: null,
            is_approver: false,
            level: 31,
          },
          viewConfigs: [
            {
              viewName: 'batches::eggs',
              config: {
                defaultTab: [
                  'thtDateFrom',
                  'stableDescription',
                  'blkLevelFarm',
                  'batchId',
                  'createdAtMin',
                  'createdAtMax',
                  'createdBy',
                  'uploadSource',
                  'packerGln',
                ],
              },
            },
            {
              viewName: 'certificates::eggs',
              config: {
                defaultTab: [
                  'thtDateFrom',
                  'batchId',
                  'createdAt',
                  'createdBy',
                  'uploadSource',
                  'documentType',
                  'auditDate',
                ],
              },
            },
          ],
        },
      };

    case ORGANIZATIONS.CITROSUCOGHENT:
      return {
        resource: {
          id: 62,
          email: 'CitrosucoGhentTST@pc.t2f.io',
          role: ORGANIZATIONS.CITROSUCOGHENT,
          is_admin: false,
          flatChannels: [
            {
              id: 15,
              name: 'juice',
              title: 'Juice',
              participant:
                'org.powerchain.juice.member.SIMUser#SIM_20ca0c7c-c962-49b4-95a2-b6d7cf4138a3',
              is_admin: false,
              approved_by_id: 159,
            },
          ],
          username: 'Citrosuco Ghent',
          profile: null,
          org: {
            id: 114,
            name: ORGANIZATIONS.CITROSUCOGHENT,
            fullname: 'Citrosuco Ghent',
            logo: null,
            is_approver: false,
            level: 20,
          },
          viewConfigs: [],
        },
      };

    case ORGANIZATIONS.CITROSUCOBR:
      return {
        resource: {
          id: 63,
          email: 'CitrosucoBrazilTST@pc.t2f.io',
          role: ORGANIZATIONS.CITROSUCOBR,
          is_admin: false,
          flatChannels: [
            {
              id: 15,
              name: 'juice',
              title: 'Juice',
              participant:
                'org.powerchain.juice.member.SIMUser#SIM_20ca0c7c-c962-49b4-95a2-b6d7cf4138a3',
              is_admin: false,
              approved_by_id: 159,
            },
          ],
          username: 'Citrosuco Brazil',
          profile: null,
          org: {
            id: 113,
            name: ORGANIZATIONS.CITROSUCOBR,
            fullname: 'Citrosuco Brazil',
            logo: null,
            is_approver: false,
            level: 20,
          },
          viewConfigs: [],
        },
      };

    case ORGANIZATIONS.REFRESCOGMBH:
      return {
        resource: {
          id: 63,
          email: 'RefrescoGbmHTST@pc.t2f.io',
          role: ORGANIZATIONS.REFRESCOGMBH,
          is_admin: false,
          flatChannels: [
            {
              id: 15,
              name: 'juice',
              title: 'Juice',
              participant:
                'org.powerchain.juice.member.SIMUser#SIM_20ca0c7c-c962-49b4-95a2-b6d7cf4138a3',
              is_admin: false,
              approved_by_id: 159,
            },
          ],
          username: 'Refresco GbmH',
          profile: null,
          org: {
            id: 116,
            name: ORGANIZATIONS.REFRESCOGMBH,
            fullname: 'Refresco GbmH',
            logo: null,
            is_approver: false,
            level: 30,
          },
          viewConfigs: [],
        },
      };

    case ORGANIZATIONS.KAUFLANDDE:
      return {
        resource: {
          id: 63,
          email: 'KauflandDETST@pc.t2f.io',
          role: ORGANIZATIONS.KAUFLANDDE,
          is_admin: false,
          flatChannels: [
            {
              id: 15,
              name: 'juice',
              title: 'Juice',
              participant:
                'org.powerchain.juice.member.SIMUser#SIM_20ca0c7c-c962-49b4-95a2-b6d7cf4138a3',
              is_admin: false,
              approved_by_id: 159,
            },
          ],
          username: 'Kaufland DE',
          profile: null,
          org: {
            id: 117,
            name: ORGANIZATIONS.KAUFLANDDE,
            fullname: 'Kaufland DE',
            logo: null,
            is_approver: false,
            level: 40,
          },
          viewConfigs: [],
        },
      };

    default:
      return false;
  }
};
