import { takeEvery } from 'redux-saga/effects';

// users , invitations
import {
  fetchUsers,
  handleFilterChange,
  deleteUser,
  approveAdminUser,
  suspendAdminUser,
  fetchInvitations,
  createInvitation,
  updateInvitation,
  resendInvitation,
} from 'domain/admin';
import * as adminActions from 'domain/admin/actions';

export default function*() {
  yield takeEvery(adminActions.setUsersFilterAction.type, handleFilterChange);
  yield takeEvery(adminActions.fetchUsersAction.type, fetchUsers);
  yield takeEvery(adminActions.deleteUserAction.type, deleteUser);
  yield takeEvery(adminActions.approveAdminUserAction.type, approveAdminUser);
  yield takeEvery(adminActions.suspendAdminUserAction.type, suspendAdminUser);
  yield takeEvery(adminActions.fetchInvitationsAction.type, fetchInvitations);
  yield takeEvery(adminActions.createInvitationAction.type, createInvitation);
  yield takeEvery(adminActions.updateInvitationAction.type, updateInvitation);
  yield takeEvery(adminActions.resendInvitationAction.type, resendInvitation);
}
