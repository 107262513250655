import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import 'moment-duration-format';
import moment from 'moment';
import { getQueueStatus, getIsLoading } from 'domain/batches';
import * as batchesActions from 'domain/batches/actions';
import injectSheet from 'react-jss';
//resources
import { RefreshSVG } from 'components/icons';
//styles
import UploadWarningStyles from './styles/UploadWarningStyles';
import Loader from '../../../components/Loaders/smallLoader';

class UploadWarning extends React.Component {
  componentDidMount() {
    this.props.fetchQueueStatus();
  }

  getFormattedDuration = () => {
    const { queueStatus } = this.props;
    const estimatedEndTime = queueStatus.estimatedEndTime;

    const secondsToMinutes = estimatedEndTime / 60;

    return moment.duration(secondsToMinutes, 'minutes').format();
  };

  formWarningMessage = () => {
    const { queueStatus, classes } = this.props;
    const pendingTaskCount = queueStatus.pendingTaskCount;

    return (
      <div className={classes.container}>
        <div className={classes.messageContainer}>
          <span className={classes.textContainer}>
            Now {pendingTaskCount} batches in the queue, your batch will be a {pendingTaskCount + 1}
            . Approximate time to start processing your batch {this.getFormattedDuration()} minutes
          </span>
        </div>
        {this.formRefreshButton()}
      </div>
    );
  };

  formRefreshButton = () => {
    const { fetchQueueStatus, classes } = this.props;

    return (
      <span
        className={classes.refreshButton}
        onClick={(event) => {
          event.preventDefault();
          fetchQueueStatus();
        }}
      >
        <RefreshSVG height={20} width={20} />
      </span>
    );
  };

  isZeroTasksBefore = () => {
    const { queueStatus } = this.props;

    const pendingTaskCount = queueStatus.pendingTaskCount;
    const estimatedEndTime = queueStatus.estimatedEndTime;

    return pendingTaskCount === 0 && estimatedEndTime === 0;
  };

  isQueueEmpty = () => {
    const { isLoading } = this.props;

    return this.isZeroTasksBefore() && !isLoading;
  };

  showLoader = () => {
    const { classes } = this.props;

    return (
      <div className={classes.container}>
        <div className={classes.messageContainer}>
          <Loader theme="black" styles={{ margin: 'auto' }} />
        </div>
      </div>
    );
  };

  render() {
    const { isLoading } = this.props;

    if (isLoading) {
      return this.showLoader();
    }

    if (!this.isQueueEmpty()) {
      return this.formWarningMessage();
    }

    return null;
  }
}

export default compose(
  connect(
    (state) => ({
      queueStatus: getQueueStatus(state),
      isLoading: getIsLoading(state),
    }),
    {
      fetchQueueStatus: () => ({ type: batchesActions.fetchQueueStatusAction.type }),
    },
  ),
  injectSheet(UploadWarningStyles),
)(UploadWarning);
