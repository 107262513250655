import { MocksUser, MocksUsers } from './users';

const getHeaderFromRequest = (request: any) => {
  if (request.headers && request.headers.Authorization) {
    const jwt = request.headers.Authorization;
    return jwt.replace('Bearer ', '');
  }
  return false;
};

const getUserByToken = (token: string) => MocksUsers.find((f) => f.token === token);

export interface UserNotFountError {
  error: string;
}

export const getUserFromRequest = (request: any): MocksUser | UserNotFountError => {
  const headerToken = getHeaderFromRequest(request);

  if (!headerToken) {
    return { error: 'Something wrong with token in headers' };
  }

  const user = getUserByToken(headerToken);

  if (!user) {
    return { error: 'No user relied to token' };
  }
  return user;
};
