export default (theme) => ({
  dataTableUploadExcel: {
    overflow: 'hidden',
    width: '100%',
    '& > .wrapper': {
      overflowX: 'auto',
      overflowY: 'hidden',
      paddingBottom: '1em',
      width: 'auto',
      maxWidth: 'auto',
      '& .dataTableHead': {
        display: 'flex',
        alignItems: 'stretch',
        justifyContent: 'space-between',
        textAlign: 'left',
        font: {
          size: theme.fontSizes.sm,
        },
        '& > div': {
          display: 'flex',
          flexGrow: 1,
          alignItems: 'center',
          justifyContent: 'center',
          textAlign: 'center',
          padding: [10, 0],
          color: theme.colors.white,
          backgroundColor: theme.colors.primaryHover,
        },
        '& .downloadSvg': {
          fill: theme.colors.white,
          width: 24,
          cursor: 'pointer',
        },
      },
      '& .dataTableRow': {
        display: 'flex',
        minHeight: 36,
        alignItems: 'stretch',
        justifyContent: 'space-between',
        flexGrow: 1,
        margin: 0,
        padding: 0,
        borderBottom: '1px solid rgb(222, 222, 222)',
        '&:nth-of-type(odd) > div': {
          backgroundColor: theme.colors.white,
        },
        '&:nth-of-type(even) > div': {
          backgroundColor: theme.colors.background,
        },
        '& .error': {
          backgroundColor: 'rgba(239, 16, 72, 0.3) !important',
        },
        '& > div': {
          display: 'flex',
          fontSize: theme.fontSizes.sm,
          justifyContent: 'center',
          alignItems: 'center',
          textAlign: 'center',
          padding: [0, 0],
          borderLeft: '1px solid rgb(222, 222, 222)',
          '&:last-child': {
            borderRight: '1px solid rgb(222, 222, 222)',
          },
          '& img': {
            maxWidth: 30,
          },
          '& svg': {
            width: 24,
            fill: theme.colors.primary,
          },
          '& a.contract': {
            color: theme.colors.link,
          },
          '& a.batch-details': {
            color: theme.colors.white,
          },
          '& a.batch-archive': {
            color: theme.colors.white,
          },
          '& .sim svg': {
            fill: theme.colors.grey,
          },
          '& .warning': {
            '& svg': {
              width: 15,
              fill: theme.colors.warning,
              marginLeft: 5,
            },
          },
          '& .sim.approved svg': {
            fill: theme.colors.primary,
          },
          '& .batch-details, & .batch-archive, & .approve-batch, & .approve-document': {
            backgroundColor: theme.colors.primary,
            color: theme.colors.white,
            padding: [5, 10],
            borderRadius: 10,
            border: 0,
            transition: 'background-color 0.5s ease',
            outline: 0,
            cursor: 'pointer',
            margin: [0, 1],
            '&:hover': {
              backgroundColor: theme.colors.primaryHover,
              textDecoration: 'none',
            },
          },
          '& .batch-archive': {
            backgroundColor: 'rgb(95, 98, 115)',
          },
        },
      },
    },
  },
  emptyTableBodyContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: 15,

    '& svg': {
      marginLeft: 2,
      widht: 24,
      height: 24,
    },
  },
  emptyBodyMessage: {
    fontSize: 20,
    marginRight: 40,
  },
  iconContainer: {
    width: 150,
    padding: 4,
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    fontSize: 16,
    backgroundColor: 'rgb(58, 85, 108)',
    color: 'white',
    cursor: 'pointer',
    borderRadius: 20,
    '& span': {
      marginLeft: 15,
    },
  },

  rowHoverMenu: {
    height: '36px',
    width: '30px',
    border: 'none !important',
    background: 'transparent !important',
    cursor: 'pointer',
    userSelect: 'none',
    '& svg:active': {
      transform: 'translateY(1px)',
    },
  },
  createSubmenu: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around !important',
    alignItems: 'center',
    height: 72,
    width: 32,
    '& svg ': {
      width: 32,
    },
  },
  loader: { ...theme.components.loader },
});
