import React from 'react';
import { compose } from 'redux';
// hoc
import injectSheet, { WithStyles } from 'react-jss';
// icons
import { ArrowUpSvg, ArrowDownSvg } from 'components/icons/index';
// utils
import { useOutsideClick } from 'lib/utils';
// types
import { Theme } from 'lib/lib';

const styles = (theme: Theme) => ({
  dropDownMenuWrapper: {
    position: 'relative',
    cursor: 'pointer',
    padding: [0, 5],
    '& .dropDownMenuActionBtn': {
      display: 'flex',
      flexWrap: 'nowrap',
      alignItems: 'center',
      '& svg': {
        width: 15,
        height: 15,
        marginRight: '0.5em',
      },
      '& span': {},
    },
  },
  dropDownMenu: {
    position: 'absolute',
    top: 25,
    left: 'auto',
    right: 0,
    bottom: 'auto',
    overflow: 'hidden',
    display: 'flex',
    flexGrow: 0,
    height: 0,
    transition: 'flexGrow 1s ease-out',
    zIndex: 1,

    '&.isOpen': {
      flexGrow: 1,
      height: 'auto',
      // border: [2, 'solid', theme.colors.white],
      border: 0,
    },
    background: {
      color: theme.colors.white,
    },
    boxShadow: 'rgba(0, 0, 0, 0.2) 0px 1px 2px 0px',
    '& ul': {
      margin: ['0.25em', 0],
      userSelect: 'none',
      '& .item': {
        '& a, & div': {
          display: 'flex',
          justifyContent: 'flex-start',
          cursor: 'pointer',
          '&:hover': {
            textDecoration: 'none',
          },
          padding: ['0.5em', '1em'],
        },
      },
      '& .item:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.05)',
      },
    },
  },
});

export interface TableCellDropdownItem {
  key: string;
  title: string;
  onClick: () => void;
}

interface OwnProps {
  items: TableCellDropdownItem[];
}

const TableCellDropdown: React.FC<OwnProps & WithStyles<typeof styles>> = ({ classes, items }) => {
  const { isOpen, setIsOpen, ref } = useOutsideClick(false);

  const toggleDropDownMenu = () => setIsOpen(!isOpen);

  return (
    <div ref={ref} className={classes.dropDownMenuWrapper}>
      <div onClick={toggleDropDownMenu} className="dropDownMenuActionBtn">
        {isOpen ? <ArrowUpSvg /> : <ArrowDownSvg />}
        <span>Actions</span>
      </div>
      <div className={`${classes.dropDownMenu} ${isOpen ? 'isOpen' : ''}`}>
        <ul>
          {items.map((item) => (
            <li className="item" key={item.key}>
              <div onClick={() => item.onClick()}>
                <span>{item.title}</span>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default compose<React.FC<OwnProps>>(
  React.memo,
  injectSheet(styles),
)(TableCellDropdown);
