import React, { useCallback, useMemo, useRef, useState } from 'react';
import { compose } from 'redux';
import EditableCSVCellPopup from 'components/Datatable/editableCSVCellPopup';
import injectStyle, { WithStyles } from 'react-jss';
import editableCellStyles from 'components/Datatable/styles/editableCellStyles';
import { IndexedObject, ModelObjectDefault } from 'lib/lib';

interface OwnProps {
  handleCellUpdate: (rowId: number, key: string, handledParsedInputValue: string) => void;
  column: ModelObjectDefault;
  dataItem: IndexedObject<string>;
  dateFormat?: string;
}

const EditableCellExcel: React.FC<OwnProps & WithStyles<typeof editableCellStyles>> = (props) => {
  const { dataItem, column, children, classes, handleCellUpdate, dateFormat } = props;

  const defaultValue = useMemo(() => dataItem[column.key] || '', [dataItem, column]);

  const [isEditing, setIsEditing] = useState(false);
  const [inputValue, setInputValue] = useState(defaultValue);

  const inputRef = useRef<HTMLInputElement>(null);

  const onInputChange = useCallback((value: string) => setInputValue(value), [setInputValue]);

  const setDefaultValue = useCallback(() => {
    setInputValue(defaultValue);
    setIsEditing(false);
  }, [setInputValue, defaultValue, setIsEditing]);

  const applyChanges = useCallback(() => {
    const handledParsedInputValue = column.handleParsedValue
      ? column.handleParsedValue(inputValue)
      : inputValue;
    handleCellUpdate((dataItem.rowId as unknown) as number, column.key, handledParsedInputValue);
    setIsEditing(false);
  }, [handleCellUpdate, setInputValue, column, inputValue]);

  const onDoubleClick = useCallback(() => {
    debugger;
    setIsEditing(true);
    if (inputRef && inputRef.current) {
      try {
        // @ts-ignore
        if (typeof inputRef!.current!.setFocus === 'function') {
          // @ts-ignore
          inputRef!.current!.setFocus();
        } else {
          inputRef!.current!.focus();
        }
      } catch (e) {
        console.warn(
          `Failed to focus on editable element, which is likely used in popup, error : ${e}`,
        );
      }
    }
  }, [setIsEditing, inputRef]);

  return (
    <div className={classes.excel} onDoubleClick={onDoubleClick}>
      {isEditing ? (
        <EditableCSVCellPopup
          inputRef={inputRef}
          handleInputChange={onInputChange}
          inputValue={inputValue}
          edit={column.edit}
          label={column.label}
          handleApplyChanges={applyChanges}
          setDefaultValue={setDefaultValue}
          dateFormat={dateFormat}
          // this is original data which is wrapped by this HOC, used to keep styles CSV cell
          originalChildren={children}
        />
      ) : (
        children
      )}
    </div>
  );
};

export default compose<React.FC<OwnProps>>(
  injectStyle(editableCellStyles),
  React.memo,
)(EditableCellExcel);
