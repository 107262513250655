export default (theme) => ({
  formWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    backgroundColor: theme.colors.logInBackground,
    width: 800,
    padding: 20,
    margin: '0 auto',
    boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.25)',
    fontWeight: 300,
    '@media (max-width: 850px)': {
      width: '100%',
      flexDirection: 'column',
      height: 300,
      paddingBottom: 0,
    },
    '@media (min-height: 700px)': {
      marginBottom: '12em',
    },
  },
  inputField: {
    outline: 0,
    minWidth: theme.authLayout.inputMinWidth,
    height: theme.authLayout.inputHeight,
    backgroundColor: theme.colors.white,
    borderRadius: theme.authLayout.borderRadius,
    padding: [5, 10],
    color: theme.colors.black,
    border: [1, 'solid', theme.colors.logInBorderColor],
    fontFamily: 'inherit',
    '&::placeholder': {
      color: theme.colors.black,
    },
  },
  loginBtn: {
    minWidth: theme.authLayout.inputMinWidth,
    height: theme.authLayout.inputHeight,
    borderRadius: theme.authLayout.borderRadius,
    color: theme.colors.white,
    padding: [0, 15],
    fontSize: theme.fontSizes.sm,
    textAlign: 'center',
    background: 'linear-gradient(191.31deg, #AA3C42 0%, #CC121D 100%)',
    fontWeight: 'normal',
    fontFamily: 'inherit',
    border: 0,
    outline: 0,
    cursor: 'pointer',
  },
  forgotPassword: {
    height: 40,
    padding: ['0.5em', 0],
    '& a': {
      color: theme.colors.gray,
    },
    '@media (max-width: 850px)': {
      textAlign: 'center',
    },
  },
  formGroup: {
    '&.submit': {
      display: 'none',
      visibility: 'hidden',
      width: 0,
      height: 0,
    },
    '&.email input': {
      borderRadius: [theme.authLayout.borderRadius, theme.authLayout.borderRadius, 0, 0],
    },
    '&.password input': {
      borderRadius: [0, 0, theme.authLayout.borderRadius, theme.authLayout.borderRadius],
      marginBottom: 0,
    },
    '&:after': {
      display: 'table',
      content: ' ',
    },
  },
});
