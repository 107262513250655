import { takeEvery, call, put, select, take } from 'redux-saga/effects';
import { replace } from 'connected-react-router';

import * as uiActions from 'domain/ui/actions';
import * as envActions from 'domain/env/actions';
import * as commonActions from 'domain/common/actions';
import { getChannel, getChannels, getUser } from 'domain/env';
import { getCurrentLayout, getMatchedRoute } from 'domain/common';

import Api from 'domain/api';
import { routes } from 'routes';

import { clearStorage, getStorageItem, setStorageItem } from 'lib/storage';
import { showAxiosErrors, toast } from 'lib/helpers';
import { AnyAction } from 'redux';

export const init = function*() {
  const token = getStorageItem('token', '');

  if (token) {
    yield put(uiActions.setUiIsLoadingAction(true));
    yield put(envActions.fetchProfileAction());
  }
};

export function* makeAuthFetchProfile({ callback }) {
  try {
    const { data } = yield call(Api.fetchProfile);

    const user = {};
    Object.keys(data.resource)
      .filter((f) =>
        [
          'id',
          'approved_by_id',
          'email',
          'id',
          'is_admin',
          'is_approved',
          'org',
          'is_approver',
          'level',
          'logo',
          'name',
          'participant',
          'username',
        ].includes(f),
      )
      .forEach((m) => {
        user[m] = data.resource[m];
      });

    yield put({
      type: envActions.fetchProfileAction.success,
      payload: {
        user,
        channels: data.resource.flatChannels,
        columns: data.resource.viewConfigs,
      },
    });

    callback && callback(false);
  } catch (error) {
    if ([401, 403, 500].includes(error.response.status)) {
      clearStorage();
      yield showAxiosErrors(error.response);
      yield put({
        type: envActions.logOutAction.success,
        payload: '',
      });
    }

    if (!error.response) {
      toast('Unknown Error');
    }

    yield put(uiActions.setUiIsLoadingAction(false));
    yield put({
      type: envActions.fetchProfileAction.failure,
      payload: error,
    });

    callback && callback(false);
  }
}

export function* setStorageChannel() {
  const channelIdFromStorage = Number(getStorageItem('channel', 0));
  const channels = yield select(getChannels);

  if (channels.length === 1) {
    yield put(envActions.setChannelAction(channels[0]));
  } else {
    // get from storage (F5 fix)
    const currentChannel = channels.find((f) => f.id === channelIdFromStorage);

    if (currentChannel) {
      yield put(envActions.setChannelAction(currentChannel));
    } else {
      yield put(uiActions.setUiIsLoadingAction(false));
    }
  }
}

export function* handleSetChannel({ payload }) {
  yield put(uiActions.setUiIsLoadingAction(true));

  setStorageItem('channel', payload.id);
  const CookieDate = new Date();
  CookieDate.setFullYear(CookieDate.getFullYear() + 10);
  document.cookie = `channel=${payload.name}; expires=${CookieDate.toUTCString()}; path=/`;
  document.cookie = `uploadSource=frontEndApp; expires=${CookieDate.toUTCString()}; path=/`;

  const user = yield select(getUser);

  yield put(commonActions.fetchServiceDataAction({ orgId: user.org.id }));
}

export function* handleChangeChannel({ payload }: AnyAction) {
  yield put(envActions.setChannelAction(payload));
  yield call(goToDefaultRoute);
}

export function* goToDefaultRoute() {
  yield take(commonActions.fetchServiceDataAction.success);

  const currentRoute = yield select(getMatchedRoute);
  if (!currentRoute.default) {
    const layout = yield select(getCurrentLayout);

    let defaultPath = '/';

    try {
      const defaultRoute = routes[layout].find(({ default: df }) => df);
      defaultPath = defaultRoute.path;
    } finally {
      yield put(replace(defaultPath));
    }
  }
}

export function* fetchServiceData() {
  try {
    const channels = yield select(getChannels);

    for (const channel of channels) {
      const channelName = channel.name;

      const { data } = yield call(Api.fetchServiceData, { channelName });

      const serviceDataIsLoaded = channels.indexOf(channel) === channels.length - 1;

      yield put({
        type: commonActions.fetchServiceDataAction.success,
        payload: { channelName, data, serviceDataIsLoaded },
      });
    }
  } catch (errors) {
    console.error(errors);
    yield showAxiosErrors(errors.response, true);
    yield put({
      type: commonActions.fetchServiceDataAction.failure,
      payload: errors,
    });
  } finally {
    yield put(uiActions.setUiIsLoadingAction(false));
  }
}

export default function*() {
  yield takeEvery(envActions.fetchProfileAction.type, makeAuthFetchProfile);
  yield takeEvery(envActions.fetchProfileAction.success, setStorageChannel);
  yield takeEvery(envActions.setChannelAction.type, handleSetChannel);
  yield takeEvery(envActions.changeChannelAction.type, handleChangeChannel);
  yield takeEvery(commonActions.fetchServiceDataAction.type, fetchServiceData);

  yield takeEvery(envActions.logInAction.success, init);

  yield* init();
}
