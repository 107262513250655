import { Link } from 'react-router-dom';
import { getUrl } from 'routes';
import { CHANNELS, ORGANIZATIONS } from 'themes/constants';
import { UPLOAD_TIME_FORMAT } from 'themes/main';
import {
  validationCustomDate,
  validationInt,
  validationRequired,
  validationString,
} from 'lib/uploadFieldValidations';
import { customDatePreview, formatDateToISO, renderShortDateFormat } from 'lib/renderHelpers';
import React from 'react';
// components
import SubContractWarning from 'models/utils/SubContractWarning/SubContractWarning';
import { BatchRecord, ModelRequiredInfo } from 'lib/lib';

export default (modelRequiredInfo: ModelRequiredInfo) => {
  const { BANAFOOD } = ORGANIZATIONS;
  const { inBatches: IN_FORMAT } = UPLOAD_TIME_FORMAT[BANAFOOD] as Record<string, string>;
  const validationBanafoodInDate = validationCustomDate(IN_FORMAT);
  return [
    {
      key: 'colombiaSSCCPallet',
      label: 'Colombia SSCC pallet #',
      title: 'Colombia SSCC pallet #',
      style: { flex: '1 0 120px' },
      renderUploadPreview: (column: BatchRecord) => column.colombiaSSCCPallet,
      render: (column: BatchRecord) => (
        <SubContractWarning
          subContractAmount={column.subcontractAmount}
          linkText={column.colombiaSSCCPallet}
        >
          <Link
            to={{
              pathname: getUrl('BatchHandshake', {
                type: ORGANIZATIONS.FYFFES,
                channelName: CHANNELS.BANANAS,
              }),
              search: `SSCCPallet=${encodeURIComponent(column.colombiaSSCCPallet)}`,
            }}
          >
            {column.colombiaSSCCPallet}
          </Link>
        </SubContractWarning>
      ),
      validate: [validationRequired, validationString],
      defaultSelectedColumn: true,
      defaultSelectedBatchContractColumn: true,
    },
    {
      key: 'receiptDate',
      label: 'Date receipt',
      title: 'Date receipt',
      style: { flex: '1 0 90px' },
      handleParsedValue: (value: string) => formatDateToISO(value, IN_FORMAT),
      renderUploadPreview: ({ receiptDate }: BatchRecord) =>
        customDatePreview(receiptDate, [validationRequired, validationBanafoodInDate]),
      render: (column: BatchRecord) => renderShortDateFormat(column.receiptDate),
      validate: [validationRequired, validationBanafoodInDate],
      defaultSelectedColumn: true,
      defaultSelectedBatchContractColumn: true,
      edit: { type: 'date' },
    },
    {
      key: 'boxes',
      label: '# boxes',
      title: '# boxes',
      style: { flex: '1 0 50px' },
      renderUploadPreview: (column: BatchRecord) => column.boxes,
      render: (column: BatchRecord) => column.boxes,
      validate: [validationInt],
      defaultSelectedColumn: true,
      defaultSelectedBatchContractColumn: true,
    },
  ];
};
