import React from 'react';
import { renderShortDateFormat } from 'lib/renderHelpers';
import { extractCreatedBy } from 'models/utils/utils';
import { Link } from 'react-router-dom';
import { getUrl } from 'routes';
import {CHANNELS, ORGANIZATIONS} from 'themes/constants';
import { validationRequired, validationString } from 'lib/uploadFieldValidations';
import { BatchRecord, ModelRequiredInfo } from 'lib/lib';

export default (modelRequiredInfo: ModelRequiredInfo) => {
  const kgShippedInfo =
    // TODO: SHOULD BE 'RETAILER' OR SMTH LIKE THAT, NOT ORGNAME. SEE JUICE SERVICE DATA
    modelRequiredInfo.loggedInUserRole === ORGANIZATIONS.KAUFLANDDE
      ? []
      : [
          {
            key: 'kgShipped',
            label: 'KG Shipped',
            title: 'KG Shipped',
            style: { flex: '1 0 30px' },
            render: (column: BatchRecord) => column.kgShipped,
            renderExportValue: (column: BatchRecord) => column.kgShipped,
          },
        ];

  return [
    {
      key: 'vesselTkBatchId',
      label: 'Batch ID in Vessel TK',
      title: 'Batch ID in Vessel TK',
      defaultSelectedBatchContractColumn: true,
      style: { flex: '1 0 100px' },
      render: (column: BatchRecord) => (
        <Link
          to={{
            pathname: getUrl('BatchHandshake', {
              type: ORGANIZATIONS.CITROSUCOBR,
              channelName: CHANNELS.JUICE,
            }),
            search: `vesselTkBatchId=${encodeURIComponent(
              encodeURIComponent(column.vesselTkBatchId),
            )}`,
          }}
        >
          {column.vesselTkBatchId}
        </Link>
      ),
      renderExportValue: (column: BatchRecord) => column.vesselTkBatchId,
    },
    {
      key: 'vesselTkTrip',
      label: 'Vessel TK Trip',
      title: 'Vessel TK Trip',
      style: { flex: '1 0 50px' },
      renderExportValue: (column: BatchRecord) => column.vesselTkTrip,
      render: (column: BatchRecord) => column.vesselTkTrip,
      validate: [validationRequired, validationString],
      edit: { type: 'input' },
    },
    {
      key: 'shipToNumber',
      label: 'Ship To Number',
      title: 'Ship To Number',
      defaultSelectedBatchContractColumn: true,
      style: { flex: '1 0 80px' },
      render: (column: BatchRecord) => column.shipToNumber,
      renderExportValue: (column: BatchRecord) => column.shipToNumber,
    },
    {
      key: 'kpiBrix',
      label: 'KPI Brix',
      title: 'KPI Brix',
      defaultSelectedBatchContractColumn: true,
      style: { flex: '1 0 30px' },
      renderExportValue: (column: BatchRecord) => column.kpiBrix,
      render: (column: BatchRecord) => column.kpiBrix,
    },
    {
      key: 'kpiRatio',
      label: 'KPI Ratio',
      title: 'KPI Ratio',
      defaultSelectedBatchContractColumn: true,
      style: { flex: '1 0 30px' },
      renderExportValue: (column: BatchRecord) => column.kpiRatio,
      render: (column: BatchRecord) => column.kpiRatio,
    },
    ...kgShippedInfo,
    {
      key: 'invoiceDate',
      label: 'Invoice Date',
      title: 'Invoice Date',
      defaultSelectedBatchContractColumn: true,
      style: { flex: '1 0 80px' },
      renderExportValue: (column: BatchRecord) => renderShortDateFormat(column.invoiceDate),
      render: (column: BatchRecord) => renderShortDateFormat(column.invoiceDate),
    },
    {
      key: 'receiptDate',
      label: 'Receiept Date',
      title: 'Receiept Date',
      defaultSelectedBatchContractColumn: true,
      style: { flex: '1 0 80px' },
      renderExportValue: (column: BatchRecord) => renderShortDateFormat(column.receiptDate),
      render: (column: BatchRecord) => renderShortDateFormat(column.receiptDate),
    },
    {
      key: 'ghentBatchId',
      label: 'Batch ID in Ghent',
      title: 'Batch ID in Ghent',
      defaultSelectedBatchContractColumn: true,
      style: { flex: '1 0 80px' },
      renderExportValue: (column: BatchRecord) => column.ghentBatchId,
      render: (column: BatchRecord) => column.ghentBatchId,
    },
    {
      key: 'ghentToVesselRatio',
      label: '% Batch ID in Ghent / Vessel',
      title: '% Batch ID in Ghent / Vessel',
      defaultSelectedBatchContractColumn: true,
      style: { flex: '1 0 80px' },
      renderExportValue: (column: BatchRecord) => column.ghentToVesselRatio,
      render: (column: BatchRecord) => column.ghentToVesselRatio,
    },
    {
      key: 'truckBatchId',
      label: 'Batch ID Truck in Ghent',
      title: 'Batch ID Truck in Ghent',
      defaultSelectedBatchContractColumn: true,
      style: { flex: '1 0 80px' },
      renderExportValue: (column: BatchRecord) => column.truckBatchId,
      render: (column: BatchRecord) => column.truckBatchId,
    },
    {
      key: 'productCode',
      label: 'Product Code',
      title: 'Product Code',
      defaultSelectedBatchContractColumn: true,
      style: { flex: '1 0 80px' },
      renderExportValue: (column: BatchRecord) => column.productCode,
      render: (column: BatchRecord) => column.productCode,
    },
    {
      key: 'createdAt',
      label: 'Created At',
      title: 'Created At',
      style: { flex: '1 0 60px' },
      renderExportValue: (column: BatchRecord) => column.createdAt,
      render: (column: BatchRecord) => renderShortDateFormat(column.createdAt),
    },
    {
      key: 'createdBy',
      label: 'Created By',
      title: 'Created By',
      style: { flex: '1 0 60px' },
      renderExportValue: (column: BatchRecord) => extractCreatedBy(column),
      render: (column: BatchRecord) => extractCreatedBy(column),
    },
    {
      key: 'status',
      label: 'Status',
      title: 'Status',
      style: { flex: '1 0 50px' },
      renderExportValue: (column: BatchRecord) => column.status,
      render: (column: BatchRecord) => column.status,
    },
    {
      key: 'uploadSource',
      label: 'Upload Source',
      title: 'Source',
      style: { flex: '1 0 50px' },
      renderExportValue: (column: BatchRecord) => column.uploadSource,
      render: (column: BatchRecord) => column.uploadSource,
    },
  ];
};
