import React from 'react';
import { Link } from 'react-router-dom';
import { getUrl } from 'routes';
import { CHANNELS, ORGANIZATIONS } from 'themes/constants';
import { renderTwoShortDateFormat, renderShortDateFormat } from 'lib/renderHelpers';
import { extractCreatedBy } from 'models/utils/utils';
import { BatchRecord, ModelRequiredInfo } from 'lib/lib';

export default (modelRequiredInfo: ModelRequiredInfo) => {
  const kgShippedInfo =
    modelRequiredInfo.loggedInUserRole === ORGANIZATIONS.KAUFLANDDE
      ? []
      : [
          {
            key: 'kgShippedTotal',
            label: 'KG Shipped',
            title: 'KG Shipped',
            defaultSelectedColumn: true,
            style: { flex: '1 0 30px' },
            render: (column: BatchRecord) => column.kgShippedTotal,
            renderExportValue: (column: BatchRecord) => column.kgShippedTotal,
          },
        ];

  return [
    {
      key: 'salesOrderNumber',
      label: 'Sales Order Number',
      title: 'Sales Order Number',
      defaultSelectedColumn: true,
      style: { flex: '1 0 70px' },
      render: (column: BatchRecord) => (
        <Link
          to={{
            pathname: getUrl('BatchContractType', {
              type: ORGANIZATIONS.CITROSUCOGHENT.toLowerCase(),
              contract_number: encodeURIComponent(encodeURIComponent(column.salesOrderNumber)),
              modelType: 'contractDetails',
              channelName: CHANNELS.JUICE,
            }),
          }}
        >
          {column.salesOrderNumber}
        </Link>
      ),
      renderExportValue: (column: BatchRecord) => column.salesOrderNumber,
    },
    {
      key: 'kpiRatioAvg',
      label: 'KPI Ratio',
      title: 'KPI Ratio',
      defaultSelectedColumn: true,
      style: { flex: '1 0 30px' },
      render: (column: BatchRecord) => column.kpiRatioAvg,
      renderExportValue: (column: BatchRecord) => column.kpiRatioAvg,
    },
    ...kgShippedInfo,
    {
      key: 'invoiceDate',
      label: 'Invoice Date',
      title: 'Invoice Date',
      defaultSelectedColumn: true,
      style: { flex: '1 0 100px' },
      renderExportValue: (column: BatchRecord) =>
        renderTwoShortDateFormat(column.invoiceDateMin, column.invoiceDateMax),
      render: (column: BatchRecord) =>
        renderTwoShortDateFormat(column.invoiceDateMin, column.invoiceDateMax),
    },
    {
      key: 'receiptDate',
      label: 'Receipt Date',
      title: 'Receipt Date',
      defaultSelectedColumn: true,
      style: { flex: '1 0 100px' },
      renderExportValue: (column: BatchRecord) =>
        renderTwoShortDateFormat(column.receiptDateMin, column.receiptDateMax),
      render: (column: BatchRecord) =>
        renderTwoShortDateFormat(column.receiptDateMin, column.receiptDateMax),
    },
    {
      key: 'vesselTkBatchId',
      label: 'Subcontract amount',
      title: 'Subcontract amount',
      defaultSelectedColumn: true,
      style: { flex: '1 0 30px' },
      renderExportValue: (column: BatchRecord) => column.vesselTkBatchId,
      render: (column: BatchRecord) => column.vesselTkBatchId,
    },
    {
      key: 'createdAtMin',
      label: 'Created At',
      title: 'Created At',
      style: { flex: '1 0 60px' },
      // isSortable: true,
      renderExportValue: (column: BatchRecord) => column.createdAtMin,
      render: (column: BatchRecord) => renderShortDateFormat(column.createdAtMin),
    },
    {
      key: 'createdAtMax',
      label: 'Last Updated',
      title: 'Last Updated',
      style: { flex: '1 0 60px' },
      // isSortable: true,
      renderExportValue: (column: BatchRecord) => column.createdAtMax,
      render: (column: BatchRecord) => renderShortDateFormat(column.createdAtMax),
    },
    {
      key: 'createdBy',
      label: 'Created By',
      title: 'Created By',
      style: { flex: '1 0 60px' },
      renderExportValue: (column: BatchRecord) => extractCreatedBy(column),
      render: (column: BatchRecord) => extractCreatedBy(column),
    },
  ];
};
