export default () => ({
  $class: 'org.powerchain.egg.batch.EGGBatch',
  uploadId: '1567436051535',
  farmEggNumber: '2-NL-1234567',
  farmName: 'FarmaNova',
  thtDateFrom: '2019-02-01T00:00:00.000Z',
  thtDateTo: '2019-02-06T00:00:00.000Z',
  packerGln: '9999999999990',
  packerName: 'Gebr. Van Beek',
  ifsPacker: true,
  brcPacker: false,
  ikbPacker: true,
  katPacker: true,
  blkLevelPacker: 3,
  stableDescription: 'Scharrel',
  ikbFarm: true,
  katFarm: false,
  blkLevelFarm: 3,
  enrichmentPresent: 'Any text',
  beakUntreated: 'Any text',
  distractionAvailable: 'Any text',
  preventiefAntibioticsUsed: 'Yes',
  visitorsWelcome: 'No',
  nightTimeStarts: '24',
  dayTimeStarts: '8',
  birthDateChicken: '2018-12-12T00:00:00.000Z',
  breedChicken: 'Novogen',
  feedConstitution: 'phase 1',
  waterIntake: 180,
  feedIntake: 120,
  feedSupplierName: 'Any name',
  feedRtrsCertificate: 'No',
  feedRtrsProvedBy: 'No',
  feedSoyReduction: 'No',
  feedSoyReductionProvedBy: 'Any text',
  greenEnergy: true,
  batchId: '98ca97d0-489b-4b82-8f5f-04c353241a4d',
  createdAt: '2019-09-02T14:54:11.000Z',
  createdBy: 'resource:org.powerchain.egg.member.EGGUser#EGG1_6bc380d4-5b16-4b87-8529-5b1f76034e64',
  status: 'PENDING',
  uploadSource: 'frontEndApp',
  modifiedBy_participant: {
    username: null,
    email: null,
    participant:
      'org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8',
    org: {
      name: 'Fyffes',
      fullname: 'Fyffes',
      logo: null,
    },
    channel: {
      title: 'Bananas',
      name: 'bananas',
    },
    user: {
      username: 'Fyffes TST staging',
      email: 'FyffesTST@pc.t2f.io',
      org: {
        name: 'Fyffes',
        fullname: 'Fyffes',
        logo: null,
      },
    },
  },
  createdBy_participant: {
    username: null,
    email: null,
    participant:
      'org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8',
    org: {
      name: 'Fyffes',
      fullname: 'Fyffes',
      logo: null,
    },
    channel: {
      title: 'Bananas',
      name: 'bananas',
    },
    user: {
      username: 'Fyffes TST staging',
      email: 'FyffesTST@pc.t2f.io',
      org: {
        name: 'Fyffes',
        fullname: 'Fyffes',
        logo: null,
      },
    },
  },
});
