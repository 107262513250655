export const validateEnumYesNo = (value) => ['yes', 'no'].includes(String(value).toLowerCase());

export const validateIsNumber = (value) => !!Number(value);

export const validateRegexp = (value, regexp) => new RegExp(regexp).test(value);

export const validationRequired = (v) => (v ? undefined : 'required');

export const validationPasswordLength8 = (password) => {
  const isValid = String(password).length > 7;
  return isValid ? undefined : 'at least 8 chars';
};

export const validationPasswordSpecialChars = (chars = '') => {
  const specialCharsRegex = /[!@#$%^&*(),.?":{}|<>]/;
  const isValid = specialCharsRegex.test(chars);
  return isValid ? undefined : 'at least 1 special char';
};

export const validationPasswordChars = (chars = '') => {
  if (!/[0-9]/.test(chars)) {
    return 'at least 1 number';
  }
  if (!/[A-Z]/.test(chars)) {
    return 'at least 1  capital char';
  }
  if (!/[a-z]/.test(chars)) {
    return 'at least 1 lowercase char';
  }
  return undefined;
};

export const validationEmail = (email) => {
  const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const isValid = emailRegex.test(String(email).toLowerCase());
  return isValid ? undefined : 'e-mail format error';
};

export const validationPasswordConfirmation = (confirmation, allValues) =>
  confirmation === allValues.password ? undefined : 'passwords do not match';
