import defaultTab from 'models/ah/oranges/tabs/defaultTabModel';
import settingsAndActions from 'models/ah/oranges/settingsAndActions/default';
import { ORGANIZATIONS } from 'themes/constants';
import { ModelRequiredInfo } from 'lib/lib';

export default (modelRequiredInfo: ModelRequiredInfo) => ({
  tabs: [
    {
      modelName: 'defaultTab',
      originalName: ORGANIZATIONS.AH,
      title: ORGANIZATIONS.AH,
    },
  ],
  defaultTab: defaultTab(modelRequiredInfo),
  settingsAndActions,
});
