import logoRefresco from 'components/logos/Refresco.png';

import { BatchListSvg, CertificateSvg, DashboardSvg, NotificationListSvg } from 'components/icons';
import { theme } from 'themes/styles';

export default {
  logo: logoRefresco,
  colors: {
    primary: '#f5c58e',
    primaryHover: '#f99627',
    mainMenuLine: '#f99627',
    mainMenuIconColor: '#f99627',
    mainMenuTextColor: 'rgb(0, 0, 0)',
    black: 'rgb(61, 61, 61)',
    backgroundHead: 'rgb(255, 255, 255)',
    datePickerBgColor: '#f99627',
    accent: '#00b25a',
    modelSettingsCheckedColor: theme.colors.green,
  },
  mainMenuItems: [
    {
      routeName: 'Dashboard',
      icon: DashboardSvg,
      disabled: true,
    },
    {
      routeName: 'Notifications',
      icon: NotificationListSvg,
      disabled: true,
    },
    {
      routeName: 'Batches',
      icon: BatchListSvg,
    },
    {
      routeName: 'Documents',
      icon: CertificateSvg,
    },
  ],
  timeFormats: ['MM/DD/YYYY hh:mm:ss', 'MM-DD-YY', 'YYYY-DD-MM', 'MM/DD/YYYY'],
};
