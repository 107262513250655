import React, { Dispatch, SetStateAction, useMemo } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
// styles
import injectStyles, { WithStyles } from 'react-jss';
import stylesSelect from 'pages/dashboard/components/Select/styles/stylesSelect';
// components
import SelectComponent from 'react-select';
// redux
import { getOwnOrgName } from 'domain/env';
// types
import { AppStateType } from 'types';
import { Option } from 'pages/dashboard/components/Header/Header';
//
import { getSelectValues } from 'pages/dashboard/components/Select/config';

interface OwnProps extends WithStyles<typeof stylesSelect> {
  selectedValue: Option;
  setSelectedValue: Dispatch<SetStateAction<Option>>;
}

const mapStateToProps = (state: AppStateType) => ({
  ownOrgName: getOwnOrgName(state),
});

const Select: React.FC<OwnProps> = (props) => {
  const { classes, selectedValue, setSelectedValue } = props;
  const { ownOrgName } = useSelector(mapStateToProps, shallowEqual);

  const options = useMemo(() => getSelectValues(ownOrgName), [ownOrgName]);

  return (
    <div className={classes.select}>
      {options.length > 1 && (
        <SelectComponent
          options={options}
          value={selectedValue}
          // @ts-ignore
          onChange={(value) => value && setSelectedValue(value)}
        />
      )}
    </div>
  );
};

export default injectStyles(stylesSelect)(Select);
