import React, { useCallback, useMemo } from 'react';
import { reduxForm, Field, InjectedFormProps, FormSubmitHandler, Form } from 'redux-form';
//
import { compose } from 'redux';
// components
import Select from 'components/Form/Select';
import Textarea from 'components/Form/Textarea/textarea';
// jss
import injectSheet, { WithStyles } from 'react-jss';
import Button from 'components/Form/Button';
// themes
import { BATCH_STATUS } from 'themes/constants';
import { Theme } from 'lib/lib';

const approveFromStyles = (theme: Theme) => ({
  handleSubmitWrapper: {
    display: 'block',
    maxWidth: 300,
    margin: [0, 'auto'],
  },
  btn: {
    display: 'block',
    position: 'relative',
    width: 'auto',
    padding: [8, 25],
    margin: [40, 'auto', 0],
    border: 0,
    borderRadius: 10,
    font: {
      weight: 600,
      size: theme.fontSizes.sm,
    },
    outline: 0,
    backgroundColor: theme.colors.primary,
    color: theme.colors.white,
    transition: 'background-color 0.5s ease',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.colors.primaryHover,
      textDecoration: 'none',
    },
  },
});

interface SubmitFormValues {
  approveMsg?: string;
  status: BATCH_STATUS;
  approveReason: string;
}

interface OwnProps extends Pick<InjectedFormProps<SubmitFormValues>, 'initialValues'> {
  callback: (payload: SubmitFormValues) => void;
}

// TODO: remove component and reuse approveBatchForm.tsx (just change validator)
const ApproveCertFrom: React.FC<
  WithStyles<typeof approveFromStyles> & InjectedFormProps<SubmitFormValues> & OwnProps
> = (props) => {
  const { handleSubmit, classes, callback } = props;

  const approveOptions = useMemo(
    () => [
      {
        value: BATCH_STATUS.APPROVED,
        title: 'Verification Accepted by SIM',
      },
      {
        value: BATCH_STATUS.REJECTED,
        title: 'Verification Rejected by SIM',
      },
    ],
    [],
  );

  const submit: FormSubmitHandler<SubmitFormValues> = (values) => {
    const option = approveOptions.find((f) => f.value === values.approveReason);
    if (!option) {
      return;
    }
    const status = option.value;
    const approveReason = option.title;
    const payload = { approveReason, status, approveMsg: values.approveMsg || ' ' };

    callback(payload);
  };

  const validation = useCallback((v) => (v ? undefined : 'Required'), []);

  return (
    <div className={classes.handleSubmitWrapper}>
      <Form onSubmit={handleSubmit(submit)}>
        <div className="handleSubmitInner">
          <Field
            name="approveReason"
            type="text"
            component={Select}
            validate={validation}
            props={{
              options: approveOptions.length ? approveOptions : [],
              keys: { value: 'value', title: 'title' },
              title: 'Approve Reason',
            }}
          />

          <Field
            title=""
            name="approveMsg"
            component={Textarea}
            props={{
              title: 'Approve Message',
            }}
          />

          <Button type="submit" className={classes.btn}>
            Submit
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default compose<React.FC<OwnProps>>(
  reduxForm({
    form: 'document-approve-from',
  }),
  React.memo,
  injectSheet(approveFromStyles),
)(ApproveCertFrom);
