import React from 'react';
import { Link } from 'react-router-dom';
import { getUrl } from 'routes';
import { renderShortDateFormat, renderTwoShortDateFormat } from 'lib/renderHelpers';
import { BATCH_STATUS, ORGANIZATIONS } from 'themes/constants';
import { getOrgNameForURL } from 'themes/main';
import { BatchRecord, ModelRequiredInfo } from 'lib/lib';
import { getCreatorName } from 'models/utils/utils';

export default ({ params }: ModelRequiredInfo) => [
  {
    key: 'batchCodeId',
    label: 'Batch Code',
    title: 'Batch Code',
    style: { flex: '1 0 100px' },
    defaultSelectedColumn: true,
    defaultSelectedBatchContractColumn: true,
    renderExportValue: (column: BatchRecord) => column.batchCodeId,
    render: (column: BatchRecord) =>
      params && params.status === BATCH_STATUS.ARCHIVED ? (
        <Link
          to={{
            pathname: getUrl('BatchDetailsTabsParams', {
              type: getOrgNameForURL(ORGANIZATIONS.REGALSPRINGS),
              batchCode: encodeURIComponent(encodeURIComponent(column.batchCodeId)),
              status: params.status,
              modelType: 'detailsTabs',
            }),
          }}
          title={column.batchCodeId}
          className="contract"
        >
          {column.batchCodeId}
        </Link>
      ) : (
        <Link
          to={{
            pathname: getUrl('BatchDetailsTabs', {
              type: getOrgNameForURL(ORGANIZATIONS.REGALSPRINGS),
              batchCode: encodeURIComponent(encodeURIComponent(column.batchCodeId)),
              modelType: 'detailsTabs',
            }),
          }}
          title={column.batchCodeId}
          className="contract"
        >
          {column.batchCodeId}
        </Link>
      ),
  },
  {
    key: 'productsQuantity',
    label: 'Products Quantity',
    title: 'Products Quantity',
    style: { flex: '1 0 100px' },
    defaultSelectedColumn: true,
    defaultSelectedBatchContractColumn: true,
    renderExportValue: (column: BatchRecord) => column.productsQuantity,
    render: (column: BatchRecord) => column.productsQuantity,
  },
  {
    key: 'shippingDate',
    label: 'Shipping Date',
    title: 'Shipping Date',
    style: { flex: '1 0 100px' },
    defaultSelectedColumn: true,
    defaultSelectedBatchContractColumn: true,
    renderExportValue: (column: BatchRecord) =>
      `${renderTwoShortDateFormat(column.shippingDateMin, column.shippingDateMax)}`,
    render: (column: BatchRecord) =>
      `${renderTwoShortDateFormat(column.shippingDateMin, column.shippingDateMax)}`,
  },
  {
    key: 'storageDate',
    label: 'Storage Date',
    title: 'Storage Date',
    style: { flex: '1 0 100px' },
    defaultSelectedColumn: true,
    defaultSelectedBatchContractColumn: true,
    renderExportValue: (column: BatchRecord) =>
      `${renderTwoShortDateFormat(column.storageDateMin, column.storageDateMax)}`,
    render: (column: BatchRecord) =>
      `${renderTwoShortDateFormat(column.storageDateMin, column.storageDateMax)}`,
  },
  {
    key: 'packingDate',
    label: 'Packing Date',
    title: 'Packing Date',
    style: { flex: '1 0 100px' },
    defaultSelectedColumn: true,
    defaultSelectedBatchContractColumn: true,
    renderExportValue: (column: BatchRecord) =>
      `${renderTwoShortDateFormat(column.packingDateMin, column.packingDateMax)}`,
    render: (column: BatchRecord) =>
      `${renderTwoShortDateFormat(column.packingDateMin, column.packingDateMax)}`,
  },
  {
    key: 'createdAt',
    label: 'Created At',
    title: 'Created At',
    style: { flex: '1 0 60px' },
    renderExportValue: (column: BatchRecord) => `${renderShortDateFormat(column.createdAt)}`,
    defaultSelectedColumn: true,
    render: (column: BatchRecord) => `${renderShortDateFormat(column.createdAt)}`,
  },
  {
    key: 'createdBy',
    label: 'Created By',
    title: 'Created By',
    style: { flex: '1 0 50px' },
    defaultSelectedColumn: true,
    renderExportValue: (record: BatchRecord) => getCreatorName(record),
    render: (record: BatchRecord) => getCreatorName(record),
  },
];
