import React, { Suspense, lazy } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { getChannel, getChannelName, getOwnOrgName, getUser } from 'domain/env';
// components
import Loader from 'components/Loaders/smallLoader';
import UploadCSVFile from 'pages/batches/components/UploadCSVFile';
// settings
import { CHANNELS, ORGANIZATIONS } from 'themes/constants';
import { AppStateType } from 'types';
import { getBatchInfoByUrlOrgRole, getHierarchyByOrgName, getModel } from 'domain/common';

const mapStateToProps = (state: AppStateType) => ({
  channelName: getChannelName(state),
  orgName: getOwnOrgName(state),
  user: getUser(state),
  channel: getChannel(state),
  batchInfo: getBatchInfoByUrlOrgRole(state),
  hierarchyByOrgName: getHierarchyByOrgName(state),
  model: getModel(state),
});

const BatchAdd = () => {
  const reduxProps = useSelector(mapStateToProps, shallowEqual);
  const { channelName, orgName } = reduxProps;

  const UploadExcelFile = lazy(() => import('pages/batches/components/UploadExcelFile'));

  const Excel = (
    <Suspense fallback={<Loader theme="black" styles={{ margin: 'auto' }} />}>
      <UploadExcelFile {...reduxProps} />
    </Suspense>
  );

  const CSV = <UploadCSVFile channelName={channelName} />;

  if ([ORGANIZATIONS.REFRESCO, ORGANIZATIONS.AH].includes(orgName)) {
    return CSV;
  }

  switch (channelName) {
    case CHANNELS.BANANAS:
    case CHANNELS.JUICE:
    case CHANNELS.TILAPIA: {
      return Excel;
    }

    default: {
      return CSV;
    }
  }
};

export default BatchAdd;
