import { fork, takeEvery, takeLatest } from 'redux-saga/effects';

// Env
import {
  fetchTableColumns,
  setTableColumns,
  fetchNotificationConfig,
  updateNotificationConfig,
  setTableModel,
  toggleModelField,
  setDefaultModelSettings,
} from 'domain/env';
import * as envActions from 'domain/env/actions';

// profile
import { makeSendEmail, makeResetPassword, makeChangePassword } from 'domain/profile';
import * as profileActions from 'domain/profile/actions';

// Documents
import {
  fetchDocuments,
  fetchDocument,
  createDocuments,
  deleteDocument,
  approveDocument,
  displayCertificate,
  uploadCertFile,
} from 'domain/documents';
import * as documentActions from 'domain/documents/actions';

// Unmatched Batches
import {
  fetchUnmatchedBatchContracts,
  fetchUnmatched,
  fetchUnmatchedStatistics,
} from 'domain/unmatched';
import * as unmatchedActions from 'domain/unmatched/actions';

// Notifications
import { fetchNotifications, fetchNotification, updateNotification } from 'domain/notifications';
import * as notificationActions from 'domain/notifications/actions';

// Batches
import {
  fetchBatches,
  fetchBatchContract,
  fetchBatchValidation,
  approveBatchValidation,
  fetchBatchHandshake,
  deleteBatch,
  fetchBatch,
  uploadBatchesCSV,
  uploadBatchesExcel,
  deleteBatchContract,
  approveBatchContract,
  archiveBatchContract,
  fetchBatchWithUser,
  deleteMultipleBatches,
  fetchQueueStatus,
  splitUploadBatchesCSV,
} from 'domain/batches';
import * as batchesActions from 'domain/batches/actions';

// Requests
import { fetchRequests, fetchRequest, createRequest, updateInboxRequest } from 'domain/requests';
import * as requestsActions from 'domain/requests/actions';

const batchesSagaMapping = {
  [batchesActions.fetchBatchesAction.type]: fetchBatches,
  [batchesActions.fetchBatchContractAction.type]: fetchBatchContract,
  [batchesActions.fetchBatchHandshakeAction.type]: fetchBatchHandshake,
  [batchesActions.fetchBatchAction.type]: fetchBatch,
  [batchesActions.fetchBatchValidationAction.type]: fetchBatchValidation,
};

function* watchBatchesActions(action) {
  yield* batchesSagaMapping[action.type](action);
}

export function* components() {
  // env
  yield takeEvery(envActions.setTableColumnsAction.type, setTableColumns);
  yield takeEvery(envActions.setDefaultModelSettingsAction.type, setDefaultModelSettings);
  yield takeEvery(envActions.toggleModelFieldAction.type, toggleModelField);
  yield takeEvery(envActions.setModelSettingsAction.type, setTableModel);
  yield takeEvery(envActions.fetchTableColumnsAction.type, fetchTableColumns);
  yield takeEvery(envActions.fetchNotificationConfigAction.type, fetchNotificationConfig);
  yield takeEvery(envActions.updateNotificationConfigAction.type, updateNotificationConfig);
  // profile
  yield takeEvery(profileActions.sendEmailForRecoverPasswordAction.type, makeSendEmail);
  yield takeEvery(profileActions.sendResetPasswordAction.type, makeResetPassword);
  yield takeEvery(profileActions.sendChangePasswordAction.type, makeChangePassword);
  // unmatched batches
  yield takeEvery(
    unmatchedActions.fetchUnmatchedBatchContractsAction.type,
    fetchUnmatchedBatchContracts,
  );
  yield takeEvery(unmatchedActions.fetchUnmatchedBatchesListAction.type, fetchUnmatched);
  yield takeEvery(unmatchedActions.fetchUnmatchedStatisticsAction.type, fetchUnmatchedStatistics);
  // document / certs create
  yield takeEvery(documentActions.fetchDocumentsAction.type, fetchDocuments);
  yield takeEvery(documentActions.fetchDocumentAction.type, fetchDocument);
  yield takeEvery(documentActions.createDocumentAction.type, createDocuments);
  yield takeEvery(documentActions.deleteDocumentAction.type, deleteDocument);
  yield takeEvery(documentActions.approveDocumentAction.type, approveDocument);

  yield takeEvery(documentActions.getCertFileAction.type, displayCertificate);
  yield takeEvery(documentActions.uploadCertFileAction.type, uploadCertFile);

  // notifications
  yield takeEvery(notificationActions.fetchNotificationsAction.type, fetchNotifications);
  yield takeEvery(notificationActions.fetchNotificationAction.type, fetchNotification);
  yield takeEvery(notificationActions.updateNotificationAction.type, updateNotification);

  // batches
  yield takeLatest([...Object.keys(batchesSagaMapping)], watchBatchesActions);
  yield takeEvery(batchesActions.fetchBatchWithUserAction.type, fetchBatchWithUser);
  yield takeEvery(batchesActions.deleteBatchAction.type, deleteBatch);
  yield takeEvery(batchesActions.archiveBatchContractAction.type, archiveBatchContract);
  yield takeEvery(batchesActions.uploadBatchesCSVAction.type, uploadBatchesCSV);
  yield takeEvery(batchesActions.splitUploadBatchesCSVAction.type, splitUploadBatchesCSV);
  yield takeEvery(batchesActions.uploadBatchesExcelAction.type, uploadBatchesExcel);
  yield takeEvery(batchesActions.approveBatchContractAction.type, approveBatchContract);
  yield takeEvery(batchesActions.deleteMultipleBatchesAction.type, deleteMultipleBatches);
  yield takeEvery(batchesActions.fetchQueueStatusAction.type, fetchQueueStatus);
  yield takeEvery(batchesActions.approveBatchValidationAction.type, approveBatchValidation);
  // requests
  yield takeEvery(requestsActions.fetchRequestsAction.type, fetchRequests);
  yield takeEvery(requestsActions.fetchRequestAction.type, fetchRequest);
  yield takeEvery(requestsActions.createRequestAction.type, createRequest);
  yield takeEvery(requestsActions.updateInboxRequestAction.type, updateInboxRequest);
}

export default function*() {
  yield fork(components);
}
