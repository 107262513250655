import React from 'react';
import { getTheme } from 'themes/main';
import { formatBatchDate } from 'lib/helpers';
import { renderShortDateFormat } from 'lib/renderHelpers';
import { BatchRecord, ModelRequiredInfo } from 'lib/lib';

// label defines what field to take from CSV batch file for upload

export default (modelRequiredInfo: ModelRequiredInfo) => [
  {
    key: 'releaseNumber',
    label: 'Release Number',
    title: 'Release Number',
    style: { flex: '1 0 100px' },
    defaultPreviewBeforeUploadColumn: true,
  },
  {
    key: 'kgShipped',
    label: 'KG shipped',
    title: 'KG shipped',
    defaultPreviewBeforeUploadColumn: true,
    style: { flex: '1 0 70px' },
  },
  {
    key: 'shipToNumber',
    label: 'Ship To Number',
    title: 'Ship To Number',
    defaultPreviewBeforeUploadColumn: true,
    style: { flex: '1 0 100px' },
  },
  {
    key: 'shipTo',
    label: 'Ship To',
    title: 'Ship To',
    style: { flex: '1 0 50px' },
  },
  {
    key: 'invoiceDate',
    label: 'Invoice Date',
    title: 'Invoice Date',
    defaultPreviewBeforeUploadColumn: true,
    style: { flex: '1 0 70px' },
    edit: { type: 'date' },
    handleParsedValueFromCSVFile: (value: string) =>
      formatBatchDate(
        value,
        getTheme(modelRequiredInfo.role, modelRequiredInfo.channelName).timezone,
        getTheme(modelRequiredInfo.role, modelRequiredInfo.channelName).timeFormats,
      ),
    renderPreviewBeforeUpload: (column: BatchRecord) => renderShortDateFormat(column.invoiceDate),
  },
  {
    key: 'contractNumber',
    label: 'Contract Number',
    title: 'Contract Number',
    style: { flex: '1 0 50px' },
  },
  {
    key: 'customerReference',
    label: 'Customer Reference',
    title: 'Customer Reference',
    style: { flex: '1 0 50px' },
  },
  {
    key: 'secondItemNumber',
    label: '2nd Item Number',
    title: '2nd Item Number',
    style: { flex: '1 0 50px' },
  },
  {
    key: 'kpiRatio',
    label: 'KPI ratio',
    title: 'KPI ratio',
    defaultPreviewBeforeUploadColumn: true,
    style: { flex: '1 0 50px' },
  },
  {
    key: 'lotSerialNumber',
    label: 'Lot Serial Number',
    title: 'Lot Serial Number',
    style: { flex: '1 0 50px' },
    defaultPreviewBeforeUploadColumn: true,
  },
  {
    key: 'batchesLevelOne',
    label: 'Batches Level1',
    title: 'Batches Level1',
    style: { flex: '1 0 50px' },
  },
  {
    key: 'blDate',
    label: 'B/L Date',
    title: 'B/L Date',
    style: { flex: '1 0 50px' },
    edit: { type: 'date' },
    handleParsedValueFromCSVFile: (value: string) =>
      formatBatchDate(
        value,
        getTheme(modelRequiredInfo.role, modelRequiredInfo.channelName).timezone,
        getTheme(modelRequiredInfo.role, modelRequiredInfo.channelName).timeFormats,
      ),
    renderPreviewBeforeUpload: (column: BatchRecord) => renderShortDateFormat(column.blDate),
  },
  {
    key: 'receiptDate',
    label: 'Receipt Date',
    title: 'Receipt Date',
    style: { flex: '1 0 50px' },
    edit: { type: 'date' },
    handleParsedValueFromCSVFile: (value: string) =>
      formatBatchDate(
        value,
        getTheme(modelRequiredInfo.role, modelRequiredInfo.channelName).timezone,
        getTheme(modelRequiredInfo.role, modelRequiredInfo.channelName).timeFormats,
      ),
    renderPreviewBeforeUpload: (column: BatchRecord) => renderShortDateFormat(column.receiptDate),
  },
  {
    key: 'batchId',
    label: 'Batch Id',
    title: 'Batch Details',
    style: { flex: '0 0 150px' },
  },
];
