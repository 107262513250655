import React from 'react';
import { getTheme } from 'themes/main';
import { formatBatchDate, getNameFromBlockchainString } from 'lib/helpers';
import { renderShortDateFormat } from 'lib/renderHelpers';
import { BatchRecord, ModelRequiredInfo } from 'lib/lib';
import { getCreatorName } from 'models/utils/utils';

export default ({ role, channelName }: ModelRequiredInfo) => [
  /* {
    key: 'participantName',
    label: 'Participant Name',
    title: 'Participant Name',
    style: { flex: '1 0 50px' },
    render: column => column.participantName,
  }, */
  /* {
    key: 'orgName',
    label: 'Org Name',
    title: 'Org Name',
    style: { flex: '1 0 50px' },
    render: column => column.orgName,
  }, */
  {
    key: 'createdAtMin',
    label: 'Created At',
    title: 'Created At',
    style: { flex: '1 0 80px' },
    isSortable: true,
    defaultSelectedColumn: true,
    renderExportValue: (column: BatchRecord) => column.createdAtMin,
    render: (column: BatchRecord) => renderShortDateFormat(column.createdAtMin),
  },
  {
    key: 'createdAtMax',
    label: 'Last Updated',
    title: 'Last Updated',
    style: { flex: '1 0 80px' },
    isSortable: true,
    defaultSelectedColumn: true,
    renderExportValue: (column: BatchRecord) => column.createdAtMax,
    render: (column: BatchRecord) => renderShortDateFormat(column.createdAtMax),
  },
  {
    key: 'createdBy',
    label: 'Created By',
    title: 'Uploaded by',
    style: { flex: '1 0 50px' },
    render: (batchRecord: BatchRecord) => getCreatorName(batchRecord),
  },
  // {
  //   key: 'modifiedAt',
  //   label: 'Archived At',
  //   title: 'Archived At',
  //   style: { flex: '1 0 50px' },
  //   additionalColumn: true,
  //   render: (column) => <span>{renderShortDateFormat(column.modifiedAt)}</span>,
  // },
  // {
  //   key: 'modifiedBy',
  //   label: 'Archived By',
  //   title: 'Archived By',
  //   style: { flex: '1 0 70px' },
  //   additionalColumn: true,
  //   render: (column) => getNameFromBlockchainString(column.modifiedBy),
  // },
  {
    key: 'approvedAt',
    label: 'Approved At',
    title: 'Approved At',
    edit: { type: 'date' },
    style: { flex: '1 0 50px' },
    handleParsedValueFromCSVFile: (value: string) =>
      formatBatchDate(
        value,
        getTheme(role, channelName).timezone,
        getTheme(role, channelName).timeFormats,
      ),
    renderPreviewBeforeUpload: (column: BatchRecord) => renderShortDateFormat(column.approvedAt),
    render: (column: BatchRecord) => renderShortDateFormat(column.approvedAt),
  },
  {
    key: 'approvedBy',
    label: 'Approved By',
    title: 'Approved By',
    style: { flex: '1 0 70px' },
    render: (column: BatchRecord) => getNameFromBlockchainString(column.approvedBy),
  },
  {
    key: 'approveMsg',
    label: 'Approve Msg',
    title: 'Approve Msg',
    style: { flex: '1 0 50px' },
    render: (column: BatchRecord) => <span>{column.approveMsg}</span>,
  },
  {
    key: 'approveReason',
    label: 'Approve Reason',
    title: 'Approve Reason',
    style: { flex: '1 0 50px' },
    render: (column: BatchRecord) => column.approveReason,
  },
  {
    key: 'uploadSource',
    label: 'Upload Source',
    title: 'Source',
    defaultSelectedColumn: true,
    defaultSelectedBatchContractColumn: true,
    style: { flex: '1 0 50px' },
    render: (column: BatchRecord) => column.uploadSource,
  },
];
