
abstract class IterableGenerator<T> {
    protected currentIndex: number = 0;
    protected constructor(protected list : Array<T>,) {}

    abstract next(): T;

    isEmpty() : boolean {
        return this.list.length === 0;
    }

    nextExist() : boolean  {
        return !!this.list[this.currentIndex]
    }

    resetIndex() {
        this.currentIndex = 0;
    }

    getNextAndIncrement() {
        return this.list[this.currentIndex++]
    }
}

export class InfiniteGenerator<T> extends IterableGenerator<T>{
    constructor(protected list : Array<T>) {
        super(list);
    }

    next() : T {
        if(super.nextExist()) {
            return super.getNextAndIncrement();
        } else {
            super.resetIndex();
            return super.getNextAndIncrement();
        }
    }
}