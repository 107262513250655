import { Theme } from 'lib/lib';

export default (theme: Theme) => ({
  content: {
    marginTop: 20,
    flexGrow: 1,
    flexBasis: '50%',
  },
  loader: { ...theme.components.loader },
});
