enum CHANNELS {
  TILAPIA = 'tilapia',
  BANANAS = 'bananas',
  ORANGES = 'oranges',
  EGGS = 'eggs',
  JUICE = 'juice',
}

enum BATCH_STATUS {
  ALL = 'ALL',
  PENDING = 'PENDING',
  APPROVED = 'APPROVED',
  ARCHIVED = 'ARCHIVED',
  REJECTED = 'REJECTED',
}

enum ORGANIZATIONS {
  BANAFOOD = 'Banafood',
  HOLLANDER = 'Hollander',
  JUMBO = 'Jumbo',
  MAYONNA = 'Mayonna',
  REGALSPRINGS = 'RegalSprings',
  SEAFOODCONNECTION = 'SeafoodConnection',
  FYFFES = 'Fyffes',
  SIM = 'SIM',
  LDCBR = 'LDCBR',
  JDE = 'JDE',
  REFRESCO = 'REFRESCO',
  AH = 'AH',
  EGG1 = 'EGG1',
  EGG2 = 'EGG2',
  CITROSUCOBR = 'CitrosucoBrazil',
  CITROSUCOGHENT = 'CitrosucoGhent',
  REFRESCOGMBH = 'RefrescoGbmH',
  KAUFLANDDE = 'KauflandDE',
}

enum MODEL_TYPES {
  INDEX = 'index',
  CONTRACT_DETAILS = 'contractDetails',
  HANDSHAKE = 'handshake',
  UPLOAD = 'upload',
  DETAILS = 'details',
  CERTIFICATES_LIST = 'certificatesList',
  CERTIFICATE = 'certificate',
  NOTIFICATIONS = 'notifications',
  ADMIN_USERS = 'adminUsers',
  ADMIN_INVITATIONS = 'adminInvitations',
  DASHBOARD = 'dashboard',
  VALIDATION = 'validation',
}

export { CHANNELS, BATCH_STATUS, ORGANIZATIONS, MODEL_TYPES };
