import Sofa from 'assets/sofa.png';

export default (theme) => ({
  background: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100vw',
    height: '100vh',
    zIndex: 98,
    background: 'rgba(255, 255, 255, 0.8)',
  },
  modal: {
    position: 'fixed',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 99,
    padding: 60,
    fontFamily: 'Lato',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    color: theme.colors.blackError,
    width: '100vw',
    maxWidth: 480,
    maxHeight: 640,
    backgroundColor: theme.colors.white,
    borderRadius: 8,
    border: [1, 'solid', theme.colors.blackError],
    userSelect: 'none',
  },
  header: {
    width: 320,
    height: 320,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    background: {
      image: `url("${Sofa}")`,
      position: ['center', 'center'],
      size: 'cover',
    },
    padding: [50, 0, 0],
    '& h5': {
      textAlign: 'center',
      width: '100%',
      margin: 0,
      padding: [20, 0, 40],
      fontWeight: 900,
      fontSize: 36,
    },
  },
  content: {
    textAlign: 'center',
    padding: [30, 0],
    '& h3': {
      fontWeight: 900,
      fontSize: 32,
    },
    '& span': {
      width: '100%',
      fontWeight: 400,
      fontSize: 22,
    },
  },
  controls: {
    padding: [30, 0],
  },
  close: {
    padding: 13,
    borderRadius: 6,
    backgroundColor: theme.colors.gray,
    width: 200,
    outline: 'none',
    cursor: 'pointer',
    borderStyle: 'hidden',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& span': {
      padding: [0, 25],
      fontFamily: 'Lato',
      color: theme.colors.whiteError,
      fontSize: 17,
    },
  },
});
