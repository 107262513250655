// helpers
import { renderShortDateFormat } from 'lib/renderHelpers';
// config
import { validationRequired, validationInt, validationString } from 'lib/uploadFieldValidations';
import { BatchRecord } from 'lib/lib';

export default () => {
  return [
    {
      key: 'SSCCPallet',
      label: 'SSCC pallet',
      title: 'SSCC pallet',
      style: { flex: '1 0 157px' },
      renderUploadPreview: (column: BatchRecord) => column.SSCCPallet,
      render: (column: BatchRecord) => column.SSCCPallet,
      validate: [validationRequired, validationInt],
      defaultSelectedColumn: true,
      defaultSelectedBatchContractColumn: true,
    },
    {
      key: 'vessel',
      label: 'Vessel',
      title: 'Vessel',
      style: { flex: '1 0 157px' },
      renderUploadPreview: (column: BatchRecord) => column.vessel,
      render: (column: BatchRecord) => column.vessel,
      validate: [validationRequired, validationString],
      defaultSelectedColumn: true,
      defaultSelectedBatchContractColumn: true,
    },
    {
      key: 'packagingType',
      label: 'Packaging type',
      title: 'Packaging type',
      style: { flex: '1 0 157px' },
      renderUploadPreview: (column: BatchRecord) => column.packagingType,
      render: (column: BatchRecord) => column.packagingType,
      validate: [validationRequired, validationString],
      defaultSelectedColumn: true,
      defaultSelectedBatchContractColumn: true,
    },
    {
      key: 'producer',
      label: 'Producer',
      title: 'Producer',
      style: { flex: '1 0 157px' },
      renderUploadPreview: (column: BatchRecord) => column.producer,
      render: (column: BatchRecord) => column.producer,
      validate: [validationRequired, validationString],
      defaultSelectedColumn: true,
      defaultSelectedBatchContractColumn: true,
    },
    {
      key: 'receiptDate',
      label: 'Shipment receipt date',
      title: 'Shipment receipt date',
      style: { flex: '1 0 157px' },
      renderUploadPreview: (column: BatchRecord) => renderShortDateFormat(column.receiptDate),
      render: (column: BatchRecord) => renderShortDateFormat(column.receiptDate),
      validate: [validationRequired, validationString],
      defaultSelectedColumn: true,
      defaultSelectedBatchContractColumn: true,
    },
    {
      key: 'outboundTransportDate',
      label: 'Outbound transport date',
      title: 'Outbound transport date',
      style: { flex: '1 0 157px' },
      renderUploadPreview: (column: BatchRecord) =>
        renderShortDateFormat(column.outboundTransportDate),
      render: (column: BatchRecord) => renderShortDateFormat(column.outboundTransportDate),
      validate: [validationRequired, validationString],
      defaultSelectedColumn: true,
      defaultSelectedBatchContractColumn: true,
    },
    {
      key: 'numberOfLoadedBoxes',
      label: 'Number of boxes loaded',
      title: 'Number of boxes loaded',
      style: { flex: '1 0 157px' },
      renderUploadPreview: (column: BatchRecord) => column.numberOfLoadedBoxes,
      render: (column: BatchRecord) => column.numberOfLoadedBoxes,
      validate: [validationRequired, validationInt],
      defaultSelectedColumn: true,
      defaultSelectedBatchContractColumn: true,
    },
  ];
};
