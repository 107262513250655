export default () => [
  {
    id: 13794,
    is_read: false,
    created_at: '2019-09-02T13:04:13.673Z',
    event: {
      id: 2666,
      entityType: 'JDEBatch',
      entityId: 'SG44444444',
      statusChangedTo: null,
      eventDoneAt: '2019-09-02T13:04:11.000Z',
      actionType: 'CREATED',
      actionSource: 'frontEndApp',
      channel: { title: 'Oranges', name: 'oranges' },
      eventType: { id: 1, title: 'Batch upload', slug: 'BatchUpload' },
      eventDoneBy: {
        username: 'JDETST staging',
        email: 'JDETST@pc.t2f.io',
        org: { name: 'JDE', fullname: 'Jde company', logo: null },
      },
    },
  },
  {
    id: 1812,
    is_read: false,
    created_at: '2019-08-13T08:39:49.822Z',
    event: {
      id: 585,
      entityType: 'Demand',
      entityId: '16',
      statusChangedTo: null,
      eventDoneAt: '2019-08-13T08:39:49.000Z',
      actionType: 'CREATED',
      actionSource: null,
      channel: { title: 'Oranges', name: 'oranges' },
      eventType: { id: 7, title: 'Request created', slug: 'DemandCreate' },
      eventDoneBy: {
        username: 'REFTST staging',
        email: 'REFTST@pc.t2f.io',
        org: { name: 'REFRESCO', fullname: 'Refresco company', logo: null },
      },
    },
  },
  {
    id: 1099,
    is_read: false,
    created_at: '2019-06-14T09:51:28.092Z',
    event: {
      id: 90,
      entityType: 'CertFile',
      entityId: 'ad41c8ec-6c5d-4f86-b3de-35fd6e10b331',
      statusChangedTo: null,
      eventDoneAt: '2019-06-14T09:51:25.000Z',
      actionType: 'CREATED',
      actionSource: null,
      channel: { title: 'Oranges', name: 'oranges' },
      eventType: { id: 4, title: 'Certificate upload', slug: 'CertificateUpload' },
      eventDoneBy: {
        username: 'JDETST staging',
        email: 'JDETST@pc.t2f.io',
        org: { name: 'JDE', fullname: 'Jde company', logo: null },
      },
    },
  },
];
