import { store } from 'pages/AppProviders';
import { getChannel, getOwnOrgName, getRole, getToken, getUser, getUserOrg } from 'domain/env';

import { CHANNELS, ORGANIZATIONS } from 'themes/constants';

export enum Actions {
  ADMIN_USERS_LOOKUP = 'ADMIN_USERS_LOOKUP',
  CREATE_NEW_BATCH = 'CREATE_NEW_BATCH',
  BATCHES_DELETE = 'BATCHES_DELETE',
  APPROVE_BATCH = 'APPROVE_BATCH',
  VIEW_ARCHIVE_BATCH_TAB = 'VIEW_ARCHIVE_BATCH_TAB',
  CREATE_NEW_CERTIFICATE = 'CREATE_NEW_CERTIFICATE',
  APPROVE_CERTIFICATE = 'APPROVE_CERTIFICATE',
  DELETE_CERTIFICATE = 'DELETE_CERTIFICATE',
  ANSWER_CERTIFICATE = 'ANSWER_CERTIFICATE',
  CREATE_NEW_REQUEST = 'CREATE_NEW_REQUEST',
  NEW_REQUEST_ORG_FROM = 'NEW_REQUEST_ORG_FROM',
  INVITE_TO_SPECIFIED_ORG = 'INVITE_TO_SPECIFIED_ORG',
}

const { AH, SIM, EGG1, EGG2, LDCBR } = ORGANIZATIONS;

/**
 * AC
 */
class AccessControl {
  private get state() {
    return store.getState();
  }

  private get channel() {
    return getChannel(this.state);
  }

  private get user() {
    return getUser(this.state);
  }
  private get org() {
    return getUserOrg(this.state);
  }

  private get orgRole() {
    return getRole(this.state);
  }

  private get orgName() {
    return getOwnOrgName(this.state);
  }

  private get isChannelValid() {
    return this.channel.id && Object.values(CHANNELS).includes(this.channel.name);
  }

  private get isUserValid() {
    return typeof this.user.id === 'number' && typeof this.org.id === 'number';
  }

  //////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////

  get TOKEN() {
    return getToken(this.state);
  }

  //////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////
  private get ADMIN_USERS_LOOKUP() {
    return this.orgName === SIM;
  }
  private get CREATE_NEW_BATCH() {
    return ![SIM].includes(this.orgName);
  }
  private get BATCHES_DELETE() {
    return this.orgName === AH;
  }
  private get APPROVE_BATCH() {
    return false;
  }

  private get VIEW_ARCHIVE_BATCH_TAB() {
    // TODO lul
    return ![''].includes(this.orgName);
  }

  private get CREATE_NEW_CERTIFICATE() {
    return ![SIM, AH].includes(this.orgName);
  }

  private get APPROVE_CERTIFICATE() {
    return this.orgName === SIM;
  }

  private get DELETE_CERTIFICATE() {
    return ![SIM, AH].includes(this.orgName);
  }

  private get ANSWER_CERTIFICATE() {
    if ([CHANNELS.BANANAS, CHANNELS.TILAPIA].includes(this.channel.name)) {
      return false;
    }
    return [SIM].includes(this.orgName);
  }

  private get INVITE_TO_SPECIFIED_ORG() {
    return this.orgName === SIM;
  }

  private get NEW_REQUEST_ORG_FROM() {
    return this.orgName === SIM;
  }

  private get CREATE_NEW_REQUEST() {
    return ![LDCBR, EGG1, EGG2].includes(this.orgName);
  }
  //////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////
  ACTIONS = Actions;

  USER = {
    CAN: (() => {
      const permissions: { [K in Actions]: boolean } = {
        ADMIN_USERS_LOOKUP: false,
        CREATE_NEW_BATCH: false,
        BATCHES_DELETE: false,
        APPROVE_BATCH: false,
        VIEW_ARCHIVE_BATCH_TAB: false,
        CREATE_NEW_CERTIFICATE: false,
        APPROVE_CERTIFICATE: false,
        DELETE_CERTIFICATE: false,
        ANSWER_CERTIFICATE: false,
        INVITE_TO_SPECIFIED_ORG: false,
        NEW_REQUEST_ORG_FROM: false,
        CREATE_NEW_REQUEST: false,
      };

      const self = this;
      const res: typeof permissions = {};

      for (const key in permissions) {
        Object.defineProperty(res, key, {
          get(): boolean {
            return Boolean(self.isChannelValid && self.isUserValid && self[key]);
          },
        });
      }

      return res;
    })(),
  };
}

const AC = new AccessControl();
export default AC;
