export default (theme) => ({
  cellInput: {
    maxWidth: '100%',
    textAlign: 'center',
    padding: 5,
    fontSize: 20,
  },
  buttonsContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    flexGrow: 1,
    padding: [5, 0],

    '& > div': {
      ...theme.components.btn,
      display: 'inline-flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      cursor: 'pointer',
      borderRadius: 50,
      '& > svg': {
        fill: 'white',
        width: 36,
        height: 36,
        marginRight: '1em',
      },
      '& > span': {
        color: 'white',
        fontSize: theme.fontSizes.md,
      },
    },
    '& .accept': {
      backgroundColor: theme.colors.green,
      '&:hover': {
        backgroundColor: theme.colors.greenHover,
      },
    },
    '& .cancel': {
      backgroundColor: theme.colors.redD,
      '&:hover': {
        backgroundColor: theme.colors.redDHover,
      },
    },
  },
  datePickerInput: {
    fontSize: 20,
    marginBottom: '5px',
  },
});
