import dashboardTabModel from 'models/fyffes/tabs/dashboardTabModel';
import settingsAndActions from 'models/fyffes/settingsAndActions/dashboard';
import { ORGANIZATIONS } from 'themes/constants';

export default () => ({
  tabs: [
    {
      modelName: 'defaultTab',
      originalName: ORGANIZATIONS.FYFFES,
      title: ORGANIZATIONS.FYFFES,
    },
  ],
  defaultTab: dashboardTabModel,
  settingsAndActions,
});
