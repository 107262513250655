export default () => [
  {
    id: 2542,
    is_read: false,
    created_at: '2019-08-14T09:37:14.057Z',
    event: {
      id: 696,
      entityType: 'AHBatch',
      entityId: '08718906150690',
      statusChangedTo: null,
      eventDoneAt: '2019-08-14T09:37:11.000Z',
      actionType: 'CREATED',
      actionSource: 'api',
      channel: { title: 'Oranges', name: 'oranges' },
      eventType: { id: 1, title: 'Batch upload', slug: 'BatchUpload' },
      eventDoneBy: {
        username: 'AH QA',
        email: 'info@simsuplychain.com',
        org: { name: 'AH', fullname: 'Ah company', logo: null },
      },
    },
  },
  {
    id: 97,
    is_read: false,
    created_at: '2019-04-18T12:20:33.555Z',
    event: {
      id: 9,
      entityType: 'CertFile',
      entityId: 'ca64daf8-8fe1-4032-ba7b-48f572c86a59',
      statusChangedTo: 'APPROVED',
      eventDoneAt: '2019-04-18T12:20:33.000Z',
      actionType: 'STATUS_CHANGED',
      actionSource: null,
      channel: { title: 'Oranges', name: 'oranges' },
      eventType: {
        id: 6,
        title: 'Certificate approval status change',
        slug: 'CertificateStatusChange',
      },
      eventDoneBy: {
        username: 'SIM TST staging',
        email: 'SIMTST@pc.t2f.io',
        org: { name: 'SIM', fullname: 'Sim company', logo: null },
      },
    },
  },
];
