import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { getRequests, getIsLoading } from 'domain/requests';
// components
import SimpleFrame from 'components/Frames/SimpleFrame';
// form
import { reduxForm, Field } from 'redux-form';
import Select from 'components/Form/Select';
import Textarea from 'components/Form/Textarea/textarea';
import Button from 'components/Form/Button';
import Input from 'components/Form/Input';
import CustomDayPicker from 'components/Form/CustomDayPicker/customDayPicker';
//
import * as requestsActions from 'domain/requests/actions';
import { getUser } from 'domain/env';
import { getOrgs } from 'domain/common';
import AC from 'domain/AC';

// jss
import injectSheet from 'react-jss';
import { toast } from 'lib/helpers';

class RequestAdd extends React.PureComponent {
  requestType = [
    {
      value: 'batch',
      title: 'Batch Info',
    },
    {
      value: 'cert',
      title: 'Certificate',
    },
  ];

  submitNewRequest = (values) => {
    const { createRequest, history } = this.props;

    let request;

    if (values.to_org_id) {
      request = { ...values, to_org_id: parseInt(values.to_org_id, 10) };

      if (request.to_org_id <= 0) {
        toast('Chose the organisation to send.');
        return false;
      }

      if (request.from_org_id) {
        request = { ...request, from_org_id: parseInt(values.from_org_id, 10) };
      }

      createRequest(request, history);
    }
    return false;
  };

  validation = (v) => (v ? undefined : 'Required');

  render() {
    const { classes, handleSubmit, orgs, user } = this.props;

    const currentOrg = orgs.find((f) => f.name === user.role);

    let toOrgs = [];

    if (currentOrg instanceof Object && currentOrg.hasOwnProperty('name')) {
      toOrgs = orgs.filter((f) => currentOrg.level > f.level);
    }

    return (
      <section className={classes.component}>
        <SimpleFrame title="Status: Open">
          <form onSubmit={handleSubmit(this.submitNewRequest)}>
            <div className={classes.requestAddForm}>
              <div className="form-item">
                <Field
                  name="type"
                  title="Request Type"
                  type="text"
                  validate={this.validation}
                  component={Select}
                  props={{
                    options: this.requestType,
                    keys: { value: 'value', title: 'title' },
                  }}
                />
              </div>
              <div className="form-item">
                <Field
                  name="subject"
                  title="Enter request"
                  type="text"
                  validate={this.validation}
                  component={Input}
                  props={{
                    placeholder: 'e.g. SG17322637',
                  }}
                />
              </div>

              {AC.USER.CAN.NEW_REQUEST_ORG_FROM && (
                <div className="form-item">
                  <Field
                    name="from_org_id"
                    title="Org From"
                    type="text"
                    validate={this.validation}
                    component={Select}
                    props={{
                      options: orgs.filter((f) => f.name !== user.role),
                      keys: { value: 'id', title: 'fullname' },
                    }}
                  />
                </div>
              )}
              <div className="form-item">
                <Field
                  name="to_org_id"
                  title="Org To"
                  type="text"
                  validate={this.validation}
                  component={Select}
                  props={{
                    options: toOrgs,
                    keys: { value: 'id', title: 'name' },
                  }}
                />
              </div>
              <div className="form-item last">
                <Field
                  name="expiry_date"
                  title="Expiration date"
                  type="text"
                  validate={this.validation}
                  component={CustomDayPicker}
                />
              </div>
              <div className="form-item-big">
                <Field
                  name="msg_request"
                  title="Notes"
                  component={Textarea}
                  props={{
                    placeholder: 'e.g. Please provide details asap.',
                  }}
                />
              </div>

              <Button type="submit" className={classes.btn}>
                Submit
              </Button>
            </div>
          </form>
        </SimpleFrame>
      </section>
    );
  }
}

const requestAddStyle = (theme) => ({
  component: {
    padding: ['2em', 0],
  },
  btn: {
    ...theme.components.btn,
    backgroundColor: theme.colors.primaryHover,
    '&:hover': {
      backgroundColor: theme.colors.primaryHover,
    },
  },
  requestAddForm: {
    display: 'flex',
    flex: '0 0 100%',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    padding: ['2em', 0],
    '& .form-item': {
      flex: '2 0 15%',
      zIndex: 1,
      marginRight: '1em',
    },
    '& .form-item.last': {
      marginRight: 0,
    },
    '& .form-item-big': {
      flex: '0 0 100%',
    },
  },
});

RequestAdd.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  createRequest: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  orgs: PropTypes.array,
  user: PropTypes.object,
};

export default compose(
  connect(
    (state, ownProps) => {
      const defaultBatch =
        ownProps.location.state && ownProps.location.state.batch
          ? ownProps.location.state.batch
          : false;
      const initialValues = {};

      if (defaultBatch) {
        initialValues.type = 'batch';
        initialValues.subject = defaultBatch.requestNumber;
        const orgs = getOrgs(state);
        const currentOrg = orgs.find((f) => f.name === defaultBatch.toOrg);
        initialValues.to_org_id = currentOrg && currentOrg.id ? currentOrg.id : 0;
        initialValues.from_org_id = getUser(state).org.id;
      } else {
        initialValues.from_org_id = getUser(state).org.id;
      }

      return {
        requests: getRequests(state),
        user: getUser(state),
        isLoading: getIsLoading(state),
        orgs: getOrgs(state),
        // initial values are here for passing in redux-form
        initialValues,
      };
    },
    {
      createRequest: (data, history) => ({
        type: requestsActions.createRequestAction.type,
        payload: data,
        history,
      }),
    },
  ),
  reduxForm({
    form: 'request-add-form',
  }),
  injectSheet(requestAddStyle),
)(RequestAdd);
