import Route from 'route-parser';

import notificationListTilapia from 'mocks/responses/notifications/tilapia';
import notificationListOranges from 'mocks/responses/notifications/oranges';
import notificationListEggs from 'mocks/responses/notifications/eggs';
import notificationListBananas from 'mocks/responses/notifications/bananas';
import notificationListJuice from 'mocks/responses/notifications/juice';

import { CHANNELS } from 'themes/constants';
import { getUserFromRequest } from 'mocks/usersUtils';
import { readNotificationResponse } from 'mocks/router/utils';
import { createNotificationResponse } from 'mocks/responses/notifications/utils';

export default (request) => {
  const user = getUserFromRequest(request);
  const route = new Route('/api/v1/users(/view_config/*config)(/notifications)(/:notificationId)');
  const parameters = route.match(request.url);

  if (parameters) {
    if (parameters.config) {
      return [200, { resource: { viewName: parameters.config, ...JSON.parse(request.data) } }];
    }

    let notificationList;
    switch (request.channel) {
      case CHANNELS.TILAPIA: {
        notificationList = notificationListTilapia(user.orgName);
        break;
      }
      case CHANNELS.ORANGES: {
        notificationList = notificationListOranges(user.orgName);
        break;
      }
      case CHANNELS.EGGS: {
        notificationList = notificationListEggs(user.orgName);
        break;
      }
      case CHANNELS.BANANAS: {
        notificationList = notificationListBananas(user.orgName);
        break;
      }
      case CHANNELS.JUICE: {
        notificationList = notificationListJuice(user.orgName);
        break;
      }
      default:
        notificationList = createNotificationResponse(0, []);
    }

    if (request.method === 'put') {
      const clickedNotification = notificationList.resources.find(
        (f) => f.id === parseInt(parameters.notificationId, 10),
      );
      return [200, readNotificationResponse(clickedNotification)];
    }

    return [200, notificationList];
  }

  return [500, `url not proceeded: ${request.url}`];
};
