export default () => [
  {
    $class: 'org.powerchain.tilapia.batch.RetailerBatch',
    contractId: '147561',
    dateReceipt: '2019-01-02T15:07:00.000Z',
    supplier: '17165',
    poNumber: '4505581691',
    articleNumberConsumerProduct: '147561',
    palletNumber: '387175915102343571',
    lotNumber: '1809210057',
    aantalVerpakkingen: 144,
    thtConsumerProduct: '2020-07-24T21:00:00.000Z',
    batchId: '3adc5d06-e2fb-4eb0-ba8b-59aa1d6fee9b',
    createdAt: '2019-08-15T08:47:46.138Z',
    createdBy:
      'resource:org.powerchain.tilapia.member.RetailerUser#Jumbo_e1d059a8-05a0-40e9-ae9f-256341b22c96',
    status: 'PENDING',
    uploadSource: 'frontEndApp',
    subcontractAmount: 1,
    subcontractImporterAmount: 1,"createdBy_participant":{
      "username":null,
      "email":null,
      "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
      "org":{
        "name":"Fyffes",
        "fullname":"Fyffes",
        "logo":null
      },
      "channel":{
        "title":"Bananas",
        "name":"bananas"
      },
      "user":{
        "username":"Fyffes TST staging",
        "email":"FyffesTST@pc.t2f.io",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        }
      }
    }
  },
  {
    $class: 'org.powerchain.tilapia.batch.RetailerBatch',
    contractId: '147561',
    dateReceipt: '2019-01-01T15:07:00.000Z',
    supplier: '17165',
    poNumber: '4505581691',
    articleNumberConsumerProduct: '147561',
    palletNumber: '387175915102343571',
    lotNumber: '1809210057',
    aantalVerpakkingen: 144,
    thtConsumerProduct: '2020-07-24T21:00:00.000Z',
    batchId: '3adc5d06-e2fb-4eb0-ba8b-59aa1d6fee9b',
    createdAt: '2019-08-15T08:47:46.138Z',
    createdBy:
      'resource:org.powerchain.tilapia.member.RetailerUser#Jumbo_e1d059a8-05a0-40e9-ae9f-256341b22c96',
    status: 'PENDING',
    uploadSource: 'frontEndApp',
    subcontractAmount: 0,
    subcontractImporterAmount: 0,"createdBy_participant":{
      "username":null,
      "email":null,
      "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
      "org":{
        "name":"Fyffes",
        "fullname":"Fyffes",
        "logo":null
      },
      "channel":{
        "title":"Bananas",
        "name":"bananas"
      },
      "user":{
        "username":"Fyffes TST staging",
        "email":"FyffesTST@pc.t2f.io",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        }
      }
    }
  },
];
