import { put, call, select } from 'redux-saga/effects';
import { isEqual } from 'lodash';

import { getUrl } from 'routes';
import Api from 'domain/api';

import * as EnvActions from './actions';
import { envColumnsSelector } from './selectors';

import { setDefaultColumns, serializeModel } from 'domain/utils';
import { getColumnsKey, getDisplayModel, getMatchedRoute, getModel } from 'domain/common';

import { toast, showAxiosErrors } from 'lib/helpers';
import {
  setStorageItem,
  setStorageType,
  clearStorage,
  LOCAL_STORAGE,
  SESSION_STORAGE,
} from 'lib/storage';

export function* makeSingIn({ payload }) {
  // TODO commented until remeber_me will be implemented
  if (LOCAL_STORAGE) {
    setStorageType(LOCAL_STORAGE);
  } else {
    setStorageType(SESSION_STORAGE);
  }

  try {
    const { data } = yield call(Api.signIn, { data: payload });

    if (data.token) {
      setStorageItem('token', data.token.token);
    }

    yield put({
      type: EnvActions.logInAction.success,
      payload: data,
    });
  } catch (error) {
    showAxiosErrors(error.response);
    yield put({
      type: EnvActions.logInAction.failure,
      payload: error,
    });
  }
}

export function* makeSignUp({ payload, history }) {
  try {
    const { data } = yield call(Api.signUp, { data: payload });

    yield put({
      type: EnvActions.signUpAction.success,
      payload: data,
    });

    toast('Signed up successfully. Thank you.', 'success');

    history.push(getUrl('SignIn'));
  } catch (error) {
    showAxiosErrors(error.response);
    yield put({
      type: EnvActions.signUpAction.failure,
      payload: error,
    });
  }
}

export function* makeSignOut() {
  document.cookie = 'channel=;expires=Thu, 01 Jan 1970 00:00:00 GMT;';
  document.cookie = 'uploadSource=;expires=Thu, 01 Jan 1970 00:00:00 GMT;';
  clearStorage();

  yield put({
    type: EnvActions.logOutAction.success,
    payload: '',
  });
}

function* generateNewConfigs(serializedModel, requestKey) {
  console.log(requestKey);
  const columns = yield select(envColumnsSelector);
  const newConfig = {
    viewName: requestKey,
    config: {
      ...serializedModel,
    },
  };
  const newColumns = columns.map((f) =>
    f.viewName.toLowerCase() === requestKey.toLowerCase() ? newConfig : f,
  );
  if (isEqual(newColumns, columns)) {
    newColumns.push(newConfig);
  }

  return newColumns;
}

// TableSettingsDropdown
export function* fetchTableColumns({ payload }) {
  try {
    const { data } = yield call(Api.fetchTableColumns, {
      storageKey: payload.storageKey,
    });
    yield put({
      type: EnvActions.fetchTableColumnsAction.success,
      payload: data,
    });
  } catch (errors) {
    yield showAxiosErrors(errors.response, true);
    yield put({
      type: EnvActions.fetchTableColumnsAction.failure,
      payload: errors,
    });
  }
}

export function* toggleModelField({ payload }) {
  try {
    const { tabName, columnName } = payload;
    const originalModel = yield select(getModel);
    const displayModel = yield select(getDisplayModel);
    const columnsKey = yield select(getColumnsKey);
    let newDisplayModel;

    // if it is present in model - remove, if not - add
    if (displayModel[tabName] && displayModel[tabName].find(({ key }) => key === columnName)) {
      // remove column
      newDisplayModel = {
        ...displayModel,
        [tabName]: displayModel[tabName].filter(({ key }) => key !== columnName),
      };
    } else {
      // add column
      const columnToAdd = originalModel[tabName].find(({ key }) => key === columnName);

      newDisplayModel = {
        ...displayModel,
        [tabName]: [...displayModel[tabName], columnToAdd],
      };
    }

    const serializedModel = serializeModel(newDisplayModel);
    const newColumns = yield generateNewConfigs(serializedModel, columnsKey);
    yield put({
      type: EnvActions.setModelSettingsAction.success,
      payload: newColumns,
    });
  } catch (errors) {
    console.error(errors);
    yield put({
      type: EnvActions.setModelSettingsAction.failure,
      payload: errors,
    });
  }
}
// envActions.setModelSettingsAction.type (save to profile button)
export function* setTableModel() {
  const displayModel = yield select(getDisplayModel);
  try {
    const columns = yield select(envColumnsSelector);
    const columnsKey = yield select(getColumnsKey);
    const serializedModel = serializeModel(displayModel);

    console.log(`saved ${columnsKey} with config`, serializedModel);
    // TODO unused data?
    const { data } = yield call(Api.setTableColumns, {
      storageKey: columnsKey,
      columns: serializedModel,
    });

    yield put({
      type: EnvActions.setModelSettingsAction.success,
      payload: columns,
    });

    toast('Saved successfully', 'success');
  } catch (errors) {
    console.error(errors);
    toast(errors.message);
    yield put({
      type: EnvActions.setModelSettingsAction.failure,
    });
  }
}

export function* setDefaultModelSettings() {
  try {
    const columnsKey = yield select(getColumnsKey);
    const model = yield select(getModel);
    const route = yield select(getMatchedRoute);
    const defaultColumnKey = route.userColumnsType;
    const modelWithDefaultFields = setDefaultColumns(model, defaultColumnKey);
    const serializedDefaultModel = serializeModel(modelWithDefaultFields);
    const newConfigs = yield generateNewConfigs(serializedDefaultModel, columnsKey);

    console.log(newConfigs);
    yield put({
      type: EnvActions.setModelSettingsAction.success,
      payload: newConfigs,
    });
  } catch (errors) {
    toast(errors.message);
    yield put({
      type: EnvActions.setModelSettingsAction.failure,
    });
  }
}

// TableSettingsDropdown
export function* setTableColumns({ payload }) {
  try {
    const { data } = yield call(Api.setTableColumns, {
      storageKey: payload.storageKey,
      columns: payload.columns,
    });

    yield put({
      type: EnvActions.setTableColumnsAction.success,
      payload: data.resource,
    });
  } catch (errors) {
    yield showAxiosErrors(errors.response, true);
    yield put({
      type: EnvActions.setTableColumnsAction.failure,
      payload: errors,
    });
  }
}

export function* fetchNotificationConfig() {
  try {
    const { data } = yield call(Api.fetchNotificationConfig);

    // beckend sends array or obj
    let nConfigs = {};
    if (Array.isArray(data.resources) && data.resources.length) {
      nConfigs = data.resources[data.resources.length - 1];
    } // TODO what is value  ???!!! #important
    if (data.resources && typeof value === 'object' && data.resources.constructor === Object) {
      nConfigs = data.resources;
    }

    yield put({
      type: EnvActions.fetchNotificationConfigAction.success,
      payload: nConfigs,
    });
  } catch (errors) {
    yield showAxiosErrors(errors.response, true);
    yield put({
      type: EnvActions.fetchNotificationConfigAction.failure,
      payload: errors,
    });
  }
}

export function* updateNotificationConfig({ payload }) {
  try {
    const { data } = yield call(Api.updateNotificationConfig, {
      data: payload,
    });

    yield put({
      type: EnvActions.updateNotificationConfigAction.success,
      payload: data.resources,
    });
    toast('Notifications configuration is saved successfully!', 'success');
  } catch (errors) {
    yield showAxiosErrors(errors.response);
    yield put({
      type: EnvActions.updateNotificationConfigAction.failure,
      payload: errors,
    });
  }
}
