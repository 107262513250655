import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { getRequest, getIsLoading } from 'domain/requests';
// components
import SimpleTabsFrame from 'components/Frames/SimpleTabsFrame';

import Loader from 'components/Loaders/smallLoader';
import * as requestsActions from 'domain/requests/actions';
// redux-form'
import { reduxForm, Field } from 'redux-form';
// components
import Select from 'components/Form/Select';
import Textarea from 'components/Form/Textarea/textarea';
import Button from 'components/Form/Button';
// jss
import injectSheet from 'react-jss';

class InboxAnswer extends React.PureComponent {
  statusOptions = [
    {
      value: 'UPDATED',
      title: 'UPDATED',
    },
    {
      value: 'CLOSED',
      title: 'CLOSED',
    },
  ];

  static propTypes = {
    classes: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    fetchRequestList: PropTypes.func.isRequired,
    updateInboxRequest: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    request: PropTypes.object,
    handleSubmit: PropTypes.func.isRequired,
  };

  componentDidMount() {
    const {
      fetchRequestList,
      match: { params },
    } = this.props;
    fetchRequestList({
      requestType: 'inbox',
      id: params.inboxId,
    });
  }

  submit = (values) => {
    const {
      updateInboxRequest,
      match: { params },
    } = this.props;
    const option = this.statusOptions.find((f) => f.value === values.status);
    if (!option) {
      return;
    }
    updateInboxRequest(params.inboxId, values);
  };

  validation = (v) => (v ? undefined : 'Required');

  render() {
    const { classes, request, isLoading, handleSubmit } = this.props;

    const incomingRequest = [
      'created_at',
      'expiry_date',
      'id',
      'msg_request',
      'msg_response',
      'status',
      'subject',
      'type',
      'updated_at',
    ];

    return (
      <section className={classes.component}>
        {request.isEmpty() || (
          <React.Fragment>
            <SimpleTabsFrame
              tabs={
                isLoading
                  ? [{ style: {}, title: <Loader />, body: '' }]
                  : [
                      {
                        style: {},
                        className: '',
                        title: <span>Inbox Request #{request.id}</span>,
                        body: Object.keys(request)
                          .filter((key) => incomingRequest.includes(key))
                          .map((key) => (
                            <div className={classes.tableRow} key={key}>
                              <div className="left">{key}</div>
                              <div className="right">{request[key]}</div>
                            </div>
                          )),
                      },
                    ]
              }
            />
            <form onSubmit={handleSubmit(this.submit)}>
              <div className={classes.handleSubmitInner}>
                <Field
                  name="status"
                  type="text"
                  component={Select}
                  validate={this.validation}
                  props={{
                    options: this.statusOptions.length ? this.statusOptions : [],
                    keys: { value: 'value', title: 'title' },
                    title: 'Status',
                  }}
                />

                <Field
                  name="msg_response"
                  component={Textarea}
                  props={{
                    title: 'Response Message',
                  }}
                />

                <Button type="submit" className={classes.btn}>
                  Submit
                </Button>
              </div>
            </form>
          </React.Fragment>
        )}
      </section>
    );
  }
}

const inboxAnswerStyles = (theme) => ({
  component: {
    padding: ['2em', 0],
  },
  tableRow: {
    display: 'flex',
    color: theme.colors.black,
    backgroundColor: theme.colors.background,
    border: '1px solid rgb(222, 222, 222)',
    borderTop: 0,
    '&:nth-of-type(even)': {
      backgroundColor: theme.colors.white,
      overflow: 'hidden',
    },
    '& .left': {
      flex: '0 0 50%',
      padding: [5, 8],
      textAlign: 'left',
      borderRight: '1px solid rgb(222, 222, 222)',
    },
    '& .right': {
      flex: '0 0 50%',
      padding: [5, 8],
      textAlign: 'left',
    },
  },
  handleSubmitInner: {
    margin: [20, 0],
  },
  btn: {
    ...theme.components.btn,
    backgroundColor: theme.colors.primary,
    '&:hover': {
      backgroundColor: theme.colors.primaryHover,
    },
  },
});

export default compose(
  connect(
    (state) => ({
      request: getRequest(state),
      isLoading: getIsLoading(state),
    }),
    {
      fetchRequestList: (data) => ({
        type: requestsActions.fetchRequestAction.type,
        payload: data,
      }),
      updateInboxRequest: (id, data) => ({
        type: requestsActions.updateInboxRequestAction.type,
        payload: { id, data },
      }),
    },
  ),
  injectSheet(inboxAnswerStyles),
  reduxForm({
    form: 'inbox-answer-from',
  }),
)(InboxAnswer);
