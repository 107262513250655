export default () => {
  if (FileReader.prototype.readAsBinaryString === undefined) {
    FileReader.prototype.readAsBinaryString = function(fileData) {
      const pt = this;
      const reader = new FileReader();
      reader.onload = function(e) {
        const blobURL = URL.createObjectURL(fileData);
        const xhr = new XMLHttpRequest();
        xhr.open('get', blobURL);
        xhr.overrideMimeType('text/plain; charset=x-user-defined');
        xhr.onload = function() {
          const g = { target: { result: xhr.response } };
          // @ts-ignore
          pt.onload && pt.onload(g);
        };
        xhr.send();
      };
      reader.readAsArrayBuffer(fileData);
    };
  }
};
