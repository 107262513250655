import { getNameFromBlockchainString } from 'lib/helpers';
import { renderShortDateFormat } from 'lib/renderHelpers';
import { extractUploaderName, getFormattedCertTitle } from 'models/certificates/utils';
import { Certificate } from 'lib/lib';

export default [
  {
    title: 'Document Type',
    key: 'documentType',
    showInDetails: true,
    showInSIM: true,
    style: { flex: '1 0 50px' },
    render: (document: Certificate) => document.documentType,
  },
  {
    title: 'Document Number',
    key: 'documentNumber',
    showInDetails: true,
    showInSIM: true,
    style: { flex: '1 0 70px' },
    render: (document: Certificate) => document.documentNumber,
  },
  {
    title: 'Audit date',
    titleSIM: 'SIM verified date',
    key: 'auditDate',
    showInDetails: true,
    showInSIM: true,
    style: { flex: '1 0 50px' },
    render: (document: Certificate) => renderShortDateFormat(document.auditDate),
  },
  {
    title: 'Entity Certified',
    key: 'entityCertified',
    showInDetails: true,
    showInSIM: true,
    style: { flex: '1 0 50px' },
    render: (document: Certificate) => document.entityCertified,
  },
  {
    title: 'Entity Id',
    key: 'entityId',
    showInDetails: true,
    showInSIM: true,
    style: { flex: '1 0 50px' },
    render: (document: Certificate) => document.entityId,
  },
  {
    title: 'Valid from',
    key: 'from',
    showInDetails: true,
    showInSIM: true,
    style: { flex: '1 0 50px' },
    render: (document: Certificate) => renderShortDateFormat(document.from),
  },
  {
    title: 'Valid until',
    key: 'until',
    showInDetails: true,
    showInSIM: true,
    style: { flex: '1 0 50px' },
    render: (document: Certificate) => getFormattedCertTitle(document.until),
  },
  {
    title: 'Uploaded by',
    key: 'uploadedBy',
    showInDetails: true,
    style: { flex: '1 0 50px' },
    render: (document: Certificate) => extractUploaderName(document),
  },
  {
    title: 'Uploaded at',
    key: 'uploadedAt',
    showInDetails: true,
    style: { flex: '1 0 50px' },
    render: (document: Certificate) => renderShortDateFormat(document.uploadedAt),
  },
  {
    title: 'Notes',
    key: 'notes',
    showInDetails: true,
    style: { flex: '1 0 50px' },
    render: (document: Certificate) => document.notes,
  },
  {
    title: 'Approve Msg',
    key: 'approveMsg',
    showInDetails: true,
    showInSIM: true,
    style: { flex: '1 0 50px' },
    render: (document: Certificate) => document.approveMsg,
  },
  {
    title: 'Approve Reason',
    key: 'approveReason',
    showInDetails: true,
    showInSIM: true,
    style: { flex: '1 0 50px' },
    render: (document: Certificate) => document.approveReason,
  },
  {
    title: 'Approved At',
    titleSIM: 'Approved At',
    key: 'approvedAt',
    showInDetails: true,
    showInSIM: true,
    style: { flex: '1 0 50px' },
    render: (document: Certificate) => renderShortDateFormat(document.approvedAt),
  },
  {
    title: 'Approved By',
    key: 'approvedBy',
    showInDetails: true,
    showInSIM: true,
    style: { flex: '1 0 50px' },
    render: (document: Certificate) => getNameFromBlockchainString(document.approvedBy),
  },
];
