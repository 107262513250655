import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
// styles
import { WithStyles } from 'react-jss';
import mainLayoutStyles from 'pages/layouts/MainLayout/mainLayoutStyles';
// components
import { SimFooterLofoSvg } from 'components/icons';
// redux
import { getMatchedRoute } from 'domain/common';
// utils
import { routes } from 'routes';
// types
import { AppStateType } from 'types';

const mapStateToProps = (state: AppStateType) => ({
  matchedRoute: getMatchedRoute(state),
});

const SubMainMenu: React.FC<WithStyles<typeof mainLayoutStyles>> = (props) => {
  const { classes } = props;
  const { matchedRoute } = useSelector(mapStateToProps, shallowEqual);
  const currentRoute = routes.MainLayout.find((r) => r.name === matchedRoute.name);

  return (
    <div className={classes.subMainMenu}>
      <div className="inner">
        <div className="left-side">
          <div className="status">{currentRoute ? currentRoute.subMainMenuTitle : ''}</div>
        </div>

        <div className="right-side">
          <div className="right-side-logo">
            <span>A solution by</span>
            <SimFooterLofoSvg width={80} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(SubMainMenu);
