import notifications from 'models/notifications/tabs/notificationsTabModel';
import settingsAndActions from 'models/notifications/settingsAndActions/index';
import { MODEL_TYPES } from 'themes/constants';

export default () => ({
  tabs: [
    {
      modelName: 'defaultTab',
      originalName: MODEL_TYPES.NOTIFICATIONS,
      title: MODEL_TYPES.NOTIFICATIONS,
    },
  ],
  defaultTab: notifications,
  settingsAndActions,
});
