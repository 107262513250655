import { Theme } from 'lib/lib';
import { Theme as ReactSelectTheme } from 'react-select';

export const defaultStyles = (theme: Theme) => ({
  defaults: {
    minWidth: 150,

    '& .react-select__control': {
      minHeight: 40,

      '&--is-focused, &:hover': {
        borderColor: '#0f32c9',
        boxShadow: 'none',
      },
    },

    '& .react-select__menu': {
      zIndex: 10,
    },

    '& .react-select__option': {
      color: theme.colors.black,
      textAlign: 'left',
      padding: '8px 4px 8px 8px',
      fontSize: '0.95em',
    },

    '&.error': {
      '& .react-select__control': {
        borderColor: '#dc233b',
        boxShadow: 'none',
      },
    },
  },
});

export const defaultTheme = (theme: ReactSelectTheme) => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary: 'rgba(143, 143, 143, 1)',
    primary75: 'rgba(143, 143, 143, 0.75)',
    primary50: 'rgba(143, 143, 143, 0.5)',
    primary25: 'rgba(143, 143, 143, 0.25)',
  },
});
