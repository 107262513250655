import generic from 'mocks/router/generic';
import producerbatch from 'mocks/router/juice/producerbatch';
import importerbatch from 'mocks/router/juice/importerbatch';
import processorbatch from 'mocks/router/juice/processorbatch';
import retailerbatch from 'mocks/router/juice/retailerbatch';
import refrescobatch from 'mocks/router/juice/refrescobatch';

export default {
  ...generic,
  producerbatch,
  importerbatch,
  processorbatch,
  retailerbatch,
  refrescobatch,
};
