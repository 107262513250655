import Route from 'route-parser';

import importerBatchContractResponseTilapia from 'mocks/responses/channels/tilapia/importerbatch/contractList';
import importerBatchHandshakeTilapia from 'mocks/responses/channels/tilapia/importerbatch/handshake';
import importerBatchDetailsResponseTilapia from 'mocks/responses/channels/tilapia/importerbatch/details';
import uploadBatchResponse from 'mocks/responses/batchUpload/batchUploadTilapiaBananas';

import { deleteContractResponseTilapia } from 'mocks/router/utils';

export default (request) => {
  const route = new Route(
    '/api/v1/tilapia/importerbatch(?*filter)(/:batchId)(/contracts/:contractId)(/handshake/?filter[ssccPallet]=:ssccPallet)',
  );

  const parameters = route.match(request.url);
  if (parameters) {
    if (parameters.ssccPallet) {
      return [200, importerBatchHandshakeTilapia()];
    }

    if (parameters.contractId) {
      if (request.method === 'delete') {
        return [200, deleteContractResponseTilapia(parameters.contractId)];
      }

      return [200, importerBatchDetailsResponseTilapia()];
    }

    if (parameters.filter) {
      return [200, importerBatchContractResponseTilapia()];
    }

    if (parameters.batchId) {
      return [200, ...importerBatchContractResponseTilapia()];
    }
  }

  if (request.method === 'post') {
    return [200, uploadBatchResponse()];
  }

  return [500, `url not proceeded: ${request.url}`];
};
