import logoKaufland from 'components/logos/Kaufland.svg';

import { BatchListSvg, CertificateSvg, DashboardSvg, NotificationListSvg } from 'components/icons';
import { theme } from 'themes/styles';

export default {
  logo: logoKaufland,
  colors: {
    primary: '#e57e84',
    primaryHover: '#e10815',
    mainMenuLine: '#e10815',
    mainMenuIconColor: '#e10815',
    mainMenuTextColor: 'rgb(0, 0, 0)',
    black: 'rgb(61, 61, 61)',
    backgroundHead: 'rgb(255, 255, 255)',
    datePickerBgColor: '#e10815',
    accent: '#7595ae',
    modelSettingsCheckedColor: theme.colors.green,
  },
  mainMenuItems: [
    {
      routeName: 'Dashboard',
      icon: DashboardSvg,
      disabled: true,
    },
    {
      routeName: 'Notifications',
      icon: NotificationListSvg,
      disabled: true,
    },
    {
      routeName: 'Batches',
      icon: BatchListSvg,
    },
    {
      routeName: 'Documents',
      icon: CertificateSvg,
    },
  ],
};
