export default () => [
  {
    $class: 'org.powerchain.tilapia.batch.RetailerBatchGroupedConcept',
    articleNumberConsumerProduct: '147561',
    uniqBatchesAmount: 11,
    thtConsumerProductMin: '2020-07-24T21:00:00.000Z',
    thtConsumerProductMax: '2020-07-24T21:00:00.000Z',
    dateReceiptMin: '2019-01-02T15:05:00.000Z',
    dateReceiptMax: '2019-03-13T13:07:00.000Z',
    uniqPalletNumbersAmount: 6,
    createdAtMin: '2019-08-15T08:47:46.138Z',
    createdAtMax: '2019-08-15T08:47:46.138Z',
    createdBy:
      'resource:org.powerchain.tilapia.member.RetailerUser#Jumbo_e1d059a8-05a0-40e9-ae9f-256341b22c96',
    status: 'PENDING',"createdBy_participant":{
      "username":null,
      "email":null,
      "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
      "org":{
        "name":"Fyffes",
        "fullname":"Fyffes",
        "logo":null
      },
      "channel":{
        "title":"Bananas",
        "name":"bananas"
      },
      "user":{
        "username":"Fyffes TST staging",
        "email":"FyffesTST@pc.t2f.io",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        }
      }
    }
  },
];
