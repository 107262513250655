import logoJumbo from 'components/logos/jumbo.png';
import { BatchesListTilapiaSvg, CertificateSvg, NotificationListSvg } from 'components/icons';
import { theme } from 'themes/styles';

export default {
  logo: logoJumbo,
  colors: {
    primary: 'rgb(39, 86, 178)',
    primaryHover: 'rgb(55, 99, 184)',
    mainMenuLine: 'rgb(39,86,178)',
    mainMenuIconColor: 'rgb(39,86,178)',
    mainMenuTextColor: 'rgb(0, 0, 0)',
    black: 'rgb(61, 61, 61)',
    backgroundHead: 'rgb(255, 255, 255)',
    datePickerBgColor: 'rgb(39,86,178)',
    datePickerColor: 'rgba(255, 255, 255, 1)',
    accent: 'rgb(55, 99, 184)',
    modelSettingsCheckedColor: theme.colors.green,
  },
  mainMenuItems: [
    {
      routeName: 'Notifications',
      icon: NotificationListSvg,
      disabled: false,
    },
    {
      routeName: 'Batches',
      icon: BatchesListTilapiaSvg,
    },
    {
      routeName: 'Documents',
      icon: CertificateSvg,
    },
  ],
};
