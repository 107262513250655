import defaultTabModel from 'models/egg1/tabs/defaultTabModel';
import { findMatchedOrgName } from 'themes/main';
import { ModelRequiredInfo } from 'lib/lib';
import { CHANNELS } from 'themes/constants';

export default (modelRequiredInfo: ModelRequiredInfo) => {
  const orgNameByParams =
    modelRequiredInfo.batchesHierarchyByOrgname[CHANNELS.EGGS][
      findMatchedOrgName(modelRequiredInfo.role)!
    ];
  const roleSubcontract = orgNameByParams.subcontract;
  return [...defaultTabModel(modelRequiredInfo)].filter((f) => ![roleSubcontract].includes(f.key));
};
