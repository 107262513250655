import React, { useLayoutEffect, useMemo } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { compose } from 'redux';
// jss
import injectSheet, { WithStyles } from 'react-jss';
import notificationsStyle from 'pages/notifications/styles/notificationsStyle';
// components
import ReactPaginate from 'react-paginate';
import Datatable from 'components/Datatable/datatable';
// redux
import * as notificationsActions from 'domain/notifications/actions';
import { getNotifications, getMeta, getIsLoading } from 'domain/notifications';
import { getDisplayModel, getModel } from 'domain/common';
// types
import { AppStateType } from 'types';

const mapStateToProps = (state: AppStateType) => ({
  notifications: getNotifications(state),
  meta: getMeta(state),
  isLoading: getIsLoading(state),
  model: getModel(state),
  displayModel: getDisplayModel(state),
});

const Notifications: React.FC<WithStyles<typeof notificationsStyle>> = (props) => {
  const dispatch = useDispatch();
  const { classes } = props;
  const { notifications, meta, isLoading, model, displayModel } = useSelector(
    mapStateToProps,
    shallowEqual,
  );

  const total = useMemo(() => (meta.pages ? meta.pages.total : 0), [meta]);
  const perPage = useMemo(() => (meta.pages ? meta.pages.perPage : 0), [meta]);
  const page = useMemo(() => (meta.pages ? meta.pages.page : 0), [meta]);
  const totalPages = useMemo(() => {
    const pagesCount = Math.ceil(total / perPage);
    if (Number.isFinite(pagesCount) && !Number.isNaN(pagesCount)) {
      return pagesCount;
    }
    return 0;
  }, [total, perPage]);

  const fetchNotifications = ({ selected } = { selected: 0 }) => {
    dispatch(
      notificationsActions.fetchNotificationsAction({ pages: { page: selected + 1, perPage } }),
    );
  };

  useLayoutEffect(fetchNotifications, []);

  return (
    <section className={classes.component}>
      {!isLoading && (
        <div className={classes.header}>{`Notifications ${page} / ${totalPages}`}</div>
      )}

      <Datatable
        data={notifications}
        columns={displayModel}
        settingsAndActions={model.settingsAndActions}
        isLoading={isLoading}
        noItemsText="No notifications have been found"
      />

      {total > perPage && (
        <div className={classes.paginate}>
          <ReactPaginate
            previousLabel="<"
            nextLabel=">"
            breakLabel="..."
            breakClassName="break-me"
            pageCount={totalPages}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={fetchNotifications}
            containerClassName="pagination"
            activeClassName="active"
          />
        </div>
      )}
    </section>
  );
};

export default compose<React.FC>(
  injectSheet(notificationsStyle),
  React.memo,
)(Notifications);
