import React from 'react';
import { Link } from 'react-router-dom';
import { renderTwoShortDateFormat, renderShortDateFormat } from 'lib/renderHelpers';
import { CHANNELS, ORGANIZATIONS } from 'themes/constants';
import { getUrl } from 'routes';
import { extractCreatedBy } from 'models/utils/utils';
import { BatchRecord, ModelRequiredInfo } from 'lib/lib';

export default (modelRequiredInfo: ModelRequiredInfo) => [
  {
    key: 'vesselTkBatchId',
    label: 'Batch ID in Vessel TK',
    title: 'Batch ID in Vessel TK',
    defaultSelectedColumn: true,
    style: { flex: '1 0 70px' },
    render: (column: BatchRecord) => (
      <Link
        to={{
          pathname: getUrl('BatchContractType', {
            type: ORGANIZATIONS.CITROSUCOBR.toLowerCase(),
            contract_number: encodeURIComponent(encodeURIComponent(`${column.vesselTkBatchId}`)),
            modelType: 'contractDetails',
            channelName: CHANNELS.JUICE,
          }),
        }}
      >
        {column.vesselTkBatchId}
      </Link>
    ),
  },
  {
    key: 'uniqPlantCount',
    label: 'Unique Plants',
    title: 'Unique Plants',
    defaultSelectedColumn: true,
    style: { flex: '1 0 30px' },
    render: (column: BatchRecord) => column.uniqPlantCount,
    renderExportValue: (column: BatchRecord) => column.uniqPlantCount,
  },
  {
    key: 'uniqFarmCount',
    label: 'Unique Farms',
    title: 'Unique Farms',
    defaultSelectedColumn: true,
    style: { flex: '1 0 30px' },
    render: (column: BatchRecord) => column.uniqFarmCount,
    renderExportValue: (column: BatchRecord) => column.uniqFarmCount,
  },
  {
    key: 'lastReceiptDateBr',
    label: 'Last Date Of Reciept Terminal Brazil',
    title: 'Last Date Of Reciept Terminal Brazil',
    defaultSelectedColumn: true,
    style: { flex: '1 0 100px' },
    renderExportValue: (column: BatchRecord) =>
      renderTwoShortDateFormat(column.lastReceiptDateBrMin, column.lastReceiptDateBrMax),
    render: (column: BatchRecord) =>
      renderTwoShortDateFormat(column.lastReceiptDateBrMin, column.lastReceiptDateBrMax),
  },
  {
    key: 'startManDate',
    label: 'Start Manufacturing Date',
    title: 'Start Manufacturing Date',
    defaultSelectedColumn: true,
    style: { flex: '1 0 100px' },
    renderExportValue: (column: BatchRecord) =>
      renderTwoShortDateFormat(column.startManDateMin, column.startManDateMax),
    render: (column: BatchRecord) =>
      renderTwoShortDateFormat(column.startManDateMin, column.startManDateMax),
  },
  {
    key: 'finalManDate',
    label: 'Final Manufacturing Date',
    title: 'Final Manufacturing Date',
    defaultSelectedColumn: true,
    style: { flex: '1 0 100px' },
    renderExportValue: (column: BatchRecord) =>
      renderTwoShortDateFormat(column.finalManDateMin, column.finalManDateMax),
    render: (column: BatchRecord) =>
      renderTwoShortDateFormat(column.finalManDateMin, column.finalManDateMax),
  },
  {
    key: 'harvestStartDate',
    label: 'Start Harvest Date',
    title: 'Start Harvest Date',
    defaultSelectedColumn: true,
    style: { flex: '1 0 100px' },
    renderExportValue: (column: BatchRecord) =>
      renderTwoShortDateFormat(column.harvestStartDateMin, column.harvestStartDateMax),
    render: (column: BatchRecord) =>
      renderTwoShortDateFormat(column.harvestStartDateMin, column.harvestStartDateMax),
  },
  {
    key: 'harvestFinalDate',
    label: 'Final Harvest Date',
    title: 'Final Harvest Date',
    defaultSelectedColumn: true,
    style: { flex: '1 0 100px' },
    renderExportValue: (column: BatchRecord) =>
      renderTwoShortDateFormat(column.harvestFinalDateMin, column.harvestFinalDateMax),
    render: (column: BatchRecord) =>
      renderTwoShortDateFormat(column.harvestFinalDateMin, column.harvestFinalDateMax),
  },
  {
    key: 'blDate',
    label: 'B/L Date',
    title: 'B/L Date',
    defaultSelectedColumn: true,
    style: { flex: '1 0 100px' },
    renderExportValue: (column: BatchRecord) =>
      renderTwoShortDateFormat(column.blDateMin, column.blDateMax),
    render: (column: BatchRecord) => renderTwoShortDateFormat(column.blDateMin, column.blDateMax),
  },
  {
    key: 'createdAtMin',
    label: 'Created At',
    title: 'Created At',
    style: { flex: '1 0 60px' },
    // isSortable: true,
    renderExportValue: (column: BatchRecord) => column.createdAtMin,
    render: (column: BatchRecord) => renderShortDateFormat(column.createdAtMin),
  },
  {
    key: 'createdAtMax',
    label: 'Last Updated',
    title: 'Last Updated',
    style: { flex: '1 0 60px' },
    // isSortable: true,
    renderExportValue: (column: BatchRecord) => column.createdAtMax,
    render: (column: BatchRecord) => renderShortDateFormat(column.createdAtMax),
  },
  {
    key: 'createdBy',
    label: 'Created By',
    title: 'Created By',
    style: { flex: '1 0 60px' },
    renderExportValue: (column: BatchRecord) => extractCreatedBy(column),
    render: (column: BatchRecord) => extractCreatedBy(column),
  },
];
