export default () => [
  {
    $class: 'org.powerchain.batch.JDEBatch',
    releaseNumber: 'SG44444444',
    kgShipped: 123,
    shipToNumber: '10423',
    shipTo: 'Refresco Benelux B.V. (Menken) Ship to',
    invoiceDate: '2018-05-28T00:00:00.000Z',
    contractNumber: 'SNL-1002/17',
    customerReference: '4500719466',
    secondItemNumber: 'CLD168015',
    kpiRatio: 166,
    lotSerialNumber: '18315037',
    blDate: '2018-04-27T00:00:00.000Z',
    receiptDate: '2018-04-28T00:00:00.000Z',
    batchesLevelOne: '444444-4444/17.1.1',
    batchId: '2847a61f-3dc8-4e5b-835c-1c46ef4748ef',
    createdAtMin: '2019-09-02T13:04:11.000Z',
    createdAtMax: '2019-09-02T13:04:11.000Z',
    createdBy: 'resource:org.powerchain.member.JDEUser#JDE_e4e32907-61ac-4509-9177-07b320ef9f47',
    status: 'PENDING',
    uploadSource: 'frontEndApp',
    channelName: 'oranges',
    modifiedBy_participant: {
      username: null,
      email: null,
      participant:
        'org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8',
      org: {
        name: 'Fyffes',
        fullname: 'Fyffes',
        logo: null,
      },
      channel: {
        title: 'Bananas',
        name: 'bananas',
      },
      user: {
        username: 'Fyffes TST staging',
        email: 'FyffesTST@pc.t2f.io',
        org: {
          name: 'Fyffes',
          fullname: 'Fyffes',
          logo: null,
        },
      },
    },
    createdBy_participant: {
      username: null,
      email: null,
      participant:
        'org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8',
      org: {
        name: 'Fyffes',
        fullname: 'Fyffes',
        logo: null,
      },
      channel: {
        title: 'Bananas',
        name: 'bananas',
      },
      user: {
        username: 'Fyffes TST staging',
        email: 'FyffesTST@pc.t2f.io',
        org: {
          name: 'Fyffes',
          fullname: 'Fyffes',
          logo: null,
        },
      },
    },
  },
];
