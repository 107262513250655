import React from 'react';
// components
import EditableCellVariant from 'components/Datatable/editableCellVariant';
import { CheckSvg, LogoutSvg } from 'components/icons';
import GenericModal from 'components/Modals/genericModal/genericModal';
// jss
import injectSheet from 'react-jss';
import editPopupStyles from 'components/Datatable/styles/editPoputStyles';

class EditableCSVCellPopup extends React.Component {
  render() {
    const { props } = this;
    const {
      classes,
      dateFormat,
      inputRef,
      label,
      handleInputChange,
      inputValue,
      edit,
      handleApplyChanges,
      setDefaultValue,
      originalChildren,
    } = props;

    return (
      <>
        <GenericModal
          hideModalCallBack={setDefaultValue}
          title={`Modify '${label}'`}
          content={
            <EditableCellVariant
              dateFormat={dateFormat}
              classes={classes}
              inputRef={inputRef}
              type="text"
              handleInputChange={handleInputChange}
              inputValue={inputValue}
              edit={edit}
            />
          }
          controls={
            <div className={classes.buttonsContainer}>
              <div className="accept" onClick={handleApplyChanges}>
                <CheckSvg />
                <span cy-data="accept">Accept</span>
              </div>
              <div className="cancel" onClick={setDefaultValue}>
                <LogoutSvg />
                <span cy-data="cancel">Cancel</span>
              </div>
            </div>
          }
        />
        {/* they are here to keep 'table' markup */}
        {originalChildren}
      </>
    );
  }
}

export default injectSheet(editPopupStyles)(EditableCSVCellPopup);
