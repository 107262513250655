import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { getChannelName, getOwnOrgName } from 'domain/env';
import DashboardOLD from 'pages/dashboard/DashboardOLD';
import DashboardNEW from 'pages/dashboard/DashboardNEW';
import { AppStateType } from 'types';
import { CHANNELS, ORGANIZATIONS } from 'themes/constants';

const mapStateToProps = (state: AppStateType) => ({
  channelName: getChannelName(state),
  ownOrgName: getOwnOrgName(state),
});

const Dashboard: React.FC = () => {
  const { channelName, ownOrgName } = useSelector(mapStateToProps, shallowEqual);
  if (channelName === CHANNELS.BANANAS && ownOrgName === ORGANIZATIONS.SIM) {
    return <DashboardNEW />;
  }
  return <DashboardOLD />;
};

export default Dashboard;
