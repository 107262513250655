import { ChannelNameProps, IsOpenedProps } from './interfaces';
import React from 'react';
import { compose } from 'redux';
import { getChannelName } from 'domain/env';
import { connect } from 'react-redux';
// icons
import { ArrowDownSvg, ArrowUpSvg } from 'components/icons';

import { CHANNELS } from 'themes/constants';
import {
  EggsChannelLogoSvg,
  OrangesChannelLogoSvg,
  TilapiaChannelLogoSvg,
  BananasChannelLogoSvg,
  JuiceChannelLogoSvg,
} from 'components/icons';

export const ChannelIconResolver: React.FC<ChannelNameProps> = ({ channelName }) => {
  switch (channelName) {
    case CHANNELS.EGGS: {
      return <EggsChannelLogoSvg alt={channelName} />;
    }

    case CHANNELS.ORANGES: {
      return <OrangesChannelLogoSvg alt={channelName} />;
    }

    case CHANNELS.TILAPIA: {
      return <TilapiaChannelLogoSvg alt={channelName} />;
    }

    case CHANNELS.BANANAS: {
      return <BananasChannelLogoSvg alt={channelName} />;
    }

    case CHANNELS.JUICE: {
      return <JuiceChannelLogoSvg alt={channelName} />;
    }
  }
};

export const Arrow: React.FC<IsOpenedProps> = (props) => {
  if (props.isOpen) {
    return <ArrowUpSvg className="arrow" />;
  } else {
    return <ArrowDownSvg className="arrow" />;
  }
};

export const OwnIcon: React.FC<ChannelNameProps> = ({ channelName }) => {
  return <ChannelIconResolver channelName={channelName} />;
};

export const OwnChannelIcon = compose(
  connect((state) => ({
    channelName: getChannelName(state),
  })),
)(OwnIcon);
