import React, { Dispatch, SetStateAction, useCallback, useMemo, useState } from 'react';
import injectStyle, { WithStyles } from 'react-jss';
import dataTableUploadExcelStyles from 'components/Datatable/styles/dataTableUploadExcelStyles';
import { UploadedParsedFile, ValidationErrors } from 'pages/batches/components/UploadExcelFile2';
import { AppStateType } from 'types';
import { getModel } from 'domain/common';
import { shallowEqual, useSelector } from 'react-redux';
import { CreateNewSvg } from 'components/icons';
import { IndexedObject, ModelObjectDefault } from 'lib/lib';
import ReactTooltipWrapper from 'components/Tooltips/ReactTooltipWrapper';
import EditableCellExcel from 'components/Datatable/editableCellExcel';

interface OwnProps extends WithStyles<typeof dataTableUploadExcelStyles> {
  uploadedParsedFile: UploadedParsedFile | null;
  setUploadedParsedFile: Dispatch<SetStateAction<UploadedParsedFile | null>>;
  activeTab: string;
  dateFormat?: string | { [p: string]: string };
}

const initialState = {
  offsetTop: -1000,
  rowId: -1,
  displayNewRowSubmenu: false,
};

const DataTableUploadExcel2: React.FC<OwnProps> = (props) => {
  const { classes, uploadedParsedFile, activeTab, dateFormat, setUploadedParsedFile } = props;
  const [state, setState] = useState(initialState);
  const model = useSelector(getModel, shallowEqual);

  const columns = useMemo(() => model[activeTab], [model, activeTab]);

  const getDateFormat = useCallback(
    (column: ModelObjectDefault) => {
      if (!dateFormat) {
        return undefined;
      }
      if (typeof dateFormat === 'string') {
        return dateFormat;
      } else {
        return dateFormat[column.key];
      }
    },
    [dateFormat],
  );

  const setHoveredRowInfo = useCallback(
    (event: React.MouseEvent<HTMLDivElement>, dIndex: number, dataItem: IndexedObject<string>) => {
      const { offsetTop } = state;

      const rows = document.querySelectorAll('*[data-cy="tableRow"]');
      // @ts-ignore
      const target = [...rows].find((row) => row.contains(event.target));

      if (target) {
        // @ts-ignore
        const offset = target.offsetTop + (target.offsetHeight - 30) / 2;
        if (offsetTop !== offset) {
          setState({
            rowId: (dataItem.rowId as unknown) as number,
            offsetTop: offset,
            displayNewRowSubmenu: false,
          });
        }
      }
    },
    [],
  );

  const handleCellUpdate = useCallback(
    (changeRowId: number, columnKey: string, newValue: string) => {
      const columnsArray = uploadedParsedFile && uploadedParsedFile[activeTab];
      const columnToChange =
        columnsArray &&
        columnsArray.map((column) =>
          ((column.rowId as unknown) as number) === changeRowId
            ? { ...column, [columnKey]: newValue }
            : column,
        );

      columnToChange &&
        setUploadedParsedFile(
          (prevState) => ({ ...prevState, [activeTab]: columnToChange } as UploadedParsedFile),
        );
    },
    [uploadedParsedFile, activeTab, setUploadedParsedFile],
  );

  return (
    <div className={classes.dataTableUploadExcel}>
      <div className="wrapper">
        {/* TABLE HEADERS */}
        <div className="dataTableHead" data-cy="tableHeader">
          {model[activeTab] &&
            model[activeTab].map(({ key, style, shortTitle, title }) => (
              <div data-cy="columnHeader" key={key} style={(style as object) || {}}>
                {(shortTitle as string) || title}
              </div>
            ))}
        </div>

        {/* TABLE BODY */}
        {uploadedParsedFile &&
        uploadedParsedFile[activeTab] &&
        uploadedParsedFile[activeTab].length > 0 ? (
          <>
            {uploadedParsedFile[activeTab].map((dataItem, dIndex) => (
              <React.Fragment key={dIndex}>
                <>
                  <div
                    onMouseOver={(event) => setHoveredRowInfo(event, dIndex, dataItem)}
                    className="dataTableRow"
                    data-cy="tableRow"
                    data-dindex={dIndex}
                    key={dIndex}
                  >
                    {columns &&
                      columns.map((column, cIndex) => {
                        const tooltip = ((column.validate as unknown) as ((
                          v: string,
                          allData: ModelObjectDefault[],
                        ) => { status: boolean; message: string })[])
                          .map((v) => v(dataItem[column.key] as string, columns))
                          .filter((f) => !f.status)
                          .map((f) => f.message)
                          .join('<br />');

                        return (
                          <div
                            data-cy="tableCell"
                            style={(column.style as React.CSSProperties) || {}}
                            key={cIndex}
                          >
                            <ReactTooltipWrapper
                              id={`${dIndex}.${cIndex}`}
                              style={{ width: '100%', height: '100%' }}
                              backgroundColor="rgba(239, 16, 72, 0.3)"
                              shouldShowTooltip={!!tooltip}
                              tooltip={tooltip}
                              tooltipProps={{
                                place: 'bottom',
                                effect: 'solid',
                                multiline: true,
                              }}
                            >
                              <EditableCellExcel
                                dateFormat={getDateFormat(column)}
                                handleCellUpdate={handleCellUpdate}
                                column={column}
                                dataItem={dataItem}
                              >
                                {column.renderUploadPreview && column.renderUploadPreview(dataItem)}
                              </EditableCellExcel>
                            </ReactTooltipWrapper>
                          </div>
                        );
                      })}
                  </div>
                </>
              </React.Fragment>
            ))}
          </>
        ) : (
          <div className={classes.emptyTableBodyContainer}>
            <span className={classes.emptyBodyMessage}>There are no rows to display</span>
            <div
              onClick={() => {
                //
              }}
              className={classes.iconContainer}
            >
              <CreateNewSvg style={{ fill: 'white' }} />
              <span>Add new row</span>
            </div>
          </div>
        )}
      </div>

      {/*{this.displayRowControls()}*/}
    </div>
  );
};

export default injectStyle(dataTableUploadExcelStyles)(DataTableUploadExcel2);
