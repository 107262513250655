import { renderShortDateFormat, customDatePreview, formatDateToISO } from 'lib/renderHelpers';

import {
  validationRequired,
  validationInt,
  validationString,
  validationCustomDate,
} from 'lib/uploadFieldValidations';
import { ORGANIZATIONS } from 'themes/constants';
import { UPLOAD_TIME_FORMAT } from 'themes/main';
import { BatchRecord, ModelRequiredInfo } from 'lib/lib';

export default (modelRequiredInfo: ModelRequiredInfo) => {
  const { FYFFES } = ORGANIZATIONS;
  const DATE_FORMAT = UPLOAD_TIME_FORMAT[FYFFES] as string;
  const validationFyffesDate = validationCustomDate(DATE_FORMAT);

  return [
    {
      key: 'SSCCPallet',
      label: 'SSCC pallet',
      title: 'SSCC pallet',
      style: { flex: '1 0 160px' },
      renderUploadPreview: (column: BatchRecord) => column.SSCCPallet,
      render: (column: BatchRecord) => column.SSCCPallet,
      validate: [validationRequired, validationString],
      defaultSelectedColumn: true,
      defaultSelectedBatchContractColumn: true,
    },
    {
      key: 'vessel',
      label: 'Vessel',
      title: 'Vessel',
      style: { flex: '1 0 100px' },
      renderUploadPreview: (column: BatchRecord) => column.vessel,
      render: (column: BatchRecord) => column.vessel,
      validate: [validationString],
      defaultSelectedColumn: true,
      defaultSelectedBatchContractColumn: true,
    },
    {
      key: 'packagingType',
      label: 'Packaging type',
      title: 'Packaging type',
      style: { flex: '1 0 100px' },
      renderUploadPreview: (column: BatchRecord) => column.packagingType,
      render: (column: BatchRecord) => column.packagingType,
      validate: [validationString],
      defaultSelectedColumn: true,
      defaultSelectedBatchContractColumn: true,
    },
    {
      key: 'producer',
      label: 'Producer',
      title: 'Producer',
      style: { flex: '1 0 100px' },
      renderUploadPreview: (column: BatchRecord) => column.producer,
      render: (column: BatchRecord) => column.producer,
      validate: [validationString],
      defaultSelectedColumn: true,
      defaultSelectedBatchContractColumn: true,
    },
    {
      key: 'receiptDate',
      label: 'Shipment receipt date',
      title: 'Shipment receipt date',
      style: { flex: '1 0 140px' },
      handleParsedValue: (value: string) => formatDateToISO(value, DATE_FORMAT),
      renderUploadPreview: ({ receiptDate }: BatchRecord) =>
        customDatePreview(receiptDate, [validationRequired, validationFyffesDate]),
      render: (column: BatchRecord) => renderShortDateFormat(column.receiptDate),
      validate: [validationRequired, validationFyffesDate],
      defaultSelectedColumn: true,
      defaultSelectedBatchContractColumn: true,
      edit: { type: 'date' },
    },
    {
      key: 'outboundTransportDate',
      label: 'Outbound transport date',
      title: 'Outbound transport date',
      style: { flex: '1 0 140px' },
      handleParsedValue: (value: string) => formatDateToISO(value, DATE_FORMAT),
      renderUploadPreview: ({ outboundTransportDate }: BatchRecord) =>
        customDatePreview(outboundTransportDate, [validationRequired, validationFyffesDate]),
      render: (column: BatchRecord) => renderShortDateFormat(column.outboundTransportDate),
      validate: [validationRequired, validationFyffesDate],
      defaultSelectedColumn: true,
      defaultSelectedBatchContractColumn: true,
      edit: { type: 'date' },
    },
    {
      key: 'numberOfLoadedBoxes',
      label: 'Number of boxes loaded',
      title: 'Number of boxes loaded',
      style: { flex: '1 0 100px' },
      renderUploadPreview: (column: BatchRecord) => column.numberOfLoadedBoxes,
      render: (column: BatchRecord) => column.numberOfLoadedBoxes,
      validate: [validationInt],
      defaultSelectedColumn: true,
      defaultSelectedBatchContractColumn: true,
    },
  ];
};
