import { call, put } from 'redux-saga/effects';
// actions
import * as requestsActions from 'domain/requests/actions';
// libs helpers
import { showAxiosErrors } from 'lib/helpers';

import Api from 'domain/api';
import { getUrl } from 'routes';

export function* fetchRequests({ payload }) {
  try {
    const { data } = yield call(Api.fetchRequests, {
      requestType: payload.requestType,
    });
    yield put({
      type: requestsActions.fetchRequestsAction.success,
      payload: data,
    });
  } catch (errors) {
    yield showAxiosErrors(errors.response);
    yield put({
      type: requestsActions.fetchRequestsAction.failure,
      payload: errors,
    });
  }
}

export function* fetchRequest({ payload }) {
  try {
    const { data } = yield call(Api.fetchRequest, {
      requestType: payload.requestType,
      requestId: payload.id,
    });
    yield put({
      type: requestsActions.fetchRequestAction.success,
      payload: data,
    });
  } catch (errors) {
    yield showAxiosErrors(errors.response);
    yield put({
      type: requestsActions.fetchRequestAction.failure,
      payload: errors,
    });
  }
}

export function* createRequest({ payload, history }) {
  try {
    const response = yield call(Api.createRequest, {
      data: payload,
    });
    yield put({
      type: requestsActions.createRequestAction.success,
      payload: response.data,
    });
    history.push(getUrl('Outbox'));
  } catch (errors) {
    yield showAxiosErrors(errors.response);
    yield put({
      type: requestsActions.createRequestAction.failure,
      payload: errors,
    });
  }
}

export function* updateInboxRequest({ payload }) {
  try {
    const { data } = yield call(Api.updateInboxRequest, {
      id: payload.id,
      data: payload.data,
    });
    yield put({
      type: requestsActions.updateInboxRequestAction.success,
      payload: data,
    });
  } catch (errors) {
    yield showAxiosErrors(errors.response);
    yield put({
      type: requestsActions.updateInboxRequestAction.failure,
      payload: errors,
    });
  }
}
