export default () => [
  {
    id: 2062,
    is_read: true,
    created_at: '2019-08-13T13:30:38.143Z',
    event: {
      id: 623,
      entityType: 'LDCBRBatch',
      entityId: '3723278-0009/18.5.1',
      statusChangedTo: null,
      eventDoneAt: '2019-08-13T13:30:34.000Z',
      actionType: 'CREATED',
      actionSource: 'frontEndApp',
      channel: { title: 'Oranges', name: 'oranges' },
      eventType: { id: 1, title: 'Batch upload', slug: 'BatchUpload' },
      eventDoneBy: {
        username: 'LDCBRTST staging',
        email: 'LDCBRTST@pc.t2f.io',
        org: { name: 'LDCBR', fullname: 'Ldcbr company', logo: null },
      },
    },
  },
  {
    id: 1184,
    is_read: false,
    created_at: '2019-06-18T08:24:31.863Z',
    event: {
      id: 96,
      entityType: 'Demand',
      entityId: '15',
      statusChangedTo: 'CLOSED',
      eventDoneAt: '2019-06-18T08:24:31.000Z',
      actionType: 'STATUS_CHANGED',
      actionSource: null,
      eventType: { id: 8, title: 'Request status change', slug: 'DemandStatusChange' },
      channel: { title: 'Oranges', name: 'oranges' },
      eventDoneBy: {
        username: 'LDCBRTST staging',
        email: 'LDCBRTST@pc.t2f.io',
        org: { name: 'LDCBR', fullname: 'Ldcbr company', logo: null },
      },
    },
  },
  {
    id: 244,
    is_read: false,
    created_at: '2019-04-18T13:29:11.483Z',
    event: {
      id: 20,
      entityType: 'CertFile',
      entityId: 'c0c1a668-4f55-462b-a5ab-659e5396bcd1',
      statusChangedTo: null,
      eventDoneAt: '2019-04-18T13:29:09.000Z',
      actionType: 'CREATED',
      actionSource: null,
      eventType: { id: 4, title: 'Certificate upload', slug: 'CertificateUpload' },
      channel: { title: 'Oranges', name: 'oranges' },
      eventDoneBy: {
        username: 'LDCBRTST staging',
        email: 'LDCBRTST@pc.t2f.io',
        org: { name: 'LDCBR', fullname: 'Ldcbr company', logo: null },
      },
    },
  },
];
