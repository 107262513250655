import produce from 'immer';
import { AnyAction } from 'redux';

import * as requestsActions from 'domain/requests/actions';
import * as envActions from 'domain/env/actions';

import { toast } from 'lib/helpers';

const initialState = {
  resources: [],
  resource: {},
  isLoading: false,
  errors: {},
};

const requests = produce<(draft: typeof initialState, action: AnyAction) => void>(
  (draft, { type, payload }) => {
    switch (type) {
      case envActions.setChannelAction.type:
      case envActions.logOutAction.success:
        return initialState;

      // Fetch Requests List
      case requestsActions.fetchRequestsAction.type:
        draft.isLoading = true;
        draft.resources = initialState.resources;
        return;

      case requestsActions.fetchRequestsAction.success:
        draft.isLoading = false;
        draft.resources = payload;
        return;

      case requestsActions.fetchRequestsAction.failure:
        draft.isLoading = false;
        draft.errors = payload;
        return;

      // Fetch Requests List
      case requestsActions.fetchRequestAction.type:
        draft.isLoading = true;
        draft.resource = initialState.resource;
        return;

      case requestsActions.fetchRequestAction.success:
        draft.isLoading = false;
        draft.resource = payload;
        return;

      case requestsActions.fetchRequestAction.failure:
        draft.isLoading = false;
        draft.errors = payload;
        return;

      // createRequestAction
      case requestsActions.createRequestAction.type:
        draft.isLoading = true;
        return;

      case requestsActions.createRequestAction.success:
        draft.isLoading = false;
        return;

      case requestsActions.createRequestAction.failure:
        const response = payload.response;
        // TODO move to saga
        if (response && response.data && Array.isArray(response.data)) {
          response.data.forEach((t) => {
            toast(t.message);
          });
        } else if (response.data.message) {
          toast(response.data.message);
        } else {
          toast('Unknown Error');
        }

        draft.isLoading = false;
        return;

      // updateInboxRequestAction
      case requestsActions.updateInboxRequestAction.type:
        draft.isLoading = true;
        return;

      case requestsActions.updateInboxRequestAction.success:
        draft.isLoading = false;
        draft.resource = payload;
        return;

      case requestsActions.updateInboxRequestAction.failure:
        // TODO move to saga
        toast('Unknown Error');

        draft.isLoading = false;
        draft.errors = payload;
        return;
    }
  },
  initialState,
);

export const reducer = { requests };
