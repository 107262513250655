import { toast } from 'lib/helpers';
import csvFileCreator from 'csv-file-creator';


export const exportBatchesToCSV = (batches, batchModel, filename = 'batchlist.csv') => {
  if (!batches.length) {
    toast('No batches ready to export!');
    return false;
  }

  const modelToProceed = batchModel.defaultTab || batchModel;
  const modelFieldsKeys = modelToProceed.map((m) => m.key);
  const modelFieldsLabels = modelToProceed.map((m) => m.label);

  const batchesToExport = batches.map((batchRow) => {
    const newRow = {};
    modelFieldsKeys.forEach((modelFieldsKey) => {
      const currentField = modelToProceed.find((field) => field.key === modelFieldsKey);
      newRow[modelFieldsKey] = currentField.renderExportValue
        ? currentField.renderExportValue(batchRow)
        : batchRow[modelFieldsKey];
    });
    return newRow;
  });


  csvFileCreator(filename, [modelFieldsLabels,...batchesToExport.map(item => Object.values(item))]);
};
