import React from 'react';
import injectSheet, { WithStyles } from 'react-jss';
import { Theme } from 'lib/lib';
import { WrappedFieldMetaProps } from 'redux-form';

const messageStyles = (theme: Theme) => ({
  errorText: {
    color: theme.colors.error,
    fontSize: 12,
  },
});

interface OwnProps extends WithStyles<typeof messageStyles> {
  meta: WrappedFieldMetaProps;
  className?: string;
}

const Message: React.FC<OwnProps> = ({ meta, classes, className = '' }) =>
  Boolean(meta.touched && meta.error) ? (
    <span className={`${classes.errorText} ${className}`}>{meta.error}</span>
  ) : null;

export default injectSheet(messageStyles)(Message);
