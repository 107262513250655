import React from 'react';
import PropTypes from 'prop-types';
// jss
import injectSheet from 'react-jss';
import bananaExcelBatchStyles from 'pages/batches/styles/bananaExcelBatchStyles';
import { compose } from 'redux';

export class DatatableTabs extends React.Component {
  state = {
    activeTabIndex: 0,
  };

  changeTab = (tabIndex) => {
    const { changeTabIndexCallback, changeTabNameCallback, tabs } = this.props;

    this.setState({ activeTabIndex: tabIndex });
    changeTabIndexCallback(tabIndex);
    changeTabNameCallback(tabs[tabIndex].modelName);
  };

  hasErrors = (modelName) => {
    const { validationErrors } = this.props;
    const errorsArray = validationErrors[modelName];

    return Array.isArray(errorsArray) && errorsArray.length > 0;
  };

  getErrorsArray = (modelName) => {
    const { validationErrors } = this.props;

    return validationErrors[modelName];
  };

  displayTabInfo(modelName) {
    const { shouldDisplayDetails } = this.props;

    if (shouldDisplayDetails) {
      const errorsArray = this.getErrorsArray(modelName);
      return this.formErrorMessage(errorsArray, modelName);
    }
    /* this is for contact details - just tabs, no info */
    return null;
  }

  formErrorMessage = (errorsArray, modelName) => {
    const { classes, uploadedParsedFile } = this.props;
    const dataRowsArray = uploadedParsedFile[modelName];
    const { length: errorArrayLength } = errorsArray;
    const errorsCount = this.hasErrors(modelName) ? (
      <span>{`(${errorArrayLength} ${this.formCorrectWord('error')})`}</span>
    ) : null;

    return (
      <p className={classes.errorMessagesContainer}>
        {errorsCount}
        <span>{`${dataRowsArray.length} ${this.formCorrectWord('row')}`}</span>
      </p>
    );
  };

  formCorrectWord = (word) => (word.length > 1 ? `${word}s` : word);

  render() {
    const { tabs, classes } = this.props;
    const { activeTabIndex } = this.state;

    return (
      <span className={classes.excelTabsContainer}>
        {tabs.map((tab, index, array) => {
          const { modelName, title } = tab;
          const errorClassName = this.hasErrors(modelName) ? classes.errorTab : '';

          return (
            <div
              onClick={() => this.changeTab(index)}
              key={tab.title}
              className={[
                classes.excelTab,
                errorClassName,
                activeTabIndex === index ? 'active' : '',
              ].join(' ')}
              style={{
                zIndex: array.length - index,
                transform: `translateX(${index * -15}px)`,
              }}
            >
              <span className={classes.tabTitle} data-cy="tab" data-tab={title}>
                {' '}
                {tab.title}
              </span>
              {this.displayTabInfo(modelName)}
            </div>
          );
        })}
      </span>
    );
  }
}

DatatableTabs.propTypes = {
  tabs: PropTypes.array.isRequired,
  classes: PropTypes.object.isRequired,
  changeTabNameCallback: PropTypes.func,
  changeTabIndexCallback: PropTypes.func,
  validationErrors: PropTypes.object,
  uploadedParsedFile: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  shouldDisplayDetails: PropTypes.bool,
};

DatatableTabs.defaultProps = {
  changeTabNameCallback: () => {},
  changeTabIndexCallback: () => {},
  validationErrors: {},
  uploadedParsedFile: [],
  shouldDisplayDetails: false,
};

export default compose(injectSheet(bananaExcelBatchStyles))(DatatableTabs);
