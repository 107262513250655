import produce from 'immer';
import { AnyAction } from 'redux';

import * as profileActions from 'domain/profile/actions';
import { toast, showAxiosErrors } from 'lib/helpers';
import * as envActions from 'domain/env/actions';

const initialState = {
  status: '',
  isLoading: false,
};

export const profile = produce<(draft: typeof initialState, action: AnyAction) => void>(
  (draft, { type, payload }) => {
    switch (type) {
      case envActions.setChannelAction.type:
      case envActions.logOutAction.success:
        return initialState;

      // sendEmailForRecoverPassword
      case profileActions.sendEmailForRecoverPasswordAction.type:
        draft.isLoading = true;
        return;

      case profileActions.sendEmailForRecoverPasswordAction.success:
        toast(payload.message, 'success');
        draft.isLoading = false;
        draft.status = 'success';
        return;

      case profileActions.sendEmailForRecoverPasswordAction.failure:
        showAxiosErrors(payload);
        draft.isLoading = false;
        return;

      // sendResetPassword
      case profileActions.sendResetPasswordAction.type:
        draft.isLoading = true;
        return;

      case profileActions.sendResetPasswordAction.success:
        toast('Your password was successfully reset!', 'success');
        draft.isLoading = false;
        draft.status = 'success';
        return;

      case profileActions.sendResetPasswordAction.failure:
        showAxiosErrors(payload);
        draft.isLoading = false;
        return;

      // sendResetPassword
      case profileActions.sendChangePasswordAction.type:
        draft.isLoading = true;
        return;

      case profileActions.sendChangePasswordAction.success:
        toast('Your password was successfully changed!', 'success');
        draft.isLoading = false;
        draft.status = 'success';
        return;

      case profileActions.sendChangePasswordAction.failure:
        showAxiosErrors(payload.response.data);
        draft.isLoading = false;
        return;

      // clear status
      case profileActions.clearStatusAction:
        draft.status = '';
        return;
    }
  },
  initialState,
);

export const reducer = { profile };
