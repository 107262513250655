import React, { useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { compose } from 'redux';
import get from 'lodash/get';
import * as batchesActions from 'domain/batches/actions';
// components
import { getBatch, getIsLoading } from 'domain/batches';
import {
  getParams,
  getBatchInfoByUrlOrgRole,
  getModel,
  getBatchInfoByUrlOrgRoleAll,
} from 'domain/common';
import SimpleTabsFrame from 'components/Frames/SimpleTabsFrame';
// jss
import injectSheet, { WithStyles } from 'react-jss';
import Loader from 'components/Loaders/smallLoader';
//
import batcheStyle from 'pages/batches/styles/batchStyle';
import archivedModelFields from 'models/archivedModelFields';
import { AppStateType } from 'types';
import { CHANNELS } from 'themes/constants';

interface OwnProps extends WithStyles<typeof batcheStyle> {}

const mapStateToProps = (state: AppStateType) => ({
  batch: getBatch(state),
  isLoading: getIsLoading(state),
  batchInfo: getBatchInfoByUrlOrgRole(state),
  batchInfoAll: getBatchInfoByUrlOrgRoleAll(state),
  params: getParams(state),
  model: getModel(state),
});

const Batch: React.FC<OwnProps> = (props) => {
  const dispatch = useDispatch();
  const { batch, isLoading, batchInfoAll, params, model } = useSelector(
    mapStateToProps,
    shallowEqual,
  );
  const { classes } = props;

  useEffect(() => {
    if (params.channelName && params.batch_id) {
      dispatch(
        batchesActions.fetchBatchAction({
          batchEndpoint: batchInfoAll[params.channelName as CHANNELS].endpoint,
          batchId: params.batch_id,
        }),
      );
    }
  }, [params]);

  const formBatchTitle = () =>
    `Batch № ${batch.batchId}${params.type ? ` (${params.type.toUpperCase()})` : ''}`;

  const isRenderPropsReceived = () => model && model.tabs;

  if (!isRenderPropsReceived()) {
    return null;
  }

  const batchModel = [...model.defaultTab, ...archivedModelFields()];
  const batchModelKeys = batchModel.map((f) => f.key);

  const tabs = isLoading
    ? [{ style: {}, title: <Loader />, body: '' }]
    : [
        {
          style: {},
          className: '',
          title: formBatchTitle(),
          body: batchModelKeys.map((key) => {
            const currentRow = batchModel.find((f) => f.key === key);
            if (get(batch, key))
              return (
                <div className={classes.tableRow} key={key}>
                  <div className="left">{currentRow && currentRow.title}</div>
                  <div className="right">
                    {currentRow && currentRow.render && currentRow.render(batch)}
                  </div>
                </div>
              );
            return null;
          }),
        },
      ];

  return (
    <section className={classes.component}>
      <SimpleTabsFrame tabs={tabs} />
    </section>
  );
};

export default compose(
  React.memo,
  injectSheet(batcheStyle),
)(Batch);
