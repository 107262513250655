import { ORGANIZATIONS } from '../themes/constants';

const {
  HOLLANDER,
  FYFFES,
  BANAFOOD,
  SIM,
  REGALSPRINGS,
  JUMBO,
  MAYONNA,
  SEAFOODCONNECTION,
  LDCBR,
  JDE,
  REFRESCO,
  AH,
  EGG1,
  EGG2,
  CITROSUCOBR,
  CITROSUCOGHENT,
  KAUFLANDDE,
  REFRESCOGMBH,
} = ORGANIZATIONS;

export interface MocksUser {
  orgName: ORGANIZATIONS;
  email: string;
  token: string;
}

export const MocksUsers: Array<MocksUser> = [
  {
    orgName: EGG1,
    email: 'EGG1TST@powerchain.nl',
    token: 'eggs__egg1__jwt__token__',
  },
  {
    orgName: EGG2,
    email: 'EGG2TST@powerchain.nl',
    token: 'eggs__egg2__jwt__token__',
  },
  {
    orgName: AH,
    email: 'AHEggsTST@powerchain.nl',
    token: 'eggs__ah__jwt__token__',
  },
  {
    orgName: SIM,
    email: 'SIMEggsTST@powerchain.nl',
    token: 'eggs__sim__jwt__token__',
  },
  {
    orgName: AH,
    email: 'AHOrangesTST@powerchain.nl',
    token: 'oranges__ah__jwt__token__',
  },
  {
    orgName: LDCBR,
    email: 'LDCBRTST@powerchain.nl',
    token: 'oranges__ldcbr__jwt__token__',
  },
  {
    orgName: JDE,
    email: 'JDETST@powerchain.nl',
    token: 'oranges__jde__jwt__token__',
  },
  {
    orgName: REFRESCO,
    email: 'RefrescoTST@powerchain.nl',
    token: 'oranges__refresco__jwt__token__',
  },
  {
    orgName: SIM,
    email: 'SIMOranges@powerchain.nl',
    token: 'oranges__sim__jwt__token__',
  },
  {
    orgName: BANAFOOD,
    email: 'banafoodTST@powerchain.nl',
    token: 'bananas__banafood__jwt__token__',
  },
  {
    orgName: FYFFES,
    email: 'fyffesTST@powerchain.nl',
    token: 'bananas__fyffes__jwt__token__',
  },
  {
    orgName: HOLLANDER,
    email: 'hollanderTST@powerchain.nl',
    token: 'bananas__hollander__jwt__token__',
  },
  {
    orgName: SIM,
    email: 'SIMBananas@powerchain.nl',
    token: 'bananas__sim__jwt__token__',
  },
  {
    orgName: REGALSPRINGS,
    email: 'RegalSpringsTST@powerchain.nl',
    token: 'tilapia__regalsprings__jwt__token__',
  },
  {
    orgName: SIM,
    email: 'SIMTilapia@powerchain.nl',
    token: 'tilapia__sim__jwt__token__',
  },
  {
    orgName: SIM,
    email: 'SIMJuice@powerchain.nl',
    token: 'juice__sim__jwt__token__',
  },
  {
    orgName: JUMBO,
    email: 'JumboTST@powerchain.nl',
    token: 'tilapia__jumbo__jwt__token__',
  },
  {
    orgName: MAYONNA,
    email: 'MayonnaTST@powerchain.nl',
    token: 'tilapia__mayonna__jwt__token__',
  },
  {
    orgName: SEAFOODCONNECTION,
    email: 'SeafoodConnectionTST@powerchain.nl',
    token: 'tilapia__seafoodconnection__jwt__token__',
  },
  {
    orgName: CITROSUCOBR,
    email: 'CitrosucoBrazilTST@powerchain.nl',
    token: 'citrosuco__citrosucobrazil__jwt__token__',
  },
  {
    orgName: CITROSUCOGHENT,
    email: 'CitrosucoGhentTST@powerchain.nl',
    token: 'citrosuco__citrosucoghent__jwt__token__',
  },
  {
    orgName: KAUFLANDDE,
    email: 'KauflandDETST@powerchain.nl',
    token: 'citrosuco__kauflandde__jwt__token__',
  },
  {
    orgName: REFRESCOGMBH,
    email: 'RefrescoGmbHTST@powerchain.nl',
    token: 'citrosuco__refrescogmbh__jwt__token__',
  },
];
