import React from 'react';
// components
import ModelSettingsDropdown from 'components/Dropdown/ModelSettingsDropDown';
import DownloadCSV from 'models/utils/DownloadCSV/DownloadCSV';
import ArchiveContractButton from 'models/utils/ArchiveContractButton/ArchiveContractButton';
import { BatchRecord } from 'lib/lib';

export default {
  key: 'settingsAndActions',
  title: (
    <React.Fragment>
      <DownloadCSV />
      <ModelSettingsDropdown />
    </React.Fragment>
  ),
  style: { flex: '0 0 105px', justifyContent: 'space-evenly' },
  render: (column: BatchRecord) => (
    <ArchiveContractButton column={column} channelName={column.channelName} />
  ),
};
