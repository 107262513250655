export default (theme) => ({
  component: {
    padding: ['2em', 0],
  },
  profile: {
    display: 'flex',
    '& .sidebar': {
      flex: '0 0 300px',
      '& > ul': {
        '& > li': {
          '& > button': {
            width: '100%',
            padding: [5, 0],
            border: 0,
            boxShadow: 0,
            outline: 'none',
            textAlign: 'left',
            cursor: 'pointer',
            '&:hover, &.active': {
              backgroundColor: theme.colors.background,
            },
          },
        },
      },
    },
    '& .main': {
      flex: '2 0 300px',
      marginLeft: '1em',
      '& .main-inner': {
        '& div.title': {
          '& h2': {
            font: {
              size: 24,
              weight: 700,
            },
            marginBottom: '0.5em',
          },

          '& p': {
            color: 'rgb(61, 61, 61)',
            fontSize: {
              size: 12,
              weight: 'normal',
              style: 'normal',
              variant: 'normal',
            },
            textDecoration: 'none',
            textAlign: 'left',
            marginBottom: 18,
          },
        },

        '& div': {
          '& .describes': {
            marginBottom: '1em',
          },

          '& ul.general-info': {
            '& li': {
              display: 'flex',
              width: '100%',
              marginBottom: '0.5em',
              '& .param-title': {
                flex: '0 0 150px',
                textAlign: 'left',
              },
              '& .param-value': {
                flex: '2 0 200px',
              },
            },
          },
        },

        '& form': {
          '& div': {
            '& label': {
              width: '100%',
            },
            '& input': {
              width: '100%',
            },
          },
        },
      },
    },
  },

  btn: {
    ...theme.components.btn,
    backgroundColor: theme.colors.primaryHover,
    '&:hover': {
      backgroundColor: theme.colors.primaryHover,
    },
  },

  changesAcceptedBlock: { ...theme.components.successBlock },

  formGroup: {
    position: 'relative',
    marginBottom: 15,
    textAlign: 'center',
    '&.password': {
      marginBottom: 0,
    },
    '&:after': {
      display: 'table',
      content: ' ',
    },
    '& select, & input': {
      lineHeight: 1.42857143,
      boxShadow: 'inset 0 1px 1px rgba(0,0,0,.075)',
      transition: 'border-color ease-in-out .15s,box-shadow ease-in-out .15s',
    },
    '& label': {
      textAlign: 'left',
    },
    '& svg': {
      position: 'absolute',
      top: 28,
      right: 15,
      fill: 'rgba(0, 0, 0, 0.5)',
      width: 25,
    },
  },
});
