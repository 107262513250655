import { Theme } from 'lib/lib';

export default (theme: Theme) => ({
  componentHeader: {
    display: 'flex',
    flexWrap: 'nowrap',
    justifyContent: 'flex-end',
    alignItems: 'center',
    backgroundColor: theme.colors.primary,
    borderRadius: [0, 5, 5, 0],
    '& .tabs': {
      display: 'flex',
      alignItems: 'flex-end',
      '& .tab': {
        position: 'relative',
        '& .select-range': {
          position: 'absolute',
          top: 40,
          borderRadius: [0, 0, 10, 10],
          display: 'flex',
          flexDirection: 'column',
          height: 'max-content',
          zIndex: 99,
          width: 'calc(100% - 2px)',
          padding: [10, 0],
          '& .select-range-form': {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          },
          '& .select-range-header': {
            color: theme.colors.white,
            fontSize: 18,
            margin: [0, 0, 10, 0],
          },
          '& .apply-range-button': {
            padding: [6, 15],
            margin: [10, 0],
            border: 'none',
            borderRadius: 5,
            cursor: 'pointer',
            backgroundColor: theme.colors.white,
            boxShadow: [0, 0, 3, theme.colors.black],
          },
          '& .datepicker-wrapper': {
            display: 'flex',
            alignItems: 'center',
            '& .date-input': {
              width: 90,
              padding: 5,
              borderRadius: 5,
              textAlign: 'center',
              border: 'none',
              boxShadow: [0, 0, 3, theme.colors.black],
            },
            '& .date-input-breaker': {
              margin: [0, 10],
            },
          },
        },
        '& .select-range:hover': {
          backgroundColor: theme.colors.primary,
        },
        '&.active': {
          '& > a, & > div, & > button': {
            backgroundColor: theme.colors.primary,
          },
        },
        '& > a, & > button': {
          cursor: 'pointer',
        },
        '& > a, & > div, & > button': {
          display: 'inline-flex',
          alignItems: 'center',
          minWidth: 240,
          height: 40,
          color: theme.colors.white,
          margin: [0, 2, 0, 0],
          padding: [5, 9],
          borderRadius: [10, 10, 0, 0],
          backgroundColor: theme.colors.primary,
          transition: 'background-color 0.5s ease',
          textDecoration: 'none',
          border: 0,
          outline: 0,
          '&:hover': {
            backgroundColor: theme.colors.primary,
            textDecoration: 'none',
          },
          '& svg': {
            width: 24,
            height: 24,
            fill: theme.colors.white,
            marginRight: 10,
          },
          '& span': {
            marginRight: 10,
            font: {
              size: theme.fontSizes.sm,
              family: 'Lato',
              weight: 400,
            },
            color: theme.colors.white,
          },
          '& select': {
            outline: 0,
          },
        },
      },
      '& .tab.select': {
        display: 'inline-flex',
        minWidth: 250,
        '& > .title, & > .drop-down-list': {
          minWidth: 'auto',
        },
        '& > button.title': {
          display: 'none',
        },
        '& > .drop-down-list': {
          flex: '1 0 50px',
          borderRadius: 0,
          cursor: 'pointer',
          position: 'relative',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          padding: 0,
          '& .datepicker-tab': {
            whiteSpace: 'nowrap',
          },
          '& > button': {
            flex: '1 0 100%',
            minHeight: 30,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: 'transparent',
            border: 0,
            cursor: 'pointer',
            color: theme.colors.white,
            outline: 0,
            '& svg': {
              marginRight: 3,
              width: 15,
              height: 15,
            },
          },
          '& > ul': {
            display: 'none',
            position: 'absolute',
            minWidth: '150%',
            padding: [0, 0, 0, 0],
            backgroundColor: theme.colors.primary,
            top: 40,
            left: 0,
            '&.isDropDownListOpen': {
              zIndex: 99,
              display: 'block',
            },
            '& > li': {
              display: 'flex',
              padding: [0, 0, 0, 5],
              '&:hover': {
                backgroundColor: theme.colors.primary,
              },
              '& button': {
                flex: '2 0 30px',
                textAlign: 'left',
                outline: 0,
                color: theme.colors.white,
                backgroundColor: 'transparent',
                border: 0,
                padding: [5, 10],
                cursor: 'pointer',
              },
            },
          },
        },
      },
    },
  },
  mismatches: {
    color: theme.colors.white,
    marginRight: 'auto',
    marginLeft: 10,
    '& b': {
      fontWeight: 'bold',
    },
  },
  loader: {
    ...theme.components.loader,
    '& .spinner:before, & .spinner:after': {
      borderRightColor: `${theme.colors.white} !important`,
      borderBottomColor: `${theme.colors.white} !important`,
    },
  },
});
