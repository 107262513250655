import React, { useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
// redux
import * as notificationsActions from 'domain/notifications/actions';
import { getChannel } from 'domain/env';
import { getMeta } from 'domain/notifications';
// types
import { AppStateType } from 'types';
import { CHANNELS } from 'themes/constants';

const mapStateToProps = (state: AppStateType) => ({
  channel: getChannel(state),
  meta: getMeta(state),
});

const NotificationCounter: React.FC = () => {
  const dispatch = useDispatch();
  const { channel, meta } = useSelector(mapStateToProps, shallowEqual);

  useEffect(() => {
    if (channel.name !== CHANNELS.JUICE) {
      dispatch({
        type: notificationsActions.fetchNotificationsAction.type,
        payload: {},
        filter: {},
      });
    }
  }, [channel.id]);

  return meta && meta.unread > 0 ? <div className="notificationCount">{meta.unread}</div> : null;
};

export default NotificationCounter;
