import { Theme } from 'lib/lib';

export default (theme: Theme) => ({
  dashboard: {
    padding: '2em 0',
  },
  tabs: {
    display: 'flex',
    justifyContent: 'flex-start',
    borderBottom: `1px solid ${theme.colors.white}`,
  },
  tab: {
    textAlign: 'center',
    minWidth: 'auto',
    borderRadius: [10, 10, 0, 0],
    backgroundColor: theme.colors.primaryHover,
    cursor: 'pointer',
    marginRight: 2,
    transition: 'background-color 0.5s ease',
    border: 'none',
    height: 30,
    color: theme.colors.white,
    padding: [0, 20],
    outline: 'none',
    '&:hover': {
      backgroundColor: theme.colors.primary,
    },
    '&.active': {
      backgroundColor: theme.colors.primary,
    },
  },
  componentBody: {
    display: 'flex',
    flexWrap: 'nowrap',
  },
});
