import React, { useEffect, useMemo, useState, useCallback } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
// styles
import injectStyles, { WithStyles } from 'react-jss';
import stylesDashboardHeader from 'pages/dashboard/components/Header/styles/stylesDashboardHeader';
// components
import Select from 'pages/dashboard/components/Select/Select';
import DropdownTab from 'components/Dropdown/DatepickerRange';
//icons
import Loader from 'components/Loaders/smallLoader';
// helpers
import { getTimePeriods } from 'pages/batches/Forms/listValues';
import { useDateFilters } from 'lib/utils';
// redux
import { getChannelName, getOwnOrgName } from 'domain/env';
import { getIsLoading } from 'domain/unmatched/selectors';
import * as envActions from 'domain/env/actions';
// types
import { BATCH_STATUS } from 'themes/constants';
import { AppStateType } from 'types';
//
import { getSelectValues } from '../Select/config';
import { getQueryParams } from 'domain/common';

export interface Option {
  value: string;
  label: string;
  defaultValue?: boolean;
}

interface OwnProps extends WithStyles<typeof stylesDashboardHeader> {
  mismatchesNumber: number;
}

const mapStateToProps = (state: AppStateType) => ({
  channelName: getChannelName(state),
  ownOrgName: getOwnOrgName(state),
  isLoading: getIsLoading(state),
  queryParams: getQueryParams(state),
});

const DashboardHeader: React.FC<OwnProps> = (props) => {
  const dispatch = useDispatch();
  const { classes, mismatchesNumber } = props;
  const { channelName, ownOrgName, isLoading, queryParams } = useSelector(mapStateToProps, shallowEqual);
  const defaultValue = getSelectValues(ownOrgName).find((value) => value.defaultValue) as Option;
  const [selectedValue, setSelectedValue] = useState(defaultValue);

  const tabs = useMemo(
    () => [
      {
        title: '',
        id: BATCH_STATUS.ALL,
        defaultSelected: true,
      },
    ],
    [],
  );

  const { dateFilters, setDateFilters } = useDateFilters(
    BATCH_STATUS.ALL,
    tabs,
    getTimePeriods(channelName),
  );

  const changeFilters = useCallback(
    (props: { beginDate: Date; endDate: Date }, batchType: string) => {
      setDateFilters({ beginDate: props.beginDate, endDate: props.endDate });
      dispatch({
        type: envActions.setTabsQueryParamsAction.success,
        payload: {
          batchType,
          beginDate: moment(props.beginDate).format('YYYY-MM-DD'),
          endDate: moment(props.endDate).format('YYYY-MM-DD'),
        },
      });
    },
    [selectedValue.value],
  );

  useEffect(() => {
    changeFilters(dateFilters, selectedValue.value);
  }, [selectedValue]);

  useEffect(
    () => () => {
      dispatch({
        type: envActions.setTabsQueryParamsAction.success,
        payload: {},
      });
    },
    [],
  );

  return (
    <div className={classes.componentHeader}>
      <span className={classes.mismatches}>
        {!isLoading ? (
          <>
            <b>{!!mismatchesNumber ? mismatchesNumber : 'No'}</b> mismatch
            {`${mismatchesNumber !== 1 ? 'es' : ''}`} found
          </>
        ) : (
          <div className={classes.loader}>
            <Loader />
          </div>
        )}
      </span>
      <div className="tabs">
        <DropdownTab
          setActiveTab={() => {}}
          id={BATCH_STATUS.ALL}
          title={''}
          values={dateFilters}
          isActive
          changeFilters={(dateFilters1) => {
            changeFilters(dateFilters1, queryParams.batchType || selectedValue.value);
          }}
        />
      </div>
      <Select selectedValue={selectedValue} setSelectedValue={setSelectedValue} />
    </div>
  );
};

export default injectStyles(stylesDashboardHeader)(DashboardHeader);
