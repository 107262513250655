import defaultTab from 'models/refrescoGbmH/tabs/indexTabModel';
import settingsAndActions from 'models/refrescoGbmH/settingsAndActions/index';
import { ORGANIZATIONS } from 'themes/constants';
import archivedModelFields from 'models/archivedModelFields';
import { ModelRequiredInfo } from 'lib/lib';

export default (modelRequiredInfo: ModelRequiredInfo) => {
  const indexArchivedTab = [...defaultTab(modelRequiredInfo), ...archivedModelFields(false)];
  return {
    tabs: [
      {
        modelName: 'defaultTab',
        originalName: ORGANIZATIONS.REFRESCOGMBH,
        title: ORGANIZATIONS.REFRESCOGMBH,
      },
    ],
    defaultTab: indexArchivedTab,
    settingsAndActions,
  };
};
