import indexTabModel from 'models/hollander/tabs/indexTabModel';
import { ORGANIZATIONS } from 'themes/constants';
import { ModelRequiredInfo } from 'lib/lib';
import { defaultBatchesSettingsAndActions } from 'models/utils/utils';

export default (modelRequiredInfo: ModelRequiredInfo) => ({
  tabs: [
    {
      modelName: 'defaultTab',
      originalName: ORGANIZATIONS.SIM,
      title: ORGANIZATIONS.SIM,
    },
  ],
  defaultTab: indexTabModel(modelRequiredInfo),
  settingsAndActions: defaultBatchesSettingsAndActions,
});
