import { formatBytes, getNameFromBlockchainString } from 'lib/helpers';
import moment from 'moment';
import { extractUploaderName, getFormattedCertTitle } from 'models/certificates/utils';
import { Certificate } from 'lib/lib';

export default [
  {
    title: 'Required evidence/rule',
    titleList: 'Category',
    key: 'category',
    showInDetails: true,
    showInSIM: true,
    style: { flex: '1 0 50px' },
    render: (document: Certificate) => document.category,
  },
  {
    title: 'Document Type',
    key: 'documentType',
    showInDetails: true,
    showInSIM: true,
    style: { flex: '1 0 50px' },
    render: (document: Certificate) => document.documentType,
  },
  {
    title: 'Result',
    key: 'result',
    showInSIM: true,
    style: { flex: '1 0 50px' },
    render: (document: Certificate) => document.result,
  },
  {
    title: 'Scope',
    key: 'scope',
    showInSIM: true,
    style: { flex: '1 0 50px' },
    render: (document: Certificate) => document.scope,
  },
  {
    title: 'Number',
    key: 'number',
    showInDetails: true,
    showInSIM: true,
    style: { flex: '1 0 50px' },
    render: (document: Certificate) => document.number,
  },
  {
    title: 'Audit date',
    titleSIM: 'SIM verified date',
    key: 'approvedAt',
    showInDetails: true,
    showInSIM: true,
    style: { flex: '1 0 50px' },
    render: (document: Certificate) =>
      document.approvedAt && moment(document.approvedAt).format('D MMM YYYY'),
  },
  {
    title: 'Valid from',
    key: 'from',
    showInDetails: true,
    showInSIM: true,
    style: { flex: '1 0 50px' },
    render: (document: Certificate) => document.from && moment(document.from).format('D MMM YYYY'),
  },
  {
    title: 'Valid until',
    key: 'until',
    showInDetails: true,
    showInSIM: true,
    style: { flex: '1 0 50px' },
    render: (document: Certificate) => getFormattedCertTitle(document.until),
  },
  {
    title: 'File size',
    key: 'filesize',
    showInDetails: true,
    showInSIM: true,
    style: { flex: '1 0 100px' },
    render: (document: Certificate) => formatBytes(document.filesize, 0),
  },
  {
    title: 'Uploaded by',
    key: 'uploadedBy',
    showInDetails: true,
    render: (document: Certificate) => extractUploaderName(document),
  },
  {
    title: 'Uploaded at',
    key: 'uploadedAt',
    showInDetails: true,
    style: { flex: '1 0 50px' },
    render: (document: Certificate) =>
      document.uploadedAt && moment(document.uploadedAt).format('D MMM YYYY'),
  },
  {
    title: 'Verified by',
    key: 'approvedBy',
    showInSIM: true,
    style: { flex: '1 0 50px' },
    render: (document: Certificate) => getNameFromBlockchainString(document.approvedBy),
  },
  {
    title: 'Notes',
    key: 'notes',
    showInDetails: true,
    style: { flex: '1 0 50px' },
    render: (document: Certificate) => document.notes,
  },
  {
    title: 'Approve Msg',
    key: 'approveMsg',
    showInDetails: true,
    showInSIM: true,
    style: { flex: '1 0 50px' },
    render: (document: Certificate) => document.approveMsg,
  },
  {
    title: 'Verification',
    key: 'approveReason',
    showInDetails: true,
    showInSIM: true,
    style: { flex: '1 0 50px' },
    render: (document: Certificate) => document.approveReason,
  },
];
