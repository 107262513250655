import React from 'react';
// components
import ModelSettingsDropdown from 'components/Dropdown/ModelSettingsDropDown';

export default {
  key: 'settingsAndActions',
  title: <ModelSettingsDropdown />,
  style: { flex: '0 0 70px', justifyContent: 'space-evenly' },
  render: () => null,
};
