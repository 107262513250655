import React, { useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
//
import {
  getBatchInfoByUrlOrgRole,
  getParams,
  getDisplayModel,
  getBatchInfoByUrlOrgRoleAll,
} from 'domain/common';
import { getUsers } from 'domain/env';
import { getBatches, getIsLoading } from 'domain/batches';
import * as batchesActions from 'domain/batches/actions';
import AC from 'domain/AC';
// components
import Datatable from 'components/Datatable/datatable';
import ModelSettingsDropDown from 'components/Dropdown/ModelSettingsDropDown';
// libs
import { participantNameFixBatches } from 'lib/helpers';
// jss
import injectSheet, { WithStyles } from 'react-jss';
import batchesSupplierStyle from 'pages/batches/styles/batchesSupplierStyle';
import { AppStateType } from 'types';
import DownloadCSV from 'models/utils/DownloadCSV/DownloadCSV';
import { getUrl } from 'routes';
import { CHANNELS } from 'themes/constants';

const mapStateToProps = (state: AppStateType) => ({
  batches: participantNameFixBatches(getBatches(state), getUsers(state)),
  users: getUsers(state),
  isLoading: getIsLoading(state),
  batchInfo: getBatchInfoByUrlOrgRole(state),
  params: getParams(state),
  displayModel: getDisplayModel(state),
});

const BatchSupplier: React.FC<WithStyles<typeof batchesSupplierStyle>> = ({ classes }) => {
  const dispatch = useDispatch();
  const { batches, isLoading, batchInfo, displayModel, params } = useSelector(
    mapStateToProps,
    shallowEqual,
  );

  const settingsAndActions = useMemo(() => {
    return {
      key: 'settingsAndActions',
      title: (
        <>
          <DownloadCSV />
          <ModelSettingsDropDown />
        </>
      ),
      style: { flex: '0 0 105px', justifyContent: 'space-evenly' },
      render: ({ batchId }: Record<string, string>) => {
        return (
          <>
            <Link
              to={{
                pathname: getUrl('BatchDetailsType', {
                  type: params.type,
                  batch_id: batchId,
                  modelType: 'details',
                  channelName: CHANNELS.EGGS,
                }),
              }}
              title={batchId}
              className="batch-details"
            >
              <span>Details</span>
            </Link>
            {AC.USER.CAN.BATCHES_DELETE && params.type === 'ah' && (
              /* NOT SURE IF THIS IS USED ANYWHERE */
              <button
                type="button"
                onClick={() =>
                  dispatch(
                    batchesActions.deleteBatchAction({ batchId, endpoint: batchInfo.endpoint }),
                  )
                }
                className="batch-details"
              >
                Archive
              </button>
            )}
          </>
        );
      },
    };
  }, []);

  const fetchBatches = () => {
    const batchEndpoint = batchInfo.endpoint;
    const queryStringParams = {
      filter: { packerGln: params.packerGln, thtDateFrom: params.thtDateFrom },
    };
    dispatch(batchesActions.fetchBatchesAction({ batchEndpoint, queryStringParams }));
  };

  useEffect(() => {
    if (params.packerGln && params.thtDateFrom) {
      fetchBatches();
    }
  }, [params]);

  return (
    <section className={classes.component}>
      <div className={classes.batchContractTableUpperHead}>
        <div className="mainPanel">
          <div className="tab">
            THT Date From {params.thtDateFrom}; Packer Gln {params.packerGln}
          </div>
        </div>
      </div>

      <Datatable
        data={batches}
        columns={displayModel}
        isLoading={isLoading}
        settingsAndActions={settingsAndActions}
        renderDefaultKey="renderBatchesContract"
      />
    </section>
  );
};

export default injectSheet(batchesSupplierStyle)(BatchSupplier);
