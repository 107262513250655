export default [
  {
    key: 'ProducerProcessBatch_floId',
    label: 'Fyffes FLO-ID',
    title: 'Fyffes FLO-ID',
    style: { flex: '1 0 60px' },
    defaultSelectedColumn: true,
    render: (column: any) => column.ProducerProcessBatch_floId || 'null',
    renderExportValue: (column: any) => column.ProducerProcessBatch_floId || 'null',
  },
  {
    key: 'ProducerProcessBatch_SSCCPallet',
    label: 'Fyffes SSCCPallet (1st tab)',
    title: 'Fyffes SSCCPallet (1st tab)',
    style: { flex: '1 0 80px' },
    defaultSelectedColumn: true,
    render: (column: any) => column.ProducerProcessBatch_SSCCPallet || 'null',
    renderExportValue: (column: any) => column.ProducerProcessBatch_SSCCPallet || 'null',
  },
  {
    key: 'ProducerDeliveryBatch_SSCCPallet',
    label: 'Fyffes SSCCPallet (2nd tab)',
    title: 'Fyffes SSCCPallet (2nd tab)',
    style: { flex: '1 0 80px' },
    defaultSelectedColumn: true,
    render: (column: any) => column.ProducerDeliveryBatch_SSCCPallet || 'null',
    renderExportValue: (column: any) => column.ProducerDeliveryBatch_SSCCPallet || 'null',
  },
];
