import indexArchivedTabModel from 'models/banafood/tabs/indexArchivedTabModel';
import settingsAndActions from 'models/banafood/settingsAndActions/indexArchived';
import { ORGANIZATIONS } from 'themes/constants';
import { ModelRequiredInfo } from 'lib/lib';

export default (modelRequiredInfo: ModelRequiredInfo) => ({
  tabs: [
    {
      modelName: 'defaultTab',
      originalName: ORGANIZATIONS.BANAFOOD,
      title: ORGANIZATIONS.BANAFOOD,
    },
  ],
  defaultTab: indexArchivedTabModel(modelRequiredInfo),
  settingsAndActions,
});
