import { ORGANIZATIONS } from 'themes/constants';
import distributor from 'mocks/responses/notifications/bananas/distributor';
import producer from 'mocks/responses/notifications/bananas/producer';
import reseller from 'mocks/responses/notifications/bananas/reseller';

import { createNotificationResponse } from '../utils';

const { SIM, HOLLANDER, FYFFES, BANAFOOD } = ORGANIZATIONS;

export default (org: ORGANIZATIONS) => {
  switch (org) {
    case HOLLANDER:
      return createNotificationResponse(2, distributor());
    case FYFFES:
      return createNotificationResponse(2, producer());
    case BANAFOOD:
      return createNotificationResponse(2, reseller());
    case SIM:
      return createNotificationResponse(6, [...distributor(), ...producer(), ...reseller()]);
    default:
      return createNotificationResponse(0, []);
  }
};
