import processBatchesTabModel from 'models/fyffes/tabs/processBatchesTabModel';
import deliveryBatchesTabModel from 'models/fyffes/tabs/deliveryBatchesTabModel';
import { ModelRequiredInfo } from 'lib/lib';

export default (modelRequiredInfo: ModelRequiredInfo) => {
  return {
    tabs: [
      {
        modelName: 'processBatches',
        originalName: 'Farm-process-shipping',
        title: 'Farm-process-shipping',
      },
      {
        modelName: 'deliveryBatches',
        originalName: 'Antwerp ship receipt-delivery',
        title: 'Antwerp ship receipt-delivery',
      },
    ],
    processBatches: processBatchesTabModel(modelRequiredInfo),
    deliveryBatches: deliveryBatchesTabModel(modelRequiredInfo),
    settingsAndActions: "this is settings and Actions key. This shouldn't crush app",
  };
};
