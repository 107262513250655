import React from 'react';
// comoponents
import ModelSettingsDropdown from 'components/Dropdown/ModelSettingsDropDown';
import DownloadCSV from 'models/utils/DownloadCSV/DownloadCSV';

export default {
  key: 'settingsAndActions',
  title: (
    <React.Fragment>
      <DownloadCSV />
      <ModelSettingsDropdown />
    </React.Fragment>
  ),
  style: { flex: '0 0 105px', justifyContent: 'space-evenly' },
  render: () => null,
};
