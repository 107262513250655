import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { compose } from 'redux';
import Input from 'components/Form/Input';
import Button from 'components/Form/Button';
// icon
import { CheckSvg, EyeSvg, EyeHiddenSvg } from 'components/icons';
// jss
import injectStyle from 'react-jss';
import profileStyle from 'pages/profile/profileStyle';
import { toast } from 'lib/helpers';

class FormChangePassword extends React.Component {
  state = {
    passwordOld: true,
    password: true,
    passwordConfirmation: true,
  };

  componentDidMount() {
    const { clearStatus } = this.props;
    clearStatus();
  }

  submitNewPassword = (values) => {
    // oldPassword, password
    const { submitFunction } = this.props;

    if (!values.old_password) {
      toast('The old password is required');
      return false;
    }

    if (!values.password) {
      toast('The password required (8 charts, 1 number and 1 special at least )');
      return false;
    }

    if (values.password !== values.password_confirmation) {
      toast("Password and password's confirmation must be equal.");
      return false;
    }

    if (String(values.password).length < 8) {
      toast('Password should be 8 characters at least');
      return false;
    }

    // eslint-disable-next-line
    const formatSpecial = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
    const formatNumber = /\d+/;

    if (
      !formatSpecial.test(String(values.password)) ||
      !formatNumber.test(String(values.password))
    ) {
      toast('Password should be 8 characters at least and contains 1 number and 1 special char');
      return false;
    }

    submitFunction({
      old_password: values.old_password,
      password: values.password,
      password_confirmation: values.password_confirmation,
    });
    return true;
  };

  togglePasswordOld = () => {
    const { passwordOld } = this.state;
    this.setState({ passwordOld: !passwordOld });
  };

  togglePassword = () => {
    const { password } = this.state;
    this.setState({ password: !password });
  };

  togglePasswordConfirmation = () => {
    const { passwordConfirmation } = this.state;
    this.setState({ passwordConfirmation: !passwordConfirmation });
  };

  render() {
    const { classes, handleSubmit, status } = this.props;
    const { passwordOld, password, passwordConfirmation } = this.state;

    return status === 'success' ? (
      <div className={classes.changesAcceptedBlock}>
        <CheckSvg />
        <div className="title">Password is changed!</div>
      </div>
    ) : (
      <>
        <p className="describes">
          To change password type your current password and type and confirm new one:
        </p>
        <form onSubmit={handleSubmit(this.submitNewPassword)}>
          <div>
            <div className={`form-item ${classes.formGroup}`}>
              <Field
                name="old_password"
                title="Type old password"
                type={`${passwordOld ? 'password' : 'text'}`}
                validate={this.validation}
                component={Input}
                props={{
                  placeholder: 'my_current_password',
                }}
              />
              {passwordOld ? (
                <EyeHiddenSvg onClick={this.togglePasswordOld} />
              ) : (
                <EyeSvg onClick={this.togglePasswordOld} />
              )}
            </div>

            <div className={`form-item ${classes.formGroup}`}>
              <Field
                name="password"
                title="Type new password"
                type={`${password ? 'password' : 'text'}`}
                validate={this.validation}
                component={Input}
                props={{
                  placeholder: 'my_new_password',
                }}
              />
              {password ? (
                <EyeHiddenSvg onClick={this.togglePassword} />
              ) : (
                <EyeSvg onClick={this.togglePassword} />
              )}
            </div>

            <div className={`form-item ${classes.formGroup}`}>
              <Field
                name="password_confirmation"
                title="Confirm new password"
                type={`${passwordConfirmation ? 'password' : 'text'}`}
                validate={this.validation}
                component={Input}
                props={{
                  placeholder: 'my_new_password',
                }}
              />
              {passwordConfirmation ? (
                <EyeHiddenSvg onClick={this.togglePasswordConfirmation} />
              ) : (
                <EyeSvg onClick={this.togglePasswordConfirmation} />
              )}
            </div>

            <Button type="submit" className={classes.btn}>
              Change password
            </Button>
          </div>
        </form>
      </>
    );
  }
}

FormChangePassword.propTypes = {
  classes: PropTypes.object,
  handleSubmit: PropTypes.func.isRequired,
  submitFunction: PropTypes.func.isRequired,
  clearStatus: PropTypes.func.isRequired,
  status: PropTypes.string.isRequired,
};

export default compose(
  reduxForm({
    form: 'form-change-password',
  }),
  injectStyle(profileStyle),
)(FormChangePassword);
