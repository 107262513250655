import { call, put, select } from 'redux-saga/effects';
import { Saga } from 'redux-saga';

import * as adminActions from './actions';
import { getFilter } from './selectors';
import Api from 'domain/api';
import { toast, showAxiosErrors } from 'lib/helpers';

export const fetchUsers: Saga = function*() {
  try {
    const filter = yield select(getFilter);

    const { data } = yield call(Api.fetchUsers, {
      filter,
    });
    yield put({
      type: adminActions.fetchUsersAction.success,
      payload: data.resources,
    });
  } catch (errors) {
    yield showAxiosErrors(errors.response, true);
    yield put({
      type: adminActions.fetchUsersAction.failure,
      payload: errors.response.data,
    });
  }
};

export const handleFilterChange: Saga = function*() {
  yield put({ type: adminActions.fetchUsersAction.type });
};

export const deleteUser: Saga = function*({ payload }) {
  try {
    const { data } = yield call(Api.deleteUser, {
      id: payload.id,
    });

    yield put({
      type: adminActions.deleteUserAction.success,
      payload: data.resource,
    });
  } catch (errors) {
    yield showAxiosErrors(errors.response);
    yield put({
      type: adminActions.deleteUserAction.failure,
      payload: errors.response.data,
    });
  }
};

export const approveAdminUser: Saga = function*({ payload }) {
  try {
    const { data } = yield call(Api.approveAdminUser, {
      id: payload.id,
    });
    yield put({
      type: adminActions.approveAdminUserAction.success,
      payload: data.resource,
    });
  } catch (errors) {
    yield showAxiosErrors(errors.response);
    yield put({
      type: adminActions.approveAdminUserAction.failure,
      payload: errors.response.data,
    });
  }
};
export const suspendAdminUser: Saga = function*({ payload }) {
  try {
    const { data } = yield call(Api.suspendAdminUser, {
      id: payload.id,
    });
    yield put({
      type: adminActions.suspendAdminUserAction.success,
      payload: data.resource,
    });
  } catch (errors) {
    yield showAxiosErrors(errors.response);
    yield put({
      type: adminActions.suspendAdminUserAction.failure,
      payload: errors.response.data,
    });
  }
};

export const fetchInvitations: Saga = function*() {
  try {
    const { data } = yield call(Api.fetchInvitations, {});
    yield put({
      type: adminActions.fetchInvitationsAction.success,
      payload: data,
    });
  } catch (errors) {
    yield showAxiosErrors(errors.response, true);
    yield put({
      type: adminActions.fetchInvitationsAction.failure,
      payload: errors.response.data,
    });
  }
};

export const createInvitation: Saga = function*({ payload }) {
  try {
    const { data } = yield call(Api.createInvitation, {
      data: payload,
    });

    yield put({
      type: adminActions.createInvitationAction.success,
      payload: data,
    });

    toast(`The letter with invitation link was sent on e-mail '${data.email}'.`, 'success');
  } catch (errors) {
    yield showAxiosErrors(errors.response);
    yield put({
      type: adminActions.createInvitationAction.failure,
      payload: errors,
    });
  }
};

export const updateInvitation: Saga = function*({ payload }) {
  try {
    const { data } = yield call(Api.updateInvitation, {
      id: payload.id,
      data: payload.data,
    });

    yield put({
      type: adminActions.updateInvitationAction.success,
      payload: data,
    });

    toast('Invitation updates successfully', 'success');
  } catch (errors) {
    yield showAxiosErrors(errors.response);
    yield put({
      type: adminActions.updateInvitationAction.failure,
      payload: errors,
    });
  }
};

export const resendInvitation: Saga = function*({ payload }) {
  try {
    const { data } = yield call(Api.resendInvitation, {
      data: payload,
    });

    yield put({
      type: adminActions.resendInvitationAction.success,
      payload: data,
    });

    toast('Invitation resent successfully', 'success');
  } catch (errors) {
    yield showAxiosErrors(errors.response);
    yield put({
      type: adminActions.resendInvitationAction.failure,
      payload: errors,
    });
  }
};
