export default () => [
  {
    id: 12058,
    is_read: false,
    created_at: '2019-08-28T14:30:05.424Z',
    event: {
      id: 2378,
      entityType: 'ProducerBatch',
      entityId: '18839',
      statusChangedTo: null,
      eventDoneAt: '2019-08-28T14:30:02.000Z',
      actionType: 'CREATED',
      actionSource: 'frontEndApp',
      channel: { title: 'Bananas', name: 'bananas' },
      eventType: { id: 1, title: 'Batch upload', slug: 'BatchUpload' },
      eventDoneBy: {
        username: 'Fyffes TST staging',
        email: 'FyffesTST@pc.t2f.io',
        org: { name: 'Fyffes', fullname: 'Fyffes', logo: null },
      },
    },
  },
  {
    id: 10287,
    is_read: false,
    created_at: '2019-08-23T12:28:12.099Z',
    event: {
      id: 2009,
      entityType: 'CertFile',
      entityId: 'ce618fcc-38d7-4eaf-4406-d32706709294',
      statusChangedTo: null,
      eventDoneAt: '2019-08-23T12:28:09.000Z',
      actionType: 'CREATED',
      actionSource: null,
      channel: { title: 'Bananas', name: 'bananas' },
      eventType: { id: 4, title: 'Certificate upload', slug: 'CertificateUpload' },
      eventDoneBy: {
        username: 'Fyffes TST staging',
        email: 'FyffesTST@pc.t2f.io',
        org: { name: 'Fyffes', fullname: 'Fyffes', logo: null },
      },
    },
  },
];
