// DON`T change rgb(...) to #...
export const colors = {
  primary: 'rgb(138, 180, 111)',
  primaryHover: 'rgb(77, 169, 89)',
  mainMenuLine: 'rgb(138, 180, 111)',
  mainMenuIconColor: 'rgb(138, 180, 111)',
  mainMenuTextColor: 'rgb(0, 0, 0)',
  black: '#000',
  redD: '#8d0d0b',
  redDHover: '#a3100e',
  lightRed: '#efd9d9',
  warning: 'red',
  error: '#8d0d0b',
  errorLight: '#ff6961',
  danger: '#cc121d',
  info: 'rgb(122, 122, 122)',
  white: '#fff',
  success: '#8ab46f',
  grey: 'rgb(186, 186, 186)',
  editing: '#f4f8ff',
  lightGrey: 'rgb(250, 250, 250)',
  green: 'rgb(138, 180, 111)',
  greenHover: 'rgb(156, 201, 127)',
  border: 'rgb(200, 200, 200)',
  background: 'rgb(237, 237, 237)',
  backgroundLight: 'rgb(242, 242, 242)',
  backgroundModal: 'rgba(0, 0, 0, 0.3)',
  backgroundHead: 'rgb(255, 255, 255)',
  backgroundTableHead: 'rgb(143, 143, 143)',
  loginButton: '#cc121d',
  link: '#007bff',
  datePickerBgColor: 'rgb(138, 180, 111)',
  datePickerColor: 'rgba(255, 255, 255, 1)',
  logInBackground: 'rgba(255, 255, 255, 0.8)',
  logInBorderColor: '#dfe4ed',
  gray: '#707070',
  blackError: '#3F3356',
  whiteError: '#FBFBFB',
};

export const fontSizes = {
  xl: 21,
  lg: 18,
  md: 16,
  sm: 14,
  xs: 12,
};

export const theme = {
  authLayout: {
    borderRadius: 4,
    inputMinWidth: 200,
    inputHeight: 40,
  },
  container: {},
  content: {
    width: '100%',
    height: '100%',
    overflowX: 'hidden',
    overflowY: 'auto',
  },
  grid: {
    width: 1260,
  },
  pageTitle: {
    fontSize: 24,
    padding: [5, 0],
    color: colors.black,
    '& > span': {
      fontSize: 14,
    },
  },
  bgGray: {
    background: 'rgba(19,60,94,0.1)',
  },
  colors,
  fontSizes,

  components: {
    btn: {
      display: 'block',
      position: 'relative',
      width: 'auto',
      padding: [8, 25],
      margin: [20, 'auto'],
      border: 0,
      borderRadius: 10,
      font: {
        weight: 600,
        size: 14,
      },
      outline: 0,
      backgroundColor: colors.primary,
      color: colors.white,
      transition: 'background-color 0.5s ease',
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: colors.primaryHover,
        textDecoration: 'none',
      },
    },
    loader: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      height: '3em',
      '& .spinner.spinner-1:before': {
        borderRightColor: colors.black,
        borderBottomColor: colors.black,
      },
      '& .spinner.spinner-1:after': {
        borderRightColor: colors.black,
        borderBottomColor: colors.black,
      },
    },
    successBlock: {
      margin: ['1em', 0],
      display: 'flex',
      height: 30,
      '& svg': {
        flex: '0 0 40',
        fill: colors.success,
        width: 24,
        marginRight: 10,
      },
      '& > div': {
        display: 'flex',
        flex: '2 0 50px',
        textAlign: 'left',
        alignItems: 'center',
      },
    },
  },
  successBtn: {
    backgroundColor: colors.success,
    color: colors.white,
    padding: [6, 10],
    borderRadius: 15,
    '&:hover, &:active, &:focus, &:enabled': {
      color: colors.white,
      outline: 'none !important',
    },
  },
  submitBtn: {
    border: 0,
    backgroundColor: colors.white,
    color: colors.black,
    padding: [6, 10],
    borderRadius: 15,
    '&:hover, &:active, &:focus, &:enabled': {
      color: colors.white,
      outline: 'none !important',
    },
  },
  timezone: 'Europe/Amsterdam',
  timeFormats: ['DD/MM/YYYY hh:mm:ss'],
};
