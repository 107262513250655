import produce from 'immer';
import { AnyAction } from 'redux';

import * as unmatchedActions from './actions';
import * as EnvActions from 'domain/env/actions';

const initialState = {
  resources: {},
  batchesList: {},
  statistics: [],
  errors: {},
  isLoading: false,
};

const unmatched = produce<(draft: typeof initialState, action: AnyAction) => void>(
  (draft, { type, payload }) => {
    switch (type) {
      case EnvActions.setChannelAction.type:
        return initialState;

      // fetch OLD dashboard
      case unmatchedActions.fetchUnmatchedBatchContractsAction.type:
        draft.isLoading = true;
        draft.resources = initialState.resources;
        return;

      case unmatchedActions.fetchUnmatchedBatchContractsAction.success:
        draft.isLoading = false;
        draft.resources = payload;
        return;

      case unmatchedActions.fetchUnmatchedBatchContractsAction.failure:
        draft.isLoading = false;
        draft.errors = payload;
        return;

      // fetch NEW dashboard batchesList
      case unmatchedActions.fetchUnmatchedBatchesListAction.type:
        draft.isLoading = true;
        draft.batchesList = initialState.batchesList;
        return;

      case unmatchedActions.fetchUnmatchedBatchesListAction.success:
        draft.isLoading = false;
        draft.batchesList = payload;
        return;

      case unmatchedActions.fetchUnmatchedBatchesListAction.failure:
        draft.isLoading = false;
        draft.errors = payload;
        return;

      // fetch NEW dashboard statistics
      case unmatchedActions.fetchUnmatchedStatisticsAction.type:
        draft.isLoading = true;
        draft.statistics = initialState.statistics;
        return;

      case unmatchedActions.fetchUnmatchedStatisticsAction.success:
        draft.isLoading = false;
        draft.statistics = payload;
        return;

      case unmatchedActions.fetchUnmatchedStatisticsAction.failure:
        draft.isLoading = false;
        draft.errors = payload;
        return;
    }
  },
  initialState,
);

export const reducer = { unmatched };
