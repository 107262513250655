import React from 'react';
import { BatchRecord, ModelRequiredInfo } from 'lib/lib';

export default (modelRequiredInfo: ModelRequiredInfo) => [
  {
    key: 'contractId',
    label: 'Contract ID',
    title: 'Contract ID',
    defaultSelectedColumn: true,
    style: { flex: '1 0 70px' },
    render: (column: BatchRecord) => column.contractId,
  },
];
