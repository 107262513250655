import batchModel from './default';
import { getTabsWithCheckBoxes } from 'models/utils/utils';
import { ModelRequiredInfo } from 'lib/lib';

export default (modelRequiredInfo: ModelRequiredInfo) => {
  const defaultBatchModel = batchModel(modelRequiredInfo);

  const tabsWithCheckboxes = getTabsWithCheckBoxes(defaultBatchModel);
  return {
    tabs: defaultBatchModel.tabs,
    ...tabsWithCheckboxes,
  };
};
