import Route from 'route-parser';

import processorBatchContractResponseTilapia from 'mocks/responses/channels/tilapia/processorbatch/contractList';
import processorBatchDetailsResponseTilapia from 'mocks/responses/channels/tilapia/processorbatch/details';
import processorBatchHandshakeTilapia from 'mocks/responses/channels/tilapia/processorbatch/handshake';
import uploadBatchResponse from 'mocks/responses/batchUpload/batchUploadTilapiaBananas';

import { deleteContractResponseTilapia } from 'mocks/router/utils';

export default (request) => {
  const route = new Route(
    '/api/v1/tilapia/processorbatch(/contracts/:contractId)(?*filter)(/:batchId)(/handshake/?*handshake)',
  );
  const parameters = route.match(request.url);

  if (parameters) {
    if (parameters.filter) {
      return [200, processorBatchContractResponseTilapia()];
    }

    if (parameters.contractId) {
      if (request.method === 'delete') {
        return [200, deleteContractResponseTilapia(parameters.contractId)];
      }

      return [200, processorBatchDetailsResponseTilapia()];
    }

    if (parameters.batchId) {
      return [200, ...processorBatchDetailsResponseTilapia()];
    }

    if (parameters.handshake) {
      return [200, processorBatchHandshakeTilapia()];
    }
  }

  if (request.method === 'post') {
    return [200, uploadBatchResponse()];
  }

  return [500, `url not proceeded: ${request.url}`];
};
