import { Theme } from 'lib/lib';

export default (theme: Theme) => ({
  background: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100vw',
    height: '100vh',
    zIndex: 90,
    backgroundColor: 'rgba(255, 255, 255, 0.8)',
  },
  modal: {
    position: 'fixed',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 99,
    padding: [32, 40],
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100vw',
    maxWidth: 640,
    maxHeight: 320,
    backgroundColor: theme.colors.white,
    userSelect: 'none',
    boxShadow:
      '0px 2px 2px rgba(0, 0, 0, 0.14), 0px 3px 1px rgba(0, 0, 0, 0.12), 0px 1px 5px rgba(0, 0, 0, 0.2)',
  },
  warningIcon: {
    width: 40,
    height: 40,
    fill: '#C97D0B',
  },
  body: {
    textAlign: 'center',
    margin: [40, 0],
  },
  title: {
    fontSize: 18,
    lineHeight: '22px',
    fontWeight: 'bold',
    color: '#000000',
  },
  description: {
    fontSize: 14,
    lineHeight: '16px',
    color: 'rgba(0, 0, 0, 0.64)',
    marginTop: 16,
  },
  approveButton: {
    backgroundColor: theme.colors.primary,
    borderRadius: 8,
    border: 0,
    outline: 0,
    color: theme.colors.white,
    padding: [8, 54],
    fontWeight: 'bold',
    cursor: 'pointer',
  },
  closeButton: {
    cursor: 'pointer',
    border: 0,
    outline: 0,
    backgroundColor: 'transparent',
    color: theme.colors.primary,
    padding: [8, 54],
    fontWeight: 'bold',
    marginTop: 16,
  },
});
