import React from 'react';
// icons
import { DownloadSvg } from 'components/icons';
import { shallowEqual, useSelector } from 'react-redux';
// selectors
import { getBatches } from 'domain/batches';
import { getModel } from 'domain/common';
import { getUnmatchedStatistics } from 'domain/unmatched';
// lib
import { exportIndexBatch } from 'lib/helpers';
// types
import { AppStateType } from 'types';

interface OwnProps {
  userName?: string;
  fileName?: string;
  sheetTitle?: string;
  entity?: 'batches' | 'unmatched';
}

const mapStateToProps = (state: AppStateType) => ({
  batches: getBatches(state),
  model: getModel(state),
  unmatchedStatistics: getUnmatchedStatistics(state),
});

const DownloadExcel: React.FC<OwnProps> = ({
  entity = 'batches',
  userName,
  fileName,
  sheetTitle,
}) => {
  const { batches, model, unmatchedStatistics } = useSelector(mapStateToProps, shallowEqual);
  const exportToExcel = () => {
    if (entity === 'batches') {
      exportIndexBatch(batches, model.defaultTab, userName, fileName, sheetTitle);
    } else if (entity === 'unmatched') {
      exportIndexBatch(unmatchedStatistics, model.defaultTab, userName, fileName, sheetTitle);
    }
  };

  return <DownloadSvg className="downloadSvg" data-cy="download" onClick={exportToExcel} />;
};

export default DownloadExcel;
