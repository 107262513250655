import React, { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
// styles
import injectSheet, { WithStyles } from 'react-jss';
import stylesUnmatchedBatchesList from 'pages/dashboard/components/BatchesList/styles/stylesUnmatchedBatchesList';
// components
import BatchDatatable from 'pages/dashboard/components//BatchDatatable/BatchDatatable';
import Loader from 'components/Loaders/smallLoader';
import Chart from 'pages/dashboard/components/Chart/Chart';
// redux
import { getUnmatchedBatchesList, getIsLoading } from 'domain/unmatched';
import { getQueryParams } from 'domain/common';
import * as unmatchedActions from 'domain/unmatched/actions';
// types
import { AppStateType } from 'types';
import { IndexedObject } from 'lib/lib';

interface OwnProps extends WithStyles<typeof stylesUnmatchedBatchesList> {
  mismatchesNumber: number;
  setMismatchesNumber: Dispatch<SetStateAction<number>>;
  isActive: boolean;
}

const mapStateToProps = (state: AppStateType) => ({
  unmatchedBatchesList: getUnmatchedBatchesList(state),
  isLoading: getIsLoading(state),
  queryParams: getQueryParams(state),
});

const UnmatchedBatchesList: React.FC<OwnProps> = (props) => {
  const dispatch = useDispatch();
  const { classes, setMismatchesNumber, mismatchesNumber, isActive } = props;
  const { unmatchedBatchesList, isLoading, queryParams } = useSelector(
    mapStateToProps,
    shallowEqual,
  );
  const [lastFetchedFilters, setLastFetchedFilters] = useState<IndexedObject<string> | null>(null);
  const [totalNumber, setTotalNumber] = useState(0);
  const [unmatchedBatchTypes, setUnmatchedBatchTypes] = useState<string[] | []>([]);

  const isFiltersEqual = useMemo(() => _.isEqual(lastFetchedFilters, queryParams), [
    queryParams,
    isActive,
  ]);

  useEffect(() => {
    if (
      isActive &&
      (lastFetchedFilters && lastFetchedFilters.batchType !== queryParams.batchType)
    ) {
      setUnmatchedBatchTypes([]);
    }
  }, [queryParams.batchType, isActive]);

  useEffect(() => {
    if (!isLoading && isActive) {
      setMismatchesNumber(
        Object.keys(unmatchedBatchesList).reduce(
          (value, key) => value + unmatchedBatchesList[key].unmatchedCount,
          0,
        ),
      );
      setTotalNumber(
        Object.keys(unmatchedBatchesList).reduce(
          (value, key) => value + unmatchedBatchesList[key].totalCount,
          0,
        ),
      );
    }
  }, [unmatchedBatchesList, isActive]);

  useEffect(() => {
    if (!isLoading) {
      setUnmatchedBatchTypes(Object.keys(unmatchedBatchesList));
    }
  }, [unmatchedBatchesList]);

  useEffect(() => {
    if (
      queryParams.beginDate &&
      queryParams.endDate &&
      queryParams.batchType &&
      isActive &&
      !isFiltersEqual
    ) {
      dispatch(
        unmatchedActions.fetchUnmatchedBatchesListAction({
          scope: queryParams.batchType,
          beginDate: queryParams.beginDate,
          endDate: queryParams.endDate,
        }),
      );
      setLastFetchedFilters(queryParams);
    }
  }, [queryParams, isActive]);

  const pieChartData = useMemo(
    () => [
      { name: 'Mismatched batches', value: mismatchesNumber, fill: '#8884d8' },
      { name: 'Matched batches', value: totalNumber - mismatchesNumber, fill: '#a4de6c' },
    ],
    [mismatchesNumber, totalNumber],
  );

  if (!isActive) {
    return null;
  }

  return (
    <React.Fragment>
      <div className={classes.content}>
        {!!unmatchedBatchTypes.length
          ? (unmatchedBatchTypes as string[]).map((batchType, index) => (
              <BatchDatatable key={batchType} batchType={batchType} isOpen={index === 0} />
            ))
          : isLoading && (
              <div className={classes.loader}>
                <Loader />
              </div>
            )}
      </div>
      <div className={classes.content}>
        <Chart data={pieChartData} />
      </div>
    </React.Fragment>
  );
};

export default injectSheet(stylesUnmatchedBatchesList)(UnmatchedBatchesList);
