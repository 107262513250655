import React from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
// selectors
import { getBatchInfoByUrlOrgRoleAll } from 'domain/common';
// actions
import * as batchesActions from 'domain/batches/actions';
// types
import { AppStateType } from 'types';
import { BatchRecord } from 'lib/lib';
import { CHANNELS } from 'themes/constants';
import { getChannelName } from 'domain/env';

interface OwnProps {
  column: BatchRecord;
  buttonClassName?: string;
  channelName?: CHANNELS;
}

const mapStateToProps = (state: AppStateType) => ({
  batchInfoAll: getBatchInfoByUrlOrgRoleAll(state),
  currentChannelName: getChannelName(state),
});

const ArchiveContractButton: React.FC<OwnProps> = ({ column, buttonClassName, channelName }) => {
  const dispatch = useDispatch();
  const { batchInfoAll, currentChannelName } = useSelector(mapStateToProps, shallowEqual);

  const batchInfo = batchInfoAll[channelName || currentChannelName];

  const onArchiveBatchContract = (batchNumber: string) => () => {
    if (window.confirm(`Are you sure you want to archive batch № ${batchNumber}?`)) {
      dispatch(
        batchesActions.archiveBatchContractAction({
          endpoint: batchInfo.endpoint,
          batchNumber,
          keyField: batchInfo.contract,
        }),
      );
    }
  };

  return (
    <button
      type="button"
      onClick={onArchiveBatchContract(column[batchInfo.contract] as string)}
      className={buttonClassName || 'batch-details'}
      data-archive={column[batchInfo.contract]}
    >
      Archive
    </button>
  );
};

export default ArchiveContractButton;
