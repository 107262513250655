import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { UPLOAD_TIME_FORMAT } from 'themes/main';

// components
import TableCellEdit from 'components/Datatable/tableCellEdit';
import EditableCell from 'components/Datatable/editableCell';
// icons
import { CheckSvg, LogoutSvg } from 'components/icons';
import { getUser } from 'domain/env';

//
import injectSheet, { WithStyles } from 'react-jss';
import detailedPreviewTableStyles from 'pages/batches/components/styles/detailedPreviewTableStyles';
import { InputValue } from 'pages/batches/components/UploadCSVFile';
import { ModelObjectDefault } from 'lib/lib';
import ReactTooltipWrapper from 'components/Tooltips/ReactTooltipWrapper';

interface OwnProps extends WithStyles<typeof detailedPreviewTableStyles> {
  currentValue: InputValue;
  modelFields: ModelObjectDefault[];
  callbackSaveEditedValue: (index: number, key: string) => (value: string) => void;
}

const DetailedPreviewTable: React.FC<OwnProps> = (props) => {
  const { currentValue, modelFields, classes, callbackSaveEditedValue } = props;
  const user = useSelector(getUser, shallowEqual);
  return (
    <div className={classes.detailedViewTable}>
      <div className="fieldLabels">
        <div>Fields:</div>
        {Object.keys(currentValue.data[0]).map((th) => {
          const field = modelFields.find((f) => f.key === th);
          return <div key={th}>{field && field.label}</div>;
        })}
      </div>
      <div className="fieldsData">
        {currentValue.data.map((column, index) => (
          <div className="fieldsColumn" key={`${column}${index}`}>
            <div className="columnHead">
              {Object.values(currentValue.validationData[index]).filter((f) => !f).length ? (
                <LogoutSvg fill="red" width={15} height={15} />
              ) : (
                <CheckSvg fill="green" width={15} height={15} />
              )}
              <span>Row #{index + 1}:</span>
            </div>
            {Object.keys(column).map((key) => {
              const isChanged = String(currentValue.originData[index][key]) === String(column[key]);
              const currentModelColumn = modelFields.find((modelItem) => modelItem.key === key)!;
              const warning =
                currentModelColumn &&
                currentModelColumn.warning &&
                currentModelColumn.warning(column[key]);

              return (
                <ReactTooltipWrapper
                  shouldShowTooltip={!!warning}
                  id={`${key}${index}`}
                  tooltip={warning}
                  tooltipProps={{
                    place: 'bottom',
                    effect: 'solid',
                  }}
                  backgroundColor="#FFCC00"
                >
                  <EditableCell
                    key={key}
                    documentType="csv"
                    handleCellUpdate={callbackSaveEditedValue(index, key)}
                    column={currentModelColumn}
                    dataItem={currentValue.originData[index][key]}
                    inputValue={currentValue.originData[index][key]}
                    dateFormat={UPLOAD_TIME_FORMAT[user.role]}
                  >
                    <TableCellEdit
                      originColumn={currentValue.originData[index]}
                      originColumnValue={currentValue.originData[index][key]}
                      callbackSaveEditedValue={callbackSaveEditedValue(index, key)}
                      column={column}
                      cellKey={key}
                      key={key}
                    >
                      <>
                        {!currentValue.validationData[index][key] ? (
                          <div key={key} className="error">
                            {currentValue.originData[index][key]}
                          </div>
                        ) : (
                          <div key={key} className={isChanged ? '' : 'changed'}>
                            {isChanged ? (
                              String(column[key])
                            ) : (
                              <>
                                <div className="parsed">
                                  {String(currentValue.originData[index][key])}
                                </div>
                                <div className="handled">
                                  {currentValue.previewData[index][key]}
                                </div>
                              </>
                            )}
                          </div>
                        )}
                      </>
                    </TableCellEdit>
                  </EditableCell>
                </ReactTooltipWrapper>
              );
            })}
          </div>
        ))}
      </div>
    </div>
  );
};

export default injectSheet(detailedPreviewTableStyles)(DetailedPreviewTable);
