export default () => [
  {
    id: 10228,
    is_read: true,
    created_at: '2019-08-22T09:24:26.422Z',
    event: {
      id: 1995,
      entityType: 'ProducerBatch',
      entityId: 'A111 E999',
      statusChangedTo: 'ARCHIVED',
      eventDoneAt: '2019-08-22T09:24:26.000Z',
      actionType: 'ARCHIVED',
      actionSource: 'api',
      channel: { title: 'Tilapia', name: 'tilapia' },
      eventType: { id: 1, title: 'Batch upload', slug: 'BatchUpload' },
      eventDoneBy: {
        username: 'RegalSpringsTST',
        email: 'RegalSpringsTST@pc.t2f.io',
        org: { name: 'RegalSprings', fullname: 'Regal Springs', logo: null },
      },
    },
  },
  {
    id: 10287,
    is_read: false,
    created_at: '2019-08-23T12:28:12.099Z',
    event: {
      id: 2009,
      entityType: 'CertFile',
      entityId: 'ce618fcc-38d7-4eaf-4406-d32706709294',
      statusChangedTo: null,
      eventDoneAt: '2019-08-23T12:28:09.000Z',
      actionType: 'CREATED',
      actionSource: null,
      channel: { title: 'Tilapia', name: 'tilapia' },
      eventType: { id: 4, title: 'Certificate upload', slug: 'CertificateUpload' },
      eventDoneBy: {
        username: 'RegalSpringsTST',
        email: 'RegalSpringsTST@pc.t2f.io',
        org: { name: 'RegalSprings', fullname: 'Regal Springs', logo: null },
      },
    },
  },
];
