export default () => [
  {
    $class: 'org.powerchain.tilapia.batch.producer.ProducerDetailBatchGroupedConcept',
    batchCodeId: 'A111 E999',
    productsQuantity: 683.364,
    shippingDateMin: '2019-03-25T00:00:00.000Z',
    shippingDateMax: '2019-03-28T00:00:00.000Z',
    storageDateMin: '2019-03-22T00:00:00.000Z',
    storageDateMax: '2019-03-28T00:00:00.000Z',
    packingDateMin: '2019-03-22T00:00:00.000Z',
    packingDateMax: '2019-03-22T00:00:00.000Z',
    createdAtMin: '2019-08-22T08:21:37.233Z',
    createdAtMax: '2019-08-22T08:21:37.233Z',
    createdBy:
      'resource:org.powerchain.tilapia.member.ProducerUser#RegalSprings_8900eb90-c29b-458a-aa40-301057d9b81f',
    "modifiedBy_participant":{
      "username":null,
      "email":null,
      "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
      "org":{
        "name":"Fyffes",
        "fullname":"Fyffes",
        "logo":null
      },
      "channel":{
        "title":"Bananas",
        "name":"bananas"
      },
      "user":{
        "username":"Fyffes TST staging",
        "email":"FyffesTST@pc.t2f.io",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        }
      }
    },
    status: 'PENDING',"createdBy_participant":{
      "username":null,
      "email":null,
      "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
      "org":{
        "name":"Fyffes",
        "fullname":"Fyffes",
        "logo":null
      },
      "channel":{
        "title":"Bananas",
        "name":"bananas"
      },
      "user":{
        "username":"Fyffes TST staging",
        "email":"FyffesTST@pc.t2f.io",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        }
      }
    }
  },
];
