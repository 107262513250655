export default () => [
  {
    $class: 'org.powerchain.tilapia.batch.ImporterBatch',
    contractId: 'MNBU0265410__20180025',
    descriptionSim: 'Example DV Zakken 270gr --> Jumbo',
    productBatchIdBulk: '20180025',
    containerNumber: 'MNBU0265410',
    customerArticleNumber: 'RE.010.210.391',
    specie: 'Tilapia',
    productType: 'Fillet',
    articleDescriptionCustomer: 'Jumbo Tilapia 10x300g ASC',
    volumeBulkReceiptInKg: 11583,
    bbd: '2020-07-25T00:00:00.000Z',
    dateReceipt: '2018-09-20T00:00:00.000Z',
    poNumberJumbo: '4505758425',
    ssccPallet: '387175915102343588',
    deliveryNumber: 'U181119135',
    deliveryDate: '2018-11-26T00:00:00.000Z',
    volumeDeliveryProductInKg: 432,
    tempLogReaderMinTemp: -25,
    tempLogReaderAveTemp: -18,
    tempLogReaderMaxTemp: -18,
    subcontractAmount: 1,
    batchId: '0fd00aa7-d993-4fb0-9786-9f03774fbcf1',
    createdAt: '2019-08-20T11:23:21.878Z',
    createdBy:
      'resource:org.powerchain.tilapia.member.ImporterUser#SeafoodConnection_4d94b219-0e64-45f0-ba42-32eff7b0d7d6',
    status: 'PENDING',
    uploadSource: 'frontEndApp',
    "createdBy_participant":{
      "username":null,
      "email":null,
      "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
      "org":{
        "name":"Fyffes",
        "fullname":"Fyffes",
        "logo":null
      },
      "channel":{
        "title":"Bananas",
        "name":"bananas"
      },
      "user":{
        "username":"Fyffes TST staging",
        "email":"FyffesTST@pc.t2f.io",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        }
      }
    }
  },
  {
    $class: 'org.powerchain.tilapia.batch.ImporterBatch',
    contractId: 'MNBU0265411__20180025',
    descriptionSim: 'Example DV Zakken 270gr --> Jumbo',
    productBatchIdBulk: '20180025',
    containerNumber: 'MNBU0265411',
    customerArticleNumber: 'RE.010.210.391',
    specie: 'Tilapia',
    productType: 'Fillet',
    articleDescriptionCustomer: 'Jumbo Tilapia 10x300g ASC',
    volumeBulkReceiptInKg: 11583,
    bbd: '2020-07-25T00:00:00.000Z',
    dateReceipt: '2018-09-20T00:00:00.000Z',
    poNumberJumbo: '4505758425',
    ssccPallet: '387175915102343588',
    deliveryNumber: 'U181119135',
    deliveryDate: '2018-11-26T00:00:00.000Z',
    volumeDeliveryProductInKg: 432,
    tempLogReaderMinTemp: -25,
    tempLogReaderAveTemp: -18,
    tempLogReaderMaxTemp: -18,
    subcontractAmount: 0,
    batchId: '0fd00aa7-d993-4fb0-9786-9f03774fbcf1',
    createdAt: '2019-08-20T11:23:21.878Z',
    createdBy:
      'resource:org.powerchain.tilapia.member.ImporterUser#SeafoodConnection_4d94b219-0e64-45f0-ba42-32eff7b0d7d6',
    status: 'PENDING',
    uploadSource: 'frontEndApp',
    "createdBy_participant":{
      "username":null,
      "email":null,
      "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
      "org":{
        "name":"Fyffes",
        "fullname":"Fyffes",
        "logo":null
      },
      "channel":{
        "title":"Bananas",
        "name":"bananas"
      },
      "user":{
        "username":"Fyffes TST staging",
        "email":"FyffesTST@pc.t2f.io",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        }
      }
    }
  },
];
