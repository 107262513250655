import { Link } from 'react-router-dom';
import React from 'react';
import { renderShortDateFormat } from 'lib/renderHelpers';
import { extractCreatedBy } from 'models/utils/utils';
import { getUrl } from 'routes';
import { CHANNELS, ORGANIZATIONS } from 'themes/constants';
import { BatchRecord, ModelRequiredInfo } from 'lib/lib';

export default (modelRequiredInfo: ModelRequiredInfo) => [
  {
    key: 'vesselTkBatchId',
    label: 'Batch ID in Vessel TK',
    title: 'Batch ID in Vessel TK',
    render: (column: BatchRecord) => (
      <Link
        to={{
          pathname: getUrl('BatchContractType', {
            type: ORGANIZATIONS.CITROSUCOBR.toLowerCase(),
            contract_number: encodeURIComponent(encodeURIComponent(column.vesselTkBatchId)),
            modelType: 'contractDetails',
            channelName: CHANNELS.JUICE,
          }),
        }}
      >
        {column.vesselTkBatchId}
      </Link>
    ),
    renderExportValue: (column: BatchRecord) => column.vesselTkBatchId,
  },
  {
    key: 'vesselTkTrip',
    label: 'VESSEL TK TRIP',
    title: 'VESSEL TK TRIP',
    style: { flex: '1 0 100px' },
    render: (column: BatchRecord) => column.vesselTkTrip,
    renderExportValue: (column: BatchRecord) => column.vesselTkTrip,
  },
  {
    key: 'blDate',
    label: 'B/L date',
    title: 'B/L date',
    style: { flex: '1 0 50px' },
    render: ({ blDate }: BatchRecord) => renderShortDateFormat(blDate),
    renderExportValue: ({ blDate }: BatchRecord) => renderShortDateFormat(blDate),
  },
  {
    key: 'terminalBrId',
    label: 'Terminal Brazil ID',
    title: 'Terminal Brazil ID',
    render: (column: BatchRecord) => column.terminalBrId,
    renderExportValue: (column: BatchRecord) => column.terminalBrId,
  },
  {
    key: 'lastReceiptDateBr',
    label: 'Last Date Of Receipt Terminal Brazil',
    title: 'Last Date Of Receipt Terminal Brazil',
    render: (column: BatchRecord) => renderShortDateFormat(column.lastReceiptDateBr),
    renderExportValue: (column: BatchRecord) => renderShortDateFormat(column.lastReceiptDateBr),
  },
  {
    key: 'plantId',
    label: 'Plant ID',
    title: 'Plant ID',
    render: (column: BatchRecord) => column.plantId,
    renderExportValue: (column: BatchRecord) => column.plantId,
  },
  {
    key: 'startManDate',
    label: 'Start Manufacturing Date',
    title: 'Start Manufacturing Date',
    render: (column: BatchRecord) => renderShortDateFormat(column.startManDate),
    renderExportValue: (column: BatchRecord) => renderShortDateFormat(column.startManDate),
  },
  {
    key: 'finalManDate',
    label: 'Final Manufacturing Date',
    title: 'Final Manufacturing Date',
    render: (column: BatchRecord) => renderShortDateFormat(column.finalManDate),
    renderExportValue: (column: BatchRecord) => renderShortDateFormat(column.finalManDate),
  },
  {
    key: 'farmId',
    label: 'Farm ID',
    title: 'Farm ID',
    render: (column: BatchRecord) => column.farmId,
    renderExportValue: (column: BatchRecord) => column.farmId,
  },
  {
    key: 'harvestStartDate',
    label: 'Start Harvest Date',
    title: 'Start Harvest Date',
    render: (column: BatchRecord) => renderShortDateFormat(column.harvestStartDate),
    renderExportValue: (column: BatchRecord) => renderShortDateFormat(column.harvestStartDate),
  },
  {
    key: 'harvestFinalDate',
    label: 'Final Harvest Date',
    title: 'Final Harvest Date',
    render: (column: BatchRecord) => renderShortDateFormat(column.harvestFinalDate),
    renderExportValue: (column: BatchRecord) => renderShortDateFormat(column.harvestFinalDate),
  },
  {
    key: 'contributionPercent',
    label: '% Farm',
    title: '% Farm',
    renderExportValue: (column: BatchRecord) => column.contributionPercent,
    render: (column: BatchRecord) => column.contributionPercent,
  },
  {
    key: 'createdAt',
    label: 'Created At',
    title: 'Created At',
    render: (column: BatchRecord) => renderShortDateFormat(column.createdAt),
    renderExportValue: (column: BatchRecord) => renderShortDateFormat(column.createdAt),
  },
  {
    key: 'createdBy',
    label: 'Created By',
    title: 'Created By',
    render: (column: BatchRecord) => extractCreatedBy(column),
    renderExportValue: (column: BatchRecord) => extractCreatedBy(column),
  },
  {
    key: 'status',
    label: 'Status',
    title: 'Status',
    render: (column: BatchRecord) => column.status,
    renderExportValue: (column: BatchRecord) => column.status,
  },
  {
    key: 'uploadSource',
    label: 'Upload Source',
    title: 'Source',
    render: (column: BatchRecord) => column.uploadSource,
    renderExportValue: (column: BatchRecord) => column.uploadSource,
  },
];
