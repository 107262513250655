import React from 'react';
// components
import ModelSettingsDropdown from 'components/Dropdown/ModelSettingsDropDown';
import ApproveContractButton from 'models/utils/ApproveContractButton/ApproveContractButton';
import DownloadCSV from 'models/utils/DownloadCSV/DownloadCSV';
import { BatchRecord } from 'lib/lib';

export default {
  key: 'settingsAndActions',
  title: (
    <>
      <DownloadCSV />
      <ModelSettingsDropdown />
    </>
  ),
  style: { flex: '0 0 105px', justifyContent: 'space-evenly' },
  render: (column: BatchRecord) => <ApproveContractButton column={column} />,
};
