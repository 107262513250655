import React from 'react';
import { getChannelName } from 'domain/env';
import { shallowEqual, useSelector } from 'react-redux';
//
import { CHANNELS } from 'themes/constants';
// components
import CertificateAddOrangesJuice from 'pages/documents/certificateAddComponetns/certificateAddOrangesJuice';
import CertificateAddTilapiaBananasEggs from 'pages/documents/certificateAddComponetns/certificateAddTilapiaBananasEggs';
// types
import { AppStateType } from 'types';

const mapStateToProps = (state: AppStateType) => ({
  channelName: getChannelName(state),
});

const AddCertForm: React.FC = () => {
  const { channelName } = useSelector(mapStateToProps, shallowEqual);
  switch (channelName) {
    case CHANNELS.ORANGES:
    case CHANNELS.JUICE:
      return <CertificateAddOrangesJuice />;
    case CHANNELS.EGGS:
    case CHANNELS.BANANAS:
    case CHANNELS.TILAPIA:
      return <CertificateAddTilapiaBananasEggs />;
    default:
      return <div>There is no configured certificate upload form yet.</div>;
  }
};

export default AddCertForm;
