import React, { useState, useEffect, useCallback } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
// actions
import * as batchesActions from 'domain/batches/actions';
// selectors
import { getUser } from 'domain/env';
import { getBatch, getIsLoading } from 'domain/batches';
import { getDisplayModel, getIndexEndpoint, getModel, getParams } from 'domain/common';
// lib
import { exportExcelFile } from 'lib/helpers';
// components
import Datatable from 'components/Datatable/datatable';
import BatchTabs from './components/tabs';
import ApproveConfirmationModal from 'components/Modals/approveConfirmation';
import { ExcelSvg } from 'components/icons';
// utils
import { getUrl } from 'routes';
import { AppStateType } from 'types';
// jss
import injectSheet, { WithStyles } from 'react-jss';
import batchValidationStyles from 'pages/batches/styles/batchValidationStyles';

const BatchValidation: React.FC<WithStyles<typeof batchValidationStyles>> = ({ classes }) => {
  const dispatch = useDispatch();

  const [activeTabName, changeTab] = useState('uitslagBatches');
  const [isOpenApproveModal, setIsOpenAprroveModal] = useState(false);

  const { isLoading, model, displayModel, endpoint, params, batch, user } = useSelector(
    mapStateToProps,
    shallowEqual,
  );

  useEffect(() => {
    dispatch(
      batchesActions.fetchBatchValidationAction({
        endpoint,
        batchNumber: params.batchNumber,
      }),
    );
  }, [endpoint, params.batchNumber]);

  const toggleOpenApproveModal = useCallback(() => {
    setIsOpenAprroveModal(!isOpenApproveModal);
  }, [isOpenApproveModal]);

  const handleApproveBatch = useCallback(() => {
    const inslagBatchIds = batch.inslagBatches.map((inslagBatch) => inslagBatch.batchId);
    const uitslagBatchIds = batch.uitslagBatches.map((uitslagBatch) => uitslagBatch.batchId);

    dispatch(
      batchesActions.approveBatchValidationAction({
        endpoint,
        batchNumber: params.batchNumber,
        data: { inslagBatchIds, uitslagBatchIds },
      }),
    );
  }, [batch]);

  const onExportToExcel = useCallback(
    () =>
      exportExcelFile(
        batch,
        displayModel,
        user.username,
        `Batch Validation - Partij Nummer ${params.batchNumber}.xlsx`,
      ),
    [batch, displayModel, user.username, params.batchNumber],
  );

  return (
    <section className={classes.wrapper}>
      <div className={classes.tableHeader}>
        <div className={classes.titleSection}>
          <h3 className={classes.title}>Partij nummer: {params.batchNumber}</h3>

          <button type="button" className={classes.approveButton} onClick={toggleOpenApproveModal}>
            Approve
          </button>
        </div>

        <Link className={classes.backToListLink} to={{ pathname: getUrl('Batches') }}>
          Back to list
        </Link>

        <button type="button" className={classes.exportToExcel} onClick={onExportToExcel}>
          <ExcelSvg width={24} height={24} />
          Export To Excel
        </button>
      </div>

      <BatchTabs changeTabCallback={changeTab} activeTabName={activeTabName} tabs={model.tabs} />

      <Datatable
        data={batch[activeTabName]}
        columns={displayModel[activeTabName]}
        isLoading={isLoading}
        settingsAndActions={model && model.settingsAndActions}
      />

      <ApproveConfirmationModal
        isOpen={isOpenApproveModal}
        handleApprove={handleApproveBatch}
        handleClose={toggleOpenApproveModal}
      />
    </section>
  );
};

const mapStateToProps = (reducerState: AppStateType) => ({
  isLoading: getIsLoading(reducerState),
  model: getModel(reducerState),
  displayModel: getDisplayModel(reducerState),
  endpoint: getIndexEndpoint(reducerState),
  params: getParams(reducerState),
  batch: getBatch(reducerState),
  user: getUser(reducerState),
});

export default injectSheet(batchValidationStyles)(BatchValidation);
