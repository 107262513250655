export default (theme) => ({
  component: {
    padding: ['2em', 0],

    '& section': {
      '& header': {
        textAlign: 'left',
      },
    },
  },
  uploadExcel: {
    textAlign: 'center',
    '& .previewFile': {
      '& header': {
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        width: '100%',
        marginBottom: '0.5em',
        '& .filename': {
          marginRight: '1em',
        },
        '& svg': {
          fill: theme.colors.redD,
          cursor: 'pointer',
        },
      },
      '& .previewFileContent': {
        '& .fileTabs': {
          width: '100%',
          // overflow: 'hidden',
          position: 'relative',
          marginBottom: '1em',
          '& .fileTabsInner': {
            display: 'flex',
            flexWrap: 'nowrap',
            alignItems: 'stretch',
            justifyContent: 'space-between',
            marginBottom: 0,
            '& .fileTab': {
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexWrap: 'wrap',
              flexGrow: 1,
              padding: [10, 5],
              cursor: 'pointer',
              backgroundColor: theme.colors.primary,
              color: theme.colors.white,
              borderRadius: [10, 10, 0, 0],
              userSelect: 'none',

              '& .title': {},
              '& .errors': {
                flex: '0 0 100%',
              },
              '& .count': {
                flex: '0 0 100%',
              },
            },
            '& .fileTab.active, & .fileTab:hover': {
              backgroundColor: theme.colors.primaryHover,
            },
            '& .fileTab.errorValidation': {
              backgroundColor: theme.colors.redD,
            },
            '& .fileTab.errorValidation.active, & .fileTab.errorValidation:hover': {
              backgroundColor: theme.colors.redDHover,
            },
          },
        },
      },
    },

    '&  .helpColorsInfo': {
      display: 'flex',
      marginLeft: 20,
      textAlign: 'left',
      '& > div': {
        display: 'flex',
        marginLeft: 20,

        '&.parsed': {
          '& svg': {
            fill: 'rgba(246, 249, 32, 0.3)',
          },
        },
        '&.handled': {
          '& svg': {
            fill: 'rgba(32, 249, 144, 0.3)',
          },
        },
        '&.error': {
          '& svg': {
            fill: 'rgba(239, 16, 72, 0.3)',
          },
        },

        '& span': {
          marginLeft: 10,
        },
      },
    },
  },
  submitBtn: {
    ...theme.submitBtn,
    backgroundColor: 'rgb(58, 85, 108)',
    color: theme.colors.white,
    cursor: 'pointer',
  },
  buttonsContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',

    '& button:not(:last-child)': {
      marginBottom: 15,
    },
  },
  wideButton: {
    minWidth: 150,
  },
  themeBackground: {
    backgroundColor: theme.colors.primary,
  },
  redBackground: {
    backgroundColor: theme.colors.redDHover,
  },
  form: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    flexDirection: 'column',
  },
  formTitle: {
    width: '100%',
    marginBottom: '3em',
    textAlign: 'center',
    '& > a': {
      display: 'inline-flex',
      alignItems: 'center',
      padding: '1em',
      '& svg': {
        fill: theme.colors.primary,
      },
      '&:hover svg': {
        fill: theme.colors.primaryHover,
      },
      '& span': {
        marginLeft: '0.5em',
      },
    },
    '& .formTitleLink': {
      color: theme.colors.link,
    },
  },

  uploadCSV: {
    display: 'flex',
    alignItems: 'center',
    width: 'auto',
    border: '2px dashed rgb(143, 143, 143)',
    borderRadius: 10,
    padding: 20,
    textAlign: 'center',
    cursor: 'pointer',
    margin: [0, 'auto', '3em'],
    '& .upload-place': {
      margin: [0, 'auto'],
      '& .text': {
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        color: 'rgb(143, 143, 143)',
        '& svg': {
          width: 40,
          fill: 'rgb(143, 143, 143)',
          marginRight: '0.5em',
        },
        '& span': {
          color: theme.colors.black,
          font: {
            size: 14,
            weight: 400,
          },
        },
      },
    },
    '&.success': {
      border: '2px dashed green',
      '& .upload-place svg': {
        fill: 'green',
      },
      '& .upload-place .text': {
        color: 'green',
      },
    },
    '&.failed': {
      border: '2px dashed red',
      '& .upload-place svg': {
        fill: 'red',
      },
      '& .upload-place .text': {
        color: 'red',
      },
    },
  },
});
