export default (theme) => ({
  genericModalComponent: {
    display: 'none',
    position: 'fixed',
    zIndex: 99,
    width: '100%',
    height: '100vh !important',
    top: 0,
    left: 0,
    justifyContent: 'center',
    alignItems: 'center',
    transition: 'all linear 0.5s',
    '&.showComponent': {
      display: 'flex',
      backgroundColor: theme.colors.backgroundModal,
    },
    '& .container': {
      position: 'relative',
      justifyContent: 'center',
      maxWidth: '450px',
      height: 'auto !important',
      backgroundColor: 'white',
      border: '1px solid rgba(0,0,0,.2)',
      borderRadius: 5,
      userSelect: 'none',
      '& .modalTitleWrapper': {
        padding: '1rem',
        textAlign: 'left',
        width: '100%',
        borderBottom: '1px solid lightgray',
        '& .modalTitle': {
          fontSize: '1.75rem',
          fontWeight: 500,
        },
      },
      '& .modalContent': {
        margin: ['1rem', '4rem'],
        maxWidth: '90%',
        '& input': {
          width: '100%',
        },
        '& select': {
          width: '100%',
        },
      },
      '& .modalControl': {
        width: '100%',
      },
    },
  },
});
