import React from 'react';
import Tooltip, { TooltipProps } from 'react-tooltip';

interface OwnProps {
  shouldShowTooltip: boolean;
  id: string;
  style?: React.CSSProperties;
  tooltip?: React.ReactNode;
  tooltipProps: TooltipProps;
  backgroundColor: string;
}

const ReactTooltipWrapper: React.FC<OwnProps> = (props) => {
  const {
    shouldShowTooltip = false,
    id,
    tooltip = '',
    children,
    tooltipProps,
    backgroundColor,
    style,
  } = props;

  if (!shouldShowTooltip) {
    return <>{children}</>;
  }

  return (
    <>
      <div data-tip={tooltip} data-for={id} style={{ ...style, backgroundColor }}>
        {children}
      </div>
      <Tooltip id={id} {...tooltipProps} />
    </>
  );
};

export default React.memo(ReactTooltipWrapper);
