import { call, put, select } from 'redux-saga/effects';
import qs from 'qs';

import { showAxiosErrors } from 'lib/helpers';
import { getMeta, getNotifications } from 'domain/notifications';
import * as notificationsAction from 'domain/notifications/actions';
import Api from 'domain/api';

export function* fetchNotifications({ payload }) {
  try {
    const { data } = yield call(Api.fetchNotifications, {
      queryString: qs.stringify(payload, {
        encodeValuesOnly: true,
        addQueryPrefix: true,
      }),
    });

    yield put({
      type: notificationsAction.fetchNotificationsAction.success,
      payload: data,
    });
  } catch (errors) {
    yield showAxiosErrors(errors.response);

    yield put({
      type: notificationsAction.fetchNotificationsAction.failure,
      payload: errors,
    });
  }
}

export function* fetchNotification({ payload }) {
  try {
    const { data } = yield call(Api.fetchNotification, {
      notificationId: payload.notificationId,
    });

    yield put({
      type: notificationsAction.fetchNotificationAction.success,
      payload: data,
    });
  } catch (errors) {
    yield showAxiosErrors(errors.response);
    yield put({
      type: notificationsAction.fetchNotificationAction.failure,
      payload: errors,
    });
  }
}

export function* updateNotification({ payload, callback }) {
  try {
    const { data } = yield call(Api.updateNotification, {
      id: payload.id,
      data: payload.data,
    });

    const notifications = yield select(getNotifications);
    const notificationsUpdated = notifications.map((r) =>
      Number(data.resource.id) === Number(r.id) ? data.resource : r,
    );
    yield put({
      type: notificationsAction.updateNotificationAction.success,
      payload: notificationsUpdated,
    });

    const meta = yield select(getMeta);
    yield put({
      type: notificationsAction.updateNotificationsCountAction.success,
      payload: meta.unread - 1,
    });

    if (callback) {
      callback();
    }
  } catch (errors) {
    yield showAxiosErrors(errors.response);
    yield put({
      type: notificationsAction.updateNotificationAction.failure,
      payload: errors,
    });
  }
}
