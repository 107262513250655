export default () => [
  {
    $class: 'org.powerchain.tilapia.batch.producer.ProducerDetailBatchGroupedConcept',
    batchCodeId: 'A20718N E20718N',
    productsQuantity: 183,
    shippingDateMin: '2018-08-09T00:00:00.000Z',
    shippingDateMax: '2018-08-09T00:00:00.000Z',
    storageDateMin: '2018-07-26T00:00:00.000Z',
    storageDateMax: '2018-08-09T00:00:00.000Z',
    packingDateMin: '2018-07-26T00:00:00.000Z',
    packingDateMax: '2018-07-26T00:00:00.000Z',
    createdAt: '2019-08-22T10:35:05.423Z',
    createdBy:
      'resource:org.powerchain.tilapia.member.ProducerUser#RegalSprings_8900eb90-c29b-458a-aa40-301057d9b81f',
    status: 'PENDING',"createdBy_participant":{
      "username":null,
      "email":null,
      "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
      "org":{
        "name":"Fyffes",
        "fullname":"Fyffes",
        "logo":null
      },
      "channel":{
        "title":"Bananas",
        "name":"bananas"
      },
      "user":{
        "username":"Fyffes TST staging",
        "email":"FyffesTST@pc.t2f.io",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        }
      }
    }
  },
];
