import React from 'react';
// components
import BatchTab, { ChangeTabProps } from './tab';
// lib
import { Tab } from 'lib/lib';
// jss
import injectSheet, { WithStyles } from 'react-jss';
// styles
import batchValidationStyles from 'pages/batches/styles/batchValidationStyles';

type BatchTabsProps = {
  tabs: Tab[];
} & ChangeTabProps &
  WithStyles<typeof batchValidationStyles>;

const BatchTabs: React.FC<BatchTabsProps> = ({
  tabs,
  activeTabName,
  classes,
  changeTabCallback,
}) => {
  return (
    <div className={classes.tabs}>
      {tabs.map((tab) => (
        <BatchTab
          key={tab.originalName}
          tab={tab}
          activeTabName={activeTabName}
          changeTabCallback={changeTabCallback}
        />
      ))}
    </div>
  );
};

export default injectSheet(batchValidationStyles)(BatchTabs);
