import React from 'react';
import { compose } from 'redux';
// logo
import { PowerChainLogoSvg } from 'components/icons';
// components
import { Link } from 'react-router-dom';
// utils
import { getUrl } from 'routes';
// jss
import injectSheet, { WithStyles } from 'react-jss';
import authLayoutStyles from 'pages/layouts/AuthLayout/authLayoutStyles';

const AuthLayout: React.FC<WithStyles<typeof authLayoutStyles>> = ({ classes, children }) => (
  <div className={classes.root}>
    <div className={classes.inner}>
      <div className={classes.content}>
        <div className={classes.logo}>
          <Link to={{ pathname: getUrl('SignIn') }}>
            <PowerChainLogoSvg alt="powerchain logo" />
            <h1>PowerChain</h1>
          </Link>
          <p>Innovate the Journey</p>
        </div>

        {children}
      </div>
    </div>
  </div>
);

export default compose<React.FC>(
  React.memo,
  injectSheet(authLayoutStyles),
)(AuthLayout);
