import React from 'react';
// icons
import { CheckSvg, LogoutSvg } from 'components/icons/index';

export default () => [
  {
    title: 'ID',
    key: 'id',
    defaultSelectedColumn: true,
    style: { flex: '0 0 50px' },
    render: (item) => item.id,
  },
  {
    title: 'Name',
    key: 'name',
    defaultSelectedColumn: true,
    style: { flex: '1 1 50px' },
    render: (item) => item.username,
  },
  {
    title: 'E-mail',
    key: 'email',
    defaultSelectedColumn: true,
    style: { flex: '1 0 50px' },
    render: (item) => item.email,
  },
  {
    title: 'status',
    key: 'status',
    defaultSelectedColumn: true,
    style: { flex: '1 0 50px' },
    render: (item) => {
      if (item.status && !item.currentUserChannel) {
        return item.status;
      }
      return item.currentUserChannel ? item.currentUserChannel.status : '';
    },
  },
  {
    title: 'Admin',
    key: 'Admin',
    defaultSelectedColumn: true,
    style: { flex: '0 0 100px' },
    render: (item) =>
      item.currentUserChannel ? (
        <div>
          {item.currentUserChannel.is_admin ? (
            <CheckSvg style={{ fill: 'green' }} />
          ) : (
            <LogoutSvg style={{ fill: 'red' }} />
          )}{' '}
        </div>
      ) : (
        'N/A'
      ),
  },
  /* {
    title: 'Approved',
    key: 'approved',
    defaultSelected: true,
    style: { flex: '0 0 100px' },
    render: item => (
      <div>{ item.is_approved ? <CheckSvg style={{ fill: 'green' }} /> : <LogoutSvg style={{ fill: 'red' }} /> } </div>
    ),
  }, */
];
