import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
// actions
import * as envActions from 'domain/env/actions';
import { getNotificationConfig, getIsLoading } from 'domain/env';
import { getEventTypes } from 'domain/common';
// components
import Loader from 'components/Loaders/smallLoader';
// jss
import injectSheet from 'react-jss';
import notificationConfigStyle from 'pages/profile/notificationConfigStyle';

/*
emailTransport: false
event_type_id: 1
id: 1
slug: "BatchUpload"
title: "Batch upload"
*/

const emptyDefaultResponse = {
  emailNotificationInterval: 'hourly',
  notificationEventSettings: [
    {
      event_type_id: 1,
      emailTransport: false,
    },
    {
      event_type_id: 2,
      emailTransport: false,
    },
    {
      event_type_id: 3,
      emailTransport: false,
    },
    {
      event_type_id: 4,
      emailTransport: false,
    },
    {
      event_type_id: 5,
      emailTransport: false,
    },
    {
      event_type_id: 6,
      emailTransport: false,
    },
    {
      event_type_id: 7,
      emailTransport: false,
    },
    {
      event_type_id: 8,
      emailTransport: false,
    },
  ],
};

const NotificationConfigComponent = ({
  fetchNotificationConfig,
  updateNotificationConfig,
  eventTypes,
  serverNotificationConfig,
  isLoading,
  classes,
}) => {
  const [isTypesOpen, toggleTypesOpen] = useState(true);
  const [isSelectAll, toggleSelectAll] = useState(false);

  // merge our default response with settings naming from server
  const defaultNotificationConfig = {
    ...emptyDefaultResponse,
    notificationEventSettings: emptyDefaultResponse.notificationEventSettings.map((e) => ({
      ...e,
      ...eventTypes.find((f) => f.id === e.event_type_id),
    })),
  };

  const [notificationConfig, setNotificationConfig] = useState(defaultNotificationConfig);

  useEffect(() => {
    fetchNotificationConfig();
  }, []);

  useEffect(() => {
    if (serverNotificationConfig.hasOwnProperty('notificationEventSettings')) {
      const newNotificationConfigEvents = notificationConfig.notificationEventSettings.map((f) => {
        const emailTransport = serverNotificationConfig.notificationEventSettings.find(
          (ff) => ff.eventType.id === f.event_type_id,
        );
        return {
          ...f,
          emailTransport: emailTransport ? emailTransport.emailTransport : f.emailTransport,
        };
      });
      setNotificationConfig({
        ...notificationConfig,
        notificationEventSettings: newNotificationConfigEvents,
        emailNotificationInterval: serverNotificationConfig.emailNotificationInterval,
      });

      toggleSelectAll(
        notificationConfig.notificationEventSettings.filter((f) => !f.emailTransport).length === 0,
      );
    }
  }, [serverNotificationConfig]);

  const setInputType = (id, value) => () => {
    const handledNotificationConfig = {
      ...notificationConfig,
      notificationEventSettings: notificationConfig.notificationEventSettings.map((f) => ({
        ...f,
        emailTransport: id === f.event_type_id ? value : f.emailTransport,
      })),
    };
    setNotificationConfig(handledNotificationConfig);
    toggleSelectAll(
      handledNotificationConfig.notificationEventSettings.filter((f) => !f.emailTransport)
        .length === 0,
    );
  };

  const setInputPeriod = (e) => {
    setNotificationConfig({ ...notificationConfig, emailNotificationInterval: e.target.value });
  };

  const onSaveBtnClick = () => {
    updateNotificationConfig(notificationConfig);
    // console.log(notificationConfig);
  };

  return (
    <div className={classes.notificationConfig}>
      <div className="line" />

      <div>
        <input
          type="radio"
          id="isTypesOpenToggler"
          name="isTypesOpen"
          value="isTypesOpen"
          checked={!!isTypesOpen}
          onClick={() => {
            toggleTypesOpen(!isTypesOpen);
          }}
          onChange={() => {}}
        />
        <label htmlFor="isTypesOpenToggler">Enable Notifications via email</label>
      </div>

      {!!serverNotificationConfig && (
        <div className="notificationTypes" style={{ display: isTypesOpen ? 'block' : 'none' }}>
          <h3 className="title">Notification Types</h3>

          <div className="selectAllTypes">
            <input
              type="checkbox"
              id="notificationTypeAll"
              name="notificationTypeAll"
              checked={isSelectAll}
              onChange={() => {
                setNotificationConfig({
                  ...notificationConfig,
                  notificationEventSettings: notificationConfig.notificationEventSettings.map(
                    (f) => ({ ...f, emailTransport: !isSelectAll }),
                  ),
                });
                toggleSelectAll(!isSelectAll);
              }}
            />
            <label htmlFor="notificationTypeAll">All</label>
          </div>

          <ul>
            {notificationConfig.notificationEventSettings.map((f) => (
              <li key={f.title}>
                <input
                  type="checkbox"
                  id={`notificationType_${f.event_type_id}`}
                  name="notificationType"
                  value="notificationConfig"
                  checked={f.emailTransport}
                  onChange={setInputType(f.event_type_id, !f.emailTransport)}
                />
                <label htmlFor={`notificationType_${f.event_type_id}`}>{f.title}</label>
              </li>
            ))}
          </ul>

          <div className="periodSelector">
            {/*
            <div className="periodSelector__items">

              <div className="periodSelector__items-item">
                <input
                  id="periodically"
                  type="radio"
                  name="periodSelector"
                  onChange={() => {}}
                  checked
                />
                <div>
                  <label htmlFor="periodically">
                    Periodically
                  </label>
                  <p>Send emails about once an hour</p>
                </div>
              </div>

              <div className="periodSelector__items-item">
                <input id="instantly" type="radio" name="periodSelector" />
                <div>
                  <label htmlFor="instantly">
                    Instantly
                  </label>
                  <p>Send emails as soon as possible</p>
                </div>
              </div>
            </div>
            */}

            <div className="periodSelector__select">
              <h2>Send emails with chosen frequency:</h2>
              <select
                value={notificationConfig.emailNotificationInterval}
                onChange={setInputPeriod}
              >
                <option value="weekly">Weekly</option>
                <option value="daily">Daily</option>
                <option value="hourly">Hourly</option>
              </select>
            </div>
          </div>

          <div className="saveBtn">
            <button type="button" onClick={onSaveBtnClick}>
              {!isLoading ? 'Save Config' : <Loader />}
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

NotificationConfigComponent.propTypes = {
  classes: PropTypes.object.isRequired,
  fetchNotificationConfig: PropTypes.func.isRequired,
  updateNotificationConfig: PropTypes.func.isRequired,
  serverNotificationConfig: PropTypes.object,
  eventTypes: PropTypes.array,
  isLoading: PropTypes.bool.isRequired,
};

export default compose(
  connect(
    (state) => ({
      serverNotificationConfig: getNotificationConfig(state),
      eventTypes: getEventTypes(state),
      isLoading: getIsLoading(state),
    }),
    {
      fetchNotificationConfig: () => ({
        type: envActions.fetchNotificationConfigAction.type,
        payload: {},
      }),
      updateNotificationConfig: (notificationConfig) => ({
        type: envActions.updateNotificationConfigAction.type,
        payload: notificationConfig,
      }),
    },
  ),
  injectSheet(notificationConfigStyle),
)(NotificationConfigComponent);
