import React, { useMemo } from 'react';
import Layout from 'components/Form/Layout';
// jss
import injectSheet, { WithStyles } from 'react-jss';
import { inputsStyles } from 'components/Form/Layout/styles';
import { WrappedFieldInputProps, WrappedFieldProps } from 'redux-form';

interface OwnProps
  extends WrappedFieldProps,
    WrappedFieldInputProps,
    WithStyles<typeof inputsStyles> {
  className?: string;
  title?: string;
  disabled?: boolean;
  style?: object;
}

const Textarea: React.FC<OwnProps> = (props) => {
  const { input, classes, className, title, style = {}, meta, disabled, ...rest } = props;

  const isError = useMemo(() => meta.touched && meta.error, [meta]);

  return (
    <Layout title={title} meta={meta} id={input.name} style={style}>
      <textarea
        id={input.name}
        className={`${classes.textarea} ${className || classes.control} ${isError &&
          classes.controlError}`}
        disabled={disabled}
        {...input}
        {...rest}
      />
    </Layout>
  );
};

export default injectSheet(inputsStyles)(Textarea);
