import { Theme } from 'lib/lib';

export default (theme: Theme) => ({
  label: {
    outline: 'none',
    cursor: 'pointer',
  },
  labelError: {
    borderColor: `#dc233b !important`,
  },
  error: {
    position: 'absolute',
    top: -20,
    right: 0,
    color: theme.colors.error,
    whiteSpace: 'nowrap',
  },
  item: {
    maxWidth: 180,
    overflowWrap: 'break-word',
  },
});
