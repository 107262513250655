import contractTabModel from 'models/refresco/juice/tabs/contractTabModel';
import { CHANNELS, ORGANIZATIONS } from 'themes/constants';
import { defaultContractSettingsAndActionsCSV } from 'models/utils/utils';
import { ModelRequiredInfo } from 'lib/lib';

export default (modelRequiredInfo: ModelRequiredInfo) => ({
  tabs: [
    {
      modelName: 'defaultTab',
      originalName: ORGANIZATIONS.REFRESCO,
      title: ORGANIZATIONS.REFRESCO,
    },
  ],
  defaultTab: contractTabModel(modelRequiredInfo),
  settingsAndActions: defaultContractSettingsAndActionsCSV(CHANNELS.JUICE),
});
