import defaultTabModel from 'models/sim/eggs/tabs/defaultTabModel';
import { CHANNELS, ORGANIZATIONS } from 'themes/constants';
import archivedModelFields from 'models/archivedModelFields';
import { defaultContractSettingsAndActionsCSV } from 'models/utils/utils';
import { ModelRequiredInfo } from 'lib/lib';

export default (modelRequiredInfo: ModelRequiredInfo) => {
  const defaultArchivedTabModel = [...defaultTabModel(modelRequiredInfo), ...archivedModelFields()];
  return {
    tabs: [
      {
        modelName: 'defaultTab',
        originalName: ORGANIZATIONS.SIM,
        title: ORGANIZATIONS.SIM,
      },
    ],
    defaultTab: defaultArchivedTabModel,
    settingsAndActions: defaultContractSettingsAndActionsCSV(CHANNELS.EGGS),
  };
};
