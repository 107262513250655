import React from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
// redux
import * as uiActions from 'domain/ui/actions';
import * as documentActions from 'domain/documents/actions';
import { getChannelName } from 'domain/env';
import { getQueryParams } from 'domain/common';
// types
import { BATCH_STATUS, CHANNELS } from 'themes/constants';
import { AppStateType } from 'types';
import { Certificate } from 'lib/lib';
// utils
import AC from 'domain/AC';
// components
import ApproveCertFrom from 'pages/documents/Forms/approveCertForm';

interface OwnProps {
  column: Certificate;
  className?: string;
}

const mapStateToProps = (state: AppStateType) => ({
  channelName: getChannelName(state),
  queryParams: getQueryParams(state),
});

const ApproveCertificateButton: React.FC<OwnProps> = (props) => {
  const dispatch = useDispatch();
  const { channelName, queryParams } = useSelector(mapStateToProps, shallowEqual);
  const { column, className = 'approve-document' } = props;

  const approveDocument = (certId: string) => (payload: {
    approveReason: string;
    status: string;
    approveMsg?: string;
  }) => {
    dispatch(documentActions.approveDocumentAction({ certId, data: payload }));
    dispatch({ type: uiActions.uiModalAction.failure });
  };

  const isAbleToApprove = () => {
    const canApprove =
      AC.USER.CAN.APPROVE_CERTIFICATE && queryParams.status !== BATCH_STATUS.ARCHIVED;
    if (channelName === CHANNELS.BANANAS) {
      // BANANAS SIM CAN'T APPROVE REJECTED CERTIFICATES SO FAR
      return canApprove && column.status !== BATCH_STATUS.REJECTED;
    } else {
      return canApprove;
    }
  };

  const openModal = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    const initialValues = {
      approveReason: column.status as string,
      approveMsg: column.approveMsg && (column.approveMsg as string).trim(),
    };
    dispatch(
      uiActions.uiModalAction({
        title: 'Select your answer',
        body: (
          <ApproveCertFrom
            callback={approveDocument(column.certId)}
            initialValues={initialValues}
          />
        ),
      }),
    );
  };

  return (
    <>
      {isAbleToApprove() && (
        <button type="button" className={className} onClick={openModal}>
          Answer
        </button>
      )}
    </>
  );
};

export default React.memo(ApproveCertificateButton);
