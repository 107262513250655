import React from 'react';
import { renderShortDateFormat } from 'lib/renderHelpers';
import { extractCreatedBy } from 'models/utils/utils';
import { BatchRecord, ModelRequiredInfo } from 'lib/lib';

export default (modelRequiredInfo: ModelRequiredInfo) => [
  {
    key: 'vesselTkBatchId',
    label: 'Batch ID in Vessel TK',
    title: 'Batch ID in Vessel TK',
    // defaultSelectedBatchContractColumn: true,
    style: { flex: '1 0 70px' },
    render: (column: BatchRecord) => column.vesselTkBatchId,
    renderExportValue: (column: BatchRecord) => column.vesselTkBatchId,
  },
  {
    key: 'vesselTkTrip',
    label: 'VESSEL TK TRIP',
    title: 'VESSEL TK TRIP',
    style: { flex: '1 0 100px' },
    render: (column: BatchRecord) => <span>{column.vesselTkTrip}</span>,
    renderExportValue: (column: BatchRecord) => column.vesselTkTrip,
  },
  {
    key: 'blDate',
    label: 'B/L date',
    title: 'B/L date',
    style: { flex: '1 0 50px' },
    render: ({ blDate }: BatchRecord) => renderShortDateFormat(blDate),
    renderExportValue: ({ blDate }: BatchRecord) => renderShortDateFormat(blDate),
  },
  {
    key: 'terminalBrId',
    label: 'Termainal Brazil ID',
    title: 'Termainal Brazil ID',
    defaultSelectedBatchContractColumn: true,
    style: { flex: '1 0 30px' },
    renderExportValue: (column: BatchRecord) => column.terminalBrId,
    render: (column: BatchRecord) => column.terminalBrId,
  },
  {
    key: 'lastReceiptDateBr',
    label: 'Last Date Of Receipt Terminal Brazil',
    title: 'Last Date Of Receipt Terminal Brazil',
    defaultSelectedBatchContractColumn: true,
    style: { flex: '1 0 100px' },
    render: (column: BatchRecord) => renderShortDateFormat(column.lastReceiptDateBr),
    renderExportValue: (column: BatchRecord) => renderShortDateFormat(column.lastReceiptDateBr),
  },
  {
    key: 'plantId',
    label: 'Plant ID',
    title: 'Plant ID',
    defaultSelectedBatchContractColumn: true,
    style: { flex: '1 0 30px' },
    render: (column: BatchRecord) => column.plantId,
    renderExportValue: (column: BatchRecord) => column.plantId,
  },
  {
    key: 'finalManDate',
    label: 'Final Manufacturing Date',
    title: 'Final Manufacturing Date',
    defaultSelectedBatchContractColumn: true,
    style: { flex: '1 0 100px' },
    render: (column: BatchRecord) => renderShortDateFormat(column.finalManDate),
    renderExportValue: (column: BatchRecord) => renderShortDateFormat(column.finalManDate),
  },
  {
    key: 'startManDate',
    label: 'Start Manufacturing Date',
    title: 'Start Manufacturing Date',
    defaultSelectedBatchContractColumn: true,
    style: { flex: '1 0 100px' },
    render: (column: BatchRecord) => renderShortDateFormat(column.startManDate),
    renderExportValue: (column: BatchRecord) => renderShortDateFormat(column.startManDate),
  },
  {
    key: 'farmId',
    label: 'Farm ID',
    title: 'Farm ID',
    defaultSelectedBatchContractColumn: true,
    style: { flex: '1 0 30px' },
    render: (column: BatchRecord) => column.farmId,
    renderExportValue: (column: BatchRecord) => column.farmId,
  },
  {
    key: 'contributionPercent',
    label: '% Farm',
    title: '% Farm',
    defaultSelectedBatchContractColumn: true,
    style: { flex: '1 0 30px' },
    render: (column: BatchRecord) => column.contributionPercent,
    renderExportValue: (column: BatchRecord) => column.contributionPercent,
  },
  {
    key: 'createdAt',
    label: 'Created At',
    title: 'Created At',
    style: { flex: '1 0 60px' },
    renderExportValue: (column: BatchRecord) => column.createdAt,
    render: (column: BatchRecord) => renderShortDateFormat(column.createdAt),
  },
  {
    key: 'createdBy',
    label: 'Created By',
    title: 'Created By',
    style: { flex: '1 0 60px' },
    renderExportValue: (column: BatchRecord) => extractCreatedBy(column),
    render: (column: BatchRecord) => extractCreatedBy(column),
  },
  {
    key: 'status',
    label: 'Status',
    title: 'Status',
    defaultSelectedBatchContractColumn: true,
    style: { flex: '1 0 50px' },
    render: (column: BatchRecord) => column.status,
    renderExportValue: (column: BatchRecord) => column.status,
  },
  {
    key: 'uploadSource',
    label: 'Upload Source',
    title: 'Source',
    defaultSelectedBatchContractColumn: true,
    style: { flex: '1 0 50px' },
    render: (column: BatchRecord) => column.uploadSource,
    renderExportValue: (column: BatchRecord) => column.uploadSource,
  },
];
