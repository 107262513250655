export default () => ({
  container: {
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    userSelect: 'none',
  },
  messageContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    width: 290,
    alignItems: 'center',
  },
  textContainer: {
    fontSize: 14,
    color: '#2756b2',
    textAlign: 'left',
  },
  refreshButton: {
    transition: 'all .2s linear',
    padding: 2,
    cursor: 'pointer',
    '&:hover': {
      transform: 'rotate(45deg)',
      transformOrigin: 'center center',
    },
  },
});
