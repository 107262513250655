import {asyncAction, syncAction} from 'lib/actions';

export const fetchUsersAction = asyncAction('admin/FETCH_USERS');
export const deleteUserAction = asyncAction('admin/DELETE_USER');
export const approveAdminUserAction = asyncAction('admin/APPROVE_ADMIN_USER_ACTION');
export const suspendAdminUserAction = asyncAction('admin/SUSPEND_ADMIN_USER_ACTION');

export const fetchInvitationsAction = asyncAction('admin/FETCH_INVITATIONS');
export const createInvitationAction = asyncAction('admin/FETCH_INVITATION_CREATE');
export const updateInvitationAction = asyncAction('admin/FETCH_INVITATION_UPDATE');

export const resendInvitationAction = asyncAction('admin/FETCH_INVITATION_RESEND');

export const setUsersFilterAction = syncAction('admin/SET_USERS_FILTER');
