import Route from 'route-parser';

import { getCertificates } from 'mocks/responses/certificates';
import {getUserFromRequest } from "../../../usersUtils";

export default (request) => {

  const {channel} = request;
  const user = getUserFromRequest(request);
  const routeq = new Route('/api/v1/documents(/:documentId)(?*filter)');
  const parameters = routeq.match(request.url);
  const { method } = request;


  switch (method) {
    case 'post': {
      return [
        200,
        {
          $class: 'org.powerchain.tilapia.member.Cert',
          certId: '4f29a7b3-975d-4368-9338-ae92955ac3a0',
          owner: 'resource:org.powerchain.tilapia.member.Org#SeafoodConnection',
          uploadedBy:
            'resource:org.powerchain.tilapia.member.ImporterUser#SeafoodConnection_4d94b219-0e64-45f0-ba42-32eff7b0d7d6',
          uploadedAt: '2019-08-21T06:19:34.057Z',
          status: 'PENDING',
          documentType: 'ASC CoC',
          documentNumber: '123',
          auditDate: '2019-08-01T00:00:00.000Z',
          entityCertified: 'Packer',
          entityId: '123',
          from: '2019-08-01T00:00:00.000Z',
          until: '2019-08-02T00:00:00.000Z',
          filename: '123',
          fileUrl: '232',
          filehash: 'e38d2678-189c-4e16-bb4f-275dcc2d629c',
          filesize: 123,
        },
      ];
    }

    default: {
      if (parameters) {
        if (parameters.documentId) {
          return [
            200,
            {
              $class: 'org.powerchain.tilapia.member.Cert',
              certId: parameters.documentId,
              owner: 'resource:org.powerchain.tilapia.member.Org#SeafoodConnection',
              uploadedBy:
                'resource:org.powerchain.tilapia.member.ImporterUser#SeafoodConnection_4d94b219-0e64-45f0-ba42-32eff7b0d7d6',
              uploadedAt: '2019-08-21T06:19:34.057Z',
              status: 'PENDING',
              documentType: 'ASC CoC',
              documentNumber: '123',
              auditDate: '2019-08-01T00:00:00.000Z',
              entityCertified: 'Packer',
              entityId: '123',
              from: '2019-08-01T00:00:00.000Z',
              until: '2019-08-02T00:00:00.000Z',
              filename: '123',
              fileUrl: '232',
              filehash: 'e38d2678-189c-4e16-bb4f-275dcc2d629c',
              filesize: 123,
            },
          ];
        }
        if (parameters.filter) {
          return [200, getCertificates(user,channel,parameters.filter.includes('ARCHIVED'))];
        }
      }
    }
  }

  return [500, `url not proceeded: ${request.url}`];
};
