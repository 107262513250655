export default (theme) => ({
  dataTableViewTelapiaBatch: {
    overflow: 'hidden',
    width: '100%',
    '& > .wrapper': {
      overflowX: 'auto',
      width: 'auto',
      maxWidth: 'auto',
      paddingBottom: 15,
      '& .dataTableHead': {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'stretch',
        justifyContent: 'space-between',
        margin: 0,
        borderRadius: 0,
        textAlign: 'left',
        font: {
          size: theme.fontSizes.sm,
        },
        '& > div': {
          display: 'flex',
          flexGrow: 1,
          alignItems: 'center',
          justifyContent: 'center',
          textAlign: 'center',
          padding: [10, 0],
          color: theme.colors.white,
          backgroundColor: theme.colors.primaryHover,
        },
        '& .downloadSvg': {
          fill: theme.colors.white,
          width: 24,
          cursor: 'pointer',
        },
      },
      '& .dataTableRow': {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'stretch',
        justifyContent: 'space-between',
        margin: 0,
        padding: 0,
        '&:nth-of-type(odd) > div': {
          backgroundColor: theme.colors.background,
          borderBottom: '1px solid rgb(222, 222, 222)',
        },
        '&:nth-of-type(even) > div': {
          backgroundColor: theme.colors.white,
          borderBottom: '1px solid rgb(222, 222, 222)',
        },
        '& > div': {
          display: 'flex',
          fontSize: theme.fontSizes.sm,
          justifyContent: 'center',
          alignItems: 'center',
          textAlign: 'center',
          padding: [10, 5],
          borderLeft: '1px solid rgb(222, 222, 222)',
          '&:last-child': {
            borderRight: '1px solid rgb(222, 222, 222)',
          },
          '& img': {
            maxWidth: 30,
          },
          '& svg': {
            width: 24,
            fill: theme.colors.primary,
          },
          '& a.contract, & div.contract': {
            color: theme.colors.link,
          },
          '& a.batch-details': {
            color: theme.colors.white,
          },
          '& a.batch-archive': {
            color: theme.colors.white,
          },
          '& .sim svg': {
            fill: theme.colors.grey,
          },
          '& .warning': {
            '& svg': {
              width: 15,
              fill: theme.colors.warning,
              marginLeft: 5,
            },
          },
          '& .sim.approved svg': {
            fill: theme.colors.primary,
          },
          '& .batch-details, & .batch-archive, & .approve-batch, & .approve-document': {
            backgroundColor: theme.colors.primary,
            color: theme.colors.white,
            padding: [5, 10],
            borderRadius: 10,
            border: 0,
            transition: 'background-color 0.5s ease',
            outline: 0,
            cursor: 'pointer',
            margin: [0, 1],
            '&:hover': {
              backgroundColor: theme.colors.primaryHover,
              textDecoration: 'none',
            },
          },
          '& .batch-archive': {
            backgroundColor: 'rgb(95, 98, 115)',
          },
          '& .square-button': {
            padding: [6, 20],
            textAlign: 'center',
            outline: 0,
            color: theme.colors.black,
            cursor: 'pointer',
            border: {
              width: 0,
              radius: 0,
              style: 'solid',
            },
            backgroundColor: theme.colors.white,
            font: {
              size: theme.fontSizes.md,
              style: 'normal',
            },
            boxShadow: 'rgba(0, 0, 0, 0.2) 0px 1px 2px 0px',
            marginLeft: 10,
            '&:hover': {
              backgroundColor: 'rgb(250, 250, 250)',
            },
          },
          '& .bold': {
            fontWeight: 700,
            fontFamily: 'Arial',
          },
        },
      },
    },
  },
  loader: { ...theme.components.loader },
});
