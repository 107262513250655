import React from 'react';
//
import { compose } from 'redux';
import { connect } from 'react-redux';

import { getUnmatchedBCs, getIsLoading } from 'domain/unmatched';
import { getUser } from 'domain/env';
// actions
import * as unmatchedBCActions from 'domain/unmatched/actions';
// jss
import injectSheet from 'react-jss';
import dashboardOldStyles from 'pages/dashboard/styles/dashboardOldStyles';
//
import SimpleFrame from 'components/Frames/SimpleFrame';
//
import Loader from 'components/Loaders/smallLoader';
import { AppStateType } from 'types';

interface OwnProps {
  classes: { [className: string]: string };
  isLoading: boolean;
  unmatchedBCs: any;
  fetchUnmatchedBatchContract: () => void;
}

// THIS IS OUTDATED COMPONENT. REMOVE THIS ASAP
class DashboardOLD extends React.PureComponent<OwnProps> {
  componentDidMount() {
    const { fetchUnmatchedBatchContract } = this.props;
    fetchUnmatchedBatchContract();
  }

  render() {
    const { classes, unmatchedBCs, isLoading } = this.props;

    return (
      <section className={classes.component}>
        <SimpleFrame
          title={<span>Unmatched batch contracts</span>}
          stylesBody={{ padding: '5px 0' }}
        >
          {unmatchedBCs &&
            Object.keys(unmatchedBCs).map((k) => (
              <div className={classes.listItem} key={k}>
                <h3>{k}</h3>
                {unmatchedBCs[k] && (
                  <ul>
                    {unmatchedBCs[k].map((b: any) => (
                      <li key={b}>
                        <span>
                          {b instanceof Object ? (
                            <span>
                              Packer Gln: {b.glnSupplier}, Tht Date From: {b.batchnumber}
                            </span>
                          ) : (
                            b
                          )}
                        </span>
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            ))}

          {isLoading && (
            <div className={classes.loader}>
              <Loader />
            </div>
          )}
        </SimpleFrame>
      </section>
    );
  }
}

export default compose<React.ComponentType>(
  connect(
    (state: AppStateType) => ({
      unmatchedBCs: getUnmatchedBCs(state),
      user: getUser(state),
      isLoading: getIsLoading(state),
    }),
    {
      fetchUnmatchedBatchContract: (data: unknown) => ({
        type: unmatchedBCActions.fetchUnmatchedBatchContractsAction.type,
        payload: data,
      }),
    },
  ),
  injectSheet(dashboardOldStyles),
)(DashboardOLD);
