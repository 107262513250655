import { Theme } from 'lib/lib';

export default (theme: Theme) => ({
  channelSwitcher: {
    '& .channels': {
      position: 'relative',
      userSelect: 'none',
      '& .currentChannelIcon': {
        '& > svg': {
          fill: theme.colors.mainMenuTextColor,
          marginLeft: 5,
          width: 20,
          height: 20,
          cursor: 'pointer',
          '&.arrow': {
            width: 12,
            margin: 0,
          },
        },
      },
      '& .channelSelect': {
        zIndex: 99998,
        display: 'none',
        position: 'absolute',
        top: 20,
        left: 'auto',
        right: 0,
        backgroundColor:
          theme.colors.primaryHover === theme.colors.accent
            ? theme.colors.primary
            : theme.colors.primaryHover,
        borderRadius: 5,
        boxShadow: [1, 1, 3, theme.colors.white],
        overflow: 'hidden',
        '&.isOpen': {
          display: 'block',
        },
        '& > div': {
          display: 'flex',
          padding: [5, 10],
          cursor: 'pointer',
          '& > svg': {
            fill: theme.colors.white,
            width: 20,
            height: 20,
            marginRight: 10,
          },
          '& > span': {
            color: theme.colors.white,
            fontWeight: 'normal',
          },
          '&:hover': {
            backgroundColor: theme.colors.accent,
          },
        },
      },
    },
  },
});
