import React, { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
// styles
import injectSheet, { WithStyles } from 'react-jss';
import stylesStatistics from 'pages/dashboard/components/Statistics/styles/stylesStatistics';
// redux
import { getIsLoading, getUnmatchedStatistics } from 'domain/unmatched';
import { getDisplayModel, getModel, getQueryParams } from 'domain/common';
import * as unmatchedActions from 'domain/unmatched/actions';
// components
import Datatable from 'components/Datatable/datatable';
// types
import { IndexedObject } from 'lib/lib';
import { AppStateType } from 'types';

interface OwnProps extends WithStyles<typeof stylesStatistics> {
  mismatchesNumber: number;
  setMismatchesNumber: Dispatch<SetStateAction<number>>;
  isActive: boolean;
}

const mapStateToProps = (state: AppStateType) => ({
  isLoading: getIsLoading(state),
  unmatchedStatistics: getUnmatchedStatistics(state),
  displayModel: getDisplayModel(state),
  model: getModel(state),
  queryParams: getQueryParams(state),
});

const Statistics: React.FC<OwnProps> = (props) => {
  const dispatch = useDispatch();
  const { isActive, setMismatchesNumber, classes } = props;
  const [lastFetchedFilters, setLastFetchedFilters] = useState<IndexedObject<string> | null>(null);
  const { isLoading, unmatchedStatistics, displayModel, model, queryParams } = useSelector(
    mapStateToProps,
    shallowEqual,
  );

  const isRequestDataEqual = useMemo(
    () =>
      lastFetchedFilters &&
      lastFetchedFilters.beginDate === queryParams.beginDate &&
      lastFetchedFilters.endDate === queryParams.endDate &&
      lastFetchedFilters.batchType === 'all',
    [queryParams, isActive],
  );

  useEffect(() => {
    if (!isLoading && isActive) {
      setMismatchesNumber(Object.keys(unmatchedStatistics).length);
    }
  }, [unmatchedStatistics, isActive]);

  useEffect(() => {
    if (
      queryParams.beginDate &&
      queryParams.endDate &&
      queryParams.batchType &&
      isActive &&
      !_.isEqual(lastFetchedFilters, queryParams) &&
      !isRequestDataEqual
    ) {
      dispatch(
        unmatchedActions.fetchUnmatchedStatisticsAction({
          scope: queryParams.batchType,
          beginDate: queryParams.beginDate,
          endDate: queryParams.endDate,
        }),
      );
      setLastFetchedFilters(queryParams);
    }
  }, [queryParams, isActive]);

  if (!isActive) {
    return null;
  }

  return (
    <div className={classes.statistics}>
      <Datatable
        columns={displayModel}
        settingsAndActions={model.settingsAndActions}
        data={unmatchedStatistics}
        isTableLoading={isLoading}
        noItemsText="No mismatches have been found"
      />
    </div>
  );
};

export default injectSheet(stylesStatistics)(Statistics);
