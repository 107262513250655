import React from 'react';
import ModelSettingsDropdown from 'components/Dropdown/ModelSettingsDropDown';
import ArchiveContractButton from 'models/utils/ArchiveContractButton/ArchiveContractButton';
import { BatchRecord } from 'lib/lib';

export default {
  key: 'settingsAndActions',
  title: <ModelSettingsDropdown />,
  style: { flex: '0 1 100px', marginLeft: 'auto' },
  render: (column: BatchRecord) => (
    <React.Fragment>
      <ArchiveContractButton column={column} />
    </React.Fragment>
  ),
};
