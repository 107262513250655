import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
// icons
import { CheckSvg, LogoutSvg, EditSvg } from 'components/icons/index';

//
import injectSheet from 'react-jss';
import tableCellEditStyles from 'components/Datatable/styles/tableCellEditStyles';

const TableCellEdit = ({ children, classes, originColumnValue, callbackSaveEditedValue }) => {
  const [isShown, setShowIcon] = useState(false);
  const [isEnterMode, setEditMode] = useState(false);
  const [inputValue, setInputValue] = useState(originColumnValue);

  const onMouseEnter = () => {
    setShowIcon(true);
  };

  const onMouseLeave = () => {
    setShowIcon(false);
  };

  const turnOnEditMode = () => {
    setEditMode(true);
  };

  const turnOffEditMode = () => {
    setEditMode(false);
  };

  const saveChangesToBatches = () => {
    callbackSaveEditedValue(inputValue);
    setEditMode(false);
  };

  return (
    <div className={classes.tableCellEdit} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      {false && isEnterMode ? (
        <>
          <input
            type="text"
            name="cellValue"
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
          />
          <div className="iconSave" onClick={saveChangesToBatches}>
            <CheckSvg width={20} height={20} style={{ fill: 'green' }} />
          </div>
          <div className="iconClose" onClick={turnOffEditMode}>
            <LogoutSvg width={18} height={18} style={{ fill: 'red' }} />
          </div>
        </>
      ) : (
        <>
          {children}
          {false && isShown && (
            <div className="iconEdit" onClick={turnOnEditMode}>
              <EditSvg width={24} height={24} />
            </div>
          )}
        </>
      )}
    </div>
  );
};

TableCellEdit.propTypes = {
  children: PropTypes.any,
  originColumnValue: PropTypes.any,
  classes: PropTypes.object.isRequired,
  callbackSaveEditedValue: PropTypes.func,
};

export default compose(
  connect(
    null,
    null,
  ),
  injectSheet(tableCellEditStyles),
)(TableCellEdit);
