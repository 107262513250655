import { fork, takeEvery } from 'redux-saga/effects';
import { makeSingIn, makeSignOut, makeSignUp } from 'domain/env';
import * as EnvActions from 'domain/env/actions';

export function* sign() {
  yield takeEvery(EnvActions.logInAction.type, makeSingIn);
  yield takeEvery(EnvActions.logOutAction.type, makeSignOut);
  yield takeEvery(EnvActions.signUpAction.type, makeSignUp);
}

export default function*() {
  yield fork(sign);
}
