export default (request) => {
  switch (request.method) {
    case 'post': {
      return [
        200,
        {
          id: 232,
          certId: 'tmp_id_28c09c2a-a4e1-4de2-ab7c-002673fa1469',
          message: 'File [webstorm.png] uploaded',
        },
      ];
    }

    default: {
      return [200];
    }
  }
};
