export default (theme) => ({
  formWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputField: {
    outline: 0,
    width: '100%',
    height: theme.authLayout.inputHeight,
    borderRadius: theme.authLayout.borderRadius,
    backgroundColor: 'transparent',
    padding: [5, 10],
    color: theme.colors.white,
    border: [1, 'solid', theme.colors.white],
    '&::placeholder': {
      color: theme.colors.white,
    },
  },
  '& .form-item': {
    marginBottom: 15,
    '&:after': {
      display: 'table',
      content: ' ',
    },
    '& select, & input': {
      lineHeight: 1.42857143,
      boxShadow: 'inset 0 1px 1px rgba(0,0,0,.075)',
      transition: 'border-color ease-in-out .15s,box-shadow ease-in-out .15s',
    },
  },

  formGroupTitle: {
    width: '100%',
    color: 'white',
    textAlign: 'left',
  },

  confirmButton: {
    minWidth: theme.authLayout.inputMinWidth,
    height: theme.authLayout.inputHeight,
    borderRadius: theme.authLayout.borderRadius,
    color: theme.colors.white,
    padding: [0, 15],
    fontSize: theme.fontSizes.sm,
    textAlign: 'center',
    backgroundColor: theme.colors.loginButton,
    border: 0,
    outline: 0,
    cursor: 'pointer',
  },
  formGroup: {
    position: 'relative',
    marginBottom: 15,
    textAlign: 'center',
    '&.password': {
      marginBottom: 0,
    },
    '&:after': {
      display: 'table',
      content: ' ',
    },
    '& select, & input': {
      lineHeight: 1.42857143,
      boxShadow: 'inset 0 1px 1px rgba(0,0,0,.075)',
      transition: 'border-color ease-in-out .15s,box-shadow ease-in-out .15s',
    },
    '& svg': {
      position: 'absolute',
      top: 7,
      right: 15,
      fill: 'rgba(255, 255, 255, 0.5)',
      width: 25,
    },
  },
  changesAcceptedBlock: {
    ...theme.components.successBlock,
    justifyContent: 'center',
    '& > div': {
      ...theme.components.successBlock['& > div'],
      color: theme.colors.white,
      flex: 'none',
    },
  },
});
