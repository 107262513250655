import { renderShortDateFormat } from 'lib/renderHelpers';
import { BatchRecord } from 'lib/lib';
import { getCreatorName } from 'models/utils/utils';

export default () => [
  {
    key: 'createdAt',
    label: 'Created At',
    title: 'Created At',
    edit: { type: 'date' },
    style: { flex: '1 0 70px' },
    defaultSelectedColumn: true,
    renderUploadPreview: () => '',
    renderExportValue: (column: BatchRecord) => column.createdAt,
    render: (column: BatchRecord) => renderShortDateFormat(column.createdAt),
    validate: [],
  },
  {
    key: 'createdBy',
    label: 'Created By',
    title: 'Created By',
    style: { flex: '1 0 50px' },
    defaultSelectedColumn: true,
    renderUploadPreview: () => '',
    renderExportValue: (record: BatchRecord) => getCreatorName(record),
    render: (record: BatchRecord) => getCreatorName(record),
    validate: [],
  },
  /*
  {
    key: 'modifiedAt',
    label: 'Archived At',
    title: 'Archived At',
    style: { flex: '1 0 100px' },
    defaultSelectedColumn: true,
    renderUploadPreview: () => {},
    renderExportValue: column => column.modifiedAt,
    render: column => renderShortDateFormat(column.modifiedAt),
    validate: [],
  },
  {
    key: 'modifiedBy',
    label: 'Archived By',
    title: 'Archived By',
    style: { flex: '1 0 100px' },
    defaultSelectedColumn: true,
    renderUploadPreview: () => {},
    renderExportValue: column => column.modifiedBy,
    render: column => getNameFromBlockchainString(column.modifiedBy),
    validate: [],
  },
  */
];
