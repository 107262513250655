import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
// redux
import { getIsUpdating } from 'domain/notifications';
import * as notificationsActions from 'domain/notifications/actions';
// icons
import Loader from 'components/Loaders/smallLoader';
import { UnreadSvg } from 'components/icons';
// types
import { Notification } from 'lib/lib';
import { AppStateType } from 'types';

interface OwnProps {
  column: Notification;
}

const mapStateToProps = (state: AppStateType) => ({
  isUpdating: getIsUpdating(state),
});

const MarkAsReadButton: React.FC<OwnProps> = (props) => {
  const dispatch = useDispatch();
  const [isCurrentNotification, setIsCurrentNotification] = useState(false);
  const { column } = props;
  const { isUpdating } = useSelector(mapStateToProps, shallowEqual);

  const markAsRead = () => {
    setIsCurrentNotification(true);
    dispatch(
      notificationsActions.updateNotificationAction({
        id: column.id,
        data: { is_read: true },
      }),
    );
  };

  useEffect(() => {
    if (!isUpdating && isCurrentNotification) {
      setIsCurrentNotification(false);
    }
  }, [isUpdating]);

  return (
    <>
      {!column.is_read && (
        <div style={{ cursor: 'pointer' }} title="Mark As Read" onClick={markAsRead}>
          {isCurrentNotification && isUpdating ? (
            <Loader theme="black" />
          ) : (
            <UnreadSvg style={{ fill: '#000' }} width={22} height={17} />
          )}
        </div>
      )}
    </>
  );
};

export default React.memo(MarkAsReadButton);
