import { peek, selector } from 'lib/selectors';
import { AdminFilter, AdminInvitation, AdminMeta, AdminUser } from 'lib/lib';
import { AppStateType } from 'types';

const adminR = (state: AppStateType) => state.admin;

export const getUsers = selector<AdminUser[]>(adminR, peek('users'));
export const getInvitations = selector<AdminInvitation[]>(adminR, peek('invitations'));
export const getUser = selector(adminR, peek('resource'));
export const getIsLoading = selector<boolean>(adminR, peek('isLoading'));
export const getMeta = selector<AdminMeta>(adminR, peek('meta'));
export const getFilter = selector<AdminFilter>(getMeta, peek('filter'));
