import React, { useMemo, useState } from 'react';
// utils
import { shallowEqual, useSelector } from 'react-redux';
// components
import DetailedPreviewTable from 'pages/batches/components/detailedPreviewTable/DetailedPreviewTable';

// icons
import { RectangleSvg } from 'components/icons';
// jss
import injectSheet, { WithStyles } from 'react-jss';
import batchAddPreviewTableStyles from 'pages/batches/components/batchAddPreviewTableStyles';
import { getModel } from 'domain/common';
import { InputValue } from 'pages/batches/components/UploadCSVFile';

interface OwnProps extends WithStyles<typeof batchAddPreviewTableStyles> {
  inputValues: InputValue[];
  fileToShow: string;
  callbackSaveEditedValue: (
    fileToShow: string,
  ) => (index: number, key: string) => (value: string) => void;
}

const BatchAddPreviewTable: React.FC<OwnProps> = (props) => {
  const { classes, inputValues, fileToShow, callbackSaveEditedValue } = props;
  const [view, setView] = useState<'detailed' | 'short'>('detailed');
  const model = useSelector(getModel, shallowEqual);

  const modelFields = useMemo(() => model.defaultTab, [model]);
  const viewModelFields = useMemo(
    () => modelFields.filter((modelField) => !!modelField.defaultPreviewBeforeUploadColumn),
    [modelFields],
  );

  const currentValue = inputValues.find((f) => f.file.name === fileToShow);

  return (
    <div className={classes.previewTable}>
      <div className="showTable">
        <div className="infoPanel">
          <div className="details">
            <div className="filename">{currentValue && currentValue.file.name}</div>
            <div className="helpColorsInfo">
              <div className="parsed">
                <RectangleSvg width={18} height={18} />
                <span>parsed value</span>
              </div>
              <div className="handled">
                <RectangleSvg width={18} height={18} />
                <span>handled value</span>
              </div>
              <div className="error">
                <RectangleSvg width={18} height={18} />
                <span>validation error</span>
              </div>
            </div>
          </div>
          <div className="viewSwitcher">
            <ul>
              <li>
                <span
                  className={`${view === 'short' ? 'active' : ''}`}
                  onClick={() => setView('short')}
                >
                  short view
                </span>
              </li>
              <li>
                <span
                  className={`${view === 'detailed' ? 'active' : ''}`}
                  onClick={() => setView('detailed')}
                >
                  detailed view
                </span>
              </li>
            </ul>
          </div>
        </div>

        {/* short */}
        {view === 'short' && (
          <div className="tableHead">
            {currentValue &&
              viewModelFields &&
              Object.keys(currentValue.data[0])
                .filter((f) =>
                  viewModelFields.map((vf) => vf.key.toLowerCase()).includes(f.toLowerCase()),
                )
                .map((th) => {
                  const field = viewModelFields && viewModelFields.find((f) => f.key === th);
                  return <div key={th}>{field && field.label}</div>;
                })}
          </div>
        )}

        {view === 'short' &&
          currentValue &&
          currentValue.data.map((trObj, trIndex) => (
            <div className="tableRow" key={trIndex}>
              {Object.keys(trObj)
                .filter((f) =>
                  viewModelFields.map((vf) => vf.key.toLowerCase()).includes(f.toLowerCase()),
                )
                .map((td) => {
                  const field = viewModelFields && viewModelFields.find((f) => f.key === td);
                  return (
                    <div key={td}>
                      {field && field.renderPreviewBeforeUpload
                        ? field.renderPreviewBeforeUpload(trObj)
                        : trObj[td]}
                    </div>
                  );
                })}
            </div>
          ))}

        {/* detailed */}
        {view === 'detailed' && currentValue && (
          <DetailedPreviewTable
            currentValue={currentValue}
            modelFields={modelFields}
            callbackSaveEditedValue={callbackSaveEditedValue(fileToShow)}
          />
        )}
      </div>
    </div>
  );
};

export default injectSheet(batchAddPreviewTableStyles)(BatchAddPreviewTable);
