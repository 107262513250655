export default () => [
  {
    $class: 'org.powerchain.tilapia.batch.ProcessorBatch',
    contractId: '871845224102',
    articleNumberConsumerProduct: '871845224102',
    articleDescriptionConsumerProduct: 'Tilapiafilet 2 st, ontdooid met kruidenzakje',
    specie: 'Tilapia',
    productType: 'Filet (skin off)',
    volumeConsumerProductInKg: 228.28,
    numberOfConsumerPackages: 878,
    processingDate: '2018-11-04T00:00:00.000Z',
    deliveryDate: '2019-01-02T00:00:00.000Z',
    thtConsumerProduct: '2018-11-12T00:00:00.000Z',
    productBatchIdBulk: '20180095',
    articleNumberBulk: '21573',
    articleDescriptionBulk: 'Tilapiafilet (TI.MAY.210.130)',
    volumeBulkInKg: 3160,
    dateReceipt: '2018-11-11T00:00:00.000Z',
    thtBulk: '2018-11-11T00:00:00.000Z',
    batchId: '07858663-a1b6-4ec1-81e6-ee74cc287fd5',
    createdAt: '2019-08-15T08:46:11.148Z',
    createdBy:
      'resource:org.powerchain.tilapia.member.ProcessorUser#Mayonna_58945e88-4654-42dd-9071-0025fbd6e840',
    status: 'PENDING',
    uploadSource: 'frontEndApp',
    subcontractAmount: 1,"createdBy_participant":{
      "username":null,
      "email":null,
      "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
      "org":{
        "name":"Fyffes",
        "fullname":"Fyffes",
        "logo":null
      },
      "channel":{
        "title":"Bananas",
        "name":"bananas"
      },
      "user":{
        "username":"Fyffes TST staging",
        "email":"FyffesTST@pc.t2f.io",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        }
      }
    }
  },
  {
    $class: 'org.powerchain.tilapia.batch.ProcessorBatch',
    contractId: '871845224101',
    articleNumberConsumerProduct: '871845224101',
    articleDescriptionConsumerProduct: 'Tilapiafilet 2 st, ontdooid met kruidenzakje',
    specie: 'Tilapia',
    productType: 'Filet (skin off)',
    volumeConsumerProductInKg: 228.28,
    numberOfConsumerPackages: 878,
    processingDate: '2018-11-04T00:00:00.000Z',
    deliveryDate: '2019-01-02T00:00:00.000Z',
    thtConsumerProduct: '2018-11-12T00:00:00.000Z',
    productBatchIdBulk: '20180096',
    articleNumberBulk: '21573',
    articleDescriptionBulk: 'Tilapiafilet (TI.MAY.210.130)',
    volumeBulkInKg: 3160,
    dateReceipt: '2018-11-11T00:00:00.000Z',
    thtBulk: '2018-11-11T00:00:00.000Z',
    batchId: '07858663-a1b6-4ec1-81e6-ee74cc287fd5',
    createdAt: '2019-08-15T08:46:11.148Z',
    createdBy:
      'resource:org.powerchain.tilapia.member.ProcessorUser#Mayonna_58945e88-4654-42dd-9071-0025fbd6e840',
    status: 'PENDING',
    uploadSource: 'frontEndApp',
    subcontractAmount: 0,"createdBy_participant":{
      "username":null,
      "email":null,
      "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
      "org":{
        "name":"Fyffes",
        "fullname":"Fyffes",
        "logo":null
      },
      "channel":{
        "title":"Bananas",
        "name":"bananas"
      },
      "user":{
        "username":"Fyffes TST staging",
        "email":"FyffesTST@pc.t2f.io",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        }
      }
    }
  },
];
