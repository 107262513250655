import Route from 'route-parser';

import processorbatchIndexListResponseJuice from 'mocks/responses/channels/juice/processorbatch/indexList';
import processorbatchContractListResponseJuice from 'mocks/responses/channels/juice/processorbatch/contractsList';
import processorbatchDetailsListResponseJuice from 'mocks/responses/channels/juice/processorbatch/detailsList';
import { deleteContractResponseJuice } from 'mocks/router/utils';
import uploadBatchResponse from 'mocks/responses/batchUpload/batchUploadEggsOranges';

export default (request) => {
  const route = new Route(
    '/api/v1/juice/processorbatch(?*filter)(/:batchId)(/contracts/:contractId)',
  );
  const parameters = route.match(request.url);

  if (parameters) {
    if (parameters.filter) {
      if (parameters.filter.includes('beginDate')) {
        return [200, processorbatchIndexListResponseJuice];
      }
    }
    if (parameters.contractId) {
      if (request.method === 'delete') {
        return [200, deleteContractResponseJuice(parameters.contractId)];
      }

      return [200, processorbatchContractListResponseJuice];
    }
    if (parameters.batchId) {
      return [200, processorbatchDetailsListResponseJuice];
    }
  }

  if (request.method === 'post') {
    return [200, uploadBatchResponse(JSON.parse(request.data))];
  }

  return [500, `url not proceeded: ${request.url}`];
};
