import React, { useRef, useEffect } from 'react';
// icons
import { WarningSvg } from 'components/icons';
// styles
import injectSheet, { WithStyles } from 'react-jss';
import approveConfirmationModalStyles from 'components/Modals/approveConfirmation/styles';

type ApproveConfirmationModalProps = {
  isOpen: boolean;
  handleApprove: () => void;
  handleClose: () => void;
} & WithStyles<typeof approveConfirmationModalStyles>;

const ApproveConfirmationModal: React.FC<ApproveConfirmationModalProps> = ({
  classes,
  isOpen,
  handleApprove,
  handleClose,
}) => {
  const modalRef = useRef(null);

  const closeModal = (event) => {
    if (event && modalRef && modalRef.current && modalRef.current.contains(event.target)) {
      return;
    }

    handleClose();
    document.removeEventListener('click', closeModal, false);
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('click', closeModal, false);
    }
  }, [isOpen]);

  return isOpen ? (
    <div className={classes.background}>
      <div className={classes.modal} ref={modalRef}>
        <WarningSvg className={classes.warningIcon} />

        <div className={classes.body}>
          <h3 className={classes.title}>This is an irreversible action.</h3>
          <p className={classes.description}>The batch status cannot be changed when approved</p>
        </div>

        <button type="button" className={classes.approveButton} onClick={handleApprove}>
          Approve
        </button>

        <button type="button" className={classes.closeButton} onClick={() => closeModal()}>
          Close
        </button>
      </div>
    </div>
  ) : null;
};

export default injectSheet(approveConfirmationModalStyles)(ApproveConfirmationModal);
