export default () => ({
  columnHeader: {
    cursor: 'pointer',
    '&:hover $arrow': {
      opacity: 1,
    },
  },
  arrow: {
    width: 15,
    height: 15,
    opacity: 0,
    marginRight: 2,
    transition: 'all .15s ease-in-out',
    // position: 'relative',
    // top: 2,
    // transition: ' .15s ease-in-out',
  },
  arrowUp: {
    transform: 'rotate(180deg)',
    // top: 0,
    // bottom: 1,
  },
  activeSort: {
    opacity: 1,
  },
});
