import React from 'react';
import { compose } from 'redux';
// jss
import injectSheet, { WithStyles } from 'react-jss';
import chooseChannelLayoutStyles from 'pages/layouts/ChooseChannelLayout/chooseChannelLayoutStyles';

const ChooseChannelLayout: React.FC<WithStyles<typeof chooseChannelLayoutStyles>> = ({
  classes,
  children,
}) => <div className={classes.root}>{children}</div>;

export default compose<React.FC>(
  React.memo,
  injectSheet(chooseChannelLayoutStyles),
)(ChooseChannelLayout);
