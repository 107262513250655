import React from 'react';
import ArchiveCertificateButton from './ArchiveCertificateButton/ArchiveCertificateButton';
import ApproveCertificateButton from './ApproveCertificateButton/ApproveCertificateButton';
import Checkbox from 'components/Checkbox';
import DownloadCSV from './DownloadCSV/DownloadCSV';
import DownloadExcel from './DownloadExcel/DownloadExcel';
import ModelSettingsDropdown from '../../components/Dropdown/ModelSettingsDropDown';
import { BatchRecord, Certificate, ModelObjectDefault, RenderModel } from 'lib/lib';
import DetailsLink from './DetailsLink/DetailsLink';
import { renderShortDateFormat } from 'lib/renderHelpers';
import DetailsCertificateLink from './DetailsCertificateLink/DetailsCertificateLink';
import ValidateBatchLink from './ValidateBatchLink/ValidateBatchLink';
import { CircleSvg } from 'components/icons';
import moment from 'moment';
import { BATCH_STATUS, CHANNELS } from 'themes/constants';

export const replaceFormattedStringWithNumberValueString = (value: string) =>
  Number(String(value || '').replace(',', '')).toLocaleString('en-US') === value
    ? value.replace(',', '')
    : value;

export const replaceScientificNumberStringWithString = (value: string) =>
  Number(String(value || ''))
    .toLocaleString('fullwide', { useGrouping: false })
    .split(',')
    .join('');

const defaultCheckbox = {
  key: 'checkbox',
  label: 'checkbox',
  title: '',
  style: { flex: '0 0 50px' },
  render: (column: BatchRecord) => <Checkbox key={column.batchId} column={column} />,
  validate: [],
  defaultSelectedColumn: true,
  defaultSelectedBatchContractColumn: true,
  isServiceField: true,
};

export const getTabsWithCheckBoxes = (defaultBatchModel: RenderModel) => {
  const tabKeys = defaultBatchModel.tabs.map(({ modelName }) => modelName);
  return tabKeys.reduce<{
    [index: string]: ModelObjectDefault[];
  }>((currentValue, tabKey) => {
    defaultBatchModel[tabKey].unshift((defaultCheckbox as unknown) as ModelObjectDefault);
    return {
      ...currentValue,
      [tabKey]: defaultBatchModel[tabKey],
    };
  }, {});
};

export const defaultContractSettingsAndActionsCSV = (channelName: CHANNELS) => ({
  key: 'settingsAndActions',
  title: (
    <React.Fragment>
      <DownloadCSV />
      <ModelSettingsDropdown />
    </React.Fragment>
  ),
  style: { flex: '1 0 105px', justifyContent: 'space-evenly' },
  render: (column: BatchRecord) => <DetailsLink column={column} channelName={channelName} />,
});

export const defaultContractSettingsAndActionsExcel = (channelName: CHANNELS) => ({
  key: 'settingsAndActions',
  title: (
    <React.Fragment>
      <DownloadExcel />
      <ModelSettingsDropdown />
    </React.Fragment>
  ),
  style: { flex: '0 1 100px', justifyContent: 'space-evenly', marginLeft: 'auto' },
  render: (column: BatchRecord) => <DetailsLink column={column} channelName={channelName} />,
});

export const defaultHandshakeSettingsAndActions = {
  key: 'settingsAndActions',
  title: <ModelSettingsDropdown />,
  style: { flex: '0 0 105px' },
  render: () => '',
};

export const defaultCertificatesSettingsAndActions = {
  key: 'settingsAndActions',
  title: (
    <React.Fragment>
      <ModelSettingsDropdown />
    </React.Fragment>
  ),
  style: { flex: '1 0 70px', justifyContent: 'space-evenly' },
  render: (column: Certificate) => (
    <React.Fragment>
      <ApproveCertificateButton column={column} />
      <ArchiveCertificateButton column={column} />
      <DetailsCertificateLink column={column} />
    </React.Fragment>
  ),
};

export const defaultBatchesSettingsAndActions = {
  key: 'settingsAndActions',
  title: '',
  style: { flex: '1 0 100px' },
  render: (column: BatchRecord) =>
    column.status === BATCH_STATUS.APPROVED ? (
      <p className="approved-text">Approved</p>
    ) : (
      <ValidateBatchLink column={column} />
    ),
};

export const extractCreatedBy = (record: any) => {
  if (record && record.createdBy_participant && record.createdBy_participant.user) {
    return record.createdBy_participant.user.username;
  }
};

enum VERIFICATION_STATUS {
  VERIFIED = 'VERIFIED',
  PENDING = 'PENDING',
}

export const getSupplierStatus = (supplier: any) => {
  if (supplier && supplier.status === VERIFICATION_STATUS.VERIFIED) {
    return (
      <>
        <CircleSvg
          size={18}
          cx={12}
          cy={12}
          fill="#4CAF50"
          style={{ width: 'auto', height: 'auto' }}
        />
        <span style={{ marginLeft: 8 }}>VERIFIED</span>
      </>
    );
  }
  return null;
};

export const getStatusSentDate = (supplier: any) => {
  if (supplier && supplier.proposalSentAt && moment(supplier.proposalSentAt).isValid()) {
    return moment(supplier.proposalSentAt).format('MMMM Do YYYY, HH:mm A');
  }
};

export const getStatusReceivedDate = (supplier: any) => {
  if (supplier && supplier.statusUpdatedAt && moment(supplier.statusUpdatedAt).isValid()) {
    return moment(supplier.statusUpdatedAt).format('MMMM Do YYYY, HH:mm A');
  }
};

export const getCreatorName = (record: BatchRecord) => {
  if (record && record.createdBy_participant && record.createdBy_participant.user) {
    return record.createdBy_participant.user.username;
  }
};

export const getModifierName = (record: BatchRecord) => {
  if (record && record.modifiedBy_participant && record.modifiedBy_participant.user) {
    return record.modifiedBy_participant.user.username;
  }
};

export const getCreatorOrgName = (record: BatchRecord) => {
  if (record && record.createdBy_participant && record.createdBy_participant.org) {
    return record.createdBy_participant.org.fullname;
  }
};

export const getModifiedOrgName = (record: BatchRecord) => {
  if (record && record.modifiedBy_participant && record.modifiedBy_participant.org) {
    return record.modifiedBy_participant.org.fullname;
  }
};

export const getModifierNameAndOrgName = (record: BatchRecord) => {
  if (record && record.createdBy && record.createdBy_participant) {
    return `${getCreatorName(record)}, ${getCreatorOrgName(record)}`;
  }

  return null;
};

export const getCreatorNameAndOrgName = (record: BatchRecord) => {
  if (record && record.createdBy && record.createdBy_participant) {
    return `${getCreatorName(record)}, ${getCreatorOrgName(record)}`;
  }

  return null;
};

export const getCreationInfoWithDate = (record: BatchRecord) => {
  if (record && record.createdBy && record.createdBy_participant) {
    return `${renderShortDateFormat(record.createdAt)}, ${getCreatorName(
      record,
    )}, ${getCreatorOrgName(record)}`;
  }

  return null;
};

export const getModificationInfoWithDate = (record: BatchRecord) => {
  if (record && record.createdBy && record.modifiedBy_participant) {
    return `${renderShortDateFormat(record.modifiedAt)}, ${getModifierName(
      record,
    )}, ${getModifiedOrgName(record)}`;
  }

  return null;
};
