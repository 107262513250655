import React from 'react';
import { Link } from 'react-router-dom';
import { shallowEqual, useSelector } from 'react-redux';
// selectors
import { getUrlOrgName } from 'domain/common';
// types
import { AppStateType } from 'types';
import { BatchRecord } from 'lib/lib';
//
import { getUrl } from 'routes';
import { CHANNELS } from 'themes/constants';

interface OwnProps {
  column: BatchRecord;
  channelName: CHANNELS;
}

const DetailsLink: React.FC<OwnProps> = ({ column, channelName }) => {
  const orgName = useSelector((state: AppStateType) => getUrlOrgName(state), shallowEqual);
  return (
    <Link
      to={{
        pathname: getUrl('BatchDetailsType', {
          type: orgName.toLowerCase(),
          batch_id: column.batchId,
          modelType: 'details',
          channelName,
        }),
      }}
      // title={column.batchId as string}
      data-batch-details={column.batchId as string}
      className="batch-details"
    >
      <span>Details</span>
    </Link>
  );
};

export default DetailsLink;
