import { Theme } from 'lib/lib';

export default (theme: Theme) => ({
  component: {
    padding: ['2em', 0],
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',

    color: 'rgb(58, 85, 108)',
    fontSize: theme.fontSizes.md,
    padding: [10, 0],
  },

  paginate: {
    margin: ['1em', 0],

    '& ul': {
      display: 'inline-block',
      padding: 0,
      borderRadius: 15,
      border: [1, 'solid', 'rgb(235, 235, 235)'],
      overflow: 'hidden',
      '& li': {
        display: 'inline-block',
        padding: 0,
        outline: 0,
        backgroundColor: 'rgb(250, 250, 250)',
        cursor: 'pointer',
        '& a': {
          display: 'inline-block',
          padding: [5, 10, 5, 10],
          outline: 0,
        },
      },
      '& li.previous': {
        display: 'inline-block',
        padding: 0,
        backgroundColor: 'rgb(235, 235, 235)',
        '& a': {
          padding: [5, 10, 5, 15],
        },
      },
      '& li.next': {
        display: 'inline-block',
        backgroundColor: 'rgb(235, 235, 235)',
        '& a': {
          padding: [5, 15, 5, 10],
        },
      },
      '& li.active': {
        '& a': {
          fontFamily: 'Arial',
          fontWeight: 'bold',
        },
      },
    },
  },
});
