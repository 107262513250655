export default [
  {
    $class: 'org.powerchain.juice.batch.ImporterBatchGroupedConcept',
    salesOrderNumber: '3200101435',
    kpiRatioAvg: 14.58,
    kgShippedTotal: 116320,
    invoiceDateMin: '2019-08-09T00:00:00.000Z',
    invoiceDateMax: '2020-06-23T07:37:43.000Z',
    receiptDateMin: '2019-02-05T00:00:00.000Z',
    receiptDateMax: '2020-06-23T07:37:49.000Z',
    vesselTkBatchId: 1,
    createdAtMin: '2020-05-08T14:26:12.483Z',
    createdAtMax: '2020-06-23T07:38:16.129Z',
    createdBy:
      'resource:org.powerchain.juice.member.ImporterUser#CitrosucoGhent_21f26251-0ebd-4973-aef5-74473868c17f',
    channelName: 'juice',
    createdBy_participant: {
      username: null,
      email: null,
      participant:
        'org.powerchain.juice.member.ImporterUser#CitrosucoGhent_21f26251-0ebd-4973-aef5-74473868c17f',
      org: { name: 'CitrosucoGhent', fullname: 'Citrosuco Ghent', logo: null },
      channel: { title: 'Juice', name: 'juice' },
      user: {
        username: 'CitrosucoGhent ADMIN staging',
        email: 'CitrosucoGhentADMIN@pc.t2f.io',
        org: { name: 'CitrosucoGhent', fullname: 'Citrosuco Ghent', logo: null },
      },
    },
  },
];
