import { renderShortDateFormat } from 'lib/renderHelpers';
import index from 'models/banafood/tabs/indexTabModel';
import { BatchRecord, ModelRequiredInfo } from 'lib/lib';
import { getModifierNameAndOrgName } from 'models/utils/utils';

export default (modelRequiredInfo: ModelRequiredInfo) => [
  ...index(modelRequiredInfo),
  {
    key: 'modifiedAt',
    label: 'Modified At',
    title: 'Modified At',
    style: { flex: '1 0 70px' },
    renderExportValue: (record: BatchRecord) => renderShortDateFormat(record.modifiedAt),
    render: (record: BatchRecord) => renderShortDateFormat(record.modifiedAt),
    defaultSelectedColumn: true,
    defaultSelectedBatchContractColumn: true,
  },
  {
    key: 'modifiedBy',
    label: 'Modified By',
    title: 'Modified By',
    style: { flex: '1 0 120px' },
    renderExportValue: (record: BatchRecord) => getModifierNameAndOrgName(record),
    render: (record: BatchRecord) => getModifierNameAndOrgName(record),
    defaultSelectedColumn: true,
    defaultSelectedBatchContractColumn: true,
  },
];
