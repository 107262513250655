export default () => ({
  $class: 'org.powerchain.egg.batch.AHBatch',
  gtin: '08718906254527',
  glnSupplier: '8711900009995',
  glnBuyer: '8710400000822',
  glnDeliveryLocation: '8710400000822',
  quantity: 104,
  deliveryDatetime: '2018-08-29T00:00:00.000Z',
  batchnumber: '2019-01-10',
  batchId: 'f5722953-999f-4229-8365-e42f5659fe87',
  createdAt: '2019-09-02T15:04:14.000Z',
  createdBy: 'resource:org.powerchain.egg.member.AHUser#AH_ac45c8d3-aba8-4433-aec2-812876211693',
  status: 'PENDING',
  uploadSource: 'frontEndApp',
  channelName: 'eggs',
  modifiedBy_participant: {
    username: null,
    email: null,
    participant:
      'org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8',
    org: {
      name: 'Fyffes',
      fullname: 'Fyffes',
      logo: null,
    },
    channel: {
      title: 'Bananas',
      name: 'bananas',
    },
    user: {
      username: 'Fyffes TST staging',
      email: 'FyffesTST@pc.t2f.io',
      org: {
        name: 'Fyffes',
        fullname: 'Fyffes',
        logo: null,
      },
    },
  },
  createdBy_participant: {
    username: null,
    email: null,
    participant:
      'org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8',
    org: {
      name: 'Fyffes',
      fullname: 'Fyffes',
      logo: null,
    },
    channel: {
      title: 'Bananas',
      name: 'bananas',
    },
    user: {
      username: 'Fyffes TST staging',
      email: 'FyffesTST@pc.t2f.io',
      org: {
        name: 'Fyffes',
        fullname: 'Fyffes',
        logo: null,
      },
    },
  },
});
