import { peek, selector } from 'lib/selectors';
import { IndexedObject, CertFile } from 'lib/lib';
import { AppStateType } from 'types';

const documents = (state: AppStateType) => state.documents;

export const getCertificates = selector(documents, peek('resources'));
export const getCertificate = selector<IndexedObject<string>>(documents, peek('resource'));
export const getCertFile = selector<CertFile>(documents, peek('certFile'));
export const getIsLoading = selector(documents, peek('isLoading'));
export const getIsFileUploading = selector(documents, peek('isFileUploading'));
