import { ORGANIZATIONS } from 'themes/constants';
import { createNotificationResponse } from 'mocks/responses/notifications/utils';

const { SIM, CITROSUCOGHENT, CITROSUCOBR, REFRESCOGMBH, KAUFLANDDE } = ORGANIZATIONS;

export default (org: ORGANIZATIONS) => {
  switch (org) {
    case CITROSUCOGHENT:
    case CITROSUCOBR:
    case REFRESCOGMBH:
    case KAUFLANDDE:
    case SIM:
    default:
      return createNotificationResponse(0, []);
  }
};
