import { fork } from 'redux-saga/effects';

import watchSign from 'sagas/sign';
import watchComponents from 'sagas/components';
import watchAdminComponents from 'sagas/admin';
import initSaga from './init';

// here is a place where all watchers define

export default function* rootSaga() {
  yield* [
    // makeAuthFetchProfileAction.type calls on <App /> onComponentMount event
    // ( e.g. runs on browser's page loads)
    // takes key from local or session storage
    // and tries to get profile (fetchServiceData), orgs, docCats (fetchServiceData) from backend

    // components watchers
    fork(watchComponents),

    // admin components watchers
    fork(watchAdminComponents),

    // watchers for signIn, signUp, signOut
    fork(watchSign),

    fork(initSaga),
  ];
}
