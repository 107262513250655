export default () => [
  {
    $class: 'org.powerchain.batch.REFRESCOBatchGroupedConcept',
    lotFinishedProduct: '1778602',
    qtyProducedPcsFinishedProductSum: 100000,
    qtyUsedLtrRawProductSum: 11770,
    productionFinishedFinishedProductMin: '2019-01-30T00:00:00.000Z',
    productionFinishedFinishedProductMax: '2019-01-30T00:00:00.000Z',
    dateCheckFcojMin: '2019-01-29T00:00:00.000Z',
    dateCheckFcojMax: '2019-01-29T00:00:00.000Z',
    ratioFcojAverage: 15.186,
    ratioFinishedProductAverage: 16.286,
    uniqLotFcojAmount: 1,
    createdAt: '2020-09-29T10:20:14.000Z',
    createdAtMin: '2020-09-29T10:20:14.000Z',
    createdAtMax: '2020-09-29T10:20:14.000Z',
    createdBy:
      'resource:org.powerchain.member.REFRESCOUser#REFRESCO_e77178d1-2504-42d5-8094-075d44465460',
    status: 'PENDING',
    uploadSource: 'api',
    createdBy_participant: {
      username: null,
      email: null,
      participant:
        'org.powerchain.member.REFRESCOUser#REFRESCO_e77178d1-2504-42d5-8094-075d44465460',
      channel: { title: 'Oranges', name: 'oranges' },
      org: { name: 'REFRESCO', fullname: 'Refresco company', logo: null },
      user: {
        username: 'REFTST staging',
        email: 'REFTST@pc.t2f.io',
        org: { name: 'REFRESCO', fullname: 'Refresco company', logo: null },
      },
    },
    channelName: 'oranges',
  },
  {
    $class: 'org.powerchain.juice.batch.ProcessorBatchGroupedConcept',
    lotFinishedProduct: '177860222',
    qtyProducedPcsFinishedProductSum: 100000,
    qtyUsedLtrRawProductSum: 11770,
    productionFinishedFinishedProductMin: '2019-01-30T00:00:00.000Z',
    productionFinishedFinishedProductMax: '2019-01-30T00:00:00.000Z',
    dateCheckFcojMin: '2019-01-29T00:00:00.000Z',
    dateCheckFcojMax: '2019-01-29T00:00:00.000Z',
    ratioFcojAverage: 15.186,
    ratioFinishedProductAverage: 16.286,
    uniqLotFcojAmount: 1,
    createdAtMin: '2020-09-25T15:08:53.887Z',
    createdAtMax: '2020-09-25T15:08:53.887Z',
    createdBy:
      'resource:org.powerchain.juice.member.ProcessorUser#REFRESCO_de6a20ca-20cf-4b62-89ea-11c822c19b19',
    createdBy_participant: {
      username: null,
      email: null,
      participant:
        'org.powerchain.juice.member.ProcessorUser#REFRESCO_de6a20ca-20cf-4b62-89ea-11c822c19b19',
      org: { name: 'REFRESCO', fullname: 'Refresco company', logo: null },
      channel: { title: 'Juice', name: 'juice' },
      user: {
        username: 'REFTST staging',
        email: 'REFTST@pc.t2f.io',
        org: { name: 'REFRESCO', fullname: 'Refresco company', logo: null },
      },
    },
    channelName: 'juice',
  },
  {
    $class: 'org.powerchain.juice.batch.ProcessorBatchGroupedConcept',
    lotFinishedProduct: '123224442',
    qtyProducedPcsFinishedProductSum: 100000,
    qtyUsedLtrRawProductSum: 11770,
    productionFinishedFinishedProductMin: '2019-01-30T00:00:00.000Z',
    productionFinishedFinishedProductMax: '2019-01-30T00:00:00.000Z',
    dateCheckFcojMin: '2019-01-29T00:00:00.000Z',
    dateCheckFcojMax: '2019-01-29T00:00:00.000Z',
    ratioFcojAverage: 15.186,
    ratioFinishedProductAverage: 16.286,
    uniqLotFcojAmount: 1,
    createdAtMin: '2020-09-25T14:37:31.141Z',
    createdAtMax: '2020-09-25T14:37:31.141Z',
    createdBy:
      'resource:org.powerchain.juice.member.ProcessorUser#REFRESCO_1c17257e-1c44-4497-8679-cc253639df2f',
    channelName: 'juice',
  },
  {
    $class: 'org.powerchain.juice.batch.ProcessorBatchGroupedConcept',
    lotFinishedProduct: '1778603',
    qtyProducedPcsFinishedProductSum: 100000,
    qtyUsedLtrRawProductSum: 11770,
    productionFinishedFinishedProductMin: '2019-01-30T00:00:00.000Z',
    productionFinishedFinishedProductMax: '2019-01-30T00:00:00.000Z',
    dateCheckFcojMin: '2019-01-29T00:00:00.000Z',
    dateCheckFcojMax: '2019-01-29T00:00:00.000Z',
    ratioFcojAverage: 15.186,
    ratioFinishedProductAverage: 16.286,
    uniqLotFcojAmount: 1,
    createdAtMin: '2020-09-25T14:20:39.522Z',
    createdAtMax: '2020-09-25T14:20:39.522Z',
    createdBy:
      'resource:org.powerchain.juice.member.ProcessorUser#REFRESCO_de6a20ca-20cf-4b62-89ea-11c822c19b19',
    createdBy_participant: {
      username: null,
      email: null,
      participant:
        'org.powerchain.juice.member.ProcessorUser#REFRESCO_de6a20ca-20cf-4b62-89ea-11c822c19b19',
      org: { name: 'REFRESCO', fullname: 'Refresco company', logo: null },
      channel: { title: 'Juice', name: 'juice' },
      user: {
        username: 'REFTST staging',
        email: 'REFTST@pc.t2f.io',
        org: { name: 'REFRESCO', fullname: 'Refresco company', logo: null },
      },
    },
    channelName: 'juice',
  },
];
