export default (theme) => ({
  previewTable: {
    '& .showTable': {
      display: 'block',
      margin: ['2em', 'auto'],
      width: '100%',

      '& > .infoPanel': {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        minHeight: 40,

        '& > .details': {
          display: 'flex',
          padding: ['1em', 0],
          '& > .filename': {
            textAlign: 'left',
          },

          '& > .helpColorsInfo': {
            display: 'flex',
            marginLeft: 20,
            textAlign: 'left',
            '& > div': {
              display: 'flex',
              marginLeft: 20,

              '&.parsed': {
                '& svg': {
                  fill: 'rgba(246, 249, 32, 0.3)',
                },
              },
              '&.handled': {
                '& svg': {
                  fill: 'rgba(32, 249, 144, 0.3)',
                },
              },
              '&.error': {
                '& svg': {
                  fill: 'rgba(239, 16, 72, 0.3)',
                },
              },

              '& span': {
                marginLeft: 10,
              },
            },
          },
        },

        '& > .viewSwitcher': {
          '& > ul': {
            padding: 0,
            '& > li': {
              display: 'inline-block',
              marginLeft: '1em',
              '& > span': {
                cursor: 'pointer',
                color: theme.colors.link,
                '&.active': {
                  textDecoration: 'underline',
                },
              },
            },
          },
        },
      },

      '& > .tableHead, & > .tableRow': {
        display: 'flex',
        justifyContent: 'space-between',
        textAlign: 'center',
        '& > div': {
          border: [1, 'solid', theme.colors.background],
          padding: 3,
          overflow: 'hidden',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        },

        '& > .fileSize': {
          flex: '0 0 135px',
          display: 'flex-index',
          minHeight: 28,
        },
        '& > .fileOptions': {
          flex: '0 0 100px',
          display: 'flex-index',
          width: 24,
          minHeight: 28,
          justifyContent: 'space-evenly',
          '& svg': {
            fill: theme.colors.primary,
            width: 24,
            cursor: 'pointer',
          },
        },
      },

      '& > .tableHead': {
        backgroundColor: 'rgb(102, 102, 102)',
        color: theme.colors.white,
        fontSize: theme.fontSizes.sm,
        '& > div': {
          flex: '1 0 50px',
        },
      },
      '& > .tableRow': {
        '&.success': {
          color: 'green',
        },
        '&.failed': {
          color: 'red',
        },
        '&:nth-child(odd)': {
          backgroundColor: theme.colors.background,
        },
        '& > div': {
          flex: '1 0 50px',
        },
      },
    },
  },
});
