import React, { useCallback, useLayoutEffect, useMemo, useState } from 'react';
import injectSheet, { WithStyles } from 'react-jss';
import uploadExcelFormStyles from 'pages/batches/components/UploadExcelFileStyles';
import readAsBinaryStringPolyfill from 'pages/batches/components/polyfills/readAsBinaryString';
import DownloadTemplateLink from 'pages/batches/components/DownloadTemplateLink';
import { ExcelSvg, LogoutSvg, RectangleSvg } from 'components/icons';
import { toast } from 'lib/helpers';
import XLSX from 'xlsx';
import { AppStateType } from 'types';
import { getChannelName, getOwnOrgName, getUser } from 'domain/env';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { getBatchInfoByUrlOrgRole, getModel } from 'domain/common';
import { IndexedObject, Tab } from 'lib/lib';
import { useExcelUpload, useExcelValidation } from 'pages/batches/components/utils';
import UploadExcelTabs from 'components/Datatable/UploadExcelTabs';
import DataTableUploadExcel2 from 'components/Datatable/DataTableUploadExcel2';
import { CHANNELS } from 'themes/constants';
import UploadWarning from 'pages/batches/components/UploadWarning';
import { UPLOAD_TIME_FORMAT } from 'themes/main';
import Loader from 'components/Loaders/smallLoader';
import { getIsLoading } from 'domain/batches';
import * as batchesActions from 'domain/batches/actions';

export type HandleParsedValue = (value: string) => string;
export type Validate = ((value: string) => { message: string; status: boolean })[];
export type UploadedParsedFile = {
  // @ts-ignore
  tabs: Tab[];
  [tab: string]: IndexedObject<string>[];
};
export type ValidationErrors = {
  [tab: string]: number;
};

const mapStateToProps = (state: AppStateType) => ({
  ownOrgName: getOwnOrgName(state),
  channelName: getChannelName(state),
  model: getModel(state),
  user: getUser(state),
  isLoading: getIsLoading(state),
  batchInfo: getBatchInfoByUrlOrgRole(state),
});

const UploadExcelFile2: React.FC<WithStyles<typeof uploadExcelFormStyles>> = ({ classes }) => {
  const dispatch = useDispatch();
  useLayoutEffect(readAsBinaryStringPolyfill, []);

  const { channelName, model, user, isLoading, batchInfo } = useSelector(
    mapStateToProps,
    shallowEqual,
  );
  const [activeTab, setActiveTab] = useState(model.tabs[0].modelName);
  const [uploadedFile, setUploadedFile] = useState<File | null>(null);
  const [uploadedParsedFile, setUploadedParsedFile] = useState<UploadedParsedFile | null>(null);
  // const [validationErrors, setValidationErrors] = useState<Record<string, number> | null>(null);

  const handleUploadedExcel = useExcelUpload(model);
  const excelValidation = useExcelValidation(model);

  const uploadFile = useCallback(() => {
    const data =
      uploadedParsedFile && uploadedParsedFile.tabs.length === 1
        ? uploadedParsedFile[uploadedParsedFile.tabs[0].modelName]
        : uploadedParsedFile;

    dispatch(batchesActions.uploadBatchesExcelAction({ data, batchEndpoint: batchInfo.endpoint }));
  }, [dispatch, uploadedParsedFile, batchInfo]);

  const readFile = useCallback(
    (file: File) =>
      new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onerror = reject;
        reader.onload = ({ target }) => resolve(target && target.result);

        reader.readAsBinaryString(file);
      }),
    [],
  );

  const uploadExcel = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files && e.target.files[0];
    if (!file) {
      return;
    }

    try {
      new Promise((resolve) => {
        toast(
          'Your file is being processed. Please wait and do not close the browser window until the process is finalized.',
          'success',
        );

        setTimeout(resolve, 1000);
      }).then(() => {
        readFile(file)
          .then((textFile) => XLSX.read(textFile, { type: 'binary', cellDates: true }))
          .then((res) => {
            const result = handleUploadedExcel(res);
            setUploadedParsedFile((result && result.uploadedParsedFile) || null);
            // setValidationErrors((result && result.validationErrors) || null);
            setUploadedFile(result ? file : null);
            console.log(result);
          });
      });
    } catch (error) {}
  }, []);

  const discardFile = () => {
    setUploadedParsedFile(null);
    // setValidationErrors(null);
    setUploadedFile(null);
  };

  const dateFormat = useMemo(() => {
    const uploadTimeFormat = UPLOAD_TIME_FORMAT[user.role];
    if (typeof uploadTimeFormat === 'string') {
      return uploadTimeFormat;
    } else if (uploadTimeFormat && uploadTimeFormat[activeTab]) {
      return uploadTimeFormat[activeTab];
    }
    return undefined;
  }, [user]);

  const validationErrors = useMemo(
    () => uploadedParsedFile && excelValidation(uploadedParsedFile),
    [excelValidation, uploadedParsedFile],
  );

  const totalErrors = useMemo(
    () =>
      validationErrors &&
      (Object.keys(validationErrors).reduce(
        (acc, key) => acc + validationErrors[key],
        0,
      ) as number),
    [validationErrors],
  );

  return (
    <section className={classes.component}>
      <div className={classes.uploadExcel}>
        {!uploadedParsedFile && (
          <form className={classes.form}>
            <div className={classes.formTitle}>
              <div> Start uploading a Excel file by clicking the button below...</div>

              {[CHANNELS.BANANAS, CHANNELS.TILAPIA].includes(channelName) && <UploadWarning />}

              <DownloadTemplateLink />
            </div>

            <label className={classes.uploadCSV}>
              <div className="upload-place">
                <div className="text">
                  <ExcelSvg width={24} height={24} />
                  <span>Upload Excel</span>
                </div>
                {/* accept=".csv" -  https://stackoverflow.com/questions/11832930/html-input-file-accept-attribute-file-type-csv */}
                <input type="file" accept=".xlsx" hidden onChange={uploadExcel} />
              </div>
            </label>
          </form>
        )}

        {!!uploadedParsedFile && (
          <div className="previewFile">
            <header>
              <span className="filename">{uploadedFile && uploadedFile.name}</span>
              <LogoutSvg width={15} height={15} onClick={discardFile} data-cy="cancelButton" />

              <div className="helpColorsInfo">
                <div className="error">
                  <RectangleSvg width={18} height={18} />
                  <span>validation error</span>
                </div>
              </div>
            </header>

            <div className="previewFileContent">
              <div className="fileTabs">
                <div className="fileTabsInner">
                  <UploadExcelTabs
                    activeTab={activeTab}
                    setActiveTab={setActiveTab}
                    validationErrors={validationErrors}
                    uploadedParsedFile={uploadedParsedFile}
                  />
                </div>

                <DataTableUploadExcel2
                  activeTab={activeTab}
                  uploadedParsedFile={uploadedParsedFile}
                  setUploadedParsedFile={setUploadedParsedFile}
                  dateFormat={dateFormat}
                />
              </div>
            </div>
          </div>
        )}

        {!!uploadedFile && (
          <div>
            {isLoading ? (
              <button
                type="button"
                className={[
                  classes.submitBtn,
                  channelName === CHANNELS.BANANAS ? classes.themeBackground : '',
                ].join(' ')}
              >
                <Loader />
              </button>
            ) : (
              !totalErrors &&
              /* case of banana buttons, they do the same, but they are two */

              (channelName === CHANNELS.BANANAS ? (
                <div className={classes.buttonsContainer}>
                  <button
                    type="button"
                    className={[
                      classes.submitBtn,
                      classes.wideButton,
                      classes.themeBackground,
                    ].join(' ')}
                    onClick={uploadFile}
                    data-cy="submitButton"
                  >
                    Submit
                  </button>
                  <button
                    type="button"
                    className={[classes.submitBtn, classes.wideButton, classes.redBackground].join(
                      ' ',
                    )}
                    onClick={discardFile}
                    data-cy="cancelButton"
                  >
                    Delete
                  </button>
                </div>
              ) : (
                <button
                  type="button"
                  className={classes.submitBtn}
                  onClick={uploadFile}
                  data-cy="submitButton"
                >
                  Register New Batch
                </button>
              ))
            )}
          </div>
        )}
      </div>
    </section>
  );
};

export default injectSheet(uploadExcelFormStyles)(UploadExcelFile2);
