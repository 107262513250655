import indexTab from 'models/certificates/bananas/tabs/indexTabModel';
import { defaultCertificatesSettingsAndActions } from 'models/utils/utils';
import { MODEL_TYPES } from 'themes/constants';

export default () => ({
  tabs: [
    {
      modelName: 'defaultTab',
      originalName: MODEL_TYPES.CERTIFICATES_LIST,
      title: MODEL_TYPES.CERTIFICATES_LIST,
    },
  ],
  defaultTab: indexTab,
  settingsAndActions: defaultCertificatesSettingsAndActions,
});
