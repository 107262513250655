import { put, call, select } from 'redux-saga/effects';
import Api from 'domain/api';
import * as unmatchedActions from 'domain/unmatched/actions';
import { showAxiosErrors } from 'lib/helpers';
import { getChannelName } from 'domain/env';

export function* fetchUnmatchedBatchContracts() {
  try {
    const { data } = yield call(Api.fetchUnmatchedBatchContracts);
    yield put({
      type: unmatchedActions.fetchUnmatchedBatchContractsAction.success,
      payload: data,
    });
  } catch (errors) {
    if (errors.response) {
      yield showAxiosErrors(errors.response);
    }
    yield put({
      type: unmatchedActions.fetchUnmatchedBatchContractsAction.failure,
      payload: errors,
    });
  }
}

export function* fetchUnmatched({ payload }) {
  try {
    const channelName = yield select(getChannelName);
    const { data } = yield call(Api.fetchUnmatched, {
      beginDate: payload.beginDate,
      endDate: payload.endDate,
      scope: payload.scope,
      channelName,
    });

    yield put({
      type: unmatchedActions.fetchUnmatchedBatchesListAction.success,
      payload: data,
    });
  } catch (errors) {
    if (errors.response) {
      yield showAxiosErrors(errors.response);
    }
    yield put({
      type: unmatchedActions.fetchUnmatchedBatchesListAction.failure,
      payload: errors,
    });
  }
}

export function* fetchUnmatchedStatistics({ payload }) {
  try {
    const channelName = yield select(getChannelName);
    const { data } = yield call(Api.fetchUnmatchedStatistics, {
      beginDate: payload.beginDate,
      endDate: payload.endDate,
      scope: payload.scope,
      channelName,
    });

    yield put({
      type: unmatchedActions.fetchUnmatchedStatisticsAction.success,
      payload: data,
    });
  } catch (errors) {
    if (errors.response) {
      yield showAxiosErrors(errors.response);
    }
    yield put({
      type: unmatchedActions.fetchUnmatchedStatisticsAction.failure,
      payload: errors,
    });
  }
}
