import React from 'react';
import { Link } from 'react-router-dom';
import { getUrl } from 'routes';
import { renderShortDateFormat, renderTwoShortDateFormat } from 'lib/renderHelpers';
import { CHANNELS, ORGANIZATIONS } from 'themes/constants';
import { BatchRecord, ModelRequiredInfo } from 'lib/lib';
import { getCreatorName } from 'models/utils/utils';

export default (modelRequiredInfo: ModelRequiredInfo) => [
  {
    key: 'articleNumberConsumerProduct',
    label: 'articleNumberConsumerProduct',
    title: 'Article Number Consumer Product',
    style: { flex: '1 0 100px' },
    defaultSelectedColumn: true,
    renderExportValue: (column: BatchRecord) => column['Article Number Consumer Product'],
    render: (column: BatchRecord) => (
      <Link
        to={{
          pathname: getUrl('BatchContractType', {
            type: ORGANIZATIONS.MAYONNA.toLowerCase(),
            contract_number: encodeURIComponent(`${column.articleNumberConsumerProduct}`),
            modelType: 'contractDetails',
            channelName: CHANNELS.TILAPIA,
          }),
        }}
      >
        {column.articleNumberConsumerProduct}
      </Link>
    ),
  },
  {
    key: 'deliveryDate',
    label: 'deliveryDate',
    title: 'Delivery Date',
    style: { flex: '1 0 100px' },
    defaultSelectedColumn: true,
    renderExportValue: (column: BatchRecord) =>
      `${renderTwoShortDateFormat(column.deliveryDateMin, column.deliveryDateMax)}`,
    render: (column: BatchRecord) =>
      `${renderTwoShortDateFormat(column.deliveryDateMin, column.deliveryDateMax)}`,
  },
  {
    key: 'processingDate',
    label: 'processingDate',
    title: 'Processing Date',
    style: { flex: '1 0 100px' },
    defaultSelectedColumn: true,
    renderExportValue: (column: BatchRecord) =>
      `${renderTwoShortDateFormat(column.processingDateMin, column.processingDateMax)}`,
    render: (column: BatchRecord) =>
      `${renderTwoShortDateFormat(column.processingDateMin, column.processingDateMax)}`,
  },
  {
    key: 'thtBulk',
    label: 'thtBulk',
    title: 'THT Bulk',
    style: { flex: '1 0 100px' },
    defaultSelectedColumn: true,
    renderExportValue: (column: BatchRecord) =>
      `${renderTwoShortDateFormat(column.thtBulkMin, column.thtBulkMax)}`,
    render: (column: BatchRecord) =>
      `${renderTwoShortDateFormat(column.thtBulkMin, column.thtBulkMax)}`,
  },
  {
    key: 'createdAt',
    label: 'Created At',
    title: 'Created At',
    style: { flex: '1 0 60px' },
    renderExportValue: (column: BatchRecord) => `${renderShortDateFormat(column.createdAt)}`,
    defaultSelectedColumn: true,
    render: (column: BatchRecord) => `${renderShortDateFormat(column.createdAt)}`,
  },
  {
    key: 'createdBy',
    label: 'Created By',
    title: 'Created By',
    style: { flex: '1 0 50px' },
    defaultSelectedColumn: true,
    renderUploadPreview: () => '',
    renderExportValue: (column: BatchRecord) => column.createdBy,
    render: (record: BatchRecord) => getCreatorName(record),
    validate: [],
  },
];
