import React from 'react';
import PropTypes from 'prop-types';
// components
import EditableCSVCellPopup from 'components/Datatable/editableCSVCellPopup';
// jss
import injectStyle from 'react-jss';
import editableCellStyles from 'components/Datatable/styles/editableCellStyles';

export const DocumentTypes = ['csv', 'excel'];

class EditableCell extends React.Component {
  constructor(props) {
    super(props);

    this.inputRef = React.createRef();
    this.container = React.createRef();
    this.state = {
      isEditing: false,
      inputValue: '',
      defaultValue: '',
    };
  }

  componentDidMount() {
    const { props } = this;
    const initialValue = this.getDefaultValue(props);

    this.setState({ inputValue: initialValue, defaultValue: initialValue });
  }

  componentWillReceiveProps(nextProps) {
    const initialValue = this.getDefaultValue(nextProps);

    this.setState({ inputValue: initialValue, defaultValue: initialValue, isEditing: false });
  }

  getDefaultValue = (propsObject) => {
    const { documentType } = this.props;
    const [csv, excel] = DocumentTypes;

    switch (documentType) {
      case csv: {
        return this.getDefaultCSVValue(propsObject);
      }

      case excel: {
        return this.getExcelDefaultValue(propsObject);
      }

      default: {
        console.error(
          `Document type doesn't match any value of available editable types, value ${documentType}`,
        );
        return this.getExcelDefaultValue(propsObject);
      }
    }
  };

  getDefaultCSVValue = (propsObject) => propsObject.inputValue;

  getExcelDefaultValue = (propsObject) => propsObject.dataItem[propsObject.column.key] || '';

  // this is used for excell only, to keep cells styles
  handleEditStart = () => {
    this.setState({ isEditing: true }, this.setFocusToInput);
  };

  setFocusToInput = () => {
    if (this.inputRef.current) {
      this.setAppropriateFocus();
    }
  };

  setAppropriateFocus = () => {
    const { current } = this.inputRef;

    try {
      if (typeof current.setFocus === 'function') {
        current.setFocus();
      } else {
        current.focus();
      }
    } catch (e) {
      console.warn(
        `Failed to focus on editable element, which is likely used in popup, error : ${e}`,
      );
    }
  };

  handleInputChange = (value) => {
    this.setState({ inputValue: value });
  };

  setDefaultValue = () => {
    this.setState({ inputValue: this.state.defaultValue, isEditing: false });
  };

  handleApplyChanges = () => {
    const { documentType } = this.props;
    const [csv, excel] = DocumentTypes;

    switch (documentType) {
      case csv: {
        return this.handleApplyCSVChanges();
      }

      case excel: {
        return this.handleApplyExcelChanges();
      }

      default: {
        console.error(
          `Document type doesn't match any value of available editable types, value ${documentType}`,
        );
        return this.handleApplyExcelChanges();
      }
    }
  };

  handleApplyCSVChanges = () => {
    const { handleCellUpdate } = this.props;
    const { inputValue } = this.state;
    handleCellUpdate(inputValue);
    this.setState({ isEditing: false });
  };

  handleApplyExcelChanges = () => {
    const { inputValue } = this.state;
    const { dataItem, column, handleCellUpdate } = this.props;
    const { rowId } = dataItem;
    const { key } = column;

    const handledParsedInputValue = column.handleParsedValue
      ? column.handleParsedValue(inputValue)
      : inputValue;

    handleCellUpdate(rowId, key, handledParsedInputValue);
    this.setState({ isEditing: false });
  };

  render() {
    const {
      container,
      inputRef,
      handleEditStart,
      handleInputChange,
      handleApplyChanges,
      setDefaultValue,
    } = this;
    const { children, classes, column = {}, documentType, dateFormat = 'DD/MM/YYYY' } = this.props;
    const { isEditing, inputValue } = this.state;
    const { edit, label } = column;

    return (
      <div ref={container} className={classes[documentType]} onDoubleClick={handleEditStart}>
        {isEditing ? (
          <EditableCSVCellPopup
            inputRef={inputRef}
            handleInputChange={handleInputChange}
            inputValue={inputValue}
            edit={edit}
            label={label}
            handleApplyChanges={handleApplyChanges}
            setDefaultValue={setDefaultValue}
            dateFormat={dateFormat}
            // this is original data which is wrapped by this HOC, used to keep styles CSV cell
            originalChildren={children}
          />
        ) : (
          children
        )}
      </div>
    );
  }
}

EditableCell.propTypes = {
  handleCellUpdate: PropTypes.func.isRequired,
  column: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  documentType: PropTypes.string.isRequired,
  dateFormat: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  children: PropTypes.any,
  dataItem: PropTypes.any,
};

export default injectStyle(editableCellStyles)(EditableCell);
