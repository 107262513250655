import React from 'react';
// components
import ModelSettingsDropdown from 'components/Dropdown/ModelSettingsDropDown';
import DownloadExcel from 'models/utils/DownloadExcel/DownloadExcel';

export default {
  key: 'settingsAndActions',
  title: (
    <React.Fragment>
      <DownloadExcel />
      <ModelSettingsDropdown />
    </React.Fragment>
  ),
  style: { flex: '1 0 70px', justifyContent: 'space-evenly' },
  render: () => null,
};
