import { useCallback, useEffect, useRef, useState } from 'react';
import moment from 'moment';
import { DateFilters, ListItem, TabItem } from '../components/Dropdown/TabDropDownRange';

export const useOutsideClick = (initialState: boolean) => {
  const [isOpen, setIsOpen] = useState(initialState);
  const ref = useRef<HTMLDivElement>(null);

  const handleClickOutside = useCallback((event: Event) => {
    if (ref.current && !ref.current.contains(event.target as Node)) {
      setIsOpen(false);
    }
  }, []);

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('click', handleClickOutside, true);
    } else {
      document.removeEventListener('click', handleClickOutside, true);
    }
  }, [isOpen, handleClickOutside]);

  return { ref, isOpen, setIsOpen };
};

export const useInitialRender = () => {
  const [isInitialRender, setIsInitialRender] = useState(true);
  useEffect(() => setIsInitialRender(false), []);
  return isInitialRender;
};

export const useDateFilters = (
  activeTabId: string | undefined,
  tabs: TabItem[],
  valuesList: ListItem[],
) => {
  const getDefaultSelectedTabDropdownId = () => {
    const defaultSelected = tabs.find(({ defaultSelected }) => defaultSelected);
    if (!defaultSelected) {
      throw new Error(
        `There should be default selected tab for first render / no props, received  ${JSON.stringify(
          tabs,
        )}`,
      );
    } else {
      return defaultSelected.id;
    }
  };

  const defineActiveTab = () => activeTabId || getDefaultSelectedTabDropdownId();

  const getDefaultDateFilters = (): DateFilters => {
    const defaultSelectedValue = valuesList.find((value) => value.defaultSelected) || valuesList[0];

    return {
      beginDate: moment()
        .subtract(defaultSelectedValue.value, 'days')
        .toDate(),
      endDate: moment().toDate(),
    };
  };

  const [dateFilters, setDateFilters] = useState(getDefaultDateFilters());
  const [activeTab, setActiveTab] = useState(defineActiveTab());

  return { dateFilters, setDateFilters, activeTab, setActiveTab };
};
