import { Theme } from 'lib/lib';

export default (theme: Theme) => ({
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 30,
  },
  exportToExcel: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& span': {
      marginLeft: 10,
    },
    cursor: 'pointer',
  },
  highOrderTab: {
    backgroundColor: theme.colors.primary,
    width: 300,
    maxWidth: 300,
    padding: 15,
    color: 'white',
    borderTopLeftRadius: 15,
    '& span ': {
      fontSize: 18,
    },
  },
  highOrderTabTitle: {
    fontWeight: 'bold',
  },
  excelTabsContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'stretch',
    position: 'relative',
  },
  excelTab: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: 60,
    width: 300,
    textAlign: 'center',
    backgroundColor: theme.colors.primaryHover,
    color: 'white',
    cursor: 'pointer',
    userSelect: 'none',
    borderTopRightRadius: 15,
    border: `1px solid ${theme.colors.primary}`,
    '&:hover:not(.active)': {
      backgroundColor: theme.colors.primary,
    },
    '&.active': {
      backgroundColor: theme.colors.primary,
    },
  },
  errorTab: {
    background: `${theme.colors.redD}`,
    border: `1px solid ${theme.colors.redD}`,
    '&.active': {
      backgroundColor: '#a3100e',
    },
    '&:hover:not(.active)': {
      backgroundColor: '#a3100e',
    },
  },
  tabTitle: {
    fontSize: 20,
    color: 'white',
    wordBreak: 'keep-all',
  },
  errorMessagesContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    '& .errorMessage': {
      fontSize: 11,
    },
  },
  deleteBatches: {
    cursor: 'pointer',
    height: 24,
  },

  loader: { ...theme.components.loader },
});
