export default () => ({
  excel: {
    position: 'relative',
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'space-between',
    padding: [10, 5],
  },
  csv: {
    transition: 'all linear 0.5s',
    position: 'relative',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'space-between',
    '& .changed': {
      display: 'flex',
      width: '100%',
      position: 'relative',
      justifyContent: 'space-around',
    },
  },
});
