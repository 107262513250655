import indexTabModel from 'models/mayonna/tabs/indexTabModel';
import settingsAndActions from 'models/mayonna/settingsAndActions/index';
import { ORGANIZATIONS } from 'themes/constants';
import { ModelRequiredInfo } from 'lib/lib';

export default (modelRequiredInfo: ModelRequiredInfo) => ({
  tabs: [
    {
      modelName: 'defaultTab',
      originalName: ORGANIZATIONS.MAYONNA,
      title: ORGANIZATIONS.MAYONNA,
    },
  ],
  defaultTab: indexTabModel(modelRequiredInfo),
  settingsAndActions,
});
