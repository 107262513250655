import React from 'react';
import moment from 'moment';
// icons
import { DownloadSvg } from 'components/icons';
import { shallowEqual, useSelector } from 'react-redux';
// selectors
import { getUser } from 'domain/env';
import { getBatches } from 'domain/batches';
import { getModel } from 'domain/common';
// lib
import { exportBatchesToCSV } from 'lib/exportToFile';
// types
import { AppStateType } from 'types';

const mapStateToProps = (state: AppStateType) => ({
  batches: getBatches(state),
  user: getUser(state),
  model: getModel(state),
});

const DownloadCSV: React.FC = () => {
  const { batches, user, model } = useSelector(mapStateToProps, shallowEqual);
  const downloadCSV = () => {
    exportBatchesToCSV(
      batches,
      model,
      `batchListIndex_${user.role}_${moment().format('YYYY-MMM-DD_HH-mm')}.csv`,
    );
  };

  return <DownloadSvg className="downloadSvg" data-cy="download" onClick={downloadCSV} />;
};

export default DownloadCSV;
