import { CHANNELS, ORGANIZATIONS } from 'themes/constants';

const {
  SIM,
  FYFFES,
  HOLLANDER,
  REGALSPRINGS,
  SEAFOODCONNECTION,
  JUMBO,
  MAYONNA,
  BANAFOOD,
  AH,
  EGG1,
  EGG2,
  JDE,
  LDCBR,
  REFRESCO,
  CITROSUCOBR,
  CITROSUCOGHENT,
  KAUFLANDDE,
  REFRESCOGMBH,
} = ORGANIZATIONS;
const { BANANAS, TILAPIA, ORANGES, EGGS, JUICE } = CHANNELS;

const certificates = {
  [SIM]: {
    [BANANAS]: {},
    [TILAPIA]: {},
    [ORANGES]: {},
    [EGGS]: {},
    [JUICE]: {},
  },
  [CITROSUCOBR]: {
    [JUICE]: {},
  },
  [CITROSUCOGHENT]: {
    [JUICE]: {},
  },
  [REFRESCOGMBH]: {
    [JUICE]: {},
  },
  [KAUFLANDDE]: {
    [JUICE]: {},
  },
  [BANAFOOD]: {
    [BANANAS]: {},
  },
  [FYFFES]: {
    [BANANAS]: {},
  },
  [HOLLANDER]: {
    [BANANAS]: {},
  },
  [REGALSPRINGS]: {
    [TILAPIA]: {},
  },
  [SEAFOODCONNECTION]: {
    [TILAPIA]: {},
  },
  [JUMBO]: {
    [TILAPIA]: {},
  },
  [MAYONNA]: {
    [TILAPIA]: {},
  },
  [AH]: {
    [ORANGES]: {},
    [EGGS]: {},
  },
  [EGG1]: {
    [EGGS]: {},
  },
  [EGG2]: {
    [EGGS]: {},
  },
  [REFRESCO]: {
    [ORANGES]: {},
    [JUICE]: {},
  },
  [JDE]: {
    [ORANGES]: {},
  },
  [LDCBR]: {
    [ORANGES]: {},
  },
};

certificates[SIM][BANANAS].active = [
  {
    $class: 'org.powerchain.bananas.member.Cert',
    certId: 'ae7aefbd-b571-444d-b6ac-852198e1aa5f',
    owner: 'resource:org.powerchain.bananas.member.Org#SIM',
    uploadedBy:
      'resource:org.powerchain.bananas.member.DistributorUser#SIM_5509e7bd-346c-4132-8587-00f1e5dccc55',
    uploadedAtMin: '2019-08-07T10:20:44.679Z',
    uploadedAtMax: '2019-08-07T10:20:44.679Z',
    status: 'PENDING',
    documentType: 'IFS Food',
    documentNumber: ' 123',
    auditDate: '2019-09-01T00:00:00.000Z',
    entityCertified: 'Packer',
    entityId: '123',
    notes: 'TEST NOTE',
    from: '2019-08-26T00:00:00.000Z',
    until: '2019-09-01T00:00:00.000Z',
    filename: '123',
    fileUrl: '224',
    filehash: '948d791a-bae2-4303-b295-3e8457d9d53c',
    filesize: 123,
    uploadedBy_participant: {
      username: null,
      email: null,
      participant:
        'org.powerchain.member.REFRESCOUser#REFRESCO_cbfaf845-4832-4347-acf0-4ccc3e78b69e',
      channel: {
        title: 'Oranges',
        name: 'oranges',
      },
      org: {
        name: 'REFRESCO',
        fullname: 'Refresco company',
        logo: null,
      },
      user: {
        username: 'REFRESCO ADMIN staging',
        email: 'REFRESCOADMIN@pc.t2f.io',
        org: {
          name: 'REFRESCO',
          fullname: 'Refresco company',
          logo: null,
        },
      },
    },
  },
];

certificates[SIM][BANANAS].archived = [
  {
    $class: 'org.powerchain.bananas.member.Cert',
    certId: 'Cert_507c0861-43fc-43a8-a54d-00010b7e6b3f',
    owner: 'resource:org.powerchain.bananas.member.Org#SIM',
    uploadedBy:
      'resource:org.powerchain.bananas.member.DistributorUser#SIM_5509e7bd-346c-4132-8587-00f1e5dccc55',
    uploadedAtMin: '2019-08-06T13:14:09.201Z',
    uploadedAtMax: '2019-08-06T13:14:09.201Z',
    modifiedBy:
      'resource:org.powerchain.bananas.member.DistributorUser#SIM_5509e7bd-346c-4132-8587-00f1e5dccc55',
    modifiedAt: '2019-08-08T07:35:45.424Z',
    status: 'archived',
    documentType: 'Rainforest Alliance (RFA)',
    documentNumber: 'M',
    auditDate: '2018-09-10T23:36:10.279Z',
    from: '2018-09-25T02:52:49.048Z',
    until: '2018-12-30T19:37:19.172Z',
    filename: 'Awesome',
    fileUrl: '21987',
    filehash: 'c3bb1c03-4e69-4e09-8618-7903d86f76af',
    filesize: 19270,
    uploadedBy_participant: {
      username: null,
      email: null,
      participant:
        'org.powerchain.member.REFRESCOUser#REFRESCO_cbfaf845-4832-4347-acf0-4ccc3e78b69e',
      channel: {
        title: 'Oranges',
        name: 'oranges',
      },
      org: {
        name: 'REFRESCO',
        fullname: 'Refresco company',
        logo: null,
      },
      user: {
        username: 'REFRESCO ADMIN staging',
        email: 'REFRESCOADMIN@pc.t2f.io',
        org: {
          name: 'REFRESCO',
          fullname: 'Refresco company',
          logo: null,
        },
      },
    },
  },
  {
    $class: 'org.powerchain.bananas.member.Cert',
    certId: 'Cert_d781e8e7-c3df-43cb-933b-35952ab1c2db',
    owner: 'resource:org.powerchain.bananas.member.Org#SIM',
    uploadedBy:
      'resource:org.powerchain.bananas.member.DistributorUser#SIM_5509e7bd-346c-4132-8587-00f1e5dccc55',
    uploadedAtMin: '2019-08-06T13:14:09.226Z',
    uploadedAtMax: '2019-08-06T13:14:09.226Z',
    modifiedBy:
      'resource:org.powerchain.bananas.member.DistributorUser#SIM_5509e7bd-346c-4132-8587-00f1e5dccc55',
    modifiedAt: '2019-08-08T07:35:54.330Z',
    status: 'archived',
    documentType: 'Rainforest Alliance (RFA)',
    documentNumber: 'G',
    auditDate: '2019-08-01T22:13:08.146Z',
    from: '2018-11-15T13:57:04.550Z',
    until: '2019-05-31T01:25:14.416Z',
    filename: 'interface',
    fileUrl: '66266',
    filehash: 'dc78209e-8e8e-4137-8942-098842d771fd',
    filesize: 14136,
    uploadedBy_participant: {
      username: null,
      email: null,
      participant:
        'org.powerchain.member.REFRESCOUser#REFRESCO_cbfaf845-4832-4347-acf0-4ccc3e78b69e',
      channel: {
        title: 'Oranges',
        name: 'oranges',
      },
      org: {
        name: 'REFRESCO',
        fullname: 'Refresco company',
        logo: null,
      },
      user: {
        username: 'REFRESCO ADMIN staging',
        email: 'REFRESCOADMIN@pc.t2f.io',
        org: {
          name: 'REFRESCO',
          fullname: 'Refresco company',
          logo: null,
        },
      },
    },
  },
  {
    $class: 'org.powerchain.bananas.member.Cert',
    certId: 'Cert_5e52f642-a5bc-47ff-a184-94235ee6e1f9',
    owner: 'resource:org.powerchain.bananas.member.Org#SIM',
    uploadedBy:
      'resource:org.powerchain.bananas.member.DistributorUser#SIM_5509e7bd-346c-4132-8587-00f1e5dccc55',
    uploadedAtMin: '2019-08-06T13:16:01.307Z',
    uploadedAtMax: '2019-08-06T13:16:01.307Z',
    status: 'archived',
    documentType: 'Rainforest Alliance (RFA)',
    documentNumber: 'K',
    auditDate: '2019-07-11T07:13:54.758Z',
    from: '2019-07-27T15:22:06.403Z',
    until: '2019-02-04T07:02:28.777Z',
    filename: 'PCI',
    fileUrl: '36396',
    filehash: 'ab4694bc-b4f5-413c-9153-5d8c1ddddaf2',
    filesize: 3904,
    uploadedBy_participant: {
      username: null,
      email: null,
      participant:
        'org.powerchain.member.REFRESCOUser#REFRESCO_cbfaf845-4832-4347-acf0-4ccc3e78b69e',
      channel: {
        title: 'Oranges',
        name: 'oranges',
      },
      org: {
        name: 'REFRESCO',
        fullname: 'Refresco company',
        logo: null,
      },
      user: {
        username: 'REFRESCO ADMIN staging',
        email: 'REFRESCOADMIN@pc.t2f.io',
        org: {
          name: 'REFRESCO',
          fullname: 'Refresco company',
          logo: null,
        },
      },
    },
  },
];

certificates[SIM][TILAPIA].active = [
  {
    $class: 'org.powerchain.tilapia.member.Cert',
    certId: '39fb105c-b46d-4a6a-b004-2385cdd258a1',
    owner: 'resource:org.powerchain.tilapia.member.Org#SIM',
    uploadedBy:
      'resource:org.powerchain.tilapia.member.ProcessorUser#SIM_58945e88-4654-42dd-9071-0025fbd6e840',
    uploadedAtMin: '2019-08-06T09:59:57.347Z',
    uploadedAtMax: '2019-08-06T09:59:57.347Z',
    status: 'PENDING',
    documentType: 'KAT',
    documentNumber: '12',
    auditDate: '2019-08-10T00:00:00.000Z',
    entityCertified: 'Packer',
    entityId: '12',
    from: '2019-08-14T00:00:00.000Z',
    until: '2019-09-01T00:00:00.000Z',
    filename: '123',
    fileUrl: '219',
    filehash: 'b0af1531-873c-4dfc-a1b8-5e8d5a8f6ad2',
    filesize: 123,
    uploadedBy_participant: {
      username: null,
      email: null,
      participant:
        'org.powerchain.member.REFRESCOUser#REFRESCO_cbfaf845-4832-4347-acf0-4ccc3e78b69e',
      channel: {
        title: 'Oranges',
        name: 'oranges',
      },
      org: {
        name: 'REFRESCO',
        fullname: 'Refresco company',
        logo: null,
      },
      user: {
        username: 'REFRESCO ADMIN staging',
        email: 'REFRESCOADMIN@pc.t2f.io',
        org: {
          name: 'REFRESCO',
          fullname: 'Refresco company',
          logo: null,
        },
      },
    },
  },
];

certificates[SIM][TILAPIA].archived = [
  {
    $class: 'org.powerchain.tilapia.member.Cert',
    certId: '6bd6d5b6-0a76-415f-9ced-715913273ae3',
    owner: 'resource:org.powerchain.tilapia.member.Org#SIM',
    uploadedBy:
      'resource:org.powerchain.tilapia.member.ProducerUser#SIM_8900eb90-c29b-458a-aa40-301057d9b81f',
    uploadedAtMin: '2019-08-07T06:57:23.053Z',
    uploadedAtMax: '2019-08-07T06:57:23.053Z',
    modifiedBy:
      'resource:org.powerchain.tilapia.member.ProducerUser#SIM_8900eb90-c29b-458a-aa40-301057d9b81f',
    modifiedAt: '2019-08-07T06:57:41.752Z',
    status: 'archived',
    documentType: 'BAP',
    documentNumber: '123',
    auditDate: '2019-08-07T00:00:00.000Z',
    entityCertified: 'Packer',
    entityId: '123',
    from: '2019-08-14T00:00:00.000Z',
    until: '2019-09-01T00:00:00.000Z',
    filename: '123',
    fileUrl: '223',
    filehash: '781cf12a-d273-43e0-91ec-98fcea783b4a',
    filesize: 123,
    uploadedBy_participant: {
      username: null,
      email: null,
      participant:
        'org.powerchain.member.REFRESCOUser#REFRESCO_cbfaf845-4832-4347-acf0-4ccc3e78b69e',
      channel: {
        title: 'Oranges',
        name: 'oranges',
      },
      org: {
        name: 'REFRESCO',
        fullname: 'Refresco company',
        logo: null,
      },
      user: {
        username: 'REFRESCO ADMIN staging',
        email: 'REFRESCOADMIN@pc.t2f.io',
        org: {
          name: 'REFRESCO',
          fullname: 'Refresco company',
          logo: null,
        },
      },
    },
  },
  {
    $class: 'org.powerchain.tilapia.member.Cert',
    certId: '02e7ce7d-4ad8-4626-9824-3f3a67d743a6',
    owner: 'resource:org.powerchain.tilapia.member.Org#SIM',
    uploadedBy:
      'resource:org.powerchain.tilapia.member.ProducerUser#SIM_8900eb90-c29b-458a-aa40-301057d9b81f',
    uploadedAtMin: '2019-08-06T09:58:11.836Z',
    uploadedAtMax: '2019-08-06T09:58:11.836Z',
    modifiedBy:
      'resource:org.powerchain.tilapia.member.ProducerUser#SIM_02c43a1f-0ca9-41db-91e5-2691ec3eeb69',
    modifiedAt: '2019-08-06T14:58:19.054Z',
    status: 'archived',
    documentType: 'BLK Level',
    documentNumber: '12',
    auditDate: '2019-08-09T00:00:00.000Z',
    entityCertified: 'Packer',
    entityId: '12',
    from: '2019-08-19T00:00:00.000Z',
    until: '2019-09-01T00:00:00.000Z',
    filename: '123',
    fileUrl: '216',
    filehash: 'b3efdcf6-8ed6-4eb6-bc71-e3a8b8f956d0',
    filesize: 123,
    uploadedBy_participant: {
      username: null,
      email: null,
      participant:
        'org.powerchain.member.REFRESCOUser#REFRESCO_cbfaf845-4832-4347-acf0-4ccc3e78b69e',
      channel: {
        title: 'Oranges',
        name: 'oranges',
      },
      org: {
        name: 'REFRESCO',
        fullname: 'Refresco company',
        logo: null,
      },
      user: {
        username: 'REFRESCO ADMIN staging',
        email: 'REFRESCOADMIN@pc.t2f.io',
        org: {
          name: 'REFRESCO',
          fullname: 'Refresco company',
          logo: null,
        },
      },
    },
  },
];

certificates[BANAFOOD][BANANAS].active = [
  {
    $class: 'org.powerchain.bananas.member.Cert',
    certId: 'c6807bcc-3f76-4e9a-978b-e613cfe3e0b6',
    owner: 'resource:org.powerchain.bananas.member.Org#Banafood',
    uploadedBy:
      'resource:org.powerchain.bananas.member.ProducerUser#Banafood_892150f4-aa19-475c-b55d-b9c5898d98b8',
    uploadedAtMin: '2019-08-08T07:29:37.435Z',
    uploadedAtMax: '2019-08-08T07:29:37.435Z',
    status: 'PENDING',
    documentType: 'BRC Food',
    documentNumber: '1',
    auditDate: '2019-08-06T00:00:00.000Z',
    entityCertified: 'Farm',
    entityId: '1',
    notes: '1',
    from: '2019-08-05T00:00:00.000Z',
    until: '2019-08-21T00:00:00.000Z',
    filename: '123',
    fileUrl: '225',
    filehash: 'b9ff8240-66d8-4ff5-b3aa-4bd46e1e680f',
    filesize: 123,
    uploadedBy_participant: {
      username: null,
      email: null,
      participant:
        'org.powerchain.member.REFRESCOUser#REFRESCO_cbfaf845-4832-4347-acf0-4ccc3e78b69e',
      channel: {
        title: 'Oranges',
        name: 'oranges',
      },
      org: {
        name: 'REFRESCO',
        fullname: 'Refresco company',
        logo: null,
      },
      user: {
        username: 'REFRESCO ADMIN staging',
        email: 'REFRESCOADMIN@pc.t2f.io',
        org: {
          name: 'REFRESCO',
          fullname: 'Refresco company',
          logo: null,
        },
      },
    },
  },
];

certificates[BANAFOOD][BANANAS].archived = [
  {
    $class: 'org.powerchain.bananas.member.Cert',
    certId: 'Cert_2588594a-b4c2-4e53-907f-04066a274c86',
    owner: 'resource:org.powerchain.bananas.member.Org#Banafood',
    uploadedBy:
      'resource:org.powerchain.bananas.member.ProducerUser#Banafood_892150f4-aa19-475c-b55d-b9c5898d98b8',
    uploadedAtMin: '2019-07-23T11:23:32.593Z',
    uploadedAtMax: '2019-07-23T11:23:32.593Z',
    status: 'archived',
    documentType: 'Rainforest Alliance (RFA)',
    documentNumber: 'A',
    auditDate: '2018-08-14T02:45:49.080Z',
    from: '2018-09-15T09:53:37.618Z',
    until: '2019-07-31T00:30:24.264Z',
    filename: 'gold',
    fileUrl: '52547',
    filehash: '5f967180-7867-49fb-81cd-b6adad271ebe',
    filesize: 5070,
    uploadedBy_participant: {
      username: null,
      email: null,
      participant:
        'org.powerchain.member.REFRESCOUser#REFRESCO_cbfaf845-4832-4347-acf0-4ccc3e78b69e',
      channel: {
        title: 'Oranges',
        name: 'oranges',
      },
      org: {
        name: 'REFRESCO',
        fullname: 'Refresco company',
        logo: null,
      },
      user: {
        username: 'REFRESCO ADMIN staging',
        email: 'REFRESCOADMIN@pc.t2f.io',
        org: {
          name: 'REFRESCO',
          fullname: 'Refresco company',
          logo: null,
        },
      },
    },
  },
  {
    $class: 'org.powerchain.bananas.member.Cert',
    certId: 'Cert_d48506b8-e574-40fe-9839-93a61236a12a',
    owner: 'resource:org.powerchain.bananas.member.Org#Banafood',
    uploadedBy:
      'resource:org.powerchain.bananas.member.ProducerUser#Banafood_892150f4-aa19-475c-b55d-b9c5898d98b8',
    uploadedAtMin: '2019-08-06T13:14:06.357Z',
    uploadedAtMax: '2019-08-06T13:14:06.357Z',
    modifiedBy:
      'resource:org.powerchain.bananas.member.ProducerUser#Banafood_892150f4-aa19-475c-b55d-b9c5898d98b8',
    modifiedAt: '2019-08-08T07:28:52.274Z',
    status: 'archived',
    documentType: 'Rainforest Alliance (RFA)',
    documentNumber: 'S',
    auditDate: '2018-12-29T23:22:55.866Z',
    from: '2019-03-31T20:36:04.196Z',
    until: '2019-07-25T16:16:34.546Z',
    filename: 'Assistant',
    fileUrl: '33520',
    filehash: '3010856b-a0c0-441c-b179-2c76e4fd0c94',
    filesize: 4344,
    uploadedBy_participant: {
      username: null,
      email: null,
      participant:
        'org.powerchain.member.REFRESCOUser#REFRESCO_cbfaf845-4832-4347-acf0-4ccc3e78b69e',
      channel: {
        title: 'Oranges',
        name: 'oranges',
      },
      org: {
        name: 'REFRESCO',
        fullname: 'Refresco company',
        logo: null,
      },
      user: {
        username: 'REFRESCO ADMIN staging',
        email: 'REFRESCOADMIN@pc.t2f.io',
        org: {
          name: 'REFRESCO',
          fullname: 'Refresco company',
          logo: null,
        },
      },
    },
  },
  {
    $class: 'org.powerchain.bananas.member.Cert',
    certId: 'Cert_27597509-6e4e-4f03-93d4-11eed91e2c3e',
    owner: 'resource:org.powerchain.bananas.member.Org#Banafood',
    uploadedBy:
      'resource:org.powerchain.bananas.member.ProducerUser#Banafood_892150f4-aa19-475c-b55d-b9c5898d98b8',
    uploadedAtMin: '2019-08-06T13:14:06.383Z',
    uploadedAtMax: '2019-08-06T13:14:06.383Z',
    modifiedBy:
      'resource:org.powerchain.bananas.member.ProducerUser#Banafood_892150f4-aa19-475c-b55d-b9c5898d98b8',
    modifiedAt: '2019-08-08T07:29:58.312Z',
    status: 'archived',
    documentType: 'Rainforest Alliance (RFA)',
    documentNumber: 'M',
    auditDate: '2019-01-08T20:20:52.146Z',
    from: '2018-12-29T19:27:32.496Z',
    until: '2018-11-14T06:25:40.021Z',
    filename: 'Inlet',
    fileUrl: '42395',
    filehash: '921181da-2648-4be9-a743-38ae9f6c5648',
    filesize: 14762,
    uploadedBy_participant: {
      username: null,
      email: null,
      participant:
        'org.powerchain.member.REFRESCOUser#REFRESCO_cbfaf845-4832-4347-acf0-4ccc3e78b69e',
      channel: {
        title: 'Oranges',
        name: 'oranges',
      },
      org: {
        name: 'REFRESCO',
        fullname: 'Refresco company',
        logo: null,
      },
      user: {
        username: 'REFRESCO ADMIN staging',
        email: 'REFRESCOADMIN@pc.t2f.io',
        org: {
          name: 'REFRESCO',
          fullname: 'Refresco company',
          logo: null,
        },
      },
    },
  },
  {
    $class: 'org.powerchain.bananas.member.Cert',
    certId: 'Cert_3f54f7ad-82cf-42d8-b426-2bec599c65da',
    owner: 'resource:org.powerchain.bananas.member.Org#Banafood',
    uploadedBy:
      'resource:org.powerchain.bananas.member.ProducerUser#Banafood_892150f4-aa19-475c-b55d-b9c5898d98b8',
    uploadedAtMin: '2019-08-06T13:14:06.391Z',
    uploadedAtMax: '2019-08-06T13:14:06.391Z',
    modifiedBy:
      'resource:org.powerchain.bananas.member.ProducerUser#Banafood_892150f4-aa19-475c-b55d-b9c5898d98b8',
    modifiedAt: '2019-08-08T07:30:04.745Z',
    status: 'archived',
    documentType: 'Rainforest Alliance (RFA)',
    documentNumber: 'P',
    auditDate: '2018-10-16T14:11:03.000Z',
    from: '2019-08-03T15:39:41.643Z',
    until: '2019-04-18T07:13:58.689Z',
    filename: 'parse',
    fileUrl: '52459',
    filehash: 'aff30bed-5d8e-4e3b-88e4-a625add5f6e7',
    filesize: 7897,
    uploadedBy_participant: {
      username: null,
      email: null,
      participant:
        'org.powerchain.member.REFRESCOUser#REFRESCO_cbfaf845-4832-4347-acf0-4ccc3e78b69e',
      channel: {
        title: 'Oranges',
        name: 'oranges',
      },
      org: {
        name: 'REFRESCO',
        fullname: 'Refresco company',
        logo: null,
      },
      user: {
        username: 'REFRESCO ADMIN staging',
        email: 'REFRESCOADMIN@pc.t2f.io',
        org: {
          name: 'REFRESCO',
          fullname: 'Refresco company',
          logo: null,
        },
      },
    },
  },
  {
    $class: 'org.powerchain.bananas.member.Cert',
    certId: 'Cert_7887175c-96e1-45c7-ba95-cca171c18d01',
    owner: 'resource:org.powerchain.bananas.member.Org#Banafood',
    uploadedBy:
      'resource:org.powerchain.bananas.member.ProducerUser#Banafood_892150f4-aa19-475c-b55d-b9c5898d98b8',
    uploadedAtMin: '2019-08-06T13:15:58.509Z',
    uploadedAtMax: '2019-08-06T13:15:58.509Z',
    status: 'archived',
    documentType: 'Rainforest Alliance (RFA)',
    documentNumber: 'M',
    auditDate: '2018-10-25T05:21:39.883Z',
    from: '2019-03-22T10:11:31.977Z',
    until: '2018-10-17T06:36:35.413Z',
    filename: 'Sports',
    fileUrl: '80622',
    filehash: 'cdad1adc-52d8-4161-8fb6-1db4912876ac',
    filesize: 19223,
    uploadedBy_participant: {
      username: null,
      email: null,
      participant:
        'org.powerchain.member.REFRESCOUser#REFRESCO_cbfaf845-4832-4347-acf0-4ccc3e78b69e',
      channel: {
        title: 'Oranges',
        name: 'oranges',
      },
      org: {
        name: 'REFRESCO',
        fullname: 'Refresco company',
        logo: null,
      },
      user: {
        username: 'REFRESCO ADMIN staging',
        email: 'REFRESCOADMIN@pc.t2f.io',
        org: {
          name: 'REFRESCO',
          fullname: 'Refresco company',
          logo: null,
        },
      },
    },
  },
  {
    $class: 'org.powerchain.bananas.member.Cert',
    certId: 'Cert_47c27473-5af5-4da1-91f7-9ab1e345107e',
    owner: 'resource:org.powerchain.bananas.member.Org#Banafood',
    uploadedBy:
      'resource:org.powerchain.bananas.member.ProducerUser#Banafood_892150f4-aa19-475c-b55d-b9c5898d98b8',
    uploadedAtMin: '2019-08-06T13:15:58.536Z',
    uploadedAtMax: '2019-08-06T13:15:58.536Z',
    status: 'archived',
    documentType: 'Rainforest Alliance (RFA)',
    documentNumber: 'B',
    auditDate: '2019-04-09T03:23:51.425Z',
    from: '2018-09-28T18:18:09.867Z',
    until: '2019-06-20T16:13:23.450Z',
    filename: 'quantifying',
    fileUrl: '11488',
    filehash: '0fd04577-1a3b-4e1b-8ab7-fa269deafa32',
    filesize: 9395,
    uploadedBy_participant: {
      username: null,
      email: null,
      participant:
        'org.powerchain.member.REFRESCOUser#REFRESCO_cbfaf845-4832-4347-acf0-4ccc3e78b69e',
      channel: {
        title: 'Oranges',
        name: 'oranges',
      },
      org: {
        name: 'REFRESCO',
        fullname: 'Refresco company',
        logo: null,
      },
      user: {
        username: 'REFRESCO ADMIN staging',
        email: 'REFRESCOADMIN@pc.t2f.io',
        org: {
          name: 'REFRESCO',
          fullname: 'Refresco company',
          logo: null,
        },
      },
    },
  },
  {
    $class: 'org.powerchain.bananas.member.Cert',
    certId: 'Cert_904fb55e-ae4e-4569-88b5-22db6199f3a3',
    owner: 'resource:org.powerchain.bananas.member.Org#Banafood',
    uploadedBy:
      'resource:org.powerchain.bananas.member.ProducerUser#Banafood_892150f4-aa19-475c-b55d-b9c5898d98b8',
    uploadedAtMin: '2019-08-06T13:15:58.543Z',
    uploadedAtMax: '2019-08-06T13:15:58.543Z',
    status: 'archived',
    documentType: 'Rainforest Alliance (RFA)',
    documentNumber: 'H',
    auditDate: '2018-11-19T05:44:39.197Z',
    from: '2018-10-23T11:49:21.387Z',
    until: '2019-01-03T08:20:37.775Z',
    filename: 'initiatives',
    fileUrl: '63583',
    filehash: '8b4e6434-e80c-44f5-9d45-bd2b19411083',
    filesize: 19868,
    uploadedBy_participant: {
      username: null,
      email: null,
      participant:
        'org.powerchain.member.REFRESCOUser#REFRESCO_cbfaf845-4832-4347-acf0-4ccc3e78b69e',
      channel: {
        title: 'Oranges',
        name: 'oranges',
      },
      org: {
        name: 'REFRESCO',
        fullname: 'Refresco company',
        logo: null,
      },
      user: {
        username: 'REFRESCO ADMIN staging',
        email: 'REFRESCOADMIN@pc.t2f.io',
        org: {
          name: 'REFRESCO',
          fullname: 'Refresco company',
          logo: null,
        },
      },
    },
  },
];

certificates[FYFFES][BANANAS].active = [
  {
    $class: 'org.powerchain.bananas.member.Cert',
    certId: 'c6807bcc-3f76-4e9a-978b-e613cfe3e0b6',
    owner: 'resource:org.powerchain.bananas.member.Org#Fyffes',
    uploadedBy:
      'resource:org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8',
    uploadedAtMin: '2019-08-08T07:29:37.435Z',
    uploadedAtMax: '2019-08-08T07:29:37.435Z',
    status: 'PENDING',
    documentType: 'BRC Food',
    documentNumber: '1',
    auditDate: '2019-08-06T00:00:00.000Z',
    entityCertified: 'Farm',
    entityId: '1',
    notes: '1',
    from: '2019-08-05T00:00:00.000Z',
    until: '2019-08-21T00:00:00.000Z',
    filename: '123',
    fileUrl: '225',
    filehash: 'b9ff8240-66d8-4ff5-b3aa-4bd46e1e680f',
    filesize: 123,
    uploadedBy_participant: {
      username: null,
      email: null,
      participant:
        'org.powerchain.member.REFRESCOUser#REFRESCO_cbfaf845-4832-4347-acf0-4ccc3e78b69e',
      channel: {
        title: 'Oranges',
        name: 'oranges',
      },
      org: {
        name: 'REFRESCO',
        fullname: 'Refresco company',
        logo: null,
      },
      user: {
        username: 'REFRESCO ADMIN staging',
        email: 'REFRESCOADMIN@pc.t2f.io',
        org: {
          name: 'REFRESCO',
          fullname: 'Refresco company',
          logo: null,
        },
      },
    },
  },
];

certificates[FYFFES][BANANAS].archived = [
  {
    $class: 'org.powerchain.bananas.member.Cert',
    certId: 'Cert_2588594a-b4c2-4e53-907f-04066a274c86',
    owner: 'resource:org.powerchain.bananas.member.Org#Fyffes',
    uploadedBy:
      'resource:org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8',
    uploadedAtMin: '2019-07-23T11:23:32.593Z',
    uploadedAtMax: '2019-07-23T11:23:32.593Z',
    status: 'archived',
    documentType: 'Rainforest Alliance (RFA)',
    documentNumber: 'A',
    auditDate: '2018-08-14T02:45:49.080Z',
    from: '2018-09-15T09:53:37.618Z',
    until: '2019-07-31T00:30:24.264Z',
    filename: 'gold',
    fileUrl: '52547',
    filehash: '5f967180-7867-49fb-81cd-b6adad271ebe',
    filesize: 5070,
    uploadedBy_participant: {
      username: null,
      email: null,
      participant:
        'org.powerchain.member.REFRESCOUser#REFRESCO_cbfaf845-4832-4347-acf0-4ccc3e78b69e',
      channel: {
        title: 'Oranges',
        name: 'oranges',
      },
      org: {
        name: 'REFRESCO',
        fullname: 'Refresco company',
        logo: null,
      },
      user: {
        username: 'REFRESCO ADMIN staging',
        email: 'REFRESCOADMIN@pc.t2f.io',
        org: {
          name: 'REFRESCO',
          fullname: 'Refresco company',
          logo: null,
        },
      },
    },
  },
  {
    $class: 'org.powerchain.bananas.member.Cert',
    certId: 'Cert_d48506b8-e574-40fe-9839-93a61236a12a',
    owner: 'resource:org.powerchain.bananas.member.Org#Fyffes',
    uploadedBy:
      'resource:org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8',
    uploadedAtMin: '2019-08-06T13:14:06.357Z',
    uploadedAtMax: '2019-08-06T13:14:06.357Z',
    modifiedBy:
      'resource:org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8',
    modifiedAt: '2019-08-08T07:28:52.274Z',
    status: 'archived',
    documentType: 'Rainforest Alliance (RFA)',
    documentNumber: 'S',
    auditDate: '2018-12-29T23:22:55.866Z',
    from: '2019-03-31T20:36:04.196Z',
    until: '2019-07-25T16:16:34.546Z',
    filename: 'Assistant',
    fileUrl: '33520',
    filehash: '3010856b-a0c0-441c-b179-2c76e4fd0c94',
    filesize: 4344,
    uploadedBy_participant: {
      username: null,
      email: null,
      participant:
        'org.powerchain.member.REFRESCOUser#REFRESCO_cbfaf845-4832-4347-acf0-4ccc3e78b69e',
      channel: {
        title: 'Oranges',
        name: 'oranges',
      },
      org: {
        name: 'REFRESCO',
        fullname: 'Refresco company',
        logo: null,
      },
      user: {
        username: 'REFRESCO ADMIN staging',
        email: 'REFRESCOADMIN@pc.t2f.io',
        org: {
          name: 'REFRESCO',
          fullname: 'Refresco company',
          logo: null,
        },
      },
    },
  },
  {
    $class: 'org.powerchain.bananas.member.Cert',
    certId: 'Cert_27597509-6e4e-4f03-93d4-11eed91e2c3e',
    owner: 'resource:org.powerchain.bananas.member.Org#Fyffes',
    uploadedBy:
      'resource:org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8',
    uploadedAtMin: '2019-08-06T13:14:06.383Z',
    uploadedAtMax: '2019-08-06T13:14:06.383Z',
    modifiedBy:
      'resource:org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8',
    modifiedAt: '2019-08-08T07:29:58.312Z',
    status: 'archived',
    documentType: 'Rainforest Alliance (RFA)',
    documentNumber: 'M',
    auditDate: '2019-01-08T20:20:52.146Z',
    from: '2018-12-29T19:27:32.496Z',
    until: '2018-11-14T06:25:40.021Z',
    filename: 'Inlet',
    fileUrl: '42395',
    filehash: '921181da-2648-4be9-a743-38ae9f6c5648',
    filesize: 14762,
    uploadedBy_participant: {
      username: null,
      email: null,
      participant:
        'org.powerchain.member.REFRESCOUser#REFRESCO_cbfaf845-4832-4347-acf0-4ccc3e78b69e',
      channel: {
        title: 'Oranges',
        name: 'oranges',
      },
      org: {
        name: 'REFRESCO',
        fullname: 'Refresco company',
        logo: null,
      },
      user: {
        username: 'REFRESCO ADMIN staging',
        email: 'REFRESCOADMIN@pc.t2f.io',
        org: {
          name: 'REFRESCO',
          fullname: 'Refresco company',
          logo: null,
        },
      },
    },
  },
  {
    $class: 'org.powerchain.bananas.member.Cert',
    certId: 'Cert_3f54f7ad-82cf-42d8-b426-2bec599c65da',
    owner: 'resource:org.powerchain.bananas.member.Org#Fyffes',
    uploadedBy:
      'resource:org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8',
    uploadedAtMin: '2019-08-06T13:14:06.391Z',
    uploadedAtMax: '2019-08-06T13:14:06.391Z',
    modifiedBy:
      'resource:org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8',
    modifiedAt: '2019-08-08T07:30:04.745Z',
    status: 'archived',
    documentType: 'Rainforest Alliance (RFA)',
    documentNumber: 'P',
    auditDate: '2018-10-16T14:11:03.000Z',
    from: '2019-08-03T15:39:41.643Z',
    until: '2019-04-18T07:13:58.689Z',
    filename: 'parse',
    fileUrl: '52459',
    filehash: 'aff30bed-5d8e-4e3b-88e4-a625add5f6e7',
    filesize: 7897,
    uploadedBy_participant: {
      username: null,
      email: null,
      participant:
        'org.powerchain.member.REFRESCOUser#REFRESCO_cbfaf845-4832-4347-acf0-4ccc3e78b69e',
      channel: {
        title: 'Oranges',
        name: 'oranges',
      },
      org: {
        name: 'REFRESCO',
        fullname: 'Refresco company',
        logo: null,
      },
      user: {
        username: 'REFRESCO ADMIN staging',
        email: 'REFRESCOADMIN@pc.t2f.io',
        org: {
          name: 'REFRESCO',
          fullname: 'Refresco company',
          logo: null,
        },
      },
    },
  },
  {
    $class: 'org.powerchain.bananas.member.Cert',
    certId: 'Cert_7887175c-96e1-45c7-ba95-cca171c18d01',
    owner: 'resource:org.powerchain.bananas.member.Org#Fyffes',
    uploadedBy:
      'resource:org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8',
    uploadedAtMin: '2019-08-06T13:15:58.509Z',
    uploadedAtMax: '2019-08-06T13:15:58.509Z',
    status: 'archived',
    documentType: 'Rainforest Alliance (RFA)',
    documentNumber: 'M',
    auditDate: '2018-10-25T05:21:39.883Z',
    from: '2019-03-22T10:11:31.977Z',
    until: '2018-10-17T06:36:35.413Z',
    filename: 'Sports',
    fileUrl: '80622',
    filehash: 'cdad1adc-52d8-4161-8fb6-1db4912876ac',
    filesize: 19223,
    uploadedBy_participant: {
      username: null,
      email: null,
      participant:
        'org.powerchain.member.REFRESCOUser#REFRESCO_cbfaf845-4832-4347-acf0-4ccc3e78b69e',
      channel: {
        title: 'Oranges',
        name: 'oranges',
      },
      org: {
        name: 'REFRESCO',
        fullname: 'Refresco company',
        logo: null,
      },
      user: {
        username: 'REFRESCO ADMIN staging',
        email: 'REFRESCOADMIN@pc.t2f.io',
        org: {
          name: 'REFRESCO',
          fullname: 'Refresco company',
          logo: null,
        },
      },
    },
  },
  {
    $class: 'org.powerchain.bananas.member.Cert',
    certId: 'Cert_47c27473-5af5-4da1-91f7-9ab1e345107e',
    owner: 'resource:org.powerchain.bananas.member.Org#Fyffes',
    uploadedBy:
      'resource:org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8',
    uploadedAtMin: '2019-08-06T13:15:58.536Z',
    uploadedAtMax: '2019-08-06T13:15:58.536Z',
    status: 'archived',
    documentType: 'Rainforest Alliance (RFA)',
    documentNumber: 'B',
    auditDate: '2019-04-09T03:23:51.425Z',
    from: '2018-09-28T18:18:09.867Z',
    until: '2019-06-20T16:13:23.450Z',
    filename: 'quantifying',
    fileUrl: '11488',
    filehash: '0fd04577-1a3b-4e1b-8ab7-fa269deafa32',
    filesize: 9395,
    uploadedBy_participant: {
      username: null,
      email: null,
      participant:
        'org.powerchain.member.REFRESCOUser#REFRESCO_cbfaf845-4832-4347-acf0-4ccc3e78b69e',
      channel: {
        title: 'Oranges',
        name: 'oranges',
      },
      org: {
        name: 'REFRESCO',
        fullname: 'Refresco company',
        logo: null,
      },
      user: {
        username: 'REFRESCO ADMIN staging',
        email: 'REFRESCOADMIN@pc.t2f.io',
        org: {
          name: 'REFRESCO',
          fullname: 'Refresco company',
          logo: null,
        },
      },
    },
  },
  {
    $class: 'org.powerchain.bananas.member.Cert',
    certId: 'Cert_904fb55e-ae4e-4569-88b5-22db6199f3a3',
    owner: 'resource:org.powerchain.bananas.member.Org#Fyffes',
    uploadedBy:
      'resource:org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8',
    uploadedAtMin: '2019-08-06T13:15:58.543Z',
    uploadedAtMax: '2019-08-06T13:15:58.543Z',
    status: 'archived',
    documentType: 'Rainforest Alliance (RFA)',
    documentNumber: 'H',
    auditDate: '2018-11-19T05:44:39.197Z',
    from: '2018-10-23T11:49:21.387Z',
    until: '2019-01-03T08:20:37.775Z',
    filename: 'initiatives',
    fileUrl: '63583',
    filehash: '8b4e6434-e80c-44f5-9d45-bd2b19411083',
    filesize: 19868,
    uploadedBy_participant: {
      username: null,
      email: null,
      participant:
        'org.powerchain.member.REFRESCOUser#REFRESCO_cbfaf845-4832-4347-acf0-4ccc3e78b69e',
      channel: {
        title: 'Oranges',
        name: 'oranges',
      },
      org: {
        name: 'REFRESCO',
        fullname: 'Refresco company',
        logo: null,
      },
      user: {
        username: 'REFRESCO ADMIN staging',
        email: 'REFRESCOADMIN@pc.t2f.io',
        org: {
          name: 'REFRESCO',
          fullname: 'Refresco company',
          logo: null,
        },
      },
    },
  },
];

certificates[HOLLANDER][BANANAS].active = [
  {
    $class: 'org.powerchain.bananas.member.Cert',
    certId: 'ae7aefbd-b571-444d-b6ac-852198e1aa5f',
    owner: 'resource:org.powerchain.bananas.member.Org#Hollander',
    uploadedBy:
      'resource:org.powerchain.bananas.member.DistributorUser#Hollander_5509e7bd-346c-4132-8587-00f1e5dccc55',
    uploadedAtMin: '2019-08-07T10:20:44.679Z',
    uploadedAtMax: '2019-08-07T10:20:44.679Z',
    status: 'PENDING',
    documentType: 'IFS Food',
    documentNumber: ' 123',
    auditDate: '2019-09-01T00:00:00.000Z',
    entityCertified: 'Packer',
    entityId: '123',
    notes: 'TEST NOTE',
    from: '2019-08-26T00:00:00.000Z',
    until: '2019-09-01T00:00:00.000Z',
    filename: '123',
    fileUrl: '224',
    filehash: '948d791a-bae2-4303-b295-3e8457d9d53c',
    filesize: 123,
    uploadedBy_participant: {
      username: null,
      email: null,
      participant:
        'org.powerchain.member.REFRESCOUser#REFRESCO_cbfaf845-4832-4347-acf0-4ccc3e78b69e',
      channel: {
        title: 'Oranges',
        name: 'oranges',
      },
      org: {
        name: 'REFRESCO',
        fullname: 'Refresco company',
        logo: null,
      },
      user: {
        username: 'REFRESCO ADMIN staging',
        email: 'REFRESCOADMIN@pc.t2f.io',
        org: {
          name: 'REFRESCO',
          fullname: 'Refresco company',
          logo: null,
        },
      },
    },
  },
];

certificates[HOLLANDER][BANANAS].archived = [
  {
    $class: 'org.powerchain.bananas.member.Cert',
    certId: 'Cert_cdc9341a-2bb4-49a2-a072-ab602cca365f',
    owner: 'resource:org.powerchain.bananas.member.Org#Hollander',
    uploadedBy:
      'resource:org.powerchain.bananas.member.DistributorUser#Hollander_5509e7bd-346c-4132-8587-00f1e5dccc55',
    uploadedAtMin: '2019-08-06T13:14:09.189Z',
    uploadedAtMax: '2019-08-06T13:14:09.189Z',
    modifiedBy:
      'resource:org.powerchain.bananas.member.DistributorUser#Hollander_5509e7bd-346c-4132-8587-00f1e5dccc55',
    modifiedAt: '2019-08-08T07:35:37.838Z',
    status: 'archived',
    documentType: 'Rainforest Alliance (RFA)',
    documentNumber: 'Q',
    auditDate: '2019-02-16T09:49:19.198Z',
    from: '2018-12-30T16:24:13.824Z',
    until: '2019-07-01T06:25:47.961Z',
    filename: 'best-of-breed',
    fileUrl: '49804',
    filehash: '1869743d-c5f2-47d7-9eef-1075ea361b0c',
    filesize: 10584,
    uploadedBy_participant: {
      username: null,
      email: null,
      participant:
        'org.powerchain.member.REFRESCOUser#REFRESCO_cbfaf845-4832-4347-acf0-4ccc3e78b69e',
      channel: {
        title: 'Oranges',
        name: 'oranges',
      },
      org: {
        name: 'REFRESCO',
        fullname: 'Refresco company',
        logo: null,
      },
      user: {
        username: 'REFRESCO ADMIN staging',
        email: 'REFRESCOADMIN@pc.t2f.io',
        org: {
          name: 'REFRESCO',
          fullname: 'Refresco company',
          logo: null,
        },
      },
    },
  },
  {
    $class: 'org.powerchain.bananas.member.Cert',
    certId: 'Cert_507c0861-43fc-43a8-a54d-00010b7e6b3f',
    owner: 'resource:org.powerchain.bananas.member.Org#Hollander',
    uploadedBy:
      'resource:org.powerchain.bananas.member.DistributorUser#Hollander_5509e7bd-346c-4132-8587-00f1e5dccc55',
    uploadedAtMin: '2019-08-06T13:14:09.201Z',
    uploadedAtMax: '2019-08-06T13:14:09.201Z',
    modifiedBy:
      'resource:org.powerchain.bananas.member.DistributorUser#Hollander_5509e7bd-346c-4132-8587-00f1e5dccc55',
    modifiedAt: '2019-08-08T07:35:45.424Z',
    status: 'archived',
    documentType: 'Rainforest Alliance (RFA)',
    documentNumber: 'M',
    auditDate: '2018-09-10T23:36:10.279Z',
    from: '2018-09-25T02:52:49.048Z',
    until: '2018-12-30T19:37:19.172Z',
    filename: 'Awesome',
    fileUrl: '21987',
    filehash: 'c3bb1c03-4e69-4e09-8618-7903d86f76af',
    filesize: 19270,
    uploadedBy_participant: {
      username: null,
      email: null,
      participant:
        'org.powerchain.member.REFRESCOUser#REFRESCO_cbfaf845-4832-4347-acf0-4ccc3e78b69e',
      channel: {
        title: 'Oranges',
        name: 'oranges',
      },
      org: {
        name: 'REFRESCO',
        fullname: 'Refresco company',
        logo: null,
      },
      user: {
        username: 'REFRESCO ADMIN staging',
        email: 'REFRESCOADMIN@pc.t2f.io',
        org: {
          name: 'REFRESCO',
          fullname: 'Refresco company',
          logo: null,
        },
      },
    },
  },
  {
    $class: 'org.powerchain.bananas.member.Cert',
    certId: 'Cert_d781e8e7-c3df-43cb-933b-35952ab1c2db',
    owner: 'resource:org.powerchain.bananas.member.Org#Hollander',
    uploadedBy:
      'resource:org.powerchain.bananas.member.DistributorUser#Hollander_5509e7bd-346c-4132-8587-00f1e5dccc55',
    uploadedAtMin: '2019-08-06T13:14:09.226Z',
    uploadedAtMax: '2019-08-06T13:14:09.226Z',
    modifiedBy:
      'resource:org.powerchain.bananas.member.DistributorUser#Hollander_5509e7bd-346c-4132-8587-00f1e5dccc55',
    modifiedAt: '2019-08-08T07:35:54.330Z',
    status: 'archived',
    documentType: 'Rainforest Alliance (RFA)',
    documentNumber: 'G',
    auditDate: '2019-08-01T22:13:08.146Z',
    from: '2018-11-15T13:57:04.550Z',
    until: '2019-05-31T01:25:14.416Z',
    filename: 'interface',
    fileUrl: '66266',
    filehash: 'dc78209e-8e8e-4137-8942-098842d771fd',
    filesize: 14136,
    uploadedBy_participant: {
      username: null,
      email: null,
      participant:
        'org.powerchain.member.REFRESCOUser#REFRESCO_cbfaf845-4832-4347-acf0-4ccc3e78b69e',
      channel: {
        title: 'Oranges',
        name: 'oranges',
      },
      org: {
        name: 'REFRESCO',
        fullname: 'Refresco company',
        logo: null,
      },
      user: {
        username: 'REFRESCO ADMIN staging',
        email: 'REFRESCOADMIN@pc.t2f.io',
        org: {
          name: 'REFRESCO',
          fullname: 'Refresco company',
          logo: null,
        },
      },
    },
  },
  {
    $class: 'org.powerchain.bananas.member.Cert',
    certId: 'Cert_5e52f642-a5bc-47ff-a184-94235ee6e1f9',
    owner: 'resource:org.powerchain.bananas.member.Org#Hollander',
    uploadedBy:
      'resource:org.powerchain.bananas.member.DistributorUser#Hollander_5509e7bd-346c-4132-8587-00f1e5dccc55',
    uploadedAtMin: '2019-08-06T13:16:01.307Z',
    uploadedAtMax: '2019-08-06T13:16:01.307Z',
    status: 'archived',
    documentType: 'Rainforest Alliance (RFA)',
    documentNumber: 'K',
    auditDate: '2019-07-11T07:13:54.758Z',
    from: '2019-07-27T15:22:06.403Z',
    until: '2019-02-04T07:02:28.777Z',
    filename: 'PCI',
    fileUrl: '36396',
    filehash: 'ab4694bc-b4f5-413c-9153-5d8c1ddddaf2',
    filesize: 3904,
    uploadedBy_participant: {
      username: null,
      email: null,
      participant:
        'org.powerchain.member.REFRESCOUser#REFRESCO_cbfaf845-4832-4347-acf0-4ccc3e78b69e',
      channel: {
        title: 'Oranges',
        name: 'oranges',
      },
      org: {
        name: 'REFRESCO',
        fullname: 'Refresco company',
        logo: null,
      },
      user: {
        username: 'REFRESCO ADMIN staging',
        email: 'REFRESCOADMIN@pc.t2f.io',
        org: {
          name: 'REFRESCO',
          fullname: 'Refresco company',
          logo: null,
        },
      },
    },
  },
  {
    $class: 'org.powerchain.bananas.member.Cert',
    certId: 'Cert_4e1596cb-5eeb-4e45-b46f-30cc769a165d',
    owner: 'resource:org.powerchain.bananas.member.Org#Hollander',
    uploadedBy:
      'resource:org.powerchain.bananas.member.DistributorUser#Hollander_5509e7bd-346c-4132-8587-00f1e5dccc55',
    uploadedAtMin: '2019-08-06T13:16:01.322Z',
    uploadedAtMax: '2019-08-06T13:16:01.322Z',
    status: 'archived',
    documentType: 'Rainforest Alliance (RFA)',
    documentNumber: 'N',
    auditDate: '2018-08-11T14:06:28.203Z',
    from: '2018-11-16T05:37:19.978Z',
    until: '2019-02-02T02:59:46.642Z',
    filename: 'Spurs',
    fileUrl: '61990',
    filehash: '11f1e7b8-c69a-4d09-aa1f-44804764dd64',
    filesize: 7367,
    uploadedBy_participant: {
      username: null,
      email: null,
      participant:
        'org.powerchain.member.REFRESCOUser#REFRESCO_cbfaf845-4832-4347-acf0-4ccc3e78b69e',
      channel: {
        title: 'Oranges',
        name: 'oranges',
      },
      org: {
        name: 'REFRESCO',
        fullname: 'Refresco company',
        logo: null,
      },
      user: {
        username: 'REFRESCO ADMIN staging',
        email: 'REFRESCOADMIN@pc.t2f.io',
        org: {
          name: 'REFRESCO',
          fullname: 'Refresco company',
          logo: null,
        },
      },
    },
  },
  {
    $class: 'org.powerchain.bananas.member.Cert',
    certId: 'Cert_32032e12-fa82-4489-896b-a5bada208b69',
    owner: 'resource:org.powerchain.bananas.member.Org#Hollander',
    uploadedBy:
      'resource:org.powerchain.bananas.member.DistributorUser#Hollander_5509e7bd-346c-4132-8587-00f1e5dccc55',
    uploadedAtMin: '2019-08-06T13:16:01.339Z',
    uploadedAtMax: '2019-08-06T13:16:01.339Z',
    status: 'archived',
    documentType: 'Rainforest Alliance (RFA)',
    documentNumber: '0',
    auditDate: '2019-01-31T21:59:48.675Z',
    from: '2019-02-20T07:34:38.320Z',
    until: '2018-12-09T09:23:27.044Z',
    filename: 'quantify',
    fileUrl: '47296',
    filehash: '9bd8068d-2f0a-4a0b-a05a-f15445dfe616',
    filesize: 1168,
    uploadedBy_participant: {
      username: null,
      email: null,
      participant:
        'org.powerchain.member.REFRESCOUser#REFRESCO_cbfaf845-4832-4347-acf0-4ccc3e78b69e',
      channel: {
        title: 'Oranges',
        name: 'oranges',
      },
      org: {
        name: 'REFRESCO',
        fullname: 'Refresco company',
        logo: null,
      },
      user: {
        username: 'REFRESCO ADMIN staging',
        email: 'REFRESCOADMIN@pc.t2f.io',
        org: {
          name: 'REFRESCO',
          fullname: 'Refresco company',
          logo: null,
        },
      },
    },
  },
  {
    $class: 'org.powerchain.bananas.member.Cert',
    certId: '370638dd-99d0-4328-b089-66ecc92b33ac',
    owner: 'resource:org.powerchain.bananas.member.Org#Hollander',
    uploadedBy:
      'resource:org.powerchain.bananas.member.DistributorUser#Hollander_5509e7bd-346c-4132-8587-00f1e5dccc55',
    uploadedAt: '2019-08-06T14:21:49.594Z',
    modifiedBy:
      'resource:org.powerchain.bananas.member.DistributorUser#Hollander_5509e7bd-346c-4132-8587-00f1e5dccc55',
    modifiedAtMin: '2019-08-08T07:36:00.576Z',
    modifiedAtMax: '2019-08-08T07:36:00.576Z',
    status: 'archived',
    documentType: 'IKB',
    documentNumber: 'фывфыв',
    auditDate: '2019-08-08T00:00:00.000Z',
    entityCertified: 'Packer',
    entityId: 'фывфыв',
    from: '2019-10-26T00:00:00.000Z',
    until: '2027-08-21T00:00:00.000Z',
    filename: '123',
    fileUrl: '221',
    filehash: '7fff30ef-2dfa-4c12-887d-bb28ff6f3f92',
    filesize: 123,
    uploadedBy_participant: {
      username: null,
      email: null,
      participant:
        'org.powerchain.member.REFRESCOUser#REFRESCO_cbfaf845-4832-4347-acf0-4ccc3e78b69e',
      channel: {
        title: 'Oranges',
        name: 'oranges',
      },
      org: {
        name: 'REFRESCO',
        fullname: 'Refresco company',
        logo: null,
      },
      user: {
        username: 'REFRESCO ADMIN staging',
        email: 'REFRESCOADMIN@pc.t2f.io',
        org: {
          name: 'REFRESCO',
          fullname: 'Refresco company',
          logo: null,
        },
      },
    },
  },
];

certificates[REGALSPRINGS][TILAPIA].active = [
  {
    $class: 'org.powerchain.tilapia.member.Cert',
    certId: '39fb105c-b46d-4a6a-b004-2385cdd258a1',
    owner: 'resource:org.powerchain.tilapia.member.Org#RegalSprings',
    uploadedBy:
      'resource:org.powerchain.tilapia.member.ProcessorUser#RegalSprings_58945e88-4654-42dd-9071-0025fbd6e840',
    uploadedAtMin: '2019-08-06T09:59:57.347Z',
    uploadedAtMax: '2019-08-06T09:59:57.347Z',
    status: 'PENDING',
    documentType: 'KAT',
    documentNumber: '12',
    auditDate: '2019-08-10T00:00:00.000Z',
    entityCertified: 'Packer',
    entityId: '12',
    from: '2019-08-14T00:00:00.000Z',
    until: '2019-09-01T00:00:00.000Z',
    filename: '123',
    fileUrl: '219',
    filehash: 'b0af1531-873c-4dfc-a1b8-5e8d5a8f6ad2',
    filesize: 123,
    uploadedBy_participant: {
      username: null,
      email: null,
      participant:
        'org.powerchain.member.REFRESCOUser#REFRESCO_cbfaf845-4832-4347-acf0-4ccc3e78b69e',
      channel: {
        title: 'Oranges',
        name: 'oranges',
      },
      org: {
        name: 'REFRESCO',
        fullname: 'Refresco company',
        logo: null,
      },
      user: {
        username: 'REFRESCO ADMIN staging',
        email: 'REFRESCOADMIN@pc.t2f.io',
        org: {
          name: 'REFRESCO',
          fullname: 'Refresco company',
          logo: null,
        },
      },
    },
  },
];

certificates[REGALSPRINGS][TILAPIA].archived = [
  {
    $class: 'org.powerchain.tilapia.member.Cert',
    certId: '6bd6d5b6-0a76-415f-9ced-715913273ae3',
    owner: 'resource:org.powerchain.tilapia.member.Org#RegalSprings',
    uploadedBy:
      'resource:org.powerchain.tilapia.member.ProducerUser#RegalSprings_8900eb90-c29b-458a-aa40-301057d9b81f',
    uploadedAtMin: '2019-08-07T06:57:23.053Z',
    uploadedAtMax: '2019-08-07T06:57:23.053Z',
    modifiedBy:
      'resource:org.powerchain.tilapia.member.ProducerUser#RegalSprings_8900eb90-c29b-458a-aa40-301057d9b81f',
    modifiedAt: '2019-08-07T06:57:41.752Z',
    status: 'archived',
    documentType: 'BAP',
    documentNumber: '123',
    auditDate: '2019-08-07T00:00:00.000Z',
    entityCertified: 'Packer',
    entityId: '123',
    from: '2019-08-14T00:00:00.000Z',
    until: '2019-09-01T00:00:00.000Z',
    filename: '123',
    fileUrl: '223',
    filehash: '781cf12a-d273-43e0-91ec-98fcea783b4a',
    filesize: 123,
    uploadedBy_participant: {
      username: null,
      email: null,
      participant:
        'org.powerchain.member.REFRESCOUser#REFRESCO_cbfaf845-4832-4347-acf0-4ccc3e78b69e',
      channel: {
        title: 'Oranges',
        name: 'oranges',
      },
      org: {
        name: 'REFRESCO',
        fullname: 'Refresco company',
        logo: null,
      },
      user: {
        username: 'REFRESCO ADMIN staging',
        email: 'REFRESCOADMIN@pc.t2f.io',
        org: {
          name: 'REFRESCO',
          fullname: 'Refresco company',
          logo: null,
        },
      },
    },
  },
  {
    $class: 'org.powerchain.tilapia.member.Cert',
    certId: '02e7ce7d-4ad8-4626-9824-3f3a67d743a6',
    owner: 'resource:org.powerchain.tilapia.member.Org#RegalSprings',
    uploadedBy:
      'resource:org.powerchain.tilapia.member.ProducerUser#RegalSprings_8900eb90-c29b-458a-aa40-301057d9b81f',
    uploadedAtMin: '2019-08-06T09:58:11.836Z',
    uploadedAtMax: '2019-08-06T09:58:11.836Z',
    modifiedBy:
      'resource:org.powerchain.tilapia.member.ProducerUser#RegalSprings_02c43a1f-0ca9-41db-91e5-2691ec3eeb69',
    modifiedAt: '2019-08-06T14:58:19.054Z',
    status: 'archived',
    documentType: 'BLK Level',
    documentNumber: '12',
    auditDate: '2019-08-09T00:00:00.000Z',
    entityCertified: 'Packer',
    entityId: '12',
    from: '2019-08-19T00:00:00.000Z',
    until: '2019-09-01T00:00:00.000Z',
    filename: '123',
    fileUrl: '216',
    filehash: 'b3efdcf6-8ed6-4eb6-bc71-e3a8b8f956d0',
    filesize: 123,
    uploadedBy_participant: {
      username: null,
      email: null,
      participant:
        'org.powerchain.member.REFRESCOUser#REFRESCO_cbfaf845-4832-4347-acf0-4ccc3e78b69e',
      channel: {
        title: 'Oranges',
        name: 'oranges',
      },
      org: {
        name: 'REFRESCO',
        fullname: 'Refresco company',
        logo: null,
      },
      user: {
        username: 'REFRESCO ADMIN staging',
        email: 'REFRESCOADMIN@pc.t2f.io',
        org: {
          name: 'REFRESCO',
          fullname: 'Refresco company',
          logo: null,
        },
      },
    },
  },
];

certificates[SEAFOODCONNECTION][TILAPIA].active = [
  {
    $class: 'org.powerchain.tilapia.member.Cert',
    certId: '6b3f6410-b5f4-4ab6-8284-2a3ae19c2adc',
    owner: 'resource:org.powerchain.tilapia.member.Org#SeafoodConnection',
    uploadedBy:
      'resource:org.powerchain.tilapia.member.ImporterUser#SeafoodConnection_4d94b219-0e64-45f0-ba42-32eff7b0d7d6',
    uploadedAtMin: '2019-08-06T09:58:56.060Z',
    uploadedAtMax: '2019-08-06T09:58:56.060Z',
    status: 'PENDING',
    documentType: 'BLK Level',
    documentNumber: '12',
    auditDate: '2019-08-01T00:00:00.000Z',
    entityCertified: 'Packer',
    entityId: '12',
    from: '2019-08-05T00:00:00.000Z',
    until: '2019-08-22T00:00:00.000Z',
    filename: '123',
    fileUrl: '218',
    filehash: '3fd72023-8cfb-4a4e-8e93-a31f2ba49fbe',
    filesize: 123,
    uploadedBy_participant: {
      username: null,
      email: null,
      participant:
        'org.powerchain.member.REFRESCOUser#REFRESCO_cbfaf845-4832-4347-acf0-4ccc3e78b69e',
      channel: {
        title: 'Oranges',
        name: 'oranges',
      },
      org: {
        name: 'REFRESCO',
        fullname: 'Refresco company',
        logo: null,
      },
      user: {
        username: 'REFRESCO ADMIN staging',
        email: 'REFRESCOADMIN@pc.t2f.io',
        org: {
          name: 'REFRESCO',
          fullname: 'Refresco company',
          logo: null,
        },
      },
    },
  },
];

certificates[SEAFOODCONNECTION][TILAPIA].archived = [
  {
    $class: 'org.powerchain.tilapia.member.Cert',
    certId: '42f2faf7-e3ea-41f3-8959-337c6fd3a128',
    owner: 'resource:org.powerchain.tilapia.member.Org#SeafoodConnection',
    uploadedBy:
      'resource:org.powerchain.tilapia.member.RetailerUser#SeafoodConnection_e1d059a8-05a0-40e9-ae9f-256341b22c96',
    uploadedAtMin: '2019-08-06T09:57:26.580Z',
    uploadedAtMax: '2019-08-06T09:57:26.580Z',
    modifiedBy:
      'resource:org.powerchain.tilapia.member.RetailerUser#SeafoodConnection_e1d059a8-05a0-40e9-ae9f-256341b22c96',
    modifiedAt: '2019-08-06T14:02:52.250Z',
    status: 'archived',
    documentType: 'BLK Level',
    documentNumber: '12',
    auditDate: '2019-08-15T00:00:00.000Z',
    entityCertified: 'Packer',
    entityId: '12',
    from: '2019-08-29T00:00:00.000Z',
    until: '2019-09-01T00:00:00.000Z',
    filename: '123',
    fileUrl: '215',
    filehash: '7e71fb75-11b0-4d30-bd85-ac113f81bf08',
    filesize: 123,
    uploadedBy_participant: {
      username: null,
      email: null,
      participant:
        'org.powerchain.member.REFRESCOUser#REFRESCO_cbfaf845-4832-4347-acf0-4ccc3e78b69e',
      channel: {
        title: 'Oranges',
        name: 'oranges',
      },
      org: {
        name: 'REFRESCO',
        fullname: 'Refresco company',
        logo: null,
      },
      user: {
        username: 'REFRESCO ADMIN staging',
        email: 'REFRESCOADMIN@pc.t2f.io',
        org: {
          name: 'REFRESCO',
          fullname: 'Refresco company',
          logo: null,
        },
      },
    },
  },
];

certificates[JUMBO][TILAPIA].active = [
  {
    $class: 'org.powerchain.tilapia.member.Cert',
    certId: 'a6f531f5-a5d4-48c4-9173-13078325987a',
    owner: 'resource:org.powerchain.tilapia.member.Org#Jumbo',
    uploadedBy:
      'resource:org.powerchain.tilapia.member.RetailerUser#Jumbo_e1d059a8-05a0-40e9-ae9f-256341b22c96',
    uploadedAtMin: '2019-08-15T08:51:16.878Z',
    uploadedAtMax: '2019-08-15T08:51:16.878Z',
    status: 'PENDING',
    documentType: 'IFS Food',
    documentNumber: '12345-09876',
    auditDate: '2019-08-01T00:00:00.000Z',
    entityCertified: 'Farm',
    entityId: '123466',
    notes: 'test notes',
    from: '2019-08-16T00:00:00.000Z',
    until: '2019-08-23T00:00:00.000Z',
    filename: '123',
    fileUrl: '226',
    filehash: 'd503feb3-2ce9-4988-850c-e702787567dd',
    filesize: 123,
    uploadedBy_participant: {
      username: null,
      email: null,
      participant:
        'org.powerchain.member.REFRESCOUser#REFRESCO_cbfaf845-4832-4347-acf0-4ccc3e78b69e',
      channel: {
        title: 'Oranges',
        name: 'oranges',
      },
      org: {
        name: 'REFRESCO',
        fullname: 'Refresco company',
        logo: null,
      },
      user: {
        username: 'REFRESCO ADMIN staging',
        email: 'REFRESCOADMIN@pc.t2f.io',
        org: {
          name: 'REFRESCO',
          fullname: 'Refresco company',
          logo: null,
        },
      },
    },
  },
  {
    $class: 'org.powerchain.tilapia.member.Cert',
    certId: '95f4d3ac-a8df-4539-9fe7-933f56f78b88',
    owner: 'resource:org.powerchain.tilapia.member.Org#Jumbo',
    uploadedBy:
      'resource:org.powerchain.tilapia.member.RetailerUser#Jumbo_e1d059a8-05a0-40e9-ae9f-256341b22c96',
    uploadedAtMin: '2019-08-06T14:48:05.476Z',
    uploadedAtMax: '2019-08-06T14:48:05.476Z',
    status: 'PENDING',
    documentType: 'ASC tilapia',
    documentNumber: '123',
    auditDate: '2019-07-30T00:00:00.000Z',
    entityCertified: 'Farm',
    entityId: '123',
    notes: '123 123 123 ',
    from: '2019-07-31T00:00:00.000Z',
    until: '2019-08-10T00:00:00.000Z',
    filename: '123',
    fileUrl: '222',
    filehash: 'd430a5c1-d909-4ef8-9ca1-e449695869d0',
    filesize: 123,
    uploadedBy_participant: {
      username: null,
      email: null,
      participant:
        'org.powerchain.member.REFRESCOUser#REFRESCO_cbfaf845-4832-4347-acf0-4ccc3e78b69e',
      channel: {
        title: 'Oranges',
        name: 'oranges',
      },
      org: {
        name: 'REFRESCO',
        fullname: 'Refresco company',
        logo: null,
      },
      user: {
        username: 'REFRESCO ADMIN staging',
        email: 'REFRESCOADMIN@pc.t2f.io',
        org: {
          name: 'REFRESCO',
          fullname: 'Refresco company',
          logo: null,
        },
      },
    },
  },
];

certificates[JUMBO][TILAPIA].archived = [
  {
    $class: 'org.powerchain.tilapia.member.Cert',
    certId: '42f2faf7-e3ea-41f3-8959-337c6fd3a128',
    owner: 'resource:org.powerchain.tilapia.member.Org#Jumbo',
    uploadedBy:
      'resource:org.powerchain.tilapia.member.RetailerUser#Jumbo_e1d059a8-05a0-40e9-ae9f-256341b22c96',
    uploadedAtMin: '2019-08-06T09:57:26.580Z',
    uploadedAtMax: '2019-08-06T09:57:26.580Z',
    modifiedBy:
      'resource:org.powerchain.tilapia.member.RetailerUser#Jumbo_e1d059a8-05a0-40e9-ae9f-256341b22c96',
    modifiedAt: '2019-08-06T14:02:52.250Z',
    status: 'archived',
    documentType: 'BLK Level',
    documentNumber: '12',
    auditDate: '2019-08-15T00:00:00.000Z',
    entityCertified: 'Packer',
    entityId: '12',
    from: '2019-08-29T00:00:00.000Z',
    until: '2019-09-01T00:00:00.000Z',
    filename: '123',
    fileUrl: '215',
    filehash: '7e71fb75-11b0-4d30-bd85-ac113f81bf08',
    filesize: 123,
    uploadedBy_participant: {
      username: null,
      email: null,
      participant:
        'org.powerchain.member.REFRESCOUser#REFRESCO_cbfaf845-4832-4347-acf0-4ccc3e78b69e',
      channel: {
        title: 'Oranges',
        name: 'oranges',
      },
      org: {
        name: 'REFRESCO',
        fullname: 'Refresco company',
        logo: null,
      },
      user: {
        username: 'REFRESCO ADMIN staging',
        email: 'REFRESCOADMIN@pc.t2f.io',
        org: {
          name: 'REFRESCO',
          fullname: 'Refresco company',
          logo: null,
        },
      },
    },
  },
];

certificates[MAYONNA][TILAPIA].active = [
  {
    $class: 'org.powerchain.tilapia.member.Cert',
    certId: '39fb105c-b46d-4a6a-b004-2385cdd258a1',
    owner: 'resource:org.powerchain.tilapia.member.Org#Mayonna',
    uploadedBy:
      'resource:org.powerchain.tilapia.member.ProcessorUser#Mayonna_58945e88-4654-42dd-9071-0025fbd6e840',
    uploadedAtMin: '2019-08-06T09:59:57.347Z',
    uploadedAtMax: '2019-08-06T09:59:57.347Z',
    status: 'PENDING',
    documentType: 'KAT',
    documentNumber: '12',
    auditDate: '2019-08-10T00:00:00.000Z',
    entityCertified: 'Packer',
    entityId: '12',
    from: '2019-08-14T00:00:00.000Z',
    until: '2019-09-01T00:00:00.000Z',
    filename: '123',
    fileUrl: '219',
    filehash: 'b0af1531-873c-4dfc-a1b8-5e8d5a8f6ad2',
    filesize: 123,
    uploadedBy_participant: {
      username: null,
      email: null,
      participant:
        'org.powerchain.member.REFRESCOUser#REFRESCO_cbfaf845-4832-4347-acf0-4ccc3e78b69e',
      channel: {
        title: 'Oranges',
        name: 'oranges',
      },
      org: {
        name: 'REFRESCO',
        fullname: 'Refresco company',
        logo: null,
      },
      user: {
        username: 'REFRESCO ADMIN staging',
        email: 'REFRESCOADMIN@pc.t2f.io',
        org: {
          name: 'REFRESCO',
          fullname: 'Refresco company',
          logo: null,
        },
      },
    },
  },
];

certificates[MAYONNA][TILAPIA].archived = [
  {
    $class: 'org.powerchain.tilapia.member.Cert',
    certId: '42f2faf7-e3ea-41f3-8959-337c6fd3a128',
    owner: 'resource:org.powerchain.tilapia.member.Org#Mayonna',
    uploadedBy:
      'resource:org.powerchain.tilapia.member.RetailerUser#Mayonna_e1d059a8-05a0-40e9-ae9f-256341b22c96',
    uploadedAtMin: '2019-08-06T09:57:26.580Z',
    uploadedAtMax: '2019-08-06T09:57:26.580Z',
    modifiedBy:
      'resource:org.powerchain.tilapia.member.RetailerUser#Mayonna_e1d059a8-05a0-40e9-ae9f-256341b22c96',
    modifiedAt: '2019-08-06T14:02:52.250Z',
    status: 'archived',
    documentType: 'BLK Level',
    documentNumber: '12',
    auditDate: '2019-08-15T00:00:00.000Z',
    entityCertified: 'Packer',
    entityId: '12',
    from: '2019-08-29T00:00:00.000Z',
    until: '2019-09-01T00:00:00.000Z',
    filename: '123',
    fileUrl: '215',
    filehash: '7e71fb75-11b0-4d30-bd85-ac113f81bf08',
    filesize: 123,
    uploadedBy_participant: {
      username: null,
      email: null,
      participant:
        'org.powerchain.member.REFRESCOUser#REFRESCO_cbfaf845-4832-4347-acf0-4ccc3e78b69e',
      channel: {
        title: 'Oranges',
        name: 'oranges',
      },
      org: {
        name: 'REFRESCO',
        fullname: 'Refresco company',
        logo: null,
      },
      user: {
        username: 'REFRESCO ADMIN staging',
        email: 'REFRESCOADMIN@pc.t2f.io',
        org: {
          name: 'REFRESCO',
          fullname: 'Refresco company',
          logo: null,
        },
      },
    },
  },
];

const activeCertificateEggs = [
  {
    $class: 'org.powerchain.egg.member.Cert',
    certId: '1a90a4a7-757d-47d2-90dd-8d070803adb7',
    owner: 'resource:org.powerchain.egg.member.Org#EGG1',
    uploadedBy:
      'resource:org.powerchain.egg.member.EGGUser#EGG1_6bc380d4-5b16-4b87-8529-5b1f76034e64',
    uploadedAt: '2019-04-26T10:57:22.993Z',
    status: 'APPROVED',
    documentType: 'KAT',
    entityCertified: 'Farm',
    entityId: '2-NL-test',
    notes: 'This is a test certificate',
    from: '2019-04-17T00:00:00.000Z',
    until: '2020-04-16T00:00:00.000Z',
    filename: '123',
    fileUrl: '152',
    filehash: 'ceec6938-f2e7-40d2-b99f-69e7e1aa7267',
    filesize: 123,
    approvedBy:
      'resource:org.powerchain.egg.member.SIMUser#SIM_f4d4f244-770d-44c8-a081-00864d66e77b',
    approvedAt: '2019-04-26T14:56:36.261Z',
    approveMsg: 'SaE',
    approveReason: 'Verification Accepted by SIM',
    uploadedBy_participant: {
      username: null,
      email: null,
      participant:
        'org.powerchain.member.REFRESCOUser#REFRESCO_cbfaf845-4832-4347-acf0-4ccc3e78b69e',
      channel: {
        title: 'Oranges',
        name: 'oranges',
      },
      org: {
        name: 'REFRESCO',
        fullname: 'Refresco company',
        logo: null,
      },
      user: {
        username: 'REFRESCO ADMIN staging',
        email: 'REFRESCOADMIN@pc.t2f.io',
        org: {
          name: 'REFRESCO',
          fullname: 'Refresco company',
          logo: null,
        },
      },
    },
  },
];

const archivedCertificateEggs = [
  {
    $class: 'org.powerchain.egg.member.Cert',
    certId: 'b573bd16-b318-45d8-b213-7ea61fe64617',
    owner: 'resource:org.powerchain.egg.member.Org#EGG1',
    uploadedBy:
      'resource:org.powerchain.egg.member.EGGUser#EGG1_6bc380d4-5b16-4b87-8529-5b1f76034e64',
    uploadedAt: '2019-02-08T10:14:12.243Z',
    modifiedBy:
      'resource:org.powerchain.egg.member.EGGUser#EGG1_6bc380d4-5b16-4b87-8529-5b1f76034e64',
    modifiedAt: '2019-02-08T10:14:18.620Z',
    status: 'ARCHIVED',
    documentType: 'KAT',
    entityCertified: 'Packer',
    entityId: 'ee',
    notes: 'ee',
    from: '2019-02-12T00:00:00.000Z',
    until: '2019-02-22T00:00:00.000Z',
    filename: '123',
    fileUrl: '125',
    filehash: '7bc01b5a-6a9e-4acd-94f3-3fbb45242dff',
    filesize: 123,
    uploadedBy_participant: {
      username: null,
      email: null,
      participant:
        'org.powerchain.member.REFRESCOUser#REFRESCO_cbfaf845-4832-4347-acf0-4ccc3e78b69e',
      channel: {
        title: 'Oranges',
        name: 'oranges',
      },
      org: {
        name: 'REFRESCO',
        fullname: 'Refresco company',
        logo: null,
      },
      user: {
        username: 'REFRESCO ADMIN staging',
        email: 'REFRESCOADMIN@pc.t2f.io',
        org: {
          name: 'REFRESCO',
          fullname: 'Refresco company',
          logo: null,
        },
      },
    },
  },
];

certificates[SIM][EGGS].active = activeCertificateEggs;
certificates[AH][EGGS].active = activeCertificateEggs;
certificates[EGG1][EGGS].active = activeCertificateEggs;
certificates[EGG2][EGGS].active = activeCertificateEggs;

certificates[SIM][EGGS].archived = archivedCertificateEggs;
certificates[AH][EGGS].archived = archivedCertificateEggs;
certificates[EGG1][EGGS].archived = archivedCertificateEggs;
certificates[EGG2][EGGS].archived = archivedCertificateEggs;

const activeCertificateOranges = [
  {
    $class: 'org.powerchain.member.Cert',
    certId: 'ad41c8ec-6c5d-4f86-b3de-35fd6e10b331',
    owner: 'resource:org.powerchain.member.Org#JDE',
    category: 'Product/Food Safety',
    documentType: 'Food Safety System Certification 22000',
    result: 'Non Compliant',
    scope: 'AII. Farming of fish and seafood',
    number: '123123',
    auditDate: '2019-06-13T00:00:00.000Z',
    notes: '123123',
    from: '2019-06-14T00:00:00.000Z',
    until: '2019-06-21T00:00:00.000Z',
    status: 'PENDING',
    filename: '123',
    fileUrl: '192',
    filehash: 'e70ce46e-f853-424d-995f-f24099b8d141',
    filesize: 123,
    uploadedBy: 'resource:org.powerchain.member.JDEUser#JDE_e4e32907-61ac-4509-9177-07b320ef9f47',
    uploadedAt: '2019-06-14T09:51:25.647Z',
    uploadedBy_participant: {
      username: null,
      email: null,
      participant:
        'org.powerchain.member.REFRESCOUser#REFRESCO_cbfaf845-4832-4347-acf0-4ccc3e78b69e',
      channel: {
        title: 'Oranges',
        name: 'oranges',
      },
      org: {
        name: 'REFRESCO',
        fullname: 'Refresco company',
        logo: null,
      },
      user: {
        username: 'REFRESCO ADMIN staging',
        email: 'REFRESCOADMIN@pc.t2f.io',
        org: {
          name: 'REFRESCO',
          fullname: 'Refresco company',
          logo: null,
        },
      },
    },
  },
];

const archivedCertificateOranges = [
  {
    $class: 'org.powerchain.member.Cert',
    certId: '347ba16c-1198-4ede-b209-f5f72768cf8d',
    owner: 'resource:org.powerchain.member.Org#JDE',
    category: 'Product/Food Safety',
    documentType: 'BRC Food',
    result: 'A+',
    scope: '05. Fruits, vegetables and nuts',
    number: '123123123',
    auditDate: '2019-02-27T00:00:00.000Z',
    from: '2019-03-01T00:00:00.000Z',
    until: '2019-03-05T00:00:00.000Z',
    status: 'ARCHIVED',
    filename: '123',
    fileUrl: '136',
    filehash: '2459d007-bad6-4d1f-844c-e8f284688f66',
    filesize: 123,
    uploadedBy: 'resource:org.powerchain.member.JDEUser#JDE_e4e32907-61ac-4509-9177-07b320ef9f47',
    uploadedAt: '2019-03-19T10:45:20.817Z',
    modifiedBy: 'resource:org.powerchain.member.JDEUser#JDE_e4e32907-61ac-4509-9177-07b320ef9f47',
    modifiedAt: '2019-03-19T10:52:05.647Z',
    uploadedBy_participant: {
      username: null,
      email: null,
      participant:
        'org.powerchain.member.REFRESCOUser#REFRESCO_cbfaf845-4832-4347-acf0-4ccc3e78b69e',
      channel: {
        title: 'Oranges',
        name: 'oranges',
      },
      org: {
        name: 'REFRESCO',
        fullname: 'Refresco company',
        logo: null,
      },
      user: {
        username: 'REFRESCO ADMIN staging',
        email: 'REFRESCOADMIN@pc.t2f.io',
        org: {
          name: 'REFRESCO',
          fullname: 'Refresco company',
          logo: null,
        },
      },
    },
  },
];

certificates[SIM][ORANGES].active = activeCertificateOranges;
certificates[AH][ORANGES].active = activeCertificateOranges;
certificates[REFRESCO][ORANGES].active = activeCertificateOranges;
certificates[JDE][ORANGES].active = activeCertificateOranges;
certificates[LDCBR][ORANGES].active = activeCertificateOranges;

certificates[SIM][ORANGES].archived = archivedCertificateOranges;
certificates[AH][ORANGES].archived = archivedCertificateOranges;
certificates[REFRESCO][ORANGES].archived = archivedCertificateOranges;
certificates[JDE][ORANGES].archived = archivedCertificateOranges;
certificates[LDCBR][ORANGES].archived = archivedCertificateOranges;

// JUICE
certificates[SIM][JUICE].active = activeCertificateOranges;
certificates[CITROSUCOBR][JUICE].active = activeCertificateOranges;
certificates[CITROSUCOGHENT][JUICE].active = activeCertificateOranges;
certificates[KAUFLANDDE][JUICE].active = activeCertificateOranges;
certificates[REFRESCOGMBH][JUICE].active = activeCertificateOranges;
certificates[REFRESCO][JUICE].active = activeCertificateOranges;

certificates[SIM][JUICE].archived = archivedCertificateOranges;
certificates[CITROSUCOBR][JUICE].archived = archivedCertificateOranges;
certificates[CITROSUCOGHENT][JUICE].archived = archivedCertificateOranges;
certificates[KAUFLANDDE][JUICE].archived = archivedCertificateOranges;
certificates[REFRESCOGMBH][JUICE].archived = archivedCertificateOranges;
certificates[REFRESCO][JUICE].archived = archivedCertificateOranges;

export default certificates;
