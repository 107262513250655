import { peek, selector } from 'lib/selectors';
import { UnmatchedResponse, UnmatchedStatResponse } from 'lib/lib';
import { AppStateType } from 'types';

const unmatched = (state: AppStateType) => state.unmatched;

export const getUnmatchedBCs = selector(unmatched, peek('resources'));
export const getUnmatchedBatchesList = selector<UnmatchedResponse>(unmatched, peek('batchesList'));
export const getUnmatchedStatistics = selector<UnmatchedStatResponse[]>(unmatched, peek('statistics'));
export const getIsLoading = selector<boolean>(unmatched, peek('isLoading'));
