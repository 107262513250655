import React from 'react';
import { Link } from 'react-router-dom';
import { getUrl } from 'routes';
import { CHANNELS, ORGANIZATIONS } from 'themes/constants';
// components
import ModelSettingsDropdown from 'components/Dropdown/ModelSettingsDropDown';
import ArchiveContractButton from 'models/utils/ArchiveContractButton/ArchiveContractButton';
import { BatchRecord } from 'lib/lib';

export default {
  key: 'settingsAndActions',
  title: (
    <React.Fragment>
      <ModelSettingsDropdown />
    </React.Fragment>
  ),
  style: { flex: '1 0 105px', justifyContent: 'space-evenly' },
  render: (column: BatchRecord) => (
    <React.Fragment>
      <Link
        className="contract batch-details"
        to={{
          pathname: getUrl('BatchContractType', {
            type: ORGANIZATIONS.EGG1.toLowerCase(),
            contract_number: column.uploadId,
            modelType: 'contractDetails',
            channelName: CHANNELS.EGGS,
          }),
          state: {},
        }}
      >
        Details
      </Link>
      <ArchiveContractButton column={column} buttonClassName="contract batch-archive" />
    </React.Fragment>
  ),
};
