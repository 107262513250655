import signInResponse from 'mocks/responses/signIn';

import { MocksUsers } from 'mocks/users';

export default (request: any) => {

  const { email } = JSON.parse(request.data);
  const currentOrg = MocksUsers.find((f) => f.email === email);

  if (currentOrg) {
    return [200, signInResponse(currentOrg.token)];
  }

  return [500, { message: 'There is no such email' }];
};
