import React, { useEffect, useMemo } from 'react';
import { change, untouch, WrappedFieldInputProps, WrappedFieldProps } from 'redux-form';

import Layout from 'components/Form/Layout';
// jss
import injectSheet, { WithStyles } from 'react-jss';
import { inputsStyles } from 'components/Form/Layout/styles';
//

interface OwnProps
  extends WrappedFieldProps,
    WrappedFieldInputProps,
    WithStyles<typeof inputsStyles> {
  className?: string;
  title?: string;
  placeholder?: string;
  disabled?: boolean;
  style?: object;
  type: string;
}

const Input: React.FC<OwnProps> = (props) => {
  const { input, classes, type, title, style = {}, meta, disabled, className, ...rest } = props;

  const isError = useMemo(() => meta.touched && meta.error, [meta]);

  useEffect(() => {
    if (disabled) {
      meta.dispatch(change(meta.form, input.name, null));
      meta.dispatch(untouch(meta.form, input.name));
    }
  }, [disabled]);

  return (
    <Layout title={title} meta={meta} id={input.name} style={style}>
      <input
        id={input.name}
        type={type}
        className={`${classes.input} ${className || classes.control} ${isError &&
          classes.controlError}`}
        disabled={disabled}
        {...input}
        {...rest}
      />
    </Layout>
  );
};

export default injectSheet(inputsStyles)(Input);
