import React from 'react';
import { WrappedFieldMetaProps } from 'redux-form';
import Message from 'components/Form/Message/index';
// jss
import injectSheet, { WithStyles } from 'react-jss';

import { layoutStyles } from './styles';

interface OwnProps extends WithStyles<typeof layoutStyles> {
  id: string;
  meta: WrappedFieldMetaProps;
  title?: string;
  style?: object;
}

const Layout: React.FC<OwnProps> = (props) => {
  const { classes, children, id, meta, title, style } = props;
  return (
    <div className={classes.inputRow} style={style}>
      <div className={classes.wrapper}>
        <label htmlFor={id} className={classes.labelText}>
          {title}
        </label>
        <Message meta={meta} className="" />
      </div>
      <div className={classes.formGroup}>{children}</div>
    </div>
  );
};

export default injectSheet(layoutStyles)(Layout);
