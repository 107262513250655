import React from 'react';
import { renderShortDateFormat } from 'lib/renderHelpers';

// components
import baseModelFields from './baseModelFields';
import { BatchRecord, ModelRequiredInfo } from 'lib/lib';

export default (modelRequiredInfo: ModelRequiredInfo) => [
  {
    key: 'articleNumberConsumerProduct',
    label: 'Article number consumer product',
    title: 'Article number consumer product',
    style: { flex: '1 0 150px' },
    render: (column: BatchRecord) => column.articleNumberConsumerProduct,
  },
  {
    key: 'articleDescriptionConsumerProduct',
    label: 'Article description consumer product',
    title: 'Article description consumer product',
    style: { flex: '0 0 250px' },
    render: (column: BatchRecord) => column.articleDescriptionConsumerProduct,
  },
  {
    key: 'specie',
    label: 'Specie',
    title: 'Specie',
    style: { flex: '1 0 100px' },
    render: (column: BatchRecord) => column.specie,
  },
  {
    key: 'productType',
    label: 'Product type (Fil-Loin-skin on/ off)',
    title: 'Product type (Fil-Loin-skin on/ off)',
    style: { flex: '1 0 100px' },
    defaultSelectedBatchContractColumn: true,
    render: (column: BatchRecord) => column.productType,
  },
  {
    key: 'volumeConsumerProductInKg',
    label: 'Volume Consumer product in KG',
    title: 'Volume consumer product in KG',
    style: { flex: '1 0 100px' },
    render: (column: BatchRecord) => column.volumeConsumerProductInKg,
  },
  {
    key: 'numberOfConsumerPackages',
    label: 'Number of consumer packages',
    title: 'Number of consumer packages',
    style: { flex: '1 0 100px' },
    render: (column: BatchRecord) => column.numberOfConsumerPackages,
  },
  {
    key: 'processingDate',
    label: 'Processing date',
    title: 'Processing date',
    style: { flex: '1 0 100px' },
    render: (column: BatchRecord) => renderShortDateFormat(column.processingDate),
  },
  {
    key: 'deliveryDate',
    label: 'Delivery date',
    title: 'Delivery date',
    style: { flex: '1 0 100px' },
    render: (column: BatchRecord) => renderShortDateFormat(column.deliveryDate),
  },
  {
    key: 'thtConsumerProduct',
    label: 'THT consumer product',
    title: 'THT consumer product',
    style: { flex: '1 0 150px' },
    defaultSelectedBatchContractColumn: true,
    render: (column: BatchRecord) => renderShortDateFormat(column.thtConsumerProduct),
  },
  {
    key: 'productBatchIdBulk',
    label: 'Product/ batch ID bulk',
    title: 'Product/ batch ID bulk',
    style: { flex: '1 0 150px' },
    defaultSelectedBatchContractColumn: true,
    render: (column: BatchRecord) => column.productBatchIdBulk,
  },
  {
    key: 'articleNumberBulk',
    label: 'Article number bulk',
    title: 'Article number bulk',
    style: { flex: '1 0 200px' },
    render: (column: BatchRecord) => column.articleNumberBulk,
  },
  {
    key: 'articleDescriptionBulk',
    label: 'Article description bulk',
    title: 'Article description bulk',
    style: { flex: '1 0 150px' },
    render: (column: BatchRecord) => column.articleDescriptionBulk,
  },
  {
    key: 'dateReceipt',
    label: 'Date receipt',
    title: 'Date receipt',
    style: { flex: '1 0 100px' },
    render: (column: BatchRecord) => renderShortDateFormat(column.dateReceipt),
  },
  {
    key: 'thtBulk',
    label: 'THT bulk',
    title: 'THT bulk',
    style: { flex: '1 0 100px' },
    defaultSelectedBatchContractColumn: true,
    render: (column: BatchRecord) => renderShortDateFormat(column.thtBulk),
  },
  {
    key: 'volumeBulkInKg',
    label: 'Volume bulk in KG',
    title: 'Volume bulk in KG',
    style: { flex: '1 0 150px' },
    render: (column: BatchRecord) => column.volumeBulkInKg,
  },
  ...baseModelFields(),
];
