import React, { useState, useEffect, Dispatch, SetStateAction } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
// redux
import * as batchesActions from 'domain/batches/actions';
import { getBatch, getIsLoading } from 'domain/batches';
import { getBatches, getParams, getModel } from 'domain/common';
// utils
import { exportExcelFile } from 'lib/helpers';
// components
import DataTableViewTelapiaBatch from 'components/Datatable/dataTableViewTelapiaBatch';
// icons
import { ExcelSvg } from 'components/icons';
// jss
import injectSheet, { WithStyles } from 'react-jss';
import batchDetailsTabsStyles from 'pages/batches/styles/batchDetailsTabsStyles';
// types
import { Tab } from 'lib/lib';
import { AppStateType } from 'types';

interface ChangeTabProps {
  activeTabName: string;
  changeTabCallback: Dispatch<SetStateAction<string>>;
}

interface BatchTabsProps extends ChangeTabProps {
  tabs: Tab[];
}

const BatchTabs: React.FC<BatchTabsProps> = (props) => {
  const { tabs, activeTabName, changeTabCallback } = props;

  return (
    <div className="tabsInner">
      {tabs.map((tab) => (
        <BatchTab
          key={tab.originalName}
          tab={tab}
          activeTabName={activeTabName}
          changeTabCallback={changeTabCallback}
        />
      ))}
    </div>
  );
};

interface BatchTabProps extends ChangeTabProps {
  tab: Tab;
}

const BatchTab = (props: BatchTabProps) => {
  const { tab, activeTabName, changeTabCallback } = props;
  const { modelName, title } = tab;

  return (
    <div
      key={modelName}
      data-tab={title}
      className={`fileTab ${modelName === activeTabName ? 'active' : ''}`}
      onClick={() => changeTabCallback(modelName)}
    >
      {title}
    </div>
  );
};

const mapStateToPropsBatchHeader = (state: AppStateType) => ({
  batch: getBatch(state),
  params: getParams(state),
  model: getModel(state),
});

const BatchHeader: React.FC = () => {
  const { batch, model, params } = useSelector(mapStateToPropsBatchHeader, shallowEqual);

  return (
    <header>
      <div className="batchCode">{`Batch code: ${params.batchCode}`}</div>
      <div
        className="exportToExcel"
        data-cy="download"
        onClick={() => exportExcelFile(batch, model, undefined, 'contract_details.xlsx')}
      >
        <ExcelSvg width={24} height={24} />
        <span>Export To Excel</span>
      </div>
    </header>
  );
};

const mapStateToPropsBatchDetailsTabs = (state: AppStateType) => ({
  batch: getBatch(state),
  isLoading: getIsLoading(state),
  batches: getBatches(state),
  params: getParams(state),
  model: getModel(state),
});

const BatchDetailsTabs: React.FC<WithStyles<typeof batchDetailsTabsStyles>> = (props) => {
  const dispatch = useDispatch();
  const { params, model, batch, isLoading, batches } = useSelector(
    mapStateToPropsBatchDetailsTabs,
    shallowEqual,
  );
  const { classes } = props;
  const { batchCode, status } = params;
  const { ProducerBatch } = batches;
  const { endpoint } = ProducerBatch;
  const { tabs } = model;

  const [activeTabName, changeTabTo] = useState('detailList');

  useEffect(() => {
    dispatch(
      batchesActions.fetchBatchWithUserAction({
        batchEndpoint: endpoint,
        batchId: batchCode,
        filters: status ? { status } : {},
      }),
    );
  }, [batchCode, endpoint]);

  return (
    <div className={classes.component}>
      <BatchHeader />

      <BatchTabs changeTabCallback={changeTabTo} activeTabName={activeTabName} tabs={tabs} />

      <DataTableViewTelapiaBatch
        data={batch[activeTabName]}
        columns={model[activeTabName]}
        isLoading={isLoading}
      />
    </div>
  );
};

export default injectSheet(batchDetailsTabsStyles)(BatchDetailsTabs);
