import { Theme } from 'lib/lib';

export default (theme: Theme) => ({
  detailedViewTable: {
    display: 'flex',
    '& .fieldLabels, & .fieldsColumn': {
      '& .columnHead': {
        height: 25,
        padding: [0, 10],
        whiteSpace: 'nowrap',
        border: `1px solid ${'rgb(102, 102, 102, 0.6)'}`,
        '& svg': {
          marginRight: 10,
        },
      },
    },
    '& .fieldLabels': {
      flex: '0 0 200px',
      '& > div': {
        height: 25,
        display: 'flex',
        flexWrap: 'nowrap',
        alignItems: 'center',
        backgroundColor: 'rgb(102, 102, 102)',
        color: theme.colors.white,
        fontSize: theme.fontSizes.sm,
        borderBottom: '1px solid white',
        textAlign: 'left',
        overflow: 'hidden',
      },
    },
    '& .fieldsData': {
      flex: '1 0 300px',
      display: 'inline-flex',
      flexWrap: 'nowrap',
      overflowX: 'auto',
      '& .fieldsColumn': {
        flexGrow: 1,
        '& > div > div': {
          display: 'flex',
          height: 25,
          alignItems: 'center',
          justifyContent: 'center',
          borderBottom: `1px solid ${'rgb(102, 102, 102, 0.6)'}`,
          borderRight: `1px solid ${'rgb(102, 102, 102, 0.6)'}`,
          '&.changed': {
            padding: 0,
            display: 'flex',
            alignItems: 'stretch',

            '& .handled, & .parsed': {
              flexGrow: 1,
              paddingLeft: 5,
              paddingRight: 5,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            },
            '& .parsed': {
              backgroundColor: 'rgba(246, 249, 32, 0.3)',
            },
            '& .handled': {
              backgroundColor: 'rgba(32, 249, 144, 0.3)',
            },
          },
          '&.error': {
            padding: 0,
            backgroundColor: 'rgba(239, 16, 72, 0.3)',
            flexGrow: 1,
            paddingLeft: 5,
            paddingRight: 5,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          },
        },
      },
    },
  },
});
