import BanafoodLogo from 'components/logos/banafood_logo.png';
import {
  NotificationListSvg,
  CertificateSvg,
  DashboardSvg,
  BatchesListTilapiaSvg,
} from 'components/icons';
import { theme } from 'themes/styles';

export default {
  logo: BanafoodLogo,
  colors: {
    primary: 'rgb(0, 95, 35)',
    breadcrumbs: 'rgb(39,86,178)',
    primaryHover: 'rgb(1, 52, 0)',
    mainMenuLine: 'rgb(241, 241, 241)',
    mainMenuIconColor: 'rgb(0, 95, 35)',
    mainMenuTextColor: 'rgb(0, 0, 0)',
    black: 'rgb(61, 61, 61)',
    backgroundHead: 'rgb(255, 255, 255)',
    datePickerBgColor: 'rgb(204, 18, 29)',
    accent: 'rgb(1, 52, 0)',
    modelSettingsCheckedColor: theme.colors.green,
  },
  mainMenuItems: [
    {
      routeName: 'Dashboard',
      icon: DashboardSvg,
      disabled: true,
    },
    {
      routeName: 'Notifications',
      icon: NotificationListSvg,
      disabled: false,
    },
    {
      routeName: 'Batches',
      icon: BatchesListTilapiaSvg,
    },
    {
      routeName: 'Documents',
      icon: CertificateSvg,
      disabled: false,
    },
  ],
};
