import React from 'react';
import { Link } from 'react-router-dom';
import { getUrl } from 'routes';
import { CHANNELS, ORGANIZATIONS } from 'themes/constants';
// components
import ModelSettingsDropdown from 'components/Dropdown/ModelSettingsDropDown';
import DownloadCSV from 'models/utils/DownloadCSV/DownloadCSV';
import ApproveContractButton from 'models/utils/ApproveContractButton/ApproveContractButton';
import { BatchRecord } from 'lib/lib';

export default {
  key: 'settingsAndActions',
  title: (
    <React.Fragment>
      <DownloadCSV />
      <ModelSettingsDropdown />
    </React.Fragment>
  ),
  style: { flex: '1 0 150px', justifyContent: 'space-evenly' },
  render: (column: BatchRecord) => (
    <React.Fragment>
      <Link
        className="contract batch-details"
        to={{
          pathname: getUrl('BatchContractType', {
            type: ORGANIZATIONS.EGG1.toLowerCase(),
            contract_number: `${column.uploadId}`,
            modelType: 'contractDetails',
            channelName: CHANNELS.EGGS,
          }),
          state: {},
        }}
      >
        <span>Details</span>
      </Link>
      <ApproveContractButton column={column} buttonClassName="batch-details" />
    </React.Fragment>
  ),
};
