import indexArchivedTabModel from 'models/hollander/tabs/indexArchivedTabModel';
import { ORGANIZATIONS } from 'themes/constants';
import { ModelRequiredInfo } from 'lib/lib';

export default (modelRequiredInfo: ModelRequiredInfo) => ({
  tabs: [
    {
      modelName: 'defaultTab',
      originalName: ORGANIZATIONS.HOLLANDER,
      title: ORGANIZATIONS.HOLLANDER,
    },
  ],
  defaultTab: indexArchivedTabModel(modelRequiredInfo),
});
