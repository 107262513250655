import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
// redux
import { getChannelName } from 'domain/env';
// utils
import { getUrl } from 'routes';
import { BATCH_STATUS, CHANNELS, ORGANIZATIONS } from 'themes/constants';
// types
import { AppStateType } from 'types';

interface OwnProps {
  onClick: () => void;
  actionType?: BATCH_STATUS;
  entityType: string;
  entityId: string;
  eventOrgName: string;
  channelName: CHANNELS;
}

const mapStateToProps = (state: AppStateType) => ({
  currentChannelName: getChannelName(state),
});

const BatchLink: React.FC<OwnProps> = (props) => {
  const { currentChannelName } = useSelector(mapStateToProps, shallowEqual);
  const { onClick, eventOrgName, entityId, entityType, actionType, channelName } = props;

  const getLink = () => {
    switch (currentChannelName) {
      case CHANNELS.BANANAS: {
        return (
          <Link
            data-batch={`${entityType}-${entityId}-${actionType || ''}`}
            className="square-button"
            to={{
              pathname: getUrl(actionType ? 'bananasBatchDetails' : 'bananasBatch', {
                type: eventOrgName,
                batchCode: entityId,
                status: actionType,
                modelType: 'details',
              }),
            }}
            onClick={onClick}
          >
            Link
          </Link>
        );
      }
      case CHANNELS.TILAPIA: {
        if (entityType === 'ProducerBatch') {
          return (
            <Link
              data-batch={`${eventOrgName}-${entityId}-${actionType || ''}`}
              className="square-button"
              to={{
                pathname: getUrl('BatchDetailsTabsParams', {
                  type: ORGANIZATIONS.REGALSPRINGS.toLowerCase(),
                  batchCode: entityId,
                  status: actionType,
                  modelType: 'detailsTabs',
                }),
              }}
              onClick={onClick}
            >
              Link
            </Link>
          );
        }
        // DO NOT BREAK HERE AND LEAVE IT BEFORE DEFAULT
      }
      default: {
        return (
          <Link
            data-batch={`${eventOrgName}-${entityId}`}
            className="square-button"
            to={{
              pathname: getUrl('BatchContractType', {
                type: eventOrgName,
                contract_number: entityId,
                modelType: 'contractDetails',
                channelName,
              }),
            }}
            onClick={onClick}
          >
            Link
          </Link>
        );
      }
    }
  };

  return getLink();
};

export default React.memo(BatchLink);
