import detailsTabModel from 'models/ah/eggs/tabs/detailsTabModel';
import settingsAndActions from 'models/ah/eggs/settingsAndActions/index';
import { ORGANIZATIONS } from 'themes/constants';
import { ModelRequiredInfo } from 'lib/lib';

export default (modelRequiredInfo: ModelRequiredInfo) => ({
  tabs: [
    {
      modelName: 'defaultTab',
      originalName: ORGANIZATIONS.AH,
      title: ORGANIZATIONS.AH,
    },
  ],
  defaultTab: detailsTabModel(modelRequiredInfo),
  settingsAndActions,
});
