import React from 'react';
// styles
import injectStyle, { WithStyles } from 'react-jss';
import styles from 'components/Datatable/styles/sortableHeadersWrapper';
// components
import SortableHeader from 'components/Datatable/SortableHeader';
// types
import { IndexedObject } from 'lib/lib';

interface ModelFieldDescriptor {
  key: string;
  style: IndexedObject<unknown>;
  shortTitle: string;
  title: string;
  isSortable?: boolean;
}

interface OwnProps extends WithStyles<typeof styles> {
  headers: Array<ModelFieldDescriptor>;
}

const SortableHeadersWrapper: React.FC<OwnProps> = (props) => {
  const { classes, headers } = props;

  return (
    <div
      className={classes.dataTableHead}
      data-cy="tableHeader"
      style={{ position: 'sticky', top: 0, zIndex: 2 }}
    >
      {headers.map(({ key, style, shortTitle, title, isSortable }) => (
        <SortableHeader
          title={title}
          style={style}
          shortTitle={shortTitle}
          key={key}
          keyField={key}
          isSortable={isSortable}
        />
      ))}
    </div>
  );
};

export default injectStyle(styles)(SortableHeadersWrapper);
