import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { Field, reduxForm } from 'redux-form';
import { logInAction } from 'domain/env';
import { Link } from 'react-router-dom';
// jss
import injectSheet from 'react-jss';
import sheetSignIn from 'pages/auth/signIn/sheetSignIn';
import { getUrl } from 'routes';

import { SimFooterLofoSvg } from 'components/icons';

class SignIn extends React.PureComponent {
  render() {
    const { classes, handleSubmit } = this.props;

    return (
      <div className={classes.formWrapper}>
        <a href="https://www.simsupplychain.com/">
          <SimFooterLofoSvg height={55} />
        </a>
        <form data-cy="singInForm" onSubmit={handleSubmit}>
          <div className={`${classes.formGroup} email`}>
            <Field
              component="input"
              className={classes.inputField}
              type="email"
              name="email"
              data-cy="email"
              placeholder="Email"
            />
          </div>
          <div className={`${classes.formGroup} password`}>
            <Field
              component="input"
              className={classes.inputField}
              type="password"
              name="password"
              data-cy="password"
              placeholder="Password"
            />
          </div>
          <input type="submit" className={`${classes.formGroup} submit`} />
        </form>
        <div>
          <div className={classes.formGroup}>
            <button
              data-cy="submit"
              className={classes.loginBtn}
              type="submit"
              onClick={handleSubmit}
            >
              LOGIN
            </button>
          </div>
          <div className={classes.forgotPassword}>
            <Link to={getUrl('ForgotPassword')}>Forgot password?</Link>
          </div>
        </div>
      </div>
    );
  }
}

SignIn.propTypes = {
  classes: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

export default compose(
  reduxForm({
    form: 'login-form',
    onSubmit: (data, dispatch) =>
      dispatch({
        type: logInAction.type,
        payload: { ...data },
      }),
  }),
  injectSheet(sheetSignIn),
)(SignIn);
