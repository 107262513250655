import React from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
// selectors
import { getBatchInfoByUrlOrgRoleAll } from 'domain/common';
// actions
import * as batchesActions from 'domain/batches/actions';
import * as uiActions from 'domain/ui/actions';
//
import ApproveBatchFrom from 'pages/batches/Forms/approveBatchForm';
// types
import { AppStateType } from 'types';
import { BatchRecord } from 'lib/lib';
import { getChannelName } from 'domain/env';
import { CHANNELS } from 'themes/constants';

interface OwnProps {
  column: BatchRecord;
  buttonClassName?: string;
  channelName?: CHANNELS;
}

const mapStateToProps = (state: AppStateType) => ({
  batchInfoAll: getBatchInfoByUrlOrgRoleAll(state),
  currentChannelName: getChannelName(state),
});

const ApproveContractButton: React.FC<OwnProps> = ({
  column,
  buttonClassName = 'approve-batch',
  channelName,
}) => {
  const dispatch = useDispatch();
  const { batchInfoAll, currentChannelName } = useSelector(mapStateToProps, shallowEqual);

  const batchInfo = batchInfoAll[channelName || currentChannelName];

  const approveBatchContract = (contractId: string) => (payload: {
    // TODO: pass these types to pages/batches/Forms/approveBatchForm submit
    approveReason: string;
    status: string;
    approveMsg?: string;
  }) => {
    dispatch(batchesActions.approveBatchContractAction({ contractId, data: payload }));
    dispatch({ type: uiActions.uiModalAction.failure });
  };

  const checkBatchContract = (contractNumber: string) => () => {
    const initialValues = {
      approveReason: column.status as string,
      approveMsg: column.approveMsg && (column.approveMsg as string).trim(),
    };
    dispatch({
      type: uiActions.uiModalAction.type,
      payload: {
        title: 'Select your answer',
        body: (
          <ApproveBatchFrom
            callback={approveBatchContract(contractNumber)}
            initialValues={initialValues}
          />
        ),
      },
    });
  };

  return (
    <button
      type="button"
      onClick={checkBatchContract(column[batchInfo.contract] as string)}
      className={buttonClassName}
    >
      Answer
    </button>
  );
};

export default ApproveContractButton;
