import React from 'react';
// components
import ModelSettingsDropdown from 'components/Dropdown/ModelSettingsDropDown';
import DownloadExcel from 'models/utils/DownloadExcel/DownloadExcel';

export default {
  key: 'settingsAndActions',
  title: (
    <React.Fragment>
      <DownloadExcel fileName="unmatched.xlsx" entity="unmatched" />
      <ModelSettingsDropdown />
    </React.Fragment>
  ),
  style: { flex: '0 0 80px', justifyContent: 'space-evenly' },
  render: () => null,
};
