//    oranges
import customThemeLDCBR from 'themes/oranges/ldcbr';
import customThemeJDE from 'themes/oranges/jde';
import customThemeOrangesRefresco from 'themes/oranges/refresco';
import customThemeOrangesSIM from 'themes/oranges/sim';
import customThemeOrangesAH from 'themes/oranges/ah';
//    eggs
import customThemeEGG1 from 'themes/eggs/egg1';
import customThemeEGG2 from 'themes/eggs/egg2';
import customThemeEggsSIM from 'themes/eggs/sim';
import customThemeEggsAH from 'themes/eggs/ah';
//    RegalSprings
import customThemeJumbo from 'themes/tilapia/jumbo';
import customThemeRegalSprings from 'themes/tilapia/regalSprings';
import customThemeSeafoodConnection from 'themes/tilapia/seafoodConnection';
import customThemeTilapiaSIM from 'themes/tilapia/sim';
import customThemeMayonna from 'themes/tilapia/mayonna';
// bananas
import customHollanderTheme from 'themes/bananas/hollander';
import customFyffesTheme from 'themes/bananas/fyffes';
import customThemeBananasSIM from 'themes/bananas/sim';
import customThemeBanafood from 'themes/bananas/banafood';
// juice
import customCitrosucoBrazilTheme from 'themes/juice/citrosucoBrazil';
import customCitrosucoGhentTheme from 'themes/juice/citrosucoGhent';
import customKauflandDETheme from 'themes/juice/kauflandDE';
import customRefrescoJuiceTheme from 'themes/juice/refresco';
import customAHJuiceTheme from 'themes/juice/ah';
import customRefrescroGmbHTheme from 'themes/juice/refrescoGbmH';
import customThemeJuiceSIM from 'themes/juice/sim';
// BATCH MODELS
//    eggs
import batchModelEGG1 from 'models/egg1/default';
import batchModelEGG1Archived from 'models/egg1/defaultArchived';
import batchModelEGG1Details from 'models/egg1/details';
import batchModelEGG1Index from 'models/egg1';
import batchModelEGG1IndexArchived from 'models/egg1/indexArchived';
import batchModelEGG1Handshake from 'models/egg1/handshake';
import batchModelEGG1Upload from 'models/egg1/upload';
import batchModelEGG2 from 'models/egg2/default';
import batchModelEGG2Archived from 'models/egg2/defaultArchived';
import batchModelEGG2Details from 'models/egg2/details';
import batchModelEGG2Index from 'models/egg2';
import batchModelEGG2IndexArchived from 'models/egg2/indexArchived';
import batchModelEGG2Handshake from 'models/egg2/handshake';
import batchModelEGG2Upload from 'models/egg2/upload';
import batchModelSIMeggs from 'models/sim/eggs/default';
import batchModelSIMeggsArchived from 'models/sim/eggs/defaultArchived';
import batchModelSIMeggsIndex from 'models/sim/eggs';
import batchModelSIMeggsIndexArchived from 'models/sim/eggs/indexArchived';
import batchModelAHeggs from 'models/ah/eggs/default';
import batchModelAHeggsArchived from 'models/ah/eggs/defaultArchived';
import batchModelAHeggsIndex from 'models/ah/eggs';
import batchModelAHeggsIndexArchived from 'models/ah/eggs/indexArchived';
import batchModelAHeggsUpload from 'models/ah/eggs/upload';
import batchModelAHeggsDetails from 'models/ah/eggs/details';
//    oranges
import batchModelRefrescoOrangesContract from 'models/refresco/oranges/contract';
import batchModelRefrescoOrangesContractArchived from 'models/refresco/oranges/contractArchived';
import batchModelRefrescoOrangesDetails from 'models/refresco/oranges/details';
import batchModelSIMoranges from 'models/sim/oranges/default';
import batchModelSIMorangesArchived from 'models/sim/oranges/defaultArchived';
import batchModelSIMorangesIndex from 'models/sim/oranges';
import batchModelSIMorangesIndexArchived from 'models/sim/oranges/indexArchived';
import batchModelAHoranges from 'models/ah/oranges/default';
import batchModelAHorangesArchived from 'models/ah/oranges/defaultArchived';
import batchModelAHorangesIndex from 'models/ah/oranges';
import batchModelAHorangesIndexArchived from 'models/ah/oranges/indexArchived';
import batchModelAHorangesUpload from 'models/ah/oranges/upload';
import batchModelAHorangesDetails from 'models/ah/oranges/details';
import batchModelLDCBR from 'models/ldcbr/default';
import batchModelLDCBRArchived from 'models/ldcbr/defaultArchived';
import batchModelLDCBRIndex from 'models/ldcbr';
import batchModelLDCBRBatchDetails from 'models/ldcbr/details';
import batchModelLDCBRIndexArchived from 'models/ldcbr/indexArchived';
import batchModelLDCBRUpload from 'models/ldcbr/upload';
import batchModelJDE from 'models/jde/default';
import batchModelJDEArchived from 'models/jde/defaultArchived';
import batchModelJDEIndex from 'models/jde';
import batchModelJDEIndexArchived from 'models/jde/indexArchived';
import batchModelJDEUpload from 'models/jde/upload';
import batchModelJDEDetails from 'models/jde/details';
//    Tilapia
import batchModelSIMtilapiaIndex from 'models/sim/tilapia/index';
import batchModelSIMtilapiaIndexArchived from 'models/sim/tilapia/indexArchived';
import batchModelJumbo from 'models/jumbo/default';
import batchModelJumboArchived from 'models/jumbo/defaultArchived';
import batchModelJumboIndex from 'models/jumbo';
import batchModelJumboIndexArchived from 'models/jumbo/indexArchived';
import batchModelJumboUpload from 'models/jumbo/upload';
import batchModelJumboDetails from 'models/jumbo/details';
import batchModelRegalSprings from 'models/regalSprings/batchModel';
import batchModelRegalSpringsIndex from 'models/regalSprings';
import batchModelRegalSpringsIndexArchived from 'models/regalSprings/indexArchived';
import batchModelRegalSpringsHandshake from 'models/regalSprings/handshake';
import batchModelSeafoodConnection from 'models/seafoodConnection/default';
import batchModelSeafoodConnectionArchived from 'models/seafoodConnection/defaultArchived';
import batchModelSeafoodConnectionIndex from 'models/seafoodConnection';
import batchModelSeafoodConnectionIndexArchived from 'models/seafoodConnection/indexArchived';
import batchModelSeafoodConnectionHandshake from 'models/seafoodConnection/handshake';
import batchModelSeafoodConnectionUpload from 'models/seafoodConnection/upload';
import batchModelSeafoodConnectionDetails from 'models/seafoodConnection/details';
import batchModelMayonna from 'models/mayonna/default';
import batchModelMayonnaArchived from 'models/mayonna/defaultArchived';
import batchModelMayonnaIndexArchived from 'models/mayonna/indexArchived';
import batchModelMayonnaIndex from 'models/mayonna';
import batchModelMayonnaHandshake from 'models/mayonna/handshake';
import batchModelMayonnaUpload from 'models/mayonna/upload';
import batchModelMayonnaDetails from 'models/mayonna/details';
//  bananas
import batchModelSIMbananasIndex from 'models/sim/bananas/index';
import batchModelSIMbananasIndexArchived from 'models/sim/bananas/indexArchived';
import batchModelBanafood from 'models/banafood/default';
import batchModelBanafoodIndex from 'models/banafood';
import batchModelBanafoodIndexArchived from 'models/banafood/indexArchived';
import batchModelBanafoodHandshake from 'models/banafood/handshake';
import batchModelBanafoodDetailed from 'models/banafood/detailed';
import batchModelHollander from 'models/hollander/default';
import batchModelHollanderIndex from 'models/hollander';
import batchModelHollanderIndexArchived from 'models/hollander/indexArchived';
import batchModelHollanderDetailed from 'models/hollander/detailed';
import batchModelFyffes from 'models/fyffes/default';
import batchModelFyffesIndex from 'models/fyffes';
import batchModelFyffesIndexArchived from 'models/fyffes/indexArchived';
import batchModelFyffesHandshake from 'models/fyffes/handshake';
import batchModelFyffesDetailed from 'models/fyffes/detailed';
import batchModelFyffesDetailedWithCheckboxes from 'models/fyffes/detailedWithCheckboxes';
import dashboardBananasSIM from 'models/sim/bananas/dashboard';
import dashboardBananasHollander from 'models/hollander/dashboard';
import dashboardBananasFyffes from 'models/fyffes/dashboard';
import dashboardBananasBanafood from 'models/banafood/dashboard';
import batchModelSIMbananasValidation from 'models/sim/bananas/validation';
// juice
import batchModelRefrescoJuiceContract from 'models/refresco/juice/contract';
import batchModelRefrescoJuiceContractArchived from 'models/refresco/juice/contractArchived';
import batchModelRefrescoJuiceDetails from 'models/refresco/juice/details';
import batchModelCitrosucoBrazilIndex from 'models/citrosucoBrazil/index';
import batchModelCitrosucoBrazilIndexArchived from 'models/citrosucoBrazil/indexArchived';
import batchModelCitrosucoBrazilContract from 'models/citrosucoBrazil/contract';
import batchModelCitrosucoBrazilContractArchived from 'models/citrosucoBrazil/contractArchived';
import batchModelCitrosucoBrazilUpload from 'models/citrosucoBrazil/upload';
import batchModelCitrosucoBrazilDetails from 'models/citrosucoBrazil/details';
import batchModelCitrosucoBrazilHandshake from 'models/citrosucoBrazil/handshake';
import batchModelCitrosucoGhentIndex from 'models/citrosucoGhent/index';
import batchModelCitrosucoGhentIndexArchived from 'models/citrosucoGhent/indexArchived';
import batchModelCitrosucoGhentContract from 'models/citrosucoGhent/contract';
import batchModelCitrosucoGhentContractArchived from 'models/citrosucoGhent/contractArchived';
import batchModelCitrosucoGhentUpload from 'models/citrosucoGhent/upload';
import batchModelCitrosucoGhentDetails from 'models/citrosucoGhent/details';
import batchModelCitrosucoGhentHandshake from 'models/citrosucoGhent/handshake';
import batchModelSIMJuiceIndex from 'models/sim/juice/index';
import batchModelSIMJuiceIndexArchived from 'models/sim/juice/indexArchived';
// juice not in use
import batchModelRefrescoGmbHIndex from 'models/refrescoGbmH/index';
import batchModelRefrescoGmbHIndexArchived from 'models/refrescoGbmH/indexArchived';
import batchModelKauflandDEIndex from 'models/kauflandDE/index';
import batchModelKauflandDEIndexArchived from 'models/kauflandDE/indexArchived';
// common
import batchModelRefrescoCommonHandshake from 'models/refresco/common/handshake';
import batchModelRefrescoCommonIndex from 'models/refresco/common';
import batchModelRefrescoCommonIndexArchived from 'models/refresco/common/indexArchived';
import batchModelRefrescoCommonUpload from 'models/refresco/common/upload';

// certificates
import { getCertificateModelByChannelName } from 'models/certificates';
// notifications
import notificationsModel from 'models/notifications';
// admin models
import adminUsersModel from 'models/admin/users';
import adminInvitationsModel from 'models/admin/invitations';
// styles
import { theme } from './styles';
import { BATCH_STATUS, CHANNELS, MODEL_TYPES, ORGANIZATIONS } from './constants';
// types
import { Batch, ChannelsWrapper, DateFormat, Nullable } from 'lib/lib';

const {
  BANAFOOD,
  HOLLANDER,
  JUMBO,
  MAYONNA,
  REGALSPRINGS,
  SEAFOODCONNECTION,
  FYFFES,
  SIM,
  LDCBR,
  JDE,
  REFRESCO,
  AH,
  EGG1,
  EGG2,
  CITROSUCOBR,
  CITROSUCOGHENT,
  REFRESCOGMBH,
  KAUFLANDDE,
} = ORGANIZATIONS;

export const getOrgNameForURL = (orgname: ORGANIZATIONS) => orgname.toLowerCase();

export function findMatchedOrgName(orgName: string): Nullable<ORGANIZATIONS> {
  const orgsRegistry = Object.entries(ORGANIZATIONS);
  const registryMatch = orgsRegistry.find(
    ([enumName, fullName]) => fullName.toLowerCase() === orgName.toLowerCase(),
  );
  if (!registryMatch) {
    console.error(`Org ${orgName} no found`);
  } else {
    return registryMatch[1];
  }
}

export const COMMON_ENPOINTS = {
  [REFRESCO]: 'refrescobatch',
};

export const CHANNEL_NAME_TO_TITLE_MAP = {
  // DO NOT REMOVE COMMON
  common: {
    [CHANNELS.TILAPIA]: 'Tilapia',
    [CHANNELS.BANANAS]: 'Bananas',
    [CHANNELS.EGGS]: 'Eggs',
    [CHANNELS.ORANGES]: 'Oranges',
    [CHANNELS.JUICE]: 'Citrosuco',
  } as Record<CHANNELS, string>,
  [ORGANIZATIONS.SIM]: {
    [CHANNELS.ORANGES]: 'LDC (Oranges)',
    [CHANNELS.JUICE]: 'Citrosuco (Oranges)',
  },
} as Record<string, Partial<Record<CHANNELS, string>>>;

// set format for entite organization, each tab or each tab & column
// if signle tab no key for tab required
export const UPLOAD_TIME_FORMAT = {
  [BANAFOOD]: {
    inBatches: 'MM/DD/YYYY',
    outBatches: 'DD-MM-YYYY',
  },
  [HOLLANDER]: 'DD-MM-YYYY',
  [JUMBO]: {
    dateReceipt: 'DD-MM-YYYY HH:mm:ss',
    thtConsumerProduct: 'DD-MM-YYYY',
  },
  [MAYONNA]: 'MM/DD/YYYY',
  [REGALSPRINGS]: 'MM/DD/YYYY',
  [SEAFOODCONNECTION]: 'MM/DD/YYYY',
  [FYFFES]: 'MM/DD/YYYY',
  [CITROSUCOBR]: {
    lastReceiptDateBr: 'DD/MM/YYYY',
    finalManDate: 'DD/MM/YYYY',
    startManDate: 'DD/MM/YYYY',
    harvestFinalDate: 'DD/MM/YYYY',
    harvestStartDate: 'DD/MM/YYYY',
    blDate: 'DD/MM/YYYY',
  },
  [CITROSUCOGHENT]: {
    invoiceDate: 'DD/MM/YYYY',
    receiptDate: 'DD/MM/YYYY',
  },
} as Record<string, DateFormat>;

export default theme;

const getCustomTheme = (orgName: ORGANIZATIONS, channelName = '') => {
  switch (orgName) {
    case LDCBR:
      return customThemeLDCBR;
    case JDE:
      return customThemeJDE;
    case REFRESCO:
      switch (channelName) {
        case CHANNELS.ORANGES:
          return customThemeOrangesRefresco;
        case CHANNELS.JUICE:
          return customRefrescoJuiceTheme;
      }
    case SIM:
      switch (channelName) {
        case CHANNELS.TILAPIA:
          return customThemeTilapiaSIM;
        case CHANNELS.ORANGES:
          return customThemeOrangesSIM;
        case CHANNELS.BANANAS:
          return customThemeBananasSIM;
        case CHANNELS.EGGS:
          return customThemeEggsSIM;
        case CHANNELS.JUICE:
          return customThemeJuiceSIM;
      }
    case AH:
      switch (channelName) {
        case CHANNELS.ORANGES:
          return customThemeOrangesAH;
        case CHANNELS.JUICE:
          return customAHJuiceTheme;
        default:
          return customThemeEggsAH;
      }
    case EGG1:
      return customThemeEGG1;
    case EGG2:
      return customThemeEGG2;
    case JUMBO:
      return customThemeJumbo;
    case REGALSPRINGS:
      return customThemeRegalSprings;
    case SEAFOODCONNECTION:
      return customThemeSeafoodConnection;
    case MAYONNA:
      return customThemeMayonna;
    case HOLLANDER:
      return customHollanderTheme;
    case FYFFES:
      return customFyffesTheme;
    case BANAFOOD:
      return customThemeBanafood;
    case JDE:
      return customThemeJDE;
    case CITROSUCOBR:
      return customCitrosucoBrazilTheme;
    case CITROSUCOGHENT:
      return customCitrosucoGhentTheme;
    case REFRESCOGMBH:
      return customRefrescroGmbHTheme;
    case KAUFLANDDE:
      return customKauflandDETheme;
    default:
      return customThemeJDE;
  }
};

export const getTheme = (role: ORGANIZATIONS, channelName = '') => {
  const customTheme = getCustomTheme(role, channelName);

  return {
    ...theme,
    ...customTheme,
    colors: {
      ...theme.colors,
      ...customTheme.colors,
    },
  };
};

export function getBatchModel(
  orgName: ORGANIZATIONS,
  channelName: CHANNELS,
  params = {},
  batchesHierarchyByOrgname: ChannelsWrapper<Record<ORGANIZATIONS, Batch>>,
  loggedInUserOrg: ORGANIZATIONS,
) {
  const foundOrgName = findMatchedOrgName(orgName);
  const modelRequieredInfo = {
    role: foundOrgName /* this is orgname and should be refactored then */,
    channelName,
    params,
    batchesHierarchyByOrgname,
    loggedInUserRole: loggedInUserOrg,
  };

  const model = findBatchModel(
    orgName /* this is orgname and should be refactored then */,
    channelName,
    params,
  );

  if (!model.call) {
    return {};
  }

  return model.call(null, modelRequieredInfo);
}

export function findBatchModel(
  orgName: ORGANIZATIONS /* this is orgname and should be refactored then */,
  channelName: CHANNELS,
  params,
) {
  if (params.type === MODEL_TYPES.ADMIN_INVITATIONS) {
    return adminInvitationsModel;
  }
  if (params.type === MODEL_TYPES.ADMIN_USERS) {
    return adminUsersModel;
  }
  if (params.type === MODEL_TYPES.NOTIFICATIONS) {
    return notificationsModel;
  }
  if (params.type === MODEL_TYPES.CERTIFICATES_LIST || params.type === MODEL_TYPES.CERTIFICATE) {
    return getCertificateModelByChannelName(channelName, params.type);
  }
  // if (params.type === MODEL_TYPES.DASHBOARD) {
  //   switch (orgName) {
  //     case ORGANIZATIONS.SIM:
  //       return dashboardSIM;
  //   }
  // }
  switch (findMatchedOrgName(orgName)) {
    case EGG1: {
      if (params.status === BATCH_STATUS.ARCHIVED) {
        if (params.type === MODEL_TYPES.INDEX) {
          return batchModelEGG1IndexArchived;
        }
        if (params.type === MODEL_TYPES.CONTRACT_DETAILS) {
          return batchModelEGG1Archived;
        }
      }
      if (params.type === MODEL_TYPES.INDEX) {
        return batchModelEGG1Index;
      }
      if (params.type === MODEL_TYPES.CONTRACT_DETAILS) {
        return batchModelEGG1;
      }
      if (params.type === MODEL_TYPES.UPLOAD) {
        return batchModelEGG1Upload;
      }
      if (params.type === MODEL_TYPES.HANDSHAKE) {
        return batchModelEGG1Handshake;
      }
      if (params.type === MODEL_TYPES.DETAILS) {
        return batchModelEGG1Details;
      }
    }

    case EGG2: {
      if (params.status === BATCH_STATUS.ARCHIVED) {
        if (params.type === MODEL_TYPES.INDEX) {
          return batchModelEGG2IndexArchived;
        }
        if (params.type === MODEL_TYPES.CONTRACT_DETAILS) {
          return batchModelEGG2Archived;
        }
      }
      if (params.type === MODEL_TYPES.INDEX) {
        return batchModelEGG2Index;
      }
      if (params.type === MODEL_TYPES.CONTRACT_DETAILS) {
        return batchModelEGG2;
      }
      if (params.type === MODEL_TYPES.UPLOAD) {
        return batchModelEGG2Upload;
      }
      if (params.type === MODEL_TYPES.HANDSHAKE) {
        return batchModelEGG2Handshake;
      }
      if (params.type === MODEL_TYPES.DETAILS) {
        return batchModelEGG2Details;
      }
    }

    case LDCBR: {
      if (params.status === BATCH_STATUS.ARCHIVED) {
        if (params.type === MODEL_TYPES.INDEX) {
          return batchModelLDCBRIndexArchived;
        }
        if (params.type === MODEL_TYPES.CONTRACT_DETAILS) {
          return batchModelLDCBRArchived;
        }
      }
      if (params.type === MODEL_TYPES.INDEX) {
        return batchModelLDCBRIndex;
      }
      if (params.type === MODEL_TYPES.CONTRACT_DETAILS) {
        return batchModelLDCBR;
      }
      if (params.type === MODEL_TYPES.UPLOAD) {
        return batchModelLDCBRUpload;
      }
      if (params.type === MODEL_TYPES.DETAILS) {
        return batchModelLDCBRBatchDetails;
      }
    }
    case JDE: {
      if (params.status === BATCH_STATUS.ARCHIVED) {
        if (params.type === MODEL_TYPES.INDEX) {
          return batchModelJDEIndexArchived;
        }
        if (params.type === MODEL_TYPES.CONTRACT_DETAILS) {
          return batchModelJDEArchived;
        }
      }
      if (params.type === MODEL_TYPES.INDEX) {
        return batchModelJDEIndex;
      }
      if (params.type === MODEL_TYPES.CONTRACT_DETAILS) {
        return batchModelJDE;
      }
      if (params.type === MODEL_TYPES.UPLOAD) {
        return batchModelJDEUpload;
      }
      if (params.type === MODEL_TYPES.DETAILS) {
        return batchModelJDEDetails;
      }
    }

    case REFRESCO: {
      if (params.type === MODEL_TYPES.HANDSHAKE) {
        return batchModelRefrescoCommonHandshake;
      }

      if (params.type === MODEL_TYPES.INDEX) {
        if (params.status === BATCH_STATUS.ARCHIVED) {
          return batchModelRefrescoCommonIndexArchived;
        }
        return batchModelRefrescoCommonIndex;
      }

      if (params.type === MODEL_TYPES.UPLOAD) {
        return batchModelRefrescoCommonUpload;
      }

      if (channelName === CHANNELS.ORANGES) {
        if (params.status === BATCH_STATUS.ARCHIVED) {
          if (params.type === MODEL_TYPES.CONTRACT_DETAILS) {
            return batchModelRefrescoOrangesContractArchived;
          }
        }
        if (params.type === MODEL_TYPES.CONTRACT_DETAILS) {
          return batchModelRefrescoOrangesContract;
        }
        if (params.type === MODEL_TYPES.DETAILS) {
          return batchModelRefrescoOrangesDetails;
        }
      }

      if (channelName === CHANNELS.JUICE) {
        if (params.status === BATCH_STATUS.ARCHIVED) {
          if (params.type === MODEL_TYPES.CONTRACT_DETAILS) {
            return batchModelRefrescoJuiceContractArchived;
          }
        }
        if (params.type === MODEL_TYPES.CONTRACT_DETAILS) {
          return batchModelRefrescoJuiceContract;
        }
        if (params.type === MODEL_TYPES.DETAILS) {
          return batchModelRefrescoJuiceDetails;
        }
      }
    }

    case JUMBO:
      {
        if (params.status === BATCH_STATUS.ARCHIVED) {
          if (params.type === MODEL_TYPES.INDEX) {
            return batchModelJumboIndexArchived;
          }
          if (params.type === MODEL_TYPES.CONTRACT_DETAILS) {
            return batchModelJumboArchived;
          }
        }
        if (params.type === MODEL_TYPES.INDEX) {
          return batchModelJumboIndex;
        }
        if (params.type === MODEL_TYPES.CONTRACT_DETAILS) {
          return batchModelJumbo;
        }
        if (params.type === MODEL_TYPES.UPLOAD) {
          return batchModelJumboUpload;
        }
        if (params.type === MODEL_TYPES.HANDSHAKE) {
          return batchModelJumboIndex;
        }
        if (params.type === MODEL_TYPES.DETAILS) {
          return batchModelJumboDetails;
        }
      }
      return batchModelJumbo;

    case REGALSPRINGS: {
      if (params.status === BATCH_STATUS.ARCHIVED) {
        if (params.type === MODEL_TYPES.INDEX) {
          return batchModelRegalSpringsIndexArchived;
        }
      }
      if (params.type === MODEL_TYPES.INDEX) {
        return batchModelRegalSpringsIndex;
      }

      if (params.type === 'detailsTabs') {
        return batchModelRegalSprings;
      }
      if (params.type === MODEL_TYPES.UPLOAD) {
        return batchModelRegalSprings;
      }
      if (params.type === MODEL_TYPES.HANDSHAKE) {
        return batchModelRegalSpringsHandshake;
      }

      return batchModelRegalSprings;
    }

    case SEAFOODCONNECTION: {
      if (params.status === BATCH_STATUS.ARCHIVED) {
        if (params.type === MODEL_TYPES.INDEX) {
          return batchModelSeafoodConnectionIndexArchived;
        }
        if (params.type === MODEL_TYPES.CONTRACT_DETAILS) {
          return batchModelSeafoodConnectionArchived;
        }
      }
      if (params.type === MODEL_TYPES.INDEX) {
        return batchModelSeafoodConnectionIndex;
      }

      if (params.type === MODEL_TYPES.CONTRACT_DETAILS) {
        return batchModelSeafoodConnection;
      }

      if (params.type === MODEL_TYPES.UPLOAD) {
        return batchModelSeafoodConnectionUpload;
      }
      if (params.type === MODEL_TYPES.HANDSHAKE) {
        return batchModelSeafoodConnectionHandshake;
      }
      if (params.type === MODEL_TYPES.DETAILS) {
        return batchModelSeafoodConnectionDetails;
      }

      return batchModelSeafoodConnection;
    }

    case SIM:
      if (channelName === CHANNELS.EGGS) {
        if (params.status === BATCH_STATUS.ARCHIVED) {
          if (params.type === MODEL_TYPES.INDEX) {
            return batchModelSIMeggsIndexArchived;
          }
          return batchModelSIMeggsArchived;
        }
        if (params.type === MODEL_TYPES.INDEX) {
          return batchModelSIMeggsIndex;
        }
        return batchModelSIMeggs;
      }
      if (channelName === CHANNELS.ORANGES) {
        if (params.status === BATCH_STATUS.ARCHIVED) {
          if (params.type === MODEL_TYPES.INDEX) {
            return batchModelSIMorangesIndexArchived;
          }
          return batchModelSIMorangesArchived;
        }
        if (params.type === MODEL_TYPES.INDEX) {
          return batchModelSIMorangesIndex;
        }
        return batchModelSIMoranges;
      }
      if (channelName === CHANNELS.BANANAS) {
        if (params.status === BATCH_STATUS.ARCHIVED) {
          return batchModelSIMbananasIndexArchived;
        }
        if (params.type === MODEL_TYPES.INDEX) {
          return batchModelSIMbananasIndex;
        }
        if (params.type === MODEL_TYPES.DETAILS) {
          return batchModelHollanderDetailed; // TODO: create SIM model
        }
        if (params.type === MODEL_TYPES.DASHBOARD) {
          return dashboardBananasSIM;
        }
        if (params.type === MODEL_TYPES.VALIDATION) {
          return batchModelSIMbananasValidation;
        }
        return batchModelHollander; // TODO: create SIM model
      }
      if (channelName === CHANNELS.TILAPIA) {
        if (params.status === BATCH_STATUS.ARCHIVED) {
          return batchModelSIMtilapiaIndexArchived;
        }
        if (params.type === MODEL_TYPES.INDEX) {
          return batchModelSIMtilapiaIndex;
        }

        if (params.type === MODEL_TYPES.CONTRACT_DETAILS) {
          return batchModelJumbo; // TODO: create SIM model
        }

        return batchModelJumbo; // TODO: create SIM model
      }
      if (channelName === CHANNELS.JUICE) {
        if (params.type === MODEL_TYPES.INDEX) {
          if (params.status === BATCH_STATUS.ARCHIVED) {
            return batchModelSIMJuiceIndexArchived;
          }
          return batchModelSIMJuiceIndex;
        }
      }

    case AH:
      if (channelName === CHANNELS.EGGS) {
        if (params.status === BATCH_STATUS.ARCHIVED) {
          if (params.type === MODEL_TYPES.INDEX) {
            return batchModelAHeggsIndexArchived;
          }
          if (params.type === MODEL_TYPES.CONTRACT_DETAILS) {
            return batchModelAHeggsArchived;
          }
        }
        if (params.type === MODEL_TYPES.INDEX) {
          return batchModelAHeggsIndex;
        }
        if (params.type === MODEL_TYPES.UPLOAD) {
          return batchModelAHeggsUpload;
        }
        if (params.type === MODEL_TYPES.CONTRACT_DETAILS) {
          return batchModelAHeggs;
        }
        if (params.type === MODEL_TYPES.DETAILS) {
          return batchModelAHeggsDetails;
        }
      }
      if (channelName === CHANNELS.ORANGES || channelName === CHANNELS.JUICE) {
        if (params.status === BATCH_STATUS.ARCHIVED) {
          if (params.type === MODEL_TYPES.INDEX) {
            return batchModelAHorangesIndexArchived;
          }
          if (params.type === MODEL_TYPES.CONTRACT_DETAILS) {
            return batchModelAHorangesArchived;
          }
        }
        if (params.type === MODEL_TYPES.INDEX) {
          return batchModelAHorangesIndex;
        }
        if (params.type === MODEL_TYPES.UPLOAD) {
          return batchModelAHorangesUpload;
        }
        if (params.type === MODEL_TYPES.CONTRACT_DETAILS) {
          return batchModelAHoranges;
        }
        if (params.type === MODEL_TYPES.DETAILS) {
          return batchModelAHorangesDetails;
        }
      }

    case MAYONNA:
      if (params.status === BATCH_STATUS.ARCHIVED) {
        if (params.type === MODEL_TYPES.INDEX) {
          return batchModelMayonnaIndexArchived;
        }
        if (params.type === MODEL_TYPES.CONTRACT_DETAILS) {
          return batchModelMayonnaArchived;
        }
      }
      if (params.type === MODEL_TYPES.INDEX) {
        return batchModelMayonnaIndex;
      }

      if (params.type === MODEL_TYPES.CONTRACT_DETAILS) {
        return batchModelMayonna;
      }

      if (params.type === MODEL_TYPES.UPLOAD) {
        return batchModelMayonnaUpload;
      }

      if (params.type === MODEL_TYPES.HANDSHAKE) {
        return batchModelMayonnaHandshake;
      }
      if (params.type === MODEL_TYPES.DETAILS) {
        return batchModelMayonnaDetails;
      }

      return batchModelMayonna;

    case HOLLANDER:
      if (params.status === BATCH_STATUS.ARCHIVED) {
        if (params.type === MODEL_TYPES.DETAILS) {
          return batchModelHollanderDetailed;
        }

        return batchModelHollanderIndexArchived;
      }
      if (params.type === MODEL_TYPES.INDEX) {
        return batchModelHollanderIndex;
      }
      if (params.type === MODEL_TYPES.DETAILS) {
        return batchModelHollanderDetailed;
      }
      if (params.type === MODEL_TYPES.UPLOAD) {
        return batchModelHollander;
      }
      if (params.type === MODEL_TYPES.DASHBOARD) {
        return dashboardBananasHollander;
      }
      return batchModelHollander;

    case FYFFES:
      if (params.status === BATCH_STATUS.ARCHIVED) {
        if (params.type === MODEL_TYPES.DETAILS) {
          return batchModelFyffesDetailed;
        }

        return batchModelFyffesIndexArchived;
      }
      if (params.type === MODEL_TYPES.INDEX) {
        return batchModelFyffesIndex;
      }
      if (params.type === MODEL_TYPES.DETAILS) {
        return batchModelFyffesDetailedWithCheckboxes;
      }
      if (params.type === MODEL_TYPES.UPLOAD) {
        return batchModelFyffes;
      }
      if (params.type === MODEL_TYPES.HANDSHAKE) {
        return batchModelFyffesHandshake;
      }
      if (params.type === MODEL_TYPES.DASHBOARD) {
        return dashboardBananasFyffes;
      }
      return batchModelFyffes;

    case BANAFOOD:
      if (params.status === BATCH_STATUS.ARCHIVED) {
        if (params.type === MODEL_TYPES.DETAILS) {
          return batchModelBanafoodDetailed;
        }

        return batchModelBanafoodIndexArchived;
      }
      if (params.type === MODEL_TYPES.INDEX) {
        return batchModelBanafoodIndex;
      }
      if (params.type === MODEL_TYPES.DETAILS) {
        return batchModelBanafoodDetailed;
      }
      if (params.type === MODEL_TYPES.UPLOAD) {
        return batchModelBanafood;
      }
      if (params.type === MODEL_TYPES.HANDSHAKE) {
        return batchModelBanafoodHandshake;
      }
      if (params.type === MODEL_TYPES.DASHBOARD) {
        return dashboardBananasBanafood;
      }
      return batchModelBanafood;

    case CITROSUCOBR: {
      if (params.type === MODEL_TYPES.INDEX) {
        if (params.status === BATCH_STATUS.ARCHIVED) {
          return batchModelCitrosucoBrazilIndexArchived;
        }
        return batchModelCitrosucoBrazilIndex;
      }
      if (params.type === MODEL_TYPES.CONTRACT_DETAILS) {
        if (params.status === BATCH_STATUS.ARCHIVED) {
          return batchModelCitrosucoBrazilContractArchived;
        }
        return batchModelCitrosucoBrazilContract;
      }
      if (params.type === MODEL_TYPES.DETAILS) {
        return batchModelCitrosucoBrazilDetails;
      }
      if (params.type === MODEL_TYPES.UPLOAD) {
        return batchModelCitrosucoBrazilUpload;
      }
      if (params.type === MODEL_TYPES.HANDSHAKE) {
        return batchModelCitrosucoBrazilHandshake;
      }
      // TODO: REFACTOR ASAP; this is here because of getTitleParamsAsString selector
      return batchModelCitrosucoBrazilIndex;
    }

    case CITROSUCOGHENT: {
      if (params.type === MODEL_TYPES.INDEX) {
        if (params.status === BATCH_STATUS.ARCHIVED) {
          return batchModelCitrosucoGhentIndexArchived;
        }
        return batchModelCitrosucoGhentIndex;
      }
      if (params.type === MODEL_TYPES.CONTRACT_DETAILS) {
        if (params.status === BATCH_STATUS.ARCHIVED) {
          return batchModelCitrosucoGhentContractArchived;
        }
        return batchModelCitrosucoGhentContract;
      }
      if (params.type === MODEL_TYPES.DETAILS) {
        return batchModelCitrosucoGhentDetails;
      }
      if (params.type === MODEL_TYPES.UPLOAD) {
        return batchModelCitrosucoGhentUpload;
      }
      if (params.type === MODEL_TYPES.HANDSHAKE) {
        return batchModelCitrosucoGhentHandshake;
      }
    }

    case REFRESCOGMBH: {
      if (params.type === MODEL_TYPES.INDEX) {
        if (params.status === BATCH_STATUS.ARCHIVED) {
          return batchModelRefrescoGmbHIndexArchived;
        }
        return batchModelRefrescoGmbHIndex;
      }
    }

    case KAUFLANDDE: {
      if (params.type === MODEL_TYPES.INDEX) {
        if (params.status === BATCH_STATUS.ARCHIVED) {
          return batchModelKauflandDEIndexArchived;
        }
        return batchModelKauflandDEIndex;
      }
    }

    default:
      return () => ({});
  }
}

/* resource and resources are two different endpoints, //TODO refactor its usages */
export const defineEndpointSemantics = (batchType) => {
  if (batchType.startsWith(CHANNELS.BANANAS) || batchType === `${CHANNELS.TILAPIA}/producerbatch`) {
    return 'resource';
  }
  return 'resources';
};
