import defaultTab from 'models/egg2/tabs/indexTabModel';
import settingsAndActions from 'models/egg2/settingsAndActions/indexArchived';
import { ORGANIZATIONS } from 'themes/constants';
import archivedModelFields from 'models/archivedModelFields';
import { ModelRequiredInfo } from 'lib/lib';

export default (modelRequiredInfo: ModelRequiredInfo) => {
  const indexArchivedTab = [...defaultTab(modelRequiredInfo), ...archivedModelFields(false)];
  return {
    tabs: [
      {
        modelName: 'defaultTab',
        originalName: ORGANIZATIONS.EGG2,
        title: ORGANIZATIONS.EGG2,
      },
    ],
    defaultTab: indexArchivedTab,
    settingsAndActions,
  };
};
