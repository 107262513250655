import Route from 'route-parser';
import serviceDataResponse from 'mocks/responses/serviceData';

import { getUserFromRequest } from 'mocks/usersUtils';

export default (request) => {
  const route = new Route('/api/v1/:channelName/service-data');
  const parameters = route.match(request.url);

  const user = getUserFromRequest(request);
  const { error, orgName } = user;

  if (error || !(parameters && parameters.channelName)) {
    return [500, error || 'channel not specified'];
  }

  return [200, serviceDataResponse(parameters.channelName, orgName)];
};
