import uitslagBatchesTabModel from 'models/hollander/tabs/uitslagBatchesTabModel';
import inslagBatchesTabModel from 'models/hollander/tabs/inslagBatchesTabModel';
import outBatchesTabModel from 'models/hollander/tabs/outBatchesTabModel';
import inBatchesTabModel from 'models/hollander/tabs/inBatchesTabModel';
import deliveryBatchesTabModel from 'models/hollander/tabs/deliveryBatchesTabModel';
import processBatchesTabModel from 'models/hollander/tabs/processBatchesTabModel';
import { defaultHandshakeSettingsAndActions } from 'models/utils/utils';

export default () => {
  const tabs = [
    {
      modelName: 'uitslagBatches',
      originalName: 'Hollander OUT',
      title: 'Hollander OUT',
    },
    {
      modelName: 'inslagBatches',
      originalName: 'Hollander IN',
      title: 'Hollander IN',
    },
    {
      modelName: 'outBatches',
      originalName: 'Banafood OUT',
      title: 'Banafood OUT',
    },
    {
      modelName: 'inBatches',
      originalName: 'Banafood IN',
      title: 'Banafood IN',
    },
    {
      modelName: 'deliveryBatches',
      originalName: 'Fyffes OUT',
      title: 'Fyffes OUT',
    },
    {
      modelName: 'processBatches',
      originalName: 'Fyffes IN',
      title: 'Fyffes IN',
    },
  ];

  return {
    tabs,
    uitslagBatches: uitslagBatchesTabModel(),
    inslagBatches: inslagBatchesTabModel(),
    outBatches: outBatchesTabModel(),
    inBatches: inBatchesTabModel(),
    deliveryBatches: deliveryBatchesTabModel(),
    processBatches: processBatchesTabModel(),
    settingsAndActions: defaultHandshakeSettingsAndActions,
  };
};
