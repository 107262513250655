import defaultTab from 'models/jumbo/tabs/defaultTabModel';
import { CHANNELS, ORGANIZATIONS } from 'themes/constants';
import archivedModelFields from 'models/archivedModelFields';
import { defaultContractSettingsAndActionsExcel } from 'models/utils/utils';
import { ModelRequiredInfo } from 'lib/lib';

export default (modelRequiredInfo: ModelRequiredInfo) => {
  const defaultArchivedTab = [...defaultTab(modelRequiredInfo), ...archivedModelFields()];
  return {
    tabs: [
      {
        modelName: 'defaultTab',
        originalName: ORGANIZATIONS.JUMBO,
        title: ORGANIZATIONS.JUMBO,
      },
    ],
    defaultTab: defaultArchivedTab,
    settingsAndActions: defaultContractSettingsAndActionsExcel(CHANNELS.TILAPIA),
  };
};
