import React from 'react';
import { WarningSvg } from 'components/icons';

interface OwnProps {
  subContractAmount?: number;
  linkText: string;
}

const SubContractWarning: React.FC<OwnProps> = (props) => {
  const { children, linkText, subContractAmount } = props;

  if (subContractAmount === 0) {
    return (
      <>
        {linkText}
        <span className="warning">
          <WarningSvg />
        </span>
      </>
    );
  }

  return <>{children}</>;
};

export default SubContractWarning;
