import defaultTab from 'models/egg2/tabs/defaultTabModel';
import { CHANNELS, ORGANIZATIONS } from 'themes/constants';
import archivedModelFields from 'models/archivedModelFields';
import { defaultContractSettingsAndActionsCSV } from 'models/utils/utils';
import { ModelRequiredInfo } from 'lib/lib';

export default (modelRequiredInfo: ModelRequiredInfo) => {
  const defaultArchivedTab = [...defaultTab(modelRequiredInfo), ...archivedModelFields(false)];
  return {
    tabs: [
      {
        modelName: 'defaultTab',
        originalName: ORGANIZATIONS.EGG2,
        title: ORGANIZATIONS.EGG2,
      },
    ],
    defaultTab: defaultArchivedTab,
    settingsAndActions: defaultContractSettingsAndActionsCSV(CHANNELS.EGGS),
  };
};
