import certFile from 'mocks/router/generic/certFile';
import documents from 'mocks/router/generic/documents';
import participants from 'mocks/router/generic/participants';
import profile from 'mocks/router/generic/profile';
import serviceData from 'mocks/router/generic/serviceData';
import signIn from 'mocks/router/generic/signIn';
import users from 'mocks/router/generic/users';
import approve from 'mocks/router/generic/approve';
import backgroundWorkerTasks from 'mocks/router/generic/backgroundWorkerTasks';
import unmatched from 'mocks/router/generic/unmatched';

export default {
  certFile,
  documents,
  participants,
  profile,
  'service-data': serviceData,
  'sign-in': signIn,
  users,
  approve,
  'background-worker-tasks': backgroundWorkerTasks,
  unmatched,
};
