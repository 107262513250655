import { asyncAction, syncAction } from 'lib/actions';
import * as uiActions from './actions';

export const uiModalAction = asyncAction('ui/MODAL');

export const openModalAction = (payload) => ({
  type: uiActions.uiModalAction.type,
  payload,
});

export const closeModalAction = () => ({
  type: uiActions.uiModalAction.failure,
});

export const setUiIsLoadingAction = syncAction<boolean>('ui/SET_UI_IS_LOADING');
