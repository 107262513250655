import React from 'react';
import { formatBatchDate } from 'lib/helpers';
import { getTheme } from 'themes/main';
import { renderShortDateFormat } from 'lib/renderHelpers';
import { BatchRecord, ModelRequiredInfo } from 'lib/lib';

// label defines what field to take from CSV batch file for upload
export default (modelRequiredInfo: ModelRequiredInfo) => [
  {
    key: 'jdeReference',
    label: 'JDE REFERENCE',
    title: 'JDE Reference',
    style: { flex: '1 0 50px' },
    defaultPreviewBeforeUploadColumn: true,
  },
  {
    key: 'contractNumber',
    label: 'CONTRACT NUMBER',
    title: 'Contract Number',
    style: { flex: '1 0 50px' },
    defaultPreviewBeforeUploadColumn: true,
  },
  {
    key: 'qttyContracted',
    label: 'QTTY CONTRACTED - KG',
    title: 'QTTY Contracted',
    style: { flex: '1 0 50px' },
    defaultPreviewBeforeUploadColumn: true,
    handleParsedValueFromCSVFile: (value: string) => parseInt(value || '0', 10),
  },
  {
    key: 'batchFinal',
    label: 'BATCH - FINAL',
    title: 'Batch Final',
    style: { flex: '1 0 100px' },
    defaultPreviewBeforeUploadColumn: true,
  },
  {
    key: 'manufacturedSequence',
    label: 'MANUFACTING SEQUENCE',
    title: 'Manufactured Sequence',
    style: { flex: '1 0 50px' },
  },
  {
    key: 'productCode',
    label: 'PRODUCT CODE',
    title: 'Product Code',
    style: { flex: '1 0 100px' },
    defaultPreviewBeforeUploadColumn: true,
  },
  {
    key: 'manufacturingDate',
    label: 'MANUFACTURING DATE',
    title: 'Manufacturing Date',
    style: { flex: '1 0 50px' },
    edit: { type: 'date' },
    handleParsedValueFromCSVFile: (value: string) =>
      formatBatchDate(
        value,
        getTheme(modelRequiredInfo.role, modelRequiredInfo.channelName).timezone,
        getTheme(modelRequiredInfo.role, modelRequiredInfo.channelName).timeFormats,
      ),
    renderPreviewBeforeUpload: (column: BatchRecord) =>
      renderShortDateFormat(column.manufacturingDate),
  },
  {
    key: 'startBlendingDate',
    label: 'START BLENDING DATE',
    title: 'Start Blending Date',
    style: { flex: '1 0 50px' },
    edit: { type: 'date' },
    handleParsedValueFromCSVFile: (value: string) =>
      formatBatchDate(
        value,
        getTheme(modelRequiredInfo.role, modelRequiredInfo.channelName).timezone,
        getTheme(modelRequiredInfo.role, modelRequiredInfo.channelName).timeFormats,
      ),
    renderPreviewBeforeUpload: (column: BatchRecord) =>
      renderShortDateFormat(column.startBlendingDate),
  },
  {
    key: 'finalBlendingDate',
    label: 'FINAL BLENDING DATE',
    title: 'Final Blending Date',
    style: { flex: '1 0 50px' },
    edit: { type: 'date' },
    handleParsedValueFromCSVFile: (value: string) =>
      formatBatchDate(
        value,
        getTheme(modelRequiredInfo.role, modelRequiredInfo.channelName).timezone,
        getTheme(modelRequiredInfo.role, modelRequiredInfo.channelName).timeFormats,
      ),
    renderPreviewBeforeUpload: (column: BatchRecord) =>
      renderShortDateFormat(column.finalBlendingDate),
  },
  {
    key: 'facilityName',
    label: 'FACILITY NAME',
    title: 'Facility Name',
    style: { flex: '1 0 50px' },
  },
  {
    key: 'farmName',
    label: 'FARM NAME',
    title: 'Farm Name',
    style: { flex: '1 0 50px' },
  },
  {
    key: 'farmAddress',
    label: 'FARM ADDRESS',
    title: 'Farm Address',
    style: { flex: '1 0 50px' },
  },
  {
    key: 'zipcode',
    label: 'ZIPCODE',
    title: 'Zipcode',
    style: { flex: '1 0 50px' },
  },
  {
    key: 'state',
    label: 'STATE',
    title: 'State',
    style: { flex: '1 0 50px' },
  },
  {
    key: 'city',
    label: 'CITY',
    title: 'City',
    style: { flex: '1 0 50px' },
  },
  {
    key: 'varietyName',
    label: 'VARIETY NAME',
    title: 'Variety Name',
    style: { flex: '1 0 50px' },
  },
  {
    key: 'block',
    label: 'BLOCK',
    title: 'Block',
    style: { flex: '1 0 50px' },
  },
  {
    key: 'fruitHarvestStartDate',
    label: 'FRUIT HARVEST START DATE',
    title: 'Fruit Harvest Start Date',
    style: { flex: '1 0 50px' },
    edit: { type: 'date' },
    handleParsedValueFromCSVFile: (value: string) =>
      formatBatchDate(
        value,
        getTheme(modelRequiredInfo.role, modelRequiredInfo.channelName).timezone,
        getTheme(modelRequiredInfo.role, modelRequiredInfo.channelName).timeFormats,
      ),
    renderPreviewBeforeUpload: (column: BatchRecord) =>
      renderShortDateFormat(column.fruitHarvestStartDate),
  },
  {
    key: 'fruitHarvestFinalDate',
    label: 'FRUIT HARVEST FINAL DATE',
    title: 'Fruit Harvest Final Date',
    style: { flex: '1 0 50px' },
    edit: { type: 'date' },
    handleParsedValueFromCSVFile: (value: string) =>
      formatBatchDate(
        value,
        getTheme(modelRequiredInfo.role, modelRequiredInfo.channelName).timezone,
        getTheme(modelRequiredInfo.role, modelRequiredInfo.channelName).timeFormats,
      ),
    renderPreviewBeforeUpload: (column: BatchRecord) =>
      renderShortDateFormat(column.fruitHarvestFinalDate),
  },
  {
    key: 'percentFarm',
    label: '%FARM',
    title: '% Farm',
    style: { flex: '1 0 50px' },
  },
  {
    key: 'totalPickers',
    label: 'TOTAL PICKERS',
    title: 'Total Pickers',
    style: { flex: '1 0 50px' },
  },
  {
    key: 'ageAverage',
    label: 'AGE AVERAGE',
    title: 'Age Average',
    style: { flex: '1 0 50px' },
  },
  {
    key: 'dateOfReceiptSantos',
    label: 'DATE OF RECEIPT SANTOS',
    title: 'Date Of Receipt Santos',
    style: { flex: '1 0 50px' },
    edit: { type: 'date' },
    handleParsedValueFromCSVFile: (value: string) =>
      formatBatchDate(
        value,
        getTheme(modelRequiredInfo.role, modelRequiredInfo.channelName).timezone,
        getTheme(modelRequiredInfo.role, modelRequiredInfo.channelName).timeFormats,
      ),
    renderPreviewBeforeUpload: (column: BatchRecord) =>
      renderShortDateFormat(column.dateOfReceiptSantos),
  },
  {
    key: 'batchId',
    label: 'Batch Id',
    title: 'Batch Details',
    style: { flex: '0 0 150px' },
  },
];
