import { CHANNELS, ORGANIZATIONS } from 'themes/constants';

const tilapiaBatchesInfo = {
  ProducerBatch: {
    contract: 'batchCodeId',
    superType: 'ImporterBatch',
    subType: '',
    subcontract: '',
    endpoint: 'tilapia/producerbatch',
    approvable: null,
    subBatches: {
      ProducerDetailBatch: {},
      ProducerExportBatch: {},
      ProducerPackingBatch: {},
      ProducerProcessingBatch: {},
      ProducerRawMaterialBatch: {},
      ProducerHarvestingFarmBatch: {},
      ProducerGrowUpBatch: {},
      ProducerStockingBatch: {},
      ProducerHarvestingHcBatch: {},
      ProducerNurseryBatch: {},
      ProducerMassBalanceBatch: {},
    },
  },
  ImporterBatch: {
    contract: 'productBatchIdBulk',
    superType: 'ProcessorBatch',
    subType: 'ProducerBatch',
    subcontract: 'containerNumber',
    endpoint: 'tilapia/importerbatch',
    approvable: null,
    subBatches: {},
  },
  ProcessorBatch: {
    contract: 'articleNumberConsumerProduct',
    superType: 'RetailerBatch',
    subType: 'ImporterBatch',
    subcontract: 'productBatchIdBulk',
    endpoint: 'tilapia/processorbatch',
    approvable: null,
    subBatches: {},
  },
  RetailerBatch: {
    contract: 'articleNumberConsumerProduct',
    superType: 'AuditorBatch',
    subType: 'ImporterBatch', // this is correct
    subcontract: 'articleNumberConsumerProduct',
    endpoint: 'tilapia/retailerbatch',
    approvable: null,
    subBatches: {},
  },
};

const tilapiaServiceData = (orgName) => {
  switch (orgName) {
    case ORGANIZATIONS.SIM:
      return {
        documentCategories: [],
        orgs: [
          {
            id: 5,
            name: 'SIM',
            fullname: 'Sim company',
            is_approver: true,
            level: 50,
            logo: null,
            role: 'AUDITOR',
          },
          {
            id: 41,
            name: 'SeafoodConnection',
            fullname: 'Seafood Connection',
            is_approver: false,
            level: 20,
            logo: null,
            role: 'IMPORTER',
          },
          {
            id: 42,
            name: 'Mayonna',
            fullname: 'Mayonna',
            is_approver: false,
            level: 30,
            logo: null,
            role: 'PROCESSOR',
          },
          {
            id: 43,
            name: 'RegalSprings',
            fullname: 'Regal Springs',
            is_approver: false,
            level: 10,
            logo: null,
            role: 'PRODUCER',
          },
          {
            id: 44,
            name: 'Jumbo',
            fullname: 'Jumbo',
            is_approver: false,
            level: 40,
            logo: null,
            role: 'RETAILER',
          },
        ],
        participants: {
          preferredBatchByOrgRole: {
            PRODUCER: 'ProducerBatch',
            IMPORTER: 'ImporterBatch',
            PROCESSOR: 'ProcessorBatch',
            RETAILER: 'RetailerBatch',
            AUDITOR: 'RetailerBatch',
          },
        },
        batches: tilapiaBatchesInfo,
        contacts: {
          address: 'Toermalijnstraat 18b 1812 RL Alkmaar The Netherlands',
          phones: ['+31 (0) 725410390'],
          emails: ['info@simsupplychain.com'],
          sites: ['https://simsupplychain.com'],
        },
        eventTypes: [
          { id: 1, title: 'Batch upload', slug: 'BatchUpload' },
          { id: 2, title: 'Batch archive', slug: 'BatchArchive' },
          { id: 3, title: 'Batch approval status update', slug: 'BatchStatusChange' },
          { id: 4, title: 'Certificate upload', slug: 'CertificateUpload' },
          { id: 5, title: 'Certificate archive', slug: 'CertificateArchive' },
          { id: 6, title: 'Certificate approval status change', slug: 'CertificateStatusChange' },
          { id: 7, title: 'Request created', slug: 'DemandCreate' },
          { id: 8, title: 'Request status change', slug: 'DemandStatusChange' },
        ],
      };

    case ORGANIZATIONS.MAYONNA:
      return {
        documentCategories: [],
        orgs: [
          {
            id: 5,
            name: 'SIM',
            fullname: 'Sim company',
            is_approver: true,
            level: 50,
            logo: null,
            role: 'AUDITOR',
          },
          {
            id: 41,
            name: 'SeafoodConnection',
            fullname: 'Seafood Connection',
            is_approver: false,
            level: 20,
            logo: null,
            role: 'IMPORTER',
          },
          {
            id: 42,
            name: 'Mayonna',
            fullname: 'Mayonna',
            is_approver: false,
            level: 30,
            logo: null,
            role: 'PROCESSOR',
          },
          {
            id: 43,
            name: 'RegalSprings',
            fullname: 'Regal Springs',
            is_approver: false,
            level: 10,
            logo: null,
            role: 'PRODUCER',
          },
          {
            id: 44,
            name: 'Jumbo',
            fullname: 'Jumbo',
            is_approver: false,
            level: 40,
            logo: null,
            role: 'RETAILER',
          },
        ],
        participants: {
          preferredBatchByOrgRole: {
            PRODUCER: 'ProducerBatch',
            IMPORTER: 'ImporterBatch',
            PROCESSOR: 'ProcessorBatch',
            RETAILER: 'RetailerBatch',
            AUDITOR: 'RetailerBatch',
          },
        },
        batches: tilapiaBatchesInfo,
        contacts: {
          address: 'Toermalijnstraat 18b 1812 RL Alkmaar The Netherlands',
          phones: ['+31 (0) 725410390'],
          emails: ['info@simsupplychain.com'],
          sites: ['https://simsupplychain.com'],
        },
        eventTypes: [
          { id: 1, title: 'Batch upload', slug: 'BatchUpload' },
          { id: 2, title: 'Batch archive', slug: 'BatchArchive' },
          { id: 3, title: 'Batch approval status update', slug: 'BatchStatusChange' },
          { id: 4, title: 'Certificate upload', slug: 'CertificateUpload' },
          { id: 5, title: 'Certificate archive', slug: 'CertificateArchive' },
          { id: 6, title: 'Certificate approval status change', slug: 'CertificateStatusChange' },
          { id: 7, title: 'Request created', slug: 'DemandCreate' },
          { id: 8, title: 'Request status change', slug: 'DemandStatusChange' },
        ],
      };
    case ORGANIZATIONS.REGALSPRINGS:
      return {
        documentCategories: [],
        orgs: [
          {
            id: 5,
            name: 'SIM',
            fullname: 'Sim company',
            is_approver: true,
            level: 50,
            logo: null,
            role: 'AUDITOR',
          },
          {
            id: 41,
            name: 'SeafoodConnection',
            fullname: 'Seafood Connection',
            is_approver: false,
            level: 20,
            logo: null,
            role: 'IMPORTER',
          },
          {
            id: 42,
            name: 'Mayonna',
            fullname: 'Mayonna',
            is_approver: false,
            level: 30,
            logo: null,
            role: 'PROCESSOR',
          },
          {
            id: 43,
            name: 'RegalSprings',
            fullname: 'Regal Springs',
            is_approver: false,
            level: 10,
            logo: null,
            role: 'PRODUCER',
          },
          {
            id: 44,
            name: 'Jumbo',
            fullname: 'Jumbo',
            is_approver: false,
            level: 40,
            logo: null,
            role: 'RETAILER',
          },
        ],
        participants: {
          preferredBatchByOrgRole: {
            PRODUCER: 'ProducerBatch',
            IMPORTER: 'ImporterBatch',
            PROCESSOR: 'ProcessorBatch',
            RETAILER: 'RetailerBatch',
            AUDITOR: 'RetailerBatch',
          },
        },
        batches: {
          ProducerBatch: {
            contract: 'batchCodeId',
            superType: 'ImporterBatch',
            subType: '',
            subcontract: '',
            endpoint: 'tilapia/producerbatch',
            approvable: null,
            subBatches: {
              ProducerDetailBatch: {},
              ProducerExportBatch: {},
              ProducerPackingBatch: {},
              ProducerProcessingBatch: {},
              ProducerRawMaterialBatch: {},
              ProducerHarvestingFarmBatch: {},
              ProducerGrowUpBatch: {},
              ProducerStockingBatch: {},
              ProducerHarvestingHcBatch: {},
              ProducerNurseryBatch: {},
              ProducerMassBalanceBatch: {},
            },
          },
          ImporterBatch: {
            contract: 'productBatchIdBulk',
            superType: 'ProcessorBatch',
            subType: 'ProducerBatch',
            subcontract: 'containerNumber',
            endpoint: 'tilapia/importerbatch',
            approvable: null,
            subBatches: {},
          },
          ProcessorBatch: {
            contract: 'articleNumberConsumerProduct',
            superType: 'RetailerBatch',
            subType: 'ImporterBatch',
            subcontract: 'productBatchIdBulk',
            endpoint: 'tilapia/processorbatch',
            approvable: null,
            subBatches: {},
          },
          RetailerBatch: {
            contract: 'articleNumberConsumerProduct',
            superType: 'AuditorBatch',
            subType: 'ImporterBatch',
            subcontract: 'articleNumberConsumerProduct',
            endpoint: 'tilapia/retailerbatch',
            approvable: null,
            subBatches: {},
          },
        },
        contacts: {
          address: 'Toermalijnstraat 18b 1812 RL Alkmaar The Netherlands',
          phones: ['+31 (0) 725410390'],
          emails: ['info@simsupplychain.com'],
          sites: ['https://simsupplychain.com'],
        },
        eventTypes: [
          { id: 1, title: 'Batch upload', slug: 'BatchUpload' },
          { id: 2, title: 'Batch archive', slug: 'BatchArchive' },
          { id: 3, title: 'Batch approval status update', slug: 'BatchStatusChange' },
          { id: 4, title: 'Certificate upload', slug: 'CertificateUpload' },
          { id: 5, title: 'Certificate archive', slug: 'CertificateArchive' },
          { id: 6, title: 'Certificate approval status change', slug: 'CertificateStatusChange' },
          { id: 7, title: 'Request created', slug: 'DemandCreate' },
          { id: 8, title: 'Request status change', slug: 'DemandStatusChange' },
        ],
      };

    case ORGANIZATIONS.JUMBO:
      return {
        documentCategories: [],
        orgs: [
          {
            id: 5,
            name: 'SIM',
            fullname: 'Sim company',
            is_approver: true,
            level: 50,
            logo: null,
            role: 'AUDITOR',
          },
          {
            id: 41,
            name: 'SeafoodConnection',
            fullname: 'Seafood Connection',
            is_approver: false,
            level: 20,
            logo: null,
            role: 'IMPORTER',
          },
          {
            id: 42,
            name: 'Mayonna',
            fullname: 'Mayonna',
            is_approver: false,
            level: 30,
            logo: null,
            role: 'PROCESSOR',
          },
          {
            id: 43,
            name: 'RegalSprings',
            fullname: 'Regal Springs',
            is_approver: false,
            level: 10,
            logo: null,
            role: 'PRODUCER',
          },
          {
            id: 44,
            name: 'Jumbo',
            fullname: 'Jumbo',
            is_approver: false,
            level: 40,
            logo: null,
            role: 'RETAILER',
          },
        ],
        participants: {
          preferredBatchByOrgRole: {
            PRODUCER: 'ProducerBatch',
            IMPORTER: 'ImporterBatch',
            PROCESSOR: 'ProcessorBatch',
            RETAILER: 'RetailerBatch',
            AUDITOR: 'RetailerBatch',
          },
        },
        batches: tilapiaBatchesInfo,
        contacts: {
          address: 'Toermalijnstraat 18b 1812 RL Alkmaar The Netherlands',
          phones: ['+31 (0) 725410390'],
          emails: ['info@simsupplychain.com'],
          sites: ['https://simsupplychain.com'],
        },
        eventTypes: [
          { id: 1, title: 'Batch upload', slug: 'BatchUpload' },
          { id: 2, title: 'Batch archive', slug: 'BatchArchive' },
          { id: 3, title: 'Batch approval status update', slug: 'BatchStatusChange' },
          { id: 4, title: 'Certificate upload', slug: 'CertificateUpload' },
          { id: 5, title: 'Certificate archive', slug: 'CertificateArchive' },
          { id: 6, title: 'Certificate approval status change', slug: 'CertificateStatusChange' },
          { id: 7, title: 'Request created', slug: 'DemandCreate' },
          { id: 8, title: 'Request status change', slug: 'DemandStatusChange' },
        ],
      };

    case ORGANIZATIONS.SEAFOODCONNECTION:
      return {
        documentCategories: [],
        orgs: [
          {
            id: 5,
            name: 'SIM',
            fullname: 'Sim company',
            is_approver: true,
            level: 50,
            logo: null,
            role: 'AUDITOR',
          },
          {
            id: 41,
            name: 'SeafoodConnection',
            fullname: 'Seafood Connection',
            is_approver: false,
            level: 20,
            logo: null,
            role: 'IMPORTER',
          },
          {
            id: 42,
            name: 'Mayonna',
            fullname: 'Mayonna',
            is_approver: false,
            level: 30,
            logo: null,
            role: 'PROCESSOR',
          },
          {
            id: 43,
            name: 'RegalSprings',
            fullname: 'Regal Springs',
            is_approver: false,
            level: 10,
            logo: null,
            role: 'PRODUCER',
          },
          {
            id: 44,
            name: 'Jumbo',
            fullname: 'Jumbo',
            is_approver: false,
            level: 40,
            logo: null,
            role: 'RETAILER',
          },
        ],
        participants: {
          preferredBatchByOrgRole: {
            PRODUCER: 'ProducerBatch',
            IMPORTER: 'ImporterBatch',
            PROCESSOR: 'ProcessorBatch',
            RETAILER: 'RetailerBatch',
            AUDITOR: 'RetailerBatch',
          },
        },
        batches: tilapiaBatchesInfo,
        contacts: {
          address: 'Toermalijnstraat 18b 1812 RL Alkmaar The Netherlands',
          phones: ['+31 (0) 725410390'],
          emails: ['info@simsupplychain.com'],
          sites: ['https://simsupplychain.com'],
        },
        eventTypes: [
          { id: 1, title: 'Batch upload', slug: 'BatchUpload' },
          { id: 2, title: 'Batch archive', slug: 'BatchArchive' },
          { id: 3, title: 'Batch approval status update', slug: 'BatchStatusChange' },
          { id: 4, title: 'Certificate upload', slug: 'CertificateUpload' },
          { id: 5, title: 'Certificate archive', slug: 'CertificateArchive' },
          { id: 6, title: 'Certificate approval status change', slug: 'CertificateStatusChange' },
          { id: 7, title: 'Request created', slug: 'DemandCreate' },
          { id: 8, title: 'Request status change', slug: 'DemandStatusChange' },
        ],
      };

    default: {
      return [500, `No such organization ${orgName}`];
    }
  }
};

const bananasServiceData = (orgName) => {
  switch (orgName) {
    case ORGANIZATIONS.SIM:
      return {
        documentCategories: [],
        orgs: [
          {
            id: 5,
            name: 'SIM',
            fullname: 'Sim company',
            is_approver: true,
            level: 50,
            logo: null,
            role: 'AUDITOR',
          },
          {
            id: 45,
            name: 'Plus',
            fullname: 'Plus',
            is_approver: false,
            level: 30,
            logo: null,
            role: 'RETAILER',
          },
          {
            id: 46,
            name: 'Hollander',
            fullname: 'Hollander',
            is_approver: false,
            level: 20,
            logo: null,
            role: 'DISTRIBUTOR',
          },
          {
            id: 47,
            name: 'Fyffes',
            fullname: 'Fyffes',
            is_approver: false,
            level: 10,
            logo: null,
            role: 'PRODUCER',
          },
          {
            id: 48,
            name: 'Banafood',
            fullname: 'Banafood',
            is_approver: false,
            level: 15,
            logo: null,
            role: 'RESELLER',
          },
        ],
        participants: {
          preferredBatchByOrgRole: {
            AUDITOR: 'DistributorBatch',
            DISTRIBUTOR: 'DistributorBatch',
            PRODUCER: 'ProducerBatch',
            RESELLER: 'ResellerBatch',
          },
        },
        batches: {
          DistributorBatch: {
            contract: 'batchNumber',
            superType: '',
            subType: 'ResellerBatch',
            subcontract: '',
            endpoint: 'bananas/distributorbatch',
            approvable: null,
            subBatches: {
              inslagBatches: {
                type: 'DistributorInslagBatch',
              },
              uitslagBatches: {
                type: 'DistributorUitslagBatch',
              },
            },
          },
          ProducerBatch: {
            contract: 'floId',
            superType: 'ResellerBatch',
            subType: '',
            subcontract: '',
            endpoint: 'bananas/producerbatch',
            approvable: null,
            subBatches: {
              processBatches: {
                type: 'ProducerProcessBatch',
              },
              deliveryBatches: {
                type: 'ProducerDeliveryBatch',
              },
            },
          },
          ResellerBatch: {
            contract: 'banafoodSSCCPallet',
            superType: 'DistributorBatch',
            subType: 'ProducerBatch',
            subcontract: '',
            endpoint: 'bananas/resellerbatch',
            approvable: null,
            subBatches: {
              inBatches: {
                type: 'ResellerInBatch',
              },
              outBatches: {
                type: 'ResellerOutBatch',
              },
            },
          },
        },
        contacts: {
          address: 'Toermalijnstraat 18b 1812 RL Alkmaar The Netherlands',
          phones: ['+31 (0) 725410390'],
          emails: ['info@simsupplychain.com'],
          sites: ['https://simsupplychain.com'],
        },
        eventTypes: [
          { id: 1, title: 'Batch upload', slug: 'BatchUpload' },
          { id: 2, title: 'Batch archive', slug: 'BatchArchive' },
          { id: 3, title: 'Batch approval status update', slug: 'BatchStatusChange' },
          { id: 4, title: 'Certificate upload', slug: 'CertificateUpload' },
          { id: 5, title: 'Certificate archive', slug: 'CertificateArchive' },
          { id: 6, title: 'Certificate approval status change', slug: 'CertificateStatusChange' },
          { id: 7, title: 'Request created', slug: 'DemandCreate' },
          { id: 8, title: 'Request status change', slug: 'DemandStatusChange' },
        ],
      };

    case ORGANIZATIONS.BANAFOOD:
      return {
        documentCategories: [],
        orgs: [
          {
            id: 5,
            name: 'SIM',
            fullname: 'Sim company',
            is_approver: true,
            level: 50,
            logo: null,
            role: 'AUDITOR',
          },
          {
            id: 45,
            name: 'Plus',
            fullname: 'Plus',
            is_approver: false,
            level: 30,
            logo: null,
            role: 'RETAILER',
          },
          {
            id: 46,
            name: 'Hollander',
            fullname: 'Hollander',
            is_approver: false,
            level: 20,
            logo: null,
            role: 'DISTRIBUTOR',
          },
          {
            id: 47,
            name: 'Fyffes',
            fullname: 'Fyffes',
            is_approver: false,
            level: 10,
            logo: null,
            role: 'PRODUCER',
          },
          {
            id: 48,
            name: 'Banafood',
            fullname: 'Banafood',
            is_approver: false,
            level: 15,
            logo: null,
            role: 'RESELLER',
          },
        ],
        participants: {
          preferredBatchByOrgRole: {
            AUDITOR: 'DistributorBatch',
            DISTRIBUTOR: 'DistributorBatch',
            PRODUCER: 'ProducerBatch',
            RESELLER: 'ResellerBatch',
          },
        },
        batches: {
          DistributorBatch: {
            contract: 'batchNumber',
            superType: '',
            subType: 'ResellerBatch',
            subcontract: '',
            endpoint: 'bananas/distributorbatch',
            approvable: null,
            subBatches: {
              inslagBatches: {
                type: 'DistributorInslagBatch',
              },
              uitslagBatches: {
                type: 'DistributorUitslagBatch',
              },
            },
          },
          ProducerBatch: {
            contract: 'floId',
            superType: 'ResellerBatch',
            subType: '',
            subcontract: '',
            endpoint: 'bananas/producerbatch',
            approvable: null,
            subBatches: {
              processBatches: {
                type: 'ProducerProcessBatch',
              },
              deliveryBatches: {
                type: 'ProducerDeliveryBatch',
              },
            },
          },
          ResellerBatch: {
            contract: 'banafoodSSCCPallet',
            superType: 'DistributorBatch',
            subType: 'ProducerBatch',
            subcontract: '',
            endpoint: 'bananas/resellerbatch',
            approvable: null,
            subBatches: {
              inBatches: {
                type: 'ResellerInBatch',
              },
              outBatches: {
                type: 'ResellerOutBatch',
              },
            },
          },
        },
        contacts: {
          address: 'Toermalijnstraat 18b 1812 RL Alkmaar The Netherlands',
          phones: ['+31 (0) 725410390'],
          emails: ['info@simsupplychain.com'],
          sites: ['https://simsupplychain.com'],
        },
        eventTypes: [
          { id: 1, title: 'Batch upload', slug: 'BatchUpload' },
          { id: 2, title: 'Batch archive', slug: 'BatchArchive' },
          { id: 3, title: 'Batch approval status update', slug: 'BatchStatusChange' },
          { id: 4, title: 'Certificate upload', slug: 'CertificateUpload' },
          { id: 5, title: 'Certificate archive', slug: 'CertificateArchive' },
          { id: 6, title: 'Certificate approval status change', slug: 'CertificateStatusChange' },
          { id: 7, title: 'Request created', slug: 'DemandCreate' },
          { id: 8, title: 'Request status change', slug: 'DemandStatusChange' },
        ],
      };

    case ORGANIZATIONS.HOLLANDER:
      return {
        documentCategories: [],
        orgs: [
          {
            id: 5,
            name: 'SIM',
            fullname: 'Sim company',
            is_approver: true,
            level: 50,
            logo: null,
            role: 'AUDITOR',
          },
          {
            id: 45,
            name: 'Plus',
            fullname: 'Plus',
            is_approver: false,
            level: 30,
            logo: null,
            role: 'RETAILER',
          },
          {
            id: 46,
            name: 'Hollander',
            fullname: 'Hollander',
            is_approver: false,
            level: 20,
            logo: null,
            role: 'DISTRIBUTOR',
          },
          {
            id: 47,
            name: 'Fyffes',
            fullname: 'Fyffes',
            is_approver: false,
            level: 10,
            logo: null,
            role: 'PRODUCER',
          },
          {
            id: 48,
            name: 'Banafood',
            fullname: 'Banafood',
            is_approver: false,
            level: 15,
            logo: null,
            role: 'RESELLER',
          },
        ],
        participants: {
          preferredBatchByOrgRole: {
            AUDITOR: 'DistributorBatch',
            DISTRIBUTOR: 'DistributorBatch',
            PRODUCER: 'ProducerBatch',
            RESELLER: 'ResellerBatch',
          },
        },
        batches: {
          DistributorBatch: {
            contract: 'batchNumber',
            superType: '',
            subType: 'ResellerBatch',
            subcontract: '',
            endpoint: 'bananas/distributorbatch',
            approvable: null,
            subBatches: {
              inslagBatches: {
                type: 'DistributorInslagBatch',
              },
              uitslagBatches: {
                type: 'DistributorUitslagBatch',
              },
            },
          },
          ProducerBatch: {
            contract: 'floId',
            superType: 'ResellerBatch',
            subType: '',
            subcontract: '',
            endpoint: 'bananas/producerbatch',
            approvable: null,
            subBatches: {
              processBatches: {
                type: 'ProducerProcessBatch',
              },
              deliveryBatches: {
                type: 'ProducerDeliveryBatch',
              },
            },
          },
          ResellerBatch: {
            contract: 'banafoodSSCCPallet',
            superType: 'DistributorBatch',
            subType: 'ProducerBatch',
            subcontract: '',
            endpoint: 'bananas/resellerbatch',
            approvable: null,
            subBatches: {
              inBatches: {
                type: 'ResellerInBatch',
              },
              outBatches: {
                type: 'ResellerOutBatch',
              },
            },
          },
        },
        contacts: {
          address: 'Toermalijnstraat 18b 1812 RL Alkmaar The Netherlands',
          phones: ['+31 (0) 725410390'],
          emails: ['info@simsupplychain.com'],
          sites: ['https://simsupplychain.com'],
        },
        eventTypes: [
          { id: 1, title: 'Batch upload', slug: 'BatchUpload' },
          { id: 2, title: 'Batch archive', slug: 'BatchArchive' },
          { id: 3, title: 'Batch approval status update', slug: 'BatchStatusChange' },
          { id: 4, title: 'Certificate upload', slug: 'CertificateUpload' },
          { id: 5, title: 'Certificate archive', slug: 'CertificateArchive' },
          { id: 6, title: 'Certificate approval status change', slug: 'CertificateStatusChange' },
          { id: 7, title: 'Request created', slug: 'DemandCreate' },
          { id: 8, title: 'Request status change', slug: 'DemandStatusChange' },
        ],
      };

    case ORGANIZATIONS.FYFFES:
      return {
        documentCategories: [],
        orgs: [
          {
            id: 5,
            name: 'SIM',
            fullname: 'Sim company',
            is_approver: true,
            level: 50,
            logo: null,
            role: 'AUDITOR',
          },
          {
            id: 45,
            name: 'Plus',
            fullname: 'Plus',
            is_approver: false,
            level: 30,
            logo: null,
            role: 'RETAILER',
          },
          {
            id: 46,
            name: 'Hollander',
            fullname: 'Hollander',
            is_approver: false,
            level: 20,
            logo: null,
            role: 'DISTRIBUTOR',
          },
          {
            id: 47,
            name: 'Fyffes',
            fullname: 'Fyffes',
            is_approver: false,
            level: 10,
            logo: null,
            role: 'PRODUCER',
          },
          {
            id: 48,
            name: 'Banafood',
            fullname: 'Banafood',
            is_approver: false,
            level: 15,
            logo: null,
            role: 'RESELLER',
          },
        ],
        participants: {
          preferredBatchByOrgRole: {
            AUDITOR: 'DistributorBatch',
            DISTRIBUTOR: 'DistributorBatch',
            PRODUCER: 'ProducerBatch',
            RESELLER: 'ResellerBatch',
          },
        },
        batches: {
          DistributorBatch: {
            contract: 'batchNumber',
            superType: '',
            subType: 'ResellerBatch',
            subcontract: '',
            endpoint: 'bananas/distributorbatch',
            approvable: null,
            subBatches: {
              inslagBatches: {
                type: 'DistributorInslagBatch',
              },
              uitslagBatches: {
                type: 'DistributorUitslagBatch',
              },
            },
          },
          ProducerBatch: {
            contract: 'floId',
            superType: 'ResellerBatch',
            subType: '',
            subcontract: '',
            endpoint: 'bananas/producerbatch',
            approvable: null,
            subBatches: {
              processBatches: {
                type: 'ProducerProcessBatch',
              },
              deliveryBatches: {
                type: 'ProducerDeliveryBatch',
              },
            },
          },
          ResellerBatch: {
            contract: 'banafoodSSCCPallet',
            superType: 'DistributorBatch',
            subType: 'ProducerBatch',
            subcontract: '',
            endpoint: 'bananas/resellerbatch',
            approvable: null,
            subBatches: {
              inBatches: {
                type: 'ResellerInBatch',
              },
              outBatches: {
                type: 'ResellerOutBatch',
              },
            },
          },
        },
        contacts: {
          address: 'Toermalijnstraat 18b 1812 RL Alkmaar The Netherlands',
          phones: ['+31 (0) 725410390'],
          emails: ['info@simsupplychain.com'],
          sites: ['https://simsupplychain.com'],
        },
        eventTypes: [
          { id: 1, title: 'Batch upload', slug: 'BatchUpload' },
          { id: 2, title: 'Batch archive', slug: 'BatchArchive' },
          { id: 3, title: 'Batch approval status update', slug: 'BatchStatusChange' },
          { id: 4, title: 'Certificate upload', slug: 'CertificateUpload' },
          { id: 5, title: 'Certificate archive', slug: 'CertificateArchive' },
          { id: 6, title: 'Certificate approval status change', slug: 'CertificateStatusChange' },
          { id: 7, title: 'Request created', slug: 'DemandCreate' },
          { id: 8, title: 'Request status change', slug: 'DemandStatusChange' },
        ],
      };

    default: {
      return [500, `No such organization ${orgName}`];
    }
  }
};

const orangesServiceData = (orgName) => {
  switch (orgName) {
    case ORGANIZATIONS.AH:
    case ORGANIZATIONS.SIM:
    case ORGANIZATIONS.JDE:
    case ORGANIZATIONS.LDCBR:
    case ORGANIZATIONS.REFRESCO:
      return {
        documentCategories: [
          {
            id: 2,
            label: 'Product/Food Safety',
            documentTypes: [
              {
                id: 5,
                label: 'BRC Food',
                hasNumber: true,
                documentResults: [
                  { id: 12, label: 'A' },
                  { id: 13, label: 'AA' },
                  { id: 14, label: 'AA+' },
                  { id: 15, label: 'A+' },
                  { id: 16, label: 'B' },
                  { id: 17, label: 'B+' },
                  { id: 18, label: 'C' },
                  { id: 19, label: 'C+' },
                  { id: 20, label: 'D' },
                  { id: 21, label: 'D+' },
                ],
                documentScopes: [
                  { id: 3, label: '01. Raw red meat' },
                  { id: 4, label: '02. Raw poultry' },
                  { id: 5, label: '03. - Raw prepared products (meat and vegetarian)' },
                  { id: 6, label: '04. Raw Fish products and preparation' },
                  { id: 7, label: '05. Fruits, vegetables and nuts' },
                  { id: 8, label: '06. Prepared fruit, vegetables and nuts' },
                  { id: 9, label: '07. Dairy, liquid egg' },
                  { id: 10, label: '08. Cooked meat/fish products' },
                  { id: 11, label: '09. Raw cured/or fermented meat and fish' },
                  { id: 12, label: '10. Ready meals and sandwiches; ready to eat desserts' },
                  { id: 13, label: '11. Low/high acid in cans/glass' },
                  { id: 14, label: '12. Beverages' },
                  { id: 15, label: '13. Alcoholic drinks and fermented/brewed products' },
                  { id: 16, label: '14. Bakery' },
                  { id: 17, label: '15. Dried foods and ingredients' },
                  { id: 18, label: '16. Confectionery' },
                  { id: 19, label: '17. Cereals and snacks' },
                  { id: 20, label: '18. Oils and fats' },
                ],
              },
              {
                id: 6,
                label: 'Food Safety System Certification 22000',
                hasNumber: true,
                documentResults: [
                  { id: 22, label: 'Compliant' },
                  { id: 23, label: 'Non Compliant' },
                ],
                documentScopes: [
                  { id: 21, label: 'AI. Farming of animals for meat/milk/eggs/honey' },
                  { id: 22, label: 'AII. Farming of fish and seafood' },
                  { id: 23, label: 'CI. Processing of perishable animal products' },
                  { id: 24, label: 'CII. Processing of perishable plant products' },
                  {
                    id: 25,
                    label:
                      'CIII. Processing of perishable animal and plant producuts (mixed products)',
                  },
                  { id: 26, label: 'CIV. Processing of ambient stable products' },
                  { id: 27, label: 'DI. Production of feed' },
                  { id: 28, label: 'DII. Production of pet food (for dogs and cats only)' },
                  { id: 29, label: 'E. Catering' },
                  { id: 30, label: 'F. Distribution' },
                  { id: 31, label: 'FI. Retail/wholesale' },
                  { id: 32, label: 'G. Provision of transport and storage services' },
                  {
                    id: 33,
                    label:
                      'GI. Provision of transport and storage services for perishable food & feed',
                  },
                  {
                    id: 34,
                    label:
                      'GII. Provision of transport and storage services for ambient stable food & feed',
                  },
                  {
                    id: 35,
                    label: 'I. Production of food and feed packaging and packaging material',
                  },
                  {
                    id: 36,
                    label:
                      'K. Production of (bio)chemicals (excluding pesticides, drugs, fertilizers and cleaning products)',
                  },
                ],
              },
              {
                id: 7,
                label: 'IFS Food',
                hasNumber: true,
                documentResults: [
                  { id: 24, label: 'Higher Level' },
                  { id: 25, label: 'Foundation Level' },
                ],
                documentScopes: [
                  { id: 37, label: '1. Red and white meat, poultry and meat products' },
                  { id: 38, label: '2. Fish and fish products' },
                  { id: 39, label: '3. Egg and egg products' },
                  { id: 40, label: '4. Dairy products' },
                  { id: 41, label: '5. Fruits and vegetables' },
                  {
                    id: 42,
                    label:
                      '6. Grain products, cereals, industrial bakery and pastry, confectionary, snacks',
                  },
                  { id: 43, label: '7. Combined products' },
                  { id: 44, label: '8. Beverages' },
                  { id: 45, label: '9. Oils and fats' },
                  { id: 46, label: '10. Dry goods, other ingredients and supplements' },
                  { id: 47, label: '11. Pet food' },
                ],
              },
              {
                id: 8,
                label: 'Safe Quality Food (SQF)',
                hasNumber: true,
                documentResults: [
                  { id: 26, label: 'E - Excellent' },
                  { id: 27, label: 'G - Good' },
                  { id: 28, label: 'C - Complies' },
                ],
                documentScopes: [
                  {
                    id: 48,
                    label: '1. Production, Capture and Harvesting of Livestock and Game Animals',
                  },
                  { id: 49, label: '2. Growing and Harvesting of Animal Feeds' },
                  {
                    id: 50,
                    label:
                      '3. Growing and Production of Fresh Produce (module 7H Not GFSI recogn.)',
                  },
                  { id: 51, label: '4. Fresh Produce Packhouse Operations' },
                  {
                    id: 52,
                    label:
                      '5. Extensive Broad Acre Agriculture Operations and Seed Production (Not GFSI recogn.)',
                  },
                  { id: 53, label: '6. Harvest and Intensive Farming of Fish (Not GFSI recogn.)' },
                  { id: 54, label: '7. Slaughterhouse, Boning and Butchery Operations' },
                  { id: 55, label: '8. Processing of Manufactured Meats and Poultry' },
                  { id: 56, label: '9. Seafood Processing' },
                  { id: 57, label: '10. Dairy Food Processing' },
                  { id: 58, label: '11. Honey Processing' },
                  { id: 59, label: '12. Egg Processing' },
                  { id: 60, label: '13. Bakery and Snack Food Processing' },
                  { id: 61, label: '14. Fruit and Vegetable Processing' },
                  {
                    id: 62,
                    label: '15. Canning (except dairy), Pasteurizing, UHT and Aseptic Operations',
                  },
                  { id: 63, label: '16. Ice, Drink and Beverage Processing' },
                  { id: 64, label: '17. Confectionary Manufacturing' },
                  { id: 65, label: '18. Preserved Foods Manufacture' },
                  { id: 66, label: '19. Food Ingredient Manufacture' },
                  { id: 67, label: '20. Recipe Meals Manufacture' },
                  {
                    id: 68,
                    label: '21. Oils, Fats, and the Manufacture of oil or fat-based spreads',
                  },
                  { id: 69, label: '22. Processing of Cereal Grains and Nuts' },
                  {
                    id: 70,
                    label: '23. Food Catering and Food Service Operations (Not yet available)',
                  },
                  { id: 71, label: '24. Food Retailing (Not yet available)' },
                  {
                    id: 72,
                    label: '25. Fresh Produce Wholesaling and Distribution (Not GFSI recogn.)',
                  },
                  { id: 73, label: '26. Food Wholesaling and Distribution (Not GFSI recogn.)' },
                  { id: 74, label: '27. Manufacture of Food Sector Packaging Materials' },
                  { id: 75, label: '28. Provision of Crop Spray Services (Not yet available)' },
                  { id: 76, label: '29. Provision of Field Harvest Services (Not yet available)' },
                  {
                    id: 77,
                    label: '30. Provision of Sanitation and Hygiene Services (Not yet available)',
                  },
                  { id: 78, label: '31. Manufacture of Dietary Supplements' },
                  { id: 79, label: '32. Manufacture of Pet Food' },
                  {
                    id: 80,
                    label: '33. Manufacture of Agricultural Chemicals and Food Processing Aides',
                  },
                  { id: 81, label: '34. Manufacture of Animal Feeds' },
                  { id: 82, label: '35. Broker or Agent (Not GFSI recogn.)' },
                ],
              },
              {
                id: 9,
                label: 'SGF',
                hasNumber: true,
                documentResults: [
                  { id: 29, label: 'Compliant' },
                  { id: 30, label: 'Non Compliant' },
                ],
                documentScopes: [],
              },
            ],
          },
          {
            id: 1,
            label: 'Social Compliance XYZ',
            documentTypes: [
              {
                id: 1,
                label: 'Amfori Business Social Compliance Initiative',
                hasNumber: true,
                documentResults: [
                  { id: 1, label: 'A' },
                  { id: 2, label: 'B' },
                  { id: 3, label: 'C' },
                  { id: 4, label: 'D' },
                  { id: 5, label: 'E' },
                ],
                documentScopes: [{ id: 1, label: 'Agricultural' }, { id: 2, label: 'Industrial' }],
              },
              {
                id: 2,
                label: 'Rainforest Alliance (RFA)',
                hasNumber: true,
                documentResults: [{ id: 6, label: 'Compliant' }, { id: 7, label: 'Non Compliant' }],
                documentScopes: [],
              },
              {
                id: 3,
                label: 'Sedex Members Ethical Trade Audit (ETI SMETA)',
                hasNumber: true,
                documentResults: [{ id: 8, label: 'Compliant' }, { id: 9, label: 'Non Compliant' }],
                documentScopes: [],
              },
              {
                id: 4,
                label: 'Fundacao Abrinq',
                hasNumber: true,
                documentResults: [
                  { id: 10, label: 'Compliant' },
                  { id: 11, label: 'Non Compliant' },
                ],
                documentScopes: [],
              },
            ],
          },
        ],
        orgs: [
          {
            id: 1,
            name: 'LDCBR',
            fullname: 'Ldcbr company',
            is_approver: false,
            level: 10,
            logo: null,
            role: 'LDCBR',
          },
          {
            id: 2,
            name: 'JDE',
            fullname: 'Jde company',
            is_approver: false,
            level: 20,
            logo: null,
            role: 'JDE',
          },
          {
            id: 3,
            name: 'REFRESCO',
            fullname: 'Refresco company',
            is_approver: false,
            level: 30,
            logo: null,
            role: 'REFRESCO',
          },
          {
            id: 4,
            name: 'AH',
            fullname: 'Ah company',
            is_approver: false,
            level: 40,
            logo: null,
            role: 'AH',
          },
          {
            id: 5,
            name: 'SIM',
            fullname: 'Sim company',
            is_approver: true,
            level: 50,
            logo: null,
            role: 'SIM',
          },
        ],
        participants: {
          preferredBatchByOrgRole: {
            SIM: 'REFRESCOBatch',
            REFRESCO: 'REFRESCOBatch',
            JDE: 'JDEBatch',
            LDCBR: 'LDCBRBatch',
            AH: 'AHBatch',
          },
        },
        batches: {
          LDCBRBatch: {
            contract: 'jdeReference',
            superType: 'JDEBatch',
            subType: '',
            subcontract: 'farmName',
            endpoint: 'oranges/ldcbrbatch',
            approvable: false,
          },
          JDEBatch: {
            contract: 'releaseNumber',
            superType: 'REFRESCOBatch',
            subType: 'LDCBRBatch',
            subcontract: 'batchesLevelOne',
            endpoint: 'oranges/jdebatch',
            approvable: false,
          },
          REFRESCOBatch: {
            contract: 'lotFinishedProduct',
            superType: 'AHBatch',
            subType: 'JDEBatch',
            subcontract: 'lotFcoj',
            endpoint: 'oranges/refrescobatch',
            approvable: true,
          },
          AHBatch: {
            contract: 'gtin',
            superType: '',
            subType: 'REFRESCOBatch',
            subcontract: 'batchnumber',
            endpoint: 'oranges/ahbatch',
            approvable: false,
          },
        },
        contacts: {
          address: 'Toermalijnstraat 18b 1812 RL Alkmaar The Netherlands',
          phones: ['+31 (0) 725410390'],
          emails: ['info@simsupplychain.com'],
          sites: ['https://simsupplychain.com'],
        },
        eventTypes: [
          { id: 1, title: 'Batch upload', slug: 'BatchUpload' },
          { id: 2, title: 'Batch archive', slug: 'BatchArchive' },
          { id: 3, title: 'Batch approval status update', slug: 'BatchStatusChange' },
          { id: 4, title: 'Certificate upload', slug: 'CertificateUpload' },
          { id: 5, title: 'Certificate archive', slug: 'CertificateArchive' },
          { id: 6, title: 'Certificate approval status change', slug: 'CertificateStatusChange' },
          { id: 7, title: 'Request created', slug: 'DemandCreate' },
          { id: 8, title: 'Request status change', slug: 'DemandStatusChange' },
          { id: 9, title: 'Contract archive', slug: 'ContractArchive' },
        ],
      };
    default:
      return [500, `No such organization ${orgName}`];
  }
};

const eggsServiceData = (orgName) => {
  switch (orgName) {
    case ORGANIZATIONS.EGG1:
    case ORGANIZATIONS.EGG2:
    case ORGANIZATIONS.AH:
    case ORGANIZATIONS.SIM:
      return {
        documentCategories: [],
        orgs: [
          {
            id: 4,
            name: 'AH',
            fullname: 'Ah company',
            is_approver: false,
            level: 40,
            logo: null,
            role: 'AH',
          },
          {
            id: 5,
            name: 'SIM',
            fullname: 'Sim company',
            is_approver: true,
            level: 50,
            logo: null,
            role: 'SIM',
          },
          {
            id: 6,
            name: 'EGG1',
            fullname: 'Egg Packer 1',
            is_approver: false,
            level: 31,
            logo: null,
            role: 'EGG',
          },
          {
            id: 7,
            name: 'EGG2',
            fullname: 'Egg Packer 2',
            is_approver: false,
            level: 31,
            logo: null,
            role: 'EGG',
          },
        ],
        participants: {
          preferredBatchByOrgRole: { EGG: 'EGGBatch', AH: 'AHBatch', SIM: 'EGGBatch' },
        },
        batches: {
          EGGBatch: {
            contract: 'uploadId',
            superType: 'AHBatch',
            subType: '',
            subcontract: '',
            endpoint: 'eggs/eggbatch',
            approvable: true,
            filters: { dateFormat: 'YYYY-MM-DD' },
          },
          AHBatch: {
            contract: 'gtin',
            superType: '',
            subType: 'EGGBatch',
            subcontract: 'batchnumber',
            endpoint: 'eggs/ahbatch',
            approvable: false,
            batchnumberFormat: 'YYYY-MM-DD',
            matchers: { fieldMapping: { batchnumber: 'thtDateFrom', glnSupplier: 'packerGln' } },
          },
        },
        contacts: {
          address: 'Toermalijnstraat 18b 1812 RL Alkmaar The Netherlands',
          phones: ['+31 (0) 725410390'],
          emails: ['info@simsupplychain.com'],
          sites: ['https://simsupplychain.com'],
        },
        eventTypes: [
          { id: 1, title: 'Batch upload', slug: 'BatchUpload' },
          { id: 2, title: 'Batch archive', slug: 'BatchArchive' },
          { id: 3, title: 'Batch approval status update', slug: 'BatchStatusChange' },
          { id: 4, title: 'Certificate upload', slug: 'CertificateUpload' },
          { id: 5, title: 'Certificate archive', slug: 'CertificateArchive' },
          { id: 6, title: 'Certificate approval status change', slug: 'CertificateStatusChange' },
          { id: 7, title: 'Request created', slug: 'DemandCreate' },
          { id: 8, title: 'Request status change', slug: 'DemandStatusChange' },
          { id: 9, title: 'Contract archive', slug: 'ContractArchive' },
        ],
      };

    default:
      return [500, `No such organization ${orgName}`];
  }
};

const juiceServiceData = (orgName) => {
  switch (orgName) {
    case ORGANIZATIONS.SIM:
    case ORGANIZATIONS.CITROSUCOGHENT:
    case ORGANIZATIONS.CITROSUCOBR:
    case ORGANIZATIONS.REFRESCOGMBH:
    case ORGANIZATIONS.KAUFLANDDE:
    case ORGANIZATIONS.REFRESCO:
    case ORGANIZATIONS.AH:
      return {
        documentCategories: [
          {
            id: 2,
            label: 'Product/Food Safety',
            documentTypes: [
              {
                id: 5,
                label: 'BRC Food',
                hasNumber: true,
                documentResults: [
                  { id: 12, label: 'A' },
                  { id: 13, label: 'AA' },
                  { id: 14, label: 'AA+' },
                  { id: 15, label: 'A+' },
                  { id: 16, label: 'B' },
                  { id: 17, label: 'B+' },
                  { id: 18, label: 'C' },
                  { id: 19, label: 'C+' },
                  { id: 20, label: 'D' },
                  { id: 21, label: 'D+' },
                ],
                documentScopes: [
                  { id: 3, label: '01. Raw red meat' },
                  { id: 4, label: '02. Raw poultry' },
                  { id: 5, label: '03. - Raw prepared products (meat and vegetarian)' },
                  { id: 6, label: '04. Raw Fish products and preparation' },
                  { id: 7, label: '05. Fruits, vegetables and nuts' },
                  { id: 8, label: '06. Prepared fruit, vegetables and nuts' },
                  { id: 9, label: '07. Dairy, liquid egg' },
                  { id: 10, label: '08. Cooked meat/fish products' },
                  { id: 11, label: '09. Raw cured/or fermented meat and fish' },
                  { id: 12, label: '10. Ready meals and sandwiches; ready to eat desserts' },
                  { id: 13, label: '11. Low/high acid in cans/glass' },
                  { id: 14, label: '12. Beverages' },
                  { id: 15, label: '13. Alcoholic drinks and fermented/brewed products' },
                  { id: 16, label: '14. Bakery' },
                  { id: 17, label: '15. Dried foods and ingredients' },
                  { id: 18, label: '16. Confectionery' },
                  { id: 19, label: '17. Cereals and snacks' },
                  { id: 20, label: '18. Oils and fats' },
                ],
              },
              {
                id: 6,
                label: 'Food Safety System Certification 22000',
                hasNumber: true,
                documentResults: [
                  { id: 22, label: 'Compliant' },
                  { id: 23, label: 'Non Compliant' },
                ],
                documentScopes: [
                  { id: 21, label: 'AI. Farming of animals for meat/milk/eggs/honey' },
                  { id: 22, label: 'AII. Farming of fish and seafood' },
                  { id: 23, label: 'CI. Processing of perishable animal products' },
                  { id: 24, label: 'CII. Processing of perishable plant products' },
                  {
                    id: 25,
                    label:
                      'CIII. Processing of perishable animal and plant producuts (mixed products)',
                  },
                  { id: 26, label: 'CIV. Processing of ambient stable products' },
                  { id: 27, label: 'DI. Production of feed' },
                  { id: 28, label: 'DII. Production of pet food (for dogs and cats only)' },
                  { id: 29, label: 'E. Catering' },
                  { id: 30, label: 'F. Distribution' },
                  { id: 31, label: 'FI. Retail/wholesale' },
                  { id: 32, label: 'G. Provision of transport and storage services' },
                  {
                    id: 33,
                    label:
                      'GI. Provision of transport and storage services for perishable food & feed',
                  },
                  {
                    id: 34,
                    label:
                      'GII. Provision of transport and storage services for ambient stable food & feed',
                  },
                  {
                    id: 35,
                    label: 'I. Production of food and feed packaging and packaging material',
                  },
                  {
                    id: 36,
                    label:
                      'K. Production of (bio)chemicals (excluding pesticides, drugs, fertilizers and cleaning products)',
                  },
                ],
              },
              {
                id: 7,
                label: 'IFS Food',
                hasNumber: true,
                documentResults: [
                  { id: 24, label: 'Higher Level' },
                  { id: 25, label: 'Foundation Level' },
                ],
                documentScopes: [
                  { id: 37, label: '1. Red and white meat, poultry and meat products' },
                  { id: 38, label: '2. Fish and fish products' },
                  { id: 39, label: '3. Egg and egg products' },
                  { id: 40, label: '4. Dairy products' },
                  { id: 41, label: '5. Fruits and vegetables' },
                  {
                    id: 42,
                    label:
                      '6. Grain products, cereals, industrial bakery and pastry, confectionary, snacks',
                  },
                  { id: 43, label: '7. Combined products' },
                  { id: 44, label: '8. Beverages' },
                  { id: 45, label: '9. Oils and fats' },
                  { id: 46, label: '10. Dry goods, other ingredients and supplements' },
                  { id: 47, label: '11. Pet food' },
                ],
              },
              {
                id: 8,
                label: 'Safe Quality Food (SQF)',
                hasNumber: true,
                documentResults: [
                  { id: 26, label: 'E - Excellent' },
                  { id: 27, label: 'G - Good' },
                  { id: 28, label: 'C - Complies' },
                ],
                documentScopes: [
                  {
                    id: 48,
                    label: '1. Production, Capture and Harvesting of Livestock and Game Animals',
                  },
                  { id: 49, label: '2. Growing and Harvesting of Animal Feeds' },
                  {
                    id: 50,
                    label:
                      '3. Growing and Production of Fresh Produce (module 7H Not GFSI recogn.)',
                  },
                  { id: 51, label: '4. Fresh Produce Packhouse Operations' },
                  {
                    id: 52,
                    label:
                      '5. Extensive Broad Acre Agriculture Operations and Seed Production (Not GFSI recogn.)',
                  },
                  { id: 53, label: '6. Harvest and Intensive Farming of Fish (Not GFSI recogn.)' },
                  { id: 54, label: '7. Slaughterhouse, Boning and Butchery Operations' },
                  { id: 55, label: '8. Processing of Manufactured Meats and Poultry' },
                  { id: 56, label: '9. Seafood Processing' },
                  { id: 57, label: '10. Dairy Food Processing' },
                  { id: 58, label: '11. Honey Processing' },
                  { id: 59, label: '12. Egg Processing' },
                  { id: 60, label: '13. Bakery and Snack Food Processing' },
                  { id: 61, label: '14. Fruit and Vegetable Processing' },
                  {
                    id: 62,
                    label: '15. Canning (except dairy), Pasteurizing, UHT and Aseptic Operations',
                  },
                  { id: 63, label: '16. Ice, Drink and Beverage Processing' },
                  { id: 64, label: '17. Confectionary Manufacturing' },
                  { id: 65, label: '18. Preserved Foods Manufacture' },
                  { id: 66, label: '19. Food Ingredient Manufacture' },
                  { id: 67, label: '20. Recipe Meals Manufacture' },
                  {
                    id: 68,
                    label: '21. Oils, Fats, and the Manufacture of oil or fat-based spreads',
                  },
                  { id: 69, label: '22. Processing of Cereal Grains and Nuts' },
                  {
                    id: 70,
                    label: '23. Food Catering and Food Service Operations (Not yet available)',
                  },
                  { id: 71, label: '24. Food Retailing (Not yet available)' },
                  {
                    id: 72,
                    label: '25. Fresh Produce Wholesaling and Distribution (Not GFSI recogn.)',
                  },
                  { id: 73, label: '26. Food Wholesaling and Distribution (Not GFSI recogn.)' },
                  { id: 74, label: '27. Manufacture of Food Sector Packaging Materials' },
                  { id: 75, label: '28. Provision of Crop Spray Services (Not yet available)' },
                  { id: 76, label: '29. Provision of Field Harvest Services (Not yet available)' },
                  {
                    id: 77,
                    label: '30. Provision of Sanitation and Hygiene Services (Not yet available)',
                  },
                  { id: 78, label: '31. Manufacture of Dietary Supplements' },
                  { id: 79, label: '32. Manufacture of Pet Food' },
                  {
                    id: 80,
                    label: '33. Manufacture of Agricultural Chemicals and Food Processing Aides',
                  },
                  { id: 81, label: '34. Manufacture of Animal Feeds' },
                  { id: 82, label: '35. Broker or Agent (Not GFSI recogn.)' },
                ],
              },
              {
                id: 9,
                label: 'SGF',
                hasNumber: true,
                documentResults: [
                  { id: 29, label: 'Compliant' },
                  { id: 30, label: 'Non Compliant' },
                ],
                documentScopes: [],
              },
            ],
          },
          {
            id: 1,
            label: 'Social Compliance XYZ',
            documentTypes: [
              {
                id: 1,
                label: 'Amfori Business Social Compliance Initiative',
                hasNumber: true,
                documentResults: [
                  { id: 1, label: 'A' },
                  { id: 2, label: 'B' },
                  { id: 3, label: 'C' },
                  { id: 4, label: 'D' },
                  { id: 5, label: 'E' },
                ],
                documentScopes: [{ id: 1, label: 'Agricultural' }, { id: 2, label: 'Industrial' }],
              },
              {
                id: 2,
                label: 'Rainforest Alliance (RFA)',
                hasNumber: true,
                documentResults: [{ id: 6, label: 'Compliant' }, { id: 7, label: 'Non Compliant' }],
                documentScopes: [],
              },
              {
                id: 3,
                label: 'Sedex Members Ethical Trade Audit (ETI SMETA)',
                hasNumber: true,
                documentResults: [{ id: 8, label: 'Compliant' }, { id: 9, label: 'Non Compliant' }],
                documentScopes: [],
              },
              {
                id: 4,
                label: 'Fundacao Abrinq',
                hasNumber: true,
                documentResults: [
                  { id: 10, label: 'Compliant' },
                  { id: 11, label: 'Non Compliant' },
                ],
                documentScopes: [],
              },
            ],
          },
        ],
        orgs: [
          {
            id: 114,
            name: 'CitrosucoGhent',
            fullname: 'Citrosuco Ghent',
            is_approver: false,
            level: 20,
            logo: null,
            role: 'IMPORTER',
          },
          {
            id: 5,
            name: 'SIM',
            fullname: 'Sim company',
            is_approver: true,
            level: 50,
            logo: null,
            role: 'AUDITOR',
          },
          {
            id: 113,
            name: 'CitrosucoBrazil',
            fullname: 'Citrosuco Brazil',
            is_approver: false,
            level: 10,
            logo: null,
            role: 'PRODUCER',
          },
          {
            id: 3,
            name: 'REFRESCO',
            fullname: 'Refresco company',
            is_approver: false,
            level: 30,
            logo: null,
            role: 'PROCESSOR',
          },
          {
            id: 4,
            name: 'AH',
            fullname: 'Ah company',
            is_approver: false,
            level: 40,
            logo: null,
            role: 'RETAILER',
          },
          {
            id: 116,
            name: 'RefrescoGbmH',
            fullname: 'Refresco GmbH',
            is_approver: false,
            level: 30,
            logo: null,
            role: 'PROCESSOR',
          },
          {
            id: 117,
            name: 'KauflandDE',
            fullname: 'Kaufland DE',
            is_approver: false,
            level: 40,
            logo: null,
            role: 'RETAILER',
          },
        ],
        participants: {
          preferredBatchByOrgRole: {
            PRODUCER: 'ProducerBatch',
            IMPORTER: 'ImporterBatch',
            PROCESSOR: 'ProcessorBatch',
            RETAILER: 'RetailerBatch',
            AUDITOR: 'RetailerBatch',
          },
        },
        batches: {
          ProducerBatch: {
            contract: 'vesselTkBatchId',
            superType: 'ImporterBatch',
            subType: '',
            subcontract: '',
            endpoint: 'juice/producerbatch',
            approvable: null,
          },
          ImporterBatch: {
            contract: 'salesOrderNumber',
            superType: 'ProcessorBatch',
            subType: 'ProducerBatch',
            subcontract: '',
            endpoint: 'juice/importerbatch',
            approvable: null,
          },
          ProcessorBatch: {
            contract: 'lotFinishedProduct',
            superType: 'RetailerBatch',
            subType: 'ImporterBatch',
            subcontract: '',
            endpoint: 'juice/processorbatch',
            approvable: null,
          },
          RetailerBatch: {
            contract: 'gtin',
            superType: '',
            subType: 'ProcessorBatch',
            subcontract: 'batchnumber',
            endpoint: 'oranges/ahbatch',
            approvable: null,
            originalName: 'AHBatch',
          },
        },
        contacts: {
          address: 'Toermalijnstraat 18b 1812 RL Alkmaar The Netherlands',
          phones: ['+31 (0) 725410390'],
          emails: ['info@simsupplychain.com'],
          sites: ['https://simsupplychain.com'],
        },
        eventTypes: [
          { id: 1, title: 'Batch upload', slug: 'BatchUpload' },
          { id: 2, title: 'Batch archive', slug: 'BatchArchive' },
          { id: 3, title: 'Batch approval status update', slug: 'BatchStatusChange' },
          { id: 4, title: 'Certificate upload', slug: 'CertificateUpload' },
          { id: 5, title: 'Certificate archive', slug: 'CertificateArchive' },
          { id: 6, title: 'Certificate approval status change', slug: 'CertificateStatusChange' },
          { id: 7, title: 'Request created', slug: 'DemandCreate' },
          { id: 8, title: 'Request status change', slug: 'DemandStatusChange' },
        ],
      };

    default:
      return [500, `No such organization ${orgName}`];
  }
};

export default (channel, orgName) => {
  switch (channel) {
    case CHANNELS.TILAPIA: {
      return tilapiaServiceData(orgName);
    }

    case CHANNELS.BANANAS: {
      return bananasServiceData(orgName);
    }

    case CHANNELS.ORANGES: {
      return orangesServiceData(orgName);
    }

    case CHANNELS.EGGS: {
      return eggsServiceData(orgName);
    }

    case CHANNELS.JUICE: {
      return juiceServiceData(orgName);
    }

    default: {
      return [500, `No such channel ${channel}`];
    }
  }
};
