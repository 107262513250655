import React from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
// hoc
import injectSheet, { WithStyles } from 'react-jss';
// components
import { LogoutSvg, SupportSvg, ProfileSvg } from 'components/icons';
// redux
import * as EnvActions from 'domain/env/actions';
import { getUser } from 'domain/env';
// utils
import { getUrl } from 'routes';
// styles
import { Theme } from 'lib/lib';
import { AppStateType } from 'types';

const styles = (theme: Theme) => ({
  dropDownMenu: {
    position: 'absolute',
    top: -5,
    left: 'auto',
    right: 0,
    overflow: 'hidden',
    display: 'flex',
    flexGrow: 0,
    height: 0,
    transition: 'flexGrow 1s ease-out',
    paddingLeft: '1.5em',
    opacity: 0.9,
    '&.isOpen': {
      flexGrow: 1,
      height: 'auto',
      border: [2, 'solid', theme.colors.white],
    },
    background: {
      image: `linear-gradient(${theme.colors.primaryHover} 2%, ${theme.colors.primary} 102%)`,
      size: 'cover',
    },
    boxShadow: 'rgba(0, 0, 0, 0.2) 0px 1px 2px 0px',
    '& ul': {
      margin: ['0.75em', 0],
      '& .item': {
        '& a, & div': {
          display: 'flex',
          justifyContent: 'flex-end',
          cursor: 'pointer',
          '&:hover': {
            textDecoration: 'none',
          },
          padding: ['0.5em', '1.5em'],

          '& span': {
            color: theme.colors.white,
            marginRight: '1em',
          },
          '& svg': {
            fill: theme.colors.white,
            width: 20,
            height: 20,
          },
        },
      },
    },
  },
});

interface OwnProps extends WithStyles<typeof styles> {
  isOpen: boolean;
}

const mapStateToProps = (state: AppStateType) => ({
  user: getUser(state),
});

const UserDropdown: React.FC<OwnProps> = (props) => {
  const dispatch = useDispatch();
  const { user } = useSelector(mapStateToProps, shallowEqual);
  const { classes, isOpen } = props;

  const onLogout = () => dispatch(EnvActions.logOutAction());

  return (
    <div className={`${classes.dropDownMenu} ${isOpen ? 'isOpen' : ''}`}>
      <ul>
        <li className="item">
          <Link to={{ pathname: getUrl('Profile') }}>
            <span>Profile</span>
            <ProfileSvg />
          </Link>
        </li>
        {!!user.is_admin && (
          <li className="item">
            <Link to={{ pathname: getUrl('AdminUsers') }}>
              <span>Admin Panel</span>
              <ProfileSvg />
            </Link>
          </li>
        )}
        <li className="item">
          <Link to={{ pathname: getUrl('Support') }}>
            <span>Support</span>
            <SupportSvg />
          </Link>
        </li>
        <li className="item">
          <div onClick={onLogout}>
            <span>Logout</span>
            <LogoutSvg />
          </div>
        </li>
      </ul>
    </div>
  );
};

export default injectSheet(styles)(UserDropdown);
