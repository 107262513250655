import dashboardTabModel from 'models/sim/bananas/tabs/dashboardTabModel';
import settingsAndActions from 'models/sim/bananas/settingsAndActions/dashboard';
import { ORGANIZATIONS } from 'themes/constants';
import { ModelRequiredInfo } from 'lib/lib';

export default (modelRequiredInfo: ModelRequiredInfo) => ({
  tabs: [
    {
      modelName: 'defaultTab',
      originalName: ORGANIZATIONS.SIM,
      title: ORGANIZATIONS.SIM,
    },
  ],
  defaultTab: dashboardTabModel(modelRequiredInfo),
  settingsAndActions,
});
