import { Theme } from 'lib/lib';

export default (theme: Theme) => ({
  wrapper: {
    padding: ['2em', 0],

    '& div[data-cy="tableHeader"]': {
      borderRadius: 0,
    },
  },
  tableHeader: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  titleSection: {
    backgroundColor: theme.colors.primary,
    borderRadius: [16, 16, 0, 0],
    padding: [10, 8],
    display: 'flex',
    alignItems: 'center',
  },
  title: {
    fontSize: 18,
    lineHeight: '22px',
    color: theme.colors.white,
  },
  approveButton: {
    backgroundColor: '#7FBA42',
    borderRadius: 8,
    color: theme.colors.white,
    padding: [8, 54],
    margin: [0, 16, 0, 62],
    outline: 0,
    border: 0,
    fontFamily: "'Lato', sans-serif",
    fontWeight: 'bold',
    cursor: 'pointer',
  },
  backToListLink: {
    backgroundColor: '#B00020',
    borderRadius: 8,
    color: theme.colors.white,
    fontWeight: 'bold',
    alignSelf: 'center',
    padding: [8, 45],
    marginLeft: 'auto',
  },
  tabs: {
    display: 'flex',

    '& button:last-child': {
      borderTopRightRadius: 24,
    },
  },
  tab: {
    backgroundColor: theme.colors.primary,
    padding: [21, 58],
    color: theme.colors.white,
    cursor: 'pointer',
    lineHeight: '17px',
    outline: 0,
    border: 0,
    fontFamily: "'Lato', sans-serif",
    flex: 1,
  },
  tabActive: {
    backgroundColor: '#333333',
  },
  exportToExcel: {
    border: 0,
    outline: 0,
    backgroundColor: 'transparent',
    cursor: 'pointer',
    padding: [8, 18],
    alignSelf: 'center',
    marginLeft: 22,
    display: 'flex',
    alignItems: 'center',

    '& svg': {
      marginRight: 8,
    },
  },
});
