import React from 'react';
import { Link } from 'react-router-dom';
import { getUrl } from 'routes';
// import baseModelFields from 'models/baseModelFields';
import { renderShortDateFormat, renderTwoShortDateFormat } from 'lib/renderHelpers';
import { CHANNELS, ORGANIZATIONS } from 'themes/constants';
import { BatchRecord, ModelRequiredInfo } from 'lib/lib';
import { getCreatorName } from '../../utils/utils';

export default (modelRequiredInfo: ModelRequiredInfo) => [
  {
    key: 'productBatchIdBulk',
    label: 'productBatchIdBulk',
    title: 'Product Batch Id Bulk',
    style: { flex: '1 0 100px' },
    defaultSelectedColumn: true,
    renderExportValue: (column: BatchRecord) => column['product Batch Id Bulk'],
    render: (column: BatchRecord) => (
      <Link
        to={{
          pathname: getUrl('BatchContractType', {
            type: ORGANIZATIONS.SEAFOODCONNECTION.toLowerCase(),
            contract_number: encodeURIComponent(`${column.productBatchIdBulk}`),
            modelType: 'contractDetails',
            channelName: CHANNELS.TILAPIA,
          }),
        }}
      >
        {column.productBatchIdBulk}
      </Link>
    ),
  },
  {
    key: 'dateReceipt',
    label: 'dateReceipt',
    title: 'Date Receipt',
    style: { flex: '1 0 100px' },
    defaultSelectedColumn: true,
    renderExportValue: (column: BatchRecord) =>
      `${renderTwoShortDateFormat(column.dateReceiptMin, column.dateReceiptMax)}`,
    render: (column: BatchRecord) =>
      `${renderTwoShortDateFormat(column.dateReceiptMin, column.dateReceiptMax)}`,
  },
  {
    key: 'deliveryDate',
    label: 'deliveryDate',
    title: 'Delivery Date',
    style: { flex: '1 0 100px' },
    defaultSelectedColumn: true,
    renderExportValue: (column: BatchRecord) =>
      `${renderTwoShortDateFormat(column.deliveryDateMin, column.deliveryDateMax)}`,
    render: (column: BatchRecord) =>
      `${renderTwoShortDateFormat(column.deliveryDateMin, column.deliveryDateMax)}`,
  },
  {
    key: 'createdAtMin',
    label: 'Created At',
    title: 'Created At',
    style: { flex: '1 0 80px' },
    isSortable: true,
    defaultSelectedColumn: true,
    renderExportValue: (column: BatchRecord) => column.createdAtMin,
    render: (column: BatchRecord) => renderShortDateFormat(column.createdAtMin),
  },
  {
    key: 'createdAtMax',
    label: 'Last Updated',
    title: 'Last Updated',
    style: { flex: '1 0 80px' },
    isSortable: true,
    defaultSelectedColumn: true,
    renderExportValue: (column: BatchRecord) => column.createdAtMax,
    render: (column: BatchRecord) => renderShortDateFormat(column.createdAtMax),
  },
  {
    key: 'createdBy',
    label: 'Created By',
    title: 'Created By',
    style: { flex: '1 0 50px' },
    defaultSelectedColumn: true,
    renderExportValue: (record: BatchRecord) => getCreatorName(record),
    render: (record: BatchRecord) => getCreatorName(record),
    validate: [],
  },
];
