import authBg from 'assets/auth-bg3.jpg';
import { Theme } from 'lib/lib';

export default (theme: Theme) => ({
  root: {
    display: 'flex',
    background: {
      image: `url("${authBg}")`,
      position: ['center', 'center'],
      size: 'cover',
    },
    boxSizing: 'border-box',
    minWidth: '100vw',
    minHeight: '100vh',
    position: 'relative',
    padding: 0,
  },
  inner: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: 0,
    minWidth: '100vw',
    minHeight: '100vh',
    mixBlendMode: 'normal',
    background: {
      // image: 'radial-gradient(at center center, rgba(0, 0, 0, 0.5) 3%, rgb(0, 0, 0) 103%)',
      position: 'center center',
      size: 'auto',
      repeat: 'no-repeat',
      color: 'transparent',
    },
  },
  content: {
    boxSizing: 'border-box',
    borderRadius: 30,
    width: '100%',
  },

  logo: {
    textAlign: 'center',
    marginBottom: '4em',
    '& img': {
      maxWidth: 160,
      width: '100%',
    },
    '& p': {
      color: theme.colors.white,
      font: {
        family: 'inherit',
        size: 14,
        weight: 300,
      },
    },
    '& h1': {
      color: theme.colors.white,
      font: {
        family: 'inherit',
        size: 24,
        weight: 300,
      },
    },
  },
  otherSignIns: {
    textAlign: 'center',
    marginTop: '5em',
    '&>div': {
      marginTop: '0.5em',
      '& span': {
        color: theme.colors.white,
      },
      '& a': {
        color: theme.colors.white,
        fontWeight: 500,
        marginLeft: '0.5em',
      },
    },
  },

  footer: {
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    bottom: 20,
    left: 0,
    color: theme.colors.white,
    width: '100%',
    fontSize: theme.fontSizes.md,
    '& span': {
      display: 'flex',
      alignItems: 'center',
    },
    '& .footerLogo': {
      width: 150,
      height: 30,
      marginLeft: 30,
    },
  },
  '@global': {
    html: {
      minWidth: '100%',
    },
    body: {
      minWidth: '100%',
    },
  },
});
