import {
  validationCustomDate,
  validationInt,
  validationRequired,
  validationString,
} from 'lib/uploadFieldValidations';
import { customDatePreview, formatDateToISO, renderShortDateFormat } from 'lib/renderHelpers';
import { ORGANIZATIONS } from 'themes/constants';
import { UPLOAD_TIME_FORMAT } from 'themes/main';
import { BatchRecord, ModelRequiredInfo } from 'lib/lib';

export default (modelRequiredInfo: ModelRequiredInfo) => {
  const { BANAFOOD } = ORGANIZATIONS;
  const { outBatches: OUT_FORMAT } = UPLOAD_TIME_FORMAT[BANAFOOD] as Record<string, string>;
  const validationBanafoodOutDate = validationCustomDate(OUT_FORMAT);
  return [
    {
      key: 'banafoodSSCCPallet',
      label: 'Banafood SSCC pallet #',
      title: 'Banafood SSCC pallet #',
      style: { flex: '1 0 60px' },
      renderUploadPreview: (column: BatchRecord) => column.banafoodSSCCPallet,
      render: (column: BatchRecord) => column.banafoodSSCCPallet,
      validate: [validationRequired, validationString],
      defaultSelectedColumn: true,
      defaultSelectedBatchContractColumn: true,
    },
    {
      key: 'deliveryDate',
      label: 'Date delivery',
      title: 'Date delivery',
      style: { flex: '1 0 90px' },
      handleParsedValue: (value: string) => formatDateToISO(value, OUT_FORMAT),
      renderUploadPreview: ({ deliveryDate }: BatchRecord) =>
        customDatePreview(deliveryDate, [validationRequired, validationBanafoodOutDate]),
      render: (column: BatchRecord) => renderShortDateFormat(column.deliveryDate),
      validate: [validationRequired, validationBanafoodOutDate],
      defaultSelectedColumn: true,
      defaultSelectedBatchContractColumn: true,
      edit: { type: 'date' },
    },
    {
      key: 'boxes',
      label: '# boxes',
      title: '# boxes',
      style: { flex: '1 0 50px' },
      renderUploadPreview: (column: BatchRecord) => column.boxes,
      render: (column: BatchRecord) => column.boxes,
      validate: [validationInt],
      defaultSelectedColumn: true,
      defaultSelectedBatchContractColumn: true,
    },
    {
      key: 'product',
      label: 'Product #',
      title: 'Product #',
      style: { flex: '1 0 50px' },
      renderUploadPreview: (column: BatchRecord) => column.product,
      render: (column: BatchRecord) => column.product,
      validate: [validationString],
      defaultSelectedColumn: true,
      defaultSelectedBatchContractColumn: true,
    },

    {
      key: 'productDescription',
      label: 'Product description',
      title: 'Product description',
      style: { flex: '1 0 100px' },
      renderUploadPreview: (column: BatchRecord) => column.productDescription,
      render: (column: BatchRecord) => column.productDescription,
      validate: [validationString],
      defaultSelectedColumn: true,
      defaultSelectedBatchContractColumn: true,
    },
    {
      key: 'antwerpSSCC',
      label: 'Antwerp SSCC Fyffes OUT',
      title: 'Antwerp SSCC Fyffes OUT',
      style: { flex: '1 0 60px' },
      renderUploadPreview: (column: BatchRecord) => column.antwerpSSCC,
      render: (column: BatchRecord) => column.antwerpSSCC,
      validate: [validationString],
      defaultSelectedColumn: true,
      defaultSelectedBatchContractColumn: true,
    },
  ];
};
