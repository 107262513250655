import React from 'react';
//
import { Link } from 'react-router-dom';

//
import Certificates from 'pages/documents/certificates';
import Certificate from 'pages/documents/certificate';
import CertificateAdd from 'pages/documents/certificateAdd';
import InboxDetails from 'pages/requests/inboxDetails';
import InboxAnswer from 'pages/requests/inboxAnswer';
import OutboxDetails from 'pages/requests/outboxDetails';
import Inbox from 'pages/requests/inbox';
import Outbox from 'pages/requests/outbox';
import RequestsAdd from 'pages/requests/requestsAdd';

import Tmp from 'pages/tmp/tmp';

import Dashboard from 'pages/dashboard/Dashboard';

import Notifications from 'pages/notifications/Notifications';

import BatchContractsList from 'pages/batches/batches';
import BatchAdd from 'pages/batches/batchAdd';
import Batch from 'pages/batches/batch';
import BananasBatchList from 'pages/batches/bananasBatchList';
import BatchDetailsTabs from 'pages/batches/batchDetailsTabs';
import BatchContract from 'pages/batches/batchContract';
import BatchHandshake from 'pages/batches/batchHandshake';
import BatchSupplier from 'pages/batches/batchSupplier';
import BatchValidation from 'pages/batches/batchValidation';
import Help from 'pages/help/Help';
import Profile from 'pages/profile/profile';

// components - AUTH
import SignIn from 'pages/auth/signIn/signIn';
import SignUp from 'pages/auth/signUp/signUp';
import ForgotPassword from 'pages/auth/forgotPassword/forgotPassword';
import ResetPassword from 'pages/auth/resetPassword/resetPassword';

// components - chooseChannel
import ChooseChannel from 'pages/chooseChannel/chooseChannel';

// components - chooseChannel
import AdminIndex from 'pages/admin/adminIndex';
import AdminUsers from 'pages/admin/adminUsers';
import AdminUser from 'pages/admin/adminUser';
import AdminInvitations from 'pages/admin/adminInvitations';
import AdminInvitation from 'pages/admin/adminInvitation';
import AdminInvitationCreate from 'pages/admin/adminInvitationCreate';

import { InboxOutboxSvg } from 'components/icons';

// themes
import { MODEL_TYPES } from 'themes/constants';
import UploadExcelFile2 from 'pages/batches/components/UploadExcelFile2';

/*
 * breadcrumbs
 * by default first link is 'main page'(dash board) and and last link is current route
 * params for route you can pass as key=>value in breadcrumbs this route
 * If you need to add some other routes to breadcrumbs, add those routesName to breadcrumbs[addRoutes] field
 */

// something weird happened with import - i cant use one from 'themes'
const CUSTOM = 'CUSTOM';
const NOTIFICATIONS = 'NOTIFICATIONS';
const USER = 'USER';
const INVITATIONS = 'INVITATIONS';

export const COLUMNS = {
  [CUSTOM]: CUSTOM,
  [NOTIFICATIONS]: NOTIFICATIONS,
  [USER]: USER,
  [INVITATIONS]: INVITATIONS,
};

export const routes = {
  MainLayout: [
    {
      // tmp endpoint for testing. Only in dev mode
      name: 'Tmp',
      path: '/tmp',
      exact: true,
      component: UploadExcelFile2, // hot(Tmp),
      pageTitle: 'Tmp',
      subMainMenuTitle: 'tmp',
      breadcrumbs: {},
      checkACL: () => process.env.NODE_ENV === 'development',
      modelType: MODEL_TYPES.UPLOAD,
    },
    {
      name: 'Dashboard',
      path: '/dashboard',
      exact: true,
      component: Dashboard,
      pageTitle: 'Dashboard',
      subMainMenuTitle: 'Dashboard',
      userSettingsKey: ({ channelName, queryParams }) =>
        `dashboard::${channelName}::scope::${queryParams.batchType}`,
      userColumnsType: 'defaultSelectedColumn',
      breadcrumbs: {},
      modelType: MODEL_TYPES.DASHBOARD,
    },
    {
      name: 'InboxDetails',
      path: '/inbox/show/:inboxId',
      exact: true,
      component: InboxDetails,
      pageTitle: 'Inbox Details',
      subMainMenuTitle: 'Inbox Details',
      breadcrumbs: {
        showParam: 'inboxId',
        addRoutes: ['Requests'],
      },
      checkACL: () => false,
    },
    {
      name: 'InboxAnswer',
      path: '/inbox/answer/:inboxId',
      exact: true,
      component: InboxAnswer,
      pageTitle: 'Answer Inbox Request',
      subMainMenuTitle: 'Answer Inbox Request',
      breadcrumbs: {
        showParam: 'inboxId',
        addRoutes: ['Requests'],
      },
      checkACL: () => false,
    },
    {
      name: 'Requests',
      path: '/inbox',
      exact: true,
      component: Inbox,
      pageTitle: 'Requests',
      subMainMenuTitle: (
        <>
          <Link to={{ pathname: '/inbox' }} className="active">
            Inbox
          </Link>
          <InboxOutboxSvg width={24} />
          <Link to={{ pathname: '/outbox' }}>Outbox</Link>
        </>
      ),
      breadcrumbs: {},
      checkACL: () => false,
    },
    {
      name: 'OutboxDetails',
      path: '/outbox/show/:outboxId',
      exact: true,
      component: OutboxDetails,
      pageTitle: 'Outbox Details',
      subMainMenuTitle: 'Outbox Details',
      breadcrumbs: {
        showParam: 'outboxId',
        addRoutes: ['Outbox'],
      },
      checkACL: () => false,
    },
    {
      name: 'Outbox',
      path: '/outbox',
      exact: true,
      component: Outbox,
      pageTitle: 'Outbox',
      subMainMenuTitle: (
        <>
          <Link to={{ pathname: '/inbox' }}>Inbox</Link>
          <InboxOutboxSvg width={24} />
          <Link to={{ pathname: '/outbox' }} className="active">
            Outbox
          </Link>
        </>
      ),
      breadcrumbs: {},
      checkACL: () => false,
    },
    {
      name: 'RequestAdd',
      path: '/requests/add',
      exact: true,
      component: RequestsAdd,
      pageTitle: 'RequestAdd',
      subMainMenuTitle: 'New Request',
      breadcrumbs: {},
      checkACL: () => false,
    },
    {
      name: 'Notifications',
      path: '/notifications',
      userSettingsKey: ({ channelName }) => `notifications::${channelName}`,
      userColumnsType: 'defaultSelectedColumn',
      exact: true,
      component: Notifications,
      pageTitle: 'Notifications',
      subMainMenuTitle: 'Notifications',
      breadcrumbs: {},
      modelType: MODEL_TYPES.NOTIFICATIONS,
    },
    {
      name: 'BatchAdd',
      path: '/batches/add/:modelType',
      exact: true,
      component: BatchAdd,
      pageTitle: 'New Batch',
      subMainMenuTitle: 'New Batch',
      breadcrumbs: {},
      modelType: MODEL_TYPES.UPLOAD,
    },
    {
      name: 'BatchContractType',
      path: '/batches/contract/:channelName/:type/:contract_number/:modelType',
      userSettingsKey: ({ channelName, ownOrgName, linkedOrgName }) =>
        `batchContract::${channelName}::${ownOrgName}::toOrganization${linkedOrgName}`,
      userColumnsType: 'defaultSelectedBatchContractColumn',
      exact: true,
      component: BatchContract,
      pageTitle: 'Batch Contract',
      subMainMenuTitle: 'Batch Contract',
      breadcrumbs: {
        showParam: 'contract_number',
        addRoutes: ['Batches'],
      },
      modelType: MODEL_TYPES.CONTRACT_DETAILS,
    },
    {
      name: 'BatchHandshake',
      path: '/batches/handshake/:channelName/:type',
      // userSettingsKey: ({ userId, channelName, routeTypeParam }) =>
      //   `batchHandshake::${userId}::${channelName}::${routeTypeParam}`,
      userSettingsKey: ({ channelName, ownOrgName, linkedOrgName }) =>
        `batchHandshake::${channelName}::${ownOrgName}::toOrganization${linkedOrgName}`,
      userColumnsType: 'defaultSelectedColumn',
      exact: true,
      component: BatchHandshake,
      pageTitle: 'Batch List',
      subMainMenuTitle: 'Batch List',
      breadcrumbs: {
        showParam: 'contractNumber',
      },
      modelType: MODEL_TYPES.HANDSHAKE,
    },
    {
      // for Eggs AH
      name: 'BatchTHTDateFrom',
      path: '/batches/thtDateFrom/:type/:packerGln/:thtDateFrom',
      // userSettingsKey: ({ userId, channelName, routeTypeParam, orgName }) =>
      //   `batchFilterList::${userId}::${channelName}::${routeTypeParam || orgName}`,
      userSettingsKey: ({ channelName, ownOrgName, linkedOrgName }) =>
        `batchHandshake::${channelName}::${ownOrgName}::toOrganization${linkedOrgName}`,
      userColumnsType: 'defaultSelectedBatchContractColumn',
      exact: true,
      component: BatchSupplier,
      pageTitle: 'Batch THT Date From',
      subMainMenuTitle: 'Batch List',
      breadcrumbs: {
        showParam: 'thtDateFrom',
        addRoutes: ['Batches'],
      },
      modelType: MODEL_TYPES.HANDSHAKE,
    },
    {
      name: 'BatchDetailsTabsParams',
      path: '/batchesDetails/:type/:batchCode/:modelType/:status',
      component: BatchDetailsTabs,
      exact: true,
      pageTitle: 'Batch Tabs Details',
      subMainMenuTitle: 'Batch Show',
      breadcrumbs: {
        showParam: 'batchCode',
      },
      modelType: MODEL_TYPES.DETAILS,
    },
    {
      name: 'BatchDetailsTabs',
      path: '/batchesDetails/:type/:batchCode/:modelType',
      exact: true,
      component: BatchDetailsTabs,
      pageTitle: 'Batch Tabs Details',
      subMainMenuTitle: 'Batch Show',
      breadcrumbs: {
        showParam: 'batchCode',
      },
      modelType: MODEL_TYPES.DETAILS,
    },
    {
      name: 'bananasBatchDetails',
      path: '/bananas/:type/:batchCode/:modelType/:status/',
      component: BananasBatchList,
      exact: true,
      userSettingsKey: ({ channelName, ownOrgName, linkedOrgName }) =>
        `batchContract::${channelName}::${ownOrgName}::toOrganization${linkedOrgName}`,
      userColumnsType: null,
      pageTitle: 'Batch Tabs Details',
      subMainMenuTitle: 'Batch Show',
      breadcrumbs: {
        showParam: 'batchCode',
      },
      modelType: MODEL_TYPES.DETAILS,
    },
    {
      name: 'bananasBatch',
      path: '/bananas/:type/:batchCode/:modelType/',
      component: BananasBatchList,
      userSettingsKey: ({ channelName, ownOrgName, linkedOrgName }) =>
        `batchContract::${channelName}::${ownOrgName}::toOrganization${linkedOrgName}`,
      exact: true,
      pageTitle: 'Batch Tabs Details',
      subMainMenuTitle: 'Batch Show',
      breadcrumbs: {
        showParam: 'batchCode',
      },
      modelType: MODEL_TYPES.DETAILS,
    },

    {
      name: 'BatchDetailsType',
      path: '/batches/:channelName/:type/:batch_id/:modelType',
      component: Batch,
      exact: true,
      pageTitle: 'Batch Details',
      subMainMenuTitle: 'Batch Details',
      breadcrumbs: {
        showParam: 'batch_id',
        addRoutes: ['Batches'],
      },
      modelType: MODEL_TYPES.DETAILS,
    },
    {
      default: true,
      name: 'Batches',
      path: '/batches',
      // modelType: 'index',
      component: BatchContractsList,
      userSettingsKey: ({ channelName }) => `batches::${channelName}`,
      userColumnsType: 'defaultSelectedColumn',
      exact: true,
      pageTitle: 'Batches List',
      subMainMenuTitle: 'Batch Contracts List',
      breadcrumbs: {},
      modelType: MODEL_TYPES.INDEX,
    },
    {
      name: 'BatchValidation',
      path: '/batches/:batchNumber/validation',
      component: BatchValidation,
      exact: true,
      pageTitle: 'Batch Validation',
      subMainMenuTitle: 'Batch Validation',
      breadcrumbs: {},
      modelType: MODEL_TYPES.VALIDATION,
    },
    {
      name: 'DocumentAdd',
      path: '/certificates/add',
      component: CertificateAdd,
      exact: true,
      pageTitle: 'New Certificate',
      subMainMenuTitle: 'New Certificate',
      breadcrumbs: {},
      modelType: MODEL_TYPES.CERTIFICATE,
    },
    {
      name: 'Document',
      path: '/certificates/:id',
      component: Certificate,
      exact: true,
      pageTitle: 'Certificate Details',
      subMainMenuTitle: 'Certificate Details',
      breadcrumbs: {
        showParam: 'id',
        addRoutes: ['Documents'],
      },
      modelType: MODEL_TYPES.CERTIFICATE,
    },
    {
      name: 'Documents',
      path: '/certificates',
      userSettingsKey: ({ channelName }) => `certificates::${channelName}`,
      userColumnsType: 'defaultSelectedColumn',
      component: Certificates,
      exact: true,
      pageTitle: 'Certificates',
      subMainMenuTitle: 'Certificates',
      breadcrumbs: {},
      modelType: MODEL_TYPES.CERTIFICATES_LIST,
    },
    {
      name: 'QRManager',
      path: '/QRManager',
      component: '',
      exact: true,
      pageTitle: 'QRManager',
      subMainMenuTitle: 'QRManager',
      breadcrumbs: {},
    },
    {
      name: 'Support',
      path: '/support',
      component: Help,
      exact: true,
      pageTitle: 'Support',
      subMainMenuTitle: 'Support',
      breadcrumbs: {},
    },
    {
      name: 'Profile',
      path: '/profile',
      component: Profile,
      exact: true,
      pageTitle: 'Profile',
      subMainMenuTitle: 'Profile',
      breadcrumbs: {},
    },
    {
      name: 'Admin',
      path: '/admin',
      component: AdminIndex,
      default: false,
      exact: true,
      pageTitle: 'Admin Page',
      subMainMenuTitle: 'Admin Page',
      breadcrumbs: {},
    },
    {
      name: 'AdminUsers',
      path: '/admin/users',
      userSettingsKey: ({ channelName }) => `adminUsers::${channelName}`,
      userColumnsType: 'defaultSelectedColumn',
      component: AdminUsers,
      default: false,
      exact: true,
      pageTitle: 'Admin Users',
      subMainMenuTitle: 'Admin Users',
      breadcrumbs: {},
      modelType: MODEL_TYPES.ADMIN_USERS,
    },
    {
      name: 'AdminUser',
      path: '/admin/users/:id',
      component: AdminUser,
      default: false,
      exact: true,
      pageTitle: 'User Details',
      subMainMenuTitle: 'Admin User Details',
      breadcrumbs: {
        addRoutes: ['AdminUsers'],
      },
    },
    {
      name: 'AdminInvitations',
      path: '/admin/invitations',
      userSettingsKey: ({ channelName }) => `adminInvitations::${channelName}`,
      userColumnsType: 'defaultSelectedColumn',
      component: AdminInvitations,
      default: false,
      exact: true,
      pageTitle: 'Admin Invitations',
      subMainMenuTitle: 'Admin Invitations',
      breadcrumbs: {},
      modelType: MODEL_TYPES.ADMIN_INVITATIONS,
    },
    {
      name: 'AdminInvitationCreate',
      path: '/admin/invitations/create',
      component: AdminInvitationCreate,
      default: false,
      exact: true,
      pageTitle: 'New Invitation',
      subMainMenuTitle: 'Admin Create New Invitation',
      breadcrumbs: {
        addRoutes: ['AdminInvitations'],
      },
    },
    {
      name: 'AdminInvitation',
      path: '/admin/invitations/:id',
      component: AdminInvitation,
      default: false,
      exact: true,
      pageTitle: 'Invitation Details',
      subMainMenuTitle: 'Admin Invitation Details',
      breadcrumbs: {
        addRoutes: ['AdminInvitations'],
      },
    },
  ],

  ChooseChannelLayout: [
    {
      name: 'ChooseChannel',
      path: '/choose-channel',
      component: ChooseChannel,
      default: true,
      exact: false,
      pageTitle: 'Choose Channel',
      subMainMenuTitle: 'Choose Channel',
      breadcrumbs: {},
    },
  ],

  AuthLayout: [
    {
      name: 'SignIn',
      path: '/auth/sign-in',
      component: SignIn,
      exact: true,
      default: true,
      pageTitle: '',
      subMainMenuTitle: '',
      breadcrumbs: {},
    },
    {
      name: 'SignUp',
      path: '/auth/sign-up',
      component: SignUp,
      exact: true,
      pageTitle: '',
      subMainMenuTitle: '',
      breadcrumbs: {},
    },
    {
      name: 'ForgotPassword',
      path: '/auth/forgot-password',
      component: ForgotPassword,
      exact: true,
      pageTitle: '',
      subMainMenuTitle: '',
      breadcrumbs: {},
    },
    {
      name: 'ResetPassword',
      path: '/auth/reset-password',
      component: ResetPassword,
      exact: true,
      pageTitle: '',
      subMainMenuTitle: '',
      breadcrumbs: {},
    },
  ],
};

export const getUrl = (name, params = {}) => {
  // todo with merge or etc...
  let getCurrentObject = routes.MainLayout.find((route) => route.name === name);

  if (!getCurrentObject) {
    getCurrentObject = routes.AuthLayout.find((route) => route.name === name);
  }

  if (!getCurrentObject) {
    getCurrentObject = routes.ChooseChannelLayout.find((route) => route.name === name);
  }

  let getCurrentUrl = '/dashboard';

  if (getCurrentObject) {
    getCurrentUrl = getCurrentObject.path;
    Object.keys(params).forEach((k) => {
      if (getCurrentUrl.includes(`/:${k}`)) {
        getCurrentUrl = getCurrentUrl.replace(`:${k}`, params[k]);
      }
    });
  } else {
    console.error(`the route name '${name}' does not exist!`);
  }

  return getCurrentUrl;
};
