import distributorbatch from 'mocks/router/bananas/distributorbatch';
import producerbatch from 'mocks/router/bananas/producerbatch';
import resellerbatch from 'mocks/router/bananas/resellerbatch';
import generic from 'mocks/router/generic';

export default {
  ...generic,
  distributorbatch,
  producerbatch,
  resellerbatch,
};
