export default () => [
  {
    id: 14044,
    is_read: false,
    created_at: '2019-09-02T15:06:18.912Z',
    event: {
      id: 2687,
      entityType: 'AHBatch',
      entityId: 'cc1ede45-8c97-4450-a1e9-0e4abc11edf3',
      statusChangedTo: 'ARCHIVED',
      eventDoneAt: '2019-09-02T15:06:18.000Z',
      actionType: 'ARCHIVED',
      actionSource: 'api',
      channel: { title: 'Eggs', name: 'eggs' },
      eventType: { id: 1, title: 'Batch upload', slug: 'BatchUpload' },
      eventDoneBy: {
        username: 'AHTST staging',
        email: 'AHTST@pc.t2f.io',
        org: { name: 'AH', fullname: 'Ah company', logo: null },
      },
    },
  },
  {
    id: 14027,
    is_read: false,
    created_at: '2019-09-02T15:06:06.958Z',
    event: {
      id: 2686,
      entityType: 'CertFile',
      entityId: '02d8f6cd-1b08-4d21-9409-64c8106560fc',
      statusChangedTo: null,
      eventDoneAt: '2019-09-02T15:06:06.000Z',
      actionType: 'CREATED',
      actionSource: null,
      channel: { title: 'Eggs', name: 'eggs' },
      eventType: { id: 4, title: 'Certificate upload', slug: 'CertificateUpload' },
      eventDoneBy: {
        username: 'AHTST staging',
        email: 'AHTST@pc.t2f.io',
        org: { name: 'AH', fullname: 'Ah company', logo: null },
      },
    },
  },
];
