import { ORGANIZATIONS } from 'themes/constants';
import egg from 'mocks/responses/notifications/eggs/egg';
import ah from 'mocks/responses/notifications/eggs/ah';

import { createNotificationResponse } from '../utils';

const { SIM, AH, EGG1, EGG2 } = ORGANIZATIONS;

export default (org: ORGANIZATIONS) => {
  switch (org) {
    case EGG1:
    case EGG2:
      return createNotificationResponse(2, egg(), 1);
    case AH:
      return createNotificationResponse(2, ah(), 2);
    case SIM:
      return createNotificationResponse(4, [...egg(), ...ah()], 3);
    default:
      return createNotificationResponse(0, []);
  }
};
