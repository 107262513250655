import React, { useCallback, useEffect, useMemo } from 'react';
import { change, untouch, WrappedFieldProps, WrappedFieldInputProps } from 'redux-form';
//
import DatePicker from 'react-calendar';
import moment from 'moment/moment';
// components
import Layout from 'components/Form/Layout';
// jss
import injectSheet, { WithStyles } from 'react-jss';
import { inputsStyles } from 'components/Form/Layout/styles';
import { useOutsideClick } from 'lib/utils';

interface OwnProps
  extends WrappedFieldProps,
    WrappedFieldInputProps,
    WithStyles<typeof inputsStyles> {
  className?: string;
  title?: string;
  placeholder?: string;
  disabled?: boolean;
  style?: object;
}

const CustomDayPicker: React.FC<OwnProps> = (props) => {
  const {
    classes,
    input,
    className,
    title,
    meta,
    disabled = false,
    value,
    placeholder,
    style,
  } = props;
  const { isOpen, setIsOpen, ref } = useOutsideClick(false);

  const onInputFocus = useCallback(() => {
    setIsOpen(true);
  }, []);

  const isError = useMemo(() => meta.touched && meta.error, [meta]);

  const toCalendarValue = useCallback(
    (date?: Date | Date[]) =>
      date && !Array.isArray(date) && moment(date).isValid() ? moment(date).toDate() : undefined,
    [],
  );

  const handleCalendarDateChange = (date: Date | Date[]) => {
    !Array.isArray(date) && input.onChange(moment(date).format('YYYY-MM-DD'));
    setIsOpen(false);
  };

  useEffect(() => {
    if (disabled) {
      meta.dispatch(change(meta.form, input.name, null));
      meta.dispatch(untouch(meta.form, input.name));
    }
  }, [disabled]);

  return (
    <Layout title={title} meta={meta} id={input.name} style={style}>
      <div ref={ref}>
        <input
          id={input.name}
          type="text"
          className={`${classes.input} ${className || classes.control} ${isError &&
            classes.controlError}`}
          value={value}
          autoComplete="off"
          disabled={disabled}
          {...input}
          onFocus={onInputFocus}
          placeholder={placeholder}
        />
        {!input.value ||
        (/^\d{4}-\d{2}-\d{2}$/.test(input.value) && moment(input.value).isValid()) ? (
          <DatePicker
            className={`${classes.calendar}${isOpen ? ' isOpen' : ''}`}
            value={toCalendarValue(input.value)}
            onChange={handleCalendarDateChange}
            selectRange={false}
            locale="en"
          />
        ) : (
          ''
        )}
      </div>
    </Layout>
  );
};

export default injectSheet(inputsStyles)(CustomDayPicker);
