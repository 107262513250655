import React from 'react';
// redux
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
// getChannels
import { getChannels, getOwnOrgName, getUserId } from 'domain/env';
// jss
import injectSheet, { WithStyles } from 'react-jss';
import chooseChannelStyle from 'pages/chooseChannel/styles/chooseChannelStyle';
// icons
import * as icons from 'components/icons';
// actions
import * as EnvActions from 'domain/env/actions';
// themes
import { CHANNELS, ORGANIZATIONS } from 'themes/constants';
import { AppStateType } from 'types';
import { CHANNEL_NAME_TO_TITLE_MAP } from 'themes/main';

const mapStateToProps = (state: AppStateType) => ({
  channels: getChannels(state),
  orgName: getOwnOrgName(state),
  userId: getUserId(state)
});

const TBD = () => (
  <h1 style={{ height: '210px', fontSize: '80px', textAlign: 'center' }}>TBD ICON</h1>
);

const ChooseChannel: React.FC<WithStyles<typeof chooseChannelStyle>> = (props) => {
  const dispatch = useDispatch();
  const { classes } = props;
  const { channels, orgName, userId } = useSelector(mapStateToProps, shallowEqual);

  const onJoinChannel = (id: number) => () => {
    const channelToSet = channels.find((f) => f.id === Number(id));
    dispatch(EnvActions.setChannelAction(channelToSet));
  };

  const logout = () => dispatch(EnvActions.logOutAction());

  const { ORANGES, EGGS, TILAPIA, BANANAS, JUICE } = CHANNELS;
  const logoMaps = {
    [ORANGES]: 'OrangesChannelLogoSvg',
    [EGGS]: 'EggsChannelLogoSvg',
    [TILAPIA]: 'TilapiaChannelLogoSvg',
    [BANANAS]: 'BananasChannelLogoSvg',
    [JUICE]: 'JuiceChannelLogoSvg',
  };

  return (
    <div className={classes.component}>
      <div className="logout" onClick={logout}>
        <span>Logout</span>
        <icons.LogoutSvg width={24} height={24} />
      </div>
      <div className="channels">
        {channels.map((channel) => {
          if (
            [ORGANIZATIONS.REFRESCO, ORGANIZATIONS.AH].includes(orgName) &&
            channel.name === CHANNELS.JUICE && userId != 72
          ) {
            return null;
          }

          const label =
            (CHANNEL_NAME_TO_TITLE_MAP[orgName] &&
              CHANNEL_NAME_TO_TITLE_MAP[orgName][channel.name]) ||
            CHANNEL_NAME_TO_TITLE_MAP.common[channel.name];

          // @ts-ignore
          const Logo = logoMaps[channel.name] ? icons[logoMaps[channel.name]] : TBD;
          return (
            <div className="channel" key={channel.id}>
              {Logo && <Logo />}
              <div className="title">{label}</div>
              <div className="button_place">
                <button
                  data-channel={channel.name}
                  type="button"
                  className={classes.btn}
                  onClick={onJoinChannel(channel.id)}
                >
                  Join channel
                </button>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default injectSheet(chooseChannelStyle)(ChooseChannel);
