import React from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
// redux
import * as uiActions from 'domain/ui/actions';
import * as documentActions from 'domain/documents/actions';
import { getChannelName } from 'domain/env';
import { getQueryParams } from 'domain/common';
// types
import { BATCH_STATUS, CHANNELS } from 'themes/constants';
import { AppStateType } from 'types';
import { Certificate } from 'lib/lib';
// utils
import AC from 'domain/AC';

interface OwnProps {
  column: Certificate;
  className?: string;
}

const mapStateToProps = (state: AppStateType) => ({
  channelName: getChannelName(state),
  queryParams: getQueryParams(state),
});

const ArchiveCertificateButton: React.FC<OwnProps> = (props) => {
  const dispatch = useDispatch();
  const { queryParams, channelName } = useSelector(mapStateToProps, shallowEqual);
  const { column, className = 'approve-document' } = props;

  const deleteDocument = (certId: string) => (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    dispatch(documentActions.deleteDocumentAction(certId));
    dispatch({ type: uiActions.uiModalAction.failure });
  };

  const openModal = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    dispatch(
      uiActions.uiModalAction({
        title: 'Confirm your answer',
        body: (
          <>
            <div>
              Are you sure you want to archive cert
              <br /> {column.certId} ?
            </div>
            <div className="buttons">
              <button type="submit" className="btn" onClick={deleteDocument(column.certId)}>
                Submit
              </button>
            </div>
          </>
        ),
      }),
    );
  };

  const getClassName = () => {
    if (channelName === CHANNELS.ORANGES || channelName === CHANNELS.EGGS) {
      return 'square-button';
    } else {
      return className;
    }
  };

  return (
    <>
      {AC.USER.CAN.DELETE_CERTIFICATE && queryParams.status !== BATCH_STATUS.ARCHIVED && (
        <button
          type="button"
          className={getClassName()}
          data-archive-cert={column.certId}
          onClick={openModal}
        >
          Archive
        </button>
      )}
    </>
  );
};

export default React.memo(ArchiveCertificateButton);
