import React, { useCallback, useLayoutEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
// actions
import * as envActions from 'domain/env/actions';
// components
import { SimpleTabGroupI, TabI } from 'components/Dropdown/SimpleTabGroup';
import DropdownTab from 'components/Dropdown/DatepickerRange';
//
import { useDateFilters } from 'lib/utils';
import { AppStateType } from 'types';
import { getQueryParams } from 'domain/common';
import { BATCH_STATUS } from 'themes/constants';

export interface ListItem {
  title: string;
  value: number;
  defaultSelected?: boolean;
}

export interface DateFilters {
  beginDate: Date;
  endDate: Date;
}

export interface TabItem {
  title: string;
  id: BATCH_STATUS;
  defaultSelected?: boolean;
}

interface TabDropdownI extends TabI {
  selectedListValue: DateFilters;
  changeDateFilters: (listItem: DateFilters) => void;
}

interface DropdownTabGroupProps<T extends TabDropdownI> extends SimpleTabGroupI<TabItem> {
  valuesList: Array<ListItem>;
  activeListValue?: number;
}

export const TabsContainer: React.FC = (props) => <div className="tabs">{props.children}</div>;

export const DropDownTabGroup: React.FC<DropdownTabGroupProps<TabDropdownI>> = (props) => {
  const dispatch = useDispatch();
  const queryParams = useSelector((state: AppStateType) => getQueryParams(state), shallowEqual);
  const { tabs, activeTabId, valuesList } = props;

  const { dateFilters, setDateFilters, activeTab, setActiveTab } = useDateFilters(
    activeTabId,
    tabs,
    valuesList,
  );

  useLayoutEffect(() => {
    dispatch({
      type: envActions.setTabsQueryParamsAction.success,
      payload: {
        ...queryParams,
        status: activeTab,
        beginDate: moment(dateFilters.beginDate).format('YYYY-MM-DD'),
        endDate: moment(dateFilters.endDate).format('YYYY-MM-DD'),
      },
    });
  }, [dateFilters, activeTab]);

  const changeFilters = useCallback(({ id, beginDate, endDate }) => {
    setActiveTab(id);
    setDateFilters({ beginDate, endDate });
  }, []);

  const mapTabs = () =>
    tabs.map((tab) => (
      <DropdownTab
        setActiveTab={setActiveTab}
        id={tab.id}
        title={tab.title}
        values={dateFilters}
        isActive={activeTab === tab.id}
        key={tab.title}
        changeFilters={changeFilters}
      />
    ));

  return <TabsContainer>{mapTabs()}</TabsContainer>;
};
