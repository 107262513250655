import React from 'react';
import { getTheme } from 'themes/main';
// utils
import { formatBatchDate } from 'lib/helpers';
import { renderShortDateFormat } from 'lib/renderHelpers';
import { BatchRecord, ModelRequiredInfo } from 'lib/lib';

export default (modelRequiredInfo: ModelRequiredInfo) => [
  {
    title: 'Gtin',
    label: 'GTIN',
    key: 'gtin',
    defaultPreviewBeforeUploadColumn: true,
    style: { flex: '1 0 50px' },
    validate: () => true,
  },
  {
    title: 'Gln Supplier',
    label: 'GLN Supplier',
    key: 'glnSupplier',
    defaultPreviewBeforeUploadColumn: true,
    style: { flex: '1 0 50px' },
    validate: () => true,
  },
  {
    title: 'Gln Buyer',
    label: 'GLN Buyer',
    key: 'glnBuyer',
    style: { flex: '1 0 50px' },
    validate: () => true,
  },
  {
    title: 'Gln Delivery Location',
    label: 'GlN Delivery location',
    key: 'glnDeliveryLocation',
    defaultPreviewBeforeUploadColumn: true,
    style: { flex: '1 0 50px' },
    validate: () => true,
  },
  {
    title: 'Quantity',
    label: 'Quantity',
    key: 'quantity',
    defaultPreviewBeforeUploadColumn: true,
    style: { flex: '1 0 50px' },
    validate: () => true,
  },
  {
    title: 'Delivery Datetime',
    label: 'Delivery datetime',
    key: 'deliveryDatetime',
    defaultPreviewBeforeUploadColumn: true,
    style: { flex: '1 0 50px' },
    edit: { type: 'date' },
    handleParsedValueFromCSVFile: (value: string) =>
      formatBatchDate(
        value,
        getTheme(modelRequiredInfo.role, modelRequiredInfo.channelName).timezone,
        getTheme(modelRequiredInfo.role, modelRequiredInfo.channelName).timeFormats,
      ),
    renderPreviewBeforeUpload: (column: BatchRecord) =>
      renderShortDateFormat(column.deliveryDatetime),
    validate: () => true,
  },
  {
    key: 'batchnumber',
    label: 'Batchnumber',
    title: 'Batch Number',
    defaultPreviewBeforeUploadColumn: true,
    style: { flex: '1 0 100px' },
    handleParsedValueFromCSVFile: (value: string) => String(value),
    renderPreviewBeforeUpload: (column: BatchRecord) => String(column.batchnumber),
    validate: () => true,
  },

  {
    title: 'Batch Id',
    label: 'batchId',
    key: 'batchId',
    style: { flex: '1 0 50px' },
  },
];
