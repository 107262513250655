export default (theme) => ({
  notificationConfig: {
    display: 'block',

    '& input': {
      marginRight: '.5em',
    },
    '& .line': {
      display: 'block',
      borderBottom: [2, 'solid', 'rgb(226, 226, 226)'],
      maxWidth: 300,
      marginBottom: 18,
    },
    '& .notificationTypes': {
      '& h3.title': {
        textTransform: 'uppercase',
        fontVariant: 'normal',
        color: 'rgb(102, 102, 102)',
        fontSize: theme.fontSizes.xs,
        fontWeight: 'normal',
        fontStyle: 'normal',
        textDecoration: 'none',
        textAlign: 'left',
        margin: ['1em', 0, '1em', 0],
      },
      '& .selectAllTypes': {
        margin: ['1.5em', 0],
      },
      '& ul': {
        display: 'inline-flex',
        padding: ['1em', 0],
        justifyContent: 'start',
        flexWrap: 'wrap',
        '& li': {
          display: 'inline-flex',
          alignItems: 'start',
          justifyContent: 'flex-start',
          width: 200,
          margin: [0, '1em', '1em', 0],
          '& input': {
            marginTop: 4,
          },
          '& label': {
            userSelect: 'none',
          },
        },
      },
      '& .periodSelector': {
        display: 'flex',

        '&__items': {
          flex: '0 0 30%',
          '&-item': {
            display: 'inline-flex',
            textAlign: 'left',
            flexWrap: 'wrap',
            font: {
              weight: 'normal',
              style: 'normal',
            },
            textDecoration: 'none',
            '& input': {
              width: 13,
              height: 13,
              marginTop: 4,
            },
            marginBottom: '1em',
            '& div': {
              color: 'rgb(61, 61, 61)',
              '& label': {
                textAlign: 'left',
                font: {
                  size: 14,
                  weight: 'normal',
                  style: 'normal',
                  decoration: 'none',
                },
              },
              '& p': {
                marginTop: 3,
                font: {
                  variant: 'normal',
                  weight: 'normal',
                  style: 'normal',
                  size: 11,
                },
                textAlign: 'left',
              },
            },
          },
        },
        '&__select': {
          flex: '0 0 30%',
          '& h2': {
            marginBottom: 15,
          },
          '& select': {
            width: 200,
            color: 'rgb(61, 61, 61)',
            border: [1, 'rgb(200, 200, 200)', 'solid'],
            borderRadius: 5,
            font: {
              size: 12,
              weight: 'normal',
              style: 'normal',
            },
            textAlign: 'left',
            boxShadow: 'rgba(0, 0, 0, 0.1) 1px 1px 2px 0px inset',
          },
        },
      },
    },
    '& .saveBtn': {
      '& button': {
        ...theme.components.btn,
      },
    },
  },
});
