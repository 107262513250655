export default () => [
  {
    id: 13813,
    is_read: false,
    created_at: '2019-09-02T13:11:57.855Z',
    event: {
      id: 2667,
      entityType: 'REFRESCOBatch',
      entityId: '1778602',
      statusChangedTo: null,
      eventDoneAt: '2019-09-02T13:11:55.000Z',
      actionType: 'CREATED',
      actionSource: 'frontEndApp',
      channel: { title: 'Oranges', name: 'oranges' },
      eventType: { id: 1, title: 'Batch upload', slug: 'BatchUpload' },
      eventDoneBy: {
        username: 'REFTST staging',
        email: 'REFTST@pc.t2f.io',
        org: { name: 'REFRESCO', fullname: 'Refresco company', logo: null },
      },
    },
  },
  {
    id: 1845,
    is_read: true,
    created_at: '2019-08-13T08:40:40.055Z',
    event: {
      id: 587,
      entityType: 'Demand',
      entityId: '13',
      statusChangedTo: 'CLOSED',
      eventDoneAt: '2019-08-13T08:40:40.000Z',
      actionType: 'STATUS_CHANGED',
      actionSource: null,
      channel: { title: 'Oranges', name: 'oranges' },
      eventType: { id: 8, title: 'Request status change', slug: 'DemandStatusChange' },
      eventDoneBy: {
        username: 'REFTST staging',
        email: 'REFTST@pc.t2f.io',
        org: { name: 'REFRESCO', fullname: 'Refresco company', logo: null },
      },
    },
  },
  {
    id: 1158,
    is_read: false,
    created_at: '2019-06-14T12:02:47.384Z',
    event: {
      id: 94,
      entityType: 'CertFile',
      entityId: '6cfe85f6-2ca1-43b9-a40d-ac053eabeddc',
      statusChangedTo: null,
      eventDoneAt: '2019-06-14T12:02:45.000Z',
      actionType: 'CREATED',
      actionSource: null,
      channel: { title: 'Oranges', name: 'oranges' },
      eventType: { id: 4, title: 'Certificate upload', slug: 'CertificateUpload' },
      eventDoneBy: {
        username: 'REFTST staging',
        email: 'REFTST@pc.t2f.io',
        org: { name: 'REFRESCO', fullname: 'Refresco company', logo: null },
      },
    },
  },
];
