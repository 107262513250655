import { customDatePreview, formatDateToISO } from 'lib/renderHelpers';
import {
  validationRequired,
  validationString,
  validationFloat,
  validationCustomDate,
} from 'lib/uploadFieldValidations';
import { ORGANIZATIONS } from 'themes/constants';
import { UPLOAD_TIME_FORMAT } from 'themes/main';
import { BatchRecord, ModelRequiredInfo } from 'lib/lib';

export default (modelRequiredInfo: ModelRequiredInfo) => {
  const { JUMBO } = ORGANIZATIONS;
  const DATE_FORMAT = UPLOAD_TIME_FORMAT[JUMBO] as Record<string, string>;
  const validationJumboReceiptDate = validationCustomDate(DATE_FORMAT.dateReceipt);
  const validationJumboThtConsumerProductDate = validationCustomDate(
    DATE_FORMAT.thtConsumerProduct,
  );

  return [
    {
      key: 'dateReceipt',
      label: 'Date receipt',
      title: 'Date Receipt',
      style: { flex: '0 0 120px' },
      handleParsedValue: (value: string) => formatDateToISO(value, DATE_FORMAT.dateReceipt),
      renderUploadPreview: ({ dateReceipt }: BatchRecord) =>
        customDatePreview(dateReceipt, [validationRequired, validationJumboReceiptDate]),
      validate: [validationRequired, validationJumboReceiptDate],
      edit: { type: 'date' },
    },
    {
      key: 'supplier',
      label: 'Supplier',
      title: 'Supplier',
      style: { flex: '0 0 80px' },
      handleParsedValue: (value: string) => String(value || ''),
      renderUploadPreview: (column: BatchRecord) => column.supplier,
      validate: [validationRequired, validationString],
    },
    {
      key: 'poNumber',
      label: 'PO number',
      title: 'PO Number',
      style: { flex: '0 0 130px' },
      handleParsedValue: (value: string) => String(value || ''),
      renderUploadPreview: (column: BatchRecord) => column.poNumber,
      validate: [validationRequired, validationString],
      edit: { type: 'input' },
    },
    {
      key: 'articleNumberConsumerProduct',
      label: 'Article number consumer product',
      title: 'Article number consumer product',
      style: { flex: '0 0 120px' },
      handleParsedValue: (value: string) => String(value || ''),
      renderUploadPreview: (column: BatchRecord) => column.articleNumberConsumerProduct,
      validate: [validationRequired, validationString],
      edit: { type: 'input' },
    },
    {
      key: 'palletNumber',
      label: 'Pallet Number',
      title: 'Pallet Number',
      style: { flex: '1 0 170px' },
      handleParsedValue: (value: string) => String(value || ''),
      renderUploadPreview: (column: BatchRecord) => column.palletNumber,
      renderExportValue: (column: BatchRecord) => column.palletNumber,
      validate: [validationRequired, validationString],
    },
    {
      key: 'lotNumber',
      label: 'Lot Number',
      title: 'Lot Number',
      style: { flex: '1 0 100px' },
      handleParsedValue: (value: string) => String(value || ''),
      renderUploadPreview: (column: BatchRecord) => column.lotNumber,
      renderExportValue: (column: BatchRecord) => column.lotNumber,
      validate: [validationRequired, validationString],
    },
    {
      key: 'aantalVerpakkingen',
      label: 'Aantal verpakkingen',
      title: 'Aantal verpakkingen',
      style: { flex: '1 0 100px' },
      handleParsedValue: (value: string) => parseFloat(value),
      renderUploadPreview: (column: BatchRecord) => column.aantalVerpakkingen,
      renderExportValue: (column: BatchRecord) => column.lotNumber,
      validate: [validationRequired, validationFloat],
    },
    {
      key: 'thtConsumerProduct',
      label: 'THT consumer product',
      title: 'THT consumer product',
      style: { flex: '1 0 120px' },
      handleParsedValue: (value: string) => formatDateToISO(value, DATE_FORMAT.thtConsumerProduct),
      renderUploadPreview: ({ thtConsumerProduct }: BatchRecord) =>
        customDatePreview(thtConsumerProduct, [
          validationRequired,
          validationJumboThtConsumerProductDate,
        ]),
      validate: [validationRequired, validationJumboThtConsumerProductDate],
      edit: { type: 'date' },
    },
  ];
};
