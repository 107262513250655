export default () => ({
  $class: 'org.powerchain.bananas.batch.ProducerContractConcept',
  details: [
    {
      $class: 'org.powerchain.bananas.batch.BatchDetailsConcept',
      createdAt: '2019-07-25T09:48:07.152Z',
      createdBy:
        'resource:org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8',
      "modifiedBy_participant":{
        "username":null,
        "email":null,
        "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        },
        "channel":{
          "title":"Bananas",
          "name":"bananas"
        },
        "user":{
          "username":"Fyffes TST staging",
          "email":"FyffesTST@pc.t2f.io",
          "org":{
            "name":"Fyffes",
            "fullname":"Fyffes",
            "logo":null
          }
        }
      },
      status: 'PENDING',
      "createdBy_participant":{
        "username":null,
        "email":null,
        "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        },
        "channel":{
          "title":"Bananas",
          "name":"bananas"
        },
        "user":{
          "username":"Fyffes TST staging",
          "email":"FyffesTST@pc.t2f.io",
          "org":{
            "name":"Fyffes",
            "fullname":"Fyffes",
            "logo":null
          }
        }
      }
    },
  ],
  processBatches: [
    {
      $class: 'org.powerchain.bananas.batch.ProducerProcessBatch',
      floId: '6168',
      farmCode: '20166',
      farmName: 'LA FRONTERA',
      productShortCode: 'FTMH20',
      containerNumber: 'SUDU629553-2',
      stuffedDate: '2019-05-22T00:00:00.000Z',
      numberOfBoxes: 54,
      voyageNumber: 'CO2119C4',
      departureDate: '2019-05-25T00:00:00.000Z',
      portLoaded: 'Turbo - Uniban',
      SSCCPallet: '377005910922461002',
      batchId: '29ce223c-8a8c-4f44-a7fd-eef796ebb470',
      createdAt: '2019-07-25T09:48:07.152Z',
      createdBy:
        'resource:org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8',
      status: 'PENDING',
      uploadSource: 'frontEndApp',
      "modifiedBy_participant":{
        "username":null,
        "email":null,
        "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        },
        "channel":{
          "title":"Bananas",
          "name":"bananas"
        },
        "user":{
          "username":"Fyffes TST staging",
          "email":"FyffesTST@pc.t2f.io",
          "org":{
            "name":"Fyffes",
            "fullname":"Fyffes",
            "logo":null
          }
        }
      },
      "createdBy_participant":{
        "username":null,
        "email":null,
        "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        },
        "channel":{
          "title":"Bananas",
          "name":"bananas"
        },
        "user":{
          "username":"Fyffes TST staging",
          "email":"FyffesTST@pc.t2f.io",
          "org":{
            "name":"Fyffes",
            "fullname":"Fyffes",
            "logo":null
          }
        }
      }
    },
    {
      $class: 'org.powerchain.bananas.batch.ProducerProcessBatch',
      floId: '6168',
      farmCode: '20166',
      farmName: 'LA FRONTERA',
      productShortCode: 'FTMH19',
      containerNumber: 'SUDU629553-1',
      stuffedDate: '2019-05-21T00:00:00.000Z',
      numberOfBoxes: 54,
      voyageNumber: 'CO2119C3',
      departureDate: '2019-05-24T00:00:00.000Z',
      portLoaded: 'Turbo - Uniban',
      SSCCPallet: '377005910922461001',
      batchId: 'a4ec1cd9-9b5a-402b-9cdb-fe414980a7ad',
      createdAt: '2019-07-25T09:48:07.152Z',
      createdBy:
        'resource:org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8',
      status: 'PENDING',
      uploadSource: 'frontEndApp',
      "modifiedBy_participant":{
        "username":null,
        "email":null,
        "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        },
        "channel":{
          "title":"Bananas",
          "name":"bananas"
        },
        "user":{
          "username":"Fyffes TST staging",
          "email":"FyffesTST@pc.t2f.io",
          "org":{
            "name":"Fyffes",
            "fullname":"Fyffes",
            "logo":null
          }
        }
      },
      "createdBy_participant":{
        "username":null,
        "email":null,
        "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        },
        "channel":{
          "title":"Bananas",
          "name":"bananas"
        },
        "user":{
          "username":"Fyffes TST staging",
          "email":"FyffesTST@pc.t2f.io",
          "org":{
            "name":"Fyffes",
            "fullname":"Fyffes",
            "logo":null
          }
        }
      }
    },
  ],
  deliveryBatches: [],
});
