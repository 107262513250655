import React, { useLayoutEffect, useMemo } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
// components
import Datatable from 'components/Datatable/datatable';
import AdminNav from 'pages/admin/components/adminNav';
import ModelSettingsDropDown from 'components/Dropdown/ModelSettingsDropDown';
import TableCellDropdown from 'components/Dropdown/TableCellDropdown';
// redux
import { getUsers, getIsLoading } from 'domain/admin';
import { getDisplayModel } from 'domain/common';
import * as adminActions from 'domain/admin/actions';
// jss
import injectSheet, { WithStyles } from 'react-jss';
import adminIndexStyle from 'pages/admin/styles/adminIndexStyle';
// types
import { AppStateType } from 'types';
import { AdminUser } from 'lib/lib';

const mapStateToProps = (state: AppStateType) => ({
  users: getUsers(state),
  isLoading: getIsLoading(state),
  displayModel: getDisplayModel(state),
});

const AdminUsers: React.FC<WithStyles<typeof adminIndexStyle>> = ({ classes }) => {
  const dispatch = useDispatch();
  const { users, displayModel, isLoading } = useSelector(mapStateToProps, shallowEqual);

  const settingsAndActions = useMemo(
    () => ({
      key: 'settingsAndActions',
      title: <ModelSettingsDropDown />,
      style: { flex: '1 0 100px' },
      render: (user: AdminUser) => (
        <>
          {(user.status || '') === 'pending' && (
            <TableCellDropdown
              items={[
                {
                  key: 'approved',
                  title: 'approve',
                  onClick: () => {
                    dispatch(adminActions.approveAdminUserAction({ id: user.id }));
                  },
                },
                {
                  key: 'delete',
                  title: 'delete',
                  onClick: () => {
                    if (window.confirm(`Are you sure you want to delete '${user.username}'`)) {
                      dispatch(adminActions.deleteUserAction({ id: user.id }));
                    }
                  },
                },
              ]}
            />
          )}
          {user.currentUserChannel && user.currentUserChannel.status === 'approved' && (
            <TableCellDropdown
              items={[
                {
                  key: 'suspend',
                  title: 'suspend',
                  onClick: () => {
                    dispatch(adminActions.suspendAdminUserAction({ id: user.id }));
                  },
                },
                {
                  key: 'delete',
                  title: 'delete',
                  onClick: () => {
                    if (window.confirm(`Are you sure you want to delete '${user.username}'`)) {
                      dispatch(adminActions.deleteUserAction({ id: user.id }));
                    }
                  },
                },
              ]}
            />
          )}
        </>
      ),
    }),
    [],
  );

  useLayoutEffect(() => {
    dispatch(adminActions.fetchUsersAction());
  }, []);

  return (
    <div className={classes.component}>
      <AdminNav />
      <Datatable
        data={users}
        columns={displayModel}
        isLoading={isLoading}
        settingsAndActions={settingsAndActions}
      />
    </div>
  );
};

export default injectSheet(adminIndexStyle)(AdminUsers);
