import React, { Dispatch, SetStateAction, useCallback } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { getModel } from 'domain/common';
import { AppStateType } from 'types';
import { getChannelName } from 'domain/env';
import { CHANNELS } from 'themes/constants';
import { ValidationErrors, UploadedParsedFile } from 'pages/batches/components/UploadExcelFile2';
import uploadExcelTabsStyles from 'components/Datatable/styles/uploadExcelTabsStyles';
import injectSheet, { WithStyles } from 'react-jss';

interface OwnProps extends WithStyles<typeof uploadExcelTabsStyles> {
  activeTab: string;
  setActiveTab: Dispatch<SetStateAction<string>>;
  validationErrors: ValidationErrors | null;
  uploadedParsedFile: UploadedParsedFile;
}

const mapStateToProps = (state: AppStateType) => ({
  model: getModel(state),
  channelName: getChannelName(state),
});

const UploadExcelTabs: React.FC<OwnProps> = (props) => {
  const { activeTab, setActiveTab, validationErrors, classes, uploadedParsedFile } = props;
  const { model, channelName } = useSelector(mapStateToProps, shallowEqual);

  const resolvePluralWord = useCallback((value?: number) => (value && value > 1 ? 's' : ''), []);

  const rowsNumber = useCallback(
    (modelName: string) =>
      uploadedParsedFile && uploadedParsedFile[modelName] && uploadedParsedFile[modelName].length,
    [uploadedParsedFile],
  );

  if (channelName === CHANNELS.BANANAS) {
    return (
      <span className={classes.excelTabsContainer}>
        {model.tabs.map((tab, index, array) => {
          const { modelName, title } = tab;
          const errorClassName =
            validationErrors && !!validationErrors[modelName] ? classes.errorTab : '';

          return (
            <div
              onClick={() => setActiveTab(modelName)}
              key={tab.title}
              className={[
                classes.excelTab,
                errorClassName,
                modelName === activeTab ? 'active' : '',
              ].join(' ')}
              style={{
                zIndex: array.length - index,
                transform: `translateX(${index * -15}px)`,
              }}
            >
              <span className={classes.tabTitle} data-cy="tab" data-tab={title}>
                {' '}
                {tab.title}
              </span>
              <p className={classes.errorMessagesContainer}>
                {validationErrors && !!validationErrors[modelName] ? (
                  <span>{`(${validationErrors[modelName]} error${resolvePluralWord(
                    validationErrors[modelName],
                  )})`}</span>
                ) : null}
                <span>{`${rowsNumber(modelName)} row${resolvePluralWord(
                  rowsNumber(modelName),
                )}`}</span>
              </p>
            </div>
          );
        })}
      </span>
    );
  }

  return (
    <>
      {model.tabs.map((tab) => (
        <div
          key={tab.modelName}
          className={`fileTab ${tab.modelName === activeTab ? 'active' : ''} ${
            !!(validationErrors && validationErrors[tab.modelName]) ? 'errorValidation' : ''
          }`}
          onClick={() => setActiveTab(tab.modelName)}
        >
          <div data-cy="tab" className="title">
            {tab.title}
          </div>
          {!!(validationErrors && validationErrors[tab.modelName]) && (
            <div className="errors">{`(${validationErrors[tab.modelName]} error${resolvePluralWord(
              validationErrors[tab.modelName],
            )})`}</div>
          )}
          <div className="count">{`${
            uploadedParsedFile[tab.modelName].length
          } row${resolvePluralWord(uploadedParsedFile[tab.modelName].length)}`}</div>
        </div>
      ))}
    </>
  );
};

export default injectSheet(uploadExcelTabsStyles)(UploadExcelTabs);
