export default [
  {
    $class: 'org.powerchain.juice.batch.ImporterBatchGroupedConcept',
    salesOrderNumber: 'tst_contract000000',
    kpiRatioAvg: 0.234,
    kgShippedTotal: 10,
    invoiceDateMin: '2020-03-23T11:12:41.334Z',
    invoiceDateMax: '2020-03-23T11:12:41.334Z',
    receiptDateMin: '2020-03-23T11:12:41.334Z',
    receiptDateMax: '2020-03-23T11:12:41.334Z',
    vesselTkBatchId: 1,
    createdAtMin: '2020-03-23T11:12:41.334Z',
    createdAtMax: '2020-03-23T11:12:41.334Z',
    createdBy:
      'resource:org.powerchain.juice.member.ImporterUser#CitrosucoGhent_f02b24d5-4aee-4077-a651-39cbb8b193d9',
    channelName: 'juice',
    createdBy_participant: {
      username: null,
      email: null,
      participant:
        'org.powerchain.juice.member.ImporterUser#CitrosucoGhent_f02b24d5-4aee-4077-a651-39cbb8b193d9',
      org: { name: 'CitrosucoGhent', fullname: 'Citrosuco Ghent', logo: null },
      user: {
        username: 'CitrosucoGhentTST staging',
        email: 'CitrosucoGhentTST@pc.t2f.io',
        org: { name: 'CitrosucoGhent', fullname: 'Citrosuco Ghent', logo: null },
      },
      channel: { title: 'Juice', name: 'juice' },
    },
  },
];
