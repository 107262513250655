import dashboardTabModelHollander from 'models/hollander/tabs/dashboardTabModel';
import dashboardTabModelFyffes from 'models/fyffes/tabs/dashboardTabModel';
import dashboardTabModelBanafood from 'models/banafood/tabs/dashboardTabModel';
import { BatchRecord, ModelRequiredInfo } from 'lib/lib';

const style = {
  minWidth: '144px',
  width: '100%',
  wordBreak: 'break-word',
  fontSize: 13,
  padding: '10px 0',
  whiteSpace: 'pre-wrap',
  overflow: 'hidden',
};

export default ({ params }: ModelRequiredInfo) => {
  switch (params.batchType) {
    case 'DistributorBatch': // Hollander dashboard model
      return dashboardTabModelHollander;
    case 'ResellerBatch': // Banafood dashboard model
      return dashboardTabModelBanafood;
    case 'ProducerBatch': // Fyffes dashboard model
      return dashboardTabModelFyffes;
    case 'all': //all
      return [
        {
          key: 'ProducerProcessBatch_floId',
          label: 'Fyffes \nFLO-ID',
          title: 'Fyffes \nFLO-ID',
          style: { ...style, minWidth: '48px' },
          defaultSelectedColumn: true,
          render: (column: BatchRecord) => column.ProducerProcessBatch_floId || 'null',
          renderExportValue: (column: BatchRecord) => column.ProducerProcessBatch_floId || 'null',
        },
        {
          key: 'ProducerProcessBatch_SSCCPallet',
          label: 'Fyffes SSCCPallet \n(1st tab)',
          title: 'Fyffes SSCCPallet \n(1st tab)',
          style: style,
          defaultSelectedColumn: true,
          render: (column: BatchRecord) => column.ProducerProcessBatch_SSCCPallet || 'null',
          renderExportValue: (column: BatchRecord) =>
            column.ProducerProcessBatch_SSCCPallet || 'null',
        },
        {
          key: 'ProducerDeliveryBatch_SSCCPallet',
          label: 'Fyffes SSCCPallet \n(2nd tab)',
          title: 'Fyffes SSCCPallet \n(2nd tab)',
          style: style,
          defaultSelectedColumn: true,
          render: (column: BatchRecord) => column.ProducerDeliveryBatch_SSCCPallet || 'null',
          renderExportValue: (column: BatchRecord) =>
            column.ProducerDeliveryBatch_SSCCPallet || 'null',
        },
        {
          key: 'ResellerInBatch_colombiaSSCCPallet',
          label: 'Colombia SSCCPallet \n(Banafood IN)',
          title: 'Colombia SSCCPallet \n(Banafood IN)',
          style: style,
          defaultSelectedColumn: true,
          render: (column: BatchRecord) => column.ResellerInBatch_colombiaSSCCPallet || 'null',
          renderExportValue: (column: BatchRecord) =>
            column.ResellerInBatch_colombiaSSCCPallet || 'null',
        },
        {
          key: 'ResellerOutBatch_antwerpSSCC',
          label: 'AntwerpSSCC \n(Banafood OUT)',
          title: 'AntwerpSSCC \n(Banafood OUT)',
          style: style,
          defaultSelectedColumn: true,
          render: (column: BatchRecord) => column.ResellerOutBatch_antwerpSSCC || 'null',
          renderExportValue: (column: BatchRecord) => column.ResellerOutBatch_antwerpSSCC || 'null',
        },
        {
          key: 'ResellerOutBatch_banafoodSSCCPallet',
          label: 'Banafood SSCCPallet \n(Banafood OUT)',
          title: 'Banafood SSCCPallet \n(Banafood OUT)',
          style: style,
          defaultSelectedColumn: true,
          render: (column: BatchRecord) => column.ResellerOutBatch_banafoodSSCCPallet || 'null',
          renderExportValue: (column: BatchRecord) =>
            column.ResellerOutBatch_banafoodSSCCPallet || 'null',
        },
        {
          key: 'DistributorInslagBatch_SSCCCode',
          label: 'SSCCCode \nHollander',
          title: 'SSCCCode \nHollander',
          style: style,
          defaultSelectedColumn: true,
          render: (column: BatchRecord) => column.DistributorInslagBatch_SSCCCode || 'null',
          renderExportValue: (column: BatchRecord) =>
            column.DistributorInslagBatch_SSCCCode || 'null',
        },
        {
          key: 'DistributorInslagBatch_batchNumber',
          label: 'Batch Number \n(Hollander IN)',
          title: 'Batch Number \n(Hollander IN)',
          style: style,
          defaultSelectedColumn: true,
          render: (column: BatchRecord) => column.DistributorInslagBatch_batchNumber || 'null',
          renderExportValue: (column: BatchRecord) =>
            column.DistributorInslagBatch_batchNumber || 'null',
        },
        {
          key: 'DistributorUitslagBatch_batchNumber',
          label: 'Batch Number \n(Hollander OUT)',
          title: 'Batch Number \n(Hollander OUT)',
          style: style,
          defaultSelectedColumn: true,
          render: (column: BatchRecord) => column.DistributorUitslagBatch_batchNumber || 'null',
          renderExportValue: (column: BatchRecord) =>
            column.DistributorUitslagBatch_batchNumber || 'null',
        },
      ];
    default:
      return [];
  }
};
