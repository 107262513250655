import React, { useLayoutEffect } from 'react';

import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import * as batchesActions from 'domain/batches/actions';
//
import Datatable from 'components/Datatable/datatable';
import { TabsGroup as SimpleTabsGroup } from 'components/Dropdown/SimpleTabGroup';

import { getBatches, getIsLoading } from 'domain/batches';
import {
  getModel,
  getDisplayModel,
  getTitleParamsAsString,
  getParsedQueryStringParams,
  getIndexEndpoint,
} from 'domain/common';
// modal
import { Link } from 'react-router-dom';
import { getUrl } from 'routes';
import batchesContractStyle from 'pages/batches/styles/batchesContractStyle';
// jss
import injectSheet, { WithStyles } from 'react-jss';
import { BATCH_STATUS } from 'themes/constants';

import { AppStateType } from 'types';

const mapStateToProps = (state: AppStateType) => ({
  batches: getBatches(state),
  isLoading: getIsLoading(state),
  model: getModel(state),
  titleParamsAsString: getTitleParamsAsString(state),
  filters: getParsedQueryStringParams(state),
  displayModel: getDisplayModel(state),
  endpoint: getIndexEndpoint(state),
});

const BatchHandshake: React.FC<WithStyles<typeof batchesContractStyle>> = (props) => {
  const dispatch = useDispatch();
  const { classes } = props;
  const {
    filters,
    batches,
    isLoading,
    displayModel,
    titleParamsAsString,
    model,
    endpoint,
  } = useSelector(mapStateToProps, shallowEqual);

  const fetchHandshakeBatches = () => {
    dispatch(batchesActions.fetchBatchHandshakeAction({ endpoint, filters }));
  };

  useLayoutEffect(fetchHandshakeBatches, []);

  return (
    <section className={classes.component}>
      <div className={classes.tableHeader}>
        <SimpleTabsGroup
          tabs={[
            {
              title: titleParamsAsString,
              id: BATCH_STATUS.ALL,
              defaultSelected: true,
            },
          ]}
        />
      </div>

      <Datatable
        data={batches}
        columns={displayModel}
        isLoading={isLoading}
        settingsAndActions={model.settingsAndActions}
      />

      {!isLoading && batches.length === 0 && (
        <div className={classes.returnToBatches}>
          <Link to={{ pathname: getUrl('Batches') }}>Back to Batch List</Link>
        </div>
      )}
    </section>
  );
};

export default injectSheet(batchesContractStyle)(BatchHandshake);
