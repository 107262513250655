import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { reducer as ReduxFormReducers } from 'redux-form';

import { History } from 'history';

// reducers
// import * as common from 'domain/common';
// import * as env from 'domain/env';
// import * as unmatched from 'domain/unmatched';
// import * as profile from 'domain/profile';
// import * as batches from 'domain/batches';
// import * as documents from 'domain/documents';
// import * as requests from 'domain/requests';
// import * as notifications from 'domain/notifications';
// import * as ui from 'domain/ui';
// import * as admin from 'domain/admin';

import { InjectedReducers } from './';

/**
 * Combine all reducers in this file and export the combined reducers.
 * Creates the main reducer with the dynamically injected ones
 */
export default function createReducer(injectedReducers: InjectedReducers, history: History) {
  return combineReducers({
    ...require('domain/common').reducer,
    ...require('domain/env').reducer,
    ...require('domain/unmatched').reducer,
    ...require('domain/profile').reducer,
    ...require('domain/batches').reducer,
    ...require('domain/documents').reducer,
    ...require('domain/requests').reducer,
    ...require('domain/notifications').reducer,
    ...require('domain/ui').reducer,
    ...require('domain/admin').reducer,
    router: connectRouter(history),
    form: ReduxFormReducers,

    ...injectedReducers,
  });
}
