import Route from 'route-parser';

import producerbatchIndexListResponseJuice from 'mocks/responses/channels/juice/producerbatch/indexList';
import producerbatchContractListResponseJuice from 'mocks/responses/channels/juice/producerbatch/contractsList';
import producerbatchDetailsListResponseJuice from 'mocks/responses/channels/juice/producerbatch/detailsList';
import producerbatchHandshakeResponseJuice from 'mocks/responses/channels/juice/producerbatch/handshake';

import { deleteContractResponseJuice } from 'mocks/router/utils';
import uploadBatchResponse from 'mocks/responses/batchUpload/batchUploadEggsOranges';

export default (request) => {
  const route = new Route(
    '/api/v1/juice/producerbatch(/handshake/?*handshake)(?*filter)(/:batchId)(/contracts/:contractId)',
  );
  const parameters = route.match(request.url);

  if (parameters) {
    if (parameters.filter) {
      if (parameters.filter.includes('beginDate')) {
        return [200, producerbatchIndexListResponseJuice];
      }
    }
    if (parameters.contractId) {
      if (request.method === 'delete') {
        return [200, deleteContractResponseJuice(parameters.contractId)];
      }

      return [200, producerbatchContractListResponseJuice];
    }
    if (parameters.batchId) {
      return [200, producerbatchDetailsListResponseJuice];
    }
    if (parameters.handshake) {
      return [200, producerbatchHandshakeResponseJuice];
    }
  }

  if (request.method === 'post') {
    return [200, uploadBatchResponse(JSON.parse(request.data))];
  }

  return [500, `url not proceeded: ${request.url}`];
};
