export default () => [
  {
    id: 1763,
    is_read: false,
    created_at: '2019-08-12T09:19:13.367Z',
    event: {
      id: 574,
      entityType: 'ImporterBatch',
      entityId: '20180095',
      statusChangedTo: null,
      eventDoneAt: '2019-08-12T09:19:10.000Z',
      actionType: 'CREATED',
      actionSource: 'frontEndApp',
      eventType: { id: 1, title: 'Batch upload', slug: 'BatchUpload' },
      channel: { title: 'Tilapia', name: 'tilapia' },
      eventDoneBy: {
        username: 'SeafoodConnectionTST',
        email: 'SeafoodConnectionTST@pc.t2f.io',
        org: { name: 'SeafoodConnection', fullname: 'Seafood Connection', logo: null },
      },
    },
  },
  {
    id: 10287,
    is_read: false,
    created_at: '2019-08-23T12:28:12.099Z',
    event: {
      id: 2009,
      entityType: 'CertFile',
      entityId: 'ae628dcc-78d7-4eff-9406-d32706709994',
      statusChangedTo: null,
      eventDoneAt: '2019-08-23T12:28:09.000Z',
      actionType: 'CREATED',
      actionSource: null,
      channel: { title: 'Tilapia', name: 'tilapia' },
      eventType: { id: 4, title: 'Certificate upload', slug: 'CertificateUpload' },
      eventDoneBy: {
        username: 'SeafoodConnectionTST',
        email: 'SeafoodConnectionTST@pc.t2f.io',
        org: { name: 'SeafoodConnection', fullname: 'Seafood Connection', logo: null },
      },
    },
  },
];
