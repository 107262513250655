import { useCallback } from 'react';
import XLSX, { WorkBook, WorkSheet } from 'xlsx';
import { IndexedObject, ModelObjectDefault, RenderModel } from 'lib/lib';
import { toast } from 'lib/helpers';
import {
  HandleParsedValue,
  UploadedParsedFile,
  Validate,
} from 'pages/batches/components/UploadExcelFile2';

export const useExcelUpload = (model: RenderModel) => {
  const handleNumbers = useCallback(
    (sheet: WorkSheet) =>
      Object.keys(sheet).reduce(
        (Result, key) => {
          const item = sheet[key];
          return {
            ...Result,
            [key]: item.t === 'n' ? { ...item, w: `${item.v}` } : item,
          };
        },
        {} as WorkSheet,
      ),
    [],
  );

  const findHeaderRowIndex = useCallback(
    (sheetData: string[][] = [], headersArray: string[] = []) => {
      const matchRow = sheetData.find((rowArray) => {
        // looking for a row which contains all headers - this will be our row to start parse from
        return headersArray
          .map((headerName) => rowArray.includes(headerName))
          .every((validationResult) => validationResult);
      });
      if (matchRow) {
        return sheetData.indexOf(matchRow);
      } else {
        console.error(
          `Failed to find row according to model headers ${headersArray} in ${sheetData}`,
        );
        throw new Error(
          `Failed to find headers row in document. Please verify all sheets have all required headers`,
        );
      }
    },
    [],
  );

  const getHeadersArrayByTabName = useCallback((model: RenderModel, tabOriginalName: string) => {
    const { tabs } = model;
    const currentTab = tabs.find(({ originalName }) => originalName === tabOriginalName);
    if (!(currentTab && currentTab.modelName)) {
      throw new Error(`${tabOriginalName} tab not found!`);
    }
    // header labels to match them to excel row
    return model[currentTab.modelName]
      .filter((field) => !['createdAt', 'createdBy'].includes(field.key))
      .map(({ label }) => label);
  }, []);

  const setModelKeysToParsedRows = useCallback(
    (parsedDataArray: IndexedObject<string>[], modelFieldsArray: ModelObjectDefault[]) =>
      parsedDataArray.map((parsedRow, rowIndex) =>
        Object.keys(parsedRow).reduce(
          (accumulator, label) => {
            const modelField = modelFieldsArray.find((modelField) => modelField.label === label);
            const fieldValue = parsedDataArray[rowIndex][label];
            // here you can check methods from your model, modify values ....
            const modifiedParsedValue = modelField && {
              [modelField.key]: modelField.handleParsedValue
                ? (modelField.handleParsedValue as HandleParsedValue)(fieldValue)
                : fieldValue,
            };
            return {
              ...accumulator,
              ...modifiedParsedValue,
              rowId: (rowIndex as unknown) as string,
            };
          },
          {} as IndexedObject<string>,
        ),
      ),
    [],
  );

  const getDataByModel = useCallback((workbook: WorkBook, model: RenderModel) => {
    const { tabs } = model;

    return (
      tabs
        .map(({ originalName, modelName }) => {
          debugger;
          const firstParseOptions = { raw: false, header: 1, blankRows: false };
          const workSheet = handleNumbers(workbook.Sheets[originalName]);
          const sheetData: string[][] = XLSX.utils.sheet_to_json(workSheet, firstParseOptions);
          const headersArray = getHeadersArrayByTabName(model, originalName);
          // if we have index - we can specify parse options to start parse from
          const headersIndex = findHeaderRowIndex(sheetData, headersArray);
          const finalParseOptions = { range: headersIndex, raw: false, blankRows: false };
          const finalParsedData: IndexedObject<string>[] = XLSX.utils.sheet_to_json(
            workSheet,
            finalParseOptions,
          );
          // excel keys should be replaced by model keys to make it usable
          const modelKeysData = setModelKeysToParsedRows(finalParsedData, model[modelName]);

          return { [modelName]: modelKeysData };
        })
        // join all into single object *OLD API SUPPORT*
        .reduce((cumulative, current) => ({ ...current, ...cumulative }), {})
    );
  }, []);

  const extractWorkbookData = useCallback((workbook: WorkBook) => {
    try {
      const { SheetNames } = workbook;
      const { tabs: modelTabs = [] } = model;

      // handling no-tab, single tab models
      switch (true) {
        case modelTabs.length === 1 && SheetNames.length > 1: {
          return getDataByModel(workbook, model);
        }
        case modelTabs.length === 1: {
          if (SheetNames.length > 1) {
            throw new Error(`Loaded document has more tabs than provided model ${SheetNames}`);
          }
          // sheet first tab
          const [tabName] = SheetNames;
          // model tabs
          const { tabs } = model;
          const [modelTab] = tabs;
          const singleTabModel = {
            ...model,
            tabs: [{ ...modelTab, originalName: tabName }],
          } as RenderModel;
          return getDataByModel(workbook, singleTabModel);
        }
        default: {
          return getDataByModel(workbook, model);
        }
      }
    } catch (e) {
      toast(e.message, 'error');
    }
  }, []);

  return useCallback((workbook: WorkBook) => {
    const xlsxData = extractWorkbookData(workbook);
    return (
      xlsxData && {
        uploadedParsedFile: { tabs: model.tabs, ...xlsxData } as UploadedParsedFile,
      }
    );
  }, []);
};

export const useExcelValidation = (model: RenderModel) =>
  useCallback(
    (uploadedParsedFile: IndexedObject<IndexedObject<string>[]>) =>
      Object.keys(uploadedParsedFile).reduce(
        (accumulator, tabName) => {
          const tabErrorsNumber = model[tabName].reduce((tabsAccumulator, modelField) => {
            let currentFieldErrors = 0;
            uploadedParsedFile[tabName].forEach((parsedFileRow) => {
              const validationErrors =
                modelField.validate &&
                (modelField.validate as Validate).find(
                  (v) => !v(parsedFileRow[modelField.key]).status,
                );
              if (validationErrors) {
                currentFieldErrors += validationErrors.length;
              }
            });
            return tabsAccumulator + currentFieldErrors;
          }, 0);
          return {
            ...accumulator,
            [tabName]: tabErrorsNumber,
          };
        },
        {} as Record<string, number>,
      ),
    [],
  );
