import React from 'react';
import { Link } from 'react-router-dom';
import { getUrl } from 'routes';
import indexBaseModelFields from 'models/indexBaseModelFields';
import { CHANNELS, ORGANIZATIONS } from 'themes/constants';
import { renderShortDateFormat } from 'lib/renderHelpers';
import { BatchRecord, ModelRequiredInfo } from 'lib/lib';

// label defines what field to take from CSV batch file for upload
export default (modelRequiredInfo: ModelRequiredInfo) => [
  {
    key: 'jdeReference',
    label: 'JDE REFERENCE',
    title: 'JDE Reference',
    style: { flex: '1 0 50px' },
    defaultSelectedColumn: true,
    render: (column: BatchRecord) => (
      <Link
        className="contract"
        to={{
          pathname: getUrl('BatchContractType', {
            type: ORGANIZATIONS.LDCBR.toLowerCase(),
            contract_number: encodeURIComponent(encodeURIComponent(`${column.jdeReference}`)),
            modelType: 'contractDetails',
            channelName: column.channelName,
          }),
        }}
      >
        {column.jdeReference}
      </Link>
    ),
  },
  {
    key: 'contractNumber',
    label: 'CONTRACT NUMBER',
    title: 'Contract Number',
    style: { flex: '1 0 50px' },
    defaultSelectedColumn: true,
    render: (column: BatchRecord) => column.contractNumber,
  },
  {
    key: 'qttyContracted',
    label: 'QTTY CONTRACTED - KG',
    title: 'QTTY Contracted',
    style: { flex: '1 0 50px' },
    defaultSelectedColumn: true,
    render: (column: BatchRecord) => column.qttyContracted,
  },
  {
    key: 'batchFinal',
    label: 'BATCH - FINAL',
    title: 'Batch Final',
    style: { flex: '1 0 100px' },
    defaultSelectedColumn: true,
    render: (column: BatchRecord) => column.batchFinal,
  },
  {
    key: 'manufacturedSequence',
    label: 'MANUFACTING SEQUENCE',
    title: 'Manufactured Sequence',
    style: { flex: '1 0 50px' },
    render: (column: BatchRecord) => column.manufacturedSequence,
  },
  {
    key: 'productCode',
    label: 'PRODUCT CODE',
    title: 'Product Code',
    style: { flex: '1 0 100px' },
    defaultSelectedColumn: true,
    render: (column: BatchRecord) => column.productCode,
  },
  {
    key: 'manufacturingDate',
    label: 'MANUFACTURING DATE',
    title: 'Manufacturing Date',
    style: { flex: '1 0 50px' },
    render: (column: BatchRecord) => renderShortDateFormat(column.manufacturingDate),
  },
  {
    key: 'startBlendingDate',
    label: 'START BLENDING DATE',
    title: 'Start Blending Date',
    style: { flex: '1 0 50px' },
    render: (column: BatchRecord) => renderShortDateFormat(column.startBlendingDate),
  },
  {
    key: 'finalBlendingDate',
    label: 'FINAL BLENDING DATE',
    title: 'Final Blending Date',
    style: { flex: '1 0 50px' },
    render: (column: BatchRecord) => renderShortDateFormat(column.finalBlendingDate),
  },
  {
    key: 'facilityName',
    label: 'FACILITY NAME',
    title: 'Facility Name',
    style: { flex: '1 0 50px' },
    render: (column: BatchRecord) => column.facilityName,
  },
  {
    key: 'farmName',
    label: 'FARM NAME',
    title: 'Farm Name',
    style: { flex: '1 0 50px' },
    render: (column: BatchRecord) => column.farmName,
  },
  // {
  //   key: 'supplierVerification',
  //   label: 'Supplier Verification',
  //   title: 'Supplier Verification',
  //   style: { flex: '1 0 50px' },
  //   defaultSelectedColumn: true,
  //   render: (record: any) => getSupplierStatus(record),
  // },
  {
    key: 'farmAddress',
    label: 'FARM ADDRESS',
    title: 'Farm Address',
    style: { flex: '1 0 50px' },
    render: (column: BatchRecord) => column.farmAddress,
  },
  {
    key: 'zipcode',
    label: 'ZIPCODE',
    title: 'Zipcode',
    style: { flex: '1 0 50px' },
    render: (column: BatchRecord) => column.zipcode,
  },
  {
    key: 'state',
    label: 'STATE',
    title: 'State',
    style: { flex: '1 0 50px' },
    render: (column: BatchRecord) => column.state,
  },
  {
    key: 'city',
    label: 'CITY',
    title: 'City',
    style: { flex: '1 0 50px' },
    render: (column: BatchRecord) => column.city,
  },
  {
    key: 'varietyName',
    label: 'VARIETY NAME',
    title: 'Variety Name',
    style: { flex: '1 0 50px' },
    render: (column: BatchRecord) => column.varietyName,
  },
  {
    key: 'block',
    label: 'BLOCK',
    title: 'Block',
    style: { flex: '1 0 50px' },
    render: (column: BatchRecord) => column.block,
  },
  {
    key: 'fruitHarvestStartDate',
    label: 'FRUIT HARVEST START DATE',
    title: 'Fruit Harvest Start Date',
    style: { flex: '1 0 50px' },
    render: (column: BatchRecord) => renderShortDateFormat(column.fruitHarvestStartDate),
  },
  {
    key: 'fruitHarvestFinalDate',
    label: 'FRUIT HARVEST FINAL DATE',
    title: 'Fruit Harvest Final Date',
    style: { flex: '1 0 50px' },
    render: (column: BatchRecord) => renderShortDateFormat(column.fruitHarvestFinalDate),
  },
  {
    key: 'percentFarm',
    label: '%FARM',
    title: '% Farm',
    style: { flex: '1 0 50px' },
    render: (column: BatchRecord) => column.percentFarm,
  },
  {
    key: 'totalPickers',
    label: 'TOTAL PICKERS',
    title: 'Total Pickers',
    style: { flex: '1 0 50px' },
    render: (column: BatchRecord) => column.totalPickers,
  },
  {
    key: 'ageAverage',
    label: 'AGE AVERAGE',
    title: 'Age Average',
    style: { flex: '1 0 50px' },
    render: (column: BatchRecord) => column.ageAverage,
  },
  {
    key: 'dateOfReceiptSantos',
    label: 'DATE OF RECEIPT SANTOS',
    title: 'Date Of Receipt Santos',
    style: { flex: '1 0 50px' },
    render: (column: BatchRecord) => renderShortDateFormat(column.dateOfReceiptSantos),
  },
  {
    key: 'batchId',
    label: 'Batch Id',
    title: 'Batch Details',
    style: { flex: '0 0 150px' },
    render: (column: BatchRecord) => (
      <React.Fragment>
        <Link
          to={{
            pathname: getUrl('BatchDetailsType', {
              type: ORGANIZATIONS.LDCBR.toLowerCase(),
              batch_id: column.batchId,
              modelType: 'details',
              channelName: column.channelName,
            }),
          }}
          title={column.batchId}
          className="batch-details"
        >
          Details
        </Link>
      </React.Fragment>
    ),
  },
  ...indexBaseModelFields(modelRequiredInfo),
];
