import React, { useMemo } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import injectSheet, { WithStyles } from 'react-jss';
// redux
import { getChannelName, getUser, getUserOrgName } from 'domain/env';
import * as uiActions from 'domain/ui/actions';
// helpers
import { getTheme } from 'themes/main';
// types
import { Theme } from 'lib/lib';
import { AppStateType } from 'types';
import { isSelectValue, SelectValue } from 'pages/documents/certificateAddComponetns/utils';

const approveFromStyles = (theme: Theme) => ({
  handleSubmitWrapper: {
    display: 'block',
    width: 'auto',
    margin: [0, 'auto'],
  },
  logo: {
    display: 'flex',
    flex: '0 0 100%',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '2em',
    '& img': {
      maxWidth: 120,
    },
  },
  certificateInfo: {
    display: 'flex',
    justifyContent: 'space-between',
    borderRadius: 15,
    backgroundColor: theme.colors.white,
    boxShadow: 'rgba(0, 0, 0, 0.2) 0px 1px 2px 0px;',
    padding: '1em',
    '& .first': {
      flex: '0 1 30%',
      textAlign: 'left',
      alignItems: 'flex-start',
    },
    '& .second': {
      flex: '1 0 40%',
      textAlign: 'left',
      alignItems: 'flex-start',
    },
    '& .third': {
      flex: '0 1 30%',
      textAlign: 'left',
      alignItems: 'flex-start',
    },
    marginBottom: '2em',
  },
  checkbox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& input': {
      marginRight: '0.5em',
    },
    marginBottom: '2em',
  },
  buttonsPlace: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '2em',
    '& > button': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: 198,
      height: 28,
      flex: '0 0 198px',
      backgroundColor: 'transparent',
      border: '1px solid black',
      borderRadius: 10,
      margin: [0, '1em'],
      outline: 0,
      cursor: 'pointer',
    },
    '& button.delete': {
      borderColor: 'rgb(252, 0, 0)',
    },
    '& button.edit': {
      borderColor: 'rgb(252, 139, 0)',
    },
    '& button.submit': {
      borderColor: 'rgb(10, 196, 0)',
    },
  },
  btn: {
    display: 'block',
    position: 'relative',
    width: 'auto',
    padding: [8, 25],
    margin: [40, 'auto', 0],
    border: 0,
    borderRadius: 10,
    font: {
      weight: 600,
      size: theme.fontSizes.sm,
    },
    outline: 0,
    backgroundColor: theme.colors.primary,
    color: theme.colors.white,
    transition: 'background-color 0.5s ease',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.colors.primaryHover,
      textDecoration: 'none',
    },
  },
});

interface CertificateFormData {
  documentCategory?: SelectValue;
  documentType: SelectValue;
  documentNumber?: string;
  documentResult?: SelectValue;
  auditDate?: string;
  documentScope?: SelectValue;
  from: string;
  until: string;
  notes?: string;
  certFile: File[];
  entityId?: string;
  entityCertified?: string | SelectValue;
}

interface OwnProps extends WithStyles<typeof approveFromStyles> {
  certificateFormData: CertificateFormData;
  deleteCallback: () => void;
  callback: () => void;
}

const mapStateToProps = (state: AppStateType) => ({
  channelName: getChannelName(state),
  orgName: getUserOrgName(state),
  user: getUser(state),
});

const BeforeSendCertFrom: React.FC<OwnProps> = (props) => {
  const dispatch = useDispatch();
  const { orgName, user, channelName } = useSelector(mapStateToProps, shallowEqual);
  const { classes, certificateFormData, deleteCallback, callback } = props;

  const logo = useMemo(() => getTheme(orgName, channelName).logo, []);

  const closeModal = () => dispatch({ type: uiActions.uiModalAction.failure });

  const deleteCertificate = () => {
    closeModal();
    deleteCallback();
  };

  const submitCertificate = () => {
    callback();
    closeModal();
  };

  const entityCertified = useMemo(
    () =>
      certificateFormData.entityCertified && isSelectValue(certificateFormData.entityCertified)
        ? certificateFormData.entityCertified.label
        : certificateFormData.entityCertified,
    [certificateFormData.entityCertified],
  );

  return (
    <div className={classes.handleSubmitWrapper}>
      <div>
        <div className={classes.logo}>
          <img src={logo} alt="logo" />
        </div>
        <div className={classes.certificateInfo}>
          <div className="first">
            <p>From: {user.username} </p>
            <p>From node: {orgName} </p>
          </div>
          <div className="second">
            {/*{!!certificateFormData.label && <p>Certificate Type: {certificateFormData.label}</p>}*/}
            {!!certificateFormData.documentCategory && (
              <p>Category: {certificateFormData.documentCategory.label}</p>
            )}
            <p>Type: {certificateFormData.documentType.label}</p>
            {!!certificateFormData.documentNumber && (
              <p>Number: {certificateFormData.documentNumber}</p>
            )}
            {!!certificateFormData.documentNumber && (
              <p>Number: {certificateFormData.documentNumber}</p>
            )}
            {!!entityCertified && (
              <p>
                Entity Certified:{' '}
                {certificateFormData.entityCertified &&
                isSelectValue(certificateFormData.entityCertified)
                  ? certificateFormData.entityCertified.label
                  : certificateFormData.entityCertified}
              </p>
            )}
            {!!certificateFormData.entityId && <p>Entity Id: {certificateFormData.entityId}</p>}
            {!!certificateFormData.documentResult && (
              <p>Result: {certificateFormData.documentResult.label}</p>
            )}
            {!!certificateFormData.documentScope && (
              <p>Scopes: {certificateFormData.documentScope.label}</p>
            )}
            {!!certificateFormData.notes && <p>Notes: {certificateFormData.notes} </p>}
          </div>
          <div className="third">
            <p>Audit date: {certificateFormData.auditDate}</p>
            <p>Valid from: {certificateFormData.from}</p>
            <p>Valid until: {certificateFormData.until}</p>
          </div>
        </div>
        <div className={classes.checkbox}>
          <input type="checkbox" id="send-email" />
          <label htmlFor="send-email">Send me a copy of this сertificate via email</label>
        </div>
        <div className={classes.buttonsPlace}>
          <button type="button" className="delete" onClick={deleteCertificate}>
            Delete
          </button>
          <button type="button" className="edit" onClick={closeModal}>
            Edit
          </button>
          <button type="button" className="submit" onClick={submitCertificate}>
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};

export default injectSheet(approveFromStyles)(BeforeSendCertFrom);
