import React from 'react';
import { Link } from 'react-router-dom';
// validation helpers
import { validateEnumYesNo, validateIsNumber, validateRegexp } from 'lib/validations';
// utils
import { getUrl } from 'routes';
import baseModelFields from 'models/baseModelFields';
import { renderShortDateFormat, booleanYesNoFormat } from 'lib/renderHelpers';
import { formatBatchDate, formatYesNoStringToBoolean } from 'lib/helpers';
import { getTheme } from 'themes/main';
import { BatchRecord, ModelRequiredInfo } from 'lib/lib';

export default (modelRequiredInfo: ModelRequiredInfo) => [
  {
    key: 'farmName',
    label: 'Farm name',
    title: 'Farm Name',
    defaultPreviewBeforeUploadColumn: true,
    style: { flex: '1 0 70px' },
  },
  {
    key: 'farmEggNumber',
    label: 'Farm egg number',
    title: 'Egg Number',
    style: { flex: '1 0 100px' },
    validate: (value: string) =>
      validateRegexp(value, /^([0-9]{1})-([a-zA-Z0-9]{2})-([0-9]{3,15})$/),
  },
  {
    key: 'thtDateFrom',
    label: 'THT date from',
    title: 'THT Date From',
    defaultPreviewBeforeUploadColumn: true,
    style: { flex: '1 0 100px' },
    edit: { type: 'date' },
    renderPreviewBeforeUpload: (column: BatchRecord) => renderShortDateFormat(column.thtDateFrom),
    handleParsedValueFromCSVFile: (value: string) => {
      if (validateRegexp(value, /^[0-9]{4}-[0-9]{2}-[0-9]{2}$/)) {
        return formatBatchDate(
          value,
          getTheme(modelRequiredInfo.role, modelRequiredInfo.channelName).timezone,
          getTheme(modelRequiredInfo.role, modelRequiredInfo.channelName).timeFormats,
        );
      }
      if (validateRegexp(value, /^[0-9]{2}-[0-9]{2}-[0-9]{4}$/)) {
        return formatBatchDate(
          value,
          getTheme(modelRequiredInfo.role, modelRequiredInfo.channelName).timezone,
          getTheme(modelRequiredInfo.role, modelRequiredInfo.channelName).timeFormats,
        );
      }
      return value;
    },
    validate: (value: string) =>
      validateRegexp(value, /^[0-9]{4}-[0-9]{2}-[0-9]{2}$/) ||
      validateRegexp(value, /^[0-9]{2}-[0-9]{2}-[0-9]{4}$/),
  },
  {
    key: 'thtDateTo',
    label: 'THT date to',
    title: 'THT Date To',
    defaultPreviewBeforeUploadColumn: true,
    style: { flex: '1 0 100px' },
    edit: { type: 'date' },
    renderPreviewBeforeUpload: (column: BatchRecord) => renderShortDateFormat(column.thtDateTo),
    handleParsedValueFromCSVFile: (value: string) => {
      if (validateRegexp(value, /^[0-9]{4}-[0-9]{2}-[0-9]{2}$/)) {
        return formatBatchDate(
          value,
          getTheme(modelRequiredInfo.role, modelRequiredInfo.channelName).timezone,
          getTheme(modelRequiredInfo.role, modelRequiredInfo.channelName).timeFormats,
        );
      }
      if (validateRegexp(value, /^[0-9]{2}-[0-9]{2}-[0-9]{4}$/)) {
        return formatBatchDate(
          value,
          getTheme(modelRequiredInfo.role, modelRequiredInfo.channelName).timezone,
          getTheme(modelRequiredInfo.role, modelRequiredInfo.channelName).timeFormats,
        );
      }
      return value;
    },
    validate: (value: string) =>
      validateRegexp(value, /^[0-9]{4}-[0-9]{2}-[0-9]{2}$/) ||
      validateRegexp(value, /^[0-9]{2}-[0-9]{2}-[0-9]{4}$/),
  },
  {
    key: 'packerGln',
    label: 'Packer GLN',
    title: 'Packer Gln',
    defaultPreviewBeforeUploadColumn: true,
    style: { flex: '1 0 100px' },
    validate: (value: string) => validateRegexp(value, /^[0-9]{13}$/),
  },
  {
    key: 'packerName',
    label: 'Packer name',
    title: 'Packer Name',
    defaultPreviewBeforeUploadColumn: true,
    style: { flex: '1 0 100px' },
    validate: () => true,
  },

  // Packer certification
  // IFS BRC IKB KAT BLK Level
  {
    key: 'ifsPacker',
    label: 'IFS Packer',
    title: 'IFS Packer',
    shortTitle: 'P IFS',
    style: { flex: '1 0 40px' },
    handleParsedValueFromCSVFile: (value: string) => formatYesNoStringToBoolean(value),
    renderPreviewBeforeUpload: (column: BatchRecord) => booleanYesNoFormat(column.ifsPacker),
  },
  {
    key: 'brcPacker',
    label: 'BRC Packer',
    title: 'BRC Packer',
    shortTitle: 'P BRC',
    style: { flex: '1 0 40px' },
    handleParsedValueFromCSVFile: (value: string) => formatYesNoStringToBoolean(value),
    renderPreviewBeforeUpload: (column: BatchRecord) => booleanYesNoFormat(column.brcPacker),
  },
  {
    key: 'ikbPacker',
    label: 'IKB Packer',
    title: 'IKB Packer',
    shortTitle: 'P IKB',
    style: { flex: '1 0 40px' },
    handleParsedValueFromCSVFile: (value: string) => formatYesNoStringToBoolean(value),
    renderPreviewBeforeUpload: (column: BatchRecord) => booleanYesNoFormat(column.ikbPacker),
  },
  {
    key: 'katPacker',
    label: 'KAT Packer',
    title: 'KAT Packer',
    shortTitle: 'P KAT',
    style: { flex: '1 0 40px' },
    handleParsedValueFromCSVFile: (value: string) => formatYesNoStringToBoolean(value),
    renderPreviewBeforeUpload: (column: BatchRecord) => booleanYesNoFormat(column.katPacker),
  },
  {
    key: 'blkLevelPacker',
    label: 'BLK Level Packer',
    title: 'BLK Level Packer',
    shortTitle: 'P BLK Lvl',
    style: { flex: '1 0 40px' },
    handleParsedValueFromCSVFile: (value: string) => Number(value),
    renderPreviewBeforeUpload: (column: BatchRecord) => Number(column.blkLevelPacker),
  },

  // Farm certification
  // Stable description IKB KAT BLK Level
  {
    key: 'stableDescription',
    label: 'Stable description',
    title: 'Stable description',
    shortTitle: 'Stable description',
    style: { flex: '1 0 40px' },
    validate: () => true,
  },
  {
    key: 'ikbFarm',
    label: 'IKB Farm',
    title: 'IKB Farm',
    shortTitle: 'F IKB',
    style: { flex: '1 0 40px' },
    renderPreviewBeforeUpload: (column: BatchRecord) => booleanYesNoFormat(column.ikbFarm),
    validate: (value: string) => validateEnumYesNo(value),
    handleParsedValueFromCSVFile: (value: string) => formatYesNoStringToBoolean(value),
  },
  {
    key: 'katFarm',
    label: 'KAT Farm',
    title: 'KAT Farm',
    shortTitle: 'F KAT',
    style: { flex: '1 0 40px' },
    renderPreviewBeforeUpload: (column: BatchRecord) => booleanYesNoFormat(column.katFarm),
    validate: (value: string) => validateEnumYesNo(value),
    handleParsedValueFromCSVFile: (value: string) => formatYesNoStringToBoolean(value),
  },
  {
    key: 'blkLevelFarm',
    label: 'BLK Level Farm',
    title: 'BLK Level Farm',
    shortTitle: 'F BLK Lvl',
    style: { flex: '1 0 40px' },
    renderPreviewBeforeUpload: (column: BatchRecord) => Number(column.blkLevelFarm),
    validate: (value: string) => validateIsNumber(value),
    handleParsedValueFromCSVFile: (value: string) => Number(value),
  },
  {
    key: 'enrichmentPresent',
    label: 'Enrichment present? (Verrijking aanwezig)',
    title: 'Enrichment Present',
    style: { flex: '1 0 50px' },
    validate: () => true,
  },
  {
    key: 'beakUntreated',
    label: 'Beak untreated? (Snavel onbehandeld)',
    title: 'beak Untreated',
    style: { flex: '1 0 50px' },
    validate: () => true,
  },
  {
    key: 'distractionAvailable',
    label: 'Distraction available? (afleidings- materiaal)',
    title: 'Distraction Available',
    style: { flex: '1 0 50px' },
    validate: () => true,
  },
  {
    key: 'preventiefAntibioticsUsed',
    label: 'Preventief antibiotics used',
    title: 'Preventief AntibioticsUsed',
    style: { flex: '1 0 50px' },
    validate: () => true,
  },
  {
    key: 'visitorsWelcome',
    label: 'Visitors welcome in person or virtual',
    title: 'Visitors Welcome',
    style: { flex: '1 0 50px' },
    validate: () => true,
  },
  {
    key: 'nightTimeStarts',
    label: 'Night time starts',
    title: 'Night Time Starts',
    style: { flex: '1 0 50px' },
    validate: () => true,
  },
  {
    key: 'dayTimeStarts',
    label: 'Day time starts',
    title: 'Day Time Starts',
    style: { flex: '1 0 50px' },
    validate: () => true,
  },
  {
    key: 'birthDateChicken',
    label: 'Birth date chicken',
    title: 'Birth Date Chicken',
    style: { flex: '1 0 50px' },
    edit: { type: 'date' },
    handleParsedValueFromCSVFile: (value: string) =>
      formatBatchDate(
        value,
        getTheme(modelRequiredInfo.role, modelRequiredInfo.channelName).timezone,
        getTheme(modelRequiredInfo.role, modelRequiredInfo.channelName).timeFormats,
      ),
    renderPreviewBeforeUpload: (column: BatchRecord) =>
      renderShortDateFormat(column.birthDateChicken),
    validate: () => true,
  },
  {
    key: 'breedChicken',
    label: 'Breed chicken',
    title: 'Breed Chicken',
    style: { flex: '1 0 50px' },
    validate: () => true,
  },
  {
    key: 'feedConstitution',
    label: 'Feed constitution',
    title: 'Feed Constitution',
    style: { flex: '1 0 50px' },
    validate: () => true,
  },
  {
    key: 'waterIntake',
    label: 'Water intake',
    title: 'Water intake',
    style: { flex: '1 0 50px' },
    validate: () => true,
  },
  {
    key: 'feedIntake',
    label: 'Feed intake',
    title: 'Farm Intake',
    style: { flex: '1 0 50px' },
    validate: () => true,
  },
  {
    key: 'feedSupplierName',
    label: 'Feed supplier name',
    title: 'Feed Supplier Name',
    style: { flex: '1 0 50px' },
    validate: () => true,
  },
  {
    key: 'feedRtrsCertificate',
    label: 'Feed RTRS certificate',
    title: 'Farm RTRS Certificate',
    style: { flex: '1 0 50px' },
    validate: () => true,
  },
  {
    key: 'feedRtrsProvedBy',
    label: 'Feed RTRS Proved by',
    title: 'Farm RTRS Proved By',
    style: { flex: '1 0 50px' },
    validate: () => true,
  },
  {
    key: 'feedSoyReduction',
    label: 'Feed Soy reduction',
    title: 'Farm Soy Reduction',
    style: { flex: '1 0 50px' },
    validate: () => true,
  },
  {
    key: 'feedSoyReductionProvedBy',
    label: 'Feed Soy reduction Proved by',
    title: 'Feed Soy Reduction ProvedBy',
    style: { flex: '1 0 50px' },
    validate: () => true,
  },
  {
    key: 'greenEnergy',
    label: 'Green Energy',
    title: 'Green Energy',
    style: { flex: '1 0 50px' },
    validate: (value: string) => validateEnumYesNo(value),
    handleParsedValueFromCSVFile: (value: string) => formatYesNoStringToBoolean(value),
  },
  {
    key: 'batchId',
    label: 'Batch Id',
    title: 'Batch Details',
    style: { flex: '0 0 150px' },
  },

  //  createdAt, createdBy fields been taken from baseModelFields for customising

  {
    key: 'createdAt',
    label: 'createdAt',
    title: 'Batch upload date',
    style: { flex: '1 0 70px' },
    edit: { type: 'date' },
    handleParsedValueFromCSVFile: (value: string) =>
      formatBatchDate(
        value,
        getTheme(modelRequiredInfo.role, modelRequiredInfo.channelName).timezone,
        getTheme(modelRequiredInfo.role, modelRequiredInfo.channelName).timeFormats,
      ),
    renderPreviewBeforeUpload: (column: BatchRecord) => renderShortDateFormat(column.createdAt),
  },
  {
    key: 'createdBy',
    label: 'createdBy',
    title: 'Batch upload by',
    style: { flex: '1 0 50px' },
  },

  //  createdAt, createdBy fields been taken from baseModelFields for customising

  ...baseModelFields(modelRequiredInfo).filter((f) => !['createdAt', 'createdBy'].includes(f.key)),
];
