import { validationRequired } from 'lib/validations';
import batchModel from './default';
import batchModelCheckboxes from './defaultWithCheckboxes';
import { BatchRecord, ModelRequiredInfo } from 'lib/lib';
import { getCreationInfoWithDate } from '../utils/utils';

export default (modelRequiredInfo: ModelRequiredInfo, withCheckboxes = false) => {
  // @ts-ignore
  const isReseller = modelRequiredInfo.loggedInUserRole === 'RESELLER';
  const defaultBatchModel =
    withCheckboxes && isReseller
      ? batchModelCheckboxes(modelRequiredInfo)
      : batchModel(modelRequiredInfo);

  const detailsTab = {
    modelName: 'details',
    originalName: 'Details',
    title: 'Details',
  };

  const detailsModel = [
    {
      key: 'createdBy',
      label: 'Uploaded By',
      title: 'Uploaded By',
      style: { flex: '1 0 200px' },
      renderUploadPreview: (column: BatchRecord) => column.createdBy,
      render: (batchRecord: BatchRecord) => getCreationInfoWithDate(batchRecord),
      validate: [validationRequired],
      defaultSelectedColumn: true,
      defaultSelectedBatchContractColumn: true,
    },
  ];

  return {
    ...defaultBatchModel,
    tabs: [...defaultBatchModel.tabs, detailsTab],
    details: detailsModel,
  };
};
