export default () => [
  {
    id: 13961,
    is_read: false,
    created_at: '2019-09-02T15:00:31.763Z',
    event: {
      id: 2682,
      entityType: 'EGGBatch',
      entityId: '1567436429033',
      statusChangedTo: null,
      eventDoneAt: '2019-09-02T15:00:29.000Z',
      actionType: 'CREATED',
      actionSource: 'frontEndApp',
      eventType: { id: 1, title: 'Batch upload', slug: 'BatchUpload' },
      channel: { title: 'Eggs', name: 'eggs' },
      eventDoneBy: {
        username: 'EGG1 staging',
        email: 'EGG1TST@pc.t2f.io',
        org: { name: 'EGG1', fullname: 'Egg Packer 1', logo: null },
      },
    },
  },
  {
    id: 1425,
    is_read: true,
    created_at: '2019-07-18T11:56:48.945Z',
    event: {
      id: 114,
      entityType: 'CertFile',
      entityId: 'f65c67f9-0d1d-4bdc-9ba9-863712dea0a7',
      statusChangedTo: null,
      eventDoneAt: '2019-07-18T11:56:46.000Z',
      actionType: 'CREATED',
      actionSource: null,
      channel: { title: 'Eggs', name: 'eggs' },
      eventType: { id: 4, title: 'Certificate upload', slug: 'CertificateUpload' },
      eventDoneBy: {
        username: 'EGG1 staging',
        email: 'EGG1TST@pc.t2f.io',
        org: { name: 'EGG1', fullname: 'Egg Packer 1', logo: null },
      },
    },
  },
];
