import React, { useMemo } from 'react';
import AC from 'domain/AC';

import { Link } from 'react-router-dom';
import { getUrl } from 'routes';
import { CreateNewSvg } from 'components/icons';

export const UploadWidget = () => {
  const hasRights = useMemo(() => AC.USER.CAN[AC.ACTIONS.CREATE_NEW_BATCH], []);

  if (!hasRights) {
    return null;
  }

  return (
    <div className="rightPanel">
      <div className="tab">
        <Link
          to={{
            pathname: getUrl('BatchAdd', {
              modelType: 'upload',
            }),
          }}
        >
          <>
            <CreateNewSvg />
            <span>Upload Batch</span>
          </>
        </Link>
      </div>
    </div>
  );
};
