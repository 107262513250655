import defaultTab from 'models/ldcbr/tabs/defaultTabModel';
import { CHANNELS, ORGANIZATIONS } from 'themes/constants';
import archivedModelFields from 'models/archivedModelFields';
import { defaultContractSettingsAndActionsCSV } from 'models/utils/utils';
import { ModelRequiredInfo } from 'lib/lib';

export default (modelRequiredInfo: ModelRequiredInfo) => {
  const model = defaultTab(modelRequiredInfo);
  const farmName = model.find((f) => f.key === 'farmName');
  const modifiedIndexTab = model.filter((f) => f.key !== 'farmName');
  const defaultArchivedTab = [farmName, ...modifiedIndexTab, ...archivedModelFields()];
  return {
    tabs: [
      {
        modelName: 'defaultTab',
        originalName: ORGANIZATIONS.LDCBR,
        title: ORGANIZATIONS.LDCBR,
      },
    ],
    defaultTab: defaultArchivedTab,
    settingsAndActions: defaultContractSettingsAndActionsCSV(CHANNELS.ORANGES),
  };
};
