import { peek, selector } from 'lib/selectors';
import qs from 'query-string';

import {
  Batch,
  BatchConfigInfo,
  Channel,
  IndexedObject,
  MatchedRoute,
  Organization,
  PreferredBatchesByOrgRole,
  RenderModel,
  DocumentConfig,
  SerializedDocumentConfig,
  Contacts,
  ChannelsWrapper,
} from 'lib/lib';
import {
  getOwnOrgName,
  getChannelName,
  getUserOrgName,
  getRole,
  getUserOrg,
  envColumnsSelector,
  getToken,
  getChannel,
  getChannels,
} from 'domain/env';
import { CHANNELS, ORGANIZATIONS } from 'themes/constants';
import { COMMON_ENPOINTS, findMatchedOrgName, getBatchModel } from 'themes/main';
import { AppStateType } from 'types';

const common = (state: AppStateType) => state.common;

export const getOrgsAll = selector<ChannelsWrapper<Organization[]>>(common, peek('orgs'));
export const getOrgs = selector<Organization[]>(
  getOrgsAll,
  getChannelName,
  (orgs: ChannelsWrapper<Organization[]>, channelName: CHANNELS) => orgs[channelName],
);
export const getContactsAll = selector<ChannelsWrapper<Contacts>>(common, peek('contacts'));
export const getContacts = selector<Contacts>(
  getContactsAll,
  getChannelName,
  (contacts: ChannelsWrapper<Contacts>, channelName: CHANNELS) => contacts[channelName],
);
export const getDocumentConfigAll = selector<ChannelsWrapper<DocumentConfig>>(
  common,
  peek('documentConfig'),
);
export const getDocumentConfig = selector<DocumentConfig>(
  getDocumentConfigAll,
  getChannelName,
  (documentConfig: ChannelsWrapper<DocumentConfig>, channelName: CHANNELS) =>
    documentConfig[channelName],
);
export const getEventTypesAll = selector(common, peek('eventTypes'));
export const getEventTypes = selector(
  getEventTypesAll,
  getChannelName,
  (eventTypes: ChannelsWrapper<Contacts>, channelName: CHANNELS) => eventTypes[channelName],
);
export const getPreferredBatchByOrgRoleAll = selector<ChannelsWrapper<PreferredBatchesByOrgRole>>(
  common,
  peek('preferredBatchByOrgRole'),
);
export const getPreferredBatchByOrgRole = selector<PreferredBatchesByOrgRole>(
  getPreferredBatchByOrgRoleAll,
  getChannelName,
  (preferredBatchByOrgRole: ChannelsWrapper<PreferredBatchesByOrgRole>, channelName: CHANNELS) =>
    preferredBatchByOrgRole[channelName],
);
export const getBatchesAll = selector<ChannelsWrapper<BatchConfigInfo>>(common, peek('batches'));
export const getBatches = selector<BatchConfigInfo>(
  getBatchesAll,
  getChannelName,
  (batches: ChannelsWrapper<BatchConfigInfo>, channelName: CHANNELS) => batches[channelName],
);
export const getServiceDataLoading = selector<boolean>(common, peek('isLoading'));
export const getServiceDataIsLoaded = selector<boolean>(common, peek('serviceDataIsLoaded'));
export const getParams = selector<IndexedObject<string>>(common, peek('params'));
export const getParamsLocationSearch = selector(getParams, peek('location', 'search'));
export const getInternalServerError = selector(common, peek('internalServerError'));
export const getQueryParams = selector<IndexedObject<string>>(common, peek('queryParams'));
export const getMatchedRoute = selector<MatchedRoute>(common, peek('matchedRoute'));
export const getRouterParamsReceived = selector(getMatchedRoute, (route) => !!route.name);
export const getCurrentLayout = selector(
  getToken,
  getChannel,
  getChannels,
  (token: string, channel: Channel, channels: Channel[]) => {
    if (!token) {
      return 'AuthLayout';
    }

    if (!channel.id && channels.length > 1) {
      return 'ChooseChannelLayout';
    }

    if (channel.id != undefined) {
      return 'MainLayout';
    }

    return 'AuthLayout';
  },
);

export const getHierarchyByOrgName = selector<Record<ORGANIZATIONS, Batch>>(
  getOrgs,
  getPreferredBatchByOrgRole,
  getBatches,
  (
    orgs: Organization[],
    prefBatchByOrgRole: PreferredBatchesByOrgRole,
    batches: BatchConfigInfo,
  ) => {
    const orgNameMap = {};

    orgs.forEach((org) => {
      const orgName = org.name;
      const orgRole = org.role;

      orgNameMap[findMatchedOrgName(orgName)] = batches[prefBatchByOrgRole[orgRole]];
    });

    return orgNameMap;
  },
);

export const getHierarchyByOrgNameAll = selector<ChannelsWrapper<Record<ORGANIZATIONS, Batch>>>(
  getOrgsAll,
  getPreferredBatchByOrgRoleAll,
  getBatchesAll,
  (
    orgs: ChannelsWrapper<Organization[]>,
    prefBatchByOrgRole: ChannelsWrapper<PreferredBatchesByOrgRole>,
    batches: ChannelsWrapper<BatchConfigInfo>,
  ) => {
    const orgNameMap = {};
    for (const channel of Object.values(CHANNELS)) {
      const currentChannelOrgNameMap = {};
      orgs[channel].forEach((org) => {
        const orgName = org.name;
        const orgRole = org.role;

        currentChannelOrgNameMap[findMatchedOrgName(orgName)] =
          batches[channel][prefBatchByOrgRole[channel][orgRole]];
      });
      orgNameMap[channel] = currentChannelOrgNameMap;
    }

    return orgNameMap;
  },
);

export const getUrlOrg = selector(
  getOrgsAll,
  getParams,
  getUserOrg,
  getChannelName,
  (orgs, params, userOrg, currentChannelName) => {
    const channelName = Object.values(CHANNELS).includes(params.channelName)
      ? (params.channelName as CHANNELS)
      : currentChannelName;

    try {
      const orgname = params.type;

      return orgs[channelName].find((f) => f.name.toLowerCase() === orgname.toLowerCase());
    } catch (e) {
      return orgs[channelName].find((f) => f.id === userOrg.id);
    }
  },
);

export const getUrlOrgAll = selector(getOrgsAll, getParams, getUserOrg, (orgs, params, userOrg) => {
  const orgname = params.type;
  const urlOrg = {};
  for (const channel of Object.values(CHANNELS)) {
    try {
      urlOrg[channel] = orgs[channel].find((f) => f.name.toLowerCase() === orgname.toLowerCase());
    } catch (e) {
      urlOrg[channel] = orgs[channel].find((f) => f.id === userOrg.id);
    }
  }
  return urlOrg;
});

export const getUrlOrgName = selector<ORGANIZATIONS>(getUrlOrg, peek('name'));
export const getUrlOrgRole = selector<string>(getUrlOrg, peek('role'));

export const getBatchTypeByOrgRole = selector<Batch>(
  getUrlOrgRole,
  getPreferredBatchByOrgRole,
  (urlOrgRole: string, prefferedBatchesInfo: PreferredBatchesByOrgRole) =>
    prefferedBatchesInfo[urlOrgRole],
);

export const getBatchTypeByOrgRoleAll = selector<ChannelsWrapper<Batch>>(
  getUrlOrgAll,
  getPreferredBatchByOrgRoleAll,
  (
    urlOrgRole: ChannelsWrapper<string>,
    prefferedBatchesInfo: ChannelsWrapper<PreferredBatchesByOrgRole>,
  ) => {
    const batchTypes = {};
    for (const channel of Object.values(CHANNELS)) {
      const role = urlOrgRole[channel] && urlOrgRole[channel].role;
      batchTypes[channel] = prefferedBatchesInfo[channel][role];
    }
    return batchTypes;
  },
);

export const getBatchInfoByUrlOrgRole = selector<Batch>(
  getBatchTypeByOrgRole,
  getBatches,
  (batchType: string, batches: BatchConfigInfo) => batches[batchType],
);

export const getBatchInfoByUrlOrgRoleAll = selector<ChannelsWrapper<Batch>>(
  getBatchTypeByOrgRoleAll,
  getBatchesAll,
  (batchType: ChannelsWrapper<string>, batches: ChannelsWrapper<BatchConfigInfo>) => {
    const batchInfo = {};
    for (const channel of Object.values(CHANNELS)) {
      batchInfo[channel] = batches[channel][batchType[channel]] || {};
    }
    return batchInfo;
  },
);

export const getIndexEndpoint = selector<string>(
  getBatchInfoByUrlOrgRoleAll,
  getParams,
  getChannelName,
  getUrlOrgName,
  (batchInfoAll, params, currentChannelName, orgName) => {
    const commonEndpoint = COMMON_ENPOINTS[orgName];
    if (commonEndpoint) {
      return commonEndpoint;
    }
    const channelName = params.channelName || currentChannelName;
    const batchInfo = batchInfoAll[channelName];
    return batchInfo.endpoint;
  },
);

export const getModel = selector<RenderModel>(
  getOwnOrgName,
  getChannelName,
  getParams,
  getHierarchyByOrgNameAll,
  getRole,
  getQueryParams,
  getMatchedRoute,
  getUrlOrgName,
  (
    orgName: ORGANIZATIONS,
    currentChannelName: CHANNELS,
    params: Record<string, string>,
    hierarchyByOrgNameAll: ChannelsWrapper<Record<ORGANIZATIONS, Batch>>,
    loggedInUserRole: ORGANIZATIONS,
    queryParams: Record<string, string>,
    route: MatchedRoute,
    UrlOrgName: ORGANIZATIONS,
  ) => {
    const channelName = (params.channelName as CHANNELS) || currentChannelName;
    const modelParams = { ...queryParams, ...params };
    modelParams.type = route.modelType!;
    modelParams.orgName = orgName;
    return getBatchModel(
      UrlOrgName,
      channelName,
      modelParams,
      hierarchyByOrgNameAll,
      loggedInUserRole,
    );
  },
);

export const getColumnsKey = selector<string>(
  getMatchedRoute,
  getChannelName,
  getUserOrgName,
  getUrlOrgName,
  getQueryParams,
  getParams,
  (route, currentChannelName, ownOrgName, linkedOrgName, queryParams, params) => {
    const { userSettingsKey } = route;

    if (!userSettingsKey) {
      return null;
    }

    const channelName = (params.channelName as CHANNELS) || currentChannelName;

    return userSettingsKey({
      channelName,
      linkedOrgName,
      ownOrgName,
      queryParams,
    });
  },
);

export const getDisplayModel = selector<RenderModel>(
  getModel,
  envColumnsSelector,
  getColumnsKey,
  getMatchedRoute,
  (model, columnsConfigs, columnsKey, route) => {
    try {
      const currentConfigObject = columnsConfigs.find((f) => f.viewName === columnsKey);
      const config = currentConfigObject && currentConfigObject.config;
      const defaultColumn = route.userColumnsType || 'defaultSelectedColumn';
      const tabKeys = model.tabs.map(({ modelName }) => modelName);

      return tabKeys.reduce(
        (currentValue, tabKey) => ({
          ...currentValue,
          [tabKey]: model[tabKey].filter(
            (f) =>
              f.isServiceField ||
              (config && Array.isArray(config[tabKey])
                ? config[tabKey].includes(f.key)
                : f[defaultColumn]),
          ),
        }),
        { tabs: model.tabs },
      );
    } catch (e) {
      console.log('theres been a problem with evaluating displayModel', e);
      return model;
    }
  },
);

export const getUrlQueryString = selector<string>(
  getParams,
  (params: IndexedObject<IndexedObject<string>>): string => {
    const { location } = params;

    return location && location.search;
  },
);

export const getParsedQueryStringParams = selector(getUrlQueryString, (urlQuery: string) =>
  qs.parse(urlQuery),
);

const handShakeOrganizationModel = selector(
  getUrlOrgName,
  getChannelName,
  getHierarchyByOrgNameAll,
  getRole,
  (
    orgName: ORGANIZATIONS,
    channelName: CHANNELS,
    hierarchy: IndexedObject<string>,
    loggedByOrgName: ORGANIZATIONS,
  ) => getBatchModel(orgName, channelName, {}, hierarchy, loggedByOrgName),
);

const mapToParamsTitles = selector(
  getParsedQueryStringParams,
  handShakeOrganizationModel,
  (qsParams: IndexedObject<any>, handshakedOrgModel) => {
    const resultFields = {};
    /* we assume handshake has only default tab TODO: adopt this selector to work on each route */
    try {
      const { tabs } = handshakedOrgModel;
      const modelNames = tabs.map(({ modelName }) => modelName);

      modelNames.forEach((modelName) => {
        handshakedOrgModel[modelName].forEach(({ key, title }) => {
          if (qsParams[key]) {
            resultFields[title] = qsParams[key];
          }
        });
      });

      if (Object.keys(resultFields).length > 0) {
        return resultFields;
      } else {
        return qsParams;
      }
    } catch {
      return qsParams;
    }
  },
);

export const getTitleParamsAsString = selector<string>(
  mapToParamsTitles,
  (titlesObject: IndexedObject<string>) => {
    try {
      const readableParams = Object.entries(titlesObject)
        .map(([key, value]) => `${key} - ${value}`)
        .join('; ');

      return `Batches: ${readableParams}`;
    } catch {
      return `Batches:`;
    }
  },
);

export const getSerializedDocumentConfig = selector<SerializedDocumentConfig>(
  getDocumentConfig,
  (documentConfig: DocumentConfig) => {
    try {
      const { documentCategories } = documentConfig;
      return documentCategories.reduce(
        (accumulator0, currentValue0) => ({
          ...accumulator0,
          [currentValue0.label]: {
            ...currentValue0,
            documentTypes: currentValue0.documentTypes
              ? currentValue0.documentTypes.reduce(
                  (accumulator1, currentValue1) => ({
                    ...accumulator1,
                    [currentValue1.label]: {
                      ...currentValue1,
                      documentResults: currentValue1.documentResults
                        ? currentValue1.documentResults.reduce(
                            (accumulator2, currentValue2) => ({
                              ...accumulator2,
                              [currentValue2.label]: currentValue2,
                            }),
                            {},
                          )
                        : {},
                      documentScopes: currentValue1.documentScopes
                        ? currentValue1.documentScopes.reduce(
                            (accumulator2, currentValue2) => ({
                              ...accumulator2,
                              [currentValue2.label]: currentValue2,
                            }),
                            {},
                          )
                        : {},
                    },
                  }),
                  {},
                )
              : {},
          },
        }),
        {},
      );
    } catch (e) {
      console.error(`Document config could not be serialized. Error: ${JSON.parse(e)}`);
      return {};
    }
  },
);
