export default [
  {
    key: 'ProducerProcessBatch_floId',
    label: 'Fyffes FLO-ID',
    title: 'Fyffes FLO-ID',
    style: { flex: '1 0 60px' },
    defaultSelectedColumn: true,
    render: (column: any) => column.ProducerProcessBatch_floId || 'null',
    renderExportValue: (column: any) => column.ProducerProcessBatch_floId || 'null',
  },
  {
    key: 'DistributorInslagBatch_SSCCCode',
    label: 'SSCCCode Hollander',
    title: 'SSCCCode Hollander',
    style: { flex: '1 0 100px' },
    defaultSelectedColumn: true,
    render: (column: any) => column.DistributorInslagBatch_SSCCCode || 'null',
    renderExportValue: (column: any) => column.DistributorInslagBatch_SSCCCode || 'null',
  },
  {
    key: 'DistributorInslagBatch_batchNumber',
    label: 'Batch Number (Hollander IN)',
    title: 'Batch Number (Hollander IN)',
    style: { flex: '1 0 80px' },
    defaultSelectedColumn: true,
    render: (column: any) => column.DistributorInslagBatch_batchNumber || 'null',
    renderExportValue: (column: any) => column.DistributorInslagBatch_batchNumber || 'null',
  },
  {
    key: 'DistributorUitslagBatch_batchNumber',
    label: 'Batch Number (Hollander OUT)',
    title: 'Batch Number (Hollander OUT)',
    style: { flex: '1 0 80px' },
    defaultSelectedColumn: true,
    render: (column: any) => column.DistributorUitslagBatch_batchNumber || 'null',
    renderExportValue: (column: any) => column.DistributorUitslagBatch_batchNumber || 'null',
  },
];
