import React, { useMemo } from 'react';
import { Redirect, Route, Switch } from 'react-router';

import { routes } from './routes';

import * as layouts from 'pages/layouts';
import ErrorModal from 'components/Modals/ErrorModal';
import { AppStateType } from 'types';
import { getChannelName, getOwnOrgName } from 'domain/env';
import { CHANNELS, ORGANIZATIONS } from 'themes/constants';
import { shallowEqual, useSelector } from 'react-redux';

interface OwnProps {
  layout: keyof typeof routes;
}

const isUnderConstructionSelector = (state: AppStateType) =>
  getOwnOrgName(state) === ORGANIZATIONS.SIM && getChannelName(state) === CHANNELS.JUICE; // TODO: remove

export const LayoutRoutes: React.FC<OwnProps> = React.memo(({ layout }) => {
  const layoutRoutes = routes[layout];

  const isUnderConstruction = useSelector(isUnderConstructionSelector, shallowEqual); // TODO: remove

  const availableRoutes = useMemo(() => {
    const avR = layoutRoutes
      .filter((f) => (f.checkACL ? f.checkACL() : true))
      // .filter((f) => !(isUnderConstruction && f.path === '/batches')) // TODO: remove this line
      .map((route) => (
        <Route
          path={route.path}
          component={route.component}
          exact={route.exact}
          key={route.path}
          name={route.name}
        />
      ));

    const redirectTo = layoutRoutes.find((route) => route.default);

    if (isUnderConstruction) {
      return avR.concat(<Redirect to="/certificates" key="redirect" />); // TODO: remove
    }

    if (redirectTo) {
      avR.push(<Redirect to={redirectTo.path} key="redirect" />);
    }

    return avR;
  }, [layout, isUnderConstruction]);

  const LayoutWrapper = layouts[layout];

  return (
    <LayoutWrapper>
      <Switch>{availableRoutes}</Switch>
      <ErrorModal />
    </LayoutWrapper>
  );
});
