export default [
  {
    $class: 'org.powerchain.batch.AHBatch',
    gtin: '08718906150690',
    glnSupplier: '8711900009995',
    glnBuyer: '8710400000198',
    glnDeliveryLocation: '8710400000198',
    quantity: 120,
    deliveryDatetime: '2018-09-20T17:01:41.000Z',
    batchnumber: '1708008',
    batchId: '792a7d63-b84d-4900-aaa5-7b9cb3da93ba',
    createdAt: '2019-08-14T09:37:11.000Z',
    createdBy: 'resource:org.powerchain.member.AHUser#AH_affef56f-d628-4fb9-86f3-e91d16f46ade',
    status: 'PENDING',
    uploadSource: 'api',
    channelName: 'oranges',
    modifiedBy_participant: {
      username: null,
      email: null,
      participant:
        'org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8',
      org: {
        name: 'Fyffes',
        fullname: 'Fyffes',
        logo: null,
      },
      channel: {
        title: 'Bananas',
        name: 'bananas',
      },
      user: {
        username: 'Fyffes TST staging',
        email: 'FyffesTST@pc.t2f.io',
        org: {
          name: 'Fyffes',
          fullname: 'Fyffes',
          logo: null,
        },
      },
    },
    createdBy_participant: {
      username: null,
      email: null,
      participant:
        'org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8',
      org: {
        name: 'Fyffes',
        fullname: 'Fyffes',
        logo: null,
      },
      channel: {
        title: 'Bananas',
        name: 'bananas',
      },
      user: {
        username: 'Fyffes TST staging',
        email: 'FyffesTST@pc.t2f.io',
        org: {
          name: 'Fyffes',
          fullname: 'Fyffes',
          logo: null,
        },
      },
    },
  },
  {
    $class: 'org.powerchain.batch.AHBatch',
    gtin: '08718906254527',
    glnSupplier: '8711900009995',
    glnBuyer: '8710400000822',
    glnDeliveryLocation: '8710400000822',
    quantity: 104,
    deliveryDatetime: '2018-08-29T00:00:00.000Z',
    batchnumber: '1748999',
    batchId: '8fec7c17-3b9d-49fa-8fb2-fd406079c200',
    createdAt: '2019-08-13T13:23:53.000Z',
    createdBy: 'resource:org.powerchain.member.AHUser#AH_aeee94af-494b-4cdf-92b3-3f58c6b162ce',
    status: 'PENDING',
    uploadSource: 'frontEndApp',
    channelName: 'oranges',
    modifiedBy_participant: {
      username: null,
      email: null,
      participant:
        'org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8',
      org: {
        name: 'Fyffes',
        fullname: 'Fyffes',
        logo: null,
      },
      channel: {
        title: 'Bananas',
        name: 'bananas',
      },
      user: {
        username: 'Fyffes TST staging',
        email: 'FyffesTST@pc.t2f.io',
        org: {
          name: 'Fyffes',
          fullname: 'Fyffes',
          logo: null,
        },
      },
    },
    createdBy_participant: {
      username: null,
      email: null,
      participant:
        'org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8',
      org: {
        name: 'Fyffes',
        fullname: 'Fyffes',
        logo: null,
      },
      channel: {
        title: 'Bananas',
        name: 'bananas',
      },
      user: {
        username: 'Fyffes TST staging',
        email: 'FyffesTST@pc.t2f.io',
        org: {
          name: 'Fyffes',
          fullname: 'Fyffes',
          logo: null,
        },
      },
    },
  },
];
