import { Theme } from 'lib/lib';

export default (theme: Theme) => ({
  component: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    width: '100%',
    height: '100%',
    padding: ['2em', 0],
    '& .logout': {
      display: 'inline-flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      position: 'absolute',
      cursor: 'pointer',
      top: '3em',
      left: 'auto',
      right: '2em',
      '& svg': {
        margin: [0, '0.5em'],
      },
      '& span': {
        fontSize: theme.fontSizes.md,
      },
    },
    '& > .channels': {
      display: 'flex',
      '& > .channel': {
        width: '15em',
        margin: [0, '2em'],
        userSelect: 'none',
        '& > .title': {
          textAlign: 'center',
        },
      },
    },
  },
  btn: { ...theme.components.btn },
});
