import React from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import LoadingWaiter from '../Loaders/LoadingWaiter';
import Loader from 'components/Loaders/smallLoader';

const SimpleTabsFrameStyles = (theme) => ({
  title: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
    backgroundColor: theme.colors.lightGrey,
    color: theme.colors.white,
    margin: 0,
    padding: 0,
    borderRadius: [10, 10, 0, 0],

    '& .tabs': {
      display: 'inline-flex',
      alignItems: 'flex-end',
      textDecoration: 'none',
      '& span': {
        font: {
          size: theme.fontSizes.sm,
          family: 'Lato',
          weight: 400,
        },
        color: theme.colors.white,
      },
      '& .tab': {
        display: 'inline-flex',
        flexDirection: 'row',
        alignItems: 'center',
        backgroundColor:
          theme.colors.primaryHover === theme.colors.accent
            ? theme.colors.primary
            : theme.colors.primaryHover,
        color: theme.colors.white,
        margin: 0,
        padding: [10, 10],
        borderRadius: [10, 10, 0, 0],
        transition: 'background-color 0.5s ease',
        textDecoration: 'none',
        cursor: 'pointer',
        '&.active': {
          filter: 'brightness(120%)',
          paddingBottom: 11,
        },
        '& span': {
          font: {
            size: theme.fontSizes.sm,
            family: 'Lato',
            weight: 400,
          },
          color: theme.colors.white,
        },
        '&.sim': {
          backgroundColor: theme.colors.redD,
          paddingLeft: 20,
          paddingRight: 20,
        },
      },
    },
    '& .headPanel': {
      display: 'inline-flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      height: 38,
      cursor: 'pointer',
      '& > a, & > button': {
        display: 'inline-flex',
        height: 38,
        alignItems: 'center',
        justifyContent: 'center',
        color: theme.colors.black,
        margin: [0, '0.5em'],
        backgroundColor: 'transparent',
        border: 0,
        outline: 0,
        cursor: 'pointer',
        font: {
          size: theme.fontSizes.md,
        },
        '& span': {
          display: 'inline-flex',
          alignItems: 'center',
          justifyContent: 'center',
        },
        '& svg': {
          marginLeft: '0.5em',
        },
      },
      '& > .btn': {
        ...theme.components.btn,
        padding: [4, 15],
        height: 'auto',
        backgroundColor: theme.colors.primary,
        '&:hover': {
          backgroundColor: theme.colors.primaryHover,
        },
      },
    },
  },
  body: {
    backgroundColor: theme.colors.background,
    color: theme.colors.white,
    margin: 0,
    padding: 0,
    borderRadius: 0,
    transition: 'background-color 0.5s ease',
    textDecoration: 'none',
  },
  loader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '3em',
    '& .spinner.spinner-1:before': {
      borderRightColor: theme.colors.black,
      borderBottomColor: theme.colors.black,
    },
    '& .spinner.spinner-1:after': {
      borderRightColor: theme.colors.black,
      borderBottomColor: theme.colors.black,
    },
  },
});

class SimpleTabsFrame extends React.PureComponent {
  static propTypes = {
    tabs: PropTypes.array.isRequired,
    classes: PropTypes.object.isRequired,
    headPanel: PropTypes.array,
    isLoading: PropTypes.bool,
  };

  static defaultProps = {
    headPanel: [],
  };

  state = {
    activeTab: 0,
  };

  changeTab = (tabIndex) => () => {
    this.setState({ activeTab: tabIndex });
  };

  render() {
    const { tabs, classes, headPanel, isLoading } = this.props;
    const { activeTab } = this.state;

    return (
      <div>
        <div className={classes.title}>
          <div className="tabs">
            {!isLoading &&
              tabs.map((t, tabIndex) => (
                <div
                  key={t.title}
                  onClick={this.changeTab(tabIndex)}
                  className={`tab ${t.className} ${tabIndex === activeTab ? 'active' : ''}`}
                  style={t.style}
                >
                  {t.title}
                </div>
              ))}
          </div>
          <div className="headPanel">{headPanel.map((item) => item.icon)}</div>
        </div>
        <div className={classes.body}>{!isLoading && tabs[activeTab].body}</div>
        {isLoading && (
          <div className={classes.loader}>
            <Loader />
          </div>
        )}
      </div>
    );
  }
}

export default injectSheet(SimpleTabsFrameStyles)(SimpleTabsFrame);
