import React, { useState } from 'react';
// styles
import injectSheet, { WithStyles } from 'react-jss';
import dashboardNewStyles from 'pages/dashboard/styles/dashboardNewStyles';
// components
import DashboardHeader from 'pages/dashboard/components/Header/Header';
// types
import UnmatchedBatchesList from 'pages/dashboard/components/BatchesList/UnmatchedBatchesList';
import Statistics from 'pages/dashboard/components/Statistics/Statistics';

const tabs = ['Batches list', 'Statistics'];

const Dashboard: React.FC<WithStyles<typeof dashboardNewStyles>> = (props) => {
  const [activeTab, setActiveTab] = useState(tabs[1]);
  const [mismatchesNumber, setMismatchesNumber] = useState(0);
  const { classes } = props;

  return (
    <div className={classes.dashboard}>
      <div className={classes.tabs}>
        {tabs.map((item) => (
          <button
            key={item}
            className={`${classes.tab}${activeTab === item ? ' active' : ''}`}
            onClick={() => {
              if (activeTab !== item) {
                setActiveTab(item);
              }
            }}
          >
            {item}
          </button>
        ))}
      </div>
      <DashboardHeader mismatchesNumber={mismatchesNumber} />
      <div className={classes.componentBody}>
        <UnmatchedBatchesList
          mismatchesNumber={mismatchesNumber}
          setMismatchesNumber={setMismatchesNumber}
          isActive={activeTab === tabs[0]}
        />
        <Statistics
          mismatchesNumber={mismatchesNumber}
          setMismatchesNumber={setMismatchesNumber}
          isActive={activeTab === tabs[1]}
        />
      </div>
    </div>
  );
};

export default injectSheet(dashboardNewStyles)(Dashboard);
