import contractTabModel from 'models/citrosucoGhent/tabs/contractTabModel';
import { CHANNELS, ORGANIZATIONS } from 'themes/constants';
import archivedModelFields from 'models/archivedModelFields';
import { defaultContractSettingsAndActionsExcel } from 'models/utils/utils';
import { ModelRequiredInfo } from 'lib/lib';

export default (modelRequiredInfo: ModelRequiredInfo) => {
  const contractArchivedTabModel = [
    ...contractTabModel(modelRequiredInfo),
    ...archivedModelFields(false),
  ];
  return {
    tabs: [
      {
        modelName: 'defaultTab',
        originalName: ORGANIZATIONS.CITROSUCOGHENT,
        title: ORGANIZATIONS.CITROSUCOGHENT,
      },
    ],
    defaultTab: contractArchivedTabModel,
    settingsAndActions: defaultContractSettingsAndActionsExcel(CHANNELS.JUICE),
  };
};
