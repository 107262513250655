import moment from 'moment';

export const shortDateFormat = 'D MMM YYYY';

export const renderShortDateFormat = (fullTimeString = '') => {
  const timeObj = moment(fullTimeString);
  return timeObj.isValid() ? timeObj.format(shortDateFormat) : fullTimeString;
};
export const renderTwoShortDateFormat = (date1 = null, date2 = null) => {
  const timeObj1 = moment(date1);
  const timeObj2 = moment(date2);

  return `${timeObj1.isValid() ? timeObj1.format('D MMM') : date1} - ${
    timeObj2.isValid() ? timeObj2.format('D MMM YYYY') : date2
  }`;
};

export const renderFullDateFormat = (fullTimeString = '') => {
  const timeObj = moment(fullTimeString);
  return timeObj.isValid() ? timeObj.format('hh:mm:ss, D MMM YYYY') : fullTimeString;
};

export const booleanYesNoFormat = (booleanVariable = false) => (booleanVariable ? 'YES' : 'NO');

export const validate = (value, validatorsArray) =>
  validatorsArray.map((validator) => validator(value)).every(({ status }) => status === true);

export const customDatePreview = (date, validatorsArray) => {
  const isValid = validate(date, validatorsArray);
  const dateToRender = moment(date, moment.ISO_8601, true);
  if (isValid && dateToRender.isValid()) {
    return dateToRender.format(shortDateFormat);
  }

  return date;
};

const customDates = (value, format) => {
  const mddyyyy = format.replace('MM', 'M');
  const mmdyyyy = format.replace('DD', 'D');
  const mdyyyy = format.replace('MM', 'M').replace('DD', 'D');
  const mmddyy = format.replace('YYYY', 'YY');
  const mddyy = mddyyyy.replace('YYYY', 'YY');
  const mmdyy = mmdyyyy.replace('YYYY', 'YY');
  const mdyy = mdyyyy.replace('YYYY', 'YY');
  if (moment(value, mddyyyy, true).isValid()) return moment.utc(value, mddyyyy, true);
  if (moment(value, mmdyyyy, true).isValid()) return moment.utc(value, mmdyyyy, true);
  if (moment(value, mdyyyy, true).isValid()) return moment.utc(value, mdyyyy, true);
  if (moment(value, mmddyy, true).isValid()) return moment.utc(value, mmddyy, true);
  if (moment(value, mddyy, true).isValid()) return moment.utc(value, mddyy, true);
  if (moment(value, mmdyy, true).isValid()) return moment.utc(value, mmdyy, true);
  if (moment(value, mdyy, true).isValid()) return moment.utc(value, mdyy, true);
  return moment.utc(value, format, true);
};

export const formatDateToISO = (value, format) => {
  const date = customDates(value, format);
  return date.isValid() ? date.format() : String(value || '');
};

export const brazilTimeShift = (value, format) => {
  const date = customDates(value, format);
  return date.isValid() ? date.set('hours', 12).format() : String(value || '');
};

export const defineExpireColor = (expirationDate, status) => {
  const expires = moment(expirationDate);
  const now = moment();

  if (status === 'canceled' || status === 'expired') {
    return 'red';
  } else if (status === 'accepted') {
    return 'black';
  } else {
    return expires.isAfter(now) ? 'green' : 'red';
  }
};
