import React from 'react';
import ModelSettingsDropdown from 'components/Dropdown/ModelSettingsDropDown';
import ApproveContractButton from 'models/utils/ApproveContractButton/ApproveContractButton';
import { BatchRecord } from 'lib/lib';

export default {
  key: 'settingsAndActions',
  title: <ModelSettingsDropdown />,
  style: { flex: '0 1 100px', marginLeft: 'auto' },
  render: (column: BatchRecord) => (
    <React.Fragment>
      <ApproveContractButton column={column} />
    </React.Fragment>
  ),
};
