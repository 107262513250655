import profileResponse from 'mocks/responses/profile';
import {getUserFromRequest, UserNotFountError} from "../../../usersUtils";
import {MocksUser} from "../../../users";

export default (request: any) => {
  const user: MocksUser | UserNotFountError = getUserFromRequest(request);

  if (user.error) {
    return [500, user.error];
  }

  return [200, profileResponse(user.orgName)];
};
