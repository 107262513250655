import React from 'react';
import { extractUploaderName, getIconByOrgName } from 'models/certificates/utils';
import { renderShortDateFormat } from 'lib/renderHelpers';
import { getNameFromBlockchainString } from 'lib/helpers';
import { Certificate } from 'lib/lib';

export default [
  {
    title: '',
    key: 'certIcon',
    style: { flex: '0 0 50px' },
    isServiceField: true,
    render: (document: Certificate) => (
      <img src={getIconByOrgName(document.documentType)} alt="icon" />
    ),
  },
  {
    title: 'ID',
    key: 'certId',
    defaultSelectedColumn: true,
    style: { flex: '2 0 60px' },
    render: (document: Certificate) => document.certId || 'N/A',
  },
  {
    title: 'Document Type',
    key: 'documentType',
    defaultSelectedColumn: true,
    style: { flex: '1 0 50px' },
    render: (document: Certificate) => document.documentType,
  },
  {
    title: 'Document Number',
    key: 'documentNumber',
    style: { flex: '1 0 70px' },
    render: (document: Certificate) => document.documentNumber,
  },
  {
    title: 'Audit date',
    titleSIM: 'SIM verified date',
    key: 'auditDate',
    style: { flex: '1 0 50px' },
    render: (document: Certificate) => renderShortDateFormat(document.auditDate),
  },
  {
    title: 'Entity Certified',
    key: 'entityCertified',
    defaultSelectedColumn: true,
    style: { flex: '1 0 50px' },
    render: (document: Certificate) => document.entityCertified,
  },
  {
    title: 'Entity Id',
    key: 'entityId',
    style: { flex: '1 0 50px' },
    render: (document: Certificate) => document.entityId,
  },
  {
    title: 'Valid from',
    key: 'from',
    style: { flex: '1 0 50px' },
    render: (document: Certificate) => renderShortDateFormat(document.from),
  },
  {
    title: 'Valid until',
    key: 'until',
    style: { flex: '1 0 50px' },
    render: (document: Certificate) => renderShortDateFormat(document.until),
  },
  {
    title: 'Valid from - until',
    key: 'fromUntil',
    defaultSelectedColumn: true,
    style: { flex: '1 0 50px' },
    render: (document: Certificate) =>
      `${renderShortDateFormat(document.from)} - ${renderShortDateFormat(document.until)}`,
  },
  {
    title: 'Uploaded by',
    key: 'uploadedBy',
    style: { flex: '1 0 50px' },
    render: (document: Certificate) => extractUploaderName(document),
  },
  {
    title: 'Uploaded at',
    key: 'uploadedAt',
    defaultSelectedColumn: true,
    style: { flex: '1 0 80px' },
    isSortable: true,
    render: (document: Certificate) => renderShortDateFormat(document.uploadedAt),
  },
  {
    title: 'Notes',
    key: 'notes',
    style: { flex: '1 0 50px' },
    render: (document: Certificate) => document.notes,
  },
  {
    title: 'Approve Msg',
    key: 'approveMsg',
    style: { flex: '1 0 50px' },
    render: (document: Certificate) => document.approveMsg,
  },
  {
    title: 'Approve Reason',
    key: 'approveReason',
    defaultSelectedColumn: true,
    style: { flex: '1 0 50px' },
    render: (document: Certificate) => document.approveReason,
  },
  {
    title: 'Approved At',
    titleSIM: 'Approved At',
    key: 'approvedAt',
    style: { flex: '1 0 50px' },
    render: (document: Certificate) => renderShortDateFormat(document.approvedAt),
  },
  {
    title: 'Approved By',
    key: 'approvedBy',
    style: { flex: '1 0 50px' },
    render: (document: Certificate) => getNameFromBlockchainString(document.approvedBy),
  },
];
