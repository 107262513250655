export default (theme) => ({
  component: {
    padding: ['2em', 0],

    '& .uploadCSVsTable': {
      display: 'block',
      width: 505,
      margin: [0, 'auto', '3em'],
      '& > .tableHead, & > .tableRow': {
        display: 'flex',
        justifyContent: 'space-between',
        textAlign: 'center',
        '& > div': {
          border: [1, 'solid', theme.colors.background],
          padding: 3,
          overflow: 'hidden',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        },
        '& > .fileName': {
          flex: '0 0 270px',
          display: 'flex-index',
          minHeight: 28,
        },
        '& > .fileSize': {
          flex: '0 0 135px',
          display: 'flex-index',
          minHeight: 28,
        },
        '& > .fileOptions': {
          flex: '0 0 100px',
          display: 'flex-index',
          width: 24,
          minHeight: 28,
          justifyContent: 'space-evenly',
          '& svg': {
            fill: theme.colors.primary,
            width: 24,
            cursor: 'pointer',
          },
        },
      },
      '& > .tableHead': {
        backgroundColor: 'rgb(102, 102, 102)',
        color: theme.colors.white,
        fontSize: theme.fontSizes.sm,
      },
      '& > .tableRow': {
        '&.success': {
          color: 'green',
        },
        '&.failed': {
          color: 'red',
        },
        '&:nth-child(odd)': {
          backgroundColor: theme.colors.background,
        },
      },
    },
  },
  batchAdd: {
    textAlign: 'center',
  },
  form: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    flexDirection: 'column',
  },
  formTitle: {
    width: '100%',
    marginBottom: '3em',
    textAlign: 'center',
    '& > a': {
      display: 'inline-flex',
      alignItems: 'center',
      padding: '1em',
      '& svg': {
        fill: theme.colors.primary,
      },
      '&:hover svg': {
        fill: theme.colors.primaryHover,
      },
      '& span': {
        marginLeft: '0.5em',
      },
    },
    '& .formTitleLink': {
      color: theme.colors.link,
    },
  },

  uploadCSV: {
    display: 'flex',
    alignItems: 'center',
    width: 'auto',
    border: '2px dashed rgb(143, 143, 143)',
    borderRadius: 10,
    padding: 20,
    textAlign: 'center',
    cursor: 'pointer',
    margin: [0, 'auto', '3em'],
    '& .upload-place': {
      margin: [0, 'auto'],
      '& svg': {
        width: 40,
        fill: 'rgb(143, 143, 143)',
      },
      '& .text': {
        font: {
          size: 10,
          weight: 200,
        },
        color: 'rgb(143, 143, 143)',
      },
    },
    '&.success': {
      border: '2px dashed green',
      '& .upload-place svg': {
        fill: 'green',
      },
      '& .upload-place .text': {
        color: 'green',
      },
    },
    '&.failed': {
      border: '2px dashed red',
      '& .upload-place svg': {
        fill: 'red',
      },
      '& .upload-place .text': {
        color: 'red',
      },
    },
  },

  submitBtn: {
    ...theme.submitBtn,
    backgroundColor: 'rgb(58, 85, 108)',
    color: theme.colors.white,
    cursor: 'pointer',
  },

  uploadFile: {},
  mainInfo: {
    ...theme.pageTitle,
  },
  right: {
    float: 'right',
  },
  addBatch: {
    position: 'relative',
    top: 50,
    marginBottom: 50,
    padding: 15,
    '& .mt-1': {
      marginTop: 30,
    },
    '& $uploadFile': {
      textAlign: 'center',
      marginBottom: 16,
      '& *:hover': {
        color: `${theme.colors.black} !important`,
      },
      '& img': {
        width: 70,
        height: 70,
      },
    },
  },
});
