import defaultTabModel from 'models/mayonna/tabs/defaultTabModel';
import { CHANNELS, ORGANIZATIONS } from 'themes/constants';
import { defaultContractSettingsAndActionsExcel } from 'models/utils/utils';
import { ModelRequiredInfo } from 'lib/lib';

export default (modelRequiredInfo: ModelRequiredInfo) => ({
  tabs: [
    {
      modelName: 'defaultTab',
      originalName: ORGANIZATIONS.MAYONNA,
      title: ORGANIZATIONS.MAYONNA,
    },
  ],
  defaultTab: defaultTabModel(modelRequiredInfo),
  settingsAndActions: defaultContractSettingsAndActionsExcel(CHANNELS.TILAPIA),
});
