export default () => [
  {
    id: 12047,
    is_read: false,
    created_at: '2019-08-28T13:54:42.864Z',
    event: {
      id: 2375,
      entityType: 'DistributorBatch',
      entityId: '1863505',
      statusChangedTo: null,
      eventDoneAt: '2019-08-28T13:54:39.000Z',
      actionType: 'CREATED',
      actionSource: 'frontEndApp',
      channel: { title: 'Bananas', name: 'bananas' },
      eventType: { id: 1, title: 'Batch upload', slug: 'BatchUpload' },
      eventDoneBy: {
        username: 'Hollander TST staging',
        email: 'HollanderTST@pc.t2f.io',
        org: { name: 'Hollander', fullname: 'Hollander', logo: null },
      },
    },
  },
  {
    id: 10287,
    is_read: false,
    created_at: '2019-08-23T12:28:12.099Z',
    event: {
      id: 2009,
      entityType: 'CertFile',
      entityId: 'be612fcc-38d7-43af-4ac6-d32326709294',
      statusChangedTo: null,
      eventDoneAt: '2019-08-23T12:28:09.000Z',
      actionType: 'CREATED',
      actionSource: null,
      channel: { title: 'Bananas', name: 'bananas' },
      eventType: { id: 4, title: 'Certificate upload', slug: 'CertificateUpload' },
      eventDoneBy: {
        username: 'Hollander TST staging',
        email: 'HollanderTST@pc.t2f.io',
        org: { name: 'Hollander', fullname: 'Hollander', logo: null },
      },
    },
  },
];
