export default (theme) => ({
  dataTableHead: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: 0,
    backgroundColor: theme.colors.backgroundTableHead,
    borderRadius: [0, 10, 0, 0],
    textAlign: 'left',
    font: {
      size: theme.fontSizes.sm,
    },
    '& > div': {
      display: 'flex',
      flexGrow: 1,
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
      padding: [10, 0],
      color: theme.colors.white,
    },
    '& .downloadSvg': {
      fill: theme.colors.white,
      maxHeight: 50,
      width: 24,
      cursor: 'pointer',
    },
  },
});
