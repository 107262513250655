import React from 'react';
import { Link } from 'react-router-dom';
// helpers
import { renderShortDateFormat, customDatePreview, formatDateToISO } from 'lib/renderHelpers';
// config
import { CHANNELS, ORGANIZATIONS } from 'themes/constants';
import { UPLOAD_TIME_FORMAT } from 'themes/main';
import {
  validationRequired,
  validationInt,
  validationString,
  validationCustomDate,
} from 'lib/uploadFieldValidations';
import { getUrl } from 'routes';
// components
import SubContractWarning from 'models/utils/SubContractWarning/SubContractWarning';
import { BatchRecord } from 'lib/lib';

export default () => {
  const { HOLLANDER } = ORGANIZATIONS;
  const DATE_FORMAT = UPLOAD_TIME_FORMAT[HOLLANDER] as string;
  const validationHollanderDate = validationCustomDate(DATE_FORMAT);

  return [
    {
      key: 'batchNumber',
      label: 'Partij nummer',
      title: 'Partij nummer',
      style: { flex: '1 0 60px' },
      renderUploadPreview: (column: BatchRecord) => column.batchNumber,
      render: (column: BatchRecord) => column.batchNumber,
      validate: [validationRequired, validationInt],
      defaultSelectedColumn: true,
      defaultSelectedBatchContractColumn: true,
    },
    {
      key: 'relationshipCodeName',
      label: 'Relatie code naam',
      title: 'Relatie code naam',
      style: { flex: '1 0 100px' },
      renderUploadPreview: (column: BatchRecord) => column.relationshipCodeName,
      render: (column: BatchRecord) => column.relationshipCodeName,
      validate: [validationRequired, validationString],
      defaultSelectedColumn: true,
      defaultSelectedBatchContractColumn: true,
    },
    {
      key: 'SSCCCode',
      label: 'SSCC code leverancier',
      title: 'SSCC code MLS',
      style: { flex: '1 0 100px' },
      renderUploadPreview: (column: BatchRecord) => column.SSCCCode,
      render: (column: BatchRecord) => (
        <SubContractWarning subContractAmount={column.subcontractAmount} linkText={column.SSCCCode}>
          <Link
            to={{
              pathname: getUrl('BatchHandshake', {
                type: ORGANIZATIONS.BANAFOOD,
                channelName: CHANNELS.BANANAS,
              }),
              search: `SSCCCode=${encodeURIComponent(column.SSCCCode)}`,
            }}
          >
            {column.SSCCCode}
          </Link>
        </SubContractWarning>
      ),
      validate: [validationRequired, validationInt],
      defaultSelectedColumn: true,
      defaultSelectedBatchContractColumn: true,
    },
    {
      key: 'mutationDate',
      label: 'Mutatie datum',
      title: 'Mutatie datum',
      style: { flex: '1 0 90px' },
      handleParsedValue: (value: string) => formatDateToISO(value, DATE_FORMAT),
      renderUploadPreview: ({ mutationDate }: BatchRecord) =>
        customDatePreview(mutationDate, [validationRequired, validationHollanderDate]),
      render: (column: BatchRecord) => renderShortDateFormat(column.mutationDate),
      validate: [validationRequired, validationHollanderDate],
      defaultSelectedColumn: true,
      defaultSelectedBatchContractColumn: true,
      edit: { type: 'date' },
    },
    {
      key: 'packagesCount',
      label: 'Colli',
      title: 'Colli',
      style: { flex: '1 0 50px' },
      renderUploadPreview: (column: BatchRecord) => column.packagesCount,
      render: (column: BatchRecord) => column.packagesCount,
      validate: [validationRequired, validationInt],
      defaultSelectedColumn: true,
      defaultSelectedBatchContractColumn: true,
    },
  ];
};
