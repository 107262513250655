import { validationRequired } from 'lib/validations';
import batchModel from './default';
import batchModelWitchCheckboxes from './defaultWithCheckboxes';
import { BatchRecord, ModelRequiredInfo } from 'lib/lib';
import { getCreationInfoWithDate } from '../utils/utils';

export default (modelRequiredInfo: ModelRequiredInfo, withCheckboxes = false) => {
  // @ts-ignore
  const isProducer = modelRequiredInfo.loggedInUserRole === 'PRODUCER';
  const defaultBatchModel =
    withCheckboxes && isProducer
      ? batchModelWitchCheckboxes(modelRequiredInfo)
      : batchModel(modelRequiredInfo);

  const detailsTab = {
    modelName: 'details',
    originalName: 'Details',
    title: 'Details',
  };

  const detailsModel = [
    {
      key: 'uploadedBy',
      label: 'Uploaded By',
      title: 'Uploaded By',
      style: { flex: '1 0 200px' },
      renderUploadPreview: (record: BatchRecord) => record.uploadedBy,
      render: (record: BatchRecord) => getCreationInfoWithDate(record),
      validate: [validationRequired],
      defaultSelectedColumn: true,
      defaultSelectedBatchContractColumn: true,
    },
  ];

  return {
    ...defaultBatchModel,
    tabs: [...defaultBatchModel.tabs, detailsTab],
    details: detailsModel,
  };
};
