import Route from 'route-parser';

import { routes } from 'routes';

function findMatchedRoute(pathname) {
  const { MainLayout, AuthLayout } = routes;

  return (
    MainLayout.find(({ path }) => new Route(path).match(pathname)) ||
    AuthLayout.find(({ path }) => new Route(path).match(pathname))
  );
}

export const getRouteParams = ({ location }) => {
  const { pathname } = location;
  const matchedRoute = findMatchedRoute(pathname);

  if (matchedRoute) {
    const params = {
      ...new Route(matchedRoute.path).match(pathname),
      location,
    };

    return {
      matchedRoute,
      params,
    };
  }

  return {};
};
