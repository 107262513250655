import inslagBatches from 'models/hollander/tabs/inslagBatchesTabModel';
import uitslagBatches from 'models/hollander/tabs/uitslagBatchesTabModel';
import { ModelRequiredInfo } from 'lib/lib';

export default (modelRequiredInfo: ModelRequiredInfo) => ({
  tabs: [
    {
      modelName: 'inslagBatches',
      originalName: 'Inslag',
      title: 'Inslag',
    },
    {
      modelName: 'uitslagBatches',
      originalName: 'Uitslag',
      title: 'Uitslag',
    },
  ],
  inslagBatches: inslagBatches(modelRequiredInfo),
  uitslagBatches: uitslagBatches(modelRequiredInfo),
});
