export const createNotificationResponse = (
  totalNumberOfItems: number,
  items: unknown[],
  unread = 27,
) => ({
  resources: items,
  meta: {
    pages: {
      total: `${totalNumberOfItems}`,
      perPage: 20,
      page: 1,
      lastPage: 1,
    },
    unread,
  },
});
