export default {
  $class: 'org.powerchain.juice.batch.ProducerBatchReadConcept',
  vesselTkBatchId: 'tst__00000000',
  vesselTkTrip: 'tst__trip0000',
  blDate: '2020-03-23T10:23:44.197Z',
  terminalBrId: 'tst__12131123',
  terminalBrName: 'tst__name',
  lastReceiptDateBr: '2020-03-23T10:23:44.197Z',
  plantId: 'tst__0000',
  plantName: 'tst_name',
  finalManDate: '2020-03-23T10:23:44.197Z',
  startManDate: '2020-03-23T10:23:44.197Z',
  farmId: 'tst__0000',
  farmName: 'stst__name',
  harvestFinalDate: '2020-03-23T10:23:44.197Z',
  harvestStartDate: '2020-03-23T10:23:44.197Z',
  contributionPercent: '0.00 %',
  farmCoordinates: 'string',
  batchId: 'tst__00000000',
  createdAt: '2020-03-23T11:12:41.484Z',
  createdBy:
    'resource:org.powerchain.juice.member.ProducerUser#CitrosucoBrazil_6c3ed9e1-e09b-455c-a3e9-d0b7e955af02',
  channelName: 'juice',
  status: 'PENDING',
  createdBy_participant: {
    username: null,
    email: null,
    participant:
      'org.powerchain.juice.member.ProducerUser#CitrosucoBrazil_6c3ed9e1-e09b-455c-a3e9-d0b7e955af02',
    channel: { title: 'Juice', name: 'juice' },
    org: { name: 'CitrosucoBrazil', fullname: 'Citrosuco Brazil', logo: null },
    user: {
      username: 'CitrosucoBrazilTST staging',
      email: 'CitrosucoBrazilTST@pc.t2f.io',
      org: { name: 'CitrosucoBrazil', fullname: 'Citrosuco Brazil', logo: null },
    },
  },
};
