import React from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
// actions
import * as batchesActions from 'domain/batches/actions';
// types
import { BatchRecord } from 'lib/lib';
import { getBatchInfoByUrlOrgRoleAll } from 'domain/common';
import { CHANNELS } from 'themes/constants';

interface OwnProps {
  column: BatchRecord;
}

const DeleteSingleBatchButton: React.FC<OwnProps> = (props) => {
  const dispatch = useDispatch();
  const { column } = props;

  const batchInfoAll = useSelector(getBatchInfoByUrlOrgRoleAll, shallowEqual);

  const deleteSingleBatch = () => {
    const endpoint = batchInfoAll[column.channelName as CHANNELS].endpoint;
    if (window.confirm(`Are you sure you want to archive batch № ${column.batchnumber}?`)) {
      // BEWARE: this component is designed for AH only
      dispatch(batchesActions.deleteBatchAction({ batchId: column.batchId, endpoint }));
    }
  };

  return (
    <button
      type="button"
      onClick={deleteSingleBatch}
      data-archive={column.batchId}
      className="batch-details batch-archive"
    >
      Archive
    </button>
  );
};

export default DeleteSingleBatchButton;
