import * as documentActions from 'domain/documents/actions';
import { showFailedResponse } from 'lib/helpers';
import produce from 'immer';
import { AnyAction } from 'redux';
import * as envActions from 'domain/env/actions';

const initialState = {
  // for lists
  resources: [],
  // for read, update one document
  resource: {},
  errors: {},
  certFile: {},
  isLoading: false,
  isFileUploading: false,
};

const documents = produce<(draft: typeof initialState, action: AnyAction) => void>(
  (draft, { type, payload }) => {
    switch (type) {
      case envActions.setChannelAction.type:
      case envActions.logOutAction.success:
        return initialState;

      // fetch documents
      case documentActions.fetchDocumentsAction.type:
        draft.isLoading = true;
        draft.resources = initialState.resources;
        return;

      case documentActions.fetchDocumentsAction.success:
        draft.isLoading = false;
        draft.resources = payload;
        return;

      case documentActions.fetchDocumentsAction.failure:
        draft.isLoading = false;
        draft.errors = payload;
        return;

      // fetch one document by id
      case documentActions.fetchDocumentAction.type:
        draft.isLoading = true;
        return;

      case documentActions.fetchDocumentAction.success:
        draft.isLoading = false;
        draft.resource = payload;
        return;

      case documentActions.fetchDocumentAction.failure:
        draft.isLoading = false;
        draft.errors = payload;
        return;

      // create Docs
      case documentActions.createDocumentAction.type:
        draft.isLoading = true;
        return;

      case documentActions.createDocumentAction.success:
        draft.isLoading = false;
        return;

      case documentActions.createDocumentAction.failure:
        showFailedResponse(payload);
        draft.isLoading = false;
        draft.errors = payload;
        return;

      // delete Doc
      case documentActions.deleteDocumentAction.type:
        draft.isLoading = true;
        return;

      case documentActions.deleteDocumentAction.success:
        draft.isLoading = false;
        draft.resources = draft.resources.filter((f) => f.certId !== payload.certId);
        return;

      case documentActions.deleteDocumentAction.failure:
        draft.errors = payload;
        return;

      // approve Doc
      case documentActions.approveDocumentAction.type:
        draft.isLoading = true;
        return;

      case documentActions.approveDocumentAction.success:
        draft.isLoading = false;
        draft.resources = draft.resources.map((f) => (f.certId === payload.certId ? payload : f));
        return;

      case documentActions.approveDocumentAction.failure:
        draft.isLoading = false;
        draft.errors = payload;
        return;

      // uploadCertFileAction
      // fetch one document by id
      case documentActions.uploadCertFileAction.type:
        draft.isFileUploading = true;
        draft.certFile = initialState.certFile;
        return;

      case documentActions.uploadCertFileAction.success:
        draft.isFileUploading = false;
        draft.certFile = payload;
        return;

      case documentActions.uploadCertFileAction.failure:
        draft.isFileUploading = false;
        draft.errors = payload;
        return;

      // getCertFileAction
      // fetch one document by id
      case documentActions.getCertFileAction.type:
        draft.isLoading = true;
        return;

      case documentActions.getCertFileAction.success:
        draft.isLoading = false;
        return;

      case documentActions.getCertFileAction.failure:
        draft.isLoading = false;
        draft.errors = payload;
        return;
    }
  },
  initialState,
);

export const reducer = { documents };
