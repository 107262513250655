import React from 'react';
import { Link } from 'react-router-dom';
import { getUrl } from 'routes';
import { CHANNELS, ORGANIZATIONS } from 'themes/constants';
//
import { renderShortDateFormat, renderTwoShortDateFormat } from 'lib/renderHelpers';
import { extractCreatedBy } from 'models/utils/utils';
import { BatchRecord, ModelRequiredInfo } from 'lib/lib';
import { CHANNEL_NAME_TO_TITLE_MAP } from 'themes/main';

export default (modelRequiredInfo: ModelRequiredInfo) => [
  {
    key: 'lotFinishedProduct',
    label: 'Lot_Finished_Product',
    title: 'Lot Finished Product',
    style: { flex: '0 0 150px' },
    defaultSelectedColumn: true,
    render: (column: BatchRecord) => (
      <Link
        className="contract"
        to={{
          pathname: getUrl('BatchContractType', {
            type: ORGANIZATIONS.REFRESCO.toLowerCase(),
            contract_number: column.lotFinishedProduct,
            modelType: 'contractDetails',
            channelName: column.channelName,
          }),
        }}
      >
        {column.lotFinishedProduct}
      </Link>
    ),
  },
  {
    key: 'qtyProducedPcsFinishedProductSum',
    label: 'QtyProduced_PCS_Finished_Product_Sum',
    title: 'QtyProduced PCS Finished Product Sum',
    style: { flex: '1 0 50px' },
    defaultSelectedColumn: true,
    render: (column: BatchRecord) => column.qtyProducedPcsFinishedProductSum,
  },
  {
    key: 'qtyUsedLtrRawProductSum',
    label: 'QtyUsed_LTR_RAW_Product_Sum',
    title: 'QtyUsed LTR RAW Product Sum',
    style: { flex: '1 0 50px' },
    defaultSelectedColumn: true,
    render: (column: BatchRecord) => column.qtyUsedLtrRawProductSum,
  },
  {
    key: 'productionFinishedFinishedProduct',
    label: 'ProductionFinished_Finished_Product',
    title: 'ProductionFinished Finished Product',
    style: { flex: '1 0 100px' },
    defaultSelectedColumn: true,
    render: (column: BatchRecord) => {
      if (
        !column.productionFinishedFinishedProductMin ||
        !column.productionFinishedFinishedProductMax
      ) {
        return '';
      }

      return renderTwoShortDateFormat(
        column.productionFinishedFinishedProductMin,
        column.productionFinishedFinishedProductMax,
      );
    },
  },
  {
    key: 'dateCheckFcoj',
    label: 'Date_check_FCOJ',
    title: 'Date check FCOJ',
    style: { flex: '1 0 100px' },
    defaultSelectedColumn: true,
    render: (column: BatchRecord) => {
      if (!column.dateCheckFcojMin || !column.dateCheckFcojMax) {
        return '';
      }

      return renderTwoShortDateFormat(column.dateCheckFcojMin, column.dateCheckFcojMax);
    },
  },
  {
    key: 'ratioFcojAverage',
    label: 'Ratio_FCOJ_Average',
    title: 'Ratio FCOJ Average',
    style: { flex: '1 0 50px' },
    defaultSelectedColumn: true,
    render: (column: BatchRecord) => column.ratioFcojAverage,
  },
  {
    key: 'ratioFinishedProductAverage',
    label: 'Ratio_Finished_Product_Average',
    title: 'Ratio Finished Product Average',
    style: { flex: '1 0 50px' },
    defaultSelectedColumn: true,
    render: (column: BatchRecord) => column.ratioFinishedProductAverage,
  },
  {
    key: 'uniqLotFcojAmount',
    label: 'uniqLotFcojAmount',
    title: 'Unique Lot FCOJ Amount',
    style: { flex: '1 0 50px' },
    defaultSelectedColumn: true,
    render: (column: BatchRecord) => column.uniqLotFcojAmount,
  },
  {
    key: 'createdAtMin',
    label: 'Created At',
    title: 'Created At',
    style: { flex: '1 0 60px' },
    // isSortable: true,
    renderExportValue: (column: BatchRecord) => column.createdAtMin,
    render: (column: BatchRecord) => renderShortDateFormat(column.createdAtMin),
  },
  {
    key: 'createdAtMax',
    label: 'Last Updated',
    title: 'Last Updated',
    style: { flex: '1 0 60px' },
    // isSortable: true,
    renderExportValue: (column: BatchRecord) => column.createdAtMax,
    render: (column: BatchRecord) => renderShortDateFormat(column.createdAtMax),
  },
  {
    key: 'createdBy',
    label: 'Created By',
    title: 'Created By',
    style: { flex: '1 0 60px' },
    renderExportValue: (column: BatchRecord) => extractCreatedBy(column),
    render: (column: BatchRecord) => extractCreatedBy(column),
  },
  {
    key: 'channelName',
    label: 'Channel',
    title: 'Channel',
    style: { flex: '1 0 60px' },
    renderExportValue: (column: BatchRecord) =>
      CHANNEL_NAME_TO_TITLE_MAP[column.channelName as CHANNELS],
    render: (column: BatchRecord) => CHANNEL_NAME_TO_TITLE_MAP[column.channelName as CHANNELS],
  },
];
