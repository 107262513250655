import Route from 'route-parser';

import refrescoBatchIndexListResponseOranges from 'mocks/responses/channels/oranges/refrescobatch/indexList';
import { deleleConractResponseEggsOranges } from 'mocks/router/utils';
import refrescoBatchContractListResponseOranges from 'mocks/responses/channels/oranges/refrescobatch/contractList';
import refrescoBatchDetailsResponseOranges from 'mocks/responses/channels/oranges/refrescobatch/details';

export default (request) => {
  const route = new Route('/api/v1(/:channelName)/refrescobatch(/handshake/?*handshake)(?*filter)');
  const parameters = route.match(request.url);

  if (request.method === 'post') {
    return [
      409,
      {
        juice: {
          status: 'fail',
          message: 'Batches were not uploaded because of problems on the "juice" channel!',
          batches: [],
          contractId: null,
          amount: 242,
        },
        oranges: {
          status: 'success',
          message: 'Successfully uploaded!',
          batches: [],
          contractId: '08718906330979',
          amount: 17,
        },
      },
    ];
  }

  if (parameters) {
    if (parameters.filter) {
      if (parameters.filter.includes('beginDate')) {
        return [200, refrescoBatchIndexListResponseOranges()];
      }
    }

    if (parameters.contractId) {
      if (request.method === 'delete') {
        return [200, deleleConractResponseEggsOranges(parameters.contractId, 'refresco')];
      }

      return [200, refrescoBatchContractListResponseOranges()];
    }

    if (parameters.batchId) {
      return [200, refrescoBatchDetailsResponseOranges()];
    }

    if (parameters.handshake) {
      return [200, refrescoBatchIndexListResponseOranges()];
    }
  }

  return [500, `url not proceeded: ${request.url}`];
};
