import React from 'react';
import injectSheet, { WithStyles } from 'react-jss';
import mainLayoutStyles from 'pages/layouts/MainLayout/mainLayoutStyles';
// components
import SubMainMenu from 'pages/layouts/MainLayout/SubMainMenu';
import Breadcrumbs from 'pages/layouts/MainLayout/Breadcrumbs';
import MainMenu from 'pages/layouts/MainLayout/MainMenu';

const MainLayout: React.FC<WithStyles<typeof mainLayoutStyles>> = (props) => {
  const { children, classes } = props;

  return (
    <div className={classes.root}>
      <MainMenu classes={classes} />
      <SubMainMenu classes={classes} />
      <Breadcrumbs classes={classes} />
      <div className={classes.content}>{children}</div>
    </div>
  );
};

export default injectSheet(mainLayoutStyles)(MainLayout);

/* ******************************************************************************* */
/* **************** LEGACY CODE. RESTORE IF APP CRASHES ON LOGOUT **************** */
/* ******************************************************************************* */

//
// import React from 'react';
// import PropTypes from 'prop-types';
// import { Link } from 'react-router-dom';
// // redux
// import { compose } from 'redux';
// import { connect } from 'react-redux';
// import { getUser, getChannel, getChannels } from 'domain/env';
// import { getMatchedRoute, getParams } from 'domain/common';
// import { getMeta } from 'domain/notifications';
// // utils
// import { CHANNELS } from 'themes/constants';
// import { getTheme } from 'themes/main';
// // components
// import UserDropdown from 'components/Dropdown/UserDropdown';
// import UserAvatar from 'components/Avatar/userAvatar';
// import SubMainMenu from 'pages/layouts/MainLayout/SubMainMenu';
// import ChannelSwitcher from 'components/ChannelSwitcher/ChannelSwitcher';
// import NotificationCounter from 'pages/notifications/NotificationCounter';
// import Breadcrumbs from 'pages/layouts/MainLayout/Breadcrumbs';
// import MainMenu from 'pages/layouts/MainLayout/MainMenu';
// // icons
// import { OrangesChannelLogoSvg, EggsChannelLogoSvg } from 'components/icons';
// // jss
// import injectSheet from 'react-jss';
// import { routes, getUrl } from 'routes';
// import mainLayoutStyles from './mainLayoutStyles';
//
// // class Breadcrumbs extends React.PureComponent {
// //   static propTypes = {
// //     classes: PropTypes.object,
// //     matchedRoute: PropTypes.object,
// //     params: PropTypes.object,
// //   };
// //
// //   getBreadcrumbs = () => {
// //     const { matchedRoute = {}, params = {} } = this.props;
// //
// //     const defaultRoute = routes.MainLayout.find((route) => route.default);
// //     const routesToRender = [defaultRoute];
// //
// //     if (matchedRoute.breadcrumbs && matchedRoute.breadcrumbs.addRoutes) {
// //       matchedRoute.breadcrumbs.addRoutes.forEach((comp) => {
// //         const obtRoute = routes.MainLayout.find((r) => r.name === comp);
// //         if (obtRoute) {
// //           routesToRender.push(obtRoute);
// //         }
// //       });
// //     }
// //
// //     if (matchedRoute.name !== defaultRoute.name) {
// //       routesToRender.push(matchedRoute);
// //     }
// //
// //     const getBreadcrumbsTitle = (r, paramsC) => {
// //       if (r.breadcrumbs && r.breadcrumbs.showParam) {
// //         const pageTitle = r.breadcrumbs.showParam;
// //         return paramsC[pageTitle];
// //       }
// //       return r.pageTitle;
// //     };
// //
// //     return routesToRender.map((r, i) => (
// //       <React.Fragment key={`${r.name}${i}`}>
// //         <Link to={{ pathname: getUrl(r.name, params) }}>{getBreadcrumbsTitle(r, params)}</Link>
// //         {routesToRender[i + 1] && <span> / </span>}
// //       </React.Fragment>
// //     ));
// //   };
// //
// //   render() {
// //     const { classes } = this.props;
// //     return (
// //       <div className={classes.breadcrumbs}>
// //         <div className="inner">{this.getBreadcrumbs()}</div>
// //       </div>
// //     );
// //   }
// // }

/*
 *  MainMenu - header block with Logo, Menu, UserDropDown Components
 */

// class MainMenu extends React.Component {
//   static propTypes = {
//     classes: PropTypes.object,
//     user: PropTypes.object,
//     channel: PropTypes.object,
//     channels: PropTypes.array,
//     meta: PropTypes.object,
//   };
//
//   state = {
//     isMenuOpened: false,
//   };
//
//   toggleDropdownMenu = () => {
//     const { isMenuOpened } = this.state;
//     this.setState({ isMenuOpened: !isMenuOpened });
//   };
//
//   getChannelIcon = (name) => {
//     if (name === CHANNELS.EGGS) {
//       return <EggsChannelLogoSvg alt={name} />;
//     }
//     if (name === CHANNELS.ORANGES) {
//       return <OrangesChannelLogoSvg alt={name} />;
//     }
//     return '';
//   };
//
//   isNotificationsLink = (link) => link.routeName === 'Notifications';
//
//   displayNotificationCounter = (link) => {
//     return this.isNotificationsLink(link) ? <NotificationCounter /> : null;
//   };
//
//   render() {
//     const { user, classes, channel, channels } = this.props;
//     const { isMenuOpened } = this.state;
//
//     const mainMenuItems = getTheme(user.role, channel.name).mainMenuItems.filter((r) =>
//       routes.MainLayout.find((route) => route.name === r.routeName),
//     );
//
//     return (
//       <>
//         <div className={classes.mainMenu}>
//           <div className={classes.mainMenuInner}>
//             <div className="mainLogo">
//               <Link to="/">
//                 <img src={getTheme(user.role, channel.name).logo} alt="logo" />
//               </Link>
//             </div>
//
//             <div className={classes.links}>
//               {mainMenuItems.map((link) => {
//                 const { pageTitle } = routes.MainLayout.find(
//                   (route) => route.name === link.routeName,
//                 );
//                 return (
//                   <div
//                     className={`${classes.link} ${link.disabled ? 'disabled' : ''}`}
//                     key={link.routeName}
//                   >
//                     <div className="icon">
//                       <link.icon />
//                       {this.displayNotificationCounter(link)}
//                     </div>
//
//                     {!link.disabled ? (
//                       link.url ? (
//                         <Link className="title" to={{ pathname: link.url }} target="_blank">
//                           {pageTitle}
//                         </Link>
//                       ) : (
//                         <Link className="title" to={getUrl(link.routeName)}>
//                           {pageTitle}
//                         </Link>
//                       )
//                     ) : (
//                       <div className="title">{pageTitle}</div>
//                     )}
//                   </div>
//                 );
//               })}
//             </div>
//
//             <div className={classes.dropDown}>
//               <div className="hello">{`hello, ${user.username}`}</div>
//
//               {channels && channels.length === 1 && (
//                 <div className="channel">{!!channel && this.getChannelIcon(channel.name)}</div>
//               )}
//
//               {channels && channels.length > 1 && (
//                 <ChannelSwitcher channel={channel} channels={channels} />
//               )}
//
//               <div className="icon" onClick={this.toggleDropdownMenu}>
//                 <UserAvatar user={user} circle />
//               </div>
//             </div>
//           </div>
//         </div>
//
//         <div className={classes.mainMenu} style={{ height: 0, zIndex: 99999, border: 0 }}>
//           <div className={classes.mainMenuInner}>
//             <UserDropdown isOpen={isMenuOpened} toggleDropdownMenu={this.toggleDropdownMenu} />
//           </div>
//         </div>
//       </>
//     );
//   }
// }

// class MainLayout extends React.Component {
//   render() {
//     const {
//       children,
//       classes,
//       user,
//       location,
//       matchedRoute,
//       channel,
//       channels,
//       meta,
//       params,
//     } = this.props;
//
//     return (
//       <div className={classes.root}>
//         <MainMenu
//           user={user}
//           classes={classes}
//           locationHOC={location}
//           channel={channel}
//           channels={channels}
//           meta={meta}
//         />
//         <SubMainMenu classes={classes} currentRouteName={matchedRoute && matchedRoute.name} />
//         <Breadcrumbs params={params} classes={classes} matchedRoute={matchedRoute} />
//         <div className={classes.content}>{children}</div>
//       </div>
//     );
//   }
// }
//
// export default compose(
//   connect((state) => ({
//     user: getUser(state),
//     params: getParams(state),
//     matchedRoute: getMatchedRoute(state),
//     channel: getChannel(state),
//     channels: getChannels(state),
//     meta: getMeta(state),
//   })),
//   injectSheet(mainLayoutStyles),
// )(MainLayout);
