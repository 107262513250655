import { Theme } from 'lib/lib';

export default (theme: Theme) => ({
  component: {
    padding: ['2em', 0],
  },
  batchContractTableUpperHead: {
    display: 'flex',
    alignItems: 'stretch',
    justifyContent: 'space-between',

    '& > .mainPanel': {
      display: 'flex',
      alignItems: 'flex-end',
      '& > .tab': {
        display: 'inline-flex',
        alignItems: 'center',
        minWidth: 40,
        color: theme.colors.white,
        margin: 0,
        padding: [5, 9],
        borderRadius: [10, 10, 0, 0],
        backgroundColor: theme.colors.primary,
        transition: 'background-color 0.5s ease',
        textDecoration: 'none',
        border: 0,
        '& svg': {
          width: 24,
          height: 24,
          fill: theme.colors.white,
          marginRight: 10,
        },
        '& span': {
          font: {
            size: theme.fontSizes.sm,
            family: 'Lato',
            weight: 400,
          },
          color: theme.colors.white,
        },
      },
    },
    '& > .rightPanel': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      '& button': {
        padding: [2, 5],
        font: {
          size: 12,
        },
        cursor: 'pointer',
        backgroundColor: theme.colors.primary,
        color: theme.colors.white,
        border: {
          radius: 8,
        },
        outline: 0,
      },
    },
  },

  tableRow: {
    display: 'flex',
    color: theme.colors.black,
    backgroundColor: theme.colors.background,
    border: '1px solid rgb(222, 222, 222)',
    borderTop: 0,
    '&:nth-of-type(even)': {
      backgroundColor: theme.colors.white,
      overflow: 'hidden',
    },
    '& .left': {
      flex: '0 0 50%',
      padding: [2, 5],
      textAlign: 'left',
      borderRight: '1px solid rgb(222, 222, 222)',
    },
    '& .right': {
      flex: '0 0 50%',
      padding: [2, 5],
      textAlign: 'right',
    },
  },

  content: theme.content,
  pageTitle: {
    ...theme.pageTitle,
    marginTop: 24,
  },
  table: {
    width: '100%',
    maxWidth: '100%',
    borderCollapse: 'separate',
    borderSpacing: '0 3px',
    '& th': {
      fontSize: theme.fontSizes.sm - 2,
      textAlign: 'center',
      padding: [16, 5],
      fontWeight: 'bold',
      cursor: 'pointer',
    },
    '& td': {
      fontSize: theme.fontSizes.sm - 2,
      textAlign: 'center',
      padding: [16, 5],
    },
  },
  tableDataRow: {
    '&:nth-child(odd)': {
      '& td': {
        backgroundColor: theme.colors.background,
      },
    },
    '&:nth-child(even)': {
      '& td': {
        backgroundColor: theme.colors.backgroundLight,
      },
    },
    '& td': {
      '&:last-child': {
        borderTopRightRadius: 25,
        borderBottomRightRadius: 25,
      },
    },
  },
  check: {
    fontSize: 14,
  },
  form: {
    marginTop: 14,
  },
  sortIcon: {
    color: theme.colors.danger,
  },
  btnDownload: {
    ...theme.successBtn,
    float: 'right',
  },
});
