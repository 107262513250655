import React from 'react';
import { Link } from 'react-router-dom';
//
import { getUrl } from 'routes';

interface OwnProps {
  onClick: () => void;
  entityId: string;
}

const RequestLink: React.FC<OwnProps> = (props) => {
  const { onClick, entityId } = props;
  return (
    <Link
      data-demand={`${entityId}`}
      className="square-button"
      to={{
        pathname: getUrl('OutboxDetails', {
          outboxId: entityId,
        }),
      }}
      onClick={onClick}
    >
      Link
    </Link>
  );
};

export default RequestLink;
