import defaultTab from 'models/jumbo/tabs/defaultTabModel';
import {CHANNELS, ORGANIZATIONS} from 'themes/constants';
import {defaultContractSettingsAndActionsExcel} from 'models/utils/utils';
import {ModelRequiredInfo} from 'lib/lib';

export default (modelRequiredInfo: ModelRequiredInfo) => ({
  tabs: [
    {
      modelName: 'defaultTab',
      originalName: ORGANIZATIONS.JUMBO,
      title: ORGANIZATIONS.JUMBO,
    },
  ],
  defaultTab: defaultTab(modelRequiredInfo),
  settingsAndActions: defaultContractSettingsAndActionsExcel(CHANNELS.TILAPIA),
});
