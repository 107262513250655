import React, { useLayoutEffect } from 'react';
import { compose } from 'redux';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
// jss
import injectSheet, { WithStyles } from 'react-jss';
import certificateStyles from 'pages/documents/styles/certificateStyles';
// components
import SimpleTabsFrame from 'components/Frames/SimpleTabsFrame';
import ApproveCertificateButton from 'models/utils/ApproveCertificateButton/ApproveCertificateButton';
import { ShowCertificateSvg } from 'components/icons';
// redux
import * as documentActions from 'domain/documents/actions';
import { getModel, getParams } from 'domain/common';
import { getCertificate, getIsLoading } from 'domain/documents';
// types
import { AppStateType } from 'types';

const mapStateToProps = (state: AppStateType) => ({
  document: getCertificate(state),
  isLoading: getIsLoading(state),
  params: getParams(state),
  model: getModel(state),
});

const Certificate: React.FC<WithStyles<typeof certificateStyles>> = (props) => {
  const dispatch = useDispatch();
  const { classes } = props;
  const { document, isLoading, params, model } = useSelector(mapStateToProps, shallowEqual);

  useLayoutEffect(() => {
    params.id && dispatch(documentActions.fetchDocumentAction(params.id));
  }, [params.id]);

  const showCertificateInNewWindow = () =>
    dispatch(documentActions.getCertFileAction(document.certId));

  const headPanel = document.fileUrl
    ? [
        {
          icon: (
            <React.Fragment>
              <button type="button" key="Show certificate" onClick={showCertificateInNewWindow}>
                <span>Show certificate</span>
                <ShowCertificateSvg width={24} />
              </button>
              <ApproveCertificateButton column={document} className={classes.approve} />
            </React.Fragment>
          ),
        },
      ]
    : [];

  return (
    <section className={classes.component}>
      <SimpleTabsFrame
        tabs={[
          {
            style: {},
            className: '',
            title: `${document.number || ''} ${document.certId || ''}`,
            body: model.defaultTab
              .filter((f) => f.showInDetails && document[f.key])
              .map((field) => (
                <div className={classes.tableRow} key={field.title}>
                  <div className="left">{field.title}</div>
                  <div className="right">{field.render && field.render(document)}</div>
                </div>
              )),
          },
          {
            style: {},
            className: 'sim',
            title: 'SIM',
            body: model.defaultTab
              .filter((f) => f.showInSIM && document[f.key])
              .map((field) => (
                <div className={classes.tableRow} key={field.title}>
                  <div className="left">{(field.titleSIM as string) || field.title}</div>
                  <div className="right">{field.render && field.render(document)}</div>
                </div>
              )),
          },
        ]}
        headPanel={headPanel}
        isLoading={isLoading}
      />
    </section>
  );
};

export default compose<React.FC>(
  injectSheet(certificateStyles),
  React.memo,
)(Certificate);
