import React from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';

const standartFrames = (theme) => ({
  addSimpleFrameTitle: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor:
      theme.colors.primaryHover === theme.colors.accent
        ? theme.colors.primary
        : theme.colors.primaryHover,
    color: theme.colors.white,
    margin: 0,
    padding: [10, 10],
    borderRadius: [10, 10, 0, 0],
    transition: 'background-color 0.5s ease',
    textDecoration: 'none',
    '& span': {
      font: {
        size: theme.fontSizes.sm,
        family: 'Lato',
        weight: 400,
      },
      color: theme.colors.white,
    },
  },
  addSimpleFrameBody: {
    backgroundColor: theme.colors.background,
    color: theme.colors.white,
    margin: 0,
    padding: [10, 20],
    borderRadius: [0, 0, 10, 10],
    transition: 'background-color 0.5s ease',
    textDecoration: 'none',
  },
});

class SimpleFrame extends React.PureComponent {
  static propTypes = {
    title: PropTypes.node.isRequired,
    children: PropTypes.node,
    classes: PropTypes.object.isRequired,
    stylesBody: PropTypes.object,
  };

  static defaultProps = {
    stylesBody: {},
  };

  render() {
    const { title, children, classes, stylesBody } = this.props;
    return (
      <div>
        <div className={classes.addSimpleFrameTitle}>{title}</div>
        <div className={classes.addSimpleFrameBody} style={stylesBody}>
          {children}
        </div>
      </div>
    );
  }
}

export default injectSheet(standartFrames)(SimpleFrame);
