import './polyfills';
import './index.css';

import React from 'react';
import ReactDOM from 'react-dom';

import AppProviders from 'pages/AppProviders';
import App from 'pages/app';
import Stub from 'pages/stub';

import * as serviceWorker from './serviceWorker';

// if (process.env.NODE_ENV !== 'production') {
//   const whyDidYouRender = require('@welldone-software/why-did-you-render');
//   whyDidYouRender(React, {
//     include: [/^.*$/],
//     exclude: [/svg|link|switch/gi],
//   });
// }

const root = document.getElementById('root');
if (process.env.REACT_APP_STUB === 'true') {
  ReactDOM.render(
    <AppProviders>
      <Stub />
    </AppProviders>,
    root,
  );
} else {
  ReactDOM.render(
    <AppProviders>
      <App />
    </AppProviders>,
    root,
  );

  if (process.env.NODE_ENV === 'development' && module.hot) {
    module.hot.accept('./pages/app', () => {
      const NextApp = require('./pages/app').default;

      ReactDOM.render(
        <AppProviders>
          <NextApp />
        </AppProviders>,
        root,
      );
    });
  }
}

serviceWorker.register({
  onUpdate: (registration: any) => {
    const waitingServiceWorker = registration.waiting;

    if (waitingServiceWorker) {
      waitingServiceWorker.addEventListener('statechange', (event: any) => {
        if (event.target.state === 'activated') {
          window.location.reload();
        }
      });
      waitingServiceWorker.postMessage({ type: 'SKIP_WAITING' });
    }
  },
});
