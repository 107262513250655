import React, { useState, useMemo } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
// styles
import injectSheet, { WithStyles } from 'react-jss';
import stylesBatchDatatable from 'pages/dashboard/components/BatchDatatable/styles/stylesBatchDatatable';
//components
import Datatable from 'components/Datatable/datatable';
// icons
import { ArrowDownSvg, ArrowUpSvg } from 'components/icons';
// redux
import { getUnmatchedBatchesList, getIsLoading } from 'domain/unmatched';
// lib
import { renderShortDateFormat } from 'lib/renderHelpers';
// types
import { AppStateType } from 'types';

const orgs = {
  DistributorBatch: 'Hollander',
  ProducerBatch: 'Fyffes',
  ResellerBatch: 'Banafood',
};

const batchDataTableModel = (unmatchedPropName: string) => [
  {
    key: unmatchedPropName,
    label: unmatchedPropName,
    title: unmatchedPropName,
    style: { flex: '1 0 80px' },
    defaultSelectedColumn: true,
    render: (column: any) => column.unmatchedPropValue,
  },
  {
    key: 'createdAt',
    label: 'Created at',
    title: 'Created at',
    style: { flex: '1 0 80px' },
    defaultSelectedColumn: true,
    render: (column: any) => renderShortDateFormat(column.createdAt),
  },
];

const mapStateToProps = (state: AppStateType) => ({
  unmatched: getUnmatchedBatchesList(state),
  isLoading: getIsLoading(state),
});

interface OwnProps extends WithStyles<typeof stylesBatchDatatable> {
  isOpen?: boolean;
  batchType: string;
}

const BatchDataTable: React.FC<OwnProps> = ({ isOpen: isOpenProp = false, batchType, classes }) => {
  const { unmatched, isLoading } = useSelector(mapStateToProps, shallowEqual);
  const [isOpen, setIsOpen] = useState(isOpenProp);

  const unmatchedPropertyName = useMemo(
    () => batchDataTableModel(unmatched[batchType].unmatchedPropertyName),
    [batchType],
  );
  return (
    <>
      <h2 onClick={() => setIsOpen(!isOpen)} className={classes.batchType}>
        {isOpen ? (
          <ArrowUpSvg className={classes.arrow} width={20} height={20} />
        ) : (
          <ArrowDownSvg className={classes.arrow} width={20} height={20} />
        )}
        {orgs[batchType as keyof typeof orgs]}
      </h2>
      {isOpen && (
        <Datatable
          data={unmatched[batchType] ? unmatched[batchType].unmatchedBatches : []}
          columns={unmatchedPropertyName}
          noItemsText="No mismatches have been found"
          isLoading={isLoading}
        />
      )}
    </>
  );
};

export default injectSheet(stylesBatchDatatable)(BatchDataTable);
