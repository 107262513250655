import { useCallback, useEffect, useMemo } from 'react';
import moment from 'moment';
// icons
import amforiCertIcon from 'assets/certIcons/amfori.png';
import rfaCertIcon from 'assets/certIcons/rfa.png';
import sedexCertIcon from 'assets/certIcons/sedex.png';
import brcFoodCertIcon from 'assets/certIcons/brcFood.png';
import foodSafetyCertIcon from 'assets/certIcons/foodSafety.png';
import ifsCertIcon from 'assets/certIcons/ifs.png';
import sqfCertIcon from 'assets/certIcons/sqf.png';
import fundacao from 'assets/certIcons/fundacao_abrinq.png';
import defaultCertIcon from 'assets/certIcons/default.svg';
import ikbCertIcon from 'assets/certIcons/ikb.png';
import katCertIcon from 'assets/certIcons/kat.png';
// types
import { SubmitFormValuesTilapiaBananasEggs } from 'pages/documents/certificateAddComponetns/certificateAddTilapiaBananasEggs';
import { SubmitFormValuesOranges } from 'pages/documents/certificateAddComponetns/certificateAddOrangesJuice';
import { CHANNELS } from 'themes/constants';
import { DocumentConfig, SerializedDocumentConfig } from 'lib/lib';

export interface SelectValue {
  label: string;
  value: number;
}

export const isSelectValue = (entity: string | SelectValue): entity is SelectValue => {
  return typeof (entity as any).label === 'string' && typeof (entity as any).value === 'number';
};

export const getIconByOrgName = (documentType: string) => {
  switch (documentType) {
    case 'Amfori Business Social Compliance Initiative':
      return amforiCertIcon;
    case 'Rainforest Alliance (RFA)':
      return rfaCertIcon;
    case 'Sedex Members Ethical Trade Audit (ETI SMETA)':
      return sedexCertIcon;
    case 'BRC Food':
      return brcFoodCertIcon;
    case 'Food Safety System Certification 22000':
      return foodSafetyCertIcon;
    case 'IFS Food':
      return ifsCertIcon;
    case 'Safe Quality Food (SQF)':
      return sqfCertIcon;
    case 'Fundacao Abrinq':
      return fundacao;
    case 'BLK Level':
      return defaultCertIcon;
    case 'IKB':
      return ikbCertIcon;
    case 'KAT':
      return katCertIcon;

    default:
      return defaultCertIcon;
  }
};

export const useFormValidation = () => {
  const validateRequired = useCallback((value) => (value ? undefined : 'Required'), []);
  const validateDateFormatYYYYMMDDDashed = useCallback(
    (value) =>
      value
        ? moment(value, 'YYYY-MM-DD', true).isValid()
          ? undefined
          : 'Date should be in YYYY-MM-DD format'
        : undefined,
    [],
  );
  const validateFileIsUploaded = useCallback(
    (value: File[]) =>
      value && value[0] && value[0] instanceof File
        ? undefined
        : 'File was discarded. Upload again',
    [],
  );

  const formatValue = useCallback(({ label, value }) => ({ label, value }), []);

  const validateEmail = useCallback((value) => {
    const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return emailRegex.test(value.toLowerCase()) ? undefined : 'Invalid e-mail';
  }, []);

  return {
    validateRequired,
    validateDateFormatYYYYMMDDDashed,
    validateFileIsUploaded,
    validateEmail,
    formatValue,
  };
};

export const getEntitiesCertified = (channelName: CHANNELS) => {
  switch (channelName) {
    case CHANNELS.EGGS:
      return [{ value: 1, title: 'Farm' }, { value: 2, title: 'Packer' }];
    default:
      return [];
  }
};

export const getDocumentTypes = (channelName: CHANNELS) => {
  switch (channelName) {
    case CHANNELS.TILAPIA:
      return [
        { value: 1, title: 'ASC CoC' },
        { value: 2, title: 'ASC tilapia' },
        { value: 3, title: 'BAP' },
        { value: 4, title: 'BRC Food' },
        { value: 5, title: 'Amfori BSCI' },
        { value: 6, title: 'GlobalG.A.P. CoC' },
        { value: 7, title: 'Halal' },
        { value: 8, title: 'IFS Broker' },
        { value: 9, title: 'IFS Food' },
        { value: 10, title: 'IFS Logistics' },
        { value: 11, title: 'MSC CoC' },
        { value: 12, title: 'Other' },
      ];
    case CHANNELS.BANANAS:
      return [
        { value: 1, title: 'IFS Food' },
        { value: 2, title: 'BRC Food' },
        { value: 3, title: 'IKB' },
        { value: 4, title: 'KAT' },
        { value: 5, title: 'BLK Level' },
        { value: 6, title: 'Other' },
      ];
    case CHANNELS.EGGS:
      return [
        { value: 1, title: 'IFS Food' },
        { value: 2, title: 'BRC Food' },
        { value: 3, title: 'IKB' },
        { value: 4, title: 'KAT' },
        { value: 5, title: 'BLK Level' },
      ];
    default:
      return [];
  }
};

export const makeFullDataObject = (
  channelName: CHANNELS,
  values: unknown,
  fileUrl: number | null,
) => {
  switch (channelName) {
    case CHANNELS.JUICE:
    case CHANNELS.ORANGES: {
      const formValues = values as SubmitFormValuesOranges;
      return {
        documentCategory: formValues.documentCategory.value,
        documentType: formValues.documentType.value,
        documentNumber: formValues.documentNumber,
        documentResult: formValues.documentResult.value,
        auditDate: formValues.auditDate || null,
        documentScope: (formValues.documentScope && formValues.documentScope.value) || null,
        from: formValues.from,
        until: formValues.until,
        notes: formValues.notes || null,
        fileUrl,
        filename: formValues.certFile[0].name,
        filesize: formValues.certFile[0].size,
      };
    }

    case CHANNELS.EGGS:
    case CHANNELS.BANANAS:
    case CHANNELS.TILAPIA: {
      const formValues = values as SubmitFormValuesTilapiaBananasEggs;
      return {
        documentType: formValues.documentType.label,
        documentNumber: formValues.documentNumber || null,
        entityId: formValues.entityId || null,
        entityCertified:
          formValues.entityCertified && isSelectValue(formValues.entityCertified)
            ? formValues.entityCertified.label
            : formValues.entityCertified || null,
        auditDate: formValues.auditDate || null,
        from: formValues.from,
        until: formValues.until,
        notes: formValues.notes || null,
        fileUrl,
        filename: formValues.certFile[0].name,
        filesize: formValues.certFile[0].size,
      };
    }
  }
};

interface Entity {
  [key: string]: {
    id: number;
    label: string;
  };
}

const mapEntityToObject = (entity: Entity) => {
  return (
    entity &&
    Object.keys(entity).map((s) => ({
      value: entity[s].id,
      title: entity[s].label,
    }))
  );
};

export const useOrangesFields = (
  documentConfig: DocumentConfig,
  serializedDocumentConfig: SerializedDocumentConfig,
  formValues: Partial<SubmitFormValuesOranges>,
) => {
  const getDCategories = () =>
    formValues.documentCategory && serializedDocumentConfig[formValues.documentCategory.label];
  const getDTypes = () =>
    formValues.documentCategory &&
    formValues.documentType &&
    serializedDocumentConfig[formValues.documentCategory.label].documentTypes[
      formValues.documentType.label
    ];

  const documentCategories = useMemo(() => mapEntityToObject(serializedDocumentConfig) || [], [
    serializedDocumentConfig,
  ]);

  const documentTypes = useMemo(() => {
    const dCategories = getDCategories();
    return (
      (dCategories && dCategories.documentTypes && mapEntityToObject(dCategories.documentTypes)) ||
      []
    );
  }, [formValues.documentCategory]);

  const documentResults = useMemo(() => {
    const dTypes = getDTypes();
    return (dTypes && dTypes.documentResults && mapEntityToObject(dTypes.documentResults)) || [];
  }, [documentTypes, formValues.documentType]);

  const documentScopes = useMemo(() => {
    const dTypes = getDTypes();
    return (dTypes && dTypes.documentScopes && mapEntityToObject(dTypes.documentScopes)) || [];
  }, [documentTypes, formValues.documentType]);

  return { documentCategories, documentTypes, documentResults, documentScopes };
};

export const useOrangesJuiceClearFieldsAfterChange = (
  change: (field: string, value: any) => void,
  untouch: (field: string) => void,
  formValues: Partial<SubmitFormValuesOranges>,
) => {
  const clearAllFields = useCallback(
    (fields: string[]) =>
      fields.forEach((field) => {
        change(field, null);
        untouch(field);
      }),
    [],
  );

  useEffect(() => {
    const fieldsToClear = ['documentType', 'documentResult', 'documentScope'];
    clearAllFields(fieldsToClear);
  }, [formValues.documentCategory]);

  useEffect(() => {
    const fieldsToClear = ['documentResult', 'documentScope'];
    clearAllFields(fieldsToClear);
  }, [formValues.documentType]);
};
