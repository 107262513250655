import { Theme } from 'lib/lib';

export default (theme: Theme) => ({
  component: {
    padding: ['2em', 0],
  },
  tableRow: {
    display: 'flex',
    color: theme.colors.black,
    backgroundColor: theme.colors.background,
    border: '1px solid rgb(222, 222, 222)',
    borderTop: 0,
    '&:nth-of-type(even)': {
      backgroundColor: theme.colors.white,
      overflow: 'hidden',
    },
    '& .left': {
      flex: '0 0 50%',
      padding: [5, 8],
      textAlign: 'left',
      borderRight: '1px solid rgb(222, 222, 222)',
      display: 'flex',
      alignItems: 'center',
    },
    '& .right': {
      flex: '0 0 50%',
      padding: [5, 8],
      textAlign: 'left',
      display: 'flex',
      alignItems: 'center',
      '& svg': {
        width: 24,
        height: 24,
        fill: 'green',
      },
      '& .warning svg': {
        width: 15,
        height: 15,
        fill: 'red',
        marginLeft: 5,
      },
    },
  },
});
