import { Theme } from 'lib/lib';

export default (theme: Theme) => ({
  root: {},
  content: {
    width: theme.grid.width,
    margin: '0 auto',
  },
  mainMenu: {
    display: 'flex',
    width: '100%',
    height: 56,
    backgroundColor: theme.colors.backgroundHead,
    borderBottom: [4, 'solid', theme.colors.mainMenuLine],
    boxShadow: 'rgba(0, 0, 0, 0.2) 2px 0px 5px',
    position: 'relative',
    zIndex: 2,
    '& .notificationCount': {
      position: 'absolute',
      top: 0,
      left: '50%',
      backgroundColor: theme.colors.white,
      border: `1px solid ${theme.colors.mainMenuIconColor}`,
      borderRadius: 8,
      padding: '0 3px',
    },
    '& $mainMenuInner': {
      position: 'relative',
      display: 'flex',
      width: theme.grid.width,
      margin: '0 auto',
      justifyContent: 'space-between',
      '& .mainLogo': {
        display: 'flex',
        alignItems: 'center',
        '& a': {
          '& img': {
            maxWidth: 120,
            maxHeight: 35,
          },
        },
      },
      '& $links': {
        display: 'flex',
        justifyContent: 'flex-start',
        flexGrow: 5,
        '& $link': {
          display: 'flex',
          justifyContent: 'flex-start',
          marginLeft: 55,
          alignItems: 'center',
          position: 'relative',
          '& .icon': {
            position: 'relative',
            '& svg': {
              fill: theme.colors.mainMenuIconColor,
              width: 32,
              height: 32,
            },
          },
          '& .title': {
            marginLeft: 18,
            color: theme.colors.mainMenuTextColor,
            fontSize: theme.fontSizes.sm,
            '&:hover, &:active, &:focus': {
              textDecoration: 'none',
            },
          },
          '&.disabled': {
            userSelect: 'none',
            cursor: 'default',
            opacity: 0.5,
          },
        },
      },
      '& $dropDown': {
        display: 'flex',
        alignItems: 'center',
        fontSize: theme.fontSizes.sm,
        '& .hello': {
          color: theme.colors.mainMenuTextColor,
          marginLeft: 5,
        },
        '& .channel': {
          position: 'relative',
          '& svg': {
            fill: theme.colors.mainMenuTextColor,
            marginLeft: 5,
            width: 20,
            height: 20,
          },
        },

        '& .icon': {
          cursor: 'pointer',
          '& svg': {
            width: 40,
            height: 60,
          },
        },
      },
    },
  },

  mainMenuInner: {},
  links: {},
  link: {
    cursor: 'pointer',
  },
  dropDown: {},

  subMainMenu: {
    width: '100%',
    backgroundColor: 'rgb(252, 252, 252)',
    '& .inner': {
      display: 'flex',
      justifyContent: 'space-between',
      width: theme.grid.width,
      margin: '0 auto',
      padding: [9, 0],
      '& .left-side': {
        display: 'flex',
        flex: '0 0 50%',
        justifyContent: 'flex-start',
        '& .status': {
          marginRight: '2em',
          userSelect: 'none',
          font: {
            family: 'Lato',
            size: 24,
            weight: 300,
          },
          color: 'rgb(58, 85, 108)',
          letterSpacing: 1,
          '& a': {
            font: {
              family: 'Lato',
              size: 24,
              weight: 300,
            },
            '&.active': {
              font: {
                weight: 500,
              },
            },
          },
          '& svg': {
            fill: theme.colors.primary,
            margin: [0, '0.5em'],
          },
        },
        '& .search': {
          marginRight: '1em',
          position: 'relative',
          '& svg': {
            fill: 'rgb(176, 176, 176)',
            position: 'absolute',
          },
          '& .searchSvg': {
            position: 'absolute',
            width: 20,
            height: 20,
            top: 6,
            left: 3,
          },
          '& .clearSvg': {
            position: 'absolute',
            width: 18,
            height: 18,
            top: 6,
            right: 5,
          },
          '& input': {
            backgroundColor: 'rgb(240, 240, 240)',
            borderRadius: 5,
            boxShadow: 'rgba(0, 0, 0, 0.1) 1px 1px 1px 0px inset',
            border: '1px solid rgb(200, 200, 200)',
            outline: 0,
            height: 30,
            opacity: 0.8,
            padding: [5, 20, 5, 25],
            font: {
              size: 14,
            },
          },
        },
      },
      '& .right-side': {
        display: 'flex',
        flex: '0 0 50%',
        maxHeight: 30,
        justifyContent: 'flex-end',
        '& .export': {
          marginLeft: '1em',
          display: 'flex',
          alignItems: 'center',
          cursor: 'pointer',
          '& span': {
            marginRight: 10,
          },
        } /*
        '& .create': {
          marginLeft: '2em',
          display: 'flex',
          alignItems: 'center',
          cursor: 'pointer',
          '& span': {
            marginRight: 10,
          },
        }, */,
        '& .right-side-logo': {
          marginLeft: '2em',
          display: 'flex',
          alignItems: 'center',
          '& span': {
            marginRight: 10,
            fontSize: 12,
          },
        },
      },
    },
  },

  breadcrumbs: {
    width: '100%',
    backgroundColor: 'rgb(250, 250, 250)',
    boxShadow: 'inset rgba(0, 0, 0, 0.2) 0px 1px 2px 0px',
    color: 'rgb(143, 143, 143)',
    '& .inner': {
      width: theme.grid.width,
      margin: '0 auto',
      padding: [12, 0],
      font: {
        size: 11,
      },
      '& a': {
        color: theme.colors.primary,
      },
    },
  },
});
