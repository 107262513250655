import { httpClient } from 'lib/http';
import { defineEndpointSemantics } from 'themes/main';

const prefix = '/api/v1';

export default {
  // api/v1/(bananas|tilapia)/background-worker-tasks/queue-state
  fetchQueueStatus: (channelName) =>
    httpClient.get(`${prefix}/${channelName}/background-worker-tasks/queue-state`),
  signIn: ({ data }) => httpClient.post(`${prefix}/sign-in`, data),
  signUp: ({ data }) => httpClient.post(`${prefix}/sign-up`, data),

  fetchProfile: () => httpClient.get(`${prefix}/profile`),

  // profile
  sendEmailForRecoverPassword: ({ email }) =>
    httpClient.post(`${prefix}/recover-password`, { email }),
  sendResetPassword: ({ headers, password, passwordConfirmation }) =>
    httpClient.post(
      `${prefix}/set-new-password/`,
      { password, password_confirmation: passwordConfirmation },
      { headers },
    ),
  sendChangePassword: ({ headers, password, oldPassword, passwordConfirmation }) =>
    httpClient.post(
      `${prefix}/change-password/`,
      { oldPassword, password, password_confirmation: passwordConfirmation },
      { headers },
    ),

  // Env
  fetchTableColumns: ({ storageKey }) =>
    httpClient.get(`${prefix}/users/view_config/${storageKey}`),
  setTableColumns: ({ storageKey, columns }) =>
    httpClient.put(`${prefix}/users/view_config/${storageKey}`, { config: columns }),
  fetchUserNameByParticipant: ({ participantId }) =>
    httpClient.get(`${prefix}/participants/${encodeURIComponent(String(participantId))}`),
  fetchNotificationConfig: () => httpClient.get(`${prefix}/users/notification_configs/`),
  updateNotificationConfig: ({ data }) =>
    httpClient.put(`${prefix}/users/notification_configs/update_config`, { resource: data }),

  // service-data
  fetchServiceData: ({ channelName }) => httpClient.get(`${prefix}/${channelName}/service-data`),

  // documents
  fetchDocuments: ({ filters }) => httpClient.get(`${prefix}/documents${filters}`),
  fetchDocument: ({ id }) => httpClient.get(`${prefix}/documents/${id}`),
  createDocument: ({ data }) => httpClient.post(`${prefix}/documents`, data),
  deleteDocument: ({ id }) => httpClient.delete(`${prefix}/documents/${id}`),
  uploadCertFile: ({ headers, data }) =>
    httpClient.post(`${prefix}/certFile`, data, { headers, withCredentials: true }),

  // unmatched batch contracts
  fetchUnmatchedBatchContracts: () => httpClient.get(`${prefix}/unmatched`),

  fetchUnmatched: ({ beginDate, endDate, scope, channelName }) =>
    httpClient.get(
      `${prefix}/${channelName}/unmatched${
        scope ? `?scope=${scope}&filter[beginDate]=${beginDate}&filter[endDate]=${endDate}` : ''
      }`,
    ),
  fetchUnmatchedStatistics: ({ beginDate, endDate, scope, channelName }) =>
    httpClient.get(
      `${prefix}/${channelName}/unmatched/total${
        scope ? `?scope=${scope}&filter[beginDate]=${beginDate}&filter[endDate]=${endDate}` : ''
      }`,
    ),

  // notifications
  fetchNotifications: ({ queryString }) =>
    httpClient.get(`${prefix}/users/notifications${queryString}`),
  fetchNotification: ({ notificationId }) =>
    httpClient.get(`${prefix}/users/notifications/${notificationId}`),
  updateNotification: ({ id, data }) =>
    httpClient.put(`${prefix}/users/notifications/${id}`, { resource: data }),

  // batches
  fetchBatches: ({ batchEndpoint, queryString }) =>
    httpClient.get(`${prefix}/${batchEndpoint}${queryString}`),
  fetchBatch: ({ batchEndpoint, batchId, filters }) =>
    httpClient.get(
      `${prefix}/${batchEndpoint.toLowerCase()}/${batchId}${
        filters
          ? `?${Object.keys(filters)
              .map((k) => `filter[${k}]=${filters[k]}`)
              .join('&')}`
          : ''
      }`,
    ),
  // fetchBatchContract: ({ batchEndpoint, contractNumber, status }) =>
  //   httpClient.get(
  //     `${prefix}/${batchEndpoint.toLowerCase()}?filter[contract]=${contractNumber}${
  //       status ? `&filter[status]=${status}` : ''
  //     }`,
  //   ),
  fetchBatchValidation: ({ endpoint, batchNumber }) =>
    httpClient.get(`${prefix}/${endpoint}/${batchNumber}/connected`),
  approveBatchValidation: ({ endpoint, batchNumber, data }) =>
    httpClient.post(`${prefix}/${endpoint}/connected/approve/${batchNumber}`, data),
  fetchBatchContract: ({ batchEndpoint, contractNumber, status }) =>
    httpClient.get(
      `${prefix}/${batchEndpoint.toLowerCase()}/contracts/${encodeURIComponent(contractNumber)}${
        status ? `?filter[status]=${status}` : ''
      }`,
    ),
  fetchBatchHandshake: ({ endpoint, filters }) =>
    httpClient.get(
      `${prefix}/${endpoint}/handshake/${
        filters
          ? `?${Object.keys(filters)
              .map((k) => `filter[${k}]=${encodeURIComponent(filters[k])}`)
              .join('&')}`
          : ''
      }`,
    ),
  uploadBatches: ({ endpoint, batchesToSend }) =>
    httpClient.post(`${prefix}/${endpoint}`, batchesToSend),
  splitUploadBatches: ({ endpoint, batchesToSend }) =>
    httpClient.post(`${prefix}/refrescobatch/split_upload`, batchesToSend),
  uploadExcelBatches: ({ data, batchEndpoint }) =>
    httpClient.post(`${prefix}/${batchEndpoint}`, {
      [defineEndpointSemantics(batchEndpoint)]: data,
    }),
  deleteBatchContract: ({ contractNumber, batchEndpoint }) =>
    httpClient.delete(
      `${prefix}/${String(batchEndpoint).toLowerCase()}?contract=${contractNumber}`,
    ),
  deleteBatch: ({ endpoint, batchId }) => httpClient.delete(`${prefix}/${endpoint}/${batchId}`),
  archiveBatchContract: ({ endpoint, batchNumber }) =>
    httpClient.delete(`${prefix}/${endpoint}/contracts/${encodeURIComponent(batchNumber)}`),
  deleteMultipleBatches: ({ endpoint, data }) => httpClient.put(`${prefix}/${endpoint}`, data),

  // requests
  fetchRequests: ({ requestType }) => httpClient.get(`${prefix}/${requestType}`),
  fetchRequest: ({ requestType, requestId }) =>
    httpClient.get(`${prefix}/${requestType}/${requestId}`),
  createRequest: ({ data }) => httpClient.post(`${prefix}/outbox`, data),
  updateInboxRequest: ({ id, data }) => httpClient.put(`${prefix}/inbox/${id}`, data),

  // approve
  approveDocument: ({ certId, data }) =>
    httpClient.post(`${prefix}/approve/document/${certId}`, data),
  approveBatchContract: ({ contractId, data, endpoint }) =>
    httpClient.post(
      `${prefix}/${endpoint}/contracts/approve/${encodeURIComponent(contractId)}`,
      data,
    ),

  // admin users
  fetchUsers: ({ filter }) => httpClient.get(`${prefix}/admin/users`, { params: { filter } }),
  deleteUser: ({ id }) => httpClient.delete(`${prefix}/admin/users/${id}`),
  approveAdminUser: ({ id }) => httpClient.put(`${prefix}/admin/users/approve/${id}`),
  suspendAdminUser: ({ id }) => httpClient.put(`${prefix}/admin/users/suspend/${id}`),

  // admin invitations
  fetchInvitations: () => httpClient.get(`${prefix}/admin/invitations`),
  createInvitation: ({ data }) => httpClient.post(`${prefix}/admin/invitations`, data),
  updateInvitation: ({ id, data }) => httpClient.put(`${prefix}/admin/invitations/${id}`, data),
  resendInvitation: ({ data }) => httpClient.post(`${prefix}/admin/invitations/resend/`, data),
};
