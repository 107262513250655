import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
// styles
import { WithStyles } from 'react-jss';
import mainLayoutStyles from 'pages/layouts/MainLayout/mainLayoutStyles';
// redux
import { getMatchedRoute, getParams } from 'domain/common';
// utils
import { getUrl, routes } from 'routes';
// types
import { AppStateType } from 'types';
import { IndexedObject, MatchedRoute } from 'lib/lib';

const mapStateToProps = (state: AppStateType) => ({
  params: getParams(state),
  matchedRoute: getMatchedRoute(state),
});

const Breadcrumbs: React.FC<WithStyles<typeof mainLayoutStyles>> = (props) => {
  const { classes } = props;
  const { params, matchedRoute } = useSelector(mapStateToProps, shallowEqual);
  const getBreadcrumbs = () => {
    const defaultRoute = routes.MainLayout.find((route) => route.default) as MatchedRoute;
    const routesToRender = [defaultRoute];

    if (matchedRoute.breadcrumbs && matchedRoute.breadcrumbs.addRoutes) {
      matchedRoute.breadcrumbs.addRoutes.forEach((comp) => {
        const obtRoute = routes.MainLayout.find((r) => r.name === comp) as MatchedRoute;
        if (obtRoute) {
          routesToRender.push(obtRoute);
        }
      });
    }

    if (matchedRoute.name !== defaultRoute.name) {
      routesToRender.push(matchedRoute);
    }

    const getBreadcrumbsTitle = (r: MatchedRoute, paramsC: IndexedObject<string>) => {
      if (r.breadcrumbs && r.breadcrumbs.showParam) {
        const pageTitle = r.breadcrumbs.showParam;
        return paramsC[pageTitle];
      }
      return r.pageTitle;
    };

    return routesToRender.map((r, i) => (
      <React.Fragment key={`${r.name}${i}`}>
        <Link to={{ pathname: getUrl(r.name, params) }}>{getBreadcrumbsTitle(r, params)}</Link>
        {routesToRender[i + 1] && <span> / </span>}
      </React.Fragment>
    ));
  };

  return (
    <div className={classes.breadcrumbs}>
      <div className="inner">{getBreadcrumbs()}</div>
    </div>
  );
};

export default React.memo(Breadcrumbs);
