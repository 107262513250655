import produce from 'immer';
import { AnyAction } from 'redux';

import * as envActions from 'domain/env/actions';
import * as uiActions from './actions';

const initialState = {
  isLoading: false,
  modal: null,
};

const ui = produce<(draft: typeof initialState, action: AnyAction) => void>(
  (draft, { type, payload }) => {
    switch (type) {
      case envActions.logInAction.type:
        draft.isLoading = true;
        return;

      case envActions.logOutAction.success:
        return initialState;

      case envActions.logInAction.failure:
        draft.isLoading = false;
        return;

      case uiActions.setUiIsLoadingAction.type:
        draft.isLoading = payload;
        return;

      case uiActions.uiModalAction.type:
        draft.modal = payload;
        return;

      case uiActions.uiModalAction.success:
        draft.modal = null;
        return;

      case uiActions.uiModalAction.failure:
        draft.modal = null;
        return;
    }
  },
  initialState,
);

export const reducer = { ui };
