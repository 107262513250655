import cerificates from 'mocks/responses/certificates/certificatesList';

const sortArchivedCertifivcates = (documents, isArchived) =>
  isArchived ? documents.archived : documents.active;

export const getCertificates = (user,channel, isArchived = false) =>
  sortArchivedCertifivcates(cerificates[user.orgName][channel], isArchived);

export const getCertificateById = (user, id) => {
  const allCertificates = getCertificates(user, true);
  allCertificates.push(...getCertificates(user, false));
  return allCertificates.find((certificate) => certificate.certId === id);
};
