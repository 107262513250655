import { Theme } from 'lib/lib';
import { Theme as ReactSelectTheme } from 'react-select';

export default (theme: Theme) => ({
  adminNav: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: [0, 0, '2em', 0],
    '& nav': {
      '&  a': {
        margin: [0, 10],
      },
      '&  a:first-child': {
        margin: [0, 10, 0, 0],
      },
    },
    '& .controls': {
      display: 'flex',
      zIndex: 3,
    },
    '& .newInvitation': {
      ...theme.components.btn,
      margin: [0, 0, 0, '1em'],
      display: 'inline-flex',
      alignItems: 'center',
      height: 30,
      padding: [0, '1em'],
      '& svg': {
        width: 17,
        height: 17,
        fill: theme.colors.white,
        marginRight: 10,
      },
      '& span': {
        font: {
          size: theme.fontSizes.xs,
          weight: 400,
        },
        color: theme.colors.white,
      },
    },
  },

  select: {
    '& .react-select__control': {
      minHeight: 30,
    },
    '& .react-select__indicator': {
      padding: [2, 4, 2, 4],
    },
  },

  selectStylesDefault: {
    minWidth: 150,

    '& .react-select__control': {

      '&--is-focused, &:hover': {
        borderColor: '#0f32c9',
        boxShadow: 'none',
      },
    },

    '& .react-select__menu': {
      zIndex: 10,
    },

    '& .react-select__option': {
      color: theme.colors.black,
      textAlign: 'left',
      padding: '8px 4px 8px 8px',
      fontSize: '0.95em',
    },

    '&.error': {
      '& .react-select__control': {
        borderColor: '#dc233b',
        boxShadow: 'none',
      },
    },
  },
});

export const activeNavStyles = {
  textDecoration: 'underline',
};

export const defaultTheme = (theme: ReactSelectTheme) => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary: 'rgba(143, 143, 143, 1)',
    primary75: 'rgba(143, 143, 143, 0.75)',
    primary50: 'rgba(143, 143, 143, 0.5)',
    primary25: 'rgba(143, 143, 143, 0.25)',
  },
});
