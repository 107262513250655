import React from 'react';
import { Link } from 'react-router-dom';
import { getUrl } from 'routes';
import baseModelFields from 'models/baseModelFields';
import { CHANNELS, ORGANIZATIONS } from 'themes/constants';
import { WarningSvg } from 'components/icons';
import { renderShortDateFormat } from 'lib/renderHelpers';
import { BatchRecord, ModelRequiredInfo } from 'lib/lib';

// label defines what field to take from CSV batch file for upload

export default (modelRequiredInfo: ModelRequiredInfo) => [
  // {
  //   key: 'releaseNumber',
  //   label: 'Release Number',
  //   title: 'Release Number',
  //   style: { flex: '1 0 100px' },
  //   defaultSelectedBatchContractColumn: true,
  //   render: (column: BatchRecord) => (
  //     <Link
  //       className="contract"
  //       to={{
  //         pathname: getUrl('BatchContractType', {
  //           type: ORGANIZATIONS.JDE.toLowerCase(),
  //           contract_number: `${column.releaseNumber}`,
  //           modelType: 'contractDetails',
  //         }),
  //       }}
  //     >
  //       column.releaseNumber
  //     </Link>
  //   ),
  // },
  {
    key: 'batchesLevelOne',
    label: 'Batches Level1',
    title: 'Batches Level1',
    style: { flex: '1 0 50px' },
    defaultSelectedBatchContractColumn: true,
    render: (column: BatchRecord) => (
      <React.Fragment>
        <Link
          className="contract"
          to={{
            pathname: getUrl('BatchContractType', {
              type: ORGANIZATIONS.LDCBR.toLowerCase(),
              contract_number: encodeURIComponent(encodeURIComponent(column.batchesLevelOne)),
              modelType: 'contractDetails',
              channelName: column.channelName,
            }),
          }}
        >
          {column.batchesLevelOne}
        </Link>
        {column.subcontractAmount === 0 && (
          <span className="warning">
            <WarningSvg />
          </span>
        )}
      </React.Fragment>
    ),
  },
  {
    key: 'releaseNumber',
    label: 'Release Number',
    title: 'Release Number',
    style: { flex: '1 0 100px' },
    defaultSelectedBatchContractColumn: true,
    render: (column: BatchRecord) => column.releaseNumber,
  },
  {
    key: 'kgShipped',
    label: 'KG shipped',
    title: 'KG shipped',
    defaultSelectedBatchContractColumn: true,
    style: { flex: '1 0 70px' },
    render: (column: BatchRecord) => column.kgShipped,
  },
  {
    key: 'shipToNumber',
    label: 'Ship To Number',
    title: 'Ship To Number',
    defaultSelectedBatchContractColumn: true,
    style: { flex: '1 0 100px' },
    render: (column: BatchRecord) => column.shipToNumber,
  },
  {
    key: 'shipTo',
    label: 'Ship To',
    title: 'Ship To',
    style: { flex: '1 0 50px' },
    render: (column: BatchRecord) => column.shipTo,
  },
  {
    key: 'invoiceDate',
    label: 'Invoice Date',
    title: 'Invoice Date',
    defaultSelectedBatchContractColumn: true,
    style: { flex: '1 0 70px' },
    render: (column: BatchRecord) => renderShortDateFormat(column.invoiceDate),
  },
  {
    key: 'contractNumber',
    label: 'Contract Number',
    title: 'Contract Number',
    style: { flex: '1 0 50px' },
    render: (column: BatchRecord) => column.contractNumber,
  },
  {
    key: 'customerReference',
    label: 'Customer Reference',
    title: 'Customer Reference',
    style: { flex: '1 0 50px' },
    render: (column: BatchRecord) => column.customerReference,
  },
  {
    key: 'secondItemNumber',
    label: '2nd Item Number',
    title: '2nd Item Number',
    style: { flex: '1 0 50px' },
    render: (column: BatchRecord) => column.secondItemNumber,
  },
  {
    key: 'kpiRatio',
    label: 'KPI ratio',
    title: 'KPI ratio',
    defaultSelectedBatchContractColumn: true,
    style: { flex: '1 0 50px' },
    render: (column: BatchRecord) => column.kpiRatio,
  },
  {
    key: 'lotSerialNumber',
    label: 'Lot Serial Number',
    title: 'Lot Serial Number',
    style: { flex: '1 0 50px' },
    defaultSelectedBatchContractColumn: true,
    render: (column: BatchRecord) => column.lotSerialNumber,
  },
  {
    key: 'blDate',
    label: 'B/L Date',
    title: 'B/L Date',
    style: { flex: '1 0 50px' },
    render: (column: BatchRecord) => renderShortDateFormat(column.blDate),
  },
  {
    key: 'receiptDate',
    label: 'Receipt Date',
    title: 'Receipt Date',
    style: { flex: '1 0 50px' },
    render: (column: BatchRecord) => renderShortDateFormat(column.receiptDate),
  },
  {
    key: 'batchId',
    label: 'Batch Id',
    title: 'Batch Details',
    style: { flex: '0 0 150px' },
    render: (column: BatchRecord) => (
      <Link
        to={{
          pathname: getUrl('BatchDetailsType', {
            type: ORGANIZATIONS.JDE.toLowerCase(),
            batch_id: column.batchId,
            modelType: 'details',
            channelName: column.channelName,
          }),
        }}
        title={column.batchId}
        className="batch-details"
      >
        Details
      </Link>
    ),
  },
  ...baseModelFields(modelRequiredInfo),
];
