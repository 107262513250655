export default () => ({
  $class: 'org.powerchain.bananas.batch.DistributorBatchConceptWithDetails',
  details: [
    {
      $class: 'org.powerchain.bananas.batch.BatchDetailsConcept',
      createdAt: '2019-07-20T09:33:23.309Z',
      modifiedAt: '2019-07-01T01:16:26.951Z',
      createdBy:
        'resource:org.powerchain.bananas.member.DistributorUser#DistributorUser_8046fbbc-947b-4ce4-963f-648085a2924a',
      modifiedBy:
        'resource:org.powerchain.bananas.member.DistributorUser#36f4d07c-329a-4956-9400-91c857ba5471',
      status: 'PENDING',
      "modifiedBy_participant":{
        "username":null,
        "email":null,
        "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        },
        "channel":{
          "title":"Bananas",
          "name":"bananas"
        },
        "user":{
          "username":"Fyffes TST staging",
          "email":"FyffesTST@pc.t2f.io",
          "org":{
            "name":"Fyffes",
            "fullname":"Fyffes",
            "logo":null
          }
        }
      },
      "createdBy_participant":{
        "username":null,
        "email":null,
        "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        },
        "channel":{
          "title":"Bananas",
          "name":"bananas"
        },
        "user":{
          "username":"Fyffes TST staging",
          "email":"FyffesTST@pc.t2f.io",
          "org":{
            "name":"Fyffes",
            "fullname":"Fyffes",
            "logo":null
          }
        }
      }
    },
  ],
  inslagBatches: [
    {
      $class: 'org.powerchain.bananas.batch.DistributorInslagBatch',
      relationshipCodeName: 'Z3SDX7 Fyffes BV',
      mutationDate: '2018-08-21T12:36:26.747Z',
      batchNumber: '0826600',
      SSCCCode: '7367151096',
      packagesCount: 28,
      batchId: 'DistributorInslagBatch_5141fd2a-b915-41d8-8443-30f3c007163e',
      createdAt: '2019-07-20T09:33:23.309Z',
      createdBy:
        'resource:org.powerchain.bananas.member.DistributorUser#DistributorUser_8046fbbc-947b-4ce4-963f-648085a2924a',
      modifiedAt: '2019-07-01T01:16:26.951Z',
      modifiedBy:
        'resource:org.powerchain.bananas.member.DistributorUser#36f4d07c-329a-4956-9400-91c857ba5471',
      status: 'PENDING',
      uploadSource: 'api',
      "modifiedBy_participant":{
        "username":null,
        "email":null,
        "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        },
        "channel":{
          "title":"Bananas",
          "name":"bananas"
        },
        "user":{
          "username":"Fyffes TST staging",
          "email":"FyffesTST@pc.t2f.io",
          "org":{
            "name":"Fyffes",
            "fullname":"Fyffes",
            "logo":null
          }
        }
      },
      "createdBy_participant":{
        "username":null,
        "email":null,
        "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        },
        "channel":{
          "title":"Bananas",
          "name":"bananas"
        },
        "user":{
          "username":"Fyffes TST staging",
          "email":"FyffesTST@pc.t2f.io",
          "org":{
            "name":"Fyffes",
            "fullname":"Fyffes",
            "logo":null
          }
        }
      }
    },
    {
      $class: 'org.powerchain.bananas.batch.DistributorInslagBatch',
      relationshipCodeName: 'WDXV1Q Fyffes BV',
      mutationDate: '2019-04-06T05:17:21.727Z',
      batchNumber: '0826600',
      SSCCCode: '15023522',
      packagesCount: 87,
      batchId: 'DistributorInslagBatch_7147e3b4-e10e-44ab-9279-feb139d0d0e2',
      createdAt: '2019-07-20T09:33:23.309Z',
      createdBy:
        'resource:org.powerchain.bananas.member.DistributorUser#DistributorUser_8046fbbc-947b-4ce4-963f-648085a2924a',
      modifiedAt: '2018-08-02T07:19:46.938Z',
      modifiedBy:
        'resource:org.powerchain.bananas.member.DistributorUser#0193f431-5668-4dcb-b2fc-c7cdeb41a156',
      status: 'APPROVED',
      uploadSource: 'api',
      subcontractAmount: 0,
      "modifiedBy_participant":{
        "username":null,
        "email":null,
        "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        },
        "channel":{
          "title":"Bananas",
          "name":"bananas"
        },
        "user":{
          "username":"Fyffes TST staging",
          "email":"FyffesTST@pc.t2f.io",
          "org":{
            "name":"Fyffes",
            "fullname":"Fyffes",
            "logo":null
          }
        }
      },
      "createdBy_participant":{
        "username":null,
        "email":null,
        "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        },
        "channel":{
          "title":"Bananas",
          "name":"bananas"
        },
        "user":{
          "username":"Fyffes TST staging",
          "email":"FyffesTST@pc.t2f.io",
          "org":{
            "name":"Fyffes",
            "fullname":"Fyffes",
            "logo":null
          }
        }
      }
    },
  ],
  uitslagBatches: [
    {
      $class: 'org.powerchain.bananas.batch.DistributorUitslagBatch',
      mutationDate: '2019-03-30T18:24:06.053Z',
      batchNumber: '0826600',
      relationshipCodeName: 'S2E6DE Plus Meeuw (Akkerhof)',
      packagesCount: 86,
      batchId: 'DistributorUitslagBatch_3d891f93-5676-495c-ae59-c91aae8abdd5',
      createdAt: '2019-07-20T09:33:23.309Z',
      createdBy:
        'resource:org.powerchain.bananas.member.DistributorUser#DistributorUser_8046fbbc-947b-4ce4-963f-648085a2924a',
      modifiedAt: '2018-11-16T08:19:59.900Z',
      modifiedBy:
        'resource:org.powerchain.bananas.member.DistributorUser#c6568fe2-1bf3-4f70-b1d5-67deaa411285',
      status: 'PENDING',
      uploadSource: 'api',
      "modifiedBy_participant":{
        "username":null,
        "email":null,
        "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        },
        "channel":{
          "title":"Bananas",
          "name":"bananas"
        },
        "user":{
          "username":"Fyffes TST staging",
          "email":"FyffesTST@pc.t2f.io",
          "org":{
            "name":"Fyffes",
            "fullname":"Fyffes",
            "logo":null
          }
        }
      },
      "createdBy_participant":{
        "username":null,
        "email":null,
        "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        },
        "channel":{
          "title":"Bananas",
          "name":"bananas"
        },
        "user":{
          "username":"Fyffes TST staging",
          "email":"FyffesTST@pc.t2f.io",
          "org":{
            "name":"Fyffes",
            "fullname":"Fyffes",
            "logo":null
          }
        }
      }
    },
    {
      $class: 'org.powerchain.bananas.batch.DistributorUitslagBatch',
      mutationDate: '2019-01-30T10:05:35.274Z',
      batchNumber: '0826600',
      relationshipCodeName: 'WANQ9D Plus Meeuw (Akkerhof)',
      packagesCount: 69,
      batchId: 'DistributorUitslagBatch_5b56116e-3e3b-4ae4-bda4-cc9fe238fb3d',
      createdAt: '2019-07-20T09:33:23.309Z',
      createdBy:
        'resource:org.powerchain.bananas.member.DistributorUser#DistributorUser_8046fbbc-947b-4ce4-963f-648085a2924a',
      modifiedAt: '2018-10-20T17:15:33.556Z',
      modifiedBy:
        'resource:org.powerchain.bananas.member.DistributorUser#8e3d3b83-9327-4dde-9db2-3fa8e8a77d3e',
      status: 'APPROVED',
      uploadSource: 'api',
      "modifiedBy_participant":{
        "username":null,
        "email":null,
        "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        },
        "channel":{
          "title":"Bananas",
          "name":"bananas"
        },
        "user":{
          "username":"Fyffes TST staging",
          "email":"FyffesTST@pc.t2f.io",
          "org":{
            "name":"Fyffes",
            "fullname":"Fyffes",
            "logo":null
          }
        }
      },
      "createdBy_participant":{
        "username":null,
        "email":null,
        "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        },
        "channel":{
          "title":"Bananas",
          "name":"bananas"
        },
        "user":{
          "username":"Fyffes TST staging",
          "email":"FyffesTST@pc.t2f.io",
          "org":{
            "name":"Fyffes",
            "fullname":"Fyffes",
            "logo":null
          }
        }
      }
    },
  ],
});
