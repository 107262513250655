import React from 'react';
import { UPLOAD_TIME_FORMAT } from 'themes/main';
import {
  validationCustomDate,
  validationRequired,
  validationString,
  validationInt,
  validationFloat,
} from 'lib/uploadFieldValidations';
import { customDatePreview, brazilTimeShift } from 'lib/renderHelpers';
import { BatchRecord, ModelRequiredInfo } from 'lib/lib';

export default (modelRequiredInfo: ModelRequiredInfo) => {
  const { CitrosucoGhent } = UPLOAD_TIME_FORMAT;
  const { invoiceDate, receiptDate } = CitrosucoGhent as Record<string, string>;
  const validateInvoiceDateFormat = validationCustomDate(invoiceDate);
  const validateReceiptDateFormat = validationCustomDate(receiptDate);

  return [
    {
      key: 'salesOrderNumber',
      label: 'Sales Order Number',
      title: 'Sales Order Number',
      style: { flex: '1 0 50px' },
      renderUploadPreview: (column: BatchRecord) => column.salesOrderNumber,
      validate: [validationRequired, validationString],
      edit: { type: 'input' },
    },
    {
      key: 'shipToNumber',
      label: 'Ship To Number',
      title: 'Ship To Number',
      style: { flex: '1 0 50px' },
      renderUploadPreview: (column: BatchRecord) => column.shipToNumber,
      validate: [validationRequired, validationInt],
      edit: { type: 'input' },
    },
    {
      key: 'kpiBrix',
      label: 'KPI Brix',
      title: 'KPI Brix',
      style: { flex: '1 0 50px' },
      renderUploadPreview: (column: BatchRecord) => column.kpiBrix,
      validate: [validationFloat],
      edit: { type: 'input' },
    },
    {
      key: 'kpiRatio',
      label: 'KPI Ratio',
      title: 'KPI Ratio',
      style: { flex: '1 0 50px' },
      renderUploadPreview: (column: BatchRecord) => column.kpiRatio,
      validate: [validationRequired, validationFloat],
      edit: { type: 'input' },
    },
    {
      key: 'kgShipped',
      label: 'KG Shipped',
      title: 'KG Shipped',
      style: { flex: '1 0 50px' },
      renderUploadPreview: (column: BatchRecord) => column.kgShipped,
      validate: [validationFloat],
      edit: { type: 'input' },
    },
    {
      key: 'invoiceDate',
      label: 'Invoice Date',
      title: 'Invoice Date',
      style: { flex: '1 0 50px' },
      handleParsedValue: (value: string) => brazilTimeShift(value, invoiceDate),
      renderUploadPreview: ({ invoiceDate }: BatchRecord) =>
        customDatePreview(invoiceDate, [validationRequired, validateInvoiceDateFormat]),
      validate: [validationRequired, validateInvoiceDateFormat],
      edit: { type: 'date' },
    },
    {
      key: 'receiptDate',
      label: 'Receipt Date',
      title: 'Receipt Date',
      style: { flex: '1 0 50px' },
      handleParsedValue: (value: string) => brazilTimeShift(value, receiptDate),
      renderUploadPreview: ({ receiptDate }: BatchRecord) =>
        customDatePreview(receiptDate, [validationRequired, validateReceiptDateFormat]),
      validate: [validationRequired, validateReceiptDateFormat],
      edit: { type: 'date' },
    },
    {
      key: 'ghentBatchId',
      label: 'Batch ID in Ghent',
      title: 'Batch ID in Ghent',
      style: { flex: '1 0 50px' },
      renderUploadPreview: (column: BatchRecord) => column.ghentBatchId,
      validate: [validationRequired, validationString],
      edit: { type: 'input' },
    },
    {
      key: 'ghentToVesselRatio',
      label: '% Batch ID in Ghent / Vessel',
      title: '% Batch ID in Ghent / Vessel',
      style: { flex: '1 0 50px' },
      renderUploadPreview: (column: BatchRecord) => column.ghentToVesselRatio,
      validate: [validationRequired, validationString],
      edit: { type: 'input' },
    },
    {
      key: 'vesselTkBatchId',
      label: 'Batch ID in Vessel TK',
      title: 'Batch ID in Vessel TK',
      style: { flex: '1 0 50px' },
      renderUploadPreview: (column: BatchRecord) => column.vesselTkBatchId,
      validate: [validationRequired, validationString],
      edit: { type: 'input' },
    },
    {
      key: 'vesselTkTrip',
      label: 'Vessel TK Trip',
      title: 'Vessel TK Trip',
      style: { flex: '1 0 50px' },
      renderUploadPreview: (column: BatchRecord) => column.vesselTkTrip,
      validate: [validationRequired, validationString],
      edit: { type: 'input' },
    },
    {
      key: 'truckBatchId',
      label: 'Batch ID Truck in Ghent',
      title: 'Batch ID Truck in Ghent',
      style: { flex: '1 0 50px' },
      renderUploadPreview: (column: BatchRecord) => column.truckBatchId,
      validate: [validationRequired, validationString],
      edit: { type: 'input' },
    },
    {
      key: 'productCode',
      label: 'Product Code',
      title: 'Product Code',
      style: { flex: '1 0 50px' },
      renderUploadPreview: (column: BatchRecord) => column.productCode,
      validate: [validationRequired, validationString],
      edit: { type: 'input' },
    },
  ];
};
