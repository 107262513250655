import React from 'react';
import { UPLOAD_TIME_FORMAT } from 'themes/main';
import {
  validationCustomDate,
  validationRequired,
  validationString,
  validationPositiveFloatInString,
} from 'lib/uploadFieldValidations';
import { brazilTimeShift, customDatePreview } from 'lib/renderHelpers';
import { BatchRecord, ModelRequiredInfo } from 'lib/lib';

export default (modelRequiredInfo: ModelRequiredInfo) => {
  const { CitrosucoBrazil } = UPLOAD_TIME_FORMAT;
  const {
    lastReceiptDateBr,
    finalManDate,
    harvestFinalDate,
    harvestStartDate,
    startManDate,
    blDate,
  } = CitrosucoBrazil as Record<string, string>;

  const validationLastReceiptDateBr = validationCustomDate(lastReceiptDateBr);
  const validationFinalManDate = validationCustomDate(finalManDate);
  const validationHarvestFinalDate = validationCustomDate(harvestFinalDate);
  const validationHarvestStartDate = validationCustomDate(harvestStartDate);
  const validationStartManDate = validationCustomDate(startManDate);
  const validationBlDate = validationCustomDate(blDate);

  return [
    {
      key: 'vesselTkBatchId',
      label: 'BATCH ID in VESSEL TK',
      title: 'BATCH ID in VESSEL TK',
      style: { flex: '1 0 50px' },
      renderUploadPreview: (column: BatchRecord) => column.vesselTkBatchId,
      validate: [validationRequired, validationString],
      edit: { type: 'input' },
    },
    {
      key: 'vesselTkTrip',
      label: 'VESSEL TK TRIP',
      title: 'VESSEL TK TRIP',
      style: { flex: '1 0 50px' },
      renderUploadPreview: (column: BatchRecord) => column.vesselTkTrip,
      validate: [validationRequired, validationString],
      edit: { type: 'input' },
    },
    {
      key: 'blDate',
      label: 'B/L date',
      title: 'B/L date',
      style: { flex: '1 0 50px' },
      handleParsedValue: (value: string) => brazilTimeShift(value, blDate),
      renderUploadPreview: ({ blDate }: BatchRecord) =>
        customDatePreview(blDate, [validationRequired, validationBlDate]),
      validate: [validationRequired, validationBlDate],
      edit: { type: 'date' },
    },
    {
      key: 'terminalBrId',
      label: 'TERMINAL BRAZIL ID #',
      title: 'TERMINAL BRAZIL ID #',
      style: { flex: '1 0 50px' },
      renderUploadPreview: (column: BatchRecord) => column.terminalBrId,
      validate: [validationRequired, validationString],
      edit: { type: 'input' },
    },
    {
      key: 'lastReceiptDateBr',
      label: 'LAST DATE OF RECEIPT TERMINAL BRAZIL',
      title: 'LAST DATE OF RECEIPT TERMINAL BRAZIL',
      style: { flex: '1 0 50px' },
      handleParsedValue: (value: string) => brazilTimeShift(value, lastReceiptDateBr),
      renderUploadPreview: ({ lastReceiptDateBr }: BatchRecord) =>
        customDatePreview(lastReceiptDateBr, [validationRequired, validationLastReceiptDateBr]),
      validate: [validationRequired, validationLastReceiptDateBr],
      edit: { type: 'date' },
    },
    {
      key: 'plantId',
      label: 'PLANT ID #',
      title: 'PLANT ID #',
      style: { flex: '1 0 50px' },
      renderUploadPreview: (column: BatchRecord) => column.plantId,
      validate: [validationRequired, validationString],
      edit: { type: 'input' },
    },
    {
      key: 'finalManDate',
      label: 'FINAL MANUFACTURING DATE',
      title: 'FINAL MANUFACTURING DATE',
      style: { flex: '1 0 50px' },
      handleParsedValue: (value: string) => brazilTimeShift(value, finalManDate),
      renderUploadPreview: ({ finalManDate }: BatchRecord) =>
        customDatePreview(finalManDate, [validationRequired, validationFinalManDate]),
      validate: [validationRequired, validationFinalManDate],
      edit: { type: 'date' },
    },
    {
      key: 'startManDate',
      label: 'START MANUFACTURING DATE',
      title: 'START MANUFACTURING DATE',
      style: { flex: '1 0 50px' },
      handleParsedValue: (value: string) => brazilTimeShift(value, startManDate),
      renderUploadPreview: ({ startManDate }: BatchRecord) =>
        customDatePreview(startManDate, [validationRequired, validationStartManDate]),
      validate: [validationRequired, validationStartManDate],
      edit: { type: 'date' },
    },
    {
      key: 'farmId',
      label: 'FRARM ID #',
      title: 'FRARM ID #',
      style: { flex: '1 0 50px' },
      renderUploadPreview: (column: BatchRecord) => column.farmId,
      validate: [validationRequired, validationString],
      edit: { type: 'input' },
    },
    {
      key: 'harvestFinalDate',
      label: 'FRUIT HARVEST FINAL DATE',
      title: 'FRUIT HARVEST FINAL DATE',
      style: { flex: '1 0 50px' },
      handleParsedValue: (value: string) => brazilTimeShift(value, harvestFinalDate),
      renderUploadPreview: ({ harvestFinalDate }: BatchRecord) =>
        customDatePreview(harvestFinalDate, [validationRequired, validationHarvestFinalDate]),
      validate: [validationRequired, validationHarvestFinalDate],
      edit: { type: 'date' },
    },
    {
      key: 'harvestStartDate',
      label: 'FRUIT HARVEST START DATE',
      title: 'FRUIT HARVEST START DATE',
      style: { flex: '1 0 50px' },
      handleParsedValue: (value: string) => brazilTimeShift(value, harvestStartDate),
      renderUploadPreview: ({ harvestStartDate }: BatchRecord) =>
        customDatePreview(harvestStartDate, [validationRequired, validationHarvestStartDate]),
      validate: [validationRequired, validationHarvestStartDate],
      edit: { type: 'date' },
    },
    {
      key: 'contributionPercent',
      label: '% FARM',
      title: '% FARM',
      style: { flex: '1 0 50px' },
      renderUploadPreview: (column: BatchRecord) => column.contributionPercent,
      validate: [validationRequired, validationPositiveFloatInString],
      edit: { type: 'input' },
    },
  ];
};
