import handshakeTabModel from 'models/mayonna/tabs/handshakeTabModel';
import { ORGANIZATIONS } from 'themes/constants';
import { defaultHandshakeSettingsAndActions } from 'models/utils/utils';
import { ModelRequiredInfo } from 'lib/lib';

export default (modelRequiredInfo: ModelRequiredInfo) => ({
  tabs: [
    {
      modelName: 'defaultTab',
      originalName: ORGANIZATIONS.MAYONNA,
      title: ORGANIZATIONS.MAYONNA,
    },
  ],
  defaultTab: handshakeTabModel(modelRequiredInfo),
  settingsAndActions: defaultHandshakeSettingsAndActions,
});
