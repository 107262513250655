import produce from 'immer';
import { AnyAction } from 'redux';

import * as adminActions from 'domain/admin/actions';
import { showAxiosErrors } from 'lib/helpers';
import * as envActions from 'domain/env/actions';

const initialState = {
  users: [],
  invitations: [],
  resource: {},
  isLoading: false,
  isResendLoading: false,
  errors: {},
  meta: {
    filter: { org_id: undefined },
  },
};

export const admin = produce<(draft: typeof initialState, action: AnyAction) => void>(
  (draft, { type, payload }) => {
    switch (type) {
      case envActions.setChannelAction.type:
      case envActions.logOutAction.success:
        return initialState;

      // Users
      case adminActions.fetchUsersAction.type:
        draft.isLoading = true;
        draft.users = initialState.users;
        return;

      case adminActions.fetchUsersAction.success:
        draft.isLoading = false;
        draft.users = payload;
        return;

      case adminActions.fetchUsersAction.failure:
        showAxiosErrors(payload);
        draft.isLoading = false;
        draft.errors = payload;
        return;

      // Delete User
      case adminActions.deleteUserAction.type:
        // draft.isLoading = true;
        return;

      case adminActions.deleteUserAction.success:
        // draft.isLoading = false;
        draft.users = draft.users.map((i) => (i.id === payload.id ? payload : i));
        return;

      case adminActions.deleteUserAction.failure:
        // draft.isLoading = false;
        return;

      // Approve user
      case adminActions.approveAdminUserAction.success:
        // draft.isLoading = false;
        draft.users = draft.users.map((i) => (i.id === payload.id ? payload : i));
        return;

      // Suspend user
      case adminActions.suspendAdminUserAction.success:
        // draft.isLoading = false;
        draft.users = draft.users.map((i) => (i.id === payload.id ? payload : i));
        return;

      // Invitations
      case adminActions.fetchInvitationsAction.type:
        draft.isLoading = true;
        draft.invitations = initialState.invitations;
        return;

      case adminActions.fetchInvitationsAction.success:
        draft.isLoading = false;
        draft.invitations = payload;
        return;

      case adminActions.fetchInvitationsAction.failure:
        showAxiosErrors(payload);

        draft.isLoading = false;
        draft.errors = payload;
        return;

      // Invitation Create
      case adminActions.createInvitationAction.type:
        draft.isLoading = true;
        return;

      case adminActions.createInvitationAction.success:
        draft.isLoading = false;
        return;

      case adminActions.createInvitationAction.failure:
        draft.isLoading = false;
        return;

      // Invitation Update
      case adminActions.updateInvitationAction.type:
        draft.isLoading = true;
        return;

      case adminActions.updateInvitationAction.success:
        draft.isLoading = false;
        draft.invitations = draft.invitations.map((i) => (i.id === payload.id ? payload : i));
        return;

      case adminActions.updateInvitationAction.failure:
        draft.isLoading = false;
        return;

      /* Invitation Resend */

      case adminActions.resendInvitationAction.type:
        draft.isResendLoading = true;
        return;

      case adminActions.resendInvitationAction.success:
        draft.isResendLoading = false;
        return;

      case adminActions.resendInvitationAction.failure:
        draft.isResendLoading = false;
        return;

      case adminActions.setUsersFilterAction.type:
        draft.meta.filter = {
          ...draft.meta.filter,
          ...payload,
        };
        return;
    }
  },
  initialState,
);

export const reducer = { admin };
