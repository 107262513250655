import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
// styles
import { WithStyles } from 'react-jss';
import mainLayoutStyles from 'pages/layouts/MainLayout/mainLayoutStyles';
// components
import { EggsChannelLogoSvg, OrangesChannelLogoSvg } from 'components/icons';
import ChannelSwitcher from 'components/ChannelSwitcher/ChannelSwitcher';
import UserAvatar from 'components/Avatar/userAvatar';
import UserDropdown from 'components/Dropdown/UserDropdown';
import NotificationCounter from 'pages/notifications/NotificationCounter';
// redux
import { getChannel, getChannels, getUser } from 'domain/env';
// utils
import { getUrl, routes } from 'routes';
import { getTheme } from 'themes/main';
import { useOutsideClick } from 'lib/utils';
// types
import { AppStateType } from 'types';
import { CHANNELS } from 'themes/constants';

interface MenuItem {
  routeName: string;
  icon: (props: unknown) => JSX.Element;
  disabled?: boolean;
}

const mapStateToProps = (state: AppStateType) => ({
  user: getUser(state),
  channel: getChannel(state),
  channels: getChannels(state),
});

/*
 *  MainMenu - header block with Logo, Menu, UserDropDown Components
 */

const MainMenu: React.FC<WithStyles<typeof mainLayoutStyles>> = (props) => {
  const { isOpen, setIsOpen, ref } = useOutsideClick(false);

  const { user, channel, channels } = useSelector(mapStateToProps, shallowEqual);
  const { classes } = props;

  const toggleDropdownMenu = () => setIsOpen(!isOpen);

  const getChannelIcon = (name: CHANNELS) => {
    if (name === CHANNELS.EGGS) {
      return <EggsChannelLogoSvg alt={name} />;
    }
    if (name === CHANNELS.ORANGES) {
      return <OrangesChannelLogoSvg alt={name} />;
    }
    return '';
  };

  const isNotificationsLink = (link: MenuItem) => link.routeName === 'Notifications';

  const displayNotificationCounter = (link: MenuItem) => {
    return isNotificationsLink(link) ? <NotificationCounter /> : '';
  };

  const mainMenuItems = getTheme(user.org && user.org.name, channel.name).mainMenuItems.filter(
    (r) => routes.MainLayout.find((route) => route.name === r.routeName),
  );

  return (
    <>
      <div className={classes.mainMenu}>
        <div className={classes.mainMenuInner}>
          <div className="mainLogo">
            <Link to="/">
              <img src={getTheme(user.org && user.org.name, channel.name).logo} alt="logo" />
            </Link>
          </div>

          <div className={classes.links}>
            {mainMenuItems.map((link: MenuItem) => {
              const route = routes.MainLayout.find((route) => route.name === link.routeName);
              const pageTitle = route && route.pageTitle;

              return (
                <div
                  className={`${classes.link} ${link.disabled ? 'disabled' : ''}`}
                  key={link.routeName}
                >
                  <div className="icon">
                    <link.icon />
                    {displayNotificationCounter(link)}
                  </div>

                  {!link.disabled ? (
                    <Link className="title" to={getUrl(link.routeName)}>
                      {pageTitle}
                    </Link>
                  ) : (
                    <div className="title">{pageTitle}</div>
                  )}
                </div>
              );
            })}
          </div>

          <div className={classes.dropDown}>
            <div className="hello">{`hello, ${user.username}`}</div>

            {channels && channels.length === 1 && (
              <div className="channel">{!!channel && getChannelIcon(channel.name)}</div>
            )}

            {channels && channels.length > 1 && <ChannelSwitcher />}

            <div className="icon" onClick={toggleDropdownMenu} ref={ref}>
              <UserAvatar user={user} circle />
            </div>
          </div>
        </div>
      </div>

      <div className={classes.mainMenu} style={{ height: 0, zIndex: 99999, border: 0 }}>
        <div className={classes.mainMenuInner}>
          <UserDropdown isOpen={isOpen} />
        </div>
      </div>
    </>
  );
};

export default React.memo(MainMenu);
