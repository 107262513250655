// helpers
import { renderShortDateFormat } from 'lib/renderHelpers';
// config
import { validationRequired, validationInt, validationString } from 'lib/uploadFieldValidations';
import { BatchRecord } from 'lib/lib';

export default () => {
  return [
    {
      key: 'floId',
      label: 'FLO-ID',
      title: 'FLO-ID',
      style: { flex: '1 0 105px', wordBreak: 'break-word' },
      renderUploadPreview: (column: BatchRecord) => column.floId,
      render: (column: BatchRecord) => column.floId,
      validate: [validationRequired, validationInt],
      defaultSelectedColumn: true,
      defaultSelectedBatchContractColumn: true,
    },
    {
      key: 'farmCode',
      label: 'Farm code',
      title: 'Farm code',
      style: { flex: '1 0 105px', wordBreak: 'break-word' },
      renderUploadPreview: (column: BatchRecord) => column.farmCode,
      render: (column: BatchRecord) => column.farmCode,
      validate: [validationRequired, validationInt],
      defaultSelectedColumn: true,
      defaultSelectedBatchContractColumn: true,
    },
    {
      key: 'farmName',
      label: 'Farm name',
      title: 'Farm name',
      style: { flex: '1 0 105px', wordBreak: 'break-word' },
      renderUploadPreview: (column: BatchRecord) => column.farmName,
      render: (column: BatchRecord) => column.farmName,
      validate: [validationRequired, validationString],
      defaultSelectedColumn: true,
      defaultSelectedBatchContractColumn: true,
    },
    {
      key: 'productShortCode',
      label: 'Product short code',
      title: 'Product short code',
      style: { flex: '1 0 105px', wordBreak: 'break-word' },
      renderUploadPreview: (column: BatchRecord) => column.productShortCode,
      render: (column: BatchRecord) => column.productShortCode,
      validate: [validationRequired, validationString],
      defaultSelectedColumn: true,
      defaultSelectedBatchContractColumn: true,
    },
    {
      key: 'containerNumber',
      label: 'Container Number',
      title: 'Container Number',
      style: { flex: '1 0 105px', wordBreak: 'break-word' },
      renderUploadPreview: (column: BatchRecord) => column.containerNumber,
      render: (column: BatchRecord) => column.containerNumber,
      validate: [validationRequired, validationString],
      defaultSelectedColumn: true,
      defaultSelectedBatchContractColumn: true,
    },
    {
      key: 'SSCCPallet',
      label: 'SSCC pallet',
      title: 'SSCC pallet',
      style: { flex: '1 0 105px', wordBreak: 'break-word' },
      renderUploadPreview: (column: BatchRecord) => column.SSCCPallet,
      render: (column: BatchRecord) => column.SSCCPallet,
      validate: [validationRequired, validationString],
      defaultSelectedColumn: true,
      defaultSelectedBatchContractColumn: true,
    },
    {
      key: 'stuffedDate',
      label: 'Container stuffed date',
      title: 'Container stuffed date',
      style: { flex: '1 0 105px', wordBreak: 'break-word' },
      renderUploadPreview: (column: BatchRecord) => renderShortDateFormat(column.stuffedDate),
      render: (column: BatchRecord) => renderShortDateFormat(column.stuffedDate),
      validate: [validationRequired, validationString],
      defaultSelectedColumn: true,
      defaultSelectedBatchContractColumn: true,
    },
    {
      key: 'numberOfBoxes',
      label: 'Number of boxes',
      title: 'Number of boxes',
      style: { flex: '1 0 105px', wordBreak: 'break-word' },
      renderUploadPreview: (column: BatchRecord) => column.numberOfBoxes,
      render: (column: BatchRecord) => column.numberOfBoxes,
      validate: [validationRequired, validationInt],
      defaultSelectedColumn: true,
      defaultSelectedBatchContractColumn: true,
    },
    {
      key: 'voyageNumber',
      label: 'Voyage number',
      title: 'Voyage number',
      style: { flex: '1 0 105px', wordBreak: 'break-word' },
      renderUploadPreview: (column: BatchRecord) => column.voyageNumber,
      render: (column: BatchRecord) => column.voyageNumber,
      validate: [validationRequired, validationString],
      defaultSelectedColumn: true,
      defaultSelectedBatchContractColumn: true,
    },
    {
      key: 'departureDate',
      label: 'Date departure port loaded',
      title: 'Date departure port loaded',
      style: { flex: '1 0 105px', wordBreak: 'break-word' },
      renderUploadPreview: (column: BatchRecord) => renderShortDateFormat(column.departureDate),
      render: (column: BatchRecord) => renderShortDateFormat(column.departureDate),
      validate: [validationRequired, validationString],
      defaultSelectedColumn: true,
      defaultSelectedBatchContractColumn: true,
    },
    {
      key: 'portLoaded',
      label: 'Port loaded',
      title: 'Port loaded',
      style: { flex: '1 0 105px', wordBreak: 'break-word' },
      renderUploadPreview: (column: BatchRecord) => column.portLoaded,
      render: (column: BatchRecord) => column.portLoaded,
      validate: [validationRequired, validationString],
      defaultSelectedColumn: true,
      defaultSelectedBatchContractColumn: true,
    },
  ];
};
