import inBatchesTabModel from 'models/banafood/tabs/inBatchesTabModel';
import outBatchesTabModel from 'models/banafood/tabs/outBatchesTabModel';
import { ModelRequiredInfo } from 'lib/lib';

export default (modelRequiredInfo: ModelRequiredInfo) => ({
  tabs: [
    {
      modelName: 'inBatches',
      originalName: 'Banafood IN',
      title: 'Banafood IN',
    },
    {
      modelName: 'outBatches',
      originalName: 'Banafood OUT',
      title: 'Banafood OUT',
    },
  ],
  inBatches: inBatchesTabModel(modelRequiredInfo),
  outBatches: outBatchesTabModel(modelRequiredInfo),
});
