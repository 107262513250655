import { Theme } from 'lib/lib';

export const layoutStyles = () => ({
  inputRow: {
    display: 'block',
    position: 'relative',
    margin: [0, 0, 16, 0],
  },

  wrapper: {
    fontSize: 12,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  labelText: {
    display: 'inline-block',
    color: '#133c5e',
    fontWeight: 600,
    letterSpacing: 0.12,
    marginBottom: 5,
    maxWidth: '100%',
  },
  formGroup: {
    marginBottom: 15,
    '&:after': {
      display: 'table',
      content: ' ',
    },
    '& select, & input': {
      lineHeight: 1.42857143,
      boxShadow: 'inset 0 1px 1px rgba(0,0,0,.075)',
      transition: 'border-color ease-in-out .15s,box-shadow ease-in-out .15s',
    },
  },
});

export const inputsStyles = (theme: Theme) => ({
  control: {
    display: 'block',
    minWidth: 100,
    minHeight: 40,
    borderRadius: 5,
    color: '#111111',
    fontSize: 15,
    fontWeight: 400,
    border: '1px solid #c8c8c8',
    backgroundColor: theme.colors.white,
    outline: 'none',
    padding: [10, 7],

    '&:focus, &:active': {
      borderColor: '#0f32c9',
      outlineColor: '#0f32c9',
    },
    '&::placeholder': {
      color: '#8a92a6',
    },
    '&:disabled': {
      // backgroundColor: theme.colors.lightGrey,
      backgroundColor: theme.colors.background,
      cursor: 'not-allowed',
    },
  },

  controlError: {
    borderColor: '#dc233b',
    outlineColor: '#dc233b',
    '&:focus, &:active': {
      outlineColor: '#dc233b',
    },
  },

  input: {
    width: '100%',
    height: 40,
  },

  select: {
    width: '100%',
    height: 40,
  },

  textarea: {
    width: '100%',
    height: '10em',
    resize: 'none',
  },

  calendar: {
    display: 'none',
    width: '100%',
    backgroundColor: theme.colors.datePickerBgColor,
    '& .react-calendar__navigation': {
      '& button': {
        color: theme.colors.datePickerColor,
      },
    },
    '& .react-calendar__month-view__weekdays': {
      '&__weekday': {
        color: theme.colors.datePickerColor,
      },
    },
    '& .react-calendar__month-view__days__day': {
      '&:hover': {
        backgroundColor: theme.colors.primaryHover,
      },
      '& abbr': {
        color: theme.colors.datePickerColor,
      },
    },
    '& time': {
      color: theme.colors.datePickerColor,
    },
    '&.isOpen': {
      display: 'inline-block',
      position: 'absolute',
      top: 66,
      left: 0,
    },
  },
});
