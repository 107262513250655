import produce from 'immer';
import { AnyAction } from 'redux';
import { LOCATION_CHANGE } from 'connected-react-router';

import * as envActions from 'domain/env/actions';
import * as commonActions from './actions';

import { getRouteParams } from './sagas';
import { CHANNELS } from 'themes/constants';

const initialState = {
  orgs: {
    [CHANNELS.BANANAS]: [],
    [CHANNELS.ORANGES]: [],
    [CHANNELS.TILAPIA]: [],
    [CHANNELS.EGGS]: [],
    [CHANNELS.JUICE]: [],
  },
  documentConfig: {
    [CHANNELS.BANANAS]: {},
    [CHANNELS.ORANGES]: {},
    [CHANNELS.TILAPIA]: {},
    [CHANNELS.EGGS]: {},
    [CHANNELS.JUICE]: {},
  },
  contacts: {
    [CHANNELS.BANANAS]: {},
    [CHANNELS.ORANGES]: {},
    [CHANNELS.TILAPIA]: {},
    [CHANNELS.EGGS]: {},
    [CHANNELS.JUICE]: {},
  },
  preferredBatchByOrgRole: {
    [CHANNELS.BANANAS]: {},
    [CHANNELS.ORANGES]: {},
    [CHANNELS.TILAPIA]: {},
    [CHANNELS.EGGS]: {},
    [CHANNELS.JUICE]: {},
  },
  eventTypes: {
    [CHANNELS.BANANAS]: {},
    [CHANNELS.ORANGES]: {},
    [CHANNELS.TILAPIA]: {},
    [CHANNELS.EGGS]: {},
    [CHANNELS.JUICE]: {},
  },
  batches: {
    [CHANNELS.BANANAS]: {},
    [CHANNELS.ORANGES]: {},
    [CHANNELS.TILAPIA]: {},
    [CHANNELS.EGGS]: {},
    [CHANNELS.JUICE]: {},
  },
  isLoading: false,
  serviceDataIsLoaded: false,
  errors: {},
  params: {},
  matchedRoute: {},
  internalServerError: false,
  queryParams: {},
};

const common = produce<(draft: typeof initialState, action: AnyAction) => void>(
  (draft, { type, payload }) => {
    switch (type) {
      // Fetch Requests List
      case commonActions.fetchServiceDataAction.type:
        draft.isLoading = true;
        return;

      case commonActions.fetchServiceDataAction.success: {
        const { data, serviceDataIsLoaded } = payload;
        const channelName = payload.channelName as CHANNELS;
        draft.isLoading = false;
        draft.serviceDataIsLoaded = serviceDataIsLoaded;
        draft.orgs[channelName] = data.orgs;
        draft.batches[channelName] = data.batches;
        draft.contacts[channelName] = data.contacts;
        draft.eventTypes[channelName] = data.eventTypes;
        draft.preferredBatchByOrgRole[channelName] = data.participants.preferredBatchByOrgRole;
        draft.documentConfig[channelName] = { documentCategories: data.documentCategories };
        return;
      }

      case commonActions.fetchServiceDataAction.failure:
        draft.isLoading = false;
        draft.errors = payload;
        return;

      case envActions.logOutAction.success:
        return initialState;

      case envActions.setChannelAction.type:
        return {
          ...initialState,
          params: draft.params,
          matchedRoute: draft.matchedRoute,
        };

      case envActions.setMatchedRouteAction.success:
        draft.matchedRoute = payload;
        return;

      case envActions.setTabsQueryParamsAction.success:
        draft.queryParams = payload;
        return;

      case envActions.setParamsAction.type:
        draft.params = payload;
        return;

      case commonActions.setInternalServerErrorAction.success:
        draft.internalServerError = payload;
        return;

      case LOCATION_CHANGE:
        const { matchedRoute, params } = getRouteParams(payload);

        // THIS LINE RESETS QUERYPARAMS ONLY IF ROUTE COMPONENT CHANGED
        // IF YOU GO CONTRACT1 -> CONTRACT2 -> CONTRACT3 IT WON'T TRIGGER
        draft.queryParams =
          matchedRoute && matchedRoute.name === draft.matchedRoute.name ? draft.queryParams : {};
        draft.matchedRoute = matchedRoute ? matchedRoute : draft.matchedRoute;
        draft.params = params ? params : draft.params;
        return;
    }
  },
  initialState,
);

export const reducer = { common };
