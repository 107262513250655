export default (theme) => ({
  tableCellEdit: {
    position: 'relative',
    width: '100%',
    '& .iconEdit': {
      position: 'absolute',
      top: 0,
      left: 'auto',
      right: 0,
      border: '0 !important',
    },
    '& .iconSave': {
      position: 'absolute',
      top: 0,
      left: 'auto',
      right: 24,
      border: '0 !important',
    },
    '& .iconClose': {
      position: 'absolute',
      top: 0,
      left: 'auto',
      right: 0,
      border: '0 !important',
    },
    '& input': {
      width: '100%',
      padding: [2, 5],
      border: '1px dashed gray',
      backgroundColor: theme.colors.editing,
    },
    '& input:focus': {
      outline: 0,
    },
  },
});
