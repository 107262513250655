// native utils
import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
// utils
import { toast } from 'lib/helpers';
// components
import Input from 'components/Form/Input';
// validaitons
import {
  validationRequired,
  validationPasswordLength8,
  validationPasswordSpecialChars,
  validationPasswordChars,
  validationPasswordConfirmation,
} from 'lib/validations';
// actions
import * as EnvActions from 'domain/env/actions';
import { getParamsLocationSearch } from 'domain/common';
// jss
import injectSheet from 'react-jss';
import sheetSignUp from 'pages/auth/signUp/sheetSignUp';

class SignUp extends React.PureComponent {
  invitationToken = '';

  componentDidMount() {
    const { search } = this.props;
    const qVars = {};
    String(search.substring(1))
      .split('&')
      .forEach((pair) => {
        const tmpVar = pair.split('=');
        qVars[String(tmpVar[0])] = String(tmpVar[1]);
      });

    const { invitation_token: invitationToken } = qVars;

    if (!invitationToken) {
      toast('No invitation token! Wrong url format');
    } else {
      this.invitationToken = encodeURIComponent(invitationToken);
      this.invitationToken = invitationToken;
    }
  }

  submit = (values) => {
    const { signUp, history } = this.props;
    const preparedData = { ...values, token: this.invitationToken };
    signUp(preparedData, history);
  };

  render() {
    const { classes, handleSubmit } = this.props;
    return (
      <div>
        <form onSubmit={handleSubmit(this.submit)}>
          <div className={classes.formGroup}>
            <Field
              component={Input}
              className={classes.inputField}
              validate={validationRequired}
              type="text"
              name="username"
              placeholder="Username"
            />
          </div>
          <div className={classes.formGroup}>
            <Field
              component={Input}
              className={classes.inputField}
              validate={[
                validationRequired,
                validationPasswordLength8,
                validationPasswordSpecialChars,
                validationPasswordChars,
              ]}
              type="password"
              name="password"
              placeholder="Password"
            />
          </div>
          <div className={classes.formGroup}>
            <Field
              component={Input}
              className={classes.inputField}
              validate={[
                validationRequired,
                validationPasswordLength8,
                validationPasswordSpecialChars,
                validationPasswordChars,
                validationPasswordConfirmation,
              ]}
              type="password"
              name="password_confirmation"
              placeholder="Password Confirmation"
            />
          </div>
          <div className={classes.formGroup}>
            <button className={classes.loginBtn} type="submit">
              Register
            </button>
          </div>
        </form>
      </div>
    );
  }
}

SignUp.propTypes = {
  classes: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  signUp: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  search: PropTypes.string,
};

export default compose(
  connect(
    (state) => ({
      search: getParamsLocationSearch(state),
    }),
    {
      signUp: (data, history) => ({ type: EnvActions.signUpAction.type, payload: data, history }),
    },
  ),
  reduxForm({
    form: 'signUp-form',
  }),
  injectSheet(sheetSignUp),
)(SignUp);
