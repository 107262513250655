export default () => ({
  $class: 'org.powerchain.batch.REFRESCOBatch',
  lotFinishedProduct: '1778602',
  articleNbrFinishedProduct: '39000000',
  descriptionFinishedProduct: 'APET 6x100  AH Sinaasappelsap RFA BC',
  qtyProducedPcsFinishedProduct: 100000,
  qtyUsedLtrRawProduct: 11770,
  productionFinishedFinishedProduct: '2019-01-30T00:00:00.000Z',
  articleNbrFcoj: '7892',
  dateCheckFcoj: '2019-01-29T00:00:00.000Z',
  certificateOfConformityFcoj: 'Present and conform',
  organolepticCheckFcoj: 'Acceptable',
  ratioFcoj: 15.186,
  organolepticCheckFinishedProduct: 'Acceptable',
  vitaminCFinishedProduct: '50.000000',
  ratioFinishedProduct: 16.286,
  dateDeliveryAhFert: '2019-01-30T00:00:00.000Z',
  lotFcoj: 'SG19310864',
  batchId: '99a2b22f-d75a-4439-b0e6-53f693399b34',
  createdAt: '2019-09-02T13:11:55.000Z',
  createdBy:
    'resource:org.powerchain.member.REFRESCOUser#REFRESCO_e77178d1-2504-42d5-8094-075d44465460',
  status: 'PENDING',
  uploadSource: 'frontEndApp',
  channelName: 'oranges',
  createdBy_participant: {
    username: null,
    email: null,
    participant:
      'org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8',
    org: {
      name: 'Fyffes',
      fullname: 'Fyffes',
      logo: null,
    },
    channel: {
      title: 'Bananas',
      name: 'bananas',
    },
    user: {
      username: 'Fyffes TST staging',
      email: 'FyffesTST@pc.t2f.io',
      org: {
        name: 'Fyffes',
        fullname: 'Fyffes',
        logo: null,
      },
    },
  },
});
