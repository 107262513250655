export default {
  $class: 'org.powerchain.batch.AHBatch',
  gtin: '08718906150690',
  glnSupplier: '8711900009995',
  glnBuyer: '8710400000198',
  glnDeliveryLocation: '8710400000198',
  quantity: 120,
  deliveryDatetime: '2018-09-20T17:01:41.000Z',
  batchnumber: '1708008',
  batchId: '792a7d63-b84d-4900-aaa5-7b9cb3da93ba',
  createdAt: '2019-08-14T09:37:11.000Z',
  createdBy: 'resource:org.powerchain.member.AHUser#AH_affef56f-d628-4fb9-86f3-e91d16f46ade',
  status: 'PENDING',
  uploadSource: 'api',
  channelName: 'oranges',
  modifiedBy_participant: {
    username: null,
    email: null,
    participant:
      'org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8',
    org: {
      name: 'Fyffes',
      fullname: 'Fyffes',
      logo: null,
    },
    channel: {
      title: 'Bananas',
      name: 'bananas',
    },
    user: {
      username: 'Fyffes TST staging',
      email: 'FyffesTST@pc.t2f.io',
      org: {
        name: 'Fyffes',
        fullname: 'Fyffes',
        logo: null,
      },
    },
  },
  createdBy_participant: {
    username: null,
    email: null,
    participant:
      'org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8',
    org: {
      name: 'Fyffes',
      fullname: 'Fyffes',
      logo: null,
    },
    channel: {
      title: 'Bananas',
      name: 'bananas',
    },
    user: {
      username: 'Fyffes TST staging',
      email: 'FyffesTST@pc.t2f.io',
      org: {
        name: 'Fyffes',
        fullname: 'Fyffes',
        logo: null,
      },
    },
  },
};
