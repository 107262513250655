export default () => [
  {
    $class: 'org.powerchain.batch.LDCBRBatch',
    jdeReference: '3723278-0009/18.5.1',
    contractNumber: 'BBE-0509/17.3.1',
    qttyContracted: 471924,
    batchFinal: 3714657,
    manufacturedSequence: 5,
    productCode: 'CLC001001',
    manufacturingDate: '2017-10-05T00:00:00.000Z',
    startBlendingDate: '2017-07-07T00:00:00.000Z',
    finalBlendingDate: '2017-10-05T00:00:00.000Z',
    facilityName: 'AGROINDUSTRIAL - MTO',
    farmName: 'FAZENDA MONTE BELO',
    farmAddress: 'EST MUN RIB BONITO-GUARAP KM7 S/N CASA 1',
    zipcode: '13580-000',
    city: 'RIBEIRAO BONITO',
    state: 'SP',
    varietyName: 'HAMLIN, NATAL/VALENCIA, PERA RIO, VALENCIA AMERICANA',
    block: '101, 102, 103, 104, 106, 108, 204, 207, 211, 212',
    fruitHarvestStartDate: '2017-07-06T00:00:00.000Z',
    fruitHarvestFinalDate: '2017-10-04T00:00:00.000Z',
    percentFarm: 27.9,
    totalPickers: 309,
    ageAverage: 37,
    dateOfReceiptSantos: '2017-10-23T00:00:00.000Z',
    batchId: '2a7a0cd4-3626-4ed4-b8b6-90bc711bbaf1',
    createdAt: '2019-08-13T13:30:34.000Z',
    createdBy:
      'resource:org.powerchain.member.LDCBRUser#LDCBR_3c4078aa-fba3-413e-9252-82e454a95136',
    status: 'PENDING',
    uploadSource: 'frontEndApp',
    channelName: 'oranges',
    supplier: {
      $class: 'org.powerchain.supplier.SupplierReadConcept',
      city: 'UBERLANDIA',
      country: 'Brazil',
      createdAt: '2020-03-11T13:45:19.205Z',
      createdBy: 'resource:org.powerchain.member.SIMUser#SIM_35275214-9bd6-4620-aef8-c4ea68f26e6f',
      id: '5e51a9be-5095-4bf7-8e15-b6093cdb0098',
      name: 'FAZ NOSSA SENHORA DE LOURDES',
      proposalSentAt: '2020-03-11T10:41:28.801Z',
      simSupplierId: '383589c2-82cc-4a53-bf07-83fc56eee8dc',
      status: 'VERIFIED',
      statusUpdatedAt: '2020-03-11T13:45:12.338Z',
      zipcode: '38400-970',
    },
    createdBy_participant: {
      username: null,
      email: null,
      participant:
        'org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8',
      org: {
        name: 'Fyffes',
        fullname: 'Fyffes',
        logo: null,
      },
      channel: {
        title: 'Bananas',
        name: 'bananas',
      },
      user: {
        username: 'Fyffes TST staging',
        email: 'FyffesTST@pc.t2f.io',
        org: {
          name: 'Fyffes',
          fullname: 'Fyffes',
          logo: null,
        },
      },
    },
  },
];
