import React from 'react';
import { renderShortDateFormat } from 'lib/renderHelpers';
import { CHANNELS, ORGANIZATIONS } from 'themes/constants';
import { Link } from 'react-router-dom';
import { getUrl } from 'routes';

// components
import baseModelFields from './baseModelFields';
import SubContractWarning from 'models/utils/SubContractWarning/SubContractWarning';
import { BatchRecord, ModelRequiredInfo } from 'lib/lib';

export default (modelRequiredInfo: ModelRequiredInfo) => [
  // {
  //   key: 'articleNumberConsumerProduct',
  //   label: 'Article number consumer product',
  //   title: 'Article number consumer product',
  //   style: { flex: '1 0 150px' },
  //   render: (column: BatchRecord) => <span>{column.articleNumberConsumerProduct}</span>,
  // },
  {
    key: 'productBatchIdBulk',
    label: 'Product/ batch ID bulk',
    title: 'Product/ batch ID bulk',
    style: { flex: '1 0 150px' },
    defaultSelectedBatchContractColumn: true,
    // render: (column: BatchRecord) => column.productBatchIdBulk,
    // renderBatchesContract: (column) => (
    render: (column: BatchRecord) => (
      <SubContractWarning
        subContractAmount={column.subcontractAmount}
        linkText={column.productBatchIdBulk}
      >
        <Link
          to={{
            pathname: getUrl('BatchContractType', {
              type: ORGANIZATIONS.SEAFOODCONNECTION.toLowerCase(),
              contract_number: encodeURIComponent(`${column.productBatchIdBulk}`),
              modelType: 'contractDetails',
              channelName: CHANNELS.TILAPIA,
            }),
          }}
        >
          {column.productBatchIdBulk}
        </Link>
      </SubContractWarning>
    ),
  },
  {
    key: 'articleDescriptionConsumerProduct',
    label: 'Article description consumer product',
    title: 'Article description consumer product',
    style: { flex: '0 0 250px' },
    render: (column: BatchRecord) => <span>{column.articleDescriptionConsumerProduct}</span>,
  },
  {
    key: 'specie',
    label: 'Specie',
    title: 'Specie',
    style: { flex: '1 0 100px' },
    render: (column: BatchRecord) => <span>{column.specie}</span>,
  },
  {
    key: 'productType',
    label: 'Product type (Fil-Loin-skin on/ off)',
    title: 'Product type (Fil-Loin-skin on/ off)',
    style: { flex: '1 0 100px' },
    defaultSelectedBatchContractColumn: true,
    render: (column: BatchRecord) => <span>{column.productType}</span>,
  },
  {
    key: 'volumeConsumerProductInKg',
    label: 'Volume Consumer product in KG',
    title: 'Volume consumer product in KG',
    style: { flex: '1 0 100px' },
    render: (column: BatchRecord) => <span>{column.volumeConsumerProductInKg}</span>,
  },
  {
    key: 'numberOfConsumerPackages',
    label: 'Number of consumer packages',
    title: 'Number of consumer packages',
    style: { flex: '1 0 100px' },
    render: (column: BatchRecord) => <span>{column.numberOfConsumerPackages}</span>,
  },
  {
    key: 'processingDate',
    label: 'Processing date',
    title: 'Processing date',
    style: { flex: '1 0 100px' },
    render: (column: BatchRecord) => <span>{renderShortDateFormat(column.processingDate)}</span>,
  },
  {
    key: 'deliveryDate',
    label: 'Delivery date',
    title: 'Delivery date',
    style: { flex: '1 0 100px' },
    render: (column: BatchRecord) => <span>{renderShortDateFormat(column.deliveryDate)}</span>,
  },
  {
    key: 'thtConsumerProduct',
    label: 'THT consumer product',
    title: 'THT consumer product',
    style: { flex: '1 0 150px' },
    defaultSelectedBatchContractColumn: true,
    render: (column: BatchRecord) => (
      <span>{renderShortDateFormat(column.thtConsumerProduct)}</span>
    ),
  },
  {
    key: 'articleNumberBulk',
    label: 'Article number bulk',
    title: 'Article number bulk',
    style: { flex: '1 0 200px' },
    render: (column: BatchRecord) => column.articleNumberBulk,
  },
  {
    key: 'articleDescriptionBulk',
    label: 'Article description bulk',
    title: 'Article description bulk',
    style: { flex: '1 0 150px' },
    render: (column: BatchRecord) => <span>{column.articleDescriptionBulk}</span>,
  },
  {
    key: 'dateReceipt',
    label: 'Date receipt',
    title: 'Date receipt',
    style: { flex: '1 0 100px' },
    render: (column: BatchRecord) => renderShortDateFormat(column.dateReceipt),
  },
  {
    key: 'thtBulk',
    label: 'THT bulk',
    title: 'THT bulk',
    style: { flex: '1 0 100px' },
    defaultSelectedBatchContractColumn: true,
    render: (column: BatchRecord) => <span>{renderShortDateFormat(column.thtBulk)}</span>,
  },
  {
    key: 'volumeBulkInKg',
    label: 'Volume bulk in KG',
    title: 'Volume bulk in KG',
    style: { flex: '1 0 150px' },
    render: (column: BatchRecord) => <span>{column.volumeBulkInKg}</span>,
  },
  ...baseModelFields(),
];
