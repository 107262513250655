import React from 'react';
import PropTypes from 'prop-types';
// jss
import injectStyle from 'react-jss';
import dataTableViewTelapiaBatchStyles from 'components/Datatable/styles/dataTableViewTelapiaBatchStyles';
import Loader from 'components/Loaders/smallLoader';

class DataTableViewTelapiaBatch extends React.PureComponent {
  render() {
    const { classes, data, columns, onRowClick, isLoading } = this.props;

    return (
      <div className={classes.dataTableViewTelapiaBatch}>
        <div className="wrapper">
          <div className="dataTableHead">
            {columns &&
              columns.map(({ key, style, shortTitle, title }) => (
                <div key={key} style={style || {}}>
                  {shortTitle || title}
                </div>
              ))}
          </div>

          {data &&
            data.map((dataItem, dIndex) => (
              <div
                className="dataTableRow"
                key={dIndex}
                onClick={onRowClick ? onRowClick(dataItem) : () => {}}
                style={onRowClick ? { cursor: 'pointer' } : {}}
              >
                {columns &&
                  columns.map((column, cIndex) => (
                    <div style={column.style || {}} key={cIndex}>
                      {column.render(dataItem)}
                    </div>
                  ))}
              </div>
            ))}

          {isLoading && (
            <div className={classes.loader}>
              <Loader />
            </div>
          )}
        </div>
      </div>
    );
  }
}

DataTableViewTelapiaBatch.propTypes = {
  classes: PropTypes.object.isRequired,
  data: PropTypes.any,
  columns: PropTypes.array.isRequired,
  onRowClick: PropTypes.func,
  isLoading: PropTypes.bool,
};

export default injectStyle(dataTableViewTelapiaBatchStyles)(DataTableViewTelapiaBatch);
