import React from 'react';
import PropTypes from 'prop-types';
//
import { connect } from 'react-redux';
import { compose } from 'redux';

// redux-form
import { Field, reduxForm } from 'redux-form';
// jss
import injectSheet from 'react-jss';
import forgotPasswordStyles from 'pages/auth/forgotPassword/forgotPasswordStyles';
import * as profileActions from 'domain/profile/actions';
import { toast, checkEmail } from 'lib/helpers';
import { CheckSvg } from 'components/icons';
import { getStatus } from 'domain/profile';

class ForgotPassword extends React.Component {
  componentDidMount() {
    const { clearStatus } = this.props;
    clearStatus();
  }

  submit = (values) => {
    const { sendEmailForRecoverPassword } = this.props;
    if (checkEmail(values.email)) {
      sendEmailForRecoverPassword({ email: values.email });
    } else {
      toast('Invalid email format');
    }
    return false;
  };

  render() {
    const { classes, handleSubmit, status } = this.props;
    return status === 'success' ? (
      <div className={classes.changesAcceptedBlock}>
        <CheckSvg />
        <div className="title">Link was sent. Pleasae check your e-mail</div>
      </div>
    ) : (
      <div className={classes.formWrapper}>
        <form onSubmit={handleSubmit(this.submit)}>
          <div className={classes.formGroup}>
            <p className={classes.formGroupTitle}>
              Please, enter the email you&#39;ve registered with:
            </p>
          </div>

          <div className={classes.formGroup}>
            <Field
              name="email"
              title="Email"
              type="email"
              component="input"
              validate={this.validation}
              className={classes.inputField}
              props={{
                placeholder: 'myemail@yahoo.eu',
              }}
            />
          </div>

          <div className={classes.formGroup}>
            <button className={classes.confirmButton} type="submit">
              Send E-mail
            </button>
          </div>
        </form>
      </div>
    );
  }
}

ForgotPassword.propTypes = {
  classes: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  sendEmailForRecoverPassword: PropTypes.func.isRequired,
  clearStatus: PropTypes.func.isRequired,
  status: PropTypes.string.isRequired,
};

export default compose(
  connect(
    (state) => ({
      status: getStatus(state),
    }),
    {
      sendEmailForRecoverPassword: (data) => ({
        type: profileActions.sendEmailForRecoverPasswordAction.type,
        payload: data,
      }),
      clearStatus: () => ({ type: profileActions.clearStatusAction }),
    },
  ),
  reduxForm({
    form: 'form-send-email-for-recovering-password',
  }),
  injectSheet(forgotPasswordStyles),
)(ForgotPassword);
