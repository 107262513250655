import batchData from 'models/citrosucoGhent/tabs/batchData';
import { ModelRequiredInfo } from 'lib/lib';

export default (modelRequiredInfo: ModelRequiredInfo) => ({
  tabs: [
    {
      modelName: 'batchData',
      originalName: 'Ghent Batch Data',
      title: 'Ghent Batch Data',
    },
  ],
  batchData: batchData(modelRequiredInfo),
});
