import Route from 'route-parser';

import distributorBatchContractResponseBananas from 'mocks/responses/channels/bananas/distributorbatch/contractList';
import distributorBatchDetailsResponseBananas from 'mocks/responses/channels/bananas/distributorbatch/details';
import uploadBatchResponse from 'mocks/responses/batchUpload/batchUploadTilapiaBananas';

import { deleteContractResponseBananas, deleteMultipleBatchesResponse } from 'mocks/router/utils';

export default (request) => {
  const routeq = new Route(
    '/api/v1/bananas/distributorbatch(?*filter)(/:batchId)(/contracts/:contractId)(/handshake/?*handshake)',
  );

  const parameters = routeq.match(request.url);

  if (parameters) {
    if (parameters.filter) {
      return [200, distributorBatchContractResponseBananas()];
    }

    if (parameters.contractId) {
      if (request.method === 'delete') {
        return [200, deleteContractResponseBananas(parameters.contractId)];
      }

      return [200, distributorBatchDetailsResponseBananas()];
    }
  }

  if (request.method === 'put') {
    return [200, deleteMultipleBatchesResponse(request.data)];
    // IN CASE ERROR RESPONSE NEEDED TODO: REMOVE
    // return [404, {
    //   $class: 'org.powerchain.bananas.batch.DestroyBatchesResponseConcept',
    //   archivedBatches: [],
    //   status: 'NOT_FOUND',
    //   statusCode: 404,
    //   batchIds: ['6a3923e7-d099-4ff6-8e90-5051d976bf0c'],
    //   batchType: 'ProducerProcessBatch',
    //   message: '[ProducerProcessBatch_ee738901-3a1b-4fbe-a104-3476132673e1] were not performed. This ids do not exist.'
    // }];
  }

  if (request.method === 'post') {
    return [200, uploadBatchResponse()];
  }

  return [500, `url not proceeded: ${request.url}`];
};
