import React, { useMemo } from 'react';
import injectSheet, { WithStyles } from 'react-jss';
import { useHistory } from 'react-router-dom';
import { Field, Form, FormSubmitHandler, InjectedFormProps, reduxForm } from 'redux-form';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { compose } from 'redux';
import moment from 'moment';
// redux
import { getChannelName } from 'domain/env';
import { getCertFile, getIsLoading } from 'domain/documents';
import * as documentActions from 'domain/documents/actions';
import * as uiActions from 'domain/ui/actions';
// utils
import { toast } from 'lib/helpers';
import {
  getDocumentTypes,
  getEntitiesCertified,
  makeFullDataObject,
  SelectValue,
  useFormValidation,
} from 'pages/documents/certificateAddComponetns/utils';
// components
import RenderDropzoneInput from 'components/Form/DropZone';
import Button from 'components/Form/Button/index';
import Select from 'components/Form/Select/index';
import CustomDayPicker from 'components/Form/CustomDayPicker/customDayPicker';
import Input from 'components/Form/Input/index';
import Textarea from 'components/Form/Textarea/textarea';
import SimpleFrame from 'components/Frames/SimpleFrame';
import Loader from 'components/Loaders/smallLoader';
// modals
import ModalApproveNewCertificateForm from 'pages/documents/Forms/modalApproveNewCertificateForm';
// styles
import certificateAddStyles from 'pages/documents/styles/certificateAddFormStyles';
// types
import { AppStateType } from 'types';
import { CHANNELS } from 'themes/constants';

export interface SubmitFormValuesTilapiaBananasEggs {
  documentType: SelectValue;
  documentNumber?: string;
  entityId?: string;
  entityCertified?: SelectValue | string;
  auditDate?: string;
  from: string;
  until: string;
  notes?: string;
  certFile: File[];
}

const mapStateToProps = (state: AppStateType) => ({
  isLoading: getIsLoading(state),
  certFile: getCertFile(state),
  channelName: getChannelName(state),
});

const CertificateAddTilapiaBananasEggs: React.FC<
  WithStyles<typeof certificateAddStyles> & InjectedFormProps<SubmitFormValuesTilapiaBananasEggs>
> = (props) => {
  const { classes, handleSubmit, reset, touch } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const { isLoading, certFile, channelName } = useSelector(mapStateToProps, shallowEqual);
  const {
    validateRequired,
    validateDateFormatYYYYMMDDDashed,
    validateFileIsUploaded,
    formatValue,
  } = useFormValidation();
  const documentTypes = useMemo(() => getDocumentTypes(channelName), []);
  const entitiesCertified = useMemo(() => getEntitiesCertified(channelName), []);

  const onDropzoneInputChange = (values: File[]) => {
    const formData = new FormData();
    formData.append('certFile', values[0]);
    dispatch(documentActions.uploadCertFileAction(formData));
    touch('certFile');
  };

  const handleBeforeSubmit: FormSubmitHandler<SubmitFormValuesTilapiaBananasEggs> = (values) => {
    if (moment(values.until).diff(moment(values.from)) < 0) {
      toast('"Valid from" date should be less then date "Valid until"!');
    } else {
      const fullData = makeFullDataObject(channelName, values, certFile.id);

      const callbackSubmitNewCertificate = () => {
        dispatch({
          type: documentActions.createDocumentAction.type,
          payload: fullData,
          history,
        });
      };

      dispatch(
        uiActions.uiModalAction({
          title: 'Are you sure you want to submit this certificate?',
          body: (
            <ModalApproveNewCertificateForm
              certificateFormData={values}
              callback={callbackSubmitNewCertificate}
              deleteCallback={reset}
            />
          ),
        }),
      );
    }
  };

  return (
    <section className={classes.component}>
      <SimpleFrame title={<span>Upload & share a new certificate</span>}>
        <div className={classes.componentInner}>
          <Form onSubmit={handleSubmit(handleBeforeSubmit)} className={classes.formWrapper}>
            <div className={classes.dropzoneSection}>
              <div className={classes.dropZone}>
                <div className="dropZoneInput">
                  <Field
                    name="certFile"
                    type="file"
                    component={RenderDropzoneInput}
                    props={{
                      style: {
                        display: 'flex',
                        position: 'relative',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: 200,
                        height: 200,
                        borderWidth: 2,
                        borderColor: 'rgb(102, 102, 102)',
                        borderStyle: 'dashed',
                        borderRadius: 5,
                      },
                      onDropzoneInputChange,
                    }}
                    validate={[validateRequired, validateFileIsUploaded]}
                  />
                </div>
              </div>
            </div>

            <div className={classes.form}>
              <div className={classes.formStringsFields}>
                <Field
                  name="documentType"
                  type="text"
                  component={Select}
                  validate={validateRequired}
                  defaultValue={0}
                  props={{
                    options: documentTypes,
                    keys: { value: 'value', title: 'title' },
                    title: 'Document Types',
                    formatValue,
                    defaultValue: null,
                  }}
                />
              </div>

              <div className={classes.formStringsFields}>
                <Field
                  name="documentNumber"
                  type="text"
                  component={Input}
                  validate={channelName === CHANNELS.BANANAS && validateRequired}
                  props={{
                    title: 'Document Number',
                    defaultValue: null,
                  }}
                />
              </div>

              <div className={classes.formStringsFields}>
                <Field
                  name="entityId"
                  type="text"
                  component={Input}
                  validate={
                    (channelName === CHANNELS.EGGS || channelName === CHANNELS.TILAPIA) &&
                    validateRequired
                  }
                  props={{
                    title: 'Entity Id',
                    defaultValue: null,
                  }}
                />
              </div>

              <div className={classes.formStringsFields}>
                <Field
                  name="entityCertified"
                  type="text"
                  component={channelName === CHANNELS.EGGS ? Select : Input}
                  validate={
                    (channelName === CHANNELS.EGGS || channelName === CHANNELS.TILAPIA) &&
                    validateRequired
                  }
                  props={{
                    options: entitiesCertified,
                    keys: { value: 'value', title: 'title' },
                    title: 'Entity Certified',
                    formatValue,
                    defaultValue: null,
                  }}
                />
              </div>

              <div className={classes.formPickerFields} style={{ zIndex: 2 }}>
                <Field
                  name="auditDate"
                  type="text"
                  component={CustomDayPicker}
                  validate={
                    channelName === CHANNELS.BANANAS
                      ? [validateRequired, validateDateFormatYYYYMMDDDashed]
                      : validateDateFormatYYYYMMDDDashed
                  }
                  props={{
                    title: 'Audit Date',
                    placeholder: 'YYYY-MM-DD',
                    defaultValue: null,
                  }}
                />
              </div>

              <div className={classes.formPickerFields}>
                <Field
                  name="from"
                  type="text"
                  component={CustomDayPicker}
                  validate={[validateRequired, validateDateFormatYYYYMMDDDashed]}
                  props={{
                    title: 'Valid from',
                    placeholder: 'YYYY-MM-DD',
                    defaultValue: null,
                  }}
                />
              </div>

              <div className={classes.formPickerFields}>
                <Field
                  name="until"
                  type="text"
                  component={CustomDayPicker}
                  validate={[validateRequired, validateDateFormatYYYYMMDDDashed]}
                  props={{
                    title: 'Valid until',
                    placeholder: 'YYYY-MM-DD',
                    defaultValue: null,
                  }}
                />
              </div>

              <div className={classes.formTextAreaFields}>
                <Field
                  name="notes"
                  type="text"
                  component={Textarea}
                  props={{
                    title: 'Notes',
                    defaultValue: null,
                  }}
                />
              </div>

              {!isLoading ? (
                <Button type="submit" className={classes.btn}>
                  {' '}
                  Review & Submit{' '}
                </Button>
              ) : (
                <Button type="button" className={classes.btn}>
                  <Loader />
                </Button>
              )}
            </div>
          </Form>
        </div>
      </SimpleFrame>
    </section>
  );
};

export default compose<React.FC>(
  React.memo,
  reduxForm({
    form: 'add-document-form',
  }),
  injectSheet(certificateAddStyles),
)(CertificateAddTilapiaBananasEggs);
