import React from 'react';
import { Link } from 'react-router-dom';
// utils
import { BATCH_STATUS, ORGANIZATIONS } from 'themes/constants';
import { renderTwoShortDateFormat, renderShortDateFormat } from 'lib/renderHelpers';
import { getUrl } from 'routes';
import { validationRequired } from 'lib/validations';
import { BatchRecord, ModelRequiredInfo } from 'lib/lib';
import { getCreatorNameAndOrgName } from 'models/utils/utils';

export default (modelRequiredInfo: ModelRequiredInfo) => [
  {
    key: 'floId',
    label: 'FLO-ID',
    title: 'FLO-ID',
    style: { flex: '1 0 80px' },
    renderExportValue: (column: BatchRecord) => column.floId,
    defaultSelectedColumn: true,
    defaultSelectedBatchContractColumn: true,
    render: (column: BatchRecord) =>
      modelRequiredInfo.params && modelRequiredInfo.params.status === BATCH_STATUS.ARCHIVED ? (
        <Link
          to={{
            pathname: getUrl('bananasBatchDetails', {
              type: ORGANIZATIONS.FYFFES,
              batchCode: encodeURIComponent(encodeURIComponent(column.floId)),
              status:
                modelRequiredInfo.params &&
                modelRequiredInfo.params.status === BATCH_STATUS.ARCHIVED
                  ? modelRequiredInfo.params.status
                  : '',
              modelType: 'details',
            }),
          }}
          title={column.floId}
          className="contract"
        >
          {column.floId}
        </Link>
      ) : (
        <Link
          to={{
            pathname: getUrl('bananasBatch', {
              type: ORGANIZATIONS.FYFFES,
              batchCode: encodeURIComponent(encodeURIComponent(column.floId)),
              modelType: 'details',
            }),
          }}
          title={column.floId}
          className="contract"
        >
          {column.floId}
        </Link>
      ),
  },
  {
    key: 'farmCodeCount',
    label: 'Farm codes count',
    title: 'Farm codes count',
    style: { flex: '1 0 130px' },
    renderExportValue: (column: BatchRecord) => column.farmCodeCount,
    render: (column: BatchRecord) => column.farmCodeCount,
    defaultSelectedBatchContractColumn: true,
    defaultSelectedColumn: true,
  },
  {
    key: 'stuffedDate',
    label: 'Container stuffed date',
    title: 'Container stuffed date',
    style: { flex: '1 0 90px' },
    defaultSelectedBatchContractColumn: true,
    renderExportValue: (column: BatchRecord) => {
      if (!column.minStuffedDate || !column.maxStuffedDate) {
        return '';
      }

      return renderTwoShortDateFormat(column.minStuffedDate, column.maxStuffedDate);
    },
    render: (column: BatchRecord) => {
      if (!column.minStuffedDate || !column.maxStuffedDate) {
        return null;
      }

      return renderTwoShortDateFormat(column.minStuffedDate, column.maxStuffedDate);
    },
    defaultSelectedColumn: true,
    edit: { type: 'date' },
  },
  {
    key: 'departureDate',
    label: 'Date departure port loaded',
    title: 'Date departure port loaded',
    style: { flex: '1 0 90px' },
    defaultSelectedBatchContractColumn: true,
    renderExportValue: (column: BatchRecord) => {
      if (!column.minDepartureDate || !column.maxDepartureDate) {
        return '';
      }

      return renderTwoShortDateFormat(column.minDepartureDate, column.maxDepartureDate);
    },
    render: (column: BatchRecord) => {
      if (!column.minDepartureDate || !column.maxDepartureDate) {
        return null;
      }

      return renderTwoShortDateFormat(column.minDepartureDate, column.maxDepartureDate);
    },
    defaultSelectedColumn: true,
    edit: { type: 'date' },
  },
  {
    key: 'SSCCPalletCount',
    label: 'SSCC pallet (count)',
    title: 'SSCC pallet (count)',
    style: { flex: '1 0 50px' },
    defaultSelectedBatchContractColumn: true,
    renderExportValue: (column: BatchRecord) => column.SSCCPalletCount,
    render: (column: BatchRecord) => column.SSCCPalletCount,
    defaultSelectedColumn: true,
  },
  {
    key: 'createdAtMin',
    label: 'Created At',
    title: 'Created At',
    style: { flex: '1 0 80px' },
    isSortable: true,
    defaultSelectedColumn: true,
    renderExportValue: (column: BatchRecord) => column.createdAtMin,
    render: (column: BatchRecord) => renderShortDateFormat(column.createdAtMin),
  },
  {
    key: 'createdAtMax',
    label: 'Last Updated',
    title: 'Last Updated',
    style: { flex: '1 0 80px' },
    isSortable: true,
    defaultSelectedColumn: true,
    renderExportValue: (column: BatchRecord) => column.createdAtMax,
    render: (column: BatchRecord) => renderShortDateFormat(column.createdAtMax),
  },
  {
    key: 'createdBy',
    label: 'Uploaded By',
    title: 'Uploaded By',
    style: { flex: '1 0 150px' },
    renderExportValue: (record: BatchRecord) => getCreatorNameAndOrgName(record),
    render: (record: BatchRecord) => getCreatorNameAndOrgName(record),
    validate: [validationRequired],
    defaultSelectedColumn: true,
    defaultSelectedBatchContractColumn: true,
  },
];
