import { toast as toast123 } from 'react-toastify';
import moment from 'moment-timezone';
import React from 'react';
import XLSX from 'xlsx';
import { put } from 'redux-saga/effects';
import * as commonActions from 'domain/common/actions';

export function formatBytes(bytes: number, decimals = 2) {
  if (bytes === 0 || bytes === 0) return '0 Bytes';
  const k = 1024;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return `${parseFloat((bytes / k ** i).toFixed(decimals))} ${sizes[i]}`;
}

export function getFileExtension($fileName = '') {
  // https://stackoverflow.com/questions/680929/how-to-extract-extension-from-filename-string-in-javascript
  return /(?:\.([^.]+))?$/.exec(String($fileName))[1];
}

export const toast = (msg = '', type = '') => {
  switch (type) {
    case 'success':
      toast123.success(msg, {
        position: toast123.POSITION.TOP_RIGHT,
      });
      break;
    case 'info':
      toast123.info(msg, {
        position: toast123.POSITION.TOP_RIGHT,
      });
      break;
    case 'warn':
      toast123.warn(msg, {
        position: toast123.POSITION.TOP_RIGHT,
      });
      break;
    default:
      toast123.error(msg, {
        position: toast123.POSITION.TOP_RIGHT,
      });
  }
};

export const showFailedResponse = (errors) => {
  const failedResponse = errors;

  if (
    failedResponse &&
    failedResponse.response &&
    failedResponse.response.data &&
    failedResponse.response.data.error &&
    failedResponse.response.data.error.message
  ) {
    toast(`${failedResponse.response.data.error.message}`);
  } else if (
    failedResponse &&
    failedResponse.response &&
    failedResponse.response.data &&
    failedResponse.response.data.length
  ) {
    failedResponse.response.data.forEach((error) => {
      if (error.field && error.message) {
        toast(`${error.field}: ${error.message}`);
      }
    });
  } else {
    toast('Unknown Error');
  }
};

const timeParser = (timeString, timezone, format) => {
  try {
    const currDate = moment.tz(timeString, format, timezone);
    if (currDate.isValid()) {
      return `${currDate.format('YYYY')}-${currDate.format('MM')}-${currDate.format(
        'DD',
      )}T00:00:00.000Z`;
    }
    throw new RangeError('Invalid date format');
  } catch (e) {
    return false;
  }
};

export const formatBatchDate = (
  dateString = '',
  timezone = 'GMT',
  timeFormats = [] as string[],
) => {
  let formattedTime = dateString;
  timeFormats.some((timeFormat) => {
    const tmpCase = timeParser(dateString, timezone, timeFormat);
    if (tmpCase) {
      formattedTime = tmpCase;
      return true;
    }
    return false;
  });
  return formattedTime;
};

/**
 * export format 'Yes' or 'No' String To Boolean
 * @param {String} value
 * @returns {Boolean}
 */

export const formatYesNoStringToBoolean = (value) => {
  const tmpValue = String(value).toLowerCase();
  if (!['yes', 'no'].includes(tmpValue)) {
    return false;
  }
  return tmpValue === 'yes';
};

/**
 * export format 'Yes' or 'No' String To Boolean
 * @param {Boolean} value
 * @returns {String}
 */

export const formatBooleanToYesNoString = (value) => (value ? 'Yes' : 'No');

export const getNameFromBlockchainString = (row = '') => {
  const regex = /.([a-zA-Z0-9]+)#/;
  const m = regex.exec(row);
  if (m && m[1]) {
    return <span>{m[1]}</span>;
  }
  return <span>{row}</span>;
};

// eslint-disable-next-line no-useless-escape
export const checkEmail = (email = '') =>
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    String(email).toLowerCase(),
  );

// response is payload object from saga to reducer
export const showAxiosErrors = (response = {}, checkStatusOnly = false) => {
  if (response.status && response.status === 500) {
    return put({
      type: commonActions.setInternalServerErrorAction.success,
      payload: true,
    });
  }
  if (checkStatusOnly) {
    return {};
  }
  if (response.message) {
    toast(response.message);
  } else if (response.data && response.data.message) {
    toast(response.data.message);
  } else if (Array.isArray(response)) {
    response.forEach((errObj) => {
      toast(errObj.message);
    });
  } else if (response.data && Array.isArray(response.data)) {
    response.data.forEach((errObj) => {
      toast(errObj.message);
    });
  } else {
    toast('Unknown server error');
  }
};

export const participantNameFixBatches = (newBatches, users) =>
  Array.isArray(newBatches) &&
  newBatches.map((batch) => {
    const participant = String(batch.createdBy).replace('resource:', '');
    const newBatch = batch;
    if (users.hasOwnProperty(participant)) {
      return {
        ...newBatch,
        participantName: users[participant].user.username,
        orgName: users[participant].org.fullname,
      };
    }
    return newBatch;
  });

export const exportIndexBatch = (
  batches,
  batchModel,
  username = '',
  filename = 'batchList.xlsx',
  sheetTitle = 'Batches list',
) => {
  const batchesArray = batches;

  if (batchesArray.length > 0) {
    /* create workbook */
    const workBook = XLSX.utils.book_new();

    workBook.Props = {
      Title: sheetTitle,
      Subject: sheetTitle,
      Author: username,
      CreatedDate: moment(),
    };

    const headersRow = batchModel.map(({ label }) => label);
    const values = batchesArray.map((batchRow) =>
      batchModel.map(({ renderExportValue }) => renderExportValue(batchRow)),
    );

    /* make worksheet */
    const workSheet = XLSX.utils.aoa_to_sheet([headersRow, ...values]);

    /* Add the worksheet to the workbook */
    XLSX.utils.book_append_sheet(workBook, workSheet, sheetTitle);
    /* download file */
    XLSX.writeFile(workBook, filename);
  } else {
    toast('Nothing yet to export');
  }
};

export const exportExcelFile = (batch, batchModel, username, filename = 'batchList.xlsx') => {
  if (Object.keys(batch).length) {
    const workBook = XLSX.utils.book_new();
    workBook.Props = {
      Title: 'Batch Show File',
      Subject: 'Batch Show File',
      Author: username,
      CreatedDate: moment(),
    };

    batchModel.tabs.forEach((tab) => {
      workBook.SheetNames.push(tab.originalName);
      const aoa = [];
      aoa.push(batchModel[tab.modelName].map((f) => f.title));
      batch[tab.modelName].forEach((row) => {
        aoa.push(
          batchModel[tab.modelName].map((mItem) => {
            const renderValue = mItem.render(row);

            return typeof renderValue === 'string' ? renderValue : mItem.renderUploadPreview(row);
          }),
        );
      });

      workBook.Sheets[tab.originalName] = XLSX.utils.aoa_to_sheet(aoa);
    });

    XLSX.writeFile(workBook, filename);
  } else {
    toast('Nothing yet to export');
  }
};

export const substractDaysFromToday = (days: string) => {
  return moment()
    .subtract(parseInt(days, 10), 'days')
    .format('YYYY-MM-DD');
};
