export default () => [
  {
    $class: 'org.powerchain.tilapia.batch.ImporterBatchGroupedConcept',
    productBatchIdBulk: '20180025',
    deliveryDateMin: '2018-10-31T00:00:00.000Z',
    deliveryDateMax: '2018-12-10T00:00:00.000Z',
    dateReceiptMin: '2018-09-20T00:00:00.000Z',
    dateReceiptMax: '2018-09-20T00:00:00.000Z',
    bbdMin: '2020-07-25T00:00:00.000Z',
    bbdMax: '2020-07-25T00:00:00.000Z',
    uniqContainerNumberCount: 1,
    createdAt: '2019-08-20T11:23:21.878Z',
    createdBy:
      'resource:org.powerchain.tilapia.member.ImporterUser#SeafoodConnection_4d94b219-0e64-45f0-ba42-32eff7b0d7d6',
    "modifiedBy_participant":{
      "username":null,
      "email":null,
      "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
      "org":{
        "name":"Fyffes",
        "fullname":"Fyffes",
        "logo":null
      },
      "channel":{
        "title":"Bananas",
        "name":"bananas"
      },
      "user":{
        "username":"Fyffes TST staging",
        "email":"FyffesTST@pc.t2f.io",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        }
      }
    },
    status: 'PENDING',"createdBy_participant":{
      "username":null,
      "email":null,
      "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
      "org":{
        "name":"Fyffes",
        "fullname":"Fyffes",
        "logo":null
      },
      "channel":{
        "title":"Bananas",
        "name":"bananas"
      },
      "user":{
        "username":"Fyffes TST staging",
        "email":"FyffesTST@pc.t2f.io",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        }
      }
    }
  },
];
