import React from 'react';
import { Link } from 'react-router-dom';
import { getUrl } from 'routes';
import baseModelFields from 'models/baseModelFields';
import { ORGANIZATIONS } from 'themes/constants';
import { WarningSvg } from 'components/icons';
import { renderShortDateFormat } from 'lib/renderHelpers';
import { BatchRecord, ModelRequiredInfo } from 'lib/lib';

export default (modelRequiredInfo: ModelRequiredInfo) => [
  {
    key: 'batchnumber',
    label: 'Batchnumber',
    title: 'Batch Number',
    defaultSelectedBatchContractColumn: true,
    style: { flex: '1 0 100px' },
    render: (column: BatchRecord) => (
      <>
        <Link
          className="contract"
          to={{
            pathname: getUrl('BatchHandshake', {
              type: ORGANIZATIONS.REFRESCO.toLowerCase(),
              channelName: 'common',
            }),
            search: `lotFcoj=${encodeURIComponent(encodeURIComponent(column.batchnumber))}`,
          }}
        >
          {column.batchnumber}
        </Link>
        {column.subcontractAmount === 0 && (
          <span className="warning">
            <WarningSvg />
          </span>
        )}
      </>
    ),
  },
  {
    title: 'Gtin',
    label: 'GTIN',
    key: 'gtin',
    defaultSelectedBatchContractColumn: true,
    style: { flex: '1 0 50px' },
    render: (column: BatchRecord) => column.gtin,
  },
  {
    title: 'Gln Supplier',
    label: 'GLN Supplier',
    key: 'glnSupplier',
    defaultSelectedBatchContractColumn: true,
    style: { flex: '1 0 50px' },
    render: (column: BatchRecord) => column.glnSupplier || '',
  },
  {
    title: 'Gln Buyer',
    label: 'GLN Buyer',
    key: 'glnBuyer',
    defaultSelectedBatchContractColumn: true,
    style: { flex: '1 0 50px' },
    render: (column: BatchRecord) => column.glnBuyer || '',
  },
  {
    title: 'Gln Delivery Location',
    label: 'GlN Delivery location',
    key: 'glnDeliveryLocation',
    defaultSelectedBatchContractColumn: true,
    style: { flex: '1 0 50px' },
    render: (column: BatchRecord) => column.glnDeliveryLocation || '',
  },
  {
    title: 'Quantity',
    label: 'Quantity',
    key: 'quantity',
    style: { flex: '1 0 50px' },
    render: (column: BatchRecord) => column.quantity || '',
  },
  {
    title: 'Delivery Datetime',
    label: 'Delivery datetime',
    key: 'deliveryDatetime',
    defaultSelectedBatchContractColumn: true,
    style: { flex: '1 0 50px' },
    render: (column: BatchRecord) => renderShortDateFormat(column.deliveryDatetime),
  },
  ...baseModelFields(modelRequiredInfo),
];
