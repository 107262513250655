import { get, isEqual } from 'lodash';
import { createSelectorCreator, defaultMemoize, Selector } from 'reselect';
import { AppStateType } from 'types';

export const peek = (...path: string[]) => (obj) => get(obj, path);

const createSelector = createSelectorCreator(defaultMemoize, isEqual);

export function selector<RV = unknown>(...args): Selector<AppStateType, RV> {
  return createSelector(...args);
}
