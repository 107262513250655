import React, { useLayoutEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import * as batchesActions from 'domain/batches/actions';
// components
import Datatable from 'components/Datatable/datatable';
// selectors
import { getBatchContract, getIsLoading } from 'domain/batches';
import {
  getBatchInfoByUrlOrgRoleAll,
  getModel,
  getDisplayModel,
  getParams,
  getQueryParams,
} from 'domain/common';
// libs
import { getUrl } from 'routes';
//
import { BATCH_STATUS, CHANNELS } from 'themes/constants';
// jss
import batchesContractStyle from 'pages/batches/styles/batchesContractStyle';
import injectSheet, { WithStyles } from 'react-jss';
// types
import { AppStateType } from 'types';
import { compose } from 'redux';
import { withSimpleTabs } from 'components/Dropdown/SimpleTabGroup';
import { useInitialRender } from 'lib/utils';

interface OwnProps extends WithStyles<typeof batchesContractStyle> {}

const mapStateToProps = (state: AppStateType) => ({
  batchContract: getBatchContract(state),
  isLoading: getIsLoading(state),
  batchInfoAll: getBatchInfoByUrlOrgRoleAll(state),
  model: getModel(state),
  routerParams: getParams(state),
  displayModel: getDisplayModel(state),
  queryParams: getQueryParams(state),
});

const BatchContract: React.FC<OwnProps> = (props) => {
  const dispatch = useDispatch();
  const {
    batchContract,
    isLoading,
    model,
    routerParams,
    displayModel,
    queryParams,
    batchInfoAll,
  } = useSelector(mapStateToProps, shallowEqual);
  const { classes } = props;

  const isInitialRender = useInitialRender();

  const isArchived = () => queryParams.status === BATCH_STATUS.ARCHIVED;

  const getFilters = () => (isArchived() ? { status: BATCH_STATUS.ARCHIVED } : {});

  const fetchBatches = () => {
    const filters = getFilters();

    dispatch(
      batchesActions.fetchBatchContractAction({
        batchEndpoint: batchInfoAll[routerParams.channelName as CHANNELS].endpoint,
        contractNumber: routerParams.contract_number,
        filters,
      }),
    );
  };

  useLayoutEffect(() => {
    if (routerParams.type && routerParams.contract_number && queryParams.status) {
      fetchBatches();
    }
  }, [routerParams.type, routerParams.contract_number, queryParams.status]);

  console.log(batchInfoAll);

  return (
    <>
      <Datatable
        data={batchContract}
        columns={displayModel}
        settingsAndActions={model && model.settingsAndActions}
        isLoading={isLoading}
        renderDefaultKey="renderBatchesContract"
      />

      {!isInitialRender && !isLoading && batchContract.length === 0 && (
        <div className={classes.returnToBatches}>
          <Link to={{ pathname: getUrl('Batches') }}>Back to Batch List</Link>
        </div>
      )}
    </>
  );
};

export default compose(
  injectSheet(batchesContractStyle),
  withSimpleTabs,
)(BatchContract);
