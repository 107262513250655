export default () => [
  {
    id: 12011,
    is_read: false,
    created_at: '2019-08-28T13:54:12.378Z',
    event: {
      id: 2369,
      entityType: 'ResellerBatch',
      entityId: '345518320001408000',
      statusChangedTo: null,
      eventDoneAt: '2019-08-28T13:54:09.000Z',
      actionType: 'CREATED',
      actionSource: 'frontEndApp',
      channel: { title: 'Bananas', name: 'bananas' },
      eventType: { id: 1, title: 'Batch upload', slug: 'BatchUpload' },
      eventDoneBy: {
        username: 'BanafoodTST',
        email: 'BanafoodTST@pc.t2f.io',
        org: { name: 'Banafood', fullname: 'Banafood', logo: null },
      },
    },
  },
  {
    id: 10287,
    is_read: false,
    created_at: '2019-08-23T12:28:12.099Z',
    event: {
      id: 2009,
      entityType: 'CertFile',
      entityId: 'ae614ac-78d7-4eff-9406-d32702109994',
      statusChangedTo: null,
      eventDoneAt: '2019-08-23T12:28:09.000Z',
      actionType: 'CREATED',
      actionSource: null,
      channel: { title: 'Bananas', name: 'bananas' },
      eventType: { id: 4, title: 'Certificate upload', slug: 'CertificateUpload' },
      eventDoneBy: {
        username: 'BanafoodTST',
        email: 'BanafoodTST@pc.t2f.io',
        org: { name: 'Banafood', fullname: 'Banafood', logo: null },
      },
    },
  },
];
