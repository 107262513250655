import React, { Dispatch, SetStateAction } from 'react';
// lib
import { Tab } from 'lib/lib';
// jss
import injectSheet, { WithStyles } from 'react-jss';
// styles
import batchValidationStyles from 'pages/batches/styles/batchValidationStyles';

export type ChangeTabProps = {
  activeTabName: string;
  changeTabCallback: Dispatch<SetStateAction<string>>;
};

type BatchTabProps = {
  tab: Tab;
} & ChangeTabProps &
  WithStyles<typeof batchValidationStyles>;

const BatchTab: React.FC<BatchTabProps> = ({ tab, activeTabName, classes, changeTabCallback }) => {
  const { modelName, title } = tab;

  return (
    <button
      type="button"
      data-tab={title}
      className={`${classes.tab} ${modelName === activeTabName ? classes.tabActive : ''}`}
      onClick={() => changeTabCallback(modelName)}
    >
      {title}
    </button>
  );
};

export default injectSheet(batchValidationStyles)(BatchTab);
