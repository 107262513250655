import Route from 'route-parser';

import ahBatchContractListResponseOranges from 'mocks/responses/channels/oranges/ahbatch/contractList';
import ahBatchindexListResponseOranges from 'mocks/responses/channels/oranges/ahbatch/indexList';
import ahBatchDetailsResponseOranges from 'mocks/responses/channels/oranges/ahbatch/details';
import uploadBatchResponse from 'mocks/responses/batchUpload/batchUploadEggsOranges';

import { deleteBatchResponseEggsOranges } from 'mocks/router/utils';

export default (request) => {
  const routeq = new Route('/api/v1/oranges/ahbatch(?*filter)(/:batchId)(/contracts/:contractId)');
  const parameters = routeq.match(request.url);
  if (parameters) {
    if (parameters.filter) {
      if (parameters.filter.includes('beginDate')) {
        return [200, ahBatchindexListResponseOranges()];
      }
    }

    if (parameters.contractId) {
      return [200, ahBatchContractListResponseOranges()];
    }

    if (parameters.batchId) {
      if (request.method === 'delete') {
        return [200, deleteBatchResponseEggsOranges(parameters.batchId, 'ah')];
      }

      return [200, ahBatchDetailsResponseOranges()];
    }
  }

  if (request.method === 'post') {
    return [200, uploadBatchResponse(JSON.parse(request.data))];
  }

  return [500, `url not proceeded: ${request.url}`];
};
