import React, { useCallback, useEffect, useMemo, useState } from 'react';
import SelectComponent from 'react-select';
import isEqual from 'lodash/isEqual';

import Layout from 'components/Form/Layout';
import { WrappedFieldProps } from 'redux-form';
import { ValueType, ActionMeta } from 'react-select';
import { defaultStyles, defaultTheme } from './selectStyles';
import injectSheet, { WithStyles } from 'react-jss';

type Keys = {
  value: string;
  title: string;
};

type OptionValue = {
  [key: string]: any;
};

type Option<T extends OptionValue> = {
  value: T;
  label?: string;
  title?: string;
};

type OwnProps<T extends OptionValue> = WithStyles<typeof defaultStyles> & {
  className?: string;
  keys?: Keys;
  formatValue?: (value: { name?: string; value?: string; label?: string }) => any;
  style?: object;
  disabled?: boolean;
  defaultValue?: Option<T>;
  title: string;
  options: {
    [key: string]: Option<T>;
  }[];
};

const Select = <T extends OptionValue>(
  props: React.PropsWithChildren<OwnProps<T> & WrappedFieldProps>,
): React.ReactElement<Omit<T, keyof OptionValue>> => {
  const {
    input: { onBlur, ...input },
    title,
    options: optionsRaw,
    keys = { value: 'value', title: 'label' },
    disabled,
    defaultValue,
    formatValue = ({ value }) => value,
    style = {},
    meta,
    className,
    classes,
    ...rest
  } = props;

  const [value, setValue] = useState(input.value);

  const options = useMemo(
    () =>
      optionsRaw.map((opt) => ({
        value: opt[keys.value],
        label: opt[keys.title],
      })),
    [optionsRaw],
  );

  type OnChange = (value: ValueType<OptionValue>, actionMeta: ActionMeta) => void;

  const onChange = useCallback(
    <T extends OptionValue>(
      option: ValueType<OptionValue>,
      desc: ActionMeta,
      currentInput: typeof input,
    ) => {
      const { name, value, onChange: change } = currentInput;

      switch (desc.action) {
        case 'select-option':
          change(
            formatValue({
              name,
              value: option && (option as OptionValue).value,
              label: option && (option as OptionValue).label,
            }),
          );
          break;
        // @ts-ignore TODO: this was used as both onChange & onInputChange callback. Remove if not necessary
        case 'input-blur':
          onBlur(value);
          break;
        default:
      }
    },
    [],
  );

  useEffect(() => {
    if (defaultValue) {
      const matched = options.find((opt) => opt.value === defaultValue);

      if (matched) {
        input.onChange(matched.value);
      }
    }
  }, []);

  useEffect(() => {
    const val = options.find((opt) => isEqual(opt, input.value) || isEqual(opt.value, input.value));
    setValue(val || null);
  }, [input.value]);

  return (
    <Layout title={title} meta={meta} id={input.name} style={style}>
      <SelectComponent
        {...input}
        // onChange={onInputChange}
        onChange={(option: ValueType<OptionValue>, desc: ActionMeta) => {
          onChange(option, desc, input);
        }}
        // onInputChange={onInputChange}
        // onInputChange={onChange}
        onBlur={() => {}}
        className={`${classes.defaults} ${className} ${
          Boolean(meta.touched && meta.error) ? 'error' : ''
        }`}
        classNamePrefix="react-select"
        theme={defaultTheme}
        blurInputOnSelect={false}
        isDisabled={disabled}
        value={value}
        options={options}
        defaultValue={options.find((opt) => opt.value === defaultValue)}
        {...rest}
      />
    </Layout>
  );
};

export default injectSheet(defaultStyles)(Select);
