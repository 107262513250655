import React, { useLayoutEffect } from 'react';
import { compose } from 'redux';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import moment from 'moment';
// jss
import injectStyle, { WithStyles } from 'react-jss';
import certificatesStyles from 'pages/documents/styles/certificatesStyles';
// redux
import { getCertificates, getIsLoading } from 'domain/documents';
import { getDisplayModel, getModel, getQueryParams } from 'domain/common';
import * as documentActions from 'domain/documents/actions';
// components
import Datatable from 'components/Datatable/datatable';
import { CreateNewSvg } from 'components/icons';
import { DropDownTabGroup } from 'components/Dropdown/TabDropDown';
// types
import { BATCH_STATUS } from 'themes/constants';
import { AppStateType } from 'types';
// utils
import { getUrl } from 'routes';
import { certificatesValuesList } from 'pages/batches/Forms/listValues';
import AC from 'domain/AC';

const tabs = [
  {
    title: 'Certificates',
    id: BATCH_STATUS.ALL,
    defaultSelected: true,
  },
  {
    title: 'Archived',
    id: BATCH_STATUS.ARCHIVED,
  },
];

const mapStateToProps = (state: AppStateType) => ({
  documents: getCertificates(state),
  isLoading: getIsLoading(state),
  queryParams: getQueryParams(state),
  model: getModel(state),
  displayModel: getDisplayModel(state),
});

const Certificates: React.FC<WithStyles<typeof certificatesStyles>> = (props) => {
  const dispatch = useDispatch();
  const { classes } = props;
  const { documents, isLoading, queryParams, model, displayModel } = useSelector(
    mapStateToProps,
    shallowEqual,
  );

  const fetchCertificates = () => {
    const days = (queryParams.days as unknown) as number;
    const status = queryParams.status;

    if (!days || !status) {
      return;
    }

    const queryStringParams = {
      filter: {
        status: status === BATCH_STATUS.ARCHIVED ? status : undefined,
        beginDate: moment()
          .subtract(days, 'days')
          .format('YYYY-MM-DD'),
      },
      sorting: { property: queryParams.sortingKey, direction: queryParams.sortingValue },
    };
    dispatch(documentActions.fetchDocumentsAction(queryStringParams));
  };

  useLayoutEffect(fetchCertificates, [queryParams]);

  return (
    <section className={classes.component}>
      <div className={classes.tableHeader}>
        <DropDownTabGroup tabs={tabs} valuesList={certificatesValuesList} />

        <div className="rightPanel">
          {AC.USER.CAN.CREATE_NEW_CERTIFICATE && (
            <div className="tab">
              <Link to={{ pathname: getUrl('DocumentAdd') }}>
                <>
                  <CreateNewSvg />
                  <span>Add New Certificate</span>
                </>
              </Link>
            </div>
          )}
        </div>
      </div>

      <Datatable
        data={documents}
        columns={displayModel}
        settingsAndActions={model.settingsAndActions}
        noItemsText="No certificates are available"
        isLoading={isLoading}
      />
    </section>
  );
};

export default compose<React.FC>(
  injectStyle(certificatesStyles),
  React.memo,
)(Certificates);
