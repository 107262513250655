import React, { useLayoutEffect } from 'react';
// redux
import { useDispatch } from 'react-redux';
// actions
import * as adminActions from 'domain/admin/actions';
// jss
import injectSheet, { WithStyles } from 'react-jss';
import adminIndexStyle from 'pages/admin/styles/adminIndexStyle';
// components
import AdminNav from 'pages/admin/components/adminNav';

const AdminIndex: React.FC<WithStyles<typeof adminIndexStyle>> = ({ classes }) => {
  const dispatch = useDispatch();
  useLayoutEffect(() => {
    dispatch(adminActions.fetchUsersAction());
  }, []);
  return (
    <div className={classes.component}>
      <AdminNav />
    </div>
  );
};

export default injectSheet(adminIndexStyle)(AdminIndex);
