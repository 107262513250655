import produce from 'immer';
import { AnyAction } from 'redux';

import * as batchesActions from './actions';
import * as envActions from 'domain/env/actions';

const initialState = {
  resources: [],
  resource: {},
  isLoading: false,
  errors: {},
  selected: [],
  queueStatus: {
    pendingTaskCount: 0,
    estimatedEndTime: 0,
  },
};

const batches = produce<(draft: typeof initialState, action: AnyAction) => void>(
  (draft, { type, payload /* channelName */ }) => {
    switch (type) {
      case envActions.setChannelAction.type:
      case envActions.logOutAction.success:
        return initialState;

      case batchesActions.fetchQueueStatusAction.type:
        draft.isLoading = true;
        return;

      case batchesActions.fetchQueueStatusAction.failure:
        draft.isLoading = false;
        return;

      case batchesActions.fetchQueueStatusAction.success:
        draft.isLoading = false;
        draft.queueStatus = payload;
        return;

      // Batch Contract
      case batchesActions.fetchBatchContractAction.type:
        // TODO same as fetchBatchHandshakeAction.type?
        draft.isLoading = true;
        draft.resources = initialState.resources;
        return;

      case batchesActions.fetchBatchContractAction.success:
        draft.isLoading = false;
        draft.resources = payload;
        return;

      case batchesActions.fetchBatchContractAction.failure:
        draft.isLoading = false;
        draft.errors = payload;
        return;

      // Batch Contract
      case batchesActions.fetchBatchHandshakeAction.type:
        // TODO same as fetchBatchContractAction.type?
        draft.isLoading = true;
        draft.resources = initialState.resources;
        return;

      case batchesActions.fetchBatchHandshakeAction.success:
        draft.isLoading = false;
        draft.resources = payload;
        return;

      case batchesActions.fetchBatchHandshakeAction.failure:
        draft.isLoading = false;
        draft.errors = payload;
        return;

      // Batch Validation
      case batchesActions.fetchBatchValidationAction.type:
        draft.isLoading = true;
        draft.resources = initialState.resources;
        return;

      case batchesActions.fetchBatchValidationAction.success:
        draft.isLoading = false;
        draft.resource = payload;
        return;

      case batchesActions.fetchBatchValidationAction.failure:
        draft.isLoading = false;
        draft.errors = payload;
        return;

      // Batch
      case batchesActions.fetchBatchAction.type:
        draft.isLoading = true;
        draft.resources = initialState.resources;
        return;

      case batchesActions.fetchBatchAction.success:
        draft.isLoading = false;
        draft.resource = payload;
        return;

      case batchesActions.fetchBatchAction.failure:
        draft.isLoading = false;
        draft.errors = payload;
        return;

      // Batches
      case batchesActions.fetchBatchesAction.type:
        Object.assign(draft, initialState);
        draft.isLoading = true;
        return;

      case batchesActions.fetchBatchesAction.success:
        draft.isLoading = false;
        draft.resources = payload;
        return;

      case batchesActions.fetchBatchesAction.failure:
        draft.isLoading = false;
        draft.errors = payload;
        return;

      //  BACTH_WITH_USER
      case batchesActions.fetchBatchWithUserAction.type:
        Object.assign(draft, initialState);
        draft.isLoading = true;
        return;

      // DELETE Batch
      case batchesActions.deleteBatchAction.type:
        draft.isLoading = true;
        return;

      case batchesActions.deleteBatchAction.success:
        draft.isLoading = false;
        draft.resources = payload;
        return;

      case batchesActions.deleteBatchAction.failure:
        draft.isLoading = false;
        draft.errors = payload;
        return;

      // Upload Batches CSV
      case batchesActions.uploadBatchesCSVAction.type:
        draft.isLoading = true;
        return;

      case batchesActions.uploadBatchesCSVAction.success:
        draft.isLoading = false;
        return;

      case batchesActions.uploadBatchesCSVAction.failure:
        draft.isLoading = false;
        return;

      // Split Upload Batches CSV
      case batchesActions.splitUploadBatchesCSVAction.type:
        draft.isLoading = true;
        return;

      case batchesActions.splitUploadBatchesCSVAction.success:
        draft.isLoading = false;
        return;

      case batchesActions.splitUploadBatchesCSVAction.failure:
        draft.isLoading = false;
        return;

      // Upload Batches Excel
      case batchesActions.uploadBatchesExcelAction.type:
        draft.isLoading = true;
        return;

      case batchesActions.uploadBatchesExcelAction.success:
        draft.isLoading = false;
        return;

      case batchesActions.uploadBatchesExcelAction.failure:
        draft.isLoading = false;
        return;

      // DELETE Batches Contract
      case batchesActions.archiveBatchContractAction.type:
        draft.isLoading = true;
        return;

      case batchesActions.archiveBatchContractAction.success:
        const { keyField, contractId: keyFieldValue } = payload;

        draft.isLoading = false;
        draft.resources = draft.resources.filter(
          (f) => f[keyField] !== decodeURIComponent(keyFieldValue),
        );
        return;

      case batchesActions.archiveBatchContractAction.failure:
        draft.isLoading = false;
        draft.errors = payload;
        return;

      // approveBatchContractAction
      case batchesActions.approveBatchContractAction.type:
        draft.isLoading = true;
        return;

      case batchesActions.approveBatchContractAction.success:
        draft.isLoading = false;
        draft.resources = draft.resources.map((f) =>
          f[payload.orgInfo.contract] === decodeURIComponent(payload.data[payload.orgInfo.contract])
            ? {
                ...f,
                status: payload.data.status,
                approvedAt: payload.data.approvedAt,
                approveMsg: payload.data.approveMsg,
                approveReason: payload.data.approveReason,
                approvedBy: payload.data.approvedBy_participant.user.username,
              }
            : f,
        );
        return;

      case batchesActions.approveBatchContractAction.failure:
        draft.isLoading = false;
        return;

      // setSelectedBatches
      case batchesActions.setSelectedBatchesAction.success:
        if (!payload) {
          draft.selected = initialState.selected;
          return;
        }

        if (!draft.selected.includes(payload)) {
          draft.selected = [...draft.selected, payload];
          return;
        }

        draft.selected = draft.selected.filter((batchId) => batchId !== payload);
        return;

      // DELETE multiple batches
      case batchesActions.deleteMultipleBatchesAction.type:
        draft.isLoading = true;
        return;

      case batchesActions.deleteMultipleBatchesAction.success:
        draft.isLoading = false;
        draft.selected = initialState.selected;
        draft.resources = {
          ...draft.resources,
          [payload.activeTabName]: draft.resources[payload.activeTabName].filter(
            (batch) => !payload.batchIds.includes(batch.batchId),
          ),
        };
        return;

      case batchesActions.deleteMultipleBatchesAction.failure:
        draft.isLoading = false;
        draft.errors = payload;
        return;
    }
  },
  initialState,
);
export const reducer = { batches };
