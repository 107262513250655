import { ORGANIZATIONS } from 'themes/constants';
import importer from 'mocks/responses/notifications/tilapia/importer';
import processor from 'mocks/responses/notifications/tilapia/processor';
import producer from 'mocks/responses/notifications/tilapia/producer';
import retailer from 'mocks/responses/notifications/tilapia/retailer';

import { createNotificationResponse } from '../utils';

const { SIM, REGALSPRINGS, JUMBO, MAYONNA, SEAFOODCONNECTION } = ORGANIZATIONS;

export default (org: ORGANIZATIONS) => {
  switch (org) {
    case SEAFOODCONNECTION:
      return createNotificationResponse(2, importer());
    case MAYONNA:
      return createNotificationResponse(2, processor());
    case REGALSPRINGS:
      return createNotificationResponse(2, producer());
    case JUMBO:
      return createNotificationResponse(2, retailer());
    case SIM:
      return createNotificationResponse(8, [
        ...importer(),
        ...processor(),
        ...producer(),
        ...retailer(),
      ]);
    default:
      return createNotificationResponse(0, []);
  }
};
