import { peek, selector } from 'lib/selectors';
import { BatchRecord, IndexedObject } from 'lib/lib';
import { AppStateType } from 'types';

const batches = (state: AppStateType) => state.batches;

export const getBatches = selector<BatchRecord[]>(batches, peek('resources'));
export const getBatch = selector<BatchRecord>(batches, peek('resource'));
export const getBatchContract = selector<
  IndexedObject<IndexedObject<string>[]> | IndexedObject<string>[]
>(batches, peek('resources'));
export const getIsLoading = selector(batches, peek('isLoading'));
export const getSelectedBatches = selector<string[]>(batches, peek('selected'));
export const getQueueStatus = selector(batches, peek('queueStatus'));
