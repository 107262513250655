import React, { useLayoutEffect, useMemo } from 'react';
// redux
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
// components
import Datatable from 'components/Datatable/datatable';
import AdminNav from 'pages/admin/components/adminNav';
import TableCellDropdown from 'components/Dropdown/TableCellDropdown';
import ModelSettingsDropDown from 'components/Dropdown/ModelSettingsDropDown';
// reducers
import { getInvitations, getIsLoading } from 'domain/admin';
import { getDisplayModel } from 'domain/common';
// actions
import * as adminActions from 'domain/admin/actions';
// jss
import injectSheet, { WithStyles } from 'react-jss';
import adminIndexStyle from 'pages/admin/styles/adminIndexStyle';
// types
import { AppStateType } from 'types';
import { AdminInvitation } from 'lib/lib';

const mapStateToProps = (state: AppStateType) => ({
  invitations: getInvitations(state),
  isLoading: getIsLoading(state),
  displayModel: getDisplayModel(state),
});

const AdminInvitations: React.FC<WithStyles<typeof adminIndexStyle>> = ({ classes }) => {
  const dispatch = useDispatch();
  const { invitations, displayModel, isLoading } = useSelector(mapStateToProps, shallowEqual);

  useLayoutEffect(() => {
    dispatch(adminActions.fetchInvitationsAction());
  }, []);

  const settingsAndActions = useMemo(
    () => ({
      key: 'settingsAndActions',
      title: <ModelSettingsDropDown />,
      style: { flex: '1 0 100px' },
      render: (invitation: AdminInvitation) =>
        invitation.status === 'pending' && (
          <TableCellDropdown
            items={[
              {
                key: 'canceled',
                title: 'cancel',
                onClick: () => {
                  dispatch(
                    adminActions.updateInvitationAction({
                      id: invitation.id,
                      data: { status: 'canceled' },
                    }),
                  );
                },
              },
              {
                key: 'resend',
                title: 'resend',
                onClick: () => {
                  dispatch(adminActions.resendInvitationAction({ oldInvitationId: invitation.id }));
                },
              },
            ]}
          />
        ),
    }),
    [],
  );

  return (
    <div className={classes.component}>
      <AdminNav />
      <Datatable
        data={invitations}
        columns={displayModel}
        isLoading={isLoading}
        settingsAndActions={settingsAndActions}
      />
    </div>
  );
};

export default injectSheet(adminIndexStyle)(AdminInvitations);
