import logoSIM from 'components/logos/SIM.png';
import {
  BatchListSvg,
  NotificationListSvg,
  CertificateSvg,
  DashboardSvg,
} from 'components/icons';
import { theme } from 'themes/styles';

export default {
  logo: logoSIM,
  colors: {
    primary: 'rgb(204, 18, 29)',
    primaryHover: 'rgb(204, 18, 29)',
    mainMenuLine: 'rgb(204, 18, 29)',
    mainMenuIconColor: 'rgb(204, 18, 29)',
    mainMenuTextColor: 'rgb(0, 0, 0)',
    black: 'rgb(61, 61, 61)',
    backgroundHead: 'rgb(255, 255, 255)',
    datePickerBgColor: 'rgb(204, 18, 29)',
    accent: 'rgb(204, 18, 29)',
    modelSettingsCheckedColor: theme.colors.green,
  },
  mainMenuItems: [
    {
      routeName: 'Dashboard',
      icon: DashboardSvg,
      disabled: true,
    },
    {
      routeName: 'Notifications',
      icon: NotificationListSvg,
      disabled: false,
    },
    {
      routeName: 'Batches',
      icon: BatchListSvg,
    },
    {
      routeName: 'Documents',
      icon: CertificateSvg,
      disabled: false,
    },
  ],
};
