import { Theme } from 'lib/lib';

export default (theme: Theme) => ({
  component: {
    padding: ['2em', 0],
  },

  addCertificate: {
    display: 'flex',
    alignItems: 'flex-end',

    '& > a': {
      display: 'inline-flex',
      alignItems: 'center',
      color: theme.colors.white,
      margin: 0,
      padding: [5, 9],
      borderRadius: [10, 10, 0, 0],
      cursor: 'pointer',
      backgroundColor: theme.colors.primary,
      transition: 'background-color 0.5s ease',
      textDecoration: 'none',
      border: 0,
      '&:hover': {
        backgroundColor: theme.colors.primaryHover,
        textDecoration: 'none',
      },
      '& svg': {
        width: 24,
        height: 24,
        fill: theme.colors.white,
        marginRight: 10,
      },
      '& span': {
        font: {
          size: theme.fontSizes.sm,
          family: 'Lato',
          weight: 400,
        },
        color: theme.colors.white,
      },
    },
  },

  certList: {},
  certificateListHead: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: 0,
    padding: 10,
    backgroundColor: 'rgb(143, 143, 143)',
    borderRadius: [0, 10, 0, 0],
    textAlign: 'center',
    '& div': {
      display: 'flex',
      flexGrow: 1,
      alignItems: 'center',
      textAlign: 'center',
      color: theme.colors.white,
    },
  },
  certificateWrap: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: theme.colors.background,
    margin: 0,
    padding: 10,
    cursor: 'pointer',
    '& i': {
      fontSize: 35,
    },
  },
  addNewBtn: {
    border: 'none',
    display: 'flex',
    fontSize: theme.fontSizes.md,
    fontWeight: 'bold',
    textAlign: 'center',
    background: 'transparent',
    alignItems: 'center',
    '&:before': {
      content: '"+"',
      display: 'block',
      lineHeight: '100%',
      width: 35,
      height: 35,
      marginRight: 15,
      fontSize: 30,
      fontWeight: 500,
      border: '2px solid #000',
      borderRadius: '50%',
      cursor: 'pointer',
    },
  },

  certTxt: {
    width: '100%',
    fontSize: theme.fontSizes.sm,
  },
  certTitle: {
    fontWeight: 'bold',
    marginBottom: 0,
    marginTop: 0,
    marginRight: 'auto',
    fontSize: theme.fontSizes.md,
  },
  certNumber: {
    marginBottom: 0,
  },
  certExpiry: {
    display: 'flex',
    flexDirection: 'column',
    // flexBasis: '100px',
    width: 120,
    marginLeft: 'auto',
    paddingLeft: 5,
    fontFamily: 'Lato',
    fontSize: 14,
    color: '#3d3d3d',
    textAlign: 'right',

    '& > strong': {
      fontWeight: 700,
    },
  },
  btn: {
    display: 'block',
    margin: '40px auto',
    position: 'relative',
    fontSize: 14,
    width: 140,
    height: 40,
    fontWeight: 600,
    borderRadius: 20,
    border: 'none',
    backgroundColor: theme.colors.success,
    color: theme.colors.white,
  },
  certWrapper: {
    backgroundColor: theme.colors.backgroundLight,
    height: '100%',
  },
  certView: {
    ...theme.container,
    paddingBottom: 30,
    margin: [0, 'auto'],
    boxSizing: 'border-box',
    maxWidth: 900,
  },
  viewInfo: {
    marginBottom: 30,
  },
  viewFile: {},
  viewBottom: {
    textAlign: 'center',
  },
  viewBackBtn: {
    display: 'inline-block',
    margin: '40px auto 30px',
    padding: [10, 20],
    fontSize: 14,
    width: 200,
    height: 40,
    fontWeight: 600,
    borderRadius: 20,
    border: 'none',
    backgroundColor: theme.colors.success,
    color: theme.colors.white,
  },
  filterSelect: {
    textAlign: 'right',
    textTransform: 'capitalize',
    '& option': {
      width: '100%',
    },
  },
  documentWrapper: {
    position: 'relative',
    '& .scrollable': {
      overflowY: 'scroll',
      maxHeight: 400,
    },
    '& .bg': {
      position: 'absolute',
      zIndex: 2,
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      background: `linear-gradient(to bottom, transparent, transparent 70%, ${theme.colors.backgroundLight})`,
    },
  },
  fileInfo: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& svg': {
      width: 32,
      height: 32,
      fill: theme.colors.success,
    },
  },

  tableHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',

    '& .tabs': {
      display: 'flex',
      alignItems: 'flex-end',

      '& .tab': {
        '&.active': {
          '& > a, & > div, & > button': {
            backgroundColor: theme.colors.primaryHover,
          },
        },
        '& > a, & > button': {
          cursor: 'pointer',
        },
        '& > a, & > div, & > button': {
          display: 'inline-flex',
          alignItems: 'center',
          minWidth: 240,
          height: 40,
          color: theme.colors.white,
          margin: [0, 2, 0, 0],
          padding: [5, 9],
          borderRadius: [10, 10, 0, 0],
          backgroundColor: theme.colors.primary,
          transition: 'background-color 0.5s ease',
          textDecoration: 'none',
          border: 0,
          outline: 0,
          '&:hover': {
            backgroundColor: theme.colors.primaryHover,
            textDecoration: 'none',
          },
          '& svg': {
            width: 24,
            height: 24,
            fill: theme.colors.white,
            marginRight: 10,
          },
          '& span': {
            marginRight: 10,
            font: {
              size: theme.fontSizes.sm,
              family: 'Lato',
              weight: 400,
            },
            color: theme.colors.white,
          },
          '& select': {
            outline: 0,
          },
        },
      },
      '& .tab.select': {
        display: 'inline-flex',
        minWidth: 250,
        '& > .title, & > .drop-down-list': {
          minWidth: 'auto',
        },
        '& > button.title': {
          flex: '2 0 50px',
          borderRadius: [10, 0, 0, 0],
          cursor: 'pointer',
          marginRight: 0,
          borderRight: '1px solid #aaa',
        },
        '& > .drop-down-list': {
          flex: '1 0 50px',
          borderRadius: [0, 10, 0, 0],
          cursor: 'pointer',
          position: 'relative',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          padding: 0,
          '& > button': {
            flex: '1 0 100%',
            minHeight: 30,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: 'transparent',
            border: 0,
            cursor: 'pointer',
            color: theme.colors.white,
            outline: 0,
            '& svg': {
              marginRight: 3,
              width: 15,
              height: 15,
            },
          },
          '& > ul': {
            display: 'none',
            position: 'absolute',
            minWidth: '150%',
            padding: [0, 0, 0, 0],
            backgroundColor: theme.colors.primary,
            top: 40,
            left: 0,
            '&.isDropDownListOpen': {
              display: 'block',
              zIndex: 99,
            },
            '& > li': {
              display: 'flex',
              padding: [0, 0, 0, 5],
              '&:hover': {
                backgroundColor: theme.colors.primaryHover,
              },
              '& button': {
                flex: '2 0 30px',
                textAlign: 'left',
                outline: 0,
                color: theme.colors.white,
                backgroundColor: 'transparent',
                border: 0,
                padding: [5, 10],
                cursor: 'pointer',
              },
            },
          },
        },
      },
    },
    '& .rightPanel': {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',

      '& .tab': {
        '& > a, & > button': {
          cursor: 'pointer',
        },
        '& > a, & > div, & > button': {
          display: 'inline-flex',
          alignItems: 'center',
          height: 30,
          color: theme.colors.white,
          margin: [0, 2, 0, 0],
          padding: [5, 9],
          borderRadius: 20,
          backgroundColor:
            theme.colors.primaryHover === theme.colors.accent
              ? theme.colors.primary
              : theme.colors.primaryHover,
          transition: 'background-color 0.5s ease',
          textDecoration: 'none',
          border: 0,
          outline: 0,
          '&:hover': {
            backgroundColor: theme.colors.accent,
            textDecoration: 'none',
          },
          '& svg': {
            width: 17,
            height: 17,
            fill: theme.colors.white,
            marginRight: 10,
          },
          '& span': {
            marginRight: 10,
            font: {
              size: theme.fontSizes.xs,
              family: 'Lato',
              weight: 400,
            },
            color: theme.colors.white,
          },
          '& select': {
            outline: 0,
          },
        },
      },
    },
  },
});
