import Dropzone from 'react-dropzone';
import React, { useCallback, useState } from 'react';
import { WrappedFieldProps } from 'redux-form';
import injectStyles, { WithStyles } from 'react-jss';
import stylesDropzoneInput from 'components/Form/DropZone/stylesDropzoneInput';

interface OwnProps extends WrappedFieldProps, WithStyles<typeof stylesDropzoneInput> {
  onDropzoneInputChange: (filesToUpload: File[]) => void;
  style?: object;
  fileName?: string;
}

const RenderDropzoneInput: React.FC<OwnProps> = (props) => {
  const { input, meta, onDropzoneInputChange, style = {}, classes, fileName } = props;
  const [isActive, setIsActive] = useState(false);

  const setActive = useCallback(() => setIsActive(true), []);
  const setNotActive = useCallback(() => setIsActive(false), []);

  return (
    <Dropzone
      accept="image/*,application/pdf"
      onDrop={(filesToUpload) => {
        onDropzoneInputChange(filesToUpload);
        input.onChange(filesToUpload);
      }}
      onDragOver={setActive}
      onDragLeave={setNotActive}
      onDropAccepted={setNotActive}
      onDropRejected={setNotActive}
    >
      {({ getRootProps, getInputProps }) => (
        <label
          {...getRootProps()}
          className={`${classes.label}${
            meta.touched && meta.error ? ` ${classes.labelError}` : ''
          }`}
          style={{
            ...style,
            ...(isActive
              ? {
                  borderColor: 'green !important',
                  backgroundColor: 'rgba(28, 140, 54, 0.2) !important',
                }
              : {}),
          }}
          onClick={(e) => e.stopPropagation()}
        >
          <input {...getInputProps()} />
          {(!input.value || !(input.value && input.value[0] && input.value[0] instanceof File)) && (
            <div>Drop certificate here</div>
          )}
          {meta.touched && meta.error && <span className={classes.error}>{meta.error}</span>}

          {((input.value && Array.isArray(input.value)) || fileName) && (
            <ul>
              {input.value && Array.isArray(input.value) && (
                <ul>{input.value[0] && <li className={classes.item}>{input.value[0].name}</li>}</ul>
              )}
            </ul>
          )}
        </label>
      )}
    </Dropzone>
  );
};

export default injectStyles(stylesDropzoneInput)(RenderDropzoneInput);
