import { Theme } from 'lib/lib';

export default (theme: Theme) => ({
  tableIcon: {
    width: 24,
    height: 24,
    padding: 0,
    zIndex: 1,
    '& > svg': {
      width: 24,
      height: 24,
      fill: theme.colors.white,
    },
  },
  dropdownWrapper: {
    position: 'absolute',
    display: 'flex',
    flexWrap: 'wrap',
    top: '100%',
    right: 0,
    minWidth: '45em',
    width: 'min-content',
    height: 'min-content',
    background: {
      image: `linear-gradient(${theme.colors.primaryHover} 2%, ${theme.colors.primary} 102%)`,
      size: 'cover',
    },

    borderRadius: [10, 0, 10, 10],
    boxShadow: 'rgba(0, 0, 0, 0.2) 0px 1px 2px 0px',
    padding: 10,
    '& $submit': {
      display: 'flex',
      flex: '0 1 100%',
      padding: 0,
      margin: [10, 0, 0],
      '& .cancel, & .accept': {
        flex: '0 3 200px',
        height: 30,
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'row',
        margin: [10, 'auto', 0, 'auto'],
        padding: [5, 10],
        cursor: 'pointer',
        backgroundColor: 'rgba(255,255,255, 0.2)',
        borderRadius: 5,
        '&:hover': {
          opacity: 0.8,
        },
        '& svg': {
          flex: '0 0 20px',
          fill: theme.colors.white,
        },
        '& span': {
          flex: '0 1 150px',
          fill: theme.colors.white,
          paddingLeft: 10,
          textAlign: 'left',
          alignItems: 'center',
          justifyContent: 'flex-start',
        },
      },
    },
  },
  columnList: {
    listStyle: 'none',
  },
  columnListSingleTab: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  columnListItem: {
    display: 'flex',
    justifyContent: 'flex-start',
    height: 24,
    alignItems: 'center',
    textAlign: 'left',
    margin: [2, 5],
    fontSize: 18,
    cursor: 'pointer',
    userSelect: 'none',
  },
  columnListItemSingleTab: {
    margin: 0,
    flex: '0 0 33%',
    minHeight: 27,
    marginBottom: '1em',
  },
  tabname: {
    fontSize: 20,
    marginBottom: 10,
  },
  tabContainer: {
    margin: [5, 10],
  },
  tabContainerSingleTab: {
    width: '100%',
  },
  submit: {},
  svgContainer: {
    display: 'block',
    width: 24,
    maxWidth: 24,
    maxHeight: 24,
    '& svg': {
      fill: theme.colors.modelSettingsCheckedColor,
    },
  },
});
