import React from 'react';
import { Link } from 'react-router-dom';
// utils
import { getUrl } from 'routes';
import { Certificate } from 'lib/lib';

interface OwnProps {
  column: Certificate;
  className?: string;
}

const DetailsCertificateLink: React.FC<OwnProps> = (props) => {
  const { column, className = 'approve-document' } = props;
  return (
    <>
      <Link
        type="button"
        className={className}
        to={{ pathname: getUrl('Document', { id: column.certId }) }}
      >
        Details
      </Link>
    </>
  );
};

export default React.memo(DetailsCertificateLink);
