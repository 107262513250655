import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { getRequests, getIsLoading } from 'domain/requests';
// icons
import { CreateNewSvg } from 'components/icons';
// utils
import moment from 'moment';
//
import * as requestsActions from 'domain/requests/actions';
import AC from 'domain/AC';

import { getUrl } from 'routes';
import { Link } from 'react-router-dom';
// jss
import injectSheet from 'react-jss';
import inboxOutboxStyles from 'pages/requests/inboxOutboxStyles';
//
import Datatable from 'components/Datatable/datatable';
import SmallLoader from 'components/Loaders/smallLoader';

class Inbox extends React.PureComponent {
  componentDidMount() {
    const { fetchRequestsList } = this.props;
    fetchRequestsList({
      requestType: 'inbox',
    });
  }

  render() {
    const { classes, requests, isLoading } = this.props;

    const columns = [
      {
        title: '',
        key: 'id',
        style: { flex: '1 0 50px' },
        render: (column) => column.id,
      },
      {
        title: 'Type',
        key: 'type',
        style: { flex: '2 0 50px' },
        render: (column) => column.type,
      },
      {
        title: 'From',
        key: 'from',
        style: { flex: '2 0 50px' },
        render: (column) => column.fromOrg.fullname,
      },
      {
        title: 'Date',
        key: 'date',
        style: { flex: '2 0 100px' },
        render: (column) =>
          column.expiry_date ? moment(column.expiry_date).format('D MMM YYYY') : '',
      },
      {
        title: 'Request information',
        key: 'requestInformation',
        style: { flex: '2 0 50px' },
        render: (column) => column.msg_request,
      },
      {
        title: 'Status',
        key: 'status',
        style: { flex: '2 0 50px' },
        render: (column) => column.status,
      },
      {
        title: '',
        key: 'settingsAndActions',
        style: { flex: '2 0 100px' },
        render: (column) => (
          <React.Fragment>
            <Link
              className="square-button"
              to={{ pathname: getUrl('InboxDetails', { inboxId: column.id }) }}
            >
              open
            </Link>
            {column.status !== 'CLOSED' && (
              <Link
                className="square-button"
                to={{ pathname: getUrl('InboxAnswer', { inboxId: column.id }) }}
              >
                answer
              </Link>
            )}
          </React.Fragment>
        ),
      },
    ];

    return (
      <section className={classes.component}>
        {AC.USER.CAN.CREATE_NEW_REQUEST && (
          <div className={classes.addRequest}>
            <Link to={{ pathname: getUrl('RequestAdd') }}>
              {isLoading ? (
                <SmallLoader theme={'black'} styles={{ margin: 'auto' }} />
              ) : (
                <React.Fragment>
                  <CreateNewSvg />
                  <span>Create new request</span>
                </React.Fragment>
              )}
            </Link>
          </div>
        )}

        <Datatable data={requests} columns={columns} />
      </section>
    );
  }
}

Inbox.propTypes = {
  classes: PropTypes.object.isRequired,
  fetchRequestsList: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  requests: PropTypes.array,
};

export default compose(
  connect(
    (state) => ({
      requests: getRequests(state),
      isLoading: getIsLoading(state),
    }),
    {
      fetchRequestsList: (data) => ({
        type: requestsActions.fetchRequestsAction.type,
        payload: data,
      }),
    },
  ),
  injectSheet(inboxOutboxStyles),
)(Inbox);
