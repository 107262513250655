import React, { useMemo } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
// redux
import * as notificationsActions from 'domain/notifications/actions';
import { getBatches } from 'domain/common';
import { getUser } from 'domain/env';
// components
import BatchLink from 'models/utils/NotificationButtons/NotificationLink/BatchLink';
import CertificateLink from 'models/utils/NotificationButtons/NotificationLink/CertificateLink';
import RequestLink from 'models/utils/NotificationButtons/NotificationLink/RequestLink';
// types
import { Notification } from 'lib/lib';
import { AppStateType } from 'types';
import { BATCH_STATUS } from 'themes/constants';

enum LINK_TYPES {
  BATCH = 'BATCH',
  CERTIFICATE = 'CERTIFICATE',
  REQUEST = 'REQUEST',
}

interface OwnProps {
  column: Notification;
}

const mapStateToProps = (state: AppStateType) => ({
  batchTypes: Object.keys(getBatches(state)),
  user: getUser(state),
});

const NotificationLink: React.FC<OwnProps> = (props) => {
  const dispatch = useDispatch();
  const { column } = props;
  const { batchTypes, user } = useSelector(mapStateToProps, shallowEqual);

  const entityType = useMemo(() => column.event.entityType, [column]);

  const actionType = useMemo(
    () => (column.event.actionType === BATCH_STATUS.ARCHIVED ? BATCH_STATUS.ARCHIVED : undefined),
    [column],
  );

  const entityId = useMemo(() => encodeURIComponent(column.event.entityId), [column]);

  const channelName = useMemo(() => column.event.channel.name, [column]);

  const eventOrgName = useMemo(() => String(column.event.eventDoneBy.org.name).toLowerCase(), [
    column,
  ]);

  const getLinkType = () => {
    if (batchTypes.includes(entityType)) {
      return LINK_TYPES.BATCH;
    } else if (entityType === 'CertFile') {
      return LINK_TYPES.CERTIFICATE;
    } else if (
      ['Request', 'Demand'].includes(entityType) &&
      column.event.eventDoneBy.org.name === user.org.name
    ) {
      return LINK_TYPES.REQUEST;
    } else {
      return null;
    }
  };

  const onClick = () => {
    if (!column.is_read) {
      dispatch(
        notificationsActions.updateNotificationAction({
          id: column.id,
          data: { is_read: true },
        }),
      );
    }
  };

  const formLink = () => {
    const linkType = getLinkType();
    switch (linkType) {
      case LINK_TYPES.BATCH: {
        return (
          <BatchLink
            entityId={entityId}
            actionType={actionType}
            entityType={entityType}
            eventOrgName={eventOrgName}
            onClick={onClick}
            channelName={channelName}
          />
        );
      }
      case LINK_TYPES.CERTIFICATE: {
        return <CertificateLink onClick={onClick} entityId={entityId} />;
      }
      case LINK_TYPES.REQUEST: {
        return <RequestLink onClick={onClick} entityId={entityId} />;
      }
      default: {
        return null;
      }
    }
  };

  return formLink();
};

export default React.memo(NotificationLink);
