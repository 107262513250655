import defaultTab from 'models/ah/eggs/tabs/defaultTabModel';
import settingsAndActions from 'models/ah/eggs/settingsAndActions/defaultArchived';
import { ORGANIZATIONS } from 'themes/constants';
import archivedModelFields from 'models/archivedModelFields';
import { ModelRequiredInfo } from 'lib/lib';

export default (modelRequiredInfo: ModelRequiredInfo) => {
  const model = defaultTab(modelRequiredInfo);
  const batchNumber = model.find((f) => f.key === 'batchnumber');
  const modifiedIndexTab = model.filter((f) => f.key !== 'batchnumber');
  const defaultArchivedTab = [batchNumber, ...modifiedIndexTab, ...archivedModelFields(false)];
  return {
    tabs: [
      {
        modelName: 'defaultTab',
        originalName: ORGANIZATIONS.AH,
        title: ORGANIZATIONS.AH,
      },
    ],
    defaultTab: defaultArchivedTab,
    settingsAndActions,
  };
};
