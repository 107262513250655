import { Theme } from 'lib/lib';

export default (theme: Theme) => ({
  dataTableRow: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'stretch',
    justifyContent: 'space-between',
    margin: 0,
    padding: 0,
    backgroundColor: theme.colors.background,
    borderBottom: '1px solid rgb(222, 222, 222)',
    '&:nth-of-type(odd)': {
      backgroundColor: theme.colors.white,
    },
    '& > div': {
      display: 'flex',
      fontSize: theme.fontSizes.sm,
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
      padding: [10, 5],
      borderLeft: '1px solid rgb(222, 222, 222)',
      '&:last-child': {
        borderRight: '1px solid rgb(222, 222, 222)',
      },
      '& img': {
        maxWidth: 30,
      },
      '& svg': {
        width: 24,
        fill: theme.colors.primary,
      },
      '& a.contract, & div.contract': {
        color: theme.colors.link,
      },
      '& a.batch-details': {
        color: theme.colors.white,
      },
      '& a.batch-archive': {
        color: theme.colors.white,
      },
      '& .sim svg': {
        fill: theme.colors.grey,
      },
      '& .warning': {
        '& svg': {
          width: 15,
          fill: theme.colors.warning,
          marginLeft: 5,
        },
      },
      '& .sim.approved svg': {
        fill: theme.colors.primary,
      },
      '& .batch-details, & .batch-archive, & .approve-batch, & .approve-document': {
        backgroundColor:
          theme.colors.primaryHover === theme.colors.accent
            ? theme.colors.primary
            : theme.colors.primaryHover,
        color: theme.colors.white,
        padding: [5, 10],
        borderRadius: 10,
        border: 0,
        transition: 'background-color 0.5s ease',
        outline: 0,
        cursor: 'pointer',
        margin: [0, 1],
        '&:hover': {
          backgroundColor: theme.colors.accent,
          textDecoration: 'none',
        },
      },
      '& .approved-text': {
        color: '#7FBA42',
        fontWeight: 'bold',
      },
      '& .validate-batch': {
        borderRadius: 8,
        padding: [8, 28],
        lineHeight: '16px',
        fontWeight: 'bold',
        color: theme.colors.white,
        cursor: 'pointer',
        backgroundColor: theme.colors.primary,
        transition: 'background-color 0.5s ease',

        '&:hover': {
          backgroundColor: 'rgba(204, 18, 29, 0.75)',
        },
      },
      '& .batch-archive': {
        backgroundColor: 'rgb(95, 98, 115)',
      },
      '& .square-button': {
        padding: [6, 20],
        textAlign: 'center',
        outline: 0,
        color: theme.colors.black,
        cursor: 'pointer',
        border: {
          width: 0,
          radius: 0,
          style: 'solid',
        },
        backgroundColor: theme.colors.white,
        font: {
          size: theme.fontSizes.md,
          style: 'normal',
        },
        boxShadow: 'rgba(0, 0, 0, 0.2) 0px 1px 2px 0px',
        marginLeft: 10,
        '&:hover': {
          backgroundColor: 'rgb(250, 250, 250)',
        },
      },
      '& .bold': {
        fontWeight: 700,
        fontFamily: 'Arial',
      },
    },
  },
  noBatchesFound: {
    display: 'block',
    textAlign: 'center',
    color: theme.colors.primary,
    padding: ['1em', 0],
    font: {
      size: theme.fontSizes.md,
    },
  },
  loader: { ...theme.components.loader },
});
