export default () => ({
  $class: 'org.powerchain.bananas.batch.ResellerContractConcept',
  details: [
    {
      $class: 'org.powerchain.bananas.batch.BatchDetailsConcept',
      createdAt: '2019-08-10T10:24:42.019Z',
      createdBy:
        'resource:org.powerchain.bananas.member.ResellerUser#ResellerUser_c3a553eb-9222-4011-bbd1-e337b7ba3562',
      status: 'PENDING',
      "modifiedBy_participant":{
        "username":null,
        "email":null,
        "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        },
        "channel":{
          "title":"Bananas",
          "name":"bananas"
        },
        "user":{
          "username":"Fyffes TST staging",
          "email":"FyffesTST@pc.t2f.io",
          "org":{
            "name":"Fyffes",
            "fullname":"Fyffes",
            "logo":null
          }
        }
      },
      "createdBy_participant":{
        "username":null,
        "email":null,
        "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        },
        "channel":{
          "title":"Bananas",
          "name":"bananas"
        },
        "user":{
          "username":"Fyffes TST staging",
          "email":"FyffesTST@pc.t2f.io",
          "org":{
            "name":"Fyffes",
            "fullname":"Fyffes",
            "logo":null
          }
        }
      }
    },
  ],
  inBatches: [
    {
      $class: 'org.powerchain.bananas.batch.ResellerInBatch',
      colombiaSSCCPallet: '3664577477',
      receiptDate: '2018-08-26T16:47:49.771Z',
      boxes: 12,
      batchId: 'ResellerInBatch_122eadc3-3ea9-4fd3-a136-5279fa0ef747',
      createdAt: '2019-08-10T10:24:42.019Z',
      createdBy:
        'resource:org.powerchain.bananas.member.ResellerUser#ResellerUser_c3a553eb-9222-4011-bbd1-e337b7ba3562',
      status: 'PENDING',
      uploadSource: 'api',
      "modifiedBy_participant":{
        "username":null,
        "email":null,
        "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        },
        "channel":{
          "title":"Bananas",
          "name":"bananas"
        },
        "user":{
          "username":"Fyffes TST staging",
          "email":"FyffesTST@pc.t2f.io",
          "org":{
            "name":"Fyffes",
            "fullname":"Fyffes",
            "logo":null
          }
        }
      },
      "createdBy_participant":{
        "username":null,
        "email":null,
        "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        },
        "channel":{
          "title":"Bananas",
          "name":"bananas"
        },
        "user":{
          "username":"Fyffes TST staging",
          "email":"FyffesTST@pc.t2f.io",
          "org":{
            "name":"Fyffes",
            "fullname":"Fyffes",
            "logo":null
          }
        }
      }
    },
    {
      $class: 'org.powerchain.bananas.batch.ResellerInBatch',
      colombiaSSCCPallet: '5286174487',
      receiptDate: '2019-06-01T08:10:17.819Z',
      boxes: 31,
      batchId: 'ResellerInBatch_943c1a07-5c32-4125-8d90-8f607273298b',
      createdAt: '2019-08-10T10:24:42.019Z',
      createdBy:
        'resource:org.powerchain.bananas.member.ResellerUser#ResellerUser_c3a553eb-9222-4011-bbd1-e337b7ba3562',
      status: 'APPROVED',
      uploadSource: 'api',
      subcontractAmount: 0,
      "modifiedBy_participant":{
        "username":null,
        "email":null,
        "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        },
        "channel":{
          "title":"Bananas",
          "name":"bananas"
        },
        "user":{
          "username":"Fyffes TST staging",
          "email":"FyffesTST@pc.t2f.io",
          "org":{
            "name":"Fyffes",
            "fullname":"Fyffes",
            "logo":null
          }
        }
      },
      "createdBy_participant":{
        "username":null,
        "email":null,
        "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        },
        "channel":{
          "title":"Bananas",
          "name":"bananas"
        },
        "user":{
          "username":"Fyffes TST staging",
          "email":"FyffesTST@pc.t2f.io",
          "org":{
            "name":"Fyffes",
            "fullname":"Fyffes",
            "logo":null
          }
        }
      }
    },
  ],
  outBatches: [
    {
      $class: 'org.powerchain.bananas.batch.ResellerOutBatch',
      banafoodSSCCPallet: '340518320001382990',
      antwerpSSCC: '3664577477',
      deliveryDate: '2019-06-23T07:10:20.419Z',
      product: 'Oregon',
      boxes: 31,
      productDescription: 'transmit Missouri invoice Bacon interface',
      batchId: 'ResellerOutBatch_43173cb0-1f93-4764-8001-f59c0866d4ab',
      createdAt: '2019-08-10T10:24:42.019Z',
      createdBy:
        'resource:org.powerchain.bananas.member.ResellerUser#ResellerUser_c3a553eb-9222-4011-bbd1-e337b7ba3562',
      status: 'PENDING',
      uploadSource: 'api',
      "modifiedBy_participant":{
        "username":null,
        "email":null,
        "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        },
        "channel":{
          "title":"Bananas",
          "name":"bananas"
        },
        "user":{
          "username":"Fyffes TST staging",
          "email":"FyffesTST@pc.t2f.io",
          "org":{
            "name":"Fyffes",
            "fullname":"Fyffes",
            "logo":null
          }
        }
      },
      "createdBy_participant":{
        "username":null,
        "email":null,
        "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        },
        "channel":{
          "title":"Bananas",
          "name":"bananas"
        },
        "user":{
          "username":"Fyffes TST staging",
          "email":"FyffesTST@pc.t2f.io",
          "org":{
            "name":"Fyffes",
            "fullname":"Fyffes",
            "logo":null
          }
        }
      }
    },
    {
      $class: 'org.powerchain.bananas.batch.ResellerOutBatch',
      banafoodSSCCPallet: '340518320001382990',
      antwerpSSCC: '5286174487',
      deliveryDate: '2018-08-16T07:39:50.757Z',
      product: 'sticky',
      productDescription: 'Delaware GB networks withdrawal Optional',
      batchId: 'ResellerOutBatch_5e6f2eac-a2de-43bc-984c-ba7dba188a82',
      createdAt: '2019-08-10T10:24:42.019Z',
      createdBy:
        'resource:org.powerchain.bananas.member.ResellerUser#ResellerUser_c3a553eb-9222-4011-bbd1-e337b7ba3562',
      status: 'APPROVED',
      uploadSource: 'api',
      "modifiedBy_participant":{
        "username":null,
        "email":null,
        "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        },
        "channel":{
          "title":"Bananas",
          "name":"bananas"
        },
        "user":{
          "username":"Fyffes TST staging",
          "email":"FyffesTST@pc.t2f.io",
          "org":{
            "name":"Fyffes",
            "fullname":"Fyffes",
            "logo":null
          }
        }
      },
      "createdBy_participant":{
        "username":null,
        "email":null,
        "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        },
        "channel":{
          "title":"Bananas",
          "name":"bananas"
        },
        "user":{
          "username":"Fyffes TST staging",
          "email":"FyffesTST@pc.t2f.io",
          "org":{
            "name":"Fyffes",
            "fullname":"Fyffes",
            "logo":null
          }
        }
      }
    },
  ],
});
