export default (theme) => ({
  modal: {
    position: 'fixed',
    display: 'none',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background: 'rgba(0, 0, 0, 0.7)',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 42,
    '&.isOpen': {
      display: 'flex',
    },
  },
  modalWindow: {
    position: 'relative',
    borderRadius: 20,
    '& .title': {
      background: theme.colors.error,
      padding: ['1em', '2em'],
      color: theme.colors.white,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: [20, 20, 0, 0],
      minWidth: 500,
      minHeight: 60,
      font: {
        size: theme.fontSizes.md,
        weight: 700,
      },
      userSelect: 'none',
    },
    '& .body': {
      background: theme.colors.background,
      padding: ['2em'],
      borderRadius: [0, 0, 20, 20],
      display: 'flex',
      textAlign: 'center',
      alignItems: 'center',
      justifyContent: 'center',
      minWidth: 500,
      minHeight: 150,
      flexWrap: 'wrap',
      '& .buttons': {
        flex: '0 0 100%',
        marginTop: '2em',
        '& .buttons, & .btn': {
          ...theme.components.btn,
          backgroundColor: theme.colors.error,
          '&:hover': {
            backgroundColor: theme.colors.error,
          },
        },
      },
    },
    '& .certificate': {
      backgroundColor: 'transparent',
      padding: 0,
      margin: 0,
      borderRadius: 0,
      display: 'flex',
      textAlign: 'center',
      alignItems: 'center',
      justifyContent: 'center',
      minWidth: 500,
      minHeight: 100,
    },
  },
  closeModal: {
    position: 'absolute',
    top: -10,
    right: -10,
    cursor: 'pointer',
    borderRadius: '100%',
    zIndex: 1,
    '& svg': {
      fill: theme.colors.white,
    },
  },
});
