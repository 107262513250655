export default () => [
  {
    $class: 'org.powerchain.tilapia.batch.ProcessorBatchGroupedConcept',
    articleNumberConsumerProduct: '871845224102',
    thtBulkMin: '2018-11-11T00:00:00.000Z',
    thtBulkMax: '2018-11-11T00:00:00.000Z',
    processingDateMin: '2018-11-01T00:00:00.000Z',
    processingDateMax: '2018-11-04T00:00:00.000Z',
    deliveryDateMin: '2019-01-02T00:00:00.000Z',
    deliveryDateMax: '2019-03-13T00:00:00.000Z',
    createdAt: '2019-08-15T08:46:11.148Z',
    createdBy:
      'resource:org.powerchain.tilapia.member.ProcessorUser#Mayonna_58945e88-4654-42dd-9071-0025fbd6e840',
    status: 'PENDING',"createdBy_participant":{
      "username":null,
      "email":null,
      "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
      "org":{
        "name":"Fyffes",
        "fullname":"Fyffes",
        "logo":null
      },
      "channel":{
        "title":"Bananas",
        "name":"bananas"
      },
      "user":{
        "username":"Fyffes TST staging",
        "email":"FyffesTST@pc.t2f.io",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        }
      }
    }
  },
];
