export default () => [
  {
    $class: 'org.powerchain.bananas.batch.GroupedResellerBatchesConcept',
    banafoodSSCCPallet: '340005910925877000',
    antwerpSSCCCount: 1,
    minDeliveryDate: '2019-06-19T00:00:00.000Z',
    maxDeliveryDate: '2019-06-19T00:00:00.000Z',
    minReceiptDate: '2019-07-11T00:00:00.000Z',
    maxReceiptDate: '2019-07-11T00:00:00.000Z',
    createdAt: '2019-08-23T15:09:26.167Z',
    status: 'PENDING',
    createdBy:
      'resource:org.powerchain.bananas.member.ResellerUser#Banafood_b9774b34-4166-40cd-b402-d901ef3b6aa5',
    "modifiedBy_participant":{
      "username":null,
      "email":null,
      "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
      "org":{
        "name":"Fyffes",
        "fullname":"Fyffes",
        "logo":null
      },
      "channel":{
        "title":"Bananas",
        "name":"bananas"
      },
      "user":{
        "username":"Fyffes TST staging",
        "email":"FyffesTST@pc.t2f.io",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        }
      }
    },
    "createdBy_participant":{
      "username":null,
      "email":null,
      "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
      "org":{
        "name":"Fyffes",
        "fullname":"Fyffes",
        "logo":null
      },
      "channel":{
        "title":"Bananas",
        "name":"bananas"
      },
      "user":{
        "username":"Fyffes TST staging",
        "email":"FyffesTST@pc.t2f.io",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        }
      }
    },
    "modifiedBy_participant":{
      "username":null,
      "email":null,
      "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
      "org":{
        "name":"Fyffes",
        "fullname":"Fyffes",
        "logo":null
      },
      "channel":{
        "title":"Bananas",
        "name":"bananas"
      },
      "user":{
        "username":"Fyffes TST staging",
        "email":"FyffesTST@pc.t2f.io",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        }
      }
    },
  },
];
