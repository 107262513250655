import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
// incons
import { CloseSvg } from 'components/icons';
// styles
import errorModalStyles from 'components/Modals/errorModalStyles';
import injectSheet from 'react-jss';
// selectors
import { getInternalServerError } from 'domain/common';
import * as commonActions from 'domain/common/actions';

const ErrorModal = (props) => {
  const { classes, internalServerError, clearInternalServerError } = props;

  const modalRef = useRef(null);
  const closeModal = (e) => {
    if (e && modalRef && modalRef.current && modalRef.current.contains(e.target)) {
      return;
    }
    clearInternalServerError();
    document.removeEventListener('click', closeModal, false);
  };

  useEffect(() => {
    if (internalServerError) {
      document.addEventListener('click', closeModal, false);
    }
  }, [internalServerError]);

  if (!internalServerError) {
    return <></>;
  }
  return (
    <div className={classes.background}>
      <div className={classes.modal} ref={modalRef}>
        <div className={classes.header} />
        <div className={classes.content}>
          <h3>The server is busy</h3>
          <span>Please try again in a few minutes.</span>
        </div>
        <div className={classes.controls}>
          <button type="button" className={classes.close} onClick={() => closeModal()}>
            <CloseSvg />
            <span>CLOSE</span>
          </button>
        </div>
      </div>
    </div>
  );
};

ErrorModal.propTypes = {
  classes: PropTypes.object.isRequired,
  internalServerError: PropTypes.bool.isRequired,
  clearInternalServerError: PropTypes.func.isRequired,
};

export default connect(
  (state) => ({
    internalServerError: getInternalServerError(state),
  }),
  {
    clearInternalServerError: () => ({
      type: commonActions.setInternalServerErrorAction.success,
      payload: false,
    }),
  },
)(injectSheet(errorModalStyles)(ErrorModal));
