import React from 'react';
// utils
import { Link } from 'react-router-dom';
import { BATCH_STATUS, ORGANIZATIONS } from 'themes/constants';
import { renderTwoShortDateFormat, renderShortDateFormat } from 'lib/renderHelpers';
import { getUrl } from 'routes';
import { validationRequired } from 'lib/validations';
import { BatchRecord, ModelRequiredInfo } from 'lib/lib';
import { getCreatorNameAndOrgName } from '../../utils/utils';

export default (modelRequiredInfo: ModelRequiredInfo) => [
  {
    key: 'batchNumber',
    label: 'Partij nummer',
    title: 'Partij nummer',
    style: { flex: '1 0 80px', wordBreak: 'break-word' },
    renderExportValue: (column: BatchRecord) => column.batchNumber,
    defaultSelectedColumn: true,
    defaultSelectedBatchContractColumn: true,
    render: (column: BatchRecord) =>
      modelRequiredInfo.params && modelRequiredInfo.params.status === BATCH_STATUS.ARCHIVED ? (
        <Link
          to={{
            pathname: getUrl('bananasBatchDetails', {
              type: ORGANIZATIONS.HOLLANDER,
              batchCode: encodeURIComponent(encodeURIComponent(column.batchNumber)),
              status:
                modelRequiredInfo.params &&
                modelRequiredInfo.params.status === BATCH_STATUS.ARCHIVED
                  ? modelRequiredInfo.params.status
                  : '',
              modelType: 'details',
            }),
          }}
          title={column.batchNumber}
          className="contract"
        >
          {column.batchNumber}
        </Link>
      ) : (
        <Link
          to={{
            pathname: getUrl('bananasBatch', {
              type: ORGANIZATIONS.HOLLANDER,
              batchCode: encodeURIComponent(encodeURIComponent(column.batchNumber)),
              modelType: 'details',
            }),
          }}
          title={column.batchNumber}
          className="contract"
        >
          {column.batchNumber}
        </Link>
      ),
  },
  {
    key: 'inslagMutationDate',
    label: 'Mutatie datum (Inslag)',
    title: 'Mutatie datum (Inslag)',
    style: { flex: '1 0 100px' },
    defaultSelectedBatchContractColumn: true,
    renderExportValue: (column: BatchRecord) => {
      if (!column.inslagMinMutationDate || !column.inslagMaxMutationDate) {
        return '';
      }

      return renderTwoShortDateFormat(column.inslagMinMutationDate, column.inslagMaxMutationDate);
    },
    render: (column: BatchRecord) => {
      if (!column.inslagMinMutationDate || !column.inslagMaxMutationDate) {
        return null;
      }

      return renderTwoShortDateFormat(column.inslagMinMutationDate, column.inslagMaxMutationDate);
    },
    defaultSelectedColumn: true,
    edit: { type: 'date' },
  },
  {
    key: 'uitslagMutationDate',
    label: 'Mutatie datum (Uitslag)',
    title: 'Mutatie datum (Uitslag)',
    style: { flex: '1 0 100px' },
    defaultSelectedBatchContractColumn: false,
    renderExportValue: (column: BatchRecord) => {
      if (!column.uitslagMinMutationDate || !column.uitslagMaxMutationDate) {
        return '';
      }

      return renderTwoShortDateFormat(column.uitslagMinMutationDate, column.uitslagMaxMutationDate);
    },
    render: (column: BatchRecord) => {
      if (!column.uitslagMinMutationDate || !column.uitslagMaxMutationDate) {
        return null;
      }

      return renderTwoShortDateFormat(column.uitslagMinMutationDate, column.uitslagMaxMutationDate);
    },
    defaultSelectedColumn: true,
    edit: { type: 'date' },
  },
  {
    key: 'relationshipCodeNameCount',
    label: 'Relatie code naam (count)',
    title: 'Relatie code naam (count)',
    style: { flex: '1 0 120px' },
    renderExportValue: (column: BatchRecord) => column.relationshipCodeNameCount,
    render: (column: BatchRecord) => column.relationshipCodeNameCount,
    defaultSelectedBatchContractColumn: true,
    defaultSelectedColumn: true,
  },
  {
    key: 'SSCCCodeCount',
    label: 'SSCC code MLS (count)',
    title: 'SSCC code MLS (count)',
    style: { flex: '1 0 120px' },
    defaultSelectedBatchContractColumn: true,
    renderExportValue: (column: BatchRecord) => column.SSCCCodeCount,
    render: (column: BatchRecord) => column.SSCCCodeCount,
    defaultSelectedColumn: true,
  },
  {
    key: 'createdAtMin',
    label: 'Created At',
    title: 'Created At',
    style: { flex: '1 0 80px' },
    isSortable: true,
    defaultSelectedColumn: true,
    renderExportValue: (column: BatchRecord) => column.createdAtMin,
    render: (column: BatchRecord) => renderShortDateFormat(column.createdAtMin),
  },
  {
    key: 'createdAtMax',
    label: 'Last Updated',
    title: 'Last Updated',
    style: { flex: '1 0 80px' },
    isSortable: true,
    defaultSelectedColumn: true,
    renderExportValue: (column: BatchRecord) => column.createdAtMax,
    render: (column: BatchRecord) => renderShortDateFormat(column.createdAtMax),
  },
  {
    key: 'createdBy',
    label: 'Created By',
    title: 'Created By',
    style: { flex: '1 0 120px' },
    renderExportValue: (record: BatchRecord) => getCreatorNameAndOrgName(record),
    render: (record: BatchRecord) => getCreatorNameAndOrgName(record),
    validate: [validationRequired],
    defaultSelectedColumn: true,
    defaultSelectedBatchContractColumn: true,
  },
];
