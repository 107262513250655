import { CHANNELS } from 'themes/constants';

const shortValuesList = [
  {
    title: 'day',
    value: 1,
  },
  {
    title: 'week',
    value: 7,
  },
  {
    title: 'month',
    value: 30,
  },
  {
    title: 'all',
    value: 9999,
  },
];

const longValuesList = [
  {
    title: '1 day',
    value: 1,
  },
  {
    title: '2 days',
    value: 2,
  },
  {
    title: '3 days',
    value: 3,
  },
  {
    title: '4 days',
    value: 4,
  },
  {
    title: '5 days',
    value: 5,
  },
  {
    title: '6 days',
    value: 6,
  },
  {
    title: '2 weeks',
    value: 14,
  },
  {
    title: '3 weeks',
    value: 21,
  },
  {
    title: '1 month',
    value: 30,
  },
  {
    title: '2 months',
    value: 60,
  },
  {
    title: '3 months',
    value: 90,
  },
  {
    title: '6 months',
    value: 180,
  },
  {
    title: '1 year',
    value: 365,
  },
  {
    title: '2 years',
    value: 730,
  },
  {
    title: 'All',
    value: 9999,
  },
];

const getListWithDefaultValue = (
  list: Array<{ value: number; title: string; default?: boolean; defaultSelected?: boolean }>,
  defaultValue = 30,
) => {
  const defaultItem = list.find((f) => f.value === defaultValue);
  if (defaultItem) {
    defaultItem.defaultSelected = true;
    defaultItem.default = true;
  }
  return list;
};

const getTimePeriods = (channelName: string) => {
  const { BANANAS, TILAPIA } = CHANNELS;

  switch (channelName) {
    case BANANAS:
      return getListWithDefaultValue(shortValuesList, 7);
    case TILAPIA:
      return getListWithDefaultValue(shortValuesList, 30);
    default:
      return getListWithDefaultValue(longValuesList, 30);
  }
};

const certificatesValuesList = getListWithDefaultValue(longValuesList, 30);

export { shortValuesList, longValuesList, getTimePeriods, certificatesValuesList };
