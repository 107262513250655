import { renderShortDateFormat } from 'lib/renderHelpers';
import { BatchRecord } from 'lib/lib';
import { getModifierName } from 'models/utils/utils';

export default (isDefaultColumn = true) => [
  {
    key: 'modifiedAt',
    label: 'Archived At',
    title: 'Archived At',
    style: { flex: '1 0 50px' },
    defaultSelectedColumn: isDefaultColumn,
    defaultSelectedBatchContractColumn: isDefaultColumn,
    render: (record: BatchRecord) => renderShortDateFormat(record.modifiedAt),
    renderExportValue: (record: BatchRecord) => renderShortDateFormat(record.modifiedAt),
  },
  {
    key: 'modifiedBy',
    label: 'Archived By',
    title: 'Archived By',
    style: { flex: '1 0 70px' },
    defaultSelectedColumn: isDefaultColumn,
    defaultSelectedBatchContractColumn: isDefaultColumn,
    render: (record: BatchRecord) => getModifierName(record),
    renderExportValue: (record: BatchRecord) => getModifierName(record),
  },
];
