import Route from 'route-parser';
import qs from 'query-string';
import { getUnmatchedResponse } from 'mocks/router/utils';
import unmatchedStatisticsResponse from 'mocks/responses/unmatched/bananas/statistics';

export default (request) => {
  const route = new Route('/api/v1/bananas/unmatched(/total?*total)(?*filter)');
  const parameters = route.match(request.url);
  if (parameters) {
    if (parameters.total) {
      return [200, unmatchedStatisticsResponse];
    }
    if (parameters.filter) {
      return getUnmatchedResponse(qs.parseUrl(request.url).query);
    }
  }

  return [500, `url not proceeded: ${request.url}`];
};
