import moment from 'moment';
import React from 'react';

import amforiCertIcon from 'assets/certIcons/amfori.png';
import rfaCertIcon from 'assets/certIcons/rfa.png';
import sedexCertIcon from 'assets/certIcons/sedex.png';
import brcFoodCertIcon from 'assets/certIcons/brcFood.png';
import foodSafetyCertIcon from 'assets/certIcons/foodSafety.png';
import ifsCertIcon from 'assets/certIcons/ifs.png';
import sqfCertIcon from 'assets/certIcons/sqf.png';
import fundacao from 'assets/certIcons/fundacao_abrinq.png';
import defaultCertIcon from 'assets/certIcons/default.svg';
import ikbCertIcon from 'assets/certIcons/ikb.png';
import katCertIcon from 'assets/certIcons/kat.png';
import { Certificate } from 'lib/lib';

export const getFormattedCertTitle = (certDate: string) => {
  if (!certDate) return <span>N/A</span>;

  const expired = moment().diff(moment(certDate)) >= 0;
  const formattedDate = moment(certDate).format("D MMM 'YY");

  return <span style={{ color: expired ? 'red' : 'green' }}>{formattedDate}</span>;
};

export const extractUploaderName = (certificateRecord: Certificate) => {
  if (
    certificateRecord &&
    certificateRecord.uploadedBy_participant &&
    certificateRecord.uploadedBy_participant.user
  ) {
    return certificateRecord.uploadedBy_participant.user.username;
  }
};

export const getIconByOrgName = (documentType: string) => {
  switch (documentType) {
    case 'Amfori Business Social Compliance Initiative':
      return amforiCertIcon;
    case 'Rainforest Alliance (RFA)':
      return rfaCertIcon;
    case 'Sedex Members Ethical Trade Audit (ETI SMETA)':
      return sedexCertIcon;
    case 'BRC Food':
      return brcFoodCertIcon;
    case 'Food Safety System Certification 22000':
      return foodSafetyCertIcon;
    case 'IFS Food':
      return ifsCertIcon;
    case 'Safe Quality Food (SQF)':
      return sqfCertIcon;
    case 'Fundacao Abrinq':
      return fundacao;
    case 'BLK Level':
      return defaultCertIcon;
    case 'IKB':
      return ikbCertIcon;
    case 'KAT':
      return katCertIcon;

    default:
      return defaultCertIcon;
  }
};
