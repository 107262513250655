import React from 'react';
import PropTypes from 'prop-types';
// jss
import injectSheet from 'react-jss';
import genericModalStyles from 'components/Modals/genericModal/genericModalStyles';

class GenericModal extends React.Component {
  state = {
    isOpen: true,
  };

  hidePopup = () => {
    const { hideModalCallBack } = this.props;

    this.setState(
      {
        isOpen: false,
      },
      hideModalCallBack,
    );
  };

  render() {
    const { classes, title, content, controls } = this.props;
    const { isOpen } = this.state;

    return (
      <div
        className={`${classes.genericModalComponent} ${isOpen ? 'showComponent' : ''}`}
        onClick={this.hidePopup}
        cy-data="modal"
      >
        <div
          className="container"
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
          }}
        >
          <div className="modalTitleWrapper">
            <h5 className="modalTitle">{title}</h5>
          </div>
          <div className="modalContent">{content}</div>
          <div className="modalControl">{controls}</div>
        </div>
      </div>
    );
  }
}

GenericModal.propTypes = {
  hideModalCallBack: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  title: PropTypes.any,
  content: PropTypes.any,
  controls: PropTypes.any,
};

export default injectSheet(genericModalStyles)(GenericModal);
