import React, { useLayoutEffect, useState } from 'react';
import { TabsContainer } from './TabDropDown';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { getParams, getQueryParams } from 'domain/common';
import * as envActions from 'domain/env/actions';
import { AppStateType } from 'types';
import { IndexedObject } from 'lib/lib';
import { BATCH_STATUS } from 'themes/constants';

interface TabTitleButton {
  setActiveTab: (tabID: BATCH_STATUS) => void;
  id: BATCH_STATUS;
  title: string;
}

export interface TabI extends TabTitleButton {
  isActive: boolean;
  defaultSelected?: boolean;
}

interface SimpleTabGroupTabsI {
  title: string;
  id: BATCH_STATUS;
  defaultSelected?: boolean;
}

export interface SimpleTabGroupI<T> {
  tabs: Array<T>;
  activeTabId?: BATCH_STATUS;
}

// type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

interface ComponentsProps {
  classes: IndexedObject<string>;
}

interface GetTabsModelI {
  params: IndexedObject<string>;
  /* matchedRoute: MatchedRoute;
  titleParamsAsString: string; */
}

const mapStateToProps = (state: AppStateType) => ({
  params: getParams(state),
  queryParams: getQueryParams(state),
  // matchedRoute: getMatchedRoute(state),
  // titleParamsAsString: getTitleParamsAsString(state),
});

export const TabTitleButton: React.FC<TabTitleButton> = (props) => {
  const { id, setActiveTab, title } = props;

  return (
    <button type="button" className="title" onClick={() => setActiveTab(id)}>
      <span data-tab={id}>{title}</span>
    </button>
  );
};

const SimpleTab: React.FC<TabI> = (props) => {
  const { isActive, title, setActiveTab, id } = props;

  return (
    <div className={`tab ${isActive ? 'active' : ''}`}>
      <TabTitleButton id={id} setActiveTab={setActiveTab} title={title} />
    </div>
  );
};

export const TabsGroup: React.FC<SimpleTabGroupI<SimpleTabGroupTabsI>> = React.memo((props) => {
  const { activeTabId, tabs } = props;
  const dispatch = useDispatch();
  const { params } = useSelector(mapStateToProps, shallowEqual);

  const getDefaultSelectedTabDropdownId = () => {
    const { tabs } = props;

    const defaultSelected = tabs.find(({ defaultSelected }) => defaultSelected);
    if (!defaultSelected) {
      throw new Error(
        `There should be default selected tab for first render / no props, received  ${JSON.stringify(
          tabs,
        )}`,
      );
    } else {
      return defaultSelected.id;
    }
  };

  const defineActiveTab = () => activeTabId || getDefaultSelectedTabDropdownId();
  const [activeTab, setActiveTab] = useState(defineActiveTab());

  useLayoutEffect(() => {
    dispatch({
      type: envActions.setTabsQueryParamsAction.success,
      payload: { status: activeTab },
    });
  }, [activeTab, params.type, params.contract_number]);

  return (
    <TabsContainer>
      {tabs.map((tab) => (
        <SimpleTab
          setActiveTab={setActiveTab}
          id={tab.id}
          title={tab.title}
          isActive={activeTab === tab.id}
          key={tab.title}
        />
      ))}
    </TabsContainer>
  );
});

const getTabsModel = ({ params /*, matchedRoute, titleParamsAsString */ }: GetTabsModelI) => {
  return [
    {
      title: `Contract № ${params.contract_number} (
                  ${params.type && params.type.toUpperCase()})`,
      id: BATCH_STATUS.ALL,
      defaultSelected: true,
    },
    {
      title: 'Archived Batches',
      id: BATCH_STATUS.ARCHIVED,
      defaultSelected: false,
    },
  ];

  // switch (matchedRoute.modelType) {
  //   case MODEL_TYPES.CONTRACT_DETAILS:
  //     return [
  //       {
  //         title: `Contract № ${decodeURIComponent(params.contract_number)} (
  //                 ${params.type && params.type.toUpperCase()})`,
  //         id: BATCH_STATUS.ALL,
  //         defaultSelected: true,
  //       },
  //       {
  //         title: 'Archived Batches',
  //         id: BATCH_STATUS.ARCHIVED,
  //         defaultSelected: false,
  //       },
  //     ];
  //   case MODEL_TYPES.HANDSHAKE:
  //     return [
  //       {
  //         title: titleParamsAsString,
  //         id: BATCH_STATUS.ALL,
  //         defaultSelected: true,
  //       },
  //     ];
  //   default:
  //     return [
  //       {
  //         title: 'Batches',
  //         id: BATCH_STATUS.ALL,
  //         defaultSelected: true,
  //       },
  //     ];
  // }
};

export const withSimpleTabs = <T extends ComponentsProps>(
  Component: React.ComponentType<T>,
): React.ComponentType<Omit<T, keyof ComponentsProps>> => (props) => {
  const reduxProps = useSelector(mapStateToProps, shallowEqual);
  const { classes } = props as T;
  const tabs = getTabsModel(reduxProps);

  return (
    <section className={classes.component}>
      <div className={classes.tableHeader}>
        <TabsGroup tabs={tabs} />
      </div>
      {reduxProps.queryParams.status && <Component {...(props as T)} />}
    </section>
  );
};
