export const makeTabsConfig = (resource) => {
  // specify where to mutate config
  // const defaultModels = ['batchContract', 'batchHandshake', 'batchFilterList'];
  // viewName format: batchContract::85::tilapia::mayonna
  const viewNameKey = resource.viewName.split('::')[0];
  if (Array.isArray(resource.config)) {
  // if (defaultModels.includes(viewNameKey)) {
    return {
      ...resource,
      config: { defaultTab: resource.config },
    };
  }
  return resource;
};

export const makeMultiTabConfig = (viewConfigs) => {
  const modifiedViewConfigs = [];
  for (const currentConfig of viewConfigs) {
    modifiedViewConfigs.push(makeTabsConfig(currentConfig));
  }
  return modifiedViewConfigs;
};

export function mergeWithSerializedModel(model, serialized) {
  const modelCopy = { ...model };

  for (const objectKey in serialized) {
    if (objectKey in modelCopy) {
      /* apply only fields from serializer */
      modelCopy[objectKey] = modelCopy[objectKey].filter(
        ({ key, isServiceField }) => serialized[objectKey].includes(key) || isServiceField,
      );
    }
  }

  return modelCopy;
}

export function setDefaultColumns(model, defaultColumnKey) {
  if (model.tabs) {
    const tabKeys = model.tabs.map(({ modelName }) => modelName);
    return tabKeys.reduce(
      (currentValue, tabKey) => ({
        ...currentValue,
        [tabKey]: model[tabKey].filter((f) => f[defaultColumnKey] || f.isServiceField),
      }),
      { tabs: model.tabs },
    );
  }
  return model.filter((f) => f[defaultColumnKey] || f.isServiceField);
}

export function setDisplayedColumns(model, { config }) {
  if (model.tabs) {
    const tabKeys = model.tabs.map(({ modelName }) => modelName);
    return tabKeys.reduce(
      (currentValue, tabKey) => ({
        ...currentValue,
        [tabKey]: model[tabKey].filter((f) => f.isServiceField || config[tabKey].includes(f.key)),
      }),
      { tabs: model.tabs },
    );
  }
  return model.filter((f) => f.isServiceField || config.includes(f.key));
}

export function serializeModel(model) {
  const modelCopy = { ...model };
  delete modelCopy.tabs;

  for (const key in modelCopy) {
    if (Array.isArray(modelCopy[key])) {
      modelCopy[key] = modelCopy[key].map(({ key }) => key);
    }
  }

  return modelCopy;
}
