import React, { useMemo } from 'react';
import { Provider, useSelector } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { ThemeProvider } from 'theming';

// Toast, ToastContainer
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

import { getTheme } from 'themes/main';
import { AppStateType } from 'types';
import configureStore, { history } from 'domain/configureStore';

import { getChannel, getChannelName, getUser } from 'domain/env';

import Modal from 'components/Modals';

export const store = configureStore();

const mapStateToProps = (state: AppStateType) => ({
  user: getUser(state),
  channelName: getChannelName(state),
});

interface OwnProps {
  children: React.ReactNode;
}

const ConnectedThemeProvider: React.FC<OwnProps> = ({ children }) => {
  const { user, channelName } = useSelector(mapStateToProps);
  const theme = useMemo(() => {
    // debugger;
    return getTheme(user.org && user.org.name, channelName)
  }, [
    user,
    channelName,
  ]);

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

const AppProviders: React.FC<Partial<OwnProps>> = ({ children }) => {
  return (
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <ConnectedThemeProvider>
          <ToastContainer />
          <Modal />

          {children}
        </ConnectedThemeProvider>
      </ConnectedRouter>
    </Provider>
  );
};

export default AppProviders;
