export default () => [
  {
    $class: 'org.powerchain.bananas.batch.GroupedDistributorBatchesConcept',
    batchNumber: '1004',
    packagesCount: 48,
    orderNumberCount: 1,
    SSCCCodeCount: 1,
    inslagMinMutationDate: '2019-06-11T00:00:00.000Z',
    inslagMaxMutationDate: '2019-06-11T00:00:00.000Z',
    uitslagMinMutationDate: '2019-06-12T00:00:00.000Z',
    uitslagMaxMutationDate: '2019-05-17T00:00:00.000Z',
    status: 'PENDING',
    createdAtMin: '2019-07-19T14:18:17.958Z',
    createdAtMax: '2019-07-19T14:18:17.958Z',
    createdBy:
      'resource:org.powerchain.bananas.member.DistributorUser#Hollander_68c4f152-4d4a-4c7d-b7fd-b98292ec8a04',
    "modifiedBy_participant":{
      "username":null,
      "email":null,
      "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
      "org":{
        "name":"Fyffes",
        "fullname":"Fyffes",
        "logo":null
      },
      "channel":{
        "title":"Bananas",
        "name":"bananas"
      },
      "user":{
        "username":"Fyffes TST staging",
        "email":"FyffesTST@pc.t2f.io",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        }
      }
    },
    "createdBy_participant":{
      "username":null,
      "email":null,
      "participant":"org.powerchain.bananas.member.ProducerUser#Fyffes_892150f4-aa19-475c-b55d-b9c5898d98b8",
      "org":{
        "name":"Fyffes",
        "fullname":"Fyffes",
        "logo":null
      },
      "channel":{
        "title":"Bananas",
        "name":"bananas"
      },
      "user":{
        "username":"Fyffes TST staging",
        "email":"FyffesTST@pc.t2f.io",
        "org":{
          "name":"Fyffes",
          "fullname":"Fyffes",
          "logo":null
        }
      }
    }
  },
];
