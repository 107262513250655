import React from 'react';
import { Field, FormSubmitHandler, InjectedFormProps, reduxForm } from 'redux-form';
import { compose } from 'redux';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
// components
import Input from 'components/Form/Input/index';
import Loader from 'components/Loaders/smallLoader';
import Select from 'components/Form/Select';
// redux
import { getIsLoading } from 'domain/admin';
import * as adminActions from 'domain/admin/actions';
import { getUser } from 'domain/env';
import { getOrgs } from 'domain/common';
// css
import injectSheet, { WithStyles } from 'react-jss';
import adminInvitationCreateStyles from './styles/adminInvitationCreateStyles';
// utils
import AC from 'domain/AC';
import { useFormValidation } from 'pages/documents/certificateAddComponetns/utils';
// types
import { AppStateType } from 'types';

interface SelectValue {
  id: number;
  fullname: string;
}

interface FormValues {
  invited_to_org_id: SelectValue;
  email: string;
}

const mapStateToProps = (state: AppStateType) => ({
  isLoading: getIsLoading(state),
  user: getUser(state),
  orgs: getOrgs(state),
});

const AdminInvitationCreate: React.FC<
  WithStyles<typeof adminInvitationCreateStyles> & InjectedFormProps<FormValues>
> = (props) => {
  const dispatch = useDispatch();
  const { classes, handleSubmit } = props;
  const { isLoading, user, orgs } = useSelector(mapStateToProps, shallowEqual);
  const { validateRequired, validateEmail } = useFormValidation();

  const submit: FormSubmitHandler<FormValues> = (values) => {
    dispatch(
      adminActions.createInvitationAction({
        invited_to_org_id: values.invited_to_org_id,
        email: values.email,
      }),
    );
  };

  return (
    <div className={classes.component}>
      <form onSubmit={handleSubmit(submit)}>
        <div className="formGroup">
          <h2>Invitation</h2>
          <p>Type the e-mail of user you want to send invitation link to.</p>
        </div>

        <div className="formGroup">
          <Field
            name="invited_to_org_id"
            type="text"
            component={Select}
            props={{
              options: orgs,
              keys: { value: 'id', title: 'fullname' },
              defaultValue: { id: user.org.id, fullname: user.org.fullname },
              title: 'Invite to organization:',
              disabled: !AC.USER.CAN.INVITE_TO_SPECIFIED_ORG,
              className: classes.select,
            }}
          />

          <Field
            name="email"
            title="Email"
            type="email"
            component={Input}
            validate={[validateRequired, validateEmail]}
            props={{
              placeholder: 'myemail@yahoo.eu',
              className: 'inputField',
            }}
          />
        </div>

        <div className="formGroup">
          <button className={classes.confirmButton} type="submit">
            {isLoading ? <Loader /> : 'Send E-mail'}
          </button>
        </div>
      </form>
    </div>
  );
};

export default compose<React.FC>(
  reduxForm({
    form: 'form-admin-invitation',
  }),
  injectSheet(adminInvitationCreateStyles),
)(AdminInvitationCreate);
