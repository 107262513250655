export default [
  {
    $class: 'org.powerchain.juice.batch.ProcessorBatchGroupedConcept',
    lotFinishedProduct: '1778602',
    qtyProducedPcsFinishedProductSum: 100000,
    qtyUsedLtrRawProductSum: 11770,
    productionFinishedFinishedProductMin: '2019-01-30T00:00:00.000Z',
    productionFinishedFinishedProductMax: '2019-01-30T00:00:00.000Z',
    dateCheckFcojMin: '2019-01-29T00:00:00.000Z',
    dateCheckFcojMax: '2019-01-29T00:00:00.000Z',
    ratioFcojAverage: 15.186,
    ratioFinishedProductAverage: 16.286,
    uniqLotFcojAmount: 1,
    createdAtMin: '2020-09-22T14:35:01.371Z',
    createdAtMax: '2020-09-22T14:35:01.371Z',
    createdBy:
      'resource:org.powerchain.juice.member.ProcessorUser#REFRESCO_de6a20ca-20cf-4b62-89ea-11c822c19b19',
    channelName: 'juice',
    createdBy_participant: {
      username: null,
      email: null,
      participant:
        'org.powerchain.juice.member.ProcessorUser#REFRESCO_de6a20ca-20cf-4b62-89ea-11c822c19b19',
      org: { name: 'REFRESCO', fullname: 'Refresco company', logo: null },
      channel: { title: 'Juice', name: 'juice' },
      user: {
        username: 'REFTST staging',
        email: 'REFTST@pc.t2f.io',
        org: { name: 'REFRESCO', fullname: 'Refresco company', logo: null },
      },
    },
  },
];
