import React from 'react';

import { Link } from 'react-router-dom';
import { getUrl } from 'routes';

import baseModelFields from 'models/jumbo/tabs/baseModelFields';
import { renderShortDateFormat } from 'lib/renderHelpers';
import { CHANNELS, ORGANIZATIONS } from 'themes/constants';
import { WarningSvg } from 'components/icons';
// components
import SubContractWarning from 'models/utils/SubContractWarning/SubContractWarning';
import { BatchRecord, ModelRequiredInfo } from 'lib/lib';

export default (modelRequiredInfo: ModelRequiredInfo) => [
  {
    key: 'articleNumberConsumerProduct',
    label: 'Article number consumer product',
    title: 'Article number consumer product',
    style: { flex: '0 0 120px' },
    defaultSelectedBatchContractColumn: true,
    render: (column: BatchRecord) => column.articleNumberConsumerProduct,
  },
  {
    key: 'dateReceipt',
    label: 'Date receipt',
    title: 'Date Receipt',
    style: { flex: '0 0 120px' },
    defaultSelectedBatchContractColumn: true,
    render: (column: BatchRecord) => (
      <SubContractWarning
        subContractAmount={column.subcontractAmount}
        linkText={renderShortDateFormat(column.dateReceipt)}
      >
        <Link
          to={{
            pathname: getUrl('BatchHandshake', {
              type: ORGANIZATIONS.MAYONNA,
              channelName: CHANNELS.TILAPIA,
            }),
            search: `deliveryDate=${encodeURIComponent(column.dateReceipt)}`,
          }}
        >
          {renderShortDateFormat(column.dateReceipt)}
        </Link>
      </SubContractWarning>
    ),
  },
  {
    key: 'supplier',
    label: 'Supplier',
    title: 'Supplier',
    defaultSelectedBatchContractColumn: true,
    style: { flex: '0 0 80px' },
    render: (column: BatchRecord) => <span>{column.supplier}</span>,
  },
  {
    key: 'poNumber',
    label: 'PO number',
    title: 'PO Number',
    style: { flex: '0 0 130px' },
    render: (column: BatchRecord) => column.poNumber,
  },
  {
    key: 'palletNumber',
    label: 'Pallet Number',
    title: 'Pallet Number',
    style: { flex: '1 0 170px' },
    defaultSelectedBatchContractColumn: true,
    renderExportValue: (column: BatchRecord) => column.palletNumber,
    render: (column: BatchRecord) => (
      <>
        {column.subcontractImporterAmount === 0 ? (
          <>
            {column.palletNumber}
            <span className="warning">
              <WarningSvg />
            </span>
          </>
        ) : (
          <Link
            to={{
              pathname: getUrl('BatchHandshake', {
                type: ORGANIZATIONS.SEAFOODCONNECTION,
                channelName: CHANNELS.TILAPIA,
              }),
              search: `ssccPallet=${encodeURIComponent(column.palletNumber)}`,
            }}
          >
            {column.palletNumber}
          </Link>
        )}
      </>
    ),
  },
  {
    key: 'lotNumber',
    label: 'Lot Number',
    title: 'Lot Number',
    style: { flex: '1 0 100px' },
    defaultSelectedBatchContractColumn: true,
    renderExportValue: (column: BatchRecord) => column.lotNumber,
    render: (column: BatchRecord) => column.lotNumber,
  },
  {
    key: 'aantalVerpakkingen',
    label: 'Aantal verpakkingen',
    title: 'Aantal verpakkingen',
    style: { flex: '1 0 100px' },
    defaultSelectedBatchContractColumn: true,
    renderExportValue: (column: BatchRecord) => column.lotNumber,
    render: (column: BatchRecord) => column.lotNumber,
  },
  {
    key: 'thtConsumerProduct',
    label: 'THT consumer product',
    title: 'THT consumer product',
    style: { flex: '1 0 120px' },
    defaultSelectedBatchContractColumn: true,
    render: (column: BatchRecord) => renderShortDateFormat(column.thtConsumerProduct),
  },
  ...baseModelFields(),
];
