import { Theme } from 'lib/lib';

export default (theme: Theme) => ({
  supportQaA: {
    '& .faqBlock': {
      maxHeight: 40,
      overflow: 'hidden',
      transition: '.4s ease-out',
      marginTop: 15,
      '& .question': {
        fontWeight: 'bold',
        fontSize: theme.fontSizes.sm,
        backgroundColor: theme.colors.backgroundLight,
        colors: theme.colors.black,
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        padding: 10,
        cursor: 'pointer',
        transition: '.6s ease',
        width: '100%',
      },
      '& .answer': {
        padding: 10,
        visibility: 'hidden',
        opacity: 0,
        transition: '.5s ease',
        '& h2': {
          fontWeight: 700,
        },
        '& h3': {
          fontWeight: 700,
          padding: [0, 0, 0, 10],
          marginTop: '1em',
        },
        '& .city': {
          marginBottom: '1em',
        },
        '& p': {
          padding: [0, 0, 0, 10],
        },
        '& ol': {
          listStyle: 'decimal',
          '& li': {
            marginLeft: 40,
          },
        },
      },
      '&.active': {
        outline: 'none',
        maxHeight: 1000,
        overflow: 'auto',
        whiteSpace: 'normal',
        transition: '.6s ease-in',
        '& .question': {
          whiteSpace: 'normal',
        },
        '& .answer': {
          visibility: 'visible',
          opacity: 1,
        },
      },
    },
  },
  title: {
    marginTop: 50,
    marginBottom: 30,
  },
  contactsWrapper: {
    extend: 'title',
    textAlign: 'center',
    '& a': {
      color: theme.colors.black,
    },
  },
  component: {
    padding: ['2em', 0],
    '& .support-tabs': {
      display: 'flex',
      justifyContent: 'flex-start',
      '& .support-tab': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'start',
        marginRight: '1em',
        flexWrap: 'wrap',
        backgroundColor: 'rgba(171, 171, 171, 0.11)',
        padding: [20, 10, 10, 10],
        width: 100,
        height: 100,
        borderBottom: '2px solid transparent',
        cursor: 'pointer',
        '& > svg': {
          fill: 'rgb(143, 143, 143)',
          width: 32,
          height: 32,
        },
        '& > div': {
          flex: '0 0 100%',
          margin: 0,
          textAlign: 'center',
          color: 'rgb(143, 143, 143)',
        },
        '&.active': {
          borderBottomColor: theme.colors.primary,
          '& > svg': {
            fill: theme.colors.black,
          },
          '& > div': {
            color: theme.colors.black,
          },
        },
      },
    },
  },
  teasers: {
    display: 'flex',
    // justifyContent: 'space-between',
    justifyContent: 'space-around',
    '& .teaser-item': {
      flex: '0 0 25%',
      justifyContent: 'center',
      textAlign: 'center',
      '& .icon': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: '1em',
        '& svg': {
          width: 32,
          height: 32,
          fill: theme.colors.loginButton,
        },
      },
    },
  },
});
