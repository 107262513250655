import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
//
import { CloseModalSvg } from 'components/icons';
// getModal
import { getModal } from 'domain/ui';
import * as uiActions from 'domain/ui/actions';
// jss
import injectSheet from 'react-jss';
import modalStyles from 'components/Modals/modalStyles';

class Modal extends React.PureComponent {
  preventClose = (e) => e.stopPropagation();

  render() {
    const { classes, modal, closeModal } = this.props;
    const body = modal && modal.body && React.cloneElement(modal.body);
    return (
      <div className={`${classes.modal} ${modal ? 'isOpen' : ''}`} onClick={this.closeModal}>
        {Boolean(modal) && (
          <div className={classes.modalWindow} onClick={this.preventClose}>
            <div className={classes.closeModal} onClick={closeModal}>
              <CloseModalSvg width={20} />
            </div>
            {modal.hasOwnProperty('title') && <div className="title">{modal.title}</div>}
            {modal.hasOwnProperty('body') && <div className="body">{body}</div>}
            {modal.hasOwnProperty('certificate') && (
              <div className="certificate">{modal.certificate}</div>
            )}
          </div>
        )}
      </div>
    );
  }
}

Modal.propTypes = {
  classes: PropTypes.object.isRequired,
  closeModal: PropTypes.func,
  modal: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]).isRequired,
};

export default compose(
  connect(
    (state) => ({
      modal: getModal(state),
    }),
    {
      closeModal: () => ({ type: uiActions.uiModalAction.failure }),
    },
  ),
  injectSheet(modalStyles),
)(Modal);
