import React from 'react';
import PropTypes from 'prop-types';
//
import { connect } from 'react-redux';
import { compose } from 'redux';
// components
import FormChangePassword from 'pages/profile/forms/formChangePassword';
import NotificationConfig from 'pages/profile/notificationConfig';
// jss
import injectStyle from 'react-jss';
import profileStyle from 'pages/profile/profileStyle';
//
import { getUser } from 'domain/env';
import { getStatus } from 'domain/profile';
import * as profileActions from 'domain/profile/actions';

class Profile extends React.Component {
  state = {
    tabIndex: 0,
  };

  tabs = ['General Info', 'Change Password', 'Notification Config'];

  onClickSetTab = (index) => () => {
    this.setState({
      tabIndex: index,
    });
  };

  render() {
    const { classes, user, changePassword, clearStatus, status } = this.props;
    const { tabIndex } = this.state;

    return (
      <div className={classes.component}>
        <div className={classes.profile}>
          <div className="sidebar">
            <ul>
              {this.tabs.map((tab, index) => (
                <li key={tab}>
                  <button
                    type="button"
                    className={index === tabIndex ? 'active' : ''}
                    onClick={this.onClickSetTab(index)}
                  >
                    {tab}
                  </button>
                </li>
              ))}
            </ul>
          </div>
          <div className="main">
            <div className="main-inner">
              {tabIndex === 0 && (
                <>
                  <div className="title">
                    <h2>General Info</h2>
                  </div>
                  <div>
                    <ul className="general-info">
                      <li>
                        <div className="param-title">User:</div>
                        <div className="param-value">{user.username}</div>
                      </li>
                      <li>
                        <div className="param-title">Email:</div>
                        <div className="param-value">{user.email}</div>
                      </li>
                      <li>
                        <div className="param-title">Role:</div>
                        <div className="param-value">{user.role}</div>
                      </li>
                      <li>
                        <div className="param-title">Participant:</div>
                        <div className="param-value">{user.participant}</div>
                      </li>
                    </ul>
                  </div>
                </>
              )}

              {tabIndex === 1 && (
                <>
                  <div className="title">
                    <h2>Change password</h2>
                  </div>

                  <div>
                    <FormChangePassword
                      submitFunction={changePassword}
                      clearStatus={clearStatus}
                      status={status}
                    />
                  </div>
                </>
              )}

              {tabIndex === 2 && (
                <>
                  <div className="title">
                    <h2>Notification</h2>
                    <p>Enable and change notification email frequency.</p>
                  </div>

                  <div>
                    <NotificationConfig />
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Profile.propTypes = {
  classes: PropTypes.object,
  user: PropTypes.object,
  changePassword: PropTypes.func.isRequired,
  clearStatus: PropTypes.func.isRequired,
  status: PropTypes.string.isRequired,
};

export default compose(
  connect(
    (state) => ({
      user: getUser(state),
      status: getStatus(state),
    }),
    {
      clearStatus: () => ({ type: profileActions.clearStatusAction }),
      changePassword: (data) => ({
        type: profileActions.sendChangePasswordAction.type,
        payload: data,
      }),
    },
  ),
  injectStyle(profileStyle),
)(Profile);
