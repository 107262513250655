import React from 'react';
import { Link } from 'react-router-dom';
import { CHANNELS } from 'themes/constants';

const contactSIM = {
  question: 'SIM',
  answer: (
    <React.Fragment>
      <h3>
        In case of questions or remarks, please contact{' '}
        <a href="mailto:powerchain@simsupplychain.com">powerchain@simsupplychain.com</a>
      </h3>

      <h3 className="city">Alkmaar</h3>

      <h3>Dionne Conijn - Quality & Services manager</h3>
      <p>powerchain@simsupplychain.com</p>
      <p>+31 (0)72 5410 390</p>

      <h3>Šárka Edel - Data Coordinator</h3>
      <p>+31 (0)72 5410 390</p>
    </React.Fragment>
  ),
};

const contactsOranges = [
  {
    question: 'Refresco',
    answer: (
      <React.Fragment>
        <h3 className="city">Bodegraven</h3>
        <h3>Gerard Voogd QESH Manager Bodegraven</h3>
        <p>gerard.voogd@refresco.com</p>
        <p className="home">+31 172 630 669</p>
        <p className="home">+31 6 23907081</p>

        <h3>Angela Zuidam QC Teamleader</h3>
        <p>angela.zuidam@refresco.com</p>
        <p className="home">+31 172 630 560</p>
        <p className="home">+31 6 22439999</p>

        <h3 className="city">Benelux</h3>
        <h3>Irina Hemeryck QESH Manager Benelux</h3>
        <p>irina.hemeryck@refresco.com</p>
        <p className="home">+31 495 596 192</p>
        <p className="home">+31 6 10547995</p>
      </React.Fragment>
    ),
  },
  {
    question: 'LDC',
    answer: (
      <React.Fragment>
        <h3 className="city">Europe</h3>
        <h3>Rik van Delft - Logistical department Juice Division</h3>
        <p>rik.vandelft@ldc.com</p>
        <p className="home">+31 10 201 0520</p>
        <p className="home">+31 6 23169561</p>

        <h3>Bart Meirlaan - Quality Assurance Manager (Gent)</h3>
        <p>bart.meirlaan@ldc.com</p>
        <p className="home">+32 9 2509165</p>
        <p className="home">+32 499 599 980</p>

        <h3>Lieselotte Ghijselinck - Quality control & Customer Service</h3>
        <p>lieselotte.ghijselinck@ldc.com</p>
        <p className="home">+32 9 2509166</p>
        <p className="home">+32 470 981131</p>

        <h3>Adriaan Henau - Assistant Team Lead expedition</h3>
        <p>adriaan.henau@ldc.com</p>
        <p className="home">+32 9 2509145</p>

        <h3 className="city">Brazil</h3>
        <h3>Marcio Mendes - Quality Assurance</h3>
        <p>marcio.mendes@ldc.com</p>
        <p className="home">+55 17 3344 6034</p>
        <p className="home">+55 17 99735 8290</p>

        <h3>Juliana Pires - Juice Quality Manager</h3>
        <p>juliana.pires@ldc.com</p>
        <p className="home">+55 17 3344 6006</p>
        <p className="home">+55 17 99614 4318</p>
      </React.Fragment>
    ),
  },
  {
    question: 'AH',
    answer: (
      <React.Fragment>
        <h3 className="city">Zaandam</h3>

        <h3>Customer Service</h3>
        <p>customer.support@ahold.com</p>
        <p className="home">+31 (0)800-0305</p>

        <h3>Quality managers</h3>
        <p>AH.qualitymanagers.Group@ahold.com</p>

        <h3>Sourcing manager</h3>
        <p>ralph-van.oss@ah.nl </p>

        <h3>Quality manager</h3>
        <p className="home">+31 (0)6 132 664 88</p>
      </React.Fragment>
    ),
  },
  contactSIM,
];

export default (endpoint: string, channelName: CHANNELS) => [
  [
    {
      question: 'What do I do if the powerchain application is not working correctly?',
      answer:
        'Sent an email to powerchain@simsupplychain.com with your question or call SIM at +31(0)725410390',
    },
    {
      question: 'What to do if I cannot login?',
      answer:
        'Sent an email to powerchain@simsupplychain.com with your emailadress and request for new credentials',
    },
    {
      question: 'What to do If I forgot my login details?',
      answer:
        'Sent an email to powerchain@simsupplychain.com with your emailadress and request for new credentials',
    },
    {
      question: 'Who should I contact if I have questions?',
      answer: 'Please check the contacts page for further details',
    },
    {
      question: 'What information should I upload?',
      answer:
        'Each participant is responsible for uploading their own information, check the request overview to see if you have any requests outstanding.',
    },
    {
      question: 'How do I create a new Request?',
      answer: (
        <React.Fragment>
          <p>
            Click on the button Create new Request in the Request inbox and fill in the necessary
            data points.
          </p>
          <ol>
            <li>
              Request type - here you select whether you request a new certificate or new batch
              information
            </li>
            <li>
              Enter request - If you selected batch information you indicate here what batch
              information you are requestion L nr SG nr or Brazil batch nr
            </li>
            <li>Select nodes - Choose who the requets is for, who should upload the information</li>
            <li>
              Expiration date - What is the follow up time, when do you need to have the information
              complete.
            </li>
          </ol>
        </React.Fragment>
      ),
    },
    {
      question: 'Instructions on how to upload a certificate',
      answer: (
        <React.Fragment>
          <p>
            Click on the button Upload new Certificate in the Certificates overview and choose the
            correct document to upload:
          </p>
          <ol>
            <li>
              Pick the correct Category (for what risk area is your certificate/report applicable)
            </li>
            <li>Choose document type (what document have you uploaded)</li>
            <li>Fill in the number where applicable (cert nr, COID etc.)</li>
            <li>Fill in the Result (what was the outcome of the audit)</li>
            <li>
              Fill in the Scope of the audit if applicable (what product/industryt was the audit
              for)
            </li>
            <li>Fill in the Valid From date of the uploaded document</li>
            <li>Fill in the Valid until date of the uploaded document</li>
            <li>Click review & Submit to finalize the process</li>
          </ol>
        </React.Fragment>
      ),
    },
    {
      question: 'Instruction on how to upload a batch list',
      answer: (
        <React.Fragment>
          <p>
            Click on the button Upload new Batch in the Batch overview and choose the correct
            document to upload.
          </p>
          <ol>
            <li>Check if all the necessary fields are filled in</li>
            <li>
              Check if the batch corresponds to the correct batch nr, L nr SG nr or Brazil batch nr
            </li>
            <li>Click submit to finalize the upload</li>
          </ol>
          <p>
            <Link
              to={{ pathname: `${process.env.REACT_APP_API_URL}/api/v1/${endpoint}/template` }}
              target="_blank"
            >
              link to instruction
            </Link>
          </p>
        </React.Fragment>
      ),
    },
    {
      question: 'What happens to my data after I submit',
      answer:
        'All data uploaded is safely stored within the powerchain application. The data is made available' +
        'to SIM Supply chain for verification and validation purposes as agreed upon. All data will remain' +
        'within the application as it has become immutable. Only the data points previously agreed upon will be' +
        'shared to the consumer interface, see Consumer page for further information.',
    },
    {
      question: 'What is SIM verification (verified by SIM)?',
      answer:
        'SIM verification entails that SIM takes a look at all uploaded data, certificates, audit reports, ' +
        'batch information and verifies this data based on the agreed upon control points. If all information provided ' +
        'is in accordance with the agreements made and complete authentic and accurate SIM will give a Verification ' +
        'accepted by SIM status. ' +
        'The data with this status is ready to be shown in the consumer interface.',
    },
    {
      question: 'Why do I see a SIM verification rejected status?',
      answer:
        'If the data, certificates, audit reports, batch information is incorrect or incomplete based on the agreements ' +
        'made between SIM and participants SIM will mark the information as rejected, and an updated or new version ' +
        'of the document/data will have to uploaded for re-verification. ',
    },
  ],
  [
    {
      question: 'Food Safety System Certification (FSSC) 22000:2005',
      answer:
        'The FSSC 22000 is a food safety standard that is mainly used by production companies.\n' +
        'It identifies what a company needs to do to demonstrate that it controls food safety\n' +
        'hazards and thus ensures that food is safe at the moment it is consumed.',
    },
    {
      question: 'International Food Standard (IFS)',
      answer:
        'The International Food Standard is an international standard in the field of food safety\n' +
        'that is used mainly in Europe. In the various phases of the production process of the AH orange juice,\n' +
        'it is checked whether the producing and logistic companies in the chain meet the requirements of this standard.',
    },
    {
      question: 'Safe Quality Food (SQF)',
      answer:
        "Safe Quality Food (SQF) is an international standard ('standard') in the field of food\n" +
        'safety that is mainly used in America and Asia.. In the various phases of the production\n' +
        'process of the AH orange juice, it is checked whether the producing and logistic companies\n' +
        'in the chain meet the requirements of this standard.',
    },
    {
      question: 'SGF - Sure Global Fair',
      answer:
        'SGF certifies fruit-producing companies, packers, bottlers, traders, cold storage and\n' +
        'transport companies in the field of fruit juice quality. The German abbreviation stands for\n' +
        'Schutzgemeinschaft der Fruchsaft-Industrie. The SGF performs random checks within the entire\n' +
        'chain of fruit juices, including in the area of ​​product authenticity (see below).',
    },
    {
      question: 'Product authenticity',
      answer:
        'The authenticity of the product is checked regularly: is the product actually what is claimed?\n' +
        'Are there really oranges? The SGF checks this randomly in collaboration with laboratories such as\n' +
        'the GFL (Gesellschaft fur Lebensmittel-Forschung).',
    },
    {
      question: 'SMETA 4-PILLAR (Sedex Members Ethical Trade audit)',
      answer: (
        <React.Fragment>
          <p>
            SMETA is a method to test suppliers in a food chain whether they work in a responsible &
            ethical manner in 4 areas:
          </p>
          <ol>
            <li>labor standards</li>
            <li>health & safety</li>
            <li>environment</li>
            <li>business integrity</li>
          </ol>
          <p />
          <p>
            The goal of the SMETA method is to make it easier to do business in a way that is
            &#39;good for everyone&#39;.
          </p>
        </React.Fragment>
      ),
    },
    {
      question: 'RainForest Alliance',
      answer:
        'RainForest Alliance is a certification for working conditions, environment and nature conservation\n' +
        'for products from areas where rainforest grows.',
    },
    {
      question: 'Fundacao Abrinq - Save the Children Fonds',
      answer: (
        <React.Fragment>
          <p>
            The fund Fundacao Abrinq is a non-profit organization (NGO) in Brazil that defends the
            rights and environment of children in Brazil. There are 3 focus areas: education, health
            and protection of rights. The organization has been recognized by the United Nations
            since 1990, and included in the Brazilian Constitution. The fund cooperates with the
            international organization Save the Children.
          </p>
          <p>
            Supply Chain partner LDC supports the fund and adheres to this with the following
            policy:
          </p>
          <ol>
            <li>no child labor</li>
            <li>promoting social events for children and teenagers</li>
            <li>encouraging the training of teenagers to give them more</li>
          </ol>
        </React.Fragment>
      ),
    },
  ],
  [...(channelName === CHANNELS.ORANGES ? contactsOranges : [contactSIM])],
  [
    {
      question: 'What data will be shared with the consumer?',
      answer: (
        <React.Fragment>
          <p>
            The data shared with the consumer are data points that give insight into the journey of
            the FCOJ such as harvest, shipping, processing & bottling dates. Furthermore some
            KPI&#39;s have been indicated such as sweetness and level of vitamin C which are shared
            with the consumer. Also the different reports and certificates that contribute to
            supplying safe and delicious juice are shown by portraying the certificate logo by each
            company. To make the journey complete the locations of the farms and all other actors in
            the chain are dotted on a map. (no actual coordinates are shown to the consumer) Please
            visit the consumer page by following the link, and fill in a batch number.
          </p>
          <Link to={{ pathname: 'https://t2f.io' }} target="_blank">
            link
          </Link>
        </React.Fragment>
      ),
    },
    {
      question: 'What will be shared with the other actors in the chain?',
      answer: (
        <React.Fragment>
          <p>
            The decision has been made between the participants (AH, Refresco, LDC, SIM) that all
            information uploaded will be visible to all actors. They have reading right and cannot
            alter the data or update your documentation. Only SIM can give the uploaded information
            a status to mark whether it is according to agreed upon data points and accuracy checks
          </p>
          <Link to={{ pathname: 'https://t2f.io' }} target="_blank">
            link
          </Link>
        </React.Fragment>
      ),
    },
    {
      question: 'Link  naar consumer interface',
      answer: (
        <React.Fragment>
          <p>https://www.ah.nl/blockchain</p>
          <Link to={{ pathname: 'https://t2f.io' }} target="_blank">
            link
          </Link>
        </React.Fragment>
      ),
    },
    {
      question: 'Where do I find the batch numbers to fill in on the consumer page?',
      answer: (
        <React.Fragment>
          <p>Use a &#39;Lot Serial Number&#39; as indicated in the batch lists</p>
        </React.Fragment>
      ),
    },
    {
      question: 'Why is the consumer page in dutch?',
      answer: (
        <React.Fragment>
          <p>
            The consumer page is oriented to the consumer of the FJOC bottles, namely the dutch
            consumer of the Albert Heijn.
          </p>
        </React.Fragment>
      ),
    },
    {
      question: 'How can I find some information in english?',
      answer: (
        <React.Fragment>
          <p>
            Here is an Infographic that shows the journey and all the information that has been
            gathered throughout the process
          </p>
        </React.Fragment>
      ),
    },
  ],
];
