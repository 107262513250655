import React, { useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
// actions
import * as batchesActions from 'domain/batches/actions';
// components
import BananaBatchDetails from 'components/Datatable/bananasBatchDetails';
import { getParams, getBatchInfoByUrlOrgRole } from 'domain/common';
// icons
import Loader from 'components/Loaders/smallLoader';
import { AppStateType } from 'types';

const mapStateToProps = (state: AppStateType) => ({
  batchInfo: getBatchInfoByUrlOrgRole(state),
  params: getParams(state),
});

const BananasBatchList: React.FC = () => {
  const dispatch = useDispatch();
  const { batchInfo, params } = useSelector(mapStateToProps, shallowEqual);

  useEffect(() => {
    dispatch(
      batchesActions.fetchBatchContractAction({
        batchEndpoint: batchInfo.endpoint,
        contractNumber: params.batchCode,
        filters: params.status ? { status: params.status } : {},
      }),
    );
  }, []);

  // TODO: remove loader and check why actions don't dispatch
  if (!params.type) return <Loader />;

  return <BananaBatchDetails />;
};

export default BananasBatchList;
