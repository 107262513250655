import Route from 'route-parser';

import producerBatchContractResponseBananas from 'mocks/responses/channels/bananas/producerbatch/contractList';
import producerBatchDetailsResponseBananas from 'mocks/responses/channels/bananas/producerbatch/details';
import producerBatchHandshakeBananas from 'mocks/responses/channels/bananas/producerbatch/handshake';
import uploadBatchResponse from 'mocks/responses/batchUpload/batchUploadTilapiaBananas';

import { deleteContractResponseBananas, deleteMultipleBatchesResponse } from 'mocks/router/utils';

export default (request) => {
  const route = new Route(
    '/api/v1/bananas/producerbatch(?*filter)(/:batchId)(/contracts/:contractId)(/handshake/?*handshake)',
  );

  const parameters = route.match(request.url);
  if (parameters) {
    if (parameters.filter) {
      return [200, producerBatchContractResponseBananas()];
    }

    if (parameters.contractId) {
      if (request.method === 'delete') {
        return [200, deleteContractResponseBananas(parameters.contractId)];
      }

      return [200, producerBatchDetailsResponseBananas()];
    }

    if (parameters.handshake) {
      return [200, producerBatchHandshakeBananas()];
    }
  }

  if (request.method === 'put') {
    return [200, deleteMultipleBatchesResponse(request.data)];
  }

  if (request.method === 'post') {
    return [200, uploadBatchResponse()];
  }

  return [500, `url not proceeded: ${request.url}`];
};
