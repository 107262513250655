import React from 'react';
import injectSheet, { WithStyles } from 'react-jss';

import { EmptyAvatarSvg } from 'components/icons';
import { ProfileResource } from 'lib/lib';

interface OwnProps extends WithStyles<typeof classes> {
  circle?: boolean;
  user: ProfileResource;
  style?: object;
}

const UserAvatar: React.FC<OwnProps> = ({ classes, circle = false, style = {}, user, ...rest }) => (
  <div className={classes.wrapper}>
    {user.org && user.org.logo ? (
      <img
        src={user.org.logo}
        alt="user name"
        style={{ borderRadius: circle ? '100%' : 0, ...style }}
        {...rest}
      />
    ) : (
      <EmptyAvatarSvg />
    )}
  </div>
);

const classes = {
  wrapper: {
    display: 'flex',
    justifyContent: 'center',
    margin: [0, 0, 0, 10],
    padding: 0,
    '& img': {
      width: 40,
      height: 40,
      userDrag: 'none',
    },
  },
};

export default injectSheet(classes)(UserAvatar);
