import { ORGANIZATIONS } from 'themes/constants';
import ah from 'mocks/responses/notifications/oranges/ah';
import jde from 'mocks/responses/notifications/oranges/jde';
import refresco from 'mocks/responses/notifications/oranges/refresco';
import ldcbr from 'mocks/responses/notifications/oranges/ldcbr';

import { createNotificationResponse } from '../utils';

const { SIM, AH, JDE, REFRESCO, LDCBR } = ORGANIZATIONS;

export default (org: ORGANIZATIONS) => {
  switch (org) {
    case AH:
      return createNotificationResponse(2, ah(), 2);
    case JDE:
      return createNotificationResponse(3, jde(), 3);
    case REFRESCO:
      return createNotificationResponse(3, refresco(), 2);
    case LDCBR:
      return createNotificationResponse(3, ldcbr(), 2);
    case SIM:
      return createNotificationResponse(11, [...ldcbr(), ...jde(), ...refresco(), ...ah()], 9);
    default:
      return createNotificationResponse(0, []);
  }
};
