import logoEGG2 from 'components/logos/EGG2_Logo.png';
import { BatchListSvg, CertificateSvg, NotificationListSvg } from 'components/icons';
import { theme } from 'themes/styles';

export default {
  logo: logoEGG2,
  colors: {
    primary: '#3a556c',
    primaryHover: '#486c8c',
    mainMenuLine: 'rgb(99, 120, 103)',
    mainMenuIconColor: 'rgb(133, 165, 204)',
    mainMenuTextColor: '#000',
    black: 'rgb(61, 61, 61)',
    backgroundHead: 'rgb(255, 255, 255)',
    datePickerBgColor: 'rgba(30, 139, 195, 1)',
    datePickerColor: 'rgba(255, 255, 255, 1)',
    accent: '#486c8c',
    modelSettingsCheckedColor: theme.colors.green,
  },
  mainMenuItems: [
    {
      routeName: 'Batches',
      icon: BatchListSvg,
    },
    {
      routeName: 'Documents',
      icon: CertificateSvg,
    },
    {
      routeName: 'Notifications',
      icon: NotificationListSvg,
    },
  ],
  timeFormats: ['DD/MM/YYYY hh:mm:ss', 'YYYY-MM-DD', 'DD/MM/YYYY'],
};
