import React from 'react';
import moment from 'moment';
import {
  extractUploaderName,
  getFormattedCertTitle,
  getIconByOrgName,
} from 'models/certificates/utils';
import { formatBytes, getNameFromBlockchainString } from 'lib/helpers';
import { Certificate } from 'lib/lib';

export default [
  {
    title: '',
    key: 'certIcon',
    style: { flex: '0 0 50px' },
    isServiceField: true,
    render: (column: Certificate) => <img src={getIconByOrgName(column.documentType)} alt="icon" />,
  },
  {
    title: 'ID',
    key: 'certId',
    defaultSelectedColumn: true,
    style: { flex: '2 0 60px' },
    render: (document: Certificate) => document.certId || 'N/A',
  },
  {
    title: 'Required evidence/rule',
    titleList: 'Category',
    key: 'category',
    defaultSelectedColumn: true,
    style: { flex: '1 0 50px' },
    render: (document: Certificate) => document.category,
  },
  {
    title: 'Document Type',
    key: 'documentType',
    style: { flex: '1 0 50px' },
    render: (document: Certificate) => document.documentType,
  },
  {
    title: 'Result',
    key: 'result',
    style: { flex: '1 0 50px' },
    render: (document: Certificate) => document.result,
  },
  {
    title: 'Scope',
    key: 'scope',
    style: { flex: '1 0 50px' },
    render: (document: Certificate) => document.scope,
  },
  {
    title: 'Number',
    key: 'number',
    style: { flex: '1 0 50px' },
    render: (document: Certificate) => document.number,
  },
  {
    title: 'Audit date',
    titleSIM: 'SIM verified date',
    key: 'approvedAt',
    style: { flex: '1 0 50px' },
    render: (document: Certificate) =>
      document.approvedAt && moment(document.approvedAt).format('D MMM YYYY'),
  },
  {
    title: 'Valid from',
    key: 'from',
    style: { flex: '1 0 50px' },
    render: (document: Certificate) => document.from && moment(document.from).format('D MMM YYYY'),
  },
  {
    title: 'Valid until',
    key: 'until',
    defaultSelectedColumn: true,
    style: { flex: '1 0 50px' },
    render: (document: Certificate) => getFormattedCertTitle(document.until),
  },
  {
    title: 'File size',
    key: 'filesize',
    defaultSelectedColumn: true,
    style: { flex: '1 0 100px' },
    render: (document: Certificate) => formatBytes(document.filesize, 0),
  },
  {
    title: 'Uploaded by',
    key: 'uploadedBy',
    render: (document: Certificate) => extractUploaderName(document),
  },
  {
    title: 'Uploaded at',
    key: 'uploadedAt',
    style: { flex: '1 0 50px' },
    isSortable: true,
    render: (document: Certificate) =>
      document.uploadedAt && moment(document.uploadedAt).format('D MMM YYYY'),
  },
  {
    title: 'Verified by',
    key: 'approvedBy',
    style: { flex: '1 0 50px' },
    render: (document: Certificate) => getNameFromBlockchainString(document.approvedBy),
  },
  {
    title: 'Notes',
    key: 'notes',
    style: { flex: '1 0 50px' },
    render: (document: Certificate) => document.notes,
  },
  {
    title: 'Approve Msg',
    key: 'approveMsg',
    style: { flex: '1 0 50px' },
    render: (document: Certificate) => document.approveMsg,
  },
  {
    title: 'Verification',
    key: 'approveReason',
    defaultSelectedColumn: true,
    style: { flex: '1 0 50px' },
    render: (document: Certificate) => document.approveReason,
  },
];
