import React from 'react';

import injectSheet, { ThemeProvider } from 'react-jss';
// utils
import { getTheme } from 'themes/main';
// components
import AuthLayout from './layouts/AuthLayout/AuthLayout';

const StubText = injectSheet((themes) => ({
  Stub: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'center',
    '& h1, p': {
      flex: '1 0 100%',
      color: themes.colors.white,
      textAlign: 'center',
      lineHeight: '28px',
    },
    '& h1': {
      font: {
        size: themes.fontSizes.xl,
        weight: 700,
      },
      marginBottom: '1.5em',
    },
    '& p': {
      font: {
        size: themes.fontSizes.sm,
        weight: 300,
      },
      marginBottom: '1em',
    },
  },
}))(({ classes }) => (
  <section className={classes.Stub}>
    <h1>Het spijt ons</h1>
    <p>De dienst is tijdelijk niet beschikbaar</p>
    <p>Probeer het over een paar minuten opnieuw</p>
  </section>
));

export default class Stub extends React.Component {
  render() {
    return (
      <ThemeProvider theme={getTheme('')}>
        <AuthLayout>
          <StubText />
        </AuthLayout>
      </ThemeProvider>
    );
  }
}
