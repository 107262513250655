import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import moment from 'moment';
// styles
import 'react-datepicker/dist/react-datepicker.css';
// this is a list of inputs available for editable cells

const ValuesList = React.forwardRef((props, ref) => {
  const { classes, handleInputChange, inputValue, edit } = props;
  const { values = [] } = edit;
  return (
    <select
      value={inputValue}
      ref={ref}
      onChange={(e) => handleInputChange(e.target.value)}
      className={classes.cellInput}
    >
      <option key={inputValue} value={inputValue}>
        {inputValue}
      </option>
      {values.map((value) => (
        <option key={value} value={value}>
          {value}
        </option>
      ))}
    </select>
  );
});

ValuesList.propTypes = {
  classes: PropTypes.object.isRequired,
  handleInputChange: PropTypes.func.isRequired,
  inputValue: PropTypes.string.isRequired,
  edit: PropTypes.object.isRequired,
};

const TextInput = React.forwardRef((props, ref) => {
  const { classes, handleInputChange, inputValue } = props;

  return (
    <input
      className={classes.cellInput}
      ref={ref}
      type="text"
      onChange={(e) => handleInputChange(e.target.value)}
      value={inputValue}
    />
  );
});

TextInput.propTypes = {
  handleInputChange: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  inputValue: PropTypes.string.isRequired,
};

/* this function used to set values in datepicker, which accepts only JS date objects */

const parseAsJSDate = (dateFromExcel, format) => {
  const momentDate = moment.utc(dateFromExcel, moment.ISO_8601, true);
  if (momentDate.isValid()) {
    return new Date(momentDate.valueOf());
  }
  const formattedDate = moment.utc(dateFromExcel, format);
  if (formattedDate.isValid()) {
    return new Date(formattedDate.valueOf());
  }
  return new Date();
};

const tryToParseByFormat = (date) => {
  try {
    return moment.utc(date, moment.ISO_8601, true).format();
  } catch (e) {
    return moment.utc().format();
  }
};

/* ref makes input active to set date by input is desirable format  */
const DateInput = React.forwardRef((props, ref) => {
  const { classes, handleInputChange, inputValue, dateFormat } = props;

  // handles initial set of invalid date
  useEffect(() => {
    const datePickeInitialDate = tryToParseByFormat(parseAsJSDate(inputValue, dateFormat));
    handleInputChange(datePickeInitialDate);
  }, []);

  const handleDateChange = (newValue) => {
    /* this format is used to parse dates from datepicker */
    const handledDate = tryToParseByFormat(newValue);

    handleInputChange(handledDate);
  };

  // react-datepicker works only with capital M letters in dateFormat
  const getDatePickerDateFormat = () => {
    if (!dateFormat) return 'MM/dd/yyyy';
    const splitDateFormat = dateFormat.split('');

    Object.keys(splitDateFormat).forEach((index) => {
      if (splitDateFormat[index]) {
        splitDateFormat[index] =
          splitDateFormat[index] && splitDateFormat[index].toLowerCase() === 'm'
            ? splitDateFormat[index].toUpperCase()
            : splitDateFormat[index].toLowerCase();
      }
    });

    // slice to remove everything but mm-dd-yyyy (for ISO8601 date format)
    return splitDateFormat.join('').slice(0, 10);
  };

  return (
    <DatePicker
      ref={ref}
      className={classes.datePickerInput}
      value={parseAsJSDate(inputValue, dateFormat)}
      selected={parseAsJSDate(inputValue, dateFormat)}
      autoFocus
      onChange={handleDateChange}
      placeholderText="Click to select a date"
      dateFormat={getDatePickerDateFormat()}
    />
  );
});

DateInput.propTypes = {
  classes: PropTypes.object.isRequired,
  handleInputChange: PropTypes.func.isRequired,
  inputValue: PropTypes.string.isRequired,
  dateFormat: PropTypes.string.isRequired,
};

export const Editables = {
  input: TextInput,
  list: ValuesList,
  date: DateInput,
};

export default class EditableCellVariant extends React.Component {
  render() {
    const { classes, inputRef, dateFormat, handleInputChange, inputValue, edit = {} } = this.props;

    /* any value without type in model will be processed as simple input */
    const { type = 'input' } = edit;

    const EditableComponent = Editables[type];

    return (
      <EditableComponent
        dateFormat={dateFormat}
        ref={inputRef}
        inputRef={inputRef}
        inputValue={inputValue}
        classes={classes}
        handleInputChange={handleInputChange}
        edit={edit}
      />
    );
  }
}

EditableCellVariant.propTypes = {
  handleInputChange: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  inputValue: PropTypes.any.isRequired,
  inputRef: PropTypes.any,
  dateFormat: PropTypes.string.isRequired,
  edit: PropTypes.shape({
    type: PropTypes.string.isRequired,
    list: PropTypes.arrayOf(PropTypes.string),
  }),
};
