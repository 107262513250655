import { AppStateType } from 'types';

import { peek, selector } from 'lib/selectors';
import { ProfileResource, Channel } from 'lib/lib';

import { CHANNELS, ORGANIZATIONS } from 'themes/constants';
import { findMatchedOrgName } from 'themes/main';

const env = (state: AppStateType) => state.env;

export const getChannel = selector<Channel>(env, peek('channel'));
export const getChannelName = selector<CHANNELS>(getChannel, peek('name'));

export const getToken = selector<string>(env, peek('token'));
export const getUser = selector<ProfileResource>(env, peek('user'));
export const getUserId = selector<ProfileResource['id']>(getUser, peek('id'));
export const getUserOrg = selector<ProfileResource['org']>(getUser, peek('org'));
export const getUserOrgName = selector<ORGANIZATIONS>(getUser, (user: ProfileResource) => {
  const userOrg = user.org;
  const userOrgName = userOrg.name;

  return findMatchedOrgName(userOrgName);
});
export const getUsers = selector<ProfileResource[]>(env, peek('users'));
export const getChannels = selector<Channel[]>(env, peek('channels'));
export const envColumnsSelector = selector(env, peek('columns'));

export const getNotificationConfig = selector(env, peek('notificationConfig'));
export const getIsLoading = selector<boolean>(env, peek('isLoading'));

export const getOwnOrgName = selector<ORGANIZATIONS>(getUserOrg, peek('name'));
export const getRole = selector<string>(getUserOrg, peek('role'));
