import React from 'react';
import { Link } from 'react-router-dom';
// utils
import { getUrl } from 'routes';
import baseModelFields from 'models/baseModelFields';
import { renderShortDateFormat, booleanYesNoFormat } from 'lib/renderHelpers';
import { CHANNELS, ORGANIZATIONS } from 'themes/constants';
import { BatchRecord, ModelRequiredInfo } from 'lib/lib';
import { getCreatorName } from 'models/utils/utils';

export default (modelRequiredInfo: ModelRequiredInfo) => [
  {
    key: 'farmName',
    label: 'Farm name',
    title: 'Farm Name',
    defaultSelectedColumn: true,
    defaultSelectedBatchContractColumn: true,
    style: { flex: '1 0 70px' },
    render: (column: BatchRecord) => column.farmName,
  },
  {
    key: 'farmEggNumber',
    label: 'Farm egg number',
    title: 'Egg Number',
    defaultSelectedBatchContractColumn: true,
    defaultPreviewBeforeUploadColumn: true,
    style: { flex: '1 0 100px' },
    render: (column: BatchRecord) => column.farmEggNumber,
  },
  {
    key: 'thtDateFrom',
    label: 'THT date from',
    title: 'THT Date From',
    defaultSelectedColumn: true,
    defaultSelectedBatchContractColumn: true,
    style: { flex: '1 0 100px' },
    render: (column: BatchRecord) => renderShortDateFormat(column.thtDateFrom),
  },
  {
    key: 'thtDateTo',
    label: 'THT date to',
    title: 'THT Date To',
    style: { flex: '1 0 100px' },
    render: (column: BatchRecord) => renderShortDateFormat(column.thtDateTo),
  },
  {
    // GLNs should be 13 digit number, check on the fact it is a number without any special signs
    key: 'packerGln',
    label: 'Packer GLN',
    title: 'Packer Gln',
    style: { flex: '1 0 100px' },
    render: (column: BatchRecord) => column.packerGln,
  },
  {
    key: 'packerName',
    label: 'Packer name',
    title: 'Packer Name',
    style: { flex: '1 0 100px' },
    render: (column: BatchRecord) => column.packerName,
  },

  // Packer certification
  // IFS BRC IKB KAT BLK Level
  {
    key: 'ifsPacker',
    label: 'IFS Packer',
    title: 'IFS Packer',
    shortTitle: 'P IFS',
    defaultSelectedBatchContractColumn: true,
    style: { flex: '1 0 40px' },
    render: (column: BatchRecord) => booleanYesNoFormat(column.ifsPacker),
  },
  {
    key: 'brcPacker',
    label: 'BRC Packer',
    title: 'BRC Packer',
    shortTitle: 'P BRC',
    defaultSelectedBatchContractColumn: true,
    style: { flex: '1 0 40px' },
    render: (column: BatchRecord) => booleanYesNoFormat(column.brcPacker),
  },
  {
    key: 'ikbPacker',
    label: 'IKB Packer',
    title: 'IKB Packer',
    shortTitle: 'P IKB',
    defaultSelectedBatchContractColumn: true,
    style: { flex: '1 0 40px' },
    render: (column: BatchRecord) => booleanYesNoFormat(column.ikbPacker),
  },
  {
    key: 'katPacker',
    label: 'KAT Packer',
    title: 'KAT Packer',
    shortTitle: 'P KAT',
    defaultSelectedBatchContractColumn: true,
    style: { flex: '1 0 40px' },
    render: (column: BatchRecord) => booleanYesNoFormat(column.katPacker),
  },
  {
    key: 'blkLevelPacker',
    label: 'BLK Level Packer',
    title: 'BLK Level Packer',
    shortTitle: 'P BLK Lvl',
    defaultSelectedBatchContractColumn: true,
    style: { flex: '1 0 40px' },
    render: (column: BatchRecord) => column.blkLevelPacker,
  },

  // Farm certification
  // Stable description IKB KAT BLK Level
  {
    key: 'stableDescription',
    label: 'Stable description',
    title: 'Stable description',
    shortTitle: 'Stable description',
    style: { flex: '1 0 40px' },
    render: (column: BatchRecord) => column.stableDescription,
  },
  {
    key: 'ikbFarm',
    label: 'IKB Farm',
    title: 'IKB Farm',
    shortTitle: 'F IKB',
    defaultSelectedBatchContractColumn: true,
    style: { flex: '1 0 40px' },
    render: (column: BatchRecord) => booleanYesNoFormat(column.ikbFarm),
  },
  {
    key: 'katFarm',
    label: 'KAT Farm',
    title: 'KAT Farm',
    shortTitle: 'F KAT',
    defaultSelectedBatchContractColumn: true,
    style: { flex: '1 0 40px' },
    render: (column: BatchRecord) => booleanYesNoFormat(column.katFarm),
  },
  {
    key: 'blkLevelFarm',
    label: 'BLK Level Farm',
    title: 'BLK Level Farm',
    shortTitle: 'F BLK Lvl',
    defaultSelectedBatchContractColumn: true,
    style: { flex: '1 0 40px' },
    render: (column: BatchRecord) => column.blkLevelFarm,
  },

  // Statements

  // enrichmentPresent
  // beakUntreated
  // distractionAvailable
  // preventiefAntibioticsUsed
  // visitorsWelcome

  {
    key: 'enrichmentPresent',
    label: 'Enrichment present? (Verrijking aanwezig)',
    title: 'Enrichment Present',
    style: { flex: '1 0 50px' },
    render: (column: BatchRecord) => column.enrichmentPresent,
  },
  {
    key: 'beakUntreated',
    label: 'Beak untreated? (Snavel onbehandeld)',
    title: 'beak Untreated',
    style: { flex: '1 0 50px' },
    render: (column: BatchRecord) => column.beakUntreated,
  },
  {
    key: 'distractionAvailable',
    label: 'Distraction available? (afleidings- materiaal)',
    title: 'Distraction Available',
    style: { flex: '1 0 50px' },
    render: (column: BatchRecord) => column.distractionAvailable,
  },
  {
    key: 'preventiefAntibioticsUsed',
    label: 'Preventief antibiotics used',
    title: 'Preventief AntibioticsUsed',
    style: { flex: '1 0 50px' },
    render: (column: BatchRecord) => column.preventiefAntibioticsUsed,
  },
  {
    key: 'visitorsWelcome',
    label: 'Visitors welcome in person or virtual',
    title: 'Visitors Welcome',
    style: { flex: '1 0 50px' },
    render: (column: BatchRecord) => column.visitorsWelcome,
  },

  // Chicken population info
  // nightTimeStarts dayTimeStarts birthDateChicken breedChicken

  {
    key: 'nightTimeStarts',
    label: 'Night time starts',
    title: 'Night Time Starts',
    style: { flex: '1 0 50px' },
    render: (column: BatchRecord) => column.nightTimeStarts,
  },
  {
    key: 'dayTimeStarts',
    label: 'Day time starts',
    title: 'Day Time Starts',
    style: { flex: '1 0 50px' },
    render: (column: BatchRecord) => column.dayTimeStarts,
  },
  {
    key: 'birthDateChicken',
    label: 'Birth date chicken',
    title: 'Birth Date Chicken',
    style: { flex: '1 0 50px' },
    render: (column: BatchRecord) => renderShortDateFormat(column.birthDateChicken),
  },
  {
    key: 'breedChicken',
    label: 'Breed chicken',
    title: 'Breed Chicken',
    style: { flex: '1 0 50px' },
    render: (column: BatchRecord) => column.breedChicken,
  },

  // Feed info

  // Feed constitution;
  // Water intake;
  // Feed intake;
  // Feed supplier name;
  // Feed RTRS certificate;
  // Feed RTRS Proved by;
  // Feed Soy reduction;
  // Feed Soy reduction Proved by;
  // VLOG

  {
    key: 'feedConstitution',
    label: 'Feed constitution',
    title: 'Feed Constitution',
    style: { flex: '1 0 50px' },
    render: (column: BatchRecord) => column.feedConstitution,
  },
  {
    key: 'waterIntake',
    label: 'Water intake',
    title: 'Water intake',
    style: { flex: '1 0 50px' },
    render: (column: BatchRecord) => column.waterIntake,
  },
  {
    key: 'feedIntake',
    label: 'Feed intake',
    title: 'Farm Intake',
    style: { flex: '1 0 50px' },
    render: (column: BatchRecord) => column.feedIntake,
  },
  {
    key: 'feedSupplierName',
    label: 'Feed supplier name',
    title: 'Feed Supplier Name',
    style: { flex: '1 0 50px' },
    render: (column: BatchRecord) => column.feedSupplierName,
  },
  {
    key: 'feedRtrsCertificate',
    label: 'Feed RTRS certificate',
    title: 'Farm RTRS Certificate',
    style: { flex: '1 0 50px' },
    render: (column: BatchRecord) => column.feedRtrsCertificate,
  },
  {
    key: 'feedRtrsProvedBy',
    label: 'Feed RTRS Proved by',
    title: 'Farm RTRS Proved By',
    style: { flex: '1 0 50px' },
    render: (column: BatchRecord) => column.feedRtrsProvedBy,
  },
  {
    key: 'feedSoyReduction',
    label: 'Feed Soy reduction',
    title: 'Farm Soy Reduction',
    style: { flex: '1 0 50px' },
    render: (column: BatchRecord) => column.feedSoyReduction,
  },
  {
    key: 'feedSoyReductionProvedBy',
    label: 'Feed Soy reduction Proved by',
    title: 'Feed Soy Reduction ProvedBy',
    style: { flex: '1 0 50px' },
    render: (column: BatchRecord) => column.feedSoyReductionProvedBy,
  },
  {
    key: 'greenEnergy',
    label: 'Green Energy',
    title: 'Green Energy (until 01-03-2020 was called VLOG)',
    style: { flex: '1 0 50px' },
    render: (column: BatchRecord) => booleanYesNoFormat(column.greenEnergy),
  },
  // TODO: this field SHOULDNT be in this model but its used in settingsAndActions to get its render value in batchContract.jsx
  {
    key: 'batchId',
    label: 'Batch Id',
    title: 'Batch Details',
    style: { flex: '0 0 150px' },
    defaultSelectedColumn: true,
    render: (column: BatchRecord) => (
      <Link
        to={{
          pathname: getUrl('BatchDetailsType', {
            type: ORGANIZATIONS.EGG1.toLowerCase(),
            batch_id: column.batchId,
            modelType: 'details',
            channelName: CHANNELS.EGGS,
          }),
        }}
        title={column.batchId}
        className="batch-details"
      >
        Details
      </Link>
    ),
  },

  ...baseModelFields(modelRequiredInfo),
];
