import logoRefresco from 'components/logos/Refresco.png';
import { BatchListSvg, CertificateSvg, DashboardSvg, NotificationListSvg } from 'components/icons';
import { theme } from 'themes/styles';

export default {
  logo: logoRefresco,
  colors: {
    primary: '#f5c58e',
    primaryHover: '#f99627',
    mainMenuLine: '#f99627',
    mainMenuIconColor: '#f99627',
    mainMenuTextColor: 'rgb(0, 0, 0)',
    black: 'rgb(61, 61, 61)',
    backgroundHead: 'rgb(255, 255, 255)',
    datePickerBgColor: '#f99627',
    accent: '#00b25a',
    modelSettingsCheckedColor: theme.colors.green,
  },
  mainMenuItems: [
    {
      routeName: 'Dashboard',
      icon: DashboardSvg,
      disabled: true,
    },
    {
      routeName: 'Notifications',
      icon: NotificationListSvg,
    },
    {
      routeName: 'Batches',
      icon: BatchListSvg,
    },
    {
      routeName: 'Documents',
      icon: CertificateSvg,
    },
  ],
  // logo: logoRefresco,
  // colors: {
  //   primary: 'rgb(255, 150, 0)',
  //   primaryHover: 'rgb(255, 181, 35)',
  //   mainMenuLine: 'rgb(138, 180, 111)',
  //   mainMenuIconColor: 'rgb(138, 180, 111)',
  //   mainMenuTextColor: 'rgb(0, 0, 0)',
  //   black: 'rgb(61, 61, 61)',
  //   backgroundHead: 'rgb(255, 255, 255)',
  //   datePickerBgColor: 'rgb(138, 180, 111)',
  //   accent: 'rgb(255, 181, 35)',
  //   modelSettingsCheckedColor: theme.colors.green,
  // },
  // mainMenuItems: [
  //   {
  //     routeName: 'Dashboard',
  //     icon: DashboardSvg,
  //   },
  //   {
  //     routeName: 'Batches',
  //     icon: BatchListSvg,
  //   },
  //   {
  //     routeName: 'Documents',
  //     icon: CertificateSvg,
  //   },
  //   {
  //     routeName: 'Notifications',
  //     icon: NotificationListSvg,
  //   },
  // ],
  timeFormats: ['MM/DD/YYYY hh:mm:ss', 'MM-DD-YY', 'YYYY-DD-MM', 'MM/DD/YYYY'],
};
