import React from 'react';
// render helpers
import { renderShortDateFormat, booleanYesNoFormat } from 'lib/renderHelpers';
import { Notification } from 'lib/lib';

const wrapperIsReadBold = (value: string, column: Notification) => (
  <span className={`${column.is_read ? '' : 'bold'}`}>{value}</span>
);

export default [
  {
    key: 'actionType',
    title: 'Action',
    defaultSelectedColumn: true,
    style: { flex: '1 0 50px' },
    render: (column: Notification) => wrapperIsReadBold(column.event.actionType, column),
  },
  {
    key: 'entityType',
    title: 'Entity Type / Id',
    defaultSelectedColumn: true,
    style: { flex: '1 0 50px' },
    render: (column: Notification) =>
      wrapperIsReadBold(
        `${
          column.event.entityType.toUpperCase() === 'DEMAND' ? 'Request' : column.event.entityType
        } / ${column.event.entityId}`,
        column,
      ),
  },
  {
    key: 'eventTypeTitle',
    title: 'Event',
    defaultSelectedColumn: true,
    style: { flex: '1 0 100px' },
    render: (column: Notification) => wrapperIsReadBold(column.event.eventType.title, column),
  },
  {
    key: 'eventDoneAt',
    title: 'Done At',
    defaultSelectedColumn: true,
    style: { flex: '1 0 50px' },
    render: (column: Notification) =>
      wrapperIsReadBold(renderShortDateFormat(column.event.eventDoneAt), column),
  },
  {
    key: 'eventDoneBy',
    title: 'Done by (Org)',
    defaultSelectedColumn: true,
    style: { flex: '1 0 50px' },
    render: (column: Notification) =>
      wrapperIsReadBold(
        `${String(column.event.eventDoneBy.username)} (${String(
          column.event.eventDoneBy.org.fullname,
        )})`,
        column,
      ),
  },
  {
    key: 'isRead',
    title: 'Is Read',
    defaultSelectedColumn: true,
    style: { flex: '1 0 50px' },
    render: (column: Notification) => wrapperIsReadBold(booleanYesNoFormat(column.is_read), column),
  },
];
