import React, { useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
// componenents
import Loader from 'components/Loaders/smallLoader';
import Datatable from 'components/Datatable/datatable';
import DatatableTabs from 'components/Datatable/datatableTabs';
import ModelSettingsDropdown from 'components/Dropdown/ModelSettingsDropDown';
import { DeleteBatchesSvg, ExcelSvg } from 'components/icons';
// selectors
import { getBatchContract, getIsLoading, getSelectedBatches } from 'domain/batches';
import { getUser } from 'domain/env';
import { getParams, getDisplayModel, getBatchInfoByUrlOrgRole } from 'domain/common';
//actions
import * as batchesActions from 'domain/batches/actions';
// jss
import injectSheet, { WithStyles } from 'react-jss';
import bananaExcelBatchStyles from 'pages/batches/styles/bananaExcelBatchStyles';
//types
import { AppStateType } from 'types';
import { IndexedObject } from 'lib/lib';
//
import { exportExcelFile } from 'lib/helpers';
import { findMatchedOrgName } from 'themes/main';
import { ORGANIZATIONS } from 'themes/constants';

interface OwnProps extends WithStyles<typeof bananaExcelBatchStyles> {}

const mapStateToProps = (state: AppStateType) => ({
  batchInfo: getBatchInfoByUrlOrgRole(state),
  batches: getBatchContract(state),
  isLoading: getIsLoading(state),
  user: getUser(state),
  displayModel: getDisplayModel(state),
  selected: getSelectedBatches(state),
  params: getParams(state),
});

const BananasBatchDetails: React.FC<OwnProps> = (props) => {
  const dispatch = useDispatch();
  const [activeTabIndex, setActiveTabIndexHook] = useState(0);
  const { classes } = props;
  const { batchInfo, batches, isLoading, user, displayModel, selected, params } = useSelector(
    mapStateToProps,
    shallowEqual,
  );

  const getDisplayModelActiveTab = () => displayModel.tabs[activeTabIndex];

  const deleteMultipleBatches = () => {
    const endpoint = batchInfo.endpoint;
    const activeTabName = getDisplayModelActiveTab().modelName;
    const subBatchType: string = batchInfo.subBatches[activeTabName].type;
    const data = {
      batchIds: selected,
      batchType: subBatchType,
    };
    if (window.confirm(`Are you sure you want to archive ${selected.length} batches?`)) {
      dispatch(batchesActions.deleteMultipleBatchesAction({ data, endpoint, activeTabName }));
    }
  };

  const setActiveTabIndex = (index: number) => {
    if (activeTabIndex !== index) {
      setActiveTabIndexHook(index);
      if (selected.length) {
        dispatch({ type: batchesActions.setSelectedBatchesAction.success });
      }
    }
  };

  const displayTabs = () => (
    <DatatableTabs tabs={displayModel.tabs} changeTabIndexCallback={setActiveTabIndex} />
  );

  const displayTableRows = () => {
    const { modelName } = getDisplayModelActiveTab();

    const modelRows = [...displayModel[modelName], getSettingsDropdown()];
    const batchRows = (batches as IndexedObject<IndexedObject<string>[]>)[modelName];

    return (
      <>
        <Datatable data={batchRows} columns={modelRows} isLoading={isLoading} />
        {modelRows && isLoading && <Loader />}
      </>
    );
  };

  const getSettingsDropdown = () => {
    return {
      key: 'settingsAndActions',
      title: (
        <>
          <ModelSettingsDropdown />
          {!!selected.length && (
            <div
              className={classes.deleteBatches}
              onClick={deleteMultipleBatches}
              data-cy="deleteBatches"
            >
              <DeleteBatchesSvg />
            </div>
          )}
        </>
      ),
      style: { flex: '1 0 70px', justifyContent: 'space-evenly' },
      render: () => null,
    };
  };

  const onExportToExcel = () =>
    exportExcelFile(batches, displayModel, user.username, 'contract_details.xlsx');

  const getTabModelTitle = () => {
    const { HOLLANDER, FYFFES, BANAFOOD } = ORGANIZATIONS;
    switch (findMatchedOrgName(params.type)) {
      case HOLLANDER: {
        return 'Partij nummer';
      }
      case FYFFES: {
        return 'FLO-ID';
      }
      case BANAFOOD:
        return 'Banafood SSCC pallet #';

      default:
        return 'Batch ID';
    }
  };

  const displayTableHeader = () => {
    return (
      <header className={classes.header}>
        <div className={classes.highOrderTab}>
          <span className={classes.highOrderTabTitle}>{`${getTabModelTitle()} : `}</span>
          <span>{params.batchCode}</span>
        </div>
        <div className={classes.exportToExcel} onClick={onExportToExcel} data-cy="download">
          <ExcelSvg width={24} height={24} />
          <span>Export To Excel</span>
        </div>
      </header>
    );
  };

  const displayTable = () => (
    <>
      {displayTabs()}
      {displayTableRows()}
    </>
  );

  const displayLoader = () => {
    return (
      <div className={classes.loader}>
        <Loader />
      </div>
    );
  };

  return (
    <>
      {displayTableHeader()}
      {!displayModel ? displayLoader() : displayTable()}
    </>
  );
};

export default injectSheet(bananaExcelBatchStyles)(BananasBatchDetails);
