import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
// components
import SimpleTabsFrame from 'components/Frames/SimpleTabsFrame';
// utils
import Loader from 'components/Loaders/smallLoader';
import * as requestsActions from 'domain/requests/actions';
// jss
import injectSheet from 'react-jss';
// selectors
import { getParams } from 'domain/common';
import { getRequest, getIsLoading } from 'domain/requests';

class OutboxDetails extends React.PureComponent {
  componentDidMount() {
    const { fetchRequestList, params } = this.props;
    fetchRequestList({
      requestType: 'outbox',
      id: params.outboxId,
    });
  }

  render() {
    const { classes, request, isLoading } = this.props;

    /*
    const outcomingRequest = ['createdByUser', 'created_at', 'expiry_date', 'fromOrg', 'id',
      'msg_request', 'msg_response', 'status', 'subject', 'toOrg', 'type', 'updatedByUser',
      'updated_at'];
    */

    const outcomingRequest = [
      'created_at',
      'expiry_date',
      'id',
      'msg_request',
      'msg_response',
      'status',
      'subject',
      'type',
      'updated_at',
    ];

    return (
      <section className={classes.component}>
        {request.isEmpty() ? (
          ''
        ) : (
          <SimpleTabsFrame
            tabs={
              isLoading
                ? [{ style: {}, title: <Loader />, body: '' }]
                : [
                    {
                      style: {},
                      className: '',
                      title: <span>Outbox Request #{request.id}</span>,
                      body: Object.keys(request)
                        .filter((key) => outcomingRequest.includes(key))
                        .map((key) => (
                          <div className={classes.tableRow} key={key}>
                            <div className="left">{key}</div>
                            <div className="right">{request[key]}</div>
                          </div>
                        )),
                    },
                  ]
            }
          />
        )}
      </section>
    );
  }
}

const outboxStyles = (theme) => ({
  component: {
    padding: ['2em', 0],
  },
  tableRow: {
    display: 'flex',
    color: theme.colors.black,
    backgroundColor: theme.colors.background,
    border: '1px solid rgb(222, 222, 222)',
    borderTop: 0,
    '&:nth-of-type(even)': {
      backgroundColor: theme.colors.white,
      overflow: 'hidden',
    },
    '& .left': {
      flex: '0 0 50%',
      padding: [5, 8],
      textAlign: 'left',
      borderRight: '1px solid rgb(222, 222, 222)',
    },
    '& .right': {
      flex: '0 0 50%',
      padding: [5, 8],
      textAlign: 'left',
    },
  },
});

OutboxDetails.propTypes = {
  classes: PropTypes.object.isRequired,
  fetchRequestList: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  request: PropTypes.object,
  params: PropTypes.object,
};

export default compose(
  connect(
    (state) => ({
      request: getRequest(state),
      isLoading: getIsLoading(state),
      params: getParams(state),
    }),
    {
      fetchRequestList: (data) => ({
        type: requestsActions.fetchRequestAction.type,
        payload: data,
      }),
    },
  ),
  injectSheet(outboxStyles),
)(OutboxDetails);
