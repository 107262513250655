import { ORGANIZATIONS } from 'themes/constants';

export const getSelectValues = (organization: ORGANIZATIONS) => {
  switch (organization) {
    case ORGANIZATIONS.HOLLANDER:
      return [{ value: 'DistributorBatch', label: 'Hollander', defaultValue: true }];
    case ORGANIZATIONS.FYFFES:
      return [{ value: 'ProducerBatch', label: 'Fyffes', defaultValue: true }];
    case ORGANIZATIONS.BANAFOOD:
      return [{ value: 'ResellerBatch', label: 'Banafood', defaultValue: true }];
    case ORGANIZATIONS.SIM:
      return [
        { value: 'all', label: 'All participants', defaultValue: true },
        { value: 'DistributorBatch', label: 'Hollander' },
        { value: 'ResellerBatch', label: 'Banafood' },
        { value: 'ProducerBatch', label: 'Fyffes' },
      ];
    default:
      return [];
  }
};
