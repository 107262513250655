import React from 'react';
import { Link } from 'react-router-dom';
import { CHANNELS, ORGANIZATIONS } from 'themes/constants';
// utils
import { getUrl } from 'routes';
import indexBaseModelFields from 'models/indexBaseModelFields';
import { renderShortDateFormat } from 'lib/renderHelpers';
import { BatchRecord, ModelRequiredInfo } from 'lib/lib';

export default (modelRequiredInfo: ModelRequiredInfo) => [
  {
    title: 'Gtin',
    label: 'GTIN',
    key: 'gtin',
    defaultSelectedColumn: true,
    style: { flex: '1 0 50px' },
    render: (column: BatchRecord) => (
      <Link
        className="contract"
        to={{
          pathname: getUrl('BatchContractType', {
            type: ORGANIZATIONS.AH.toLowerCase(),
            contract_number: `${column.gtin}`,
            modelType: 'contractDetails',
            channelName: CHANNELS.EGGS,
          }),
        }}
      >
        {column.gtin}
      </Link>
    ),
  },
  {
    title: 'Gln Supplier',
    label: 'GLN Supplier',
    key: 'glnSupplier',
    defaultSelectedColumn: true,
    style: { flex: '1 0 50px' },
    // BatchTHTDateFrom
    render: (column: BatchRecord) => column.glnSupplier,
  },
  {
    title: 'Gln Buyer',
    label: 'GLN Buyer',
    key: 'glnBuyer',
    style: { flex: '1 0 50px' },
    render: (column: BatchRecord) => column.glnBuyer || '',
  },
  {
    title: 'Gln Delivery Location',
    label: 'GlN Delivery location',
    key: 'glnDeliveryLocation',
    defaultSelectedColumn: true,
    style: { flex: '1 0 50px' },
    render: (column: BatchRecord) => column.glnDeliveryLocation || '',
  },
  {
    title: 'Quantity',
    label: 'Quantity',
    key: 'quantity',
    defaultSelectedColumn: true,
    style: { flex: '1 0 50px' },
    render: (column: BatchRecord) => column.quantity || '',
  },
  {
    title: 'Delivery Datetime',
    label: 'Delivery datetime',
    key: 'deliveryDatetime',
    defaultSelectedColumn: true,
    style: { flex: '1 0 50px' },
    edit: { type: 'date' },
    render: (column: BatchRecord) => renderShortDateFormat(column.deliveryDatetime),
  },
  {
    key: 'batchnumber',
    label: 'Batchnumber',
    title: 'Batch Number',
    defaultSelectedColumn: true,
    style: { flex: '1 0 100px' },
    render: (column: BatchRecord) => renderShortDateFormat(column.batchnumber),
  },

  ...indexBaseModelFields(modelRequiredInfo),
];
