import generic from 'mocks/router/generic';
import bananas from 'mocks/router/bananas';
import tilapia from 'mocks/router/tilapia';
import oranges from 'mocks/router/oranges';
import juice from 'mocks/router/juice';
import eggs from 'mocks/router/eggs';

import { CHANNELS } from 'themes/constants';

const { BANANAS, TILAPIA, ORANGES, EGGS, JUICE } = CHANNELS;

export default {
  generic,
  [BANANAS]: bananas,
  [TILAPIA]: tilapia,
  [ORANGES]: oranges,
  [EGGS]: eggs,
  [JUICE]: juice,
};
