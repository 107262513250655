import moment from 'moment';
import { IndexedObject } from 'lib/lib';

const validationSerializer = (msg = '') =>
  msg ? { message: msg, status: false } : { message: msg, status: true };

const validDateValidator = (value, format) =>
  validationSerializer(
    !moment(value, moment.ISO_8601, true).isValid() && `Should be valid ${format} date`,
  );

export const validationRequired = (v) => validationSerializer(!v ? 'This field is required' : '');

export const validationRegexp = (regexp, msg) => (value) =>
  validationSerializer(new RegExp(regexp).test(value) && msg);

// "!value || " means value might be not required but if it exists must accord the rule

export const validationInt = (value) =>
  !value ||
  validationSerializer(
    !new RegExp(/^[-+]?[0-9]+$/).test(String(value).trim()) && 'Cell value must be integer',
  );
export const validationFloat = (value) =>
  !value ||
  validationSerializer(
    !new RegExp(/^[-+]?[0-9]+(\,[0-9]{3})*[\.]?[0-9]*$/).test(String(value).trim()) &&
      'Cell value must be float',
  );
export const validationString = () => validationSerializer('');

// Date formats validator
export const validationDateByFormat = (value, format) => validDateValidator(value, format);
export const validationCustomDate = (format) => (value) => validationDateByFormat(value, format);

export const validationPositiveFloatInString = (value: string) =>
  !value ||
  validationSerializer(
    !new RegExp(/^[+]?[0-9]+([.,][0-9]*)?$/).test(String(value).trim())
      ? 'Should be positive float'
      : undefined,
  );

export const validationFloatSumLessThan1 = (key: string) => (
  value,
  currentTabValues: IndexedObject<unknown>[],
) => {
  const sum =
    currentTabValues &&
    currentTabValues
      .map((dataItem) => dataItem[key])
      .reduce((a, b) => parseFloat(Number(a) + Number(b)).toFixed(15), 0);
  return !sum || validationSerializer(sum > 1 ? "Sum can't be grater than 1" : undefined);
};

export const validationISODataRegexp1 = (value) =>
  validationSerializer(
    !new RegExp(/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/).test(String(value).trim()) &&
      'Must be in ISO format.',
  );
