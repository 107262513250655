import produce from 'immer';
import { AnyAction } from 'redux';

import * as notificationsActions from './actions';
import * as envActions from 'domain/env/actions';

const initialState = {
  resources: [],
  resource: {},
  isLoading: false,
  isUpdating: false,
  errors: {},
  meta: {},
};

const notifications = produce<(draft: typeof initialState, action: AnyAction) => void>(
  (draft, { type, payload }) => {
    switch (type) {
      case envActions.setChannelAction.type:
      case envActions.logOutAction.success:
        return initialState;

      // notifications
      case notificationsActions.fetchNotificationsAction.type:
        draft.isLoading = true;
        draft.resources = initialState.resources;
        return;

      case notificationsActions.fetchNotificationsAction.success:
        draft.isLoading = false;
        draft.meta = payload.meta;
        draft.resources = payload.resources;
        return;

      case notificationsActions.fetchNotificationsAction.failure:
        draft.isLoading = false;
        draft.errors = payload;
        return;

      // one notification
      case notificationsActions.fetchNotificationAction.type:
        draft.isLoading = true;
        draft.resource = initialState.resource;
        return;

      case notificationsActions.fetchNotificationAction.success:
        draft.isLoading = false;
        draft.resource = payload;
        return;

      case notificationsActions.fetchNotificationAction.failure:
        draft.isLoading = false;
        draft.errors = payload;
        return;

      // update notification
      case notificationsActions.updateNotificationAction.type:
        draft.isUpdating = true;
        return;

      case notificationsActions.updateNotificationAction.success:
        draft.isUpdating = false;
        draft.resources = payload;
        return;

      case notificationsActions.updateNotificationAction.failure:
        draft.isUpdating = false;
        return;

      // update notification count
      case notificationsActions.updateNotificationsCountAction.success:
        draft.meta.unread = payload;
    }
  },
  initialState,
);

export const reducer = { notifications };
