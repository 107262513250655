import React, { useCallback, useState } from 'react';
//
import { Link } from 'react-router-dom';
import {
  MarkSvg,
  PhoneSvg,
  MailSvg,
  GridSvg,
  HelpSettingsSvg,
  HelpDocumentsSvg,
  HelpContactsSvg,
  // HelpConsumerSvg,
} from 'components/icons';
import faq from 'pages/help/faq';
import { getBatchInfoByUrlOrgRole, getContacts } from 'domain/common';
import { getChannelName } from 'domain/env';
//
import { compose } from 'redux';
import { shallowEqual, useSelector } from 'react-redux';
// jss
import injectSheet, { WithStyles } from 'react-jss';
import helpStyles from 'pages/help/helpStyles';
import { AppStateType } from 'types';

const mapStateToProps = (state: AppStateType) => ({
  contacts: getContacts(state),
  batchInfo: getBatchInfoByUrlOrgRole(state),
  channelName: getChannelName(state),
});

const Help: React.FC<WithStyles<typeof helpStyles>> = ({ classes }) => {
  const [tabIndex, setActiveTab] = useState(0);
  const [activeQuestion, setActiveQuestion] = useState(0);

  const { contacts, batchInfo, channelName } = useSelector(mapStateToProps, shallowEqual);

  const onTabClick = useCallback((index: number) => {
    setActiveTab(index);
    setActiveQuestion(0);
  }, []);

  return (
    <section className={classes.component}>
      <div>
        <div className="support-tabs">
          <div
            className={`support-tab ${tabIndex === 0 && 'active'}`}
            onClick={() => onTabClick(0)}
          >
            <HelpSettingsSvg />
            <div className={classes.title}>FAQ</div>
          </div>
          <div
            className={`support-tab ${tabIndex === 1 && 'active'}`}
            onClick={() => onTabClick(1)}
          >
            <HelpDocumentsSvg />
            <div className={classes.title}>Documents</div>
          </div>
          <div
            className={`support-tab ${tabIndex === 2 && 'active'}`}
            onClick={() => onTabClick(2)}
          >
            <HelpContactsSvg />
            <div className={classes.title}>Contacts</div>
          </div>
          {/*<div*/}
          {/*  className={`support-tab ${tabIndex === 3 && 'active'}`}*/}
          {/*  onClick={() => onTabClick(3)}*/}
          {/*>*/}
          {/*  <HelpConsumerSvg />*/}
          {/*  <div className={classes.title}>Consumer</div>*/}
          {/*</div>*/}
        </div>
        <div className={classes.supportQaA}>
          {faq(batchInfo.endpoint, channelName)[tabIndex].map(({ question, answer }, i) => (
            <div
              key={i}
              className={`faqBlock ${activeQuestion === i ? 'active' : ''}`}
              onClick={() => setActiveQuestion(i)}
            >
              <div className="question">{question}</div>
              <div className="answer">{answer}</div>
            </div>
          ))}
        </div>
      </div>

      <div className="row">
        <div className="col-md-12">
          <h2 className={classes.title}>Contact us</h2>
        </div>
      </div>

      <div className={classes.teasers}>
        <div className="teaser-item">
          <div className="icon">
            <MarkSvg />
          </div>
          <p>{contacts.address || ''}</p>
        </div>

        <div className="teaser-item">
          <div className="icon">
            <PhoneSvg />
          </div>
          {(contacts.phones || []).map((p, ix) => (
            <a href={`tel:+${p.replace(/[^\d\.]*/g, '')}`} key={ix}>
              {p}
            </a>
          ))}
        </div>

        <div className="teaser-item">
          <div className="icon">
            <MailSvg />
          </div>
          {(contacts.emails || []).map((e, ix) => (
            <p key={ix}>
              <a href={`mailto:${e}`}>{e}</a>
            </p>
          ))}
        </div>

        <div className="teaser-item">
          <div className="icon">
            <GridSvg />
          </div>
          {(contacts.sites || []).map((s, ix) => (
            <p key={ix}>
              <Link to={{ pathname: s }} target="_blank">
                {s}
              </Link>
            </p>
          ))}
        </div>
      </div>
    </section>
  );
};

export default compose<React.FC>(
  React.memo,
  injectSheet(helpStyles),
)(Help);
