import Route from 'route-parser';

import retailerbatchIndexListResponseJuice from 'mocks/responses/channels/juice/retailerbatch/indexList';
import { deleteContractResponseJuice } from 'mocks/router/utils';
import retailerbatchContractListResponseJuice from 'mocks/responses/channels/juice/retailerbatch/contractsList';
import retailerbatchDetailsListResponseJuice from 'mocks/responses/channels/juice/retailerbatch/details';
import uploadBatchResponse from 'mocks/responses/batchUpload/batchUploadEggsOranges';

export default (request) => {
  const route = new Route(
    '/api/v1/juice/retailerbatch(?*filter)(/:batchId)(/contracts/:contractId)',
  );
  const parameters = route.match(request.url);

  if (parameters) {
    if (parameters.filter) {
      if (parameters.filter.includes('beginDate')) {
        return [200, retailerbatchIndexListResponseJuice];
      }
    }
  }

  if (parameters) {
    if (parameters.filter) {
      if (parameters.filter.includes('beginDate')) {
        return [200, retailerbatchIndexListResponseJuice];
      }
    }
    if (parameters.contractId) {
      if (request.method === 'delete') {
        return [200, deleteContractResponseJuice(parameters.contractId)];
      }

      return [200, retailerbatchContractListResponseJuice];
    }
    if (parameters.batchId) {
      return [200, retailerbatchDetailsListResponseJuice];
    }
  }

  if (request.method === 'post') {
    return [200, uploadBatchResponse(JSON.parse(request.data))];
  }

  return [500, `url not proceeded: ${request.url}`];
};
