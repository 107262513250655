import { put, call } from 'redux-saga/effects';
import Api from 'domain/api';
import qs from 'qs';
import * as documentActions from 'domain/documents/actions';
import { getUrl } from 'routes';
import axios from 'axios';

import { getStorageItem } from 'lib/storage';
// helpers
import { showAxiosErrors } from 'lib/helpers';

export function* fetchDocuments({ payload }) {
  try {
    const { data } = yield call(Api.fetchDocuments, {
      filters: qs.stringify(payload, { encodeValuesOnly: true, addQueryPrefix: true }),
    });
    yield put({
      type: documentActions.fetchDocumentsAction.success,
      payload: data,
    });
  } catch (errors) {
    yield showAxiosErrors(errors.response);
    yield put({
      type: documentActions.fetchDocumentsAction.failure,
      payload: errors,
    });
  }
}

export function* fetchDocument({ payload }) {
  try {
    const { data } = yield call(Api.fetchDocument, {
      id: payload,
    });
    yield put({
      type: documentActions.fetchDocumentAction.success,
      payload: data,
    });
  } catch (errors) {
    yield showAxiosErrors(errors.response);
    yield put({
      type: documentActions.fetchDocumentAction.failure,
      payload: errors,
    });
  }
}

export function* createDocuments({ payload, history }) {
  try {
    const { data } = yield call(Api.createDocument, {
      data: payload,
    });

    yield put({
      type: documentActions.createDocumentAction.success,
      payload: data,
    });

    history.push(getUrl('Document', { id: data.certId }));
  } catch (errors) {
    yield showAxiosErrors(errors.response);
    yield put({
      type: documentActions.createDocumentAction.failure,
      payload: errors,
    });
  }
}

export function* deleteDocument({ payload }) {
  try {
    const { data } = yield call(Api.deleteDocument, {
      id: payload,
    });

    // yield put(push(getUrl('Documents')));
    yield put({
      type: documentActions.deleteDocumentAction.success,
      payload: data,
    });
  } catch (errors) {
    yield showAxiosErrors(errors.response);
    yield put({
      type: documentActions.deleteDocumentAction.failure,
      payload: errors,
    });
  }
}

export function* approveDocument({ payload }) {
  try {
    const { data } = yield call(Api.approveDocument, {
      certId: payload.certId,
      data: payload.data,
    });

    yield put({
      type: documentActions.approveDocumentAction.success,
      payload: data,
    });
  } catch (errors) {
    yield showAxiosErrors(errors.response);
    yield put({
      type: documentActions.approveDocumentAction.failure,
      payload: errors,
    });
  }
}

export function* displayCertificate({ payload }) {
  try {
    const jwtToken = getStorageItem('token');

    const { data } = yield axios.request({
      url: `${process.env.REACT_APP_API_URL}/api/v1/certfile/${payload}`,
      responseType: 'blob',
      headers: {
        Authorization: `Bearer ${jwtToken}`,
      },
    });

    yield put({ type: documentActions.getCertFileAction.success });
    // eslint-disable-next-line
    const wUrl = window.URL.createObjectURL(data);
    window.open(wUrl);
  } catch (errors) {
    yield showAxiosErrors(errors.response);

    yield put({
      type: documentActions.getCertFileAction.failure,
      payload: errors,
    });
  }
}

export function* uploadCertFile({ payload }) {
  try {
    const { data } = yield call(Api.uploadCertFile, {
      data: payload,
    });

    yield put({
      type: documentActions.uploadCertFileAction.success,
      payload: data,
    });
  } catch (errors) {
    yield showAxiosErrors(errors.response);
    yield put({
      type: documentActions.uploadCertFileAction.failure,
      payload: errors,
    });
  }
}
