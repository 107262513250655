export default () => [
  {
    id: 1674,
    is_read: false,
    created_at: '2019-08-11T13:39:24.912Z',
    event: {
      id: 557,
      entityType: 'RetailerBatch',
      entityId: '147561',
      statusChangedTo: null,
      eventDoneAt: '2019-08-11T13:39:22.000Z',
      actionType: 'CREATED',
      actionSource: 'frontEndApp',
      channel: { title: 'Tilapia', name: 'tilapia' },
      eventType: { id: 1, title: 'Batch upload', slug: 'BatchUpload' },
      eventDoneBy: {
        username: 'JumboTST',
        email: 'JumboTST@pc.t2f.io',
        org: { name: 'Jumbo', fullname: 'Jumbo', logo: null },
      },
    },
  },
  {
    id: 10287,
    is_read: false,
    created_at: '2019-08-23T12:28:12.099Z',
    event: {
      id: 2009,
      entityType: 'CertFile',
      entityId: 'be612fcc-38d7-43af-4ac6-d32326709294',
      statusChangedTo: null,
      eventDoneAt: '2019-08-23T12:28:09.000Z',
      actionType: 'CREATED',
      actionSource: null,
      channel: { title: 'Tilapia', name: 'tilapia' },
      eventType: { id: 4, title: 'Certificate upload', slug: 'CertificateUpload' },
      eventDoneBy: {
        username: 'JumboTST',
        email: 'JumboTST@pc.t2f.io',
        org: { name: 'Jumbo', fullname: 'Jumbo', logo: null },
      },
    },
  },
];
