import Route from 'route-parser';

import eggBatchContractListResponseEggs from 'mocks/responses/channels/eggs/eggbatch/contractList';
import eggBatchIndexListResponseEggs from 'mocks/responses/channels/eggs/eggbatch/indexList';
import eggBatchDetailsResponseeggs from 'mocks/responses/channels/eggs/eggbatch/details';
import handshakeResponseEggs from 'mocks/responses/channels/eggs/eggbatch/handshake';
import uploadBatchResponse from 'mocks/responses/batchUpload/batchUploadEggsOranges';

import { deleleConractResponseEggsOranges } from 'mocks/router/utils';

export default (request) => {
  const route = new Route('/api/v1/eggs/eggbatch(?*filter)(/:batchId)(/contracts/:contractId)');

  const parameters = route.match(request.url);
  if (parameters) {
    if (parameters.filter) {
      if (parameters.filter.includes('beginDate')) {
        return [200, eggBatchIndexListResponseEggs()];
      }

      // handshake
      if (parameters.filter.includes('packerGln') && parameters.filter.includes('thtDateFrom')) {
        return [200, handshakeResponseEggs()];
      }
    }

    if (parameters.contractId) {
      if (request.method === 'delete') {
        return [200, deleleConractResponseEggsOranges(parameters.contractId)];
      }

      return [200, eggBatchContractListResponseEggs()];
    }

    if (parameters.batchId) {
      return [200, eggBatchDetailsResponseeggs()];
    }
  }

  if (request.method === 'post') {
    return [200, uploadBatchResponse(JSON.parse(request.data))];
  }

  return [500, `url not proceeded: ${request.url}`];
};
