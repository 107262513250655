import unmatchedResellerBatch from 'mocks/responses/unmatched/bananas/reseller.json';
import unmatchedDistributorBatch from 'mocks/responses/unmatched/bananas/distributor.json';
import unmatchedProducerBatch from 'mocks/responses/unmatched/bananas/producer.json';

const deleteContractResponse = (id, channel) => ({
  $class: `org.powerchain.${channel}.batch.BatchContractDestroyResponseConcept`,
  contractId: `${id}`,
  id: `${id}`,
  statusCode: 200,
  status: 'ARCHIVED',
  message: 'SUCCESS. Archived 7 batches.',
});

export const readNotificationResponse = (notification) => ({
  resource: {
    ...notification,
    event: {
      ...notification.event,
      actionType: 'ARCHIVED',
      statusChangedTo: 'ARCHIVED',
    },
    is_read: true,
  },
});

export const deleleConractResponseEggsOranges = (id, role = 'somerole') => ({
  $class: `org.powerchain.batch.Delete${role.toLocaleUpperCase()}BatchList`,
  contractId: id,
  transactionId: '2796fb2917d4ee6c6730ae0c164d3b6d56e25a91753e51ee2cf16c5bd07f3c9d',
});

export const deleteBatchResponseEggsOranges = (id, role = 'somerole') => ({
  $class: `org.powerchain.egg.batch.Delete${role.toLocaleUpperCase()}BatchTx`,
  batchId: id,
  transactionId: '36e52c71d4506ed80c92a3da74fffebbc537c68740836b210b21ba6f29233b95',
});

export const deleteContractResponseTilapia = (id) => deleteContractResponse(id, 'tilapia');

export const deleteContractResponseBananas = (id) => deleteContractResponse(id, 'bananas');

export const deleteContractResponseJuice = (id) => deleteContractResponse(id, 'juice');

export const approveCertificate = (certificate, requestParams) => ({
  ...certificate,
  ...requestParams,
  approvedBy: 'resource:org.powerchain.member.SIMUser#SIM_3123365b-d1ce-4079-baa0-40e836920dcf',
  approvedAt: new Date().toISOString(),
});

export const deleteMultipleBatchesResponse = (queryString) => {
  const data = JSON.parse(queryString);
  return {
    $class: 'org.powerchain.bananas.batch.DestroyBatchesResponseConcept',
    archivedBatches: data.batchIds.map((batchId) => ({
      $class: 'org.powerchain.bananas.batch.BaseBatchInfo',
      batchId,
      createdAt: '2019-09-16T18:17:24.281Z',
      createdBy:
        'resource:org.powerchain.bananas.member.ProducerUser#a73e54df-58b4-4e51-b876-e5e9b1f94b85',
      modifiedAt: '2019-10-10T07:07:02.812Z',
      modifiedBy:
        'resource:org.powerchain.bananas.member.ProducerUser#ProducerUser_c65f0c65-b391-4a24-8306-361ee6ff8d2c',
      status: 'ARCHIVED',
      uploadSource: 'api',
    })),
    batchIds: data.batchIds,
    batchType: data.batchType,
    status: 'SUCCESS',
    statusCode: 200,
  };
};

export const getUnmatchedResponse = ({ scope }) => {
  switch (scope) {
    case 'ResellerBatch':
      return [200, { ResellerBatch: unmatchedResellerBatch }];
    case 'DistributorBatch':
      return [200, { DistributorBatch: unmatchedDistributorBatch }];
    case 'ProducerBatch':
      return [200, { ProducerBatch: unmatchedProducerBatch }];
    case 'all':
      return [
        200,
        {
          ResellerBatch: unmatchedResellerBatch,
          DistributorBatch: unmatchedDistributorBatch,
          ProducerBatch: unmatchedProducerBatch,
        },
      ];
    default:
      return [500, {}];
  }
};
