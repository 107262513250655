import logoCitrosuco from 'components/logos/Citrosuco.svg';

import { BatchListSvg, CertificateSvg, DashboardSvg, NotificationListSvg } from 'components/icons';
import { theme } from 'themes/styles';

export default {
  logo: logoCitrosuco,
  colors: {
    primary: '#b3d571',
    primaryHover: '#77b500',
    mainMenuLine: '#77b500',
    mainMenuIconColor: '#77b500',
    mainMenuTextColor: 'rgb(0, 0, 0)',
    black: 'rgb(61, 61, 61)',
    backgroundHead: 'rgb(255, 255, 255)',
    datePickerBgColor: '#77b500',
    accent: '#005f32',
    modelSettingsCheckedColor: theme.colors.white,
  },
  mainMenuItems: [
    {
      routeName: 'Dashboard',
      icon: DashboardSvg,
      disabled: true,
    },
    {
      routeName: 'Notifications',
      icon: NotificationListSvg,
      disabled: true,
    },
    {
      routeName: 'Batches',
      icon: BatchListSvg,
    },
    {
      routeName: 'Documents',
      icon: CertificateSvg,
    },
  ],
};
