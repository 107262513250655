import { Theme } from 'lib/lib';

export default (theme: Theme) => ({
  component: {
    padding: ['2em', 0],
  },
  section: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'flex-start',
    padding: [30, 0],
  },
  leftSide: {
    flex: '0 0 800px',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'left',
    '& .worldMap': {
      flex: '0 0 90%',
      fill: theme.colors.lightGrey,
      filter: 'drop-shadow(1px 1px 2px rgba(150,150,150,0.5))',
    },
  },
  rightSide: {
    flex: '2 0 100px',
    flexWrap: 'wrap',
    display: 'flex',
    alignItems: 'flex-start',
    marginLeft: '2em',
  },
  tableHead: {
    display: 'flex',
    flex: '0 0 100%',
    backgroundColor: theme.colors.primary,
    justifyContent: 'space-between',
    color: theme.colors.white,
    '& div': {
      flex: '1 0 50px',
      padding: [15, 5],
      textAlign: 'left',
      font: {
        size: 12,
        family: 'Lato',
        weight: 700,
      },
    },
  },
  tableRow: {
    display: 'flex',
    flex: '0 0 100%',
    marginTop: '1em',
    backgroundColor: theme.colors.lightGrey,
    justifyContent: 'space-between',
    color: theme.colors.black,
    '& div': {
      flex: '1 0 50px',
      padding: [15, 5],
      textAlign: 'left',
      font: {
        size: 12,
        family: 'Lato',
        weight: 700,
      },
    },
  },
  listItem: {
    margin: [0, 0, '1em', 0],
    color: theme.colors.black,
    '& > h3': {
      padding: [5, 5],
      backgroundColor: theme.colors.backgroundHead,
      color: theme.colors.mainMenuIconColor,
    },
    '& > ul': {
      '& > li': {
        padding: [2, '1em'],
        '&:nth-child(odd)': {
          backgroundColor: theme.colors.background,
        },
        '&:nth-child(even)': {
          backgroundColor: theme.colors.backgroundLight,
        },
        '&:last-child': {},
      },
    },
  },
  loader: { ...theme.components.loader },
});
