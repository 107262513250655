import React, { useState, useMemo, useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
// styles
import injectStyles, { WithStyles } from 'react-jss';
import sortableHeaderStyles from 'components/Datatable/styles/sortableHeaderStyles';
// components
import { ArrowDown } from 'components/icons';
// redux
import * as envActions from 'domain/env/actions';
import { getQueryParams } from 'domain/common';
// utils
import { InfiniteGenerator } from 'components/Datatable/utils/IterableGenerator';
// types
import { IndexedObject } from 'lib/lib';
import { AppStateType } from 'types';

interface OwnProps extends WithStyles<typeof sortableHeaderStyles> {
  keyField: string;
  style: IndexedObject<unknown>;
  shortTitle: string;
  title: string;
  isSortable?: boolean;
}

enum SortDirection {
  ASC = 'ASC',
  DESC = 'DESC',
}

const mapStateToProps = (state: AppStateType) => ({
  queryParams: getQueryParams(state),
});

const SortableHeader: React.FC<OwnProps> = (props) => {
  const dispatch = useDispatch();
  const [sortingValue, setSortingValue] = useState<SortDirection | null>(null);
  const { queryParams } = useSelector(mapStateToProps, shallowEqual);
  const { keyField, shortTitle, style, title, isSortable, classes } = props;

  const sortPropsGenerator = useMemo(
    () => new InfiniteGenerator<SortDirection>([SortDirection.DESC, SortDirection.ASC]),
    [],
  );

  const isActiveSort = useMemo(() => queryParams.sortingKey === keyField, [queryParams.sortingKey]);

  const isAsc = useMemo(() => sortingValue === SortDirection.ASC, [sortingValue]);

  const dispatchSortParams = () => {
    if (sortingValue) {
      dispatch({
        type: envActions.setTabsQueryParamsAction.success,
        payload: {
          ...queryParams,
          sortingKey: keyField,
          sortingValue,
        },
      });
    }
  };

  const sort = () => {
    if (isSortable) {
      if (isActiveSort || !sortingValue) {
        setSortingValue(sortPropsGenerator.next());
      } else {
        dispatchSortParams();
      }
    }
  };

  useEffect(dispatchSortParams, [sortingValue]);

  return (
    <div
      className={isSortable ? classes.columnHeader : undefined}
      data-cy="columnHeader"
      key={keyField}
      style={style || {}}
      onClick={sort}
    >
      {isSortable && (
        <div
          className={`${classes.arrow}${isAsc ? ` ${classes.arrowUp}` : ''}${
            isActiveSort ? ` ${classes.activeSort}` : ''
          }`}
        >
          <ArrowDown width={15} fill="white" />
        </div>
      )}
      {shortTitle || title}
    </div>
  );
};

export default React.memo(injectStyles(sortableHeaderStyles)(SortableHeader));
