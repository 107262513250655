import React from 'react';
import { Link } from 'react-router-dom';
import { getUrl } from 'routes';

import { renderShortDateFormat } from 'lib/renderHelpers';

import { CHANNELS, ORGANIZATIONS } from 'themes/constants';

// components
import baseModelFields from 'models/seafoodConnection/tabs/baseModelFields';
import SubContractWarning from 'models/utils/SubContractWarning/SubContractWarning';
import { BatchRecord, ModelRequiredInfo } from 'lib/lib';

export default (modelRequiredInfo: ModelRequiredInfo) => [
  {
    key: 'productBatchIdBulk',
    label: 'Product/ batch ID bulk',
    title: 'ProductBatch Id Bulk',
    style: { flex: '1 0 200px' },
    render: (column: BatchRecord) => column.productBatchIdBulk,
  },
  {
    key: 'containerNumber',
    label: 'Container number',
    title: 'Container Number',
    style: { flex: '0 0 150px' },
    render: (column: BatchRecord) => (
      <SubContractWarning
        subContractAmount={column.subcontractAmount}
        linkText={column.containerNumber}
      >
        <Link
          to={{
            pathname: getUrl('BatchHandshake', {
              type: ORGANIZATIONS.REGALSPRINGS,
              channelName: CHANNELS.TILAPIA,
            }),
            search: `contractNumber=${encodeURIComponent(
              column.productBatchIdBulk,
            )}&containerNumber=${encodeURIComponent(column.containerNumber)}`,
          }}
        >
          {column.containerNumber}
        </Link>
      </SubContractWarning>
    ),
  },
  {
    key: 'descriptionSim',
    label: 'Description SIM',
    title: 'Description Sim',
    style: { flex: '1 0 130px' },
    render: (column: BatchRecord) => column.descriptionSim,
  },
  {
    key: 'customerArticleNumber',
    label: 'Customer article number',
    title: 'Customer Article Number',
    style: { flex: '0 0 120px' },
    defaultSelectedBatchContractColumn: true,
    render: (column: BatchRecord) => column.customerArticleNumber,
  },
  {
    key: 'specie',
    label: 'Specie',
    title: 'Specie',
    style: { flex: '0 0 70px' },
    defaultSelectedBatchContractColumn: true,
    render: (column: BatchRecord) => column.specie,
  },
  {
    key: 'productType',
    label: 'Product type (Fil-Loin-skin on/ off)',
    title: 'Product Type',
    style: { flex: '0 0 80px' },
    render: (column: BatchRecord) => column.productType,
  },
  {
    key: 'articleDescriptionCustomer',
    label: 'Article description customer',
    title: 'Article Description Customer',
    style: { flex: '1 0 200px' },
    render: (column: BatchRecord) => column.articleDescriptionCustomer,
  },
  {
    key: 'volumeBulkReceiptInKg',
    label: 'Volume bulk receipt in KG',
    title: 'Volume Bulk Receipt In Kg',
    style: { flex: '1 0 80px' },
    defaultSelectedBatchContractColumn: true,
    render: (column: BatchRecord) => column.volumeBulkReceiptInKg,
  },
  {
    key: 'bbd',
    label: 'BBD',
    title: 'BBD',
    style: { flex: '1 0 90px' },
    render: (column: BatchRecord) => renderShortDateFormat(column.bbd),
  },
  {
    key: 'dateReceipt',
    label: 'Date receipt',
    title: 'Date Receipt',
    style: { flex: '0 0 100px' },
    render: (column: BatchRecord) => renderShortDateFormat(column.dateReceipt),
  },
  {
    key: 'poNumberJumbo',
    label: 'PO number Jumbo',
    title: 'PO number Jumbo',
    style: { flex: '1 0 100px' },
    defaultSelectedBatchContractColumn: true,
    render: (column: BatchRecord) => column.poNumberJumbo,
  },
  {
    key: 'ssccPallet',
    label: 'SSCC Pallet',
    title: 'SSCC Pallet',
    style: { flex: '1 0 200px' },
    defaultSelectedBatchContractColumn: true,
    render: (column: BatchRecord) => column.ssccPallet,
  },
  {
    key: 'deliveryNumber',
    label: 'Delivery number',
    title: 'Delivery Number',
    style: { flex: '1 0 100px' },
    render: (column: BatchRecord) => column.deliveryNumber,
  },
  {
    key: 'deliveryDate',
    label: 'Delivery date',
    title: 'Delivery Date',
    style: { flex: '1 0 100px' },
    render: (column: BatchRecord) => renderShortDateFormat(column.deliveryDate),
  },
  {
    key: 'volumeDeliveryProductInKg',
    label: 'Volume delivery product in KG',
    title: 'Volume Delivery ProductInKg',
    style: { flex: '1 0 150px' },
    render: (column: BatchRecord) => column.volumeDeliveryProductInKg,
  },
  {
    key: 'tempLogReaderMinTemp',
    label: 'Temp log reader Min temp',
    title: 'Temp Log Reader Min Temp',
    style: { flex: '1 0 150px' },
    render: (column: BatchRecord) => column.tempLogReaderMinTemp,
  },
  {
    key: 'tempLogReaderAveTemp',
    label: 'Temp log reader ave temp',
    title: 'Temp Log Reader Ave Temp',
    style: { flex: '1 0 150px' },
    render: (column: BatchRecord) => column.tempLogReaderAveTemp,
  },
  {
    key: 'tempLogReaderMaxTemp',
    label: 'Temp log reader Max temp',
    title: 'Temp Log Reader Max Temp',
    style: { flex: '1 0 150px' },
    render: (column: BatchRecord) => column.tempLogReaderMaxTemp,
  },
  ...baseModelFields(),
];
