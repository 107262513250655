import dashboardTabModel from 'models/hollander/tabs/dashboardTabModel';
import settingsAndActions from 'models/hollander/settingsAndActions/dashboard';
import { ORGANIZATIONS } from 'themes/constants';
import { ModelRequiredInfo } from 'lib/lib';

export default (modelRequiredInfo: ModelRequiredInfo) => ({
  tabs: [
    {
      modelName: 'defaultTab',
      originalName: ORGANIZATIONS.HOLLANDER,
      title: ORGANIZATIONS.HOLLANDER,
    },
  ],
  defaultTab: dashboardTabModel,
  settingsAndActions,
});
