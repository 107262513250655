export default {
  $class: 'org.powerchain.juice.batch.ProcessorBatchReadConcept',
  lotFinishedProduct: '1778602',
  articleNbrFinishedProduct: '39000000',
  descriptionFinishedProduct: 'APET 6x100  AH Sinaasappelsap RFA BC',
  qtyProducedPcsFinishedProduct: 100000,
  qtyUsedLtrRawProduct: 11770,
  productionFinishedFinishedProduct: '2019-01-30T00:00:00.000Z',
  articleNbrFcoj: '7892',
  dateCheckFcoj: '2019-01-29T00:00:00.000Z',
  certificateOfConformityFcoj: 'Present and conform',
  organolepticCheckFcoj: 'Acceptable',
  ratioFcoj: 15.186,
  organolepticCheckFinishedProduct: 'Acceptable',
  vitaminCFinishedProduct: '50.000000',
  ratioFinishedProduct: 16.286,
  lotFcoj: 'SG19310864',
  batchId: '86e7a1d1-1cf9-4b79-a4c6-2ba87840d96f',
  createdAt: '2020-09-22T14:35:01.371Z',
  createdBy:
    'resource:org.powerchain.juice.member.ProcessorUser#REFRESCO_de6a20ca-20cf-4b62-89ea-11c822c19b19',
  status: 'PENDING',
  uploadSource: 'frontEndApp',
  channelName: 'juice',
  createdBy_participant: {
    username: null,
    email: null,
    participant:
      'org.powerchain.juice.member.ProcessorUser#REFRESCO_de6a20ca-20cf-4b62-89ea-11c822c19b19',
    channel: { title: 'Juice', name: 'juice' },
    org: { name: 'REFRESCO', fullname: 'Refresco company', logo: null },
    user: {
      username: 'REFTST staging',
      email: 'REFTST@pc.t2f.io',
      org: { name: 'REFRESCO', fullname: 'Refresco company', logo: null },
    },
  },
};
