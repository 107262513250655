import React from 'react';
import PropTypes from 'prop-types';
// jss
import injectStyle from 'react-jss';
import datatableStyles from 'components/Datatable/styles/datatableStyles';
import Loader from 'components/Loaders/smallLoader';
import SortableHeadersWrapper from './SortableHeadersWrapper';

class Datatable extends React.PureComponent {
  addSettingsAndActions = (columns) => {
    const { settingsAndActions } = this.props;
    if (settingsAndActions) {
      columns.push(settingsAndActions);
    }
  };

  render() {
    const {
      classes,
      data,
      columns,
      onRowClick,
      isLoading,
      noItemsText,
      isTableLoading = false,
    } = this.props;

    let columnsToRender = (columns && columns.defaultTab) || columns;
    if (!Array.isArray(columnsToRender)) {
      return null;
    }
    columnsToRender = columnsToRender.filter((f) => f);
    this.addSettingsAndActions(columnsToRender);
    return (
      <div>
        <SortableHeadersWrapper headers={columnsToRender} />
        {data &&
          !isTableLoading &&
          !!data.length &&
          data.map((dataItem, dIndex) => (
            <div
              data-cy="tableRow"
              className={classes.dataTableRow}
              key={dIndex}
              onClick={onRowClick ? onRowClick(dataItem) : () => {}}
              style={onRowClick ? { cursor: 'pointer' } : {}}
            >
              {!!columnsToRender.length &&
                columnsToRender.map((column, cIndex) => (
                  <div data-cy="tableCell" style={column.style || {}} key={cIndex}>
                    {column.render && column.render(dataItem)}
                  </div>
                ))}
            </div>
          ))}

        {!isLoading && !isTableLoading && data && data.length === 0 && (
          <div className={classes.noBatchesFound}>{noItemsText}</div>
        )}

        {(isLoading || isTableLoading) && (
          <div className={classes.loader}>
            <Loader />
          </div>
        )}
      </div>
    );
  }
}

Datatable.defaultProps = {
  noItemsText: 'No linked batches have been found',
};

Datatable.propTypes = {
  classes: PropTypes.object.isRequired,
  data: PropTypes.array,
  columns: PropTypes.oneOfType([PropTypes.object, PropTypes.instanceOf(Array)]).isRequired,
  onRowClick: PropTypes.func,
  noItemsText: PropTypes.string,
  settingsAndActions: PropTypes.object,
  currentTab: PropTypes.string,
  additionalColumns: PropTypes.instanceOf(Array),
  fieldsToExclude: PropTypes.instanceOf(Array),
  firstField: PropTypes.string,
};

export default injectStyle(datatableStyles)(Datatable);
