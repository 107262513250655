import detailsTabModelTab from 'models/jumbo/tabs/detailsTabModel';
import { ORGANIZATIONS } from 'themes/constants';
import { ModelRequiredInfo } from 'lib/lib';

export default (modelRequiredInfo: ModelRequiredInfo) => ({
  tabs: [
    {
      modelName: 'defaultTab',
      originalName: ORGANIZATIONS.JUMBO,
      title: ORGANIZATIONS.JUMBO,
    },
  ],
  defaultTab: detailsTabModelTab(modelRequiredInfo),
});
