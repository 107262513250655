import { Theme } from 'lib/lib';

export default (theme: Theme) => ({
  component: {
    padding: ['2em', 0],
  },
  componentInner: {
    width: '100%',
    display: 'flex',
    alignItems: 'flex-start',
    '& section': {
      marginRight: '1em',
    },
  },
  dropzoneSection: {
    display: 'flex',
    padding: ['3em', '1em', 0, 0],
  },
  formWrapper: {
    display: 'flex',
  },
  form: {
    display: 'flex',
    flex: '2 0 300px',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    padding: [25, 0],
  },
  formStringsFields: {
    width: '48%',
  },
  formPickerFields: {
    width: '48%',
    color: '#fff',
    zIndex: 1,
  },
  formTextAreaFields: {
    width: '100%',
  },
  btn: {
    display: 'block',
    position: 'relative',
    width: 'auto',
    padding: [8, 25],
    margin: [20, 'auto'],
    border: 0,
    borderRadius: 10,
    font: {
      weight: 600,
      size: 14,
    },
    outline: 0,
    backgroundColor:
      theme.colors.primaryHover === theme.colors.accent
        ? theme.colors.primary
        : theme.colors.primaryHover,
    color: theme.colors.white,
    transition: 'background-color 0.5s ease',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.colors.accent,
      textDecoration: 'none',
    },
  },
  dropZone: {
    color: theme.colors.black,
    '& form': {
      '& .dropZoneInput': {
        color: theme.colors.black,
        '& > label': {
          outline: 0,
          cursor: 'pointer',
        },
      },
    },
  },
  thumbsContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 16,
    '& .thumb': {
      display: 'inline-flex',
      borderRadius: 2,
      border: '1px solid #eaeaea',
      marginBottom: 8,
      marginRight: 8,
      width: 100,
      height: 100,
      padding: 4,
      boxSizing: 'border-box',
      '& .thumbInner': {
        display: 'flex',
        minWidth: 0,
        overflow: 'hidden',
        '& img': {
          display: 'block',
          width: 'auto',
          height: '100%',
        },
      },
    },
  },
});
