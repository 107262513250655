import React from 'react';
import { Link } from 'react-router-dom';
import { getUrl } from 'routes';
import baseModelFields from 'models/baseModelFields';
import { ORGANIZATIONS } from 'themes/constants';
//
import { renderShortDateFormat } from 'lib/renderHelpers';
import { BatchRecord, ModelRequiredInfo } from 'lib/lib';
// label defines what field to take from CSV batch file for upload

export default (modelRequiredInfo: ModelRequiredInfo) => [
  {
    key: 'lotFcoj',
    label: 'Lot_FCOJ',
    title: 'Lot FCOJ',
    style: { flex: '1 0 100px' },
    defaultSelectedBatchContractColumn: true,
    render: (column: BatchRecord) => (
      <Link
        to={{
          pathname: getUrl('BatchHandshake', {
            type: ORGANIZATIONS.CITROSUCOGHENT,
            channelName: column.channelName,
          }),
          search: `truckBatchId=${encodeURIComponent(encodeURIComponent(column.lotFcoj))}`,
        }}
      >
        {column.lotFcoj}
      </Link>
    ),
  },
  {
    key: 'lotFinishedProduct',
    label: 'Lot_Finished_Product',
    title: 'Lot Finished Product',
    style: { flex: '0 0 150px' },
    defaultSelectedBatchContractColumn: true,
    render: (column: BatchRecord) => column.lotFinishedProduct,
  },
  {
    key: 'articleNbrFinishedProduct',
    label: 'ArticleNbr_Finished_Product',
    title: 'Article Nbr Finished Product',
    style: { flex: '1 0 50px' },
    render: (column: BatchRecord) => column.articleNbrFinishedProduct,
  },
  {
    key: 'descriptionFinishedProduct',
    label: 'Description_Finished_Product',
    title: 'Description Finished Product',
    style: { flex: '0 0 270px' },
    defaultSelectedBatchContractColumn: true,
    render: (column: BatchRecord) => column.descriptionFinishedProduct,
  },
  {
    key: 'qtyProducedPcsFinishedProduct',
    label: 'QtyProduced_PCS_Finished_Product',
    title: 'QtyProduced PCS Finished Product',
    style: { flex: '1 0 100px' },
    defaultSelectedBatchContractColumn: true,
    render: (column: BatchRecord) => column.qtyProducedPcsFinishedProduct,
  },
  {
    key: 'qtyUsedLtrRawProduct',
    label: 'QtyUsed_LTR_RAW_Product',
    title: 'QtyUsed LTR RAW Product',
    style: { flex: '1 0 100px' },
    defaultSelectedBatchContractColumn: true,
    render: (column: BatchRecord) => column.qtyProducedPcsFinishedProduct,
  },
  {
    key: 'productionFinishedFinishedProduct',
    label: 'ProductionFinished_Finished_Product',
    title: 'ProductionFinished Finished Product',
    style: { flex: '1 0 100px' },
    defaultSelectedBatchContractColumn: true,
    render: (column: BatchRecord) =>
      renderShortDateFormat(column.productionFinishedFinishedProduct),
  },
  {
    key: 'articleNbrFcoj',
    label: 'ArticleNbr_FCOJ',
    title: 'ArticleNbr FCOJ',
    style: { flex: '1 0 50px' },
    render: (column: BatchRecord) => column.articleNbrFcoj,
  },
  {
    key: 'dateCheckFcoj',
    label: 'Date_check_FCOJ',
    title: 'Date check FCOJ',
    style: { flex: '1 0 50px' },
    render: (column: BatchRecord) => renderShortDateFormat(column.dateCheckFcoj),
  },
  {
    key: 'certificateOfConformityFcoj',
    label: 'Certificate_of_conformity_FCOJ',
    title: 'Certificate of conformity FCOJ',
    style: { flex: '1 0 50px' },
    render: (column: BatchRecord) => column.certificateOfConformityFcoj,
  },
  {
    key: 'organolepticCheckFcoj',
    label: 'Organoleptic_Check_FCOJ',
    title: 'Organoleptic Check FCOJ',
    style: { flex: '1 0 50px' },
    render: (column: BatchRecord) => column.organolepticCheckFcoj,
  },
  {
    key: 'ratioFcoj',
    label: 'Ratio_FCOJ',
    title: 'Ratio FCOJ',
    style: { flex: '1 0 50px' },
    render: (column: BatchRecord) => column.ratioFcoj,
  },
  {
    key: 'organolepticCheckFinishedProduct',
    label: 'Organoleptic_Check_Finished_Product',
    title: 'Organoleptic Check Finished Product',
    style: { flex: '1 0 50px' },
    render: (column: BatchRecord) => column.organolepticCheckFinishedProduct,
  },
  {
    key: 'vitaminCFinishedProduct',
    label: 'Vitamin_C_Finished_Product',
    title: 'Vitamin C Finished Product',
    style: { flex: '1 0 50px' },
    render: (column: BatchRecord) => column.vitaminCFinishedProduct,
  },
  {
    key: 'ratioFinishedProduct',
    label: 'Ratio_Finished_Product',
    title: 'Ratio Finished Product',
    style: { flex: '1 0 50px' },
    render: (column: BatchRecord) => column.ratioFinishedProduct,
  },
  {
    key: 'dateDeliveryAhFert',
    label: 'Date_Delivery_AH_FERT',
    title: 'Date Delivery AH FERT',
    style: { flex: '1 0 50px' },
    render: (column: BatchRecord) => renderShortDateFormat(column.dateDeliveryAhFert),
  },
  ...baseModelFields(modelRequiredInfo),
];
