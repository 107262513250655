import contractTabModel from 'models/refresco/juice/tabs/contractTabModel';
import { CHANNELS, ORGANIZATIONS } from 'themes/constants';
import archivedModelFields from 'models/archivedModelFields';
import { defaultContractSettingsAndActionsCSV } from 'models/utils/utils';
import { ModelRequiredInfo } from 'lib/lib';

export default (modelRequiredInfo: ModelRequiredInfo) => {
  const defaultArchivedTab = [...contractTabModel(modelRequiredInfo), ...archivedModelFields()];
  return {
    tabs: [
      {
        modelName: 'defaultTab',
        originalName: ORGANIZATIONS.REFRESCO,
        title: ORGANIZATIONS.REFRESCO,
      },
    ],
    defaultTab: defaultArchivedTab,
    settingsAndActions: defaultContractSettingsAndActionsCSV(CHANNELS.JUICE),
  };
};
