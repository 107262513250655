import React from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { getSelectedBatches } from 'domain/batches';
import { setSelectedBatchesAction } from 'domain/batches/actions';
import { BatchRecord } from 'lib/lib';

interface OwnProps {
  column: BatchRecord;
}

const Checkbox: React.FC<OwnProps> = ({ column }) => {
  const dispatch = useDispatch();
  const selected = useSelector(getSelectedBatches, shallowEqual);

  const selectCurrentBatch = () => {
    dispatch({
      type: setSelectedBatchesAction.success,
      payload: column.batchId,
    });
  };

  return (
    <input
      checked={selected.includes(column.batchId)}
      data-cy={column.batchId}
      type="checkbox"
      onChange={selectCurrentBatch}
    />
  );
};

export default React.memo(Checkbox);
