import React, { useLayoutEffect, useMemo } from 'react';
import { compose } from 'redux';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
// redux
import { getUsers, getIsLoading } from 'domain/admin';
import { getParams } from 'domain/common';
import * as adminActions from 'domain/admin/actions';
// jss
import injectSheet, { WithStyles } from 'react-jss';
import adminUserStyle from 'pages/admin/styles/adminUserStyle';
// components
import SimpleTabsFrame from 'components/Frames/SimpleTabsFrame';
import Loader from 'components/Loaders/smallLoader';
import AdminNav from 'pages/admin/components/adminNav';
// types
import { AppStateType } from 'types';
import { AdminUser as AdminUserType } from 'lib/lib';

const mapStateToProps = (state: AppStateType) => ({
  users: getUsers(state),
  isLoading: getIsLoading(state),
  params: getParams(state),
});

const AdminUser: React.FC<WithStyles<typeof adminUserStyle>> = ({ classes }) => {
  const dispatch = useDispatch();
  const { users, params, isLoading } = useSelector(mapStateToProps, shallowEqual);

  const userModel = useMemo(
    () => [
      {
        title: 'username',
        key: 'username',
        render: (u: AdminUserType) => u.username,
      },
      // {
      //   title: 'role',
      //   key: 'role',
      //   render: (u: AdminUserType) => u.role,
      // },
      {
        title: 'id',
        key: 'id',
        render: (u: AdminUserType) => u.id,
      },
      {
        title: 'org_id',
        key: 'org_id',
        render: (u: AdminUserType) => u.id,
      },
      {
        title: 'is_admin',
        key: 'is_admin',
        render: (u: AdminUserType) => (u.is_admin ? 'yes' : 'no'),
      },
      // {
      //   title: 'is_approved',
      //   key: 'is_approved',
      //   render: (u: AdminUserType) => (u.is_approved ? 'yes' : 'no'),
      // },
      // {
      //   title: 'participant',
      //   key: 'participant',
      //   render: (u: AdminUserType) => u.participant,
      // },
    ],
    [],
  );

  useLayoutEffect(() => {
    dispatch(adminActions.fetchUsersAction());
  }, []);

  const user = useMemo(() => users.find((f) => f.id === Number(params.id)), [users, params.id]);

  const userModelKeys = useMemo(() => userModel.map((c) => c.key), []);

  return (
    <div className={classes.component}>
      {user && (
        <React.Fragment>
          <AdminNav />
          <SimpleTabsFrame
            tabs={
              isLoading
                ? [{ style: {}, title: <Loader />, body: '' }]
                : [
                    {
                      style: {},
                      className: '',
                      title: `User № ${user.id}`,
                      body: Object.keys(user)
                        .filter((key) => userModelKeys.includes(key))
                        .map((key) => {
                          const userModelField = userModel.find((f) => f.key === key);
                          return (
                            userModelField && (
                              <div className={classes.tableRow} key={key}>
                                <div className="left">{userModelField.title}</div>
                                <div className="right">{userModelField.render(user)}</div>
                              </div>
                            )
                          );
                        }),
                    },
                  ]
            }
          />
        </React.Fragment>
      )}
    </div>
  );
};

export default compose<React.FC>(
  React.memo,
  injectSheet(adminUserStyle),
)(AdminUser);
